import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Interval} from "../../../core/models/interval";
import {TimeService} from "../../../core/services/time.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {DayConfig} from "../../../core/models/day-config";

@Component({
  selector: 'app-turn-form-control',
  templateUrl: './turn-form-control.component.html',
  styleUrls: ['./turn-form-control.component.scss']
})
export class TurnFormControlComponent implements OnInit, OnChanges {
  @Input() index: number | null = null;
  @Input() dayConfig: DayConfig | null = null;
  @Input() editable: boolean = false;
  @Input() turnForm: FormGroup;
  @Input() intervals: Interval[];
  @Input() clean = false;
  selectedIntervals: Interval[] = this.timeService.getFullDayHours(15);
  @Output() updateInterval = new EventEmitter<Interval[]>();
  @Output() deleteTurn = new EventEmitter<void>();

  constructor(private timeService: TimeService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    if (this.dayConfig !== null) {
      // @ts-ignore
      if (this.index < this.dayConfig?.turns?.length && !this.clean) {
        // @ts-ignore
        const indexFrom = this.selectedIntervals.findIndex(i => i.hour === this.dayConfig?.turns[this.index].fromHour);
        if (indexFrom !== -1) {
          this.turnForm.controls.fromHour.setValue(this.selectedIntervals[indexFrom]);
          this.setSelectedFrom(this.selectedIntervals[indexFrom]);
        }
        // @ts-ignore
        const indexTo = this.selectedIntervals.findIndex(i => i.hour === this.dayConfig?.turns[this.index].toHour);
        if (indexTo !== -1) {
          this.turnForm.controls.toHour.setValue(this.selectedIntervals[indexTo]);
          this.setSelectedTo(this.selectedIntervals[indexTo]);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editable && this.editable) {}
    if (changes.intervals && this.intervals) {
      this.selectedIntervals.forEach((interval, index) => {
        interval.blocked = this.intervals[index].blocked;
      });
    }
  }

  removeTurn() {
    this.deleteTurn.emit();
  }

  setSelectedFrom($event: Interval) {
    if (this.turnForm.controls.toHour.value !== null && $event) {
      // @ts-ignore
      if ($event.index > this.turnForm.controls.toHour.value.index) {
        this.turnForm.controls.fromHour.setValue(null);
        this.notification.create('error', 'Error al seleccionar rango', 'El horario seleccionado es incorrecto');
      } else {
        // @ts-ignore
        for (let i = $event.index; i >= 0; i--) {
          // @ts-ignore
          this.intervals[i].blocked = true;
        }
        this.updateInterval.emit(this.intervals);
      }
    }
  }

  setSelectedTo($event: Interval) {
    if (this.turnForm.controls.fromHour.value !== null && $event) {
      // @ts-ignore
      if ($event.index < this.turnForm.controls.fromHour.value.index) {
        this.turnForm.controls.toHour.setValue(null);
        this.notification.create('error', 'Error al seleccionar rango', 'El horario seleccionado es incorrecto');
      } else {
        // @ts-ignore
        for (let i = $event.index; i >= 0; i--) {
          // @ts-ignore
          this.intervals[i].blocked = true;
        }
        // @ts-ignore
        for (let j = $event.index + 1; j < this.intervals.length; j++) {
          // @ts-ignore
          this.intervals[j].blocked = false;
        }
        this.updateInterval.emit(this.intervals);
      }
    }
  }
}
