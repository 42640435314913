import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../core/models/user";
import {MemberService} from "../../../core/services/member.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize} from "rxjs/operators";
import {Company} from "../../../core/models/company";

@Component({
  selector: 'app-edit-member-vacations-modal',
  templateUrl: './edit-member-vacations-modal.component.html',
  styleUrls: ['./edit-member-vacations-modal.component.scss']
})
export class EditMemberVacationsModalComponent implements OnInit {
  @Input() member: User | null;
  memberForm: FormGroup;
  isLoading = false;
  error: string | null;
  @Output() memberEdited: EventEmitter<User> = new EventEmitter();

  constructor(private memberService: MemberService,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  closeModal() {
    this.drawerRef.close();
  }

  getArray(): FormArray {
    return this.memberForm.controls.vacationsConfigData as FormArray;
  }

  getArrayControl(index: number): FormGroup {
    return this.getArray().at(index) as FormGroup;
  }

  updateVacations() {
    if (this.memberForm.valid) {
      this.isLoading = true;
      this.memberService.updateMemberVacations(this.cleanData(this.memberForm.getRawValue()))
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((member: User | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Datos actualizados correctamente');
          // @ts-ignore
          this.memberEdited.emit(member);
          this.drawerRef.close();
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al actualizar las vacaciones', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.memberForm);
      this.formHelper.markFormArrayAsDirty(this.getArray());
    }
  }

  cleanData(data: any) {
    const form = data;
    form.vacationsConfigData.forEach((config: any) => {
      delete config.label;
    });
    return form;
  }

  private createForm() {
    this.memberForm = this.formBuilder.group({
      employeeUid: [this.member?.uid],
      rule: [this.member?.vacationsRules?.rule === 'Absolute' ? 'a' : 'v'],
      vacationsConfigData: this.formBuilder.array([])
    });
    this.setConfigData();
  }

  private setConfigData() {
    const arr = this.getArray();
    this.member?.vacationsRules?.vacationsConfig?.forEach((config, index) => {
      arr.push(
        this.formBuilder.group({
          days: [config.days, Validators.required],
          index: [index],
          uid: [config.uid],
          label: [config.label]
        })
      )
    });
  }

}
