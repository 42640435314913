import {Component, Input, OnInit} from '@angular/core';
import {GeoFencing} from "../../../core/models/geo-fencing";

@Component({
  selector: 'app-geo-fencing-details',
  templateUrl: './geo-fencing-details.component.html',
  styleUrls: ['./geo-fencing-details.component.scss']
})
export class GeoFencingDetailsComponent implements OnInit {
  @Input() geoFencing: GeoFencing;
  marker: any;

  constructor() { }

  ngOnInit(): void {
    this.marker = {
      latitude: this.geoFencing?.address?.latitude,
      longitude: this.geoFencing?.address?.longitude
    };
  }

}
