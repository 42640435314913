<div class="massive-load-modal-component">

  <!--<div class="stepper-container">
    <div class="step">
      <div class="circle" [ngClass]="{'active-circle': step === 1, 'passed-step': step === 2 || step === 3}"><span>1</span></div>
      <div class="title">Descargar</div>
      <div class="line"></div>
    </div>

    <div class="step">
      <div class="circle" [ngClass]="{'active-circle': step === 2, 'passed-step': step === 3}"><span>2</span></div>
      <div class="title">Sube documento</div>
      <div class="line"></div>
    </div>

    <div class="step">
      <div class="circle" [ngClass]="{'active-circle': step === 3}"><span>3</span></div>
      <div class="title">Listo</div>
    </div>
  </div>-->

  <nz-steps [nzCurrent]="step" [nzDirection]="vertical ? 'vertical' : 'horizontal'">
    <nz-step nzTitle="Descargar"></nz-step>
    <nz-step nzTitle="Sube documento"></nz-step>
    <nz-step nzTitle="Listo"></nz-step>
  </nz-steps>

  <div class="text-container">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="0">
        <div class="text">Descarga el template con los datos necesarios para poder realizar la carga masiva de {{ type === 'employees' ?  'colaboradores' : type === 'positions' ? 'puestos' : 'geocercas'}}.</div>
        <button class="blue-btn" nz-button nzType="primary" [nzLoading]="isDownloading" (click)="downloadTemplate()">Descargar template</button>
      </ng-container>

      <ng-container *ngSwitchCase="1">
        <div class="text">Sube el template con los datos completados para poder realizar la carga masiva de {{ type === 'employees' ?  'colaboradores' : type === 'positions' ? 'puestos' : 'geocercas'}}.</div>
        <button class="blue-btn" nz-button nzType="primary" [nzLoading]="isLoading" (click)="newFile.click()">Subir documento</button>
        <input style="display: none;" type="file" #newFile (change)="uploadDocument($event)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <div class="uploaded-container">
          <div class="uploaded-box">
            <i nz-icon nzType="icons:complete"></i>
            <div class="details-container">
              <div class="file-name">{{ file?.name }}</div>
              <div class="success">Se han subido tus datos de manera exitosa</div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="btns-box">
    <button class="default" *ngIf="step < 2" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button *ngIf="step === 0" nz-button nzType="primary" (click)="next()">Siguiente</button>
    <button *ngIf="step === 1" nz-button nzType="primary" [disabled]="isLoading" (click)="back()">Regresar</button>
    <button *ngIf="step === 2" nz-button nzType="primary" (click)="close()">Aceptar</button>
  </div>

</div>
