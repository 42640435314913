<div class="festivity-day-row flex between wrap align-center">
  <div class="number-content">
    <div class="number">Día festivo <span>#{{ festivityDay?.numberId }}</span></div>
  </div>


  <div class="category-container flex">
    <div class="category-box flex align-center">

      <div class="background" [ngStyle]="{backgroundColor: festivityDay?.colour}"></div>

      <div class="icon">
        <i nz-icon nzType="icons:party-fill" [ngStyle]="{color: festivityDay?.colour}" nzTheme="fill"></i>
      </div>

      <div class="type" [ngStyle]="{color: festivityDay?.colour}">
        {{ festivityDay?.name | titlecase }}
      </div>
    </div>
  </div>

  <div class="date-container">
    <div class="date">Del {{ festivityDay?.startDate | date: 'dd' }} de {{ festivityDay?.startDate | date: 'MMM' }} {{ festivityDay?.startDate | date: 'yyyy' }}</div>
  </div>


  <div class="date-container">
    <div class="date">Al {{ festivityDay?.endDate | date: 'dd' }} de {{ festivityDay?.endDate | date: 'MMM' }} {{ festivityDay?.endDate | date: 'yyyy' }}</div>
  </div>


  <ng-container>
    <div class="festive-day-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" nz-tooltip="Configurar día festivo" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="setting" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="editFestivityDay()" [nzDisabled]="isDeleting"><i class="empty-icon grey" nz-icon nzType="icons:party-fill" nzTheme="outline"></i> Editar día festivo</li>
        <li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar este día festivo?"
            (nzOnConfirm)="removeFestivityDay()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar día festivo</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
