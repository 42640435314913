import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../core/models/user";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MemberService} from "../../../core/services/member.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-edit-actual-vacations',
  templateUrl: './edit-actual-vacations.component.html',
  styleUrls: ['./edit-actual-vacations.component.scss']
})
export class EditActualVacationsComponent implements OnInit {
  @Input() member: User | null;
  editVacationsForm: FormGroup;
  isLoading = false;
  error: string | null;
  formatter = (value: number) => `${(value)}` + ' ' + (value === 1 ? 'día' : 'días');
  parser = (value: string) => value.replace("día", "").replace("días", "");

  constructor(private memberService: MemberService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  close() {
    this.modalRef.close(null);
  }

  updateVacations() {
    if (this.editVacationsForm.valid) {
      this.isLoading = true;
      this.memberService.updateMemberActualVacations(this.editVacationsForm.getRawValue())
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((member: User | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Vacaciones restantes actualizadas correctamente');
          this.modalRef.close(member);
        }, error => {
          this.notification.create('error', 'Error al actualizar las vacaciones restantes', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.editVacationsForm);
    }
  }

  private createForm() {
    this.editVacationsForm = this.formBuilder.group({
      employeeUid: [this.member?.uid],
      actualVacations: [this.member?.remainingVacationDays, Validators.required]
    });
  }

}
