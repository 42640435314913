<div class="edit-member-position">

  <ng-template #newPositionTitle>
    <div class="flex align-center">
      <span>Nuevo Puesto</span>
      <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'1'" nz-icon nzType="info-circle" nzTheme="fill"></i>
    </div>
  </ng-template>

  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <nz-spin [nzSpinning]="isLoadingPositions">
    <form nz-form nzLayout="vertical" [formGroup]="memberForm" *ngIf="!isLoadingPositions">
      <nz-form-item>
        <nz-form-label>Seleccionar nuevo puesto</nz-form-label>
        <nz-form-control [nzErrorTip]="positionErrorTpl">

          <nz-select formControlName="positionUid" [nzPlaceHolder]="'Seleccionar puesto'">
            <nz-option [nzValue]="{name: 'new'}" [nzLabel]="'+ Nuevo puesto'"></nz-option>
            <nz-option *ngFor="let position of positions" [nzValue]="position" [nzLabel]="position?.name"></nz-option>
          </nz-select>

          <ng-template #positionErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              La posición es requerida
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-spin>

  <div class="btns-container" *ngIf="!isLoadingPositions">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="closeModal()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="updatePosition()">Guardar</button>
  </div>
</div>
