<div class="user-horizontal-bar-component">
  <nz-spin [nzSpinning]="isLoading">
    <div class="chart-container">
      <div class="filters-container">
        <div class="title">{{ title }}</div>

        <div class="filters-box">
          <div class="month-box">
            <i nz-icon nzType="left" (click)="prevMonth()"></i>
            <span class="month">{{ months[selectedMonth]?.name }}</span>
            <i nz-icon nzType="right" (click)="nextMonth()"></i>
          </div>

          <nz-select [(ngModel)]="selectedYear" (ngModelChange)="yearChange()">
            <nz-option *ngFor="let year of years" [nzValue]="year" [nzLabel]="year.toString()"></nz-option>
          </nz-select>
        </div>
      </div>

      <div class="users-container" *ngIf="data">
        <div class="empty-box" *ngIf="data.length === 0" style="margin-top: 68px;">
          <i nz-icon nzType="icons:empty"></i>
          <div class="message-box">
            <div>No se encontraron registros en este mes</div>
          </div>
        </div>


        <div class="user-row" *ngFor="let row of data">
          <div class="user-box">
            <nz-avatar [nzGap]="5" [nzSize]="36"  [nzSrc]="row.employee?.photo?.url || undefined" [nzText]="row?.employee?.firstName?.charAt(0) + '' + row?.employee?.lastName?.charAt(0)"></nz-avatar>
            <div class="user-info">
              <div class="name">{{ row?.employee?.firstName }} {{ row?.employee?.lastName }}</div>
              <div class="email">{{ row?.employee?.email }}</div>
            </div>
          </div>

          <div class="progress-container">
            <div class="progress horizontal" nz-popover nzPopoverTrigger="hover" [nzPopoverContent]="template">
              <div class="bar" [style.width]="row?.percent + '%'" [style.background-color]="barColor"></div>
            </div>
          </div>

          <ng-template #template>
            <div class="status-container">
              <div class="name">{{ row?.employee?.firstName }} {{ row?.employee?.lastName }}</div>
              <ng-container [ngSwitch]="type">
                <div *ngSwitchCase="'onTime'" class="content">Puntualidad registrada en el mes: {{ row?.amount }} ({{ row?.percent.toFixed(2) }}%)</div>
                <div *ngSwitchCase="'attendances'" class="content">Asistencias registradas en el mes: {{ row?.amount }} ({{ row?.percent.toFixed(2) }}%)</div>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </nz-spin>
</div>
