import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-vacation-config-control',
  templateUrl: './vacation-config-control.component.html',
  styleUrls: ['./vacation-config-control.component.scss']
})
export class VacationConfigControlComponent implements OnInit {
  @Input() vacationFormGroup: FormGroup;
  formatter = (value: number) => `${value} ${value === 1 ? ' día' : ' días'}`;
  parser = (value: string) => value.replace(' día', '').replace(' días', '');

  constructor() { }

  ngOnInit(): void {
  }

}
