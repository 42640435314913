import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbsenceType} from '../../../core/models/absence-type';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Color} from '../../../core/models/color';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {ColorService} from '../../../core/services/color.service';
import {AbsenceTypeService} from '../../../core/services/absence-type.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-edit-absence-type',
  templateUrl: './edit-absence-type.component.html',
  styleUrls: ['./edit-absence-type.component.scss']
})
export class EditAbsenceTypeComponent implements OnInit {
  @Input() absenceType: AbsenceType;

  newAbsenceType: FormGroup;

  @Output() absenceTypeEdited: EventEmitter<AbsenceType> = new EventEmitter<AbsenceType>();

  colors: Color[] = this.colorService.getColors();

  isLoading: boolean;
  error: string | null;

  constructor(
      private modalRef: NzModalRef,
      private formBuilder: FormBuilder,
      private formHelper: FormHelperService,
      private colorService: ColorService,
      private absenceTypeService: AbsenceTypeService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  destroyModal() {
    this.modalRef.close();
  }

  private createForm() {
    this.newAbsenceType = this.formBuilder.group({
      absenceTypeUid: [this.absenceType.uid],
      name: [this.absenceType.name, Validators.required],
      colour: [this.absenceType.colour, Validators.required],
      paid: [this.absenceType.paid],
      vacation: [this.absenceType.vacation]
    });
  }

  submitForm() {
    if (this.newAbsenceType.valid) {
      this.isLoading = true;
      this.error = null;
      this.absenceTypeService.updateAbsenceType(this.cleanData())
          .pipe(
              finalize(() => {
                this.isLoading = false;
              })
          ).subscribe(absenceType => {
        this.absenceTypeEdited.emit(absenceType);
        this.destroyModal();
      }, error => {
        this.error = error;
      })
    } else {
      this.formHelper.markFormAsDirty(this.newAbsenceType);
    }
  }

  private cleanData(): AbsenceType {
    const data: AbsenceType = this.newAbsenceType.getRawValue();
    return data;
  }

}
