<div class="edit-position">
  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <nz-spin class="spin-loading" [nzSpinning]="isLoading">

    <form nz-form nzLayout="vertical" [formGroup]="positionForm" *ngIf="!isLoading && positionForm && !error">
      <nz-form-item>
        <nz-form-label nzFor="name">Nombre del puesto</nz-form-label>
        <nz-form-control [nzErrorTip]="nameErrorTpl">

          <input nz-input placeholder="Nombre del puesto" formControlName="name" type="text" id="name">

          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El nombre del puesto es requerido
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <div class="form-items">
        <nz-form-item class="item">
          <nz-form-label>Tolerancia de check in / out</nz-form-label>
          <nz-form-control [nzErrorTip]="toleranceMinutesErrorTpl">

            <nz-input-number
              style="width: 100%"
              [OnlyNumber]="true"
              formControlName="toleranceMinutes"
              [nzStep]="10"
              [nzFormatter]="formatterMin"
              [nzParser]="parserMin">
            </nz-input-number>

            <ng-template #toleranceMinutesErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                La tolerancia es requerida
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="item">
          <nz-form-control>

            <nz-radio-group formControlName="affectsEveryoneSchedule">
              <label nz-radio [nzValue]="true" class="radio-text">Aplicar cambios a todos los miembros con este puesto</label>
              <label nz-radio [nzValue]="false" class="radio-text">Guardar configuracion a futuros miembros</label>
            </nz-radio-group>

          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="days-container" *ngIf="positionForm">
        <div class="day">
          <app-schedule-form-control [dayConfig]="position?.schedule?.monday" [dayFormGroup]="getDayFormGroup('monday')" [dayName]="'Lunes'"></app-schedule-form-control>
        </div>

        <div class="day">
          <app-schedule-form-control [dayConfig]="position?.schedule?.tuesday" [dayFormGroup]="getDayFormGroup('tuesday')" [dayName]="'Martes'"></app-schedule-form-control>
        </div>

        <div class="day">
          <app-schedule-form-control [dayConfig]="position?.schedule?.wednesday" [dayFormGroup]="getDayFormGroup('wednesday')" [dayName]="'Miércoles'"></app-schedule-form-control>
        </div>

        <div class="day">
          <app-schedule-form-control [dayConfig]="position?.schedule?.thursday" [dayFormGroup]="getDayFormGroup('thursday')" [dayName]="'Jueves'"></app-schedule-form-control>
        </div>

        <div class="day">
          <app-schedule-form-control [dayConfig]="position?.schedule?.friday" [dayFormGroup]="getDayFormGroup('friday')" [dayName]="'Viernes'"></app-schedule-form-control>
        </div>

        <div class="day">
          <app-schedule-form-control [dayConfig]="position?.schedule?.saturday" [dayFormGroup]="getDayFormGroup('saturday')" [dayName]="'Sábado'"></app-schedule-form-control>
        </div>

        <div class="day">
          <app-schedule-form-control [dayConfig]="position?.schedule?.sunday" [dayFormGroup]="getDayFormGroup('sunday')" [dayName]="'Domingo'"></app-schedule-form-control>
        </div>
      </div>
    </form>

  </nz-spin>

  <div class="drawer-footer ant-drawer-footer" *ngIf="!isLoading">
    <div class="flex end wrap">
      <button nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
              (nzOnConfirm)="closeModal()"
              type="button" nz-button nzType="default">Cancelar</button>
      <button *ngIf="!error" [nzLoading]="isUpdating" (click)="updatePosition()" type="button" nz-button nzType="primary">Guardar</button>
    </div>
  </div>
</div>
