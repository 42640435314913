<div (click)="openModal()" class="absence-request-box flex align-center" [nz-tooltip]="'Ver detalles'" nzTooltipColor="white">

  <ng-template #absenceModalTitle>
    <div class="flex align-center">
      <span *ngIf="absenceRequest.approved">Detalles de ausencia</span>
      <span *ngIf="!absenceRequest.approved">Solicitud de ausencia</span>
      <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'5'" nz-icon nzType="info-circle" nzTheme="fill"></i>
    </div>
  </ng-template>

  <div class="background" [ngStyle]="{backgroundColor: absenceRequest.absenceType?.colour}"></div>

  <div class="icon">
    <i nz-icon nzType="calendar" [ngStyle]="{color: absenceRequest.absenceType?.colour}" nzTheme="fill"></i>
  </div>

  <div class="type" [ngStyle]="{color: absenceRequest.absenceType?.colour}">
    {{absenceRequest.absenceType?.name | titlecase}} - {{absenceRequest.days}} día<span *ngIf="absenceRequest.days != 1">s</span>
  </div>
</div>
