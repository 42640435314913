import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {AbsenceType} from '../models/absence-type';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AbsenceTypeService {

  constructor(
      private apollo: Apollo
  ) { }

  getAbsenceTypes(): Observable<AbsenceType[] | undefined> {
    const absenceTypes = gql`
        query absenceTypes {
            myCompany {
                absenceTypes {
                    uid
                    name
                    colour
                    vacation
                    paid
                }
            }
        }
    `;
    return this.apollo
        .query<{ myCompany: {absenceTypes: AbsenceType[] }}>({
          query: absenceTypes
        })
        .pipe(
            map(({ data }) => {
              return data.myCompany.absenceTypes;
            })
        );
  }

  createAbsenceTypes(absenceType: AbsenceType): Observable<AbsenceType | undefined> {
    const createAbsenceType = gql`
        mutation createAbsenceType(
          $paid: Boolean!
          $vacation: Boolean!
          $name: String!
          $colour: String!
        ) {
            createAbsenceType(
                paid: $paid
                vacation: $vacation
                name: $name
                colour: $colour
            ) {
                absenceType {
                    uid
                    name
                    colour
                    vacation
                    paid
                }
            }
        }
    `;
    return this.apollo
        .mutate<{ createAbsenceType: {absenceType: AbsenceType }}>({
          mutation: createAbsenceType,
          variables: {
            ...absenceType
          }
        })
        .pipe(
            map(({ data }) => {
              return data?.createAbsenceType.absenceType;
            })
        );
  }

  updateAbsenceType(absenceType: AbsenceType): Observable<AbsenceType | undefined> {
    const updateAbsenceType = gql`
        mutation updateAbsenceType(
            $paid: Boolean!
            $vacation: Boolean!
            $name: String!
            $colour: String!
            $absenceTypeUid: String!
        ) {
            updateAbsenceType(
                paid: $paid
                vacation: $vacation
                name: $name
                colour: $colour
                absenceTypeUid: $absenceTypeUid
            ) {
                absenceType {
                    uid
                    name
                    colour
                    vacation
                    paid
                }
            }
        }
    `;
    return this.apollo
        .mutate<{ updateAbsenceType: {absenceType: AbsenceType }}>({
          mutation: updateAbsenceType,
          variables: {
            ...absenceType
          }
        })
        .pipe(
            map(({ data }) => {
              return data?.updateAbsenceType.absenceType;
            })
        );
  }

  deleteAbsenceType(absenceTypeUid: string | undefined): Observable<string | undefined> {
    const deleteAbsenceType = gql`
        mutation deleteAbsenceType(
            $absenceTypeUid: String!
        ) {
            deleteAbsenceType(
                absenceTypeUid: $absenceTypeUid
            ) {
                message
            }
        }
    `;
    return this.apollo
        .mutate<{ deleteAbsenceType: {message: string }}>({
          mutation: deleteAbsenceType,
          variables: {
            absenceTypeUid
          }
        })
        .pipe(
            map(({ data }) => {
              return data?.deleteAbsenceType.message;
            })
        );
  }
}
