import { Injectable } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {PaymentsTableFilter} from '../models/payments-table-filter';
import {Observable} from 'rxjs';
import {Payment} from '../models/payment';
import {map} from 'rxjs/operators';
import {Company} from "../models/company";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
      private apollo: Apollo
  ) { }

  getPayments(filters: PaymentsTableFilter): Observable<{ count: number, payments: Payment[] }> {
    const paymentsTable = gql`
        query paymentsTable(
            $substring: String
            $limit: Int
            $skip: Int
        ) {
            paymentsTable(
                substring: $substring
                limit: $limit
                skip: $skip
            ) {
                count
                payments {
                    amount
                    invoice {
                        numberId
                        zipFileName
                        zipFileUrl
                    }
                    numberId
                    paymentLink
                    paymentMethod {
                        brand
                        last4
                        name
                    }
                    rejectReason
                    status
                    transactionDate
                    transactionId
                    uid
                }
            }
        }
    `;
    return this.apollo
        .query<{ paymentsTable: { count: number, payments: Payment[] } }>({
          query: paymentsTable,
          variables: {
            ...filters
          }
        })
        .pipe(
            map(({ data }) => {
              return data.paymentsTable;
            })
        );
  }

  addCreditsToCompany(form: any): Observable<Company> {
    const addCompanyCredit = gql`
      mutation addCompanyCredit($amount: Float! $paymentMethodUid: String $usePaymentLink: Boolean) {
        addCompanyCredit(amount: $amount paymentMethodUid: $paymentMethodUid usePaymentLink: $usePaymentLink) {
          company {
            subscription {
              dueBalance
            }
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ addCompanyCredit: { company: Company } }>({
        mutation: addCompanyCredit,
        variables: {
          ...form
        }
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.addCompanyCredit.company;
        })
      );
  }
}
