<div class="earnings-form-control flex wrap">

  <div class="flex">
    <nz-select
      [nzMaxMultipleCount]="!moreThanOne ? 1 : 100000000000"
      [(nzOpen)]="isOpen"
      class="hidden-select"
      [nzDropdownRender]="renderTemplate"
      [nzDropdownMatchSelectWidth]="false"
      [nzMaxTagCount]="0"
      [compareWith]="compareDeductions"
      [nzOptionHeightPx]="40"
      [nzOptionOverflowSize]="5"
      [nzDropdownClassName]="'earning-form-control-select'"
      nzMode="multiple"
      nzPlaceHolder="Selecciona las percepciones"
      [formControl]="earnings"
      nzShowSearch
      nzServerSearch
      (nzOnSearch)="onEarningsSearch($event)"
      (nzScrollToBottom)="loadEarnings()"
      (ngModelChange)="updateAction($event)">

      <ng-container *ngFor="let earning of list">
        <nz-option nzCustomContent [nzLabel]="(earning?.isDefault ? (earning?.name) : (earning?.name | titlecase)) || ''"
                   [nzValue]="earning" [nzDisabled]="blockDefaults ? (earning?.isDefault && notSelected(earning)) : false">
          <div class="earning-row flex align-center">
            <div class="earning-photo earning-photo-control">
              <app-team-icon [size]="28" [icon]="earning?.icon?.url"  [color]="earning?.colour"></app-team-icon>
            </div>
            <div class="earning-name">
              {{earning?.isDefault ? (earning?.name) : (earning?.name | titlecase)}}
            </div>
          </div>
        </nz-option>
      </ng-container>

      <nz-option *ngIf="earningsLoading" nzDisabled nzCustomContent>
        <div class="earnings-row loading-row align-center flex">
          <i nz-icon nzType="loading" class="loading-icon"></i> Cargando percepciones...
        </div>
      </nz-option>

    </nz-select>

    <ng-template #renderTemplate>
      <div class="search-box" (click)="openNewEarning()">
        <div class="line"></div>
        <div class="new-label">
          + Crear nueva percepción
        </div>
      </div>
    </ng-template>
  </div>

  <ng-container>
    <div class="earning bubble" nzTooltipColor="white"
         [nz-tooltip]="(earning?.name | titlecase)" *ngFor="let earning of earnings.value">
      <i *ngIf="blockDefaults ? !earning?.isDefault : true" (click)="removeEarning(earning)" class="close-icon" nz-icon [nzTwotoneColor]="'#FF8484'" nzType="close-circle" nzTheme="twotone"></i>
      <app-team-icon [size]="50" [icon]="earning?.icon?.url"  [color]="earning?.colour"></app-team-icon>
    </div>
  </ng-container>

  <div class="earning add-new" (click)="isOpen = true;" *ngIf="moreThanOne ? true : earnings.value.length === 0">
    <i nz-icon nzType="icons:earning-add" class="loading-icon e"></i>
  </div>
</div>
