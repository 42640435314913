<div class="map-box3">
  <agm-map [latitude]="selectedGeolocation !== null ? selectedGeolocation.latitude : (selectedTeamCluster?.length > 0 ? selectedTeamCluster[0].latitude : geolocations[0].latitude)"
           [longitude]="selectedGeolocation !== null ? selectedGeolocation.longitude : (selectedTeamCluster?.length > 0 ? selectedTeamCluster[0].longitude : geolocations[0].longitude)"
           [minZoom]="2"
           [zoom]="2"
           [maxZoom]="16"
           [fitBounds]="true"
           [disableDefaultUI]="true"
           [mapTypeId]="'roadmap'"
           [styles]="mapStyle"
           class="round-img">

    <ng-container *ngIf="selectedGeolocation === null && selectedTeamCluster?.length === 0">
      <ng-container *ngFor="let team of teams">
        <ct-marker-cluster
          (clusterClick)="clusterClick($event)"
          [imagePath]="'../assets/images/map-pins/map-cluster-pins/' + team?.color?.replace('#', '') + '-m-'"
          [enableRetinaIcons]="false">

          <ng-container *ngFor="let member of team?.members; let i = index;">
            <agm-marker
              [iconUrl]="member?.lastLocation?.pinData"
              [agmFitBounds]="true"
              [latitude]="member?.lastLocation?.latitude"
              [longitude]="member?.lastLocation?.longitude"
              [markerClickable]="true">
              <agm-info-window [disableAutoPan]="true">
                <div style="margin: 0 12px;">{{ member?.firstName | titlecase }} {{ member?.lastName | titlecase }}</div>
                <div style="margin: 0 12px;">{{ member?.lastLocation?.correctTimestamp | date: 'dd' }} de  {{ member?.lastLocation?.correctTimestamp | date: 'MMM' }} {{ member?.lastLocation?.correctTimestamp | date: 'yyyy' }}</div>
                <div style="margin: 0 12px;">{{ member?.lastLocation?.correctTimestamp | date: 'HH:mm' }} hrs</div>
              </agm-info-window>
            </agm-marker>
          </ng-container>
        </ct-marker-cluster>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedGeolocation === null && selectedTeamCluster?.length > 0 && color !== null">
      <ct-marker-cluster
        (clusterClick)="clusterClick($event)"
        [imagePath]="color"
        [enableRetinaIcons]="false">

        <ng-container *ngFor="let location of selectedTeamCluster; let i = index;">
          <agm-marker
            [iconUrl]="location?.pinData"
            [agmFitBounds]="true"
            [latitude]="location?.latitude"
            [longitude]="location.longitude"
            [markerClickable]="true">
            <agm-info-window [disableAutoPan]="true">
              <div style="margin: 0 12px;">{{ location?.pinData?.extra.employee?.firstName | titlecase }} {{ location?.pinData?.extra.employee?.lastName | titlecase }}</div>
              <div style="margin: 0 12px;">{{location?.correctTimestamp | date: 'dd' }} de  {{ location?.correctTimestamp | date: 'MMM' }} {{ location?.correctTimestamp | date: 'yyyy' }}</div>
              <div style="margin: 0 12px;">{{ location?.correctTimestamp | date: 'HH:mm' }} hrs</div>
            </agm-info-window>
          </agm-marker>
        </ng-container>
      </ct-marker-cluster>
    </ng-container>

    <ng-container *ngIf="selectedGeolocation !== null">
      <agm-marker
        [iconUrl]="selectedGeolocation?.pinData"
        [agmFitBounds]="true"
        [latitude]="selectedGeolocation.latitude"
        [longitude]="selectedGeolocation.longitude"
        [markerClickable]="true">
        <agm-info-window [disableAutoPan]="true" [isOpen]="true">
          <div style="margin: 0 12px;">{{ selectedGeolocation?.pinData?.extra?.employee?.firstName | titlecase }} {{ selectedGeolocation?.pinData?.extra?.employee?.lastName | titlecase }}</div>
          <div style="margin: 0 12px;">{{ selectedGeolocation?.correctTimestamp | date: 'dd' }} de  {{ selectedGeolocation?.correctTimestamp | date: 'MMM' }} {{ selectedGeolocation?.correctTimestamp | date: 'yyyy' }}</div>
          <div style="margin: 0 12px;">{{ selectedGeolocation?.correctTimestamp | date: 'HH:mm' }} hrs</div>
        </agm-info-window>
      </agm-marker>

      <agm-circle
        [strokeColor]="selectedGeolocation?.pinData?.extra?.employee?.team?.color"
        [strokeOpacity]=".8"
        [strokeWeight]="2"
        [fillColor]="selectedGeolocation?.pinData?.extra?.employee?.team?.color"
        [fillOpacity]=".25"
        [longitude]="selectedGeolocation?.longitude || 0"
        [latitude]="selectedGeolocation?.latitude || 0"
        [radius]="200">
      </agm-circle>
    </ng-container>
  </agm-map>
</div>
