import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {GeoLocation} from "../../../core/models/geo-location";
import {MapTypeStyle} from "@agm/core/services/google-maps-types";
import {Cluster} from "@google/markerclustererplus/dist/cluster";

@Component({
  selector: 'app-team-geo-locations-map',
  templateUrl: './team-geo-locations-map.component.html',
  styleUrls: ['./team-geo-locations-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamGeoLocationsMapComponent implements OnInit {
  @Input() selectedGeolocation: GeoLocation | null = null;
  @Input() geolocations: GeoLocation[];
  @Input() teamColor: string;
  @Output() selectedCluster = new EventEmitter<GeoLocation[]>();
  mapStyle: MapTypeStyle[] = [
    {
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    // for hidding elelmnts
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    }
  ];

  transparentIcon: any = {
    url: '../assets/images/empty.png',
    scaledSize: {
      width: 30,
      height: 30
    }
  };

  constructor() { }

  ngOnInit(): void {
    this.geolocations.forEach((geolocation: GeoLocation) => {
      if (geolocation?.timestamp) {
        // @ts-ignore
        geolocation?.correctTimestamp = new Date(geolocation?.timestamp.slice(0,-6));
      }
      geolocation.pinData = {
        url: geolocation.user?.photo ? geolocation.user?.photo?.url : '../assets/images/map-pins/user-pins/user-' + this.teamColor.replace('#', '') + '.png',
        scaledSize: {
          width: 30,
          height: 32
        },
        extra: {
          geolocation: geolocation,
          employee: geolocation.user
        }
      };
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedGeolocation && this.selectedGeolocation !== null) {
      if (this.selectedGeolocation?.timestamp) {
        // @ts-ignore
        this.selectedGeolocation?.correctTimestamp = new Date(this.selectedGeolocation?.timestamp.slice(0,-6));
      }
    }
  }

  clusterClick(cluster: Cluster) {
    const selectedCluster: GeoLocation[] = [];
    cluster.getMarkers().map((marker: any) => {
      selectedCluster.push(marker.getIcon().extra.geolocation);
    });
    this.selectedCluster.emit(selectedCluster);
  }
}
