import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EmployerRegistry} from "../../../core/models/employer-registry";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {Subject} from "rxjs";
import {finalize, takeUntil} from "rxjs/operators";
import {EditEmployerRegistryModalComponent} from "../../modals/edit-employer-registry-modal/edit-employer-registry-modal.component";
import {EmployerRegistryService} from "../../../core/services/employer-registry.service";
import {EmployerRegistryDetailsModalComponent} from "../../modals/employer-registry-details-modal/employer-registry-details-modal.component";
import {ClientCertificate} from "../../../core/models/client-certificate";

@Component({
  selector: 'app-employer-registry-row',
  templateUrl: './employer-registry-row.component.html',
  styleUrls: ['./employer-registry-row.component.scss']
})
export class EmployerRegistryRowComponent implements OnInit, OnDestroy {
  @Input() employerRegistry: EmployerRegistry | null;
  @Output() removeEmployerRegistry = new EventEmitter<void>();
  isDeleting = false;
  $onDestroy: Subject<void> = new Subject<void>();

  constructor(private employerRegistryService: EmployerRegistryService,
              private notification: NzNotificationService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  deleteEmployerRegistry() {
    this.isDeleting = true;
    this.employerRegistryService.deleteEmployerRegistry(this.employerRegistry?.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', 'Registro patronal eliminada correctamente');
        this.removeEmployerRegistry.emit();
      }, error => {
        this.notification.create('error', 'Error al eliminar el registro patronal', error.message.replace("GraphQL error:", "").trim());
      });
  }

  editEmployerRegistry() {
    const modal = this.drawerService.create({
      nzContent: EditEmployerRegistryModalComponent,
      nzTitle: 'Editar registro patronal',
      nzWidth: 504,
      nzContentParams: {
        employerRegistry: this.employerRegistry
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((employerRegistry: EmployerRegistry) => {
      if (employerRegistry) {
        this.employerRegistry = employerRegistry;
      }
    })
  }

  viewEmployerRegistry() {
    const modal = this.drawerService.create({
      nzContent: EmployerRegistryDetailsModalComponent,
      nzTitle: 'Detalles de registro patronal',
      nzWidth: 504,
      nzContentParams: {
        employerRegistry: this.employerRegistry
      }
    });

    modal.afterOpen.pipe(takeUntil(this.$onDestroy)).subscribe(() => {
      // @ts-ignore
      modal.getContentComponent().updateCertificates.subscribe((certificate: ClientCertificate | null) => {
        // @ts-ignore
        this.employerRegistry?.certificate = certificate;
      });
    });
  }

}
