import { Component, OnInit } from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {FestivityDaysService} from "../../../core/services/festivity-days.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {Color} from "../../../core/models/color";
import {ColorService} from "../../../core/services/color.service";
import {finalize} from "rxjs/operators";
import {FestivityDay} from "../../../core/models/festivity-day";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import {DatePipe} from "@angular/common";
import {TeamService} from "../../../core/services/team.service";
import {Team} from "../../../core/models/team";
import {User} from "../../../core/models/user";

@Component({
  selector: 'app-create-festivity-day-modal',
  templateUrl: './create-festivity-day-modal.component.html',
  styleUrls: ['./create-festivity-day-modal.component.scss']
})
export class CreateFestivityDayModalComponent implements OnInit {
  isLoadingTeams = true;
  newFestivityDayForm: FormGroup;
  isLoading = false;
  colors: Color[] = this.colorService.getColors();
  error: string | null;
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(new Date(), current) < 0;
  };
  nodes: any = [];

  constructor(private festivityDaysService: FestivityDaysService,
              private teamsService: TeamService,
              private colorService: ColorService,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              private notification: NzNotificationService,
              private datePipe: DatePipe,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.getTeams();
  }

  close() {
    this.modalRef.close(null);
  }

  createFestivityDay() {
    if (this.newFestivityDayForm.valid) {
      this.isLoading = true;
      const data = this.newFestivityDayForm.getRawValue();
      this.festivityDaysService.createFestivityDay(this.cleanData(data))
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((festivityDay: FestivityDay) => {
          this.notification.create('success', '¡Operación exitosa!','Día festivo creado correctamente');
          this.modalRef.close(festivityDay);
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al crear el día festivo', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelperService.markFormAsDirty(this.newFestivityDayForm);
    }
  }

  rangeChange($event: any) {
    this.newFestivityDayForm.controls.startDate.setValue($event[0]);
    this.newFestivityDayForm.controls.endDate.setValue($event[1]);
  }

  private createForm() {
    this.newFestivityDayForm = this.formBuilder.group({
      name: [null, Validators.required],
      colour: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      excludedEmployeesUids: [[]],
      dates: [null, Validators.required]
    });
  }

  private getTeams() {
    this.isLoadingTeams = true;
    this.teamsService.getTeamsMembers()
      .subscribe((teams: Team[]) => {
        this.createNodes(teams);
      }, error => {
        this.isLoadingTeams = false;
        this.error = error.message.replace("GraphQL error:", "").trim();
      });
  }

  private createNodes(teams: Team[]) {
    this.nodes = [];
    teams.forEach((team: Team, indexT: number) => {
      const leaves: any[] = [];
      team.members?.forEach((member: User, indexM: number) => {
        leaves.push({
          title: member.firstName + ' ' + member.lastName,
          value: member.uid,
          key: member.uid,
          isLeaf: true
        })
      });
      this.nodes.push({
        title: team.name,
        value: team.uid,
        key: team.uid,
        children: leaves
      });
    });
    this.createForm();
    this.isLoadingTeams = false;
  }

  private cleanData(data: any) {
    const form = data;
    form.startDate = this.datePipe.transform(form.startDate, 'yyyy-MM-dd');
    form.endDate = this.datePipe.transform(form.endDate, 'yyyy-MM-dd');
    const employeesUids: string[] = [];
    data.excludedEmployeesUids.forEach((key: string) => {
      const teamIndex = this.nodes.findIndex((node: any) => node.key === key);
      if (teamIndex !== -1) {
        this.nodes[teamIndex].children.forEach((leaf: any) => {
          employeesUids.push(leaf.key);
        });
      } else {
        employeesUids.push(key);
      }
    });
    form.excludedEmployeesUids = employeesUids;
    return form;
  }
}
