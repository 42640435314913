import {Component, Input, OnInit} from '@angular/core';
import {NonAttendance} from '../../../core/models/non-attendance';

@Component({
  selector: 'app-non-attendance-box',
  templateUrl: './non-attendance-box.component.html',
  styleUrls: ['./non-attendance-box.component.scss']
})
export class NonAttendanceBoxComponent implements OnInit {
  @Input() nonAttendance: NonAttendance;

  constructor() { }

  ngOnInit(): void {
  }

}
