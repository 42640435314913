import { Injectable } from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {AppealTable} from "../models/appeal-table";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Appeal} from "../models/appeal";

@Injectable({
  providedIn: 'root'
})
export class AppealsService {

  constructor(private apollo: Apollo) { }

  getAppealsTable(form: any): Observable<AppealTable> {
    const appealsTable  = gql`
      query appealsTable (
        $substring: String
        $teamUid: String
        $employeeUid: String
        $startDate: String!
        $endDate: String!
        $eventType: String
        $skip: Int
        $limit: Int
        $sort: String
        $order: String
      ) {
        appealsTable (
          substring: $substring
          teamUid: $teamUid
          employeeUid: $employeeUid
          startDate: $startDate
          endDate: $endDate
          eventType: $eventType
          skip: $skip
          limit: $limit
          sort: $sort
          order: $order
        ) {
          count
          appeals {
            uid
            numberId
            createdDate
            approved
            description
            issuedBy {
              uid
              firstName
              lastName
              photo {
                url
              }
            }
            attendance {
              uid
              onTime
              onLocation
              eventCategory
              createdDate
              timestamp
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{ appealsTable: AppealTable}>({
        query: appealsTable ,
        variables: {
          ...form
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.appealsTable;
        })
      );
  }

  acceptOrDenyAppeal(appealUid: string | undefined, status: boolean): Observable<Appeal | undefined> {
    const acceptOrDenyAppeal  = gql`
      mutation acceptOrDenyAppeal (
        $appealUid: String!
        $status: Boolean!
      ) {
        acceptOrDenyAppeal (
          appealUid: $appealUid
          status: $status
        ) {
          appeal {
            uid
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ acceptOrDenyAppeal: { appeal: Appeal }}>({
        mutation: acceptOrDenyAppeal ,
        variables: {
          appealUid,
          status
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.acceptOrDenyAppeal.appeal;
        })
      );
  }

  getAppeal(appealUid: string | undefined): Observable<Appeal> {
    const appeal  = gql`
      query appeal (
        $appealUid: String!
      ) {
        appeal (
          appealUid: $appealUid
        ) {
          uid
          approved
          approvedBy {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
          }
          rejectedBy {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
          }
          issuedBy {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
            geoFencings {
              name
              longitude
              latitude
              rangeMeters
              address {
                city
                colony
                country
                exteriorNumber
                interiorNumber
                latitude
                longitude
                state
                street
                zipcode
              }
            }
          }
          description
          evidences {
            uid
            url
            name
            extension
          }
          attendance {
            uid
            onTime
            onLocation
            eventCategory
            timestamp
            turnFromHour
            turnToHour
            longitude
            latitude
            createdDate
          }
        }
      }
    `;
    return this.apollo
      .query<{ appeal: Appeal}>({
        query: appeal ,
        variables: {
          appealUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.appeal;
        })
      );
  }
}
