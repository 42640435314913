<nz-spin [nzSpinning]="isLoading">
  <div class="bar-vertical-multi">
    <ngx-charts-bar-vertical-2d
          [scheme]="colorScheme"
          [results]="multi"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [legendTitle]="legendTitle"
          (select)="onSelect($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)">
    </ngx-charts-bar-vertical-2d>
  </div>
</nz-spin>
