import {Component, Input, OnInit} from '@angular/core';
import {File} from "../../../core/models/file";
import {IconService} from "../../../core/services/icon.service";
import {FileService} from "../../../core/services/file.service";
import {finalize} from "rxjs/operators";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-file-details',
  templateUrl: './file-details.component.html',
  styleUrls: ['./file-details.component.scss']
})
export class FileDetailsComponent implements OnInit {
  @Input() file: File;
  isDownloading = false;

  constructor(private iconService: IconService,
              private fileService: FileService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    if (this.file) {
      // @ts-ignore
      this.file?.extension = this.iconService.getMime(this.file?.extension);
    }
  }

  downloadFile(): void {
    this.isDownloading = true;
    // @ts-ignore
    this.fileService.downloadFile2(this.file?.url)
      .pipe(
        finalize(() => {
          this.isDownloading = false;
        })
      )
      .subscribe(
        data => {
          // @ts-ignore
          saveAs(data, (this.file?.name + '.' + this.file?.extension));
        },
        error => {
          this.notification.create(
            "error",
            "Error al descargar el archivo",
            error.message.replace("GraphQL error:", "").trim()
          );
        }
      );
  }

  openFile(): void {
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,left=450,top=100`;
    window.open(this.file?.url, '', params);
  }

}
