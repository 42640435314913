<div class="absence-request-modal">

  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
          nzType="error"
          [nzMessage]="error"
          nzShowIcon
    ></nz-alert>
  </div>

  <div class="status-row flex end">
    <div class="status">
      <div class="background green" *ngIf="absenceRequest?.approved"></div>

      <div class="background red" *ngIf="absenceRequest?.approved == false"></div>

      <div class="background yellow" *ngIf="absenceRequest?.approved == null"></div>

      <div class="label green" *ngIf="absenceRequest?.approved">
        Solicitud aprobada
      </div>

      <div class="label red" *ngIf="absenceRequest?.approved == false">
        Solicitud rechazada
      </div>

      <div class="label yellow" *ngIf="absenceRequest?.approved == null">
        Pendiente de aprobación
      </div>
    </div>
  </div>

  <div class="members-row flex wrap between">
    <div class="member-box">
      <div class="title">
        Solicitado por:
      </div>
      <div class="member-row flex align-center">
        <div class="member-photo">
          <nz-avatar [nzSize]="30"  [nzSrc]="member?.photo?.url || undefined"
                     [nzText]="member?.firstName?.charAt(0) + '' + member?.lastName?.charAt(0)"></nz-avatar>
        </div>

        <div class="member-data">
          <div class="name">
            {{member?.firstName | titlecase}} {{member?.lastName | titlecase}}
          </div>
          <div class="email">
            {{member?.email}}
          </div>
        </div>
      </div>
      <div class="remain-box">
        <div class="vacation-box">
          <span class="title">Días restantes de vacaciones: </span> {{absenceRequest?.employee?.remainingVacationDays || 0}}
        </div>
      </div>
    </div>
    <div class="member-box" *ngIf="absenceRequest?.approvedBy">
      <div class="title">
        Aprobado por:
      </div>
      <div class="member-row flex align-center">
        <div class="member-photo">
          <nz-avatar [nzSize]="35"  [nzSrc]="absenceRequest?.approvedBy?.photo?.url || undefined"
                     [nzText]="absenceRequest?.approvedBy?.firstName?.charAt(0) + '' + absenceRequest?.approvedBy?.lastName?.charAt(0)"></nz-avatar>
        </div>

        <div class="member-data">
          <div class="name">
            {{absenceRequest?.approvedBy?.firstName | titlecase}} {{absenceRequest?.approvedBy?.lastName | titlecase}}
          </div>
          <div class="email">
            {{absenceRequest?.approvedBy?.email}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="date-box flex between wrap">
    <div class="data">
      <div class="flex align-center">
        <div class="icon">
          <i nz-icon nzType="calendar" [ngStyle]="{color: absenceRequest?.absenceType?.colour}" nzTheme="fill"></i>
        </div>

        <div class="type" [ngStyle]="{color: absenceRequest?.absenceType?.colour}">
          {{absenceRequest?.absenceType?.name | titlecase}}
        </div>
      </div>

      <div class="label-row flex">
        <div class="label" *ngIf="absenceRequest?.absenceType?.paid">
          Ausencia pagada
        </div>
        <div class="label" *ngIf="absenceRequest?.absenceType?.vacation">
          Resta vacaciones
        </div>
      </div>
    </div>

    <div class="date flex wrap align-center">
      <div class="day-box flex column align-center center">
        <div class="day" [ngStyle]="{color: absenceRequest?.absenceType?.colour}">
          {{absenceRequest?.fromDate | date: 'dd'}}
        </div>
        <div class="month">
          {{(absenceRequest?.fromDate | date: 'MMM' | uppercase)?.replace('.', '')}}
        </div>
      </div>
      <div class="separator">
        >
      </div>
      <div class="day-box flex column center align-center">
        <div class="day" [ngStyle]="{color: absenceRequest?.absenceType?.colour}">
          {{absenceRequest?.toDate | date: 'dd'}}
        </div>
        <div class="month">
          {{(absenceRequest?.toDate | date: 'MMM' | uppercase)?.replace('.', '')}}
        </div>
      </div>
    </div>
  </div>

  <div class="request-data">
    <div class="data">
      <span class="title">Duración: </span> {{absenceRequest?.days}} día<span *ngIf="absenceRequest?.days != 1">s</span>
    </div>

    <div class="data">
      <span class="title">Fecha: </span> del {{absenceRequest?.fromDate | date: 'dd MMMM'}} al {{absenceRequest?.toDate | date: 'dd MMMM'}}
    </div>

    <div class="data" *ngIf="absenceRequest?.description">
      <span class="title">Descripción: </span> {{absenceRequest?.description}}
    </div>
  </div>

  <div class="evidences-column flex column">
    <div class="evidence" *ngFor="let evidence of absenceRequest?.evidences">
      <app-download-file-box [file]="evidence"></app-download-file-box>
    </div>
  </div>
</div>

<div class="drawer-footer ant-drawer-footer" *ngIf="absenceRequest?.approved == null || isOnTime">
  <div class="flex end wrap">
    <button nz-popconfirm *ngIf="absenceRequest?.approved == null || absenceRequest?.approved"
            nzDanger
            [nzLoading]="isLoading"
            nzPopconfirmTitle="¿Estas seguro que deseas rechazar esta solicitud?"
            (nzOnConfirm)="denyAbsenceRequest()"
            type="button" nz-button nzType="primary">Rechazar</button>
    <button nz-popconfirm  *ngIf="absenceRequest?.approved == null"
            [nzLoading]="isLoading"
            class="ant-btn-secondary"
            nzPopconfirmTitle="¿Estas seguro que deseas aprobar esta solicitud?"
            (nzOnConfirm)="approveAbsenceRequest()"
            type="button" nz-button nzType="primary">Aprobar</button>
  </div>
</div>
