import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ShellComponent} from './shared/components/shell/shell.component';
import {AuthenticatedGuard} from './core/guards/authenticated.guard';
import {NotAuthenticationGuard} from './core/guards/not-authenticated.guard';
import {IsAdminGuard} from './shared/guards/is-admin.guard';
import {IsLeaderGuard} from './shared/guards/is-leader.guard';

const routes: Routes = [
  {
    path: "session",
    canActivate: [NotAuthenticationGuard],
    loadChildren: () =>
        import("./modules/session/session.module").then(
            m => m.SessionModule
        )
  },
  {
    path: "",
    component: ShellComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: "home",
        loadChildren: () =>
            import("./modules/home/home.module").then(
                m => m.HomeModule
            ),
        data: {
          reuse: true,
        }
      },
      {
        path: "settings",
        loadChildren: () =>
            import("./modules/settings/settings.module").then(
                m => m.SettingsModule
            ),
        data: {
          reuse: true,
        }
      },
      {
        path: "billing",
        canActivate: [IsAdminGuard],
        loadChildren: () =>
            import("./modules/billing/billing.module").then(
                m => m.BillingModule
            ),
        data: {
          reuse: true,
        }
      },
      {
        path: "teams",
        loadChildren: () =>
            import("./modules/teams/teams.module").then(
                m => m.TeamsModule
            ),
        data: {
          reuse: true,
        }
      },
      {
        path: "members",
        canActivate: [IsLeaderGuard],
        loadChildren: () =>
            import("./modules/members/members.module").then(
                m => m.MembersModule
            ),
        data: {
          reuse: true,
        }
      },
      { path: "**", redirectTo: "home", pathMatch: "full" }
    ]
  },
  { path: "**", redirectTo: "", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRouting { }
