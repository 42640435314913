<form [formGroup]="vacationFormGroup">
  <nz-form-item>
    <nz-form-label>{{ vacationFormGroup?.controls?.label?.value }}</nz-form-label>
    <nz-form-control [nzErrorTip]="lastNameErrorTpl">
      <nz-input-number [nzPlaceHolder]="'# de días'" formControlName="days" [nzStep]="1" [OnlyNumber]="true" [nzFormatter]="formatter"
                       [nzParser]="parser" style="width: 100%"></nz-input-number>

      <ng-template #lastNameErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">
          Los días son requeridos
        </ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</form>
