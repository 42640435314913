import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from "../../../core/models/user";
import {Observable, Subject} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MemberService} from "../../../core/services/member.service";
import {AddressService} from "../../../core/services/address.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TeamService} from "../../../core/services/team.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {TimeZone} from "../../../core/models/time-zone";
import {TimezoneStore} from "../../../core/stores/timezone.store";
import {Country} from "../../../core/models/country";
import {CountryStore} from "../../../core/stores/country.store";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {Address} from "../../../core/models/address";
import {DatePipe} from "@angular/common";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import {ContractShift} from "../../../core/models/contract-shift";

@Component({
  selector: 'app-edit-member-general-data-modal',
  templateUrl: './edit-member-general-data-modal.component.html',
  styleUrls: ['./edit-member-general-data-modal.component.scss']
})
export class EditMemberGeneralDataModalComponent implements OnInit, OnDestroy {
  @Input() member: User | null;
  $onDestroy: Subject<void> = new Subject<void>();
  memberForm: FormGroup;
  isLoading = false;
  error: string | null;
  @Output() memberEdited: EventEmitter<User> = new EventEmitter();
  $timezones: Observable<TimeZone[] | undefined> = this.timezoneStore.getTimezones();
  countries$: Observable<Country[] | undefined> = this.countryStore.getCountries();
  zipcodeLoading: boolean = false;
  zipcodeError: boolean = false;
  colonies: any[] = [];
  addressLoading: boolean;
  addressError: string | null;
  contractChoices: ContractShift[] = [];
  shiftChoices: ContractShift[] = [];
  regimeChoices: ContractShift[] = [];
  isLoadingChoices = true;
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(new Date(), current) < 0;
  };
  regimeType: string = '';

  constructor(
      private memberService: MemberService,
      private addressService: AddressService,
      private teamService: TeamService,
      private timezoneStore: TimezoneStore,
      private countryStore: CountryStore,
      private formHelper: FormHelperService,
      private formBuilder: FormBuilder,
      private notification: NzNotificationService,
      private datePipe: DatePipe,
      private drawerRef: NzDrawerRef
  ) { }

  ngOnInit(): void {
    this.getContractChoices();
    this.getRegimeChoices();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  getImageControl(): FormControl {
    return this.memberForm.controls.photo as FormControl;
  }

  getTeamsControl(): FormControl {
    return this.memberForm.controls.assignedTeamsUid as FormControl;
  }

  getTeamControl(): FormControl {
    return this.memberForm.controls.teamUid as FormControl;
  }

  getAddressForm(): FormGroup {
    return this.memberForm.controls.addressData as FormGroup;
  }

  closeModal() {
    this.drawerRef.close();
  }

  submitForm() {
    if (this.memberForm.valid) {
      this.isLoading = true;
      this.error = null;

      this.memberService.updateMember(
        this.cleanData(this.memberForm.getRawValue())
      ).pipe(finalize(() => {
        this.isLoading = false;
      })).subscribe(
        (member: User | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Datos actualizados correctamente');
          // @ts-ignore
          this.memberEdited.emit(member);
          this.drawerRef.close();
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al actualizar la información', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.memberForm);
      this.formHelper.markGroupAsDirty(this.memberForm, 'addressData');
    }
  }

  cleanData(data: any): any {
    const form = data;
    const teams: string[] = [];
    form.assignedTeamsUid.forEach((team: any) => {
      teams.push(team.uid);
    });
    form.assignedTeamsUid = teams;
    form.teamUid = form.teamUid.length > 0 ? form.teamUid[0].uid : null;
    form.birthDate = form.birthDate ? this.datePipe.transform(form.birthDate, 'yyyy-MM-dd') : null;
    form.contractDate = form.contractDate ? this.datePipe.transform(form.contractDate, 'yyyy-MM-dd') : null;
    if (form.photo) {
      form.photo = form.photo.uid;
    }
    form.checksInWithTeam = !form.checksInWithTeam;
    if (form.curp) {
      form.curp = form.curp.toUpperCase();
    }
    return form;
  }

  private createForm(): void {
    this.memberForm = this.formBuilder.group({
      employeeUid: [this.member?.uid],
      firstName: [this.member?.firstName, Validators.required],
      lastName: [this.member?.lastName, Validators.required],
      email: [this.member?.email, [Validators.required, Validators.email]],
      birthDate: [this.member?.birthDate ?  this.setDate(this.member.birthDate) : null],
      phone: [this.member?.phone, [Validators.pattern(/\d{10}/), Validators.required]],
      assignedTeamsUid: [this.member?.assignedTeams ? this.member?.assignedTeams : []],
      teamUid: [this.member?.team ? [this.member?.team] : []],
      photo: [this.member?.photo],
      timezone: [this.member?.timezone, Validators.required],
      contractDate: [this.member?.contractDate ? this.setDate(this.member.contractDate): null, Validators.required],
      gender: [this.member?.gender, Validators.required],
      checksInWithTeam: [!this.member?.checksInWithTeam],
      rfc: [this.member?.rfc, Validators.pattern(
        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
      )],
      curp: [this.member?.curp, Validators.pattern(
        /([A-Za-z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HMhm](AS|as|BC|bc|BS|bs|CC|cc|CL|cl|CM|cm|CS|cs|CH|ch|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne)[A-Za-z]{3}[0-9A-Za-z]\d)$/
      )],
      contractType: [this.getContractType(), Validators.required],
      shiftType: [this.getShiftType(), Validators.required],
      taxRegimeUid: [this.getRegimeType(), Validators.required],
      socialSecurityNumber: [this.member?.socialSecurityNumber ? this.member.socialSecurityNumber : null],
      externalNumber: [this.member?.externalNumber ? this.member?.externalNumber : null],
      addressData: this.formBuilder.group({
        latitude: [0],
        longitude: [0],
        country: ['MX', [Validators.required]],
        state: [this.member?.address ? this.member?.address?.state : null, [Validators.required]],
        colony: [this.member?.address ? this.member?.address?.colony : null, [Validators.required]],
        city: [this.member?.address ? this.member?.address?.city : null, [Validators.required]],
        zipcode: [this.member?.address ? this.member?.address?.zipcode : null, [Validators.required]],
        street: [this.member?.address ? this.member?.address?.street : null, [Validators.required]],
        interiorNumber: [this.member?.address ? this.member?.address?.interiorNumber : null],
        exteriorNumber: [this.member?.address ? this.member?.address?.exteriorNumber : null, [Validators.required]]
      })
    });

    this.getAddressForm().controls.zipcode.valueChanges
      .pipe(
        takeUntil(this.$onDestroy),
        debounceTime(500)
      ).subscribe(zipcode => {
      if (zipcode && this.getAddressForm().controls.country.value) {
        this.getColonies(zipcode, this.getAddressForm().controls.country.value);
      }
    });

    this.getAddressForm().controls.country.valueChanges
      .pipe(
        takeUntil(this.$onDestroy)
      ).subscribe(country => {
      if (country && this.getAddressForm().controls.zipcode.value) {
        this.getColonies(this.getAddressForm().controls.zipcode.value, country);
      }
    });

    if (this.member?.address !== null) {
      this.getCountry();
    }
  }

  private getCountry() {
    this.countries$.subscribe(countries => {
      if (countries) {
        const index = countries.findIndex(c => c.countryName === this.member?.address?.country);
        if (index !== -1) {
          this.getAddressForm().controls.country.setValue(countries[index].countryCode);
        } else {
          this.getAddressForm().controls.country.setValue('MX');
        }
        if (this.member?.address !== null) {
          if (this.getAddressForm().controls.zipcode.value && this.getAddressForm().controls.country.value) {
            this.getColonies(this.getAddressForm().controls.zipcode.value, this.getAddressForm().controls.country.value);
          }
        }
      }
    });
  }

  private setAddress(address: Address | null) {
    if (address) {
      this.zipcodeError = false;
      this.colonies = address.colony;
      if (this.member?.address === null) {
        this.getAddressForm().controls.state.setValue(address.state);
        this.getAddressForm().controls.city.setValue(address.city);
        this.getAddressForm().controls.colony.setValue(address.colony[0]);
      } else {
        const index = address.colony.findIndex((c: string) => c === this.member?.address?.colony);
        if (index !== -1) {
          this.getAddressForm().controls.colony.setValue(address.colony[index]);
        } else {
          this.getAddressForm().controls.colony.setValue(address.colony[0]);
        }
      }
    } else {
      this.zipcodeError = true;
      this.colonies = [];
      this.getAddressForm().controls.colony.setValue(null);
      this.getAddressForm().controls.state.setValue(null);
      this.getAddressForm().controls.city.setValue(null);
    }
  }

  private getColonies(zipcode: string, country: string) {
    this.zipcodeLoading = true;
    this.zipcodeError = false;
    this.addressService.getAddressByZipcode(zipcode, country)
      .pipe(
        finalize(() => {
          this.zipcodeLoading = false;
        })
      )
      .subscribe(
        address => {
          this.setAddress(address);
        }
      )
  }

  private setDate(date: any): Date {
    const correctDate = new Date(date);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    return correctDate;
  }

  private getContractType() {
    const contract = this.contractChoices.find(c => c.description === this.member?.contractType);
    if (contract !== undefined) {
      return contract.id;
    }
    return null;
  }

  private getShiftType() {
    const shift = this.shiftChoices.find(s => s.description === this.member?.shiftType);
    if (shift !== undefined) {
      return shift.id;
    }
    return null;
  }

  private getRegimeType(): string {
    const regime = this.regimeChoices.find(r => r.uid === this.member?.taxRegime?.uid);
    return regime?.uid!;
  }

  private getContractChoices() {
    this.isLoadingChoices = true;
    this.memberService.getContractChoices()
      .subscribe((contractChoices: ContractShift[]) => {
        this.contractChoices = contractChoices;
        this.getShiftChoices();
      }, error => {
        this.isLoadingChoices = false;
        this.error = error;
      });
  }

  private getShiftChoices() {
    this.memberService.getShiftChoices()
      .pipe(finalize(() => {
        this.isLoadingChoices = false;
      }))
      .subscribe((shiftChoices: ContractShift[]) => {
        this.shiftChoices = shiftChoices;
        this.createForm();
      }, error => {
        this.error = error;
      });
  }

  private getRegimeChoices() {
    this.isLoadingChoices = true;

    this.memberService.getRegimeChoices().subscribe({
      next: (regimeChoices: ContractShift[]) => {
        this.regimeChoices = regimeChoices;
      },
      error: err => {
        this.isLoadingChoices = false;
        this.error = err;
      }
    });
  }
}
