import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {AnnouncementTable} from "../models/announcement-table";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {ActiveResourcesTableFilter} from "../models/active-resources-table-filter";
import {ActiveTable} from "../models/active-table";
import {ActiveResourceCategoryTable} from "../models/active-resource-category-table";
import {ActiveResourceCategory} from "../models/active-resource-category";
import {ActiveResources} from "../models/active-resources";
import {ActiveResourceLog} from "../models/active-resource-log";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class ActiveResourcesService {

  constructor(private apollo: Apollo,
              private authService: AuthenticationService,
              private http: HttpClient) { }

  getActiveResourcesTable(filters: ActiveResourcesTableFilter): Observable<ActiveTable> {
    return this.apollo.query<{activeResourcesTable: AnnouncementTable}>({
      query: gql`
        query activeResourcesTable(
          $categoryUid: String
          $unassigned: Boolean
          $substring: String
          $skip: Int
          $limit: Int
          $sort: String
          $order: String
        ) {
          activeResourcesTable(
            categoryUid: $categoryUid
            unassigned: $unassigned
            substring: $substring
            skip: $skip
            limit: $limit
            sort: $sort
            order: $order
          ) {
            count
            activeResources {
              uid
              name
              serial
              purchaseDate
              registrationDate
              lastAssigned
              description
              active
              photo {
                url
              }
              currentHolder {
                uid
                firstName
                lastName
                photo {
                  url
                }
                email
              }
              category {
                uid
                name
                color
              }
            }
          }
        }
      `,
      variables: {...filters}
    }).pipe(
      map(({data}) => {
        return data.activeResourcesTable;
      })
    );
  }

  getActiveResourceCategories(filters: any): Observable<ActiveResourceCategoryTable> {
    return this.apollo.query<{categoriesTable: ActiveResourceCategoryTable}>({
      query: gql`
        query categoriesTable(
          $substring: String
          $skip: Int
          $limit: Int
        ) {
          categoriesTable(
            substring: $substring
            skip: $skip
            limit: $limit
          ) {
            count
            categories {
              uid
              name
              color
            }
          }
        }
      `,
      variables: {...filters}
    }).pipe(
      map(({data}) => {
        return data.categoriesTable;
      })
    );
  }

  createActiveResourceCategory(color: string, name: string): Observable<ActiveResourceCategory> {
    return this.apollo.mutate<{createActiveResourceCategory: {category: ActiveResourceCategory}}>({
      mutation: gql`
        mutation createActiveResourceCategory(
          $color: String!
          $name: String!
        ) {
          createActiveResourceCategory(
            color: $color
            name: $name
          ) {
            category {
              uid
              name
              color
            }
          }
        }
      `,
      variables: {
        color,
        name
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.createActiveResourceCategory.category;
      })
    );
  }

  createActiveResource(form: any): Observable<ActiveResources> {
    return this.apollo.mutate<{createActiveResource: {resource: ActiveResources}}>({
      mutation: gql`
        mutation createActiveResource(
          $categoryUid: String!
          $description: String
          $employeeUid: String
          $name: String!
          $photoUid: String
          $purchaseDate: Date!
          $serial: String!
        ) {
          createActiveResource(
            categoryUid: $categoryUid
            description: $description
            employeeUid: $employeeUid
            name: $name
            photoUid: $photoUid
            purchaseDate: $purchaseDate
            serial: $serial
          ) {
            resource {
              uid
              name
              serial
              purchaseDate
              registrationDate
              lastAssigned
              description
              active
              photo {
                url
              }
              currentHolder {
                uid
                firstName
                lastName
                photo {
                  url
                }
                email
              }
              category {
                uid
                name
                color
              }
            }
          }
        }
      `,
      variables: {
       ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.createActiveResource.resource;
      })
    );
  }

  updateActiveResource(form: any): Observable<ActiveResources> {
    return this.apollo.mutate<{updateActiveResource: {resource: ActiveResources}}>({
      mutation: gql`
        mutation updateActiveResource(
          $resourceUid: String!
          $description: String
          $employeeUid: String
          $name: String
          $photoUid: String
          $purchaseDate: Date
          $serial: String
          $categoryUid: String
        ) {
          updateActiveResource(
            resourceUid: $resourceUid
            description: $description
            employeeUid: $employeeUid
            name: $name
            photoUid: $photoUid
            purchaseDate: $purchaseDate
            serial: $serial
            categoryUid: $categoryUid
          ) {
            resource {
              uid
              name
              serial
              purchaseDate
              registrationDate
              lastAssigned
              description
              active
              photo {
                url
              }
              currentHolder {
                uid
                firstName
                lastName
                photo {
                  url
                }
                email
              }
              category {
                uid
                name
                color
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateActiveResource.resource;
      })
    );
  }

  archiveActiveResource(resourceUid: string | undefined): Observable<string> {
    return this.apollo.mutate<{archiveActiveResource: {message: string}}>({
      mutation: gql`
        mutation archiveActiveResource($resourceUid: String!) {
          archiveActiveResource(resourceUid: $resourceUid) {
            message
          }
        }
      `,
      variables: {
        resourceUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.archiveActiveResource.message;
      })
    );
  }

  deleteActiveResource(resourceUid: string | undefined): Observable<string> {
    return this.apollo.mutate<{deleteActiveResource: {message: string}}>({
      mutation: gql`
        mutation deleteActiveResource($resourceUid: String!) {
          deleteActiveResource(resourceUid: $resourceUid) {
            message
          }
        }
      `,
      variables: {
        resourceUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deleteActiveResource.message;
      })
    );
  }

  getActiveResourceLogs(activeResourceUid: string | undefined): Observable<ActiveResourceLog[] | undefined> {
    return this.apollo.query<{activeResource: ActiveResources}>({
      query: gql`
        query activeResource($activeResourceUid: String!) {
          activeResource(activeResourceUid: $activeResourceUid) {
            logs {
              actionMessage
              assignmentDate
              acceptedAssignment
              createdAt
              actionDescription
              evidence {
                uid
                name
                extension
                url
              }
              relatedPerson {
                uid
                firstName
                lastName
                photo {
                  url
                }
              }
            }
          }
        }
      `,
      variables: {
        activeResourceUid
      }
    }).pipe(
      map(({data}) => {
        return data.activeResource.logs;
      })
    );
  }

  downloadFile(url: string, params: any = null): Observable<any> {
    const header = new HttpHeaders().set(
      'Authorization',
      'JWT ' + this.authService.credentials?.authToken,
    );
    header.set('content-type', 'application/json');
    return this.http.post(url, params, {
      responseType: 'blob',
      headers: header,
      observe: 'response'
    });
  }
}
