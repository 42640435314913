import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActiveResources} from "../../../core/models/active-resources";
import {ActiveResourcesService} from "../../../core/services/active-resources.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {Subject} from "rxjs";
import {finalize, takeUntil} from "rxjs/operators";
import {EditActiveResourceModalComponent} from "../../modals/edit-active-resource-modal/edit-active-resource-modal.component";
import {ActiveResourceHistoryModalComponent} from "../../modals/active-resource-history-modal/active-resource-history-modal.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {ChangeActiveResourceHolderModalComponent} from "../../modals/change-active-resource-holder-modal/change-active-resource-holder-modal.component";
import {UserStore} from "../../../core/stores/user.store";
import {User} from "../../../core/models/user";

@Component({
  selector: 'app-active-resource-row',
  templateUrl: './active-resource-row.component.html',
  styleUrls: ['./active-resource-row.component.scss']
})
export class ActiveResourceRowComponent implements OnInit, OnDestroy {
  @Input() activeResource: ActiveResources | null | undefined;
  isDeleting = false;
  isArchiving = false;
  $onDestroy: Subject<void> = new Subject<void>();
  @Output() removeActiveResource = new EventEmitter<ActiveResources>();
  @Output() updateActiveResource = new EventEmitter<void>();
  user: User;
  canDelete = false;

  constructor(private userStore: UserStore,
              private activeResourcesService: ActiveResourcesService,
              private notification: NzNotificationService,
              private modalService: NzModalService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.userStore.getUser()
      .pipe(takeUntil(this.$onDestroy))
      .subscribe(user => {
        if (user) {
          this.user = user;
          const index = this.user.availableModules?.findIndex(p => p === 'Eliminar Activos Fijos');
          this.canDelete = index !== -1;
        }
      });
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  editActiveResource() {
    const modal = this.drawerService.create({
      nzContent: EditActiveResourceModalComponent,
      nzTitle: 'Editar activo fijo',
      nzWidth: 504,
      nzContentParams: {
        // @ts-ignore
        activeResource: this.activeResource
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((activeResource: ActiveResources | null | undefined) => {
      if (activeResource !== undefined && activeResource !== null) {
        this.activeResource = activeResource;
      }
    });
  }

  archiveActiveResource() {
    this.isArchiving = true;
    this.activeResourcesService.archiveActiveResource(
      // @ts-ignore
      this.activeResource.uid)
      .pipe(finalize(() => {
        this.isArchiving = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', message);
        this.updateActiveResource.emit();
      }, error => {
        this.notification.create('error', 'Error al dar de baja el activo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  deleteActiveResource() {
    this.isDeleting = true;
    this.activeResourcesService.deleteActiveResource(
      // @ts-ignore
      this.activeResource.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', message);
        // @ts-ignore
        this.removeActiveResource.emit(this.activeResource);
      }, error => {
        this.notification.create('error', 'Error al eliminar el activo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  activeResourceHistory() {
    const modal = this.drawerService.create({
      nzContent: ActiveResourceHistoryModalComponent,
      nzTitle: 'Historial de activo fijo',
      nzWidth: 504,
      nzContentParams: {
        // @ts-ignore
        activeResource: this.activeResource
      }
    });
  }

  changeHolder() {
    const modal = this.modalService.create({
      nzTitle: 'Reasignar activo fijo',
      nzContent: ChangeActiveResourceHolderModalComponent,
      nzWidth: 500,
      nzFooter: null,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        activeResource: this.activeResource
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((active: ActiveResources | null | undefined) => {
      if (active !== undefined && active !== null) {
        // @ts-ignore
        this.removeActiveResource.emit(this.activeResource);
      }
    });
  }
}
