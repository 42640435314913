import {Component, Input, OnInit} from '@angular/core';
import {Help} from '../../../core/models/help';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {
  @Input() help: Help;

  constructor() { }

  ngOnInit(): void {
  }

}
