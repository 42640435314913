import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Color} from '../../../core/models/color';
import {Icon} from '../../../core/models/icon';
import {Team} from '../../../core/models/team';
import {ColorService} from '../../../core/services/color.service';
import {IconStore} from '../../../core/stores/icons.store';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {TimezoneStore} from '../../../core/stores/timezone.store';
import {TeamService} from '../../../core/services/team.service';
import {finalize} from 'rxjs/operators';
import {TimeZone} from '../../../core/models/time-zone';
import {Company} from "../../../core/models/company";
import {SlackChannel} from "../../../core/models/slack-channel";
import {CompanyService} from "../../../core/services/company.service";
import {IntegrationsService} from "../../../core/services/integrations.service";

@Component({
  selector: 'app-edit-team-modal',
  templateUrl: './edit-team-modal.component.html',
  styleUrls: ['./edit-team-modal.component.scss']
})
export class EditTeamModalComponent implements OnInit, OnDestroy {

  $onDestroy: Subject<void> = new Subject<void>();

  editTeamForm: FormGroup;

  @Input() team: Team;

  colors: Color[] = this.colorService.getColors();

  isLoading = false;
  error: string | null;

  compareIconFn = (o1: Icon, o2: Icon) => o1?.uid === o2?.uid;

  $icons: Observable<Icon[] | undefined> = this.iconsStore.getIcons();
  $timezones: Observable<TimeZone[] | undefined> = this.timezoneStore.getTimezones();

  @Output() teamEdited: EventEmitter<Team> = new EventEmitter();

  company: Company| null = null;
  isLoadingCompany = true;
  slackChannels: SlackChannel[] = [];

  constructor(
      private formBuilder: FormBuilder,
      private colorService: ColorService,
      private iconsStore: IconStore,
      private formHelper: FormHelperService,
      private drawerRef: NzDrawerRef,
      private timezoneStore: TimezoneStore,
      private teamService: TeamService,
      private companyService: CompanyService,
      private integrationsService: IntegrationsService
  ) { }

  ngOnInit(): void {
    this.integrationsService.getSlackChannels()
      .subscribe((slackChannels: SlackChannel[]) => {
        this.slackChannels = slackChannels;
        this.companyService.getCompanyIntegrations()
          .pipe(finalize(() => {
            this.isLoadingCompany = false;
          }))
          .subscribe((company: Company) => {
            this.company = company;
            this.createForm();
          });
      }, error => {
        this.isLoadingCompany = false;
        this.error = error;
      });
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  private createForm() {
    this.editTeamForm = this.formBuilder.group({
      teamUid: [this.team.uid],
      color: [this.team.color, [Validators.required]],
      icon: [this.team.icon, [Validators.required]],
      members: [this.team.members],
      name: [this.team.name, [Validators.required]],
      teamLeaders: [this.team.teamLeaders],
      geoFencings: [this.team.geoFencings],
      timezone: [this.team.timezone, Validators.required],
      slackChannelsUid: [this.getChannels()]
    });
  }

  submitForm() {
    this.error = null;
    if (this.editTeamForm.valid) {
      this.isLoading = true;
      this.teamService.editTeam(this.cleanData())
          .pipe(
              finalize(() => {
                this.isLoading = false;
              })
          )
          .subscribe(editedTeam => {
            this.teamEdited.next(editedTeam);
            this.closeModal();
          }, error => {
            this.error = error;
          })
    } else {
      this.formHelper.markFormAsDirty(this.editTeamForm);
    }
  }

  closeModal() {
    this.drawerRef.close();
  }

  getTeamLeadersFormControl() {
    return this.editTeamForm.controls.teamLeaders as FormControl;
  }

  getMemberFormControl() {
    return this.editTeamForm.controls.members as FormControl;
  }

  getGeoFencingsFormControl() {
    return this.editTeamForm.controls.geoFencings as FormControl;
  }

  private cleanData(): Team {
    const data: Team = this.editTeamForm.getRawValue();
    data.membersUid = data.members?.map(el => el.uid);
    delete data.members;
    data.teamLeadersUid = data.teamLeaders?.map(el => el.uid);
    delete data.teamLeaders;
    data.geoFencingsUid = data.geoFencings?.map(el => el.uid);
    delete data.geoFencings;
    data.iconUid = data.icon?.uid;
    delete data.icon;
    return data;
  }

  private getChannels() {
    const channels: any[] = [];
    this.team.slackChannels?.forEach((channel: SlackChannel) => {
      const index = this.slackChannels.findIndex(c => c.uid === channel.uid);
      if (index !== -1) {
        channels.push(channel.uid);
      }
    });
    return channels;
  }
}
