import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {GeoLocation} from "../../../core/models/geo-location";
import {MapTypeStyle} from "@agm/core/services/google-maps-types";
import {Cluster} from "@google/markerclustererplus/dist/cluster";
import {Team} from "../../../core/models/team";

@Component({
  selector: 'app-home-geo-locations-map',
  templateUrl: './home-geo-locations-map.component.html',
  styleUrls: ['./home-geo-locations-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeGeoLocationsMapComponent implements OnInit, OnChanges {
  @Input() selectedGeolocation: GeoLocation | null = null;
  @Input() geolocations: GeoLocation[];
  @Input() selectedTeamCluster: GeoLocation[];
  @Input() teams: Team[];
  @Output() selectedCluster = new EventEmitter<GeoLocation[]>();
  color: string | null = null;
  mapStyle: MapTypeStyle[] = [
    {
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    // for hidding elelmnts
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    }
  ];

  transparentIcon: any = {
    url: '../assets/images/empty.png',
    scaledSize: {
      width: 30,
      height: 30
    }
  };

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTeamCluster && this.selectedTeamCluster !== undefined && this.selectedTeamCluster.length > 0) {
      this.color = null;
      setTimeout(() => {
        this.color = '../assets/images/map-pins/map-cluster-pins/' + this.selectedTeamCluster[0].pinData.extra.employee.team.color?.replace('#', '') + '-m-';
      }, 0);
    }
  }

  clusterClick(cluster: Cluster) {
    const selectedCluster: GeoLocation[] = [];
    cluster.getMarkers().map((marker: any) => {
      selectedCluster.push(marker.getIcon().extra.geolocation);
    });
    this.selectedCluster.emit(selectedCluster);
  }
}
