import {Component, Input, OnInit} from '@angular/core';
import {IconService} from "../../../core/services/icon.service";
import {FileService} from "../../../core/services/file.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {finalize} from "rxjs/operators";
import {MemberService} from "../../../core/services/member.service";
import {User} from "../../../core/models/user";
import {EmployeeDocuments} from "../../../core/models/employee-documents";

@Component({
  selector: 'app-file-box',
  templateUrl: './file-box.component.html',
  styleUrls: ['./file-box.component.scss']
})
export class FileBoxComponent implements OnInit {
  @Input() employeeUid: string | null;
  @Input() name: string | undefined;
  @Input() type = 'member';
  @Input() file: EmployeeDocuments | undefined;
  @Input() folder: string;
  isUploading = false;
  isDeleting = false;
  isAccepting = false;
  isRejecting = false;
  isDownloading = false;

  constructor(private iconService: IconService,
              private fileService: FileService,
              private memberService: MemberService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    if (this.file?.document) {
      // @ts-ignore
      this.file?.document.icon = this.iconService.getMime(this.file?.document?.extension);
    } else {
      // @ts-ignore
      this.file?.document = {name: this.name, url: null};
    }
  }

  approveFile() {
    this.isAccepting = true;
    this.memberService.approveMemberDocument(this.file?.document?.uid, this.employeeUid)
      .pipe(finalize(() => {
        this.isAccepting = false;
      }))
      .subscribe((member: User | undefined) => {
        // @ts-ignore
        this.file?.approved = true;
      }, error => {
        this.notification.create(
          "error",
          "Error al aprobar el archivo",
          error.message.replace("GraphQL error:", "").trim()
        )
      });
  }

  uploadFile($event: any) {
    this.isUploading = true;
    const file: File = $event.target.files[0];
    const blob = file.slice(0, file.size, file.type);
    // @ts-ignore
    const newFile = new File([blob], file.name, { type: file.type });
    this.fileService
      .uploadImage(this.folder, newFile, this.name)
      .subscribe(
        (file: any) => {
          this.memberService.updateMemberDocument(file.uid, this.employeeUid)
            .pipe( finalize(() => {
              this.isUploading = false;
            }))
            .subscribe((message: string | undefined) => {
              file.icon = this.iconService.getMime(file.extension);
              // @ts-ignore
              this.file?.document = file;
              this.notification.create(
                "success",
                "¡Operación exitosa!",
                'El archivo se subió correctamente'
              );
            }, error => {
              this.notification.create(
                "error",
                "Error al subir el archivo",
                error.message.replace("GraphQL error:", "").trim()
              );
            });
        },
        error => {
          this.isUploading = false;
          this.notification.create(
            "error",
            "Error al subir el archivo",
            error.message.replace("GraphQL error:", "").trim()
          );
        }
      );
  }

  downloadFile(): void {
    this.isDownloading = true;
    // @ts-ignore
    this.fileService.downloadFile2(this.file?.document?.url)
      .pipe(
        finalize(() => {
          this.isDownloading = false;
        })
      )
      .subscribe(
        data => {
          // @ts-ignore
          saveAs(data, (this.file?.document?.name + this.file?.document?.extension));
        },
        error => {
          this.notification.create(
            "error",
            "Error al descargar el archivo",
            error.message.replace("GraphQL error:", "").trim()
          );
        }
      );
  }

  openFile(): void {
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,left=450,top=100`;
    window.open(this.file?.document?.url, '', params);
  }

  deleteFile() {
    this.isDeleting = true;
    this.memberService.deleteMemberDocument(this.file?.document?.uid)
      .pipe( finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string | undefined) => {
        // @ts-ignore
        this.file?.document = {name: this.name, url: null};
        this.notification.create(
          "success",
          "¡Operación exitosa!",
          'El archivo se subió correctamente'
        );
      }, error => {
        this.notification.create(
          "error",
          "Error al subir el archivo",
          error.message.replace("GraphQL error:", "").trim());
      });
  }

  unApproveFile() {
    this.isRejecting = true;
    this.memberService.unApproveMemberDocument(this.file?.document?.uid, this.employeeUid)
      .pipe(finalize(() => {
        this.isRejecting = false;
      }))
      .subscribe((member: User | undefined) => {
        // @ts-ignore
        this.file?.approved = false;
      }, error => {
        this.notification.create(
          "error",
          "Error al desaprobar el archivo",
          error.message.replace("GraphQL error:", "").trim()
        )
      });
  }
}
