<div class="team-circle team-icon-component" [ngStyle]="{
  backgroundColor: color,
  width: size + 'px',
  height: size + 'px'
  }">
  <div class="team-icon flex center align-center">
    <div class="img-box" *ngIf="!icon">
      <img alt="Team Icon" [src]="'../assets/images/team-icon.png'">
    </div>

    <div *ngIf="icon" class="icon-svg flex align-center">
      <app-svg *ngIf="type == 'url'" [size]="size" [src]="icon"></app-svg>
      <i [style]="'font-size: ' + size + 'px'" *ngIf="type == 'icon'" nz-icon [nzType]="icon" nzTheme="outline" [style.stroke]="icon === 'icons:payroll' ? 'white' : 'none'"></i>
    </div>
  </div>
</div>
