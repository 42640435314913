import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import {TimezoneService} from '../services/timezone.service';
import {TimeZone} from '../models/time-zone';

@Injectable({
  providedIn: 'root'
})
export class TimezoneStore {
  timezones$: Subject<TimeZone[] | undefined> = new ReplaySubject();
  timezones: TimeZone[] | undefined;

  isLoading$: BehaviorSubject<boolean>;
  isError$: BehaviorSubject<string | null>;

  constructor(
      private timezoneService: TimezoneService
  ) {
    if (!this.isLoading$) {
      this.isLoading$ = new BehaviorSubject(false) as BehaviorSubject<boolean>;
    }

    if (!this.isError$) {
      this.isError$ = new BehaviorSubject(null)  as BehaviorSubject<string | null>;
    }

    if (!this.timezones) {
      this.subscribeToGetTimezones();
    }
  }

  getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  getIsError(): Observable<string | null> {
    return this.isError$.asObservable();
  }

  private subscribeToGetTimezones(): void {
    this.timezoneService
        .getTimezones()
        .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
        )
        .subscribe(
            (timezones: TimeZone[] | undefined) => {
              this.timezones = timezones;
              this.timezones$.next(this.timezones);
            },
            (error: any) => {
              this.isError$.next(
                  error.message.replace("GraphQL error:", "").trim()
              );
            }
        );
  }

  getTimezones(): Observable<TimeZone[] | undefined> {
    return this.timezones$.asObservable();
  }
}
