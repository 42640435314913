<div class="help-row flex center wrap align-center">
  <div class="image" *ngIf="help?.image">
    <div class="img-box">
      <img [src]="'../assets/images/' + help?.image">
    </div>
  </div>

  <div class="description">
    {{help.description}}
  </div>
</div>
