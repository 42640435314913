<div class="active-resource-row flex align-center wrap between">

  <div class="active-container">
    <div class="box" *ngIf="activeResource?.photo">
      <div class="dot" [style.backgroundColor]="activeResource?.active ? activeResource?.currentHolder !== null ? 'rgb(252, 167, 43)' : '#64D27C' : '#FC6D4F'" [nz-tooltip]="activeResource?.active ? activeResource?.currentHolder !== null ? 'Activo asignado' : 'Activo disponible' : 'Activo desactivado'" [nzTooltipColor]="'white'"></div>
      <div class="img-box" [style.backgroundImage]="'url(' + activeResource?.photo?.url + ')'"></div>
    </div>

    <div class="box" *ngIf="!activeResource?.photo">
      <div class="dot" [style.backgroundColor]="activeResource?.active ? activeResource?.currentHolder !== null ? 'rgb(252, 167, 43)' : '#64D27C' : '#FC6D4F'" [nz-tooltip]="activeResource?.active ? activeResource?.currentHolder !== null ? 'Activo asignado' : 'Activo disponible' : 'Activo desactivado'" [nzTooltipColor]="'white'"></div>
      <div class="active-box" [style.backgroundColor]="activeResource?.category?.color">
        <i nz-icon nzType="icons:monitor"></i>
      </div>
    </div>

    <div class="active-name" (click)="activeResourceHistory()">
     {{ activeResource?.name }}
    </div>
  </div>

  <div class="sku-container">
    <b>SN:</b> {{ activeResource?.serial }}
  </div>


  <div class="category-container flex">
    <div class="category-box flex align-center">

      <div class="background" [ngStyle]="{backgroundColor: activeResource?.category?.color}"></div>

      <div class="icon">
        <i nz-icon nzType="icons:monitor" [ngStyle]="{color: activeResource?.category?.color}" nzTheme="fill"></i>
      </div>

      <div class="type" [ngStyle]="{color: activeResource?.category?.color}">
        {{ activeResource?.category?.name | titlecase }}
      </div>
    </div>
  </div>


  <div class="flex align-center holder" *ngIf="activeResource?.currentHolder !== null">
    <a [routerLink]="['/members/' + activeResource?.currentHolder?.uid]" class="member-photo router-label">
      <nz-avatar [nzSize]="30"  [nzSrc]="activeResource?.currentHolder?.photo?.url || undefined"
                 [nzText]="activeResource?.currentHolder?.firstName?.charAt(0) + '' + activeResource?.currentHolder?.lastName?.charAt(0)"></nz-avatar>
    </a>

    <div class="member-name flex column center">
      <a [routerLink]="['/members/' + activeResource?.currentHolder?.uid]" class="name router-label">
        {{activeResource?.currentHolder?.firstName | titlecase}} {{activeResource?.currentHolder?.lastName | titlecase}}
      </a>
      <div class="email">
        {{activeResource?.currentHolder?.email | lowercase}}
      </div>
    </div>
  </div>

  <div class="flex align-center holder" *ngIf="activeResource?.currentHolder === null">
    <div class="no-member">No Asignado</div>
  </div>


  <div class="date-container" style="white-space: nowrap">
    <b>Creado el:</b> {{ activeResource?.registrationDate | date: 'dd' }} de {{ (activeResource?.registrationDate | date: 'MMM') }} {{ activeResource?.registrationDate | date: 'yyyy' }}
  </div>


  <div class="actions-row flex end">
    <div class="icon-box" nz-tooltip="Editar" nzTooltipColor="white" nzTrigger="click" nz-dropdown [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="setting" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="activeResourceHistory()" [nzDisabled]="isDeleting || isArchiving"><i class="empty-icon grey" nz-icon nzType="icons:history" nzTheme="outline"></i> Historial de activo fijo</li>
        <li *ngIf="activeResource?.active" nz-menu-item (click)="editActiveResource()" [nzDisabled]="isDeleting || isArchiving"><i class="empty-icon grey" nz-icon nzType="icons:monitor" nzTheme="outline"></i> Editar activo fijo</li>
        <li *ngIf="activeResource?.active" nz-menu-item (click)="changeHolder()" [nzDisabled]="isDeleting || isArchiving"><i class="empty-icon grey" nz-icon nzType="icons:monitor" nzTheme="outline"></i> Reasignar activo fijo</li>
        <li *ngIf="activeResource?.active" nz-menu-item nz-popconfirm [nzDisabled]="isArchiving"
            nzPopconfirmTitle="¿Estas seguro que deseas dar de baja este activo fijo?"
            (nzOnConfirm)="archiveActiveResource()">
          <i *ngIf="!isArchiving" class="empty-icon" nz-icon nzType="stop" nzTheme="outline"></i>
          <i *ngIf="isArchiving" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Dar de baja el  activo fijo</li>
        <li *ngIf="canDelete" nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar este activo fijo?"
            (nzOnConfirm)="deleteActiveResource()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar activo fijo</li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
