import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EmployerRegistry} from "../../../core/models/employer-registry";
import {Deduction} from "../../../core/models/deduction";
import {Earning} from "../../../core/models/earning";
import {PayrollService} from "../../../core/services/payroll.service";
import {DeductionsService} from "../../../core/services/deductions.service";
import {EarningsService} from "../../../core/services/earnings.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {DeductionsTable} from "../../../core/models/deductions-table";
import {EarningTable} from "../../../core/models/earning-table";
import {Subject} from "rxjs";
import {EmployerRegistryService} from "../../../core/services/employer-registry.service";
import {DatePipe} from "@angular/common";
import {Payroll} from "../../../core/models/payroll";
import {ArrayValidator} from "../../../shared/validators/array.validator";

@Component({
  selector: 'app-new-payroll-modal',
  templateUrl: './new-payroll-modal.component.html',
  styleUrls: ['./new-payroll-modal.component.scss']
})
export class NewPayrollModalComponent implements OnInit, OnDestroy {
  payrollForm: FormGroup;
  isCreating = false;
  error: string | null;

  defaultDeductions: Deduction[] | undefined;
  defaultEarnings: Earning[] | undefined;
  isLoading = true;

  $onDestroy: Subject<void> = new Subject<void>();
  employerRegistries: EmployerRegistry[] = [];
  isLoadingEmployerRegistry = true;
  employerRegistriesIndex = 1;
  stopLoading = false;
  searchEmployerRegistry: null | string;
  $searchChange: Subject<string> = new Subject<string>();
  firstSearch = true;

  payrollTypes = [
    {name: 'Normal', value: 'N'},
    {name: 'Complemento', value: 'D'}
  ];
  categories = [
    {name: 'Mensual', value: 'M'},
    {name: 'Quincenal', value: 'Q'},
    {name: 'Catorcenal', value: 'C'},
    {name: 'Semanal', value: 'S'},
    {name: 'Decenal', value: 'D'}
  ];

  constructor(private payrollService: PayrollService,
              private deductionsService: DeductionsService,
              private earningsService: EarningsService,
              private employerRegistryService: EmployerRegistryService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private datePipe: DatePipe,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.loadMoreEmployerRegistries();
    this.subscribeToSearch();
    this.getDeductions();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();

    this.$searchChange.next();
    this.$searchChange.complete();
  }

  closeModal() {
    this.drawerRef.close();
  }

  getTeamsFormControl() {
    return this.payrollForm.controls.teamsUid as FormControl;
  }

  getDeductionsFormControl() {
    return this.payrollForm.controls.deductionsUid as FormControl;
  }

  getEarningsFormControl() {
    return this.payrollForm.controls.earningsUid as FormControl;
  }

  onEmployerRegistrySearch($event: string) {
    if (!this.firstSearch) {
      this.stopLoading = false;
      this.employerRegistries = [];
      this.searchEmployerRegistry = $event === "" ? null : $event;
      this.employerRegistriesIndex = 1;
      this.loadMoreEmployerRegistries();
    }
    this.firstSearch = false;
  }

  loadMoreEmployerRegistries() {
    if (!this.stopLoading) {
      this.isLoadingEmployerRegistry = true;
      this.employerRegistryService.getEmployerRegistries({
        substring: this.searchEmployerRegistry,
        skip: this.employerRegistriesIndex,
        limit: 10
      }).pipe(
        finalize(() => {
          this.isLoadingEmployerRegistry = false;
        })
      )
        .subscribe(employerRegistryTable => {
          if (employerRegistryTable?.employerRegistries?.length) {
            this.employerRegistries = [...this.employerRegistries, ...employerRegistryTable?.employerRegistries];
          } else {
            this.stopLoading = true;
          }
        });
      this.employerRegistriesIndex++;
    }
  }

  createPayroll() {
    if (this.payrollForm.valid) {
      this.isCreating = true;
      const data = this.payrollForm.getRawValue();
      this.payrollService.createPayroll(this.cleanData(data))
        .pipe(finalize(() => {
          this.isCreating = false;
        }))
        .subscribe((payroll: Payroll) => {
          this.notification.create('success', '¡Operación exitosa!', 'Nómina creada correctamente');
          this.drawerRef.close(payroll);
        }, error => {
          this.notification.create('error', 'Error al crear la nómina', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.payrollForm);
    }
  }

  onChangeRange($event: any) {
    if ($event.length === 2) {
      this.payrollForm.controls.startDate.setValue($event[0]);
      this.payrollForm.controls.endDate.setValue($event[1]);
    } else {
      this.payrollForm.controls.startDate.setValue(null);
      this.payrollForm.controls.endDate.setValue(null);
    }
  }

  private getDeductions() {
    this.isLoading = true;
    this.deductionsService.getDeductions({
      substring: null,
      skip: 1,
      limit: 1000
    }, true).subscribe((deductionsTable: DeductionsTable | undefined) => {
        this.defaultDeductions = deductionsTable?.deductions;
        this.getEarnings();
      }, error => {
        this.isLoading = false;
        this.error = error.message.replace("GraphQL error:", "").trim();
      });
  }

  private getEarnings() {
    this.isLoading = true;
    this.earningsService.getEarnings({
      substring: null,
      skip: 1,
      limit: 1000
    }, true)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((earningsTable: EarningTable | undefined) => {
        this.defaultEarnings = earningsTable?.earnings;
        this.createForm();
      }, error => {
        this.error = error.message.replace("GraphQL error:", "").trim();
      });
  }

  private createForm() {
    this.payrollForm = this.formBuilder.group({
      name: [null, Validators.required],
      employerRegistryUid: [null, Validators.required],
      payrollType: [null, Validators.required],
      temporalCategory: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      teamsUid: [[], ArrayValidator.ArrayContainMin(1)],
      deductionsUid: [[]],
      earningsUid: [[]],
      dateRange: [[], Validators.required]
    });
  }

  private subscribeToSearch() {
    this.$searchChange
      .pipe(takeUntil(this.$onDestroy), debounceTime(400))
      .subscribe(search => {
        this.onEmployerRegistrySearch(search);
      });
  }

  private cleanData(form: any) {
    const data = form;

    data.employerRegistryUid = data.employerRegistryUid.uid;

    data.startDate = this.datePipe.transform(data.startDate, 'yyyy-MM-dd');
    data.endDate = this.datePipe.transform(data.endDate, 'yyyy-MM-dd');

    const teams: string[] = [];
    data.teamsUid.forEach((team: any) => {
      teams.push(team.uid);
    });
    data.teamsUid = teams;

    const deductions: string[] = [];
    data.deductionsUid.forEach((deduction: any) => {
      deductions.push(deduction.uid);
    });
    data.deductionsUid = deductions;

    const earnings: string[] = [];
    data.earningsUid.forEach((earning: any) => {
      earnings.push(earning.uid);
    });
    data.earningsUid = earnings;

    data.taxableCategory = data.payrollType;

    delete data.payrollType;
    delete data.dateRange;

    return data;
  }
}
