<div class="add-credits-modal">

  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <ng-container *ngIf="isLoadingPaymentMethods">
    <nz-spin style="margin: 30px;"></nz-spin>
  </ng-container>

  <form nz-form nzLayout="vertical" [formGroup]="creditsForm" *ngIf="!isLoadingPaymentMethods && creditsForm">
    <nz-form-item>
      <nz-form-label>Cantidad a pagar</nz-form-label>
      <nz-form-control [nzErrorTip]="amountErrorTpl">

        <nz-input-number
          style="width: 100%"
          [OnlyNumber]="true"
          formControlName="amount"
          [nzMin]="1"
          [nzStep]="10"
          [nzFormatter]="formatter"
          [nzParser]="parser">
        </nz-input-number>

        <ng-template #amountErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            La cantidad es requerida
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Método de pago</nz-form-label>
      <nz-form-control [nzErrorTip]="paymentMethodErrorTpl">

        <nz-select formControlName="paymentMethodUid" [nzPlaceHolder]="'Método de pago'" [nzCustomTemplate]="optionsTemplate">
          <nz-option [nzValue]="{uid: 'new'}" [nzLabel]="'+ Nuevo método de pago'"></nz-option>
          <nz-option nzCustomContent [nzValue]="{uid: 'payment link'}" [nzLabel]="'Transferencia bancaria / SPEI'">
            <div class="payment-option-select">
              <div class="img-box">
                <img src="../assets/images/spei.png">
              </div>
              <label>Transferencia bancaria / SPEI</label>
            </div>
          </nz-option>
          <nz-option nzCustomContent
                     [nzLabel]="(method.brand | uppercase) + ' - ' + method.last4"
                     *ngFor="let method of paymentMethods"
                     [nzValue]="method">
            <div class="payment-option-select">
              <div class="img-box">
                <ng-container [ngSwitch]="method.brand">
                  <img *ngSwitchCase="'mastercard'" src="../assets/images/mastercard-c.png">
                  <img *ngSwitchCase="'visa'" src="../assets/images/visa-c.png">
                  <img *ngSwitchCase="'american_express'" src="../assets/images/american_express-c.png">
                  <img *ngSwitchDefault src="../assets/images/tc-card.png">
                </ng-container>
              </div>
              <label>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{{' ' + method.last4}}</label>
            </div>
          </nz-option>
        </nz-select>

        <ng-template #optionsTemplate let-selected>
          <div *ngIf="selected.nzValue.uid === 'new'">+ Nuevo método de pago</div>
          <div class="payment-option-select" *ngIf="selected.nzValue.uid === 'payment link'">
            <div class="img-box">
              <img src="../assets/images/spei.png">
            </div>
            <label>Transferencia bancaria / SPEI</label>
          </div>
          <div class="payment-option-select" *ngIf="selected.nzValue.uid !== 'payment link' && selected.nzValue.uid !== 'new'">
            <div class="img-box">
              <ng-container [ngSwitch]="selected.nzValue.brand">
                <img *ngSwitchCase="'mastercard'" src="../assets/images/mastercard-c.png">
                <img *ngSwitchCase="'visa'" src="../assets/images/visa-c.png">
                <img *ngSwitchCase="'american_express'" src="../assets/images/american_express-c.png">
                <img *ngSwitchDefault src="../assets/images/tc-card.png">
              </ng-container>
            </div>
            <label>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{{' ' + selected.nzValue.last4}}</label>
          </div>
        </ng-template>

        <ng-template #paymentMethodErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            El método de pago es requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="btns-container" *ngIf="!isLoadingPaymentMethods">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="closeModal()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="addPayment()">Pagar</button>
  </div>
</div>
