<div class="vacation-box flex wrap align-center">

  <ng-template #absenceModalTitle>
    <div class="flex align-center">
      <span *ngIf="absenceRequest.approved">Detalles de ausencia</span>
      <span *ngIf="!absenceRequest.approved">Solicitud de ausencia</span>
      <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'5'" nz-icon nzType="info-circle" nzTheme="fill"></i>
    </div>
  </ng-template>

  <div class="number flex align-center" (click)="openAbsenceRequest()">
    <i class="empty-icon" nz-icon nzType="icons:hourglass" nzTheme="outline"></i> <span class="label">Vacación</span> #{{absenceRequest.numberId}}
  </div>

  <div class="date">
    <span class="label">Desde:</span> {{absenceRequest.fromDate | date : 'dd MMMM yyyy'}}
  </div>

  <div class="date">
    <span class="label">Hasta:</span> {{absenceRequest.toDate | date : 'dd MMMM yyyy'}}
  </div>

  <div class="request flex end">
    <div class="request-box">
      <app-absence-request-box [absenceRequest]="absenceRequest" [member]="member || undefined"></app-absence-request-box>
    </div>
  </div>

</div>
