import {Component, Input, OnInit} from '@angular/core';
import {Team} from '../../../core/models/team';

@Component({
  selector: 'app-team-locations',
  templateUrl: './team-locations.component.html',
  styleUrls: ['./team-locations.component.scss']
})
export class TeamLocationsComponent implements OnInit {
  @Input() team: Team;

  constructor() { }

  ngOnInit(): void {
  }

}
