import { Injectable } from '@angular/core';
import {CompanyModules} from '../models/company-modules';
import {User} from '../models/user';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CompanyModulesService {

  companyModules: CompanyModules[] = [
    {
      id: "pr",
      name: "Nóminas",
      color: '#9596FF',
      icon: 'wallet',
    },
    {
      id: "ar",
      name: "Activos Fijos",
      color: '#76CF72',
      icon: 'laptop',
    },
    {
      id: "rar",
      name: "Eliminar Activos Fijos",
      color: '#B9CF72',
      icon: 'icons:no-actives',
    },
    {
      id: "an",
      name: "Anuncios",
      color: '#FFB180',
      icon: 'notification',
    },
    {
      id: "fd",
      name: "Días Festivos",
      color: '#95BAFF',
      icon: 'carry-out'
    },
    {
      id: "ab",
      name: "Solicitudes de Vacaciones",
      color: '#FFD131',
      icon: 'calendar',
    },
    {
      id: "me",
      name: "Juntas",
      color: '#41AF90',
      icon: 'icons:briefcase-outline',
    }
  ]

  constructor(
      private router: Router
  ) { }

  navigateSettings(user: User | null) {
    if (!user?.availableModules?.length || user.hasLeaderAccess) {
      this.router.navigate(['/home']).then();
    } else {
      // @ts-ignore
      switch (user.availableModules[0]) {
        case 'Nóminas':
          this.router.navigate(['/settings/positions']).then();
          break;
        case 'Activos Fijos':
          this.router.navigate(['/home/active-resources']).then();
          break;
        case 'Anuncios':
          this.router.navigate(['/home/announcements']).then();
          break;
        case 'Solicitudes de Vacaciones':
          this.router.navigate(['/settings/absence-types']).then();
          break;
        case 'Días Festivos':
          this.router.navigate(['/settings/festivity-days']).then();
          break;
        default:
          this.router.navigate(['/home']).then();
      }
    }
  }

  navigateHome(user: User | null) {
    if (!user?.availableModules?.length || user.hasLeaderAccess) {
      this.router.navigate(['/home']).then();
    } else {
      // @ts-ignore
      switch (user.availableModules[0]) {
        case 'Nóminas':
          this.router.navigate(['/home/payrolls']).then();
          break;
        case 'Activos Fijos':
          this.router.navigate(['/settings/active-resources']).then();
          break;
        case 'Anuncios':
          this.router.navigate(['/home/announcements']).then();
          break;
        case 'Solicitudes de Vacaciones':
          this.router.navigate(['/home/absence-requests']).then();
          break;
        case 'Días Festivos':
          this.router.navigate(['/settings/festivity-days']).then();
          break;
        default:
          this.router.navigate(['/home']).then();
      }
    }
  }

  navigateTeams(user: User | null) {
    if (!user?.availableModules?.length || user.hasLeaderAccess) {
      this.router.navigate(['/details']).then();
    } else {
      // @ts-ignore
      switch (user.availableModules[0]) {
        case 'Nóminas':
          this.router.navigate(['./payroll']).then();
          break;
        case 'Activos Fijos':
          this.router.navigate(['/settings/active-resources']).then();
          break;
        case 'Anuncios':
          this.router.navigate(['./announcements']).then();
          break;
        case 'Solicitudes de Vacaciones':
          this.router.navigate(['./absence-requests']).then();
          break;
        case 'Días Festivos':
          this.router.navigate(['/settings/festivity-days']).then();
          break;
        default:
          this.router.navigate(['./details']).then();
      }
    }
  }
}
