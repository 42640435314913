import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Announcement} from '../../../core/models/announcement';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Icon} from '../../../core/models/icon';
import {IconStore} from '../../../core/stores/icons.store';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {TeamService} from '../../../core/services/team.service';
import {AnnouncementService} from '../../../core/services/announcement.service';
import {DateHelperService} from '../../../core/services/date-helper.service';
import {finalize} from 'rxjs/operators';
import {DatePipe} from "@angular/common";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Company} from "../../../core/models/company";
import {CompanyService} from "../../../core/services/company.service";
import {IntegrationsService} from "../../../core/services/integrations.service";
import {SlackChannel} from "../../../core/models/slack-channel";

@Component({
  selector: 'app-edit-announcement-modal',
  templateUrl: './edit-announcement-modal.component.html',
  styleUrls: ['./edit-announcement-modal.component.scss']
})
export class EditAnnouncementModalComponent implements OnInit, OnDestroy {

  $onDestroy: Subject<void> = new Subject<void>();

  announcementForm: FormGroup;
  isLoading = false;
  error: string | null;

  @Input() announcement: Announcement;
  @Output() announcementEdited: EventEmitter<Announcement> = new EventEmitter();

  $icons: Observable<Icon[] | undefined> = this.iconsStore.getImages();
  compareIcons = (o1: Icon, o2: Icon) => o1?.uid === o2?.uid;

  company: Company| null = null;
  isLoadingCompany = true;
  slackChannels: SlackChannel[] = [];

  constructor(
      private iconsStore: IconStore,
      private formBuilder: FormBuilder,
      private formHelper: FormHelperService,
      private drawerRef: NzDrawerRef,
      private teamService: TeamService,
      private announcementService: AnnouncementService,
      private dateHelper: DateHelperService,
      private datePipe: DatePipe,
      private notification: NzNotificationService,
      private companyService: CompanyService,
      private integrationsService: IntegrationsService
  ) { }

  ngOnInit(): void {
    this.integrationsService.getSlackChannels()
      .subscribe((slackChannels: SlackChannel[]) => {
        this.slackChannels = slackChannels;
        this.companyService.getCompanyIntegrations()
          .pipe(finalize(() => {
            this.isLoadingCompany = false;
          }))
          .subscribe((company: Company) => {
            this.company = company;
            this.createForm();
          });
      }, error => {
        this.isLoadingCompany = false;
        this.error = error;
      });
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  private createForm() {
    this.announcementForm = this.formBuilder.group({
      announcementUid: [this.announcement.uid],
      addressedTeams: [this.announcement.addressedTeams],
      description: [this.announcement.description, [Validators.required]],
      dateRange: [[this.dateHelper.cleanDate(this.announcement.startDate), this.dateHelper.cleanDate(this.announcement.endDate)], [Validators.required]],
      files: [this.announcement.files],
      icon: [this.announcement.icon, [Validators.required]],
      mainImage: [this.announcement.mainImage],
      title:  [this.announcement.title, [Validators.required]],
      publicationDate: [this.announcement.publicationDate !== null ? this.isPublicationDate() : null, this.announcement.publicationDate !== null ? (this.isPublicationDate() !== null ? Validators.required : []) : []],
      publicationHour: [{value: this.announcement.publicationHour !== null ? this.isPublicationHour() : null, disabled: this.isPublicationHour() === null}, this.announcement.publicationHour !== null ? (this.isPublicationHour() !== null ? Validators.required : []) : []],
      sendByEmail: [this.announcement.sendByEmail],
      // @ts-ignore
      sendBySlack: [this.announcement.slackChannels?.length > 0],
      slackChannelsUid: [this.getChannels()]
    });
  }

  private isPublicationDate(): Date | null {
    const pDate = this.setDate(this.announcement.publicationDate);
    const pDateString = this.datePipe.transform(pDate, 'yyyy-MM-dd');
    if (pDateString === this.announcement.startDate && this.announcement.publicationHour === '07:00') {
      return null;
    }
    return pDate;
  }

  private setDate(day: string): Date {
    const correctDate = new Date(day);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    return correctDate;
  }

  private isPublicationHour(): Date | null {
    if (this.announcement.publicationHour === '07:00') {
      return null;
    }
    return this.announcement.publicationHour === null ? null : this.setHour(this.announcement.publicationHour);
  }

  private setHour(hour: any): Date {
    const date = new Date();
    const split = hour.split(':');
    date.setHours(+(split[0].replace(' ', '')));
    date.setMinutes(+(split[1].replace(' ', '')));
    return date;
  }

  getImageControl(): FormControl {
    return this.announcementForm.controls.mainImage as FormControl;
  }

  getTeamsControl(): FormControl {
    return this.announcementForm.controls.addressedTeams as FormControl;
  }

  submitForm() {
    this.error = null;
    if (this.announcementForm.valid) {
      this.isLoading = true;
      this.announcementService.editAnnouncement(this.cleanData())
          .pipe(
              finalize(() => {
                this.isLoading = false;
              })
          )
          .subscribe(editedAnnouncement => {
            this.notification.create('success', '¡Operación exitosa!', 'Anuncio actualizado correctamente');
            this.announcementEdited.emit(editedAnnouncement);
            this.closeModal();
          }, error => {
            this.error = error;
          })
    } else {
      this.formHelper.markFormAsDirty(this.announcementForm);
    }
  }

  closeModal() {
    this.drawerRef.close();
  }

  private cleanData(): Announcement {
    const data: any = this.announcementForm.getRawValue();
    data.filesUid = data.files?.map((el: any) => el.uid);
    delete data.files;

    data.addressedTeamsUid = data.addressedTeams?.map((el: any) => el.uid);
    delete data.addressedTeams;

    data.mainImageUid = data.mainImage?.uid;
    delete data.mainImage;

    data.iconUid = data.icon?.uid;
    delete data.icon;

    data.startDate = this.dateHelper.formatDate(data.dateRange[0]);
    data.endDate = this.dateHelper.formatDate(data.dateRange[1]);
    delete data.dateRange;

    if (data.publicationDate !== null) {
      data.publicationDate = this.datePipe.transform(data.publicationDate, 'yyyy-MM-dd');
    } else {
      data.publicationDate = data.startDate;
    }

    if (data.publicationHour !== null) {
      data.publicationHour = this.datePipe.transform(data.publicationHour, 'HH:mm') || '';
    } else {
      data.publicationHour = '07:00';
    }

    if (!data.sendBySlack) {
      data.slackChannelsUid = [];
    }
    delete data.sendBySlack;

    return data;
  }

  onNewFileUploaded($event: any) {
    this.announcementForm.controls.files.setValue([...this.announcementForm.controls.files.value, $event]);
  }

  onFileDeleted($event: any) {
    this.announcementForm.controls.files.setValue(this.announcementForm.controls.files.value.filter((file: { uid: any; }) => file.uid !== $event.uid));
  }

  updateValidators($event: any) {
    if ($event === null) {
      this.announcementForm.controls.publicationDate.clearValidators();
      this.announcementForm.controls.publicationHour.setValue(null);
      this.announcementForm.controls.publicationHour.disable();
      this.announcementForm.controls.publicationHour.clearValidators();
    }
    if ($event !== null) {
      this.announcementForm.controls.publicationDate.setValidators([Validators.required]);
      if (this.announcementForm.controls.publicationHour.value === null) {
        this.announcementForm.controls.publicationHour.setValue(null);
        this.announcementForm.controls.publicationHour.enable();
        this.announcementForm.controls.publicationHour.setValidators([Validators.required]);
      }
    }
    this.announcementForm.controls.publicationDate.updateValueAndValidity();
    this.announcementForm.controls.publicationHour.updateValueAndValidity();
  }

  private getChannels() {
    const channels: any[] = [];
    this.announcement.slackChannels?.forEach((channel: SlackChannel) => {
      const index = this.slackChannels.findIndex(c => c.uid === channel.uid);
      if (index !== -1) {
        channels.push(channel.uid);
      }
    });
    return channels;
  }

}
