<div class="file-box flex between align-center">

  <i nzTooltipColor="white"
     *ngIf="canDelete"
     [nz-tooltip]="'Eliminar archivo'" (click)="deleteFile()" class="close-icon" nz-icon [nzTwotoneColor]="'#FF8484'" nzType="close-circle" nzTheme="twotone"></i>

  <div class="icon" *ngIf="file?.mime">
    <i nz-icon [nzType]="'icons:' + file?.mime"></i>
  </div>

  <div class="name stretch">
    {{file.name | titlecase}}
  </div>

  <div class="icons-row flex end">

    <ng-container *ngIf="!isLoading">
      <div class="action-icon" (click)="downloadFile()">
        <i nz-icon nzType="cloud-download" nzTheme="outline"></i>
      </div>
      <div class="action-icon" (click)="openFile();">
        <i nz-icon nzType="eye" nzTheme="outline"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <nz-spin nzSize="small"></nz-spin>
    </ng-container>
  </div>
</div>
