import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bar-vertical-multi',
  templateUrl: './bar-vertical-multi.component.html',
  styleUrls: ['./bar-vertical-multi.component.scss']
})
export class BarVerticalMultiComponent implements OnInit {

  @Input() multi: any[] = [
    {
      "name": "Semana 1",
      "series": [
        {
          "name": "Check in",
          "value": 20
        },
        {
          "name": "Check out",
          "value": 12
        }
      ]
    },
    {
      "name": "Semana 2",
      "series": [
        {
          "name": "Check in",
          "value": 33
        },
        {
          "name": "Check out",
          "value": 48
        }
      ]
    },
    {
      "name": "Semana 3",
      "series": [
        {
          "name": "Check in",
          "value": 4
        },
        {
          "name": "Check out",
          "value": 25
        }
      ]
    },
    {
      "name": "Semana 4",
      "series": [
        {
          "name": "Check in",
          "value": 12
        },
        {
          "name": "Check out",
          "value": 31
        }
      ]
    },
    {
      "name": "Semana 5",
      "series": [
        {
          "name": "Check in",
          "value": 23
        },
        {
          "name": "Check out",
          "value": 8
        }
      ]
    },
  ];
  @Input() isLoading: boolean;

  // options
  @Input() showXAxis: boolean = true;
  @Input() showYAxis: boolean = true;
  @Input() showLegend: boolean = true;
  @Input() xAxisLabel: string = 'Semanas';
  @Input() yAxisLabel: string = 'Número de check in/out';
  @Input() legendTitle: string | null = null;
  @Input() legendPosition: any = 'below';
  @Input() colorScheme: any = {
    domain: ['#5EE2BC', '#FAD151']
  };

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
