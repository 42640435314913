<div class="appeal-details-modal-component">
  <div class="title-container">
    <div class="title-box">
      <div class="title">Detalle de aclaración</div>

      <div class="time-content">
        <div class="turn-box">
          <ng-container>
            <div class="time-box active" [ngClass]="{
            'red': appeal?.approved !== null && !appeal?.approved,
            'yellow': appeal?.approved === null,
            'green': appeal?.approved !== null && appeal?.approved
            }">
              <span>{{ (appeal?.approved !== null && !appeal?.approved) ? 'Rechazada' : (appeal?.approved !== null && appeal?.approved) ? 'Aprobada' : 'Pendiente' }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <i class="close" nz-icon nzType="close" (click)="close()"></i>
  </div>

  <div class="appeal-body">
    <nz-spin [nzSpinning]="isLoading" *ngIf="isLoading"></nz-spin>

    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <nz-spin [nzSpinning]="addressLoading" *ngIf="!isLoading">
      <div class="map-row" *ngIf="!isLoading">
        <app-attendance-map [pin]="marker" [geoFencings]="userGeofencings"></app-attendance-map>
      </div>
    </nz-spin>

    <div class="attendance-block" *ngIf="!isLoading">

      <div class="attendance-title">Registro de {{ appeal?.attendance?.eventCategory | lowercase }}</div>

      <div class="attendance-info-box">
        <div class="attendance-time-box">
          <div class="time">Fecha: <span class="subtime">{{ appeal?.attendance?.createdDate | date: 'dd' }} de {{ appeal?.attendance?.createdDate | date: 'MMM' }} {{ appeal?.attendance?.createdDate | date: 'yyyy' }}</span></div>
          <div class="time">Turno: <span class="subtime">{{ appeal?.attendance?.turnFromHour }} - {{ appeal?.attendance?.turnToHour }}</span></div>
          <div class="time">Hora registrada: <span class="subtime">{{ (appeal?.attendance?.correctTimestamp | date:'HH:mm') || '-'}}</span></div>
        </div>

        <div class="status-box">
          <div class="status">
            <div class="background green" *ngIf="appeal?.attendance !== null && appeal?.attendance?.onTime"></div>

            <div class="background yellow" *ngIf="appeal?.attendance !== null && !appeal?.attendance?.onTime && appeal?.attendance?.onLocation"></div>

            <div class="background red" *ngIf="!appeal?.attendance?.onTime && !appeal?.attendance?.onLocation"></div>

            <div class="label green" *ngIf="appeal?.attendance !== null && appeal?.attendance?.onTime">
              En tiempo
            </div>

            <div class="label yellow" *ngIf="appeal?.attendance !== null && !appeal?.attendance?.onTime && appeal?.attendance?.onLocation">
              Fuera de tiempo
            </div>

            <div class="label red" *ngIf="!appeal?.attendance?.onTime && !appeal?.attendance?.onLocation">
              Fuera de tiempo
            </div>
          </div>

          <div class="status">
            <div class="background green" *ngIf="appeal?.attendance !== null && appeal?.attendance?.onLocation"></div>

            <div class="background yellow" *ngIf="appeal?.attendance !== null && !appeal?.attendance?.onLocation && appeal?.attendance?.onTime"></div>

            <div class="background red" *ngIf="!appeal?.attendance?.onTime && !appeal?.attendance?.onLocation"></div>

            <div class="label green" *ngIf="appeal?.attendance !== null && appeal?.attendance?.onLocation">
              En ubicación
            </div>

            <div class="label yellow" *ngIf="appeal?.attendance !== null && !appeal?.attendance?.onLocation && appeal?.attendance?.onTime">
              Fuera de ubicación
            </div>

            <div class="label red" *ngIf="!appeal?.attendance?.onTime && !appeal?.attendance?.onLocation">
              Fuera de ubicación
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="geofencings-container" *ngIf="!isLoading">
      <div class="geofencings-title">Geocercas permitidas</div>

      <div class="address-container">
        <div class="address-row" *ngFor="let geofencing of appeal?.issuedBy?.geoFencings">
          <div class="img-box">
            <img [src]="'../assets/images/pin-1.png'" [alt]="'pin-icon'">
          </div>
          <div class="address">{{ geofencing?.address?.fullAddress || 'Sin dirección registrada' }}</div>
        </div>
      </div>
    </div>

    <div class="line-border" *ngIf="!isLoading"></div>

    <div class="appeal-status" *ngIf="!isLoading">
      <i nz-icon nzType="icons:shapes" [ngClass]="{
            'red': appeal?.approved !== null && !appeal?.approved,
            'yellow': appeal?.approved === null,
            'green': appeal?.approved !== null && appeal?.approved
            }">
      </i>
      <div class="status-title">Aclaración</div>
    </div>

    <div class="members-row flex wrap between" *ngIf="!isLoading">
      <div class="member-box">
        <div class="title">
          Solicitado por:
        </div>
        <div class="member-row flex align-center">
          <div class="member-photo">
            <nz-avatar [nzSize]="25"  [nzSrc]="appeal?.issuedBy?.photo?.url || undefined"
                       [nzText]="appeal?.issuedBy?.firstName?.charAt(0) + '' + appeal?.issuedBy?.lastName?.charAt(0)"></nz-avatar>
          </div>

          <div class="member-data">
            <div class="name">
              {{appeal?.issuedBy?.firstName | titlecase}} {{appeal?.issuedBy?.lastName | titlecase}}
            </div>
            <div class="email">
              {{appeal?.issuedBy?.email}}
            </div>
          </div>
        </div>
      </div>

      <div class="member-box" *ngIf="appeal?.approved !== null && appeal?.approvedBy">
        <div class="title end-2">
          Aprobado por:
        </div>
        <div class="member-row flex align-center">
          <div class="member-photo">
            <nz-avatar [nzSize]="25"  [nzSrc]="appeal?.approvedBy?.photo?.url || undefined"
                       [nzText]="appeal?.approvedBy?.firstName?.charAt(0) + '' + appeal?.approvedBy?.lastName?.charAt(0)"></nz-avatar>
          </div>

          <div class="member-data">
            <div class="name">
              {{appeal?.approvedBy?.firstName | titlecase}} {{appeal?.approvedBy?.lastName | titlecase}}
            </div>
            <div class="email">
              {{appeal?.approvedBy?.email}}
            </div>
          </div>
        </div>
      </div>

      <div class="member-box" *ngIf="appeal?.approved !== null && appeal?.rejectedBy">
        <div class="title end-2">
          Rechazado por:
        </div>
        <div class="member-row flex align-center">
          <div class="member-photo">
            <nz-avatar [nzSize]="25"  [nzSrc]="appeal?.rejectedBy?.photo?.url || undefined"
                       [nzText]="appeal?.rejectedBy?.firstName?.charAt(0) + '' + appeal?.rejectedBy?.lastName?.charAt(0)"></nz-avatar>
          </div>

          <div class="member-data">
            <div class="name">
              {{appeal?.rejectedBy?.firstName | titlecase}} {{appeal?.rejectedBy?.lastName | titlecase}}
            </div>
            <div class="email">
              {{appeal?.rejectedBy?.email}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="description-container" *ngIf="!isLoading">
      <div>Descripción: <span class="description">{{ appeal?.description || 'Sin descripción' }}</span></div>
    </div>

    <div class="file-container" *ngIf="!isLoading">
      <ng-container *ngFor="let file of appeal?.evidences">
        <app-file-details [file]="file"></app-file-details>
      </ng-container>
    </div>

  </div>
</div>

<div class="drawer-footer ant-drawer-footer" *ngIf="!isLoading && appeal?.approved === null">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzDanger
            [nzLoading]="isLoadingApproveDeny"
            nzPopconfirmTitle="¿Estas seguro que deseas rechazar esta solicitud?"
            (nzOnConfirm)="approveDenyRequest(false)"
            type="button" nz-button nzType="primary">Rechazar</button>
    <button nz-popconfirm
            [nzLoading]="isLoadingApproveDeny"
            class="ant-btn-secondary"
            nzPopconfirmTitle="¿Estas seguro que deseas aprobar esta solicitud?"
            (nzOnConfirm)="approveDenyRequest(true)"
            type="button" nz-button nzType="primary">Aprobar</button>
  </div>
</div>
