import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeoLocation} from "../../../core/models/geo-location";
import {Team} from "../../../core/models/team";
import {User} from "../../../core/models/user";

@Component({
  selector: 'app-home-geo-locations-card',
  templateUrl: './home-geo-locations-card.component.html',
  styleUrls: ['./home-geo-locations-card.component.scss']
})
export class HomeGeoLocationsCardComponent implements OnInit {
  @Input() allUsersLocations: GeoLocation[];
  @Input() teamLocations: Team[];
  @Output() selectedLocation = new EventEmitter<GeoLocation | null>();
  @Output() selectedTeamLocation = new EventEmitter<Team | null>();
  @Output() clearCluster = new EventEmitter<void>();
  showMore = false;
  selectedLocationActive = false;

  constructor() { }

  ngOnInit(): void {}


  locationClick(index: number, selected: boolean) {
    if (!this.teamLocations[index].selected) {
      // @ts-ignore
      this.teamLocations[index].members.forEach(member => {
        member.selected = false;
      });
      this.teamLocations.forEach(team => {
        team.selected = false
        team.selectedMember = false;
      });
      this.teamLocations[index].selected = !selected;
      if (this.teamLocations[index].selected) {
        this.selectedLocationActive = true;
        this.selectedLocation.emit(null);
        this.selectedTeamLocation.emit(this.teamLocations[index]);
      }
    }
  }

  setSelectedMember(member: User, teamIndex: number, setMemberSelected: boolean) {
    if (setMemberSelected) {
      // @ts-ignore
      this.teamLocations[teamIndex].members.forEach(member => {
        member.selected = false;
      });
      member.selected = true;
      this.teamLocations[teamIndex].selectedMember = true;
    }
    this.selectedLocation.emit(member?.lastLocation);
  }

  cleanSelected() {
    this.selectedLocationActive = false;
    this.teamLocations.forEach(team => {
      team.selected = false
      team.selectedMember = false;
      team.members?.forEach(member => {
        member.selected = false;
      });
    });
    this.selectedTeamLocation.emit(null);
  }

  cleanTeamSelected(index: number) {
    // @ts-ignore
    this.teamLocations[index].members.forEach(member => {
      member.selected = false;
    });
    this.teamLocations[index].selected = true;
    this.teamLocations[index].selectedMember = false;
    this.selectedLocationActive = true;
    this.selectedLocation.emit(null);
    this.selectedTeamLocation.emit(this.teamLocations[index]);
  }

  returnSelected(teamIndex: number) {
    // @ts-ignore
    const index = this.teamLocations[teamIndex].members.findIndex(m => m.selected === true);
    if (index !== -1) {
      // @ts-ignore
      this.selectedLocation.emit(this.teamLocations[teamIndex].members[index]?.lastLocation);
    }
  }
}
