import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-team-icon',
  templateUrl: './team-icon.component.html',
  styleUrls: ['./team-icon.component.scss']
})
export class TeamIconComponent implements OnInit, OnChanges {
  @Input() color: string | undefined = '#D8D8D8';
  @Input() icon : string | undefined;
  @Input() size : number | undefined = 60;
  @Input() type : string = 'url';

  constructor() { }

  ngOnInit(): void {
    if (this.color?.charAt(0) !== '#') {
      this.color = '#' + this.color;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.color = this.color || '#D8D8D8';
    if (this.color?.charAt(0) !== '#') {
      this.color = '#' + this.color;
    }
    this.size = this.size || 60;
  }

}
