<nz-spin [nzSpinning]="isLoading">
  <div class="google-row flex align-center between wrap">
    <div class="name-container">
      <div class="img-box">
        <img [src]="'../assets/images/meet.png'" alt="google-logo">
      </div>

      <div class="name">Google Meets</div>
    </div>

    <div class="description-box">
      <div class="text" *ngIf="!userIntegrations?.googleCalendarActive">Integra Google Meets con tu cuenta de Nommi y podrás automatizar tus juntas.</div>
      <div class="user-box" *ngIf="userIntegrations?.googleCalendarActive">
        <nz-avatar style="min-width: 26px" [nzSize]="26" [nzSrc]="userIntegrations?.googleToken?.profilePictureUrl"
                   [nzText]="userIntegrations?.googleToken?.name">
        </nz-avatar>

        <div class="info-cont">
          <div class="text-info">{{ userIntegrations?.googleToken?.name }}</div>
          <div class="text-info">{{ userIntegrations?.googleToken?.email }}</div>
        </div>
      </div>
    </div>

    <div class="buttons-container">

      <button class="login-with-google-btn" [disabled]="isLoading" (click)="signIn()" *ngIf="!userIntegrations?.googleCalendarActive">
        <span>Iniciar sesión con Google</span>
      </button>

      <button *ngIf="userIntegrations?.googleCalendarActive"
              class="default" nz-button nzType="default"
              nz-popconfirm="¿Seguro que deseas quitar esta integración?"
              (nzOnConfirm)="signOut()">
        Cerrar sesión
      </button>

      <div class="tag flex align-center" *ngIf="userIntegrations?.googleCalendarActive">
        <div class="background"></div>
        Integrado
      </div>
    </div>
  </div>
</nz-spin>
