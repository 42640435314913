import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeductionConcept} from "../../../core/models/deduction-concept";
import {Deduction} from "../../../core/models/deduction";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Color} from "../../../core/models/color";
import {Observable, Subject} from "rxjs";
import {Icon} from "../../../core/models/icon";
import {DeductionsService} from "../../../core/services/deductions.service";
import {ColorService} from "../../../core/services/color.service";
import {IconStore} from "../../../core/stores/icons.store";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {CurrencyPipe} from "@angular/common";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-edit-deduction-modal',
  templateUrl: './edit-deduction-modal.component.html',
  styleUrls: ['./edit-deduction-modal.component.scss']
})
export class EditDeductionModalComponent implements OnInit, OnDestroy {
  @Input() deductionConcepts: DeductionConcept[];
  @Input() deduction: Deduction | null;
  $onDestroy: Subject<void> = new Subject<void>();
  editDeductionForm: FormGroup;
  isUpdating = false;
  error: string | null;
  colors: Color[] = this.colorService.getColors();
  icons: Icon[] | undefined;
  formatter = (value: number) => `${this.currencyPipe.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");

  constructor(private deductionsService: DeductionsService,
              private colorService: ColorService,
              private iconsStore: IconStore,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private currencyPipe: CurrencyPipe,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.iconsStore.getIcons().pipe(takeUntil(this.$onDestroy)).subscribe(icons => {
      this.icons = icons;
      this.createForm();
    });
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  close() {
    this.drawerRef.close(null);
  }

  updateDeduction() {
    if (this.editDeductionForm.valid) {
      this.isUpdating = true;
      const data = this.editDeductionForm.getRawValue();
      data.iconUid = data.iconUid.uid;
      data.conceptCode = data.conceptCode.id;
      this.deductionsService.updateDeduction(data)
        .pipe(finalize(() => {
          this.isUpdating = false;
        }))
        .subscribe((deduction: Deduction) => {
          this.notification.create('success', '¡Operación exitosa!', 'Deducción actualizada correctamente');
          this.drawerRef.close(deduction);
        }, error => {
          this.notification.create('error', 'Error al actualizar la deducción', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.editDeductionForm);
    }
  }

  private createForm() {
    this.editDeductionForm = this.formBuilder.group({
      amount: [this.deduction?.amount, Validators.required],
      colour: [this.deduction?.colour, Validators.required],
      conceptCode:[this.getCode(), Validators.required],
      iconUid: [this.getIcon(), Validators.required],
      name: [this.deduction?.name, Validators.required],
      deductionUid: [this.deduction?.uid]
    });
  }

  private getCode() {
    const index = this.deductionConcepts?.findIndex(d => d.id === this.deduction?.conceptCode);
    if (index !== -1) {
      return this.deductionConcepts[index];
    } else {
      return null;
    }
  }

  private getIcon() {
    const index = this.icons?.findIndex(i => i.uid === this.deduction?.icon?.uid);
    if (index !== -1) {
      // @ts-ignore
      return this.icons[index];
    } else {
      return null;
    }
  }

}
