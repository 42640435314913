import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Turn} from '../../../core/models/turn';
import {User} from '../../../core/models/user';
import {takeUntil} from 'rxjs/operators';
import {Attendance} from '../../../core/models/attendance';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {AttendanceModalComponent} from '../../modals/attendance-modal/attendance-modal.component';
import {Subject} from 'rxjs';
import {TitleCasePipe} from '@angular/common';

@Component({
  selector: 'app-turn-box',
  templateUrl: './turn-box.component.html',
  styleUrls: ['./turn-box.component.scss']
})
export class TurnBoxComponent implements OnInit, OnDestroy {
  @Input() turn: Turn;
  @Input() member: User;
  @Input() isPastDate = false;
  @Input() isToday = false;

  $onDestroy: Subject<void> = new Subject<void>();

  constructor(
      private drawerService: NzDrawerService,
      private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void {
    if (this.turn.checkinAtt?.timestamp) {
      this.turn.checkinAtt.correctTimestamp = new Date(this.turn.checkinAtt.timestamp.slice(0,-6));
    }
    if (this.turn.checkoutAtt?.timestamp) {
      this.turn.checkoutAtt.correctTimestamp = new Date(this.turn.checkoutAtt.timestamp.slice(0,-6));
    }
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  getCheckinLabel() {
    if (this.turn.checkinAtt?.onTime && this.turn.checkinAtt?.onLocation) {
      return 'Check in correcto';
    } else {
      if (!this.turn.checkinAtt?.onTime && !this.turn.checkinAtt?.onLocation) {
        return 'Check in fuera de tiempo y ubicación';
      } else {
        if (!this.turn.checkinAtt?.onTime) {
          return 'Check in fuera de tiempo';
        } else {
          return 'Check in fuera de ubicación';
        }
      }
    }
  }

  getCheckoutLabel() {
    if (this.turn.checkoutAtt?.onTime && this.turn.checkoutAtt?.onLocation) {
      return 'Check out correcto';
    } else {
      if (!this.turn.checkoutAtt?.onTime && !this.turn.checkoutAtt?.onLocation) {
        return 'Check out fuera de tiempo y ubicación';
      } else {
        if (!this.turn.checkoutAtt?.onTime) {
          return 'Check out fuera de tiempo';
        } else {
          return 'Check out fuera de ubicación';
        }
      }
    }
  }

  openAttendance(attendance: Attendance | undefined) {
    const modal = this.drawerService.create({
      nzContent: AttendanceModalComponent,
      nzContentParams: {
        member: this.member,
        turn: this.turn,
        attendance: attendance
      },
      nzTitle: 'Información del ' + this.titleCasePipe.transform(attendance?.eventCategory),
      nzWidth: 504
    });

    modal.afterOpen.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(() => {

    })
  }

  isPastHourFrom() {
    if (this.isPastDate && !this.isToday) {
      return true;
    } else if (this.isToday) {
      const today = new Date();
      const hour = today.getHours().toString();
      const minutes = today.getMinutes().toString();
      // @ts-ignore
      const checkHour = this.turn?.fromHour?.split(':')[0];
      // @ts-ignore
      const checkMinutes = this.turn?.fromHour?.split(':')[1];
      // @ts-ignore
      if (hour >= checkHour) {
        // @ts-ignore
        return minutes > checkMinutes;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isPastHourTo() {
    if (this.isPastDate && !this.isToday) {
      return true;
    } else if (this.isToday) {
      const today = new Date();
      const hour = today.getHours().toString();
      const minutes = today.getMinutes().toString();
      // @ts-ignore
      const checkHour = this.turn?.toHour?.split(':')[0];
      // @ts-ignore
      const checkMinutes = this.turn?.toHour?.split(':')[1];
      // @ts-ignore
      if (hour >= checkHour) {
        // @ts-ignore
        return minutes > checkMinutes;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
