import { Injectable } from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor() { }

  // TODO Check update value because it changes the pin location

  markGroupAsDirty(form: FormGroup, group: string): void {
    for (const i in (form.controls[group] as FormGroup).controls) {
      (form.controls[group] as FormGroup).controls[i].markAsDirty();
      (form.controls[group] as FormGroup).controls[i].updateValueAndValidity();
    }
  }

  markFormAsDirty(form: FormGroup): void {
    for (const i in form.controls) {
      form.controls[i].markAsDirty();
      form.controls[i].markAsTouched();
      form.controls[i].updateValueAndValidity();
    }
  }

  markFormArrayAsDirty(formArray: FormArray): void {
    const length = formArray.getRawValue().length;
    for (let i = 0; i < length; i++) {
      this.markFormAsDirty(formArray.at(i) as FormGroup);
    }
  }
}
