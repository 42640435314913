import {NgModule} from '@angular/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {merge} from 'rxjs';
import {Title} from '@angular/platform-browser';



@NgModule()
export class TitleModule{

  constructor(
      private titleService: Title,
      private router: Router,
      private activatedRoute: ActivatedRoute
  ) {
    const onNavigationEnd = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    );

    merge(onNavigationEnd)
        .pipe(
            map(() => {
              let route = this.activatedRoute;
              while (route.firstChild) {
                route = route.firstChild;
              }
              return route;
            }),
            filter(route => route.outlet === "primary"),
            mergeMap(route => route.data)
        )
        .subscribe(event => {
          const title = event.title;
          if (title) {
            this.titleService.setTitle(title + ' - Nommi');
          }
        });
  }
}
