import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {FestivityDay} from "../models/festivity-day";
import {FestivityDayTable} from "../models/festivity-day-table";
import {FestivityDaysTableFilter} from "../models/festivity-days-table-filter";
import {DateHelperService} from "./date-helper.service";

@Injectable({
  providedIn: 'root'
})
export class FestivityDaysService {

  constructor(private apollo: Apollo,
              private dateHelper: DateHelperService) { }

  getFestivityDays(): Observable<FestivityDay[]> {
    return this.apollo.query<{festivityDays: FestivityDay[]}>({
      query: gql`
        query festivityDays {
          festivityDays {
            uid
            numberId
            name
            colour
            startDate
            endDate
          }
        }
      `
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.festivityDays;
      })
    );
  }

  getFestivityDaysTable(filters: FestivityDaysTableFilter): Observable<FestivityDayTable> {
    if (filters.startDate)
      filters.startDate = this.dateHelper.formatDate(filters.startDate);
    if (filters.endDate)
      filters.endDate = this.dateHelper.formatDate(filters.endDate);
    return this.apollo.query<{festivityDaysTable: FestivityDayTable}>({
      query: gql`
        query festivityDaysTable(
          $startDate: String!
          $endDate: String!
          $substring: String
          $skip: Int
          $limit: Int
        ) {
          festivityDaysTable(
            startDate: $startDate
            endDate: $endDate
            substring: $substring
            skip: $skip
            limit: $limit
          ) {
            count
            festivityDays {
              uid
              numberId
              name
              colour
              startDate
              endDate
              excludedEmployees {
                uid
              }
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.festivityDaysTable;
      })
    );
  }

  createFestivityDay(form: any): Observable<FestivityDay> {
    return this.apollo.mutate<{createFestivityDay: { festivityDay: FestivityDay }}>({
      mutation: gql`
        mutation createFestivityDay(
            $colour: String!
            $endDate: Date!
            $name: String!
            $startDate: Date!
            $excludedEmployeesUids: [String]
        ) {
          createFestivityDay(
            colour: $colour
            endDate: $endDate
            name: $name
            startDate: $startDate
            excludedEmployeesUids: $excludedEmployeesUids
          ) {
            festivityDay {
              uid
              numberId
              name
              colour
              startDate
              endDate
              excludedEmployees {
                uid
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.createFestivityDay.festivityDay;
      })
    );
  }

  deleteFestivityDays(festivityDayUid: string | undefined): Observable<string> {
    return this.apollo.mutate<{deleteFestivityDay: { message: string }}>({
      mutation: gql`
        mutation deleteFestivityDay($festivityDayUid: String!) {
          deleteFestivityDay(festivityDayUid: $festivityDayUid) {
            message
          }
        }
      `,
      variables: {
        festivityDayUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deleteFestivityDay.message;
      })
    );
  }

  updateFestivityDay(form: any): Observable<FestivityDay> {
    return this.apollo.mutate<{updateFestivityDay: { festivityDay: FestivityDay }}>({
      mutation: gql`
        mutation updateFestivityDay(
          $festivityDayUid: String!
          $colour: String!
          $endDate: Date!
          $name: String!
          $startDate: Date!
          $excludedEmployeesUids: [String]
        ) {
          updateFestivityDay(
            festivityDayUid: $festivityDayUid
            colour: $colour
            endDate: $endDate
            name: $name
            startDate: $startDate
            excludedEmployeesUids: $excludedEmployeesUids
          ) {
            festivityDay {
              uid
              numberId
              name
              colour
              startDate
              endDate
              excludedEmployees {
                uid
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateFestivityDay.festivityDay;
      })
    );
  }
}
