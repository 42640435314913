import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from "../../../core/models/user";
import {Subject} from "rxjs";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize} from "rxjs/operators";
import {MemberService} from "../../../core/services/member.service";
import {Schedule} from "../../../core/models/schedule";
import {Turn} from "../../../core/models/turn";

@Component({
  selector: 'app-edit-member-schedule-modal',
  templateUrl: './edit-member-schedule-modal.component.html',
  styleUrls: ['./edit-member-schedule-modal.component.scss']
})
export class EditMemberScheduleModalComponent implements OnInit, OnDestroy {
  @Input() member: User | null;
  $onDestroy: Subject<void> = new Subject<void>();
  memberForm: FormGroup;
  isLoading = false;
  error: string | null;
  @Output() memberEdited: EventEmitter<User> = new EventEmitter();

  constructor(private memberService: MemberService,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private nzDrawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  closeModal() {
    this.nzDrawerRef.close();
  }

  getFormGroup(): FormGroup {
    return this.memberForm.controls.scheduleData as FormGroup;
  }

  getDayFormGroup(day: string): FormGroup {
    return this.getFormGroup().get(day) as FormGroup;
  }

  getFormArray(day: string): FormArray {
    return (this.getFormGroup().get(day) as FormGroup).get('turnsData') as FormArray;
  }

  addTurn(day: string) {
    // @ts-ignore
    this.member?.schedule[day].turns.forEach((turn: Turn) => {
      this.getFormArray(day).push(
        this.formBuilder.group({
          fromHour: [null, Validators.required],
          toHour: [null, Validators.required]
        })
      );
    });
  }

  cleanData(form: any) {
    const data = form;
    data.scheduleData.monday = this.cleanDay(data, 'monday');
    data.scheduleData.tuesday = this.cleanDay(data, 'tuesday');
    data.scheduleData.wednesday = this.cleanDay(data, 'wednesday');
    data.scheduleData.thursday = this.cleanDay(data, 'thursday');
    data.scheduleData.friday = this.cleanDay(data, 'friday');
    data.scheduleData.saturday = this.cleanDay(data, 'saturday');
    data.scheduleData.sunday = this.cleanDay(data, 'sunday');
    return data;
  }

  cleanDay(data: any, day: string) {
    if (data.scheduleData[day].turnsData.length === 0) {
      data.scheduleData[day].active = false;
    } else {
      data.scheduleData[day].active = true;
      data.scheduleData[day].turnsData.forEach((turn: any) => {
        turn.fromHour = turn.fromHour.hour;
        turn.toHour = turn.toHour.hour;
      });
    }
    return data.scheduleData[day];
  }

  updateSchedule() {
    if (this.memberForm.valid) {
      this.isLoading = true;
      this.memberService.updateMemberSchedule(this.cleanData(this.memberForm.getRawValue()))
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((schedule: Schedule | undefined) => {
          this.notification.create('success', '¡Operación exitosa!', 'Horario actualizado correctamente');
          // @ts-ignore
          this.member?.schedule = schedule;
          // @ts-ignore
          this.memberEdited.emit(this.member);
          this.nzDrawerRef.close();
        }, error => {
          this.notification.create('error', 'Error al actualizar el horario', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      // general group data
      this.formHelper.markFormAsDirty(this.memberForm);
      // general day group data
      this.formHelper.markFormAsDirty(this.getDayFormGroup('monday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('tuesday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('wednesday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('thursday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('friday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('saturday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('sunday'));
      // day form group array data
      this.formHelper.markFormArrayAsDirty(this.getFormArray('monday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('tuesday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('wednesday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('thursday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('friday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('saturday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('sunday'));
    }
  }

  private createForm() {
    this.memberForm = this.formBuilder.group({
      employeeUid: [this.member?.uid],
      scheduleData: this.formBuilder.group({
        monday: this.setFormDefault('monday'),
        tuesday: this.setFormDefault('tuesday'),
        wednesday: this.setFormDefault('wednesday'),
        thursday: this.setFormDefault('thursday'),
        friday: this.setFormDefault('friday'),
        saturday: this.setFormDefault('saturday'),
        sunday: this.setFormDefault('sunday')
      })
    });

    this.setDefaultDays();
  }

  private setFormDefault(day: string) {
    return this.formBuilder.group({
      // @ts-ignore
      active: [this.member?.schedule ? this.member.schedule[day].active : true],
      // @ts-ignore
      category: [this.member?.schedule ? (this.member.schedule[day].category === 'Remoto' ? 'R' : 'I') : 'I'],
      turnsData: this.formBuilder.array([])
    });
  }

  private setDefaultDays() {
    this.addTurn('monday');
    this.addTurn('tuesday');
    this.addTurn('wednesday');
    this.addTurn('thursday');
    this.addTurn('friday');
    this.addTurn('saturday');
    this.addTurn('sunday');
  }

}
