import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from "../../../core/models/user";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {MemberService} from "../../../core/services/member.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {finalize} from "rxjs/operators";
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-edit-member-salary',
  templateUrl: './edit-member-salary.component.html',
  styleUrls: ['./edit-member-salary.component.scss']
})
export class EditMemberSalaryComponent implements OnInit {
  @Input() member: User | null;
  memberForm: FormGroup;
  isLoading = false;
  error: string | null;
  hours = [5, 7, 7.5, 8];
  @Output() salaryEdited: EventEmitter<User> = new EventEmitter();
  // @ts-ignore
  formatter = (value: number) => `${this.currencyPipe.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");

  constructor(private memberService: MemberService,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private currencyPipe: CurrencyPipe,
              private nzModuleRef: NzModalRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  closeModal() {
    this.nzModuleRef.close();
  }

  updateSalary() {
    if (this.memberForm.valid) {
      this.isLoading = true;
      const data = this.memberForm.getRawValue();
      data.salaryData = {
        hoursWorked: data.hoursWorked,
        imssPaidAmount: data.imssPaidAmount,
        paidAmount: data.newPaidAmount
      };
      delete data.hoursWorked;
      delete data.imssPaidAmount;
      delete data.newPaidAmount;
      this.memberService.updateMemberSalary(data)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((member: User | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Salario actualizado correctamente');
          // @ts-ignore
          this.salaryEdited.emit(member);
          this.nzModuleRef.close();
        }, error => {
            // this.error = error.message.replace("GraphQL error:", "").trim();
            this.notification.create('error', 'Error al actualizar el salario', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.memberForm);
    }
  }

  private createForm() {
    this.memberForm = this.formBuilder.group({
      employeeUid: [this.member?.uid],
      newPaidAmount: [this.member?.currentSalary?.paidAmount, [Validators.required]],
      hoursWorked: [this.member?.currentSalary?.hoursWorked || this.hours[this.hours.length - 1], Validators.required],
      imssPaidAmount:[this.member?.currentSalary?.imssPaidAmount || 0, Validators.required],
    });
  }

}
