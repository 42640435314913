import {Component, Input, OnInit} from '@angular/core';
import {Paycheck} from "../../../core/models/paycheck";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PayrollService} from "../../../core/services/payroll.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {CurrencyPipe} from "@angular/common";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {EarningsService} from "../../../core/services/earnings.service";
import {Earning} from "../../../core/models/earning";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-edit-paycheck-earnings-modal',
  templateUrl: './edit-paycheck-earnings-modal.component.html',
  styleUrls: ['./edit-paycheck-earnings-modal.component.scss']
})
export class EditPaycheckEarningsModalComponent implements OnInit {
  @Input() paycheck: Paycheck | null;
  paycheckForm: FormGroup;
  isLoading = false;
  total = 0;
  formatter = (value: number) => `${this.currency.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");

  constructor(private payrollService: PayrollService,
              private earningsService: EarningsService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private currency: CurrencyPipe,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  close() {
    this.drawerRef.close(null);
  }

  getEarningArray() {
    return this.paycheckForm.controls.earningsData.value as Earning[];
  }

  getEarningsFormControl() {
    return this.paycheckForm.controls.earningsData as FormControl;
  }


  setTotal() {
    setTimeout(() => {
      let total = 0;
      this.paycheckForm.controls?.earningsData?.value?.forEach((earning: Earning, index: number) => {
        // @ts-ignore
        total += (+earning.editWorth) || 0;
        if (earning.editWorth === '') {
          this.paycheckForm.controls.earningsData.value[index].editWorth = 0;
        }
      });
      this.total = total;
    }, 500);
  }

  updateEarnings() {
    if (this.paycheckForm.valid) {
      this.isLoading = true;
      const data = this.paycheckForm.getRawValue();
      this.payrollService.updatePaycheckEarnings(this.cleanData(data))
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((paycheck: Paycheck) => {
          this.notification.create('success', '¡Operación exitosa!', 'Percepciones actualizadas correctamente');
          this.drawerRef.close(paycheck);
        }, error => {
          this.notification.create('error', 'Error al editar las percepciones', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.paycheckForm);
    }
  }

  private createForm() {
    this.paycheckForm = this.formBuilder.group({
      paycheckUid: [this.paycheck?.uid, Validators.required],
      earningsData: [this.paycheck?.earnings, [Validators.required]]
    });

    this.setTotal();
  }

  private cleanData(data: any) {
    const form = data;
    const tempArr: any[] = [];
    form.earningsData.forEach((item: any) => {
      tempArr.push({
        conceptUid: item.uid,
        worth: item.editWorth
      })
    });
    form.earningsData = tempArr;
    return form;
  }
}
