import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Subject, Observable } from 'rxjs';

import { Company } from '../../../core/models/company';
import { Country } from '../../../core/models/country';
import { CompanyService } from '../../../core/services/company.service';
import { AddressService } from '../../../core/services/address.service';
import { CountryStore } from '../../../core/stores/country.store';
import { FormHelperService } from '../../../core/services/form-helper.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { finalize, takeUntil, debounceTime } from 'rxjs/operators';
import { Address } from '../../../core/models/address';
import { ContractShift } from '../../../core/models/contract-shift';

@Component({
  selector: 'app-edit-company-fiscal-data',
  templateUrl: './edit-company-fiscal-data.component.html',
  styleUrls: ['./edit-company-fiscal-data.component.scss']
})
export class EditCompanyFiscalDataComponent implements OnInit, OnDestroy {
  @Input() company: Company | null;
  @Output() companyEdited: EventEmitter<Company> = new EventEmitter();

  colonies: any[] = [];
  companyForm!: FormGroup;
  countries$: Observable<Country[] | undefined> = this.countryStore.getCountries();
  error: string | null;
  isLoading: boolean = false;
  isLoadingChoices: boolean = false;
  regimeChoices: ContractShift[] = [];
  zipcodeError: boolean = false;
  zipcodeLoading: boolean = false;

  latLongAddress: any[];

  $onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private companyService: CompanyService,
    private addressService: AddressService,
    private countryStore: CountryStore,
    private formHelper: FormHelperService,
    private formBuilder: FormBuilder,
    private notification: NzNotificationService,
    private drawerRef: NzDrawerRef
  ) {}

  ngOnInit(): void {
    this.getRegimeChoices();
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  closeModal() {
    this.drawerRef.close();
  }

  getAddressForm(): FormGroup {
    return this.companyForm.controls.addressData as FormGroup;
  }

  submitForm() {
    if (this.companyForm.valid) {
      this.isLoading = true;

      const colony = this.companyForm.value.addressData.colony;
      const coords = this.latLongAddress.find(c => c.placeName === colony);

      const data = {
        companyUid: this.company?.uid,
        taxRegimeUid: this.companyForm.value.taxRegimeUid,
        addressData: {
          latitude: coords.latitude,
          longitude: coords.longitude,
          ...this.companyForm.value.addressData,
        }
      };

      this.companyService.updateCompanyTaxData(data).pipe(
        finalize(() => {
          this.isLoading = false;
        })
      ).subscribe((company: Company) => {
        this.notification.create('success', '¡Operación exitosa!', 'Datos actualizados correctamente');
        this.companyEdited.emit(company);
        this.drawerRef.close();
      }, error => {
        // this.error = error.message.replace("GraphQL error:", "").trim();
        this.notification.create('error', 'Error al actualizar la información', error.message.replace("GraphQL error:", "").trim());
      });
    } else {
      this.formHelper.markFormAsDirty(this.companyForm);
      this.formHelper.markGroupAsDirty(this.companyForm, 'addressData');
    }
  }

  private createForm() {
    this.companyForm = this.formBuilder.group({
      taxRegimeUid: [this.company?.taxRegime?.uid, Validators.required],
      addressData: this.formBuilder.group({
        street: [this.company?.taxDomicile?.street || null, [Validators.required]],
        exteriorNumber: [this.company?.taxDomicile?.exteriorNumber || null, [Validators.required]],
        interiorNumber: [this.company?.taxDomicile?.interiorNumber || null],
        zipcode: [this.company?.taxDomicile?.zipcode || null, [Validators.required]],
        colony: [this.company?.taxDomicile?.colony || null, [Validators.required]],
        city: [this.company?.taxDomicile?.city || null, [Validators.required]],
        state: [this.company?.taxDomicile?.state || null, [Validators.required]],
        country: [this.company?.taxDomicile?.country || null, [Validators.required]],
      })
    });

    this.getAddressForm().controls.zipcode.valueChanges.pipe(
      takeUntil(this.$onDestroy),
      debounceTime(500)
    ).subscribe(zipcode => {
      if (zipcode && this.getAddressForm().controls.country.value) {
        this.getColonies(zipcode, this.getAddressForm().controls.country.value);
      }
    });

    this.getAddressForm().controls.country.valueChanges.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(country => {
      if (country && this.getAddressForm().controls.zipcode.value) {
        this.getColonies(this.getAddressForm().controls.zipcode.value, country);
      }
    });

    if (this.company?.taxDomicile !== null) {
      this.getCountry();
    }
  }

  private getCountry() {
    this.countries$.subscribe(countries => {
      if (countries) {
        const index = countries.findIndex(c => c.countryName === this.company?.taxDomicile?.country);

        if (index !== -1) {
          this.getAddressForm().controls.country.setValue(countries[index].countryCode);
        } else {
          this.getAddressForm().controls.country.setValue('MX');
        }

        if (this.company?.taxDomicile !== null) {
          if (this.getAddressForm().controls.zipcode.value && this.getAddressForm().controls.country.value) {
            this.getColonies(this.getAddressForm().controls.zipcode.value, this.getAddressForm().controls.country.value);
          }
        }
      }
    });
  }

  private getColonies(zipcode: string, country: string) {
    this.zipcodeLoading = true;
    this.zipcodeError = false;

    this.addressService.getAddressByZipcode(zipcode, country).pipe(
      finalize(() => {
        this.zipcodeLoading = false;
      })
    ).subscribe(address => {
        this.latLongAddress = address?.latLongAddress!;
        this.setAddress(address);
      }
    );
  }

  private setAddress(address: Address | null) {
    if (address) {
      this.zipcodeError = false;
      this.colonies = address.colony;
      if (this.company?.taxDomicile === null) {
        this.getAddressForm().controls.state.setValue(address.state);
        this.getAddressForm().controls.city.setValue(address.city);
        this.getAddressForm().controls.colony.setValue(address.colony[0]);
      } else {
        const index = address.colony.findIndex((c: string) => c === this.company?.taxDomicile?.colony);
        if (index !== -1) {
          this.getAddressForm().controls.colony.setValue(address.colony[index]);
        } else {
          this.getAddressForm().controls.colony.setValue(address.colony[0]);
        }
      }
    } else {
      this.zipcodeError = true;
      this.colonies = [];
      this.getAddressForm().controls.colony.setValue(null);
      this.getAddressForm().controls.state.setValue(null);
      this.getAddressForm().controls.city.setValue(null);
    }
  }

  private getRegimeChoices() {
    this.isLoadingChoices = true;

    this.companyService.getRegimeChoices().pipe(
      finalize(() => {
        this.isLoadingChoices = false;
      })
    ).subscribe({
      next: (regimeChoices: ContractShift[]) => {
        this.regimeChoices = regimeChoices;
      },
      error: err => {
        this.isLoadingChoices = false;
        this.error = err;
      }
    });
  }

  get taxRegimeUid() {
    return this.companyForm.get('taxRegimeUid');
  }

  get street() {
    return this.companyForm.get('addressData.street');
  }


  get exteriorNumber() {
    return this.companyForm.get('addressData.exteriorNumber');
  }

  get zipcode() {
    return this.companyForm.get('addressData.zipcode');
  }

  get colony() {
    return this.companyForm.get('addressData.colony');
  }

  get city() {
    return this.companyForm.get('addressData.city');
  }

  get state() {
    return this.companyForm.get('addressData.state');
  }

  get country() {
    return this.companyForm.get('addressData.country');
  }
}
