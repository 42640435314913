import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Team} from '../../../core/models/team';
import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {TeamService} from '../../../core/services/team.service';
import {NewTeamModalComponent} from '../../modals/new-team-modal/new-team-modal.component';
import {NzDrawerService} from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-teams-form-control',
  templateUrl: './teams-form-control.component.html',
  styleUrls: ['./teams-form-control.component.scss']
})
export class TeamsFormControlComponent implements OnInit, OnDestroy {
  @Input() allowNew = true;
  @Input() allowDelete = true;
  @Input() teams: FormControl
  @Input() moreThanOne: boolean = true;
  @Output() addedTeam = new EventEmitter<Team>();
  @Output() deletedTeam = new EventEmitter<Team>();
  temList: Team[] = [];
  list: Team[] | undefined;

  @ViewChild('newTeamTitle') newTeamTitle: TemplateRef<any>;

  teamsLoading = false;
  isOpen = false;

  $onDestroy: Subject<void> = new Subject<void>();
  compareTeams = (o1: Team, o2: Team) => o1?.uid === o2?.uid;

  constructor(
      private teamsService: TeamService,
      private drawerService: NzDrawerService
  ) { }

  ngOnInit(): void {
    this.temList = [...this.teams.value];
    this.loadTeams();
  }

  ngOnDestroy(): void {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  private loadTeams() {
    this.teamsLoading = true;
    this.teamsService.getTeams()
        .pipe(
            finalize(() => {
              this.teamsLoading = false;
            })
        )
        .subscribe( (res) => {
          this.list = res;
        });
  }

  removeTeam(team: any) {
    this.teams.setValue(
        this.teams.value.filter((el: Team) => el.uid != team.uid)
    );
    const indexDelete = this.temList.findIndex(t => t.uid === team.uid);
    if (indexDelete !== -1) {
      this.deletedTeam.emit(team);
      this.temList.splice(indexDelete, 1);
    }
  }

  openNewTeam() {
    const modal = this.drawerService.create({
      nzContent: NewTeamModalComponent,
      nzTitle: this.newTeamTitle,
      nzPlacement: 'left',
      nzWidth: 504
    });

    modal.afterOpen.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.teamCreated
          .pipe(takeUntil(this.$onDestroy))
          .subscribe((newTeam: Team) => {
            this.list?.push(newTeam);
            this.teams.setValue([...this.teams.value, newTeam]);
            this.addedTeam.emit(newTeam);
            this.temList = [...this.teams.value];
          })
    })
  }

  addDeleteTeam($event: any) {
    if ($event.length > this.temList.length) {
      this.addedTeam.emit($event[$event.length - 1]);
      this.temList = [...$event];
    } else {
     this.temList.forEach((team: Team) => {
       const index = $event.findIndex((t: any) => t.uid === team.uid);
       if (index === -1) {
         const indexDelete = this.temList.findIndex(t => t.uid === team.uid);
         if (indexDelete !== -1) {
           this.deletedTeam.emit(team);
           this.temList.splice(indexDelete, 1);
         }
       }
     });
    }
  }
}
