<div class="absence-type-box flex between wrap align-center">
  <div class="name">
    <div class="tag flex align-center" [ngStyle]="{color: absenceType.colour}">
      <div class="background" [ngStyle]="{backgroundColor: absenceType.colour}"></div>
      <i nz-icon nzType="calendar" nzTheme="fill"></i> {{absenceType.name | titlecase}}
    </div>
  </div>

  <div class="paid">
    <span *ngIf="absenceType.paid">
      Se paga las ausencias
    </span>
    <span *ngIf="!absenceType.paid">
      No se paga las ausencias
    </span>
  </div>

  <div class="vacation">
    <span *ngIf="absenceType.vacation">
      Resta dias de vacaciones
    </span>
    <span *ngIf="!absenceType.vacation">
      No resta dias de vacaciones
    </span>
  </div>

  <div class="team-actions flex end align-center">

    <ng-container *ngIf="!isLoading">
      <div class="team-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" nz-tooltip="Configurar tipo de asueto" [nzClickHide]="false" [nzDropdownMenu]="menu">
        <i nz-icon nzType="setting" nzTheme="fill"></i>
      </div>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="openEditAbsenceType()"><i class="empty-icon" nz-icon nzType="edit" nzTheme="outline"></i> Editar tipo de ausencia</li>
          <li nz-menu-item nzDanger nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas eliminar este tipo de ausencia?"
              (nzOnConfirm)="deleteAbsenceType()"><i class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i> Eliminar tipo de ausencia</li>
        </ul>
      </nz-dropdown-menu>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <nz-spin nzSize="small"></nz-spin>
    </ng-container>
  </div>
</div>
