<div class="member-payroll-row flex between wrap align-center">
  <div class="icons-row">
    <div class="icons-box">
      <i *ngIf="paycheck?.payroll?.stampedStatus !== 'N'" [style.color]="paycheck?.payroll?.stampedStatus === 'C' ? '#78E7AC' : '#FF9904'" nz-icon nzType="icons:ticket" nz-popover [nzPopoverContent]="contentTemplate1"></i>
      <i *ngIf="paycheck?.payroll?.disbursedStatus !== 'N'" [style.color]="paycheck?.payroll?.disbursedStatus === 'C' ? '#78E7AC' : '#FF9904'" nz-icon nzType="icons:money-ticket" nz-popover [nzPopoverContent]="contentTemplate2"></i>
    </div>

    <ng-template #contentTemplate1>
      <div class="popover-name">Nómina timbrada</div>
    </ng-template>

    <ng-template #contentTemplate2>
      <div class="popover-name">Nómina dispersada</div>
    </ng-template>
  </div>

  <div class="category-row">
    <div class="category-num" (click)="goToDetails()">Nómina <span>#{{  paycheck?.payroll?.numberId }}</span></div>
    <div class="period">Periodo {{ paycheck?.payroll?.startDate | date: 'dd' }} {{ paycheck?.payroll?.startDate | date: 'MMM' }} - {{ paycheck?.payroll?.endDate | date: 'dd' }} {{ paycheck?.payroll?.endDate | date: 'MMM' }}</div>
  </div>

  <div class="category-row">
    <div class="category">{{ paycheck?.payroll?.name }}</div>
    <div class="period">{{ paycheck?.payroll?.employerRegistry?.businessName }}</div>
  </div>

  <div class="category-row">
    <div class="count">Deducciones: {{ paycheck?.totalDeductions | currency }}</div>
  </div>

  <div class="category-row">
    <div class="count">Percepciones: {{ paycheck?.totalEarnings | currency }}</div>
  </div>


  <div class="category-row">
    <div class="total">Total: {{ paycheck?.totalNet | currency }}</div>
  </div>

  <ng-container>
    <div class="earning-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" [nz-tooltip]="'Acciones de nómina'" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="icons:dots" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item nz-popconfirm [nzDisabled]="isDownloading" (click)="goToDetails()">
          <i class="empty-icon" nz-icon nzType="eye" nzTheme="outline"></i>
          Ver detalles de nómina</li>
        <!--<li nz-menu-item nz-popconfirm [nzDisabled]="isDownloading" (click)="download()">
          <i *ngIf="isDownloading" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          <i *ngIf="!isDownloading" class="empty-icon" nz-icon nzType="download" nzTheme="outline"></i>
          Descargar CFDI</li>-->
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
