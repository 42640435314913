<div class="meeting-row flex between wrap align-center">
  <div class="name-content">
    <div class="container">
      <div class="img-box" *ngIf="meeting?.fromGoogleCalendar">
        <img [src]="'../assets/images/meet.png'" [alt]="'meet-icon'">
      </div>
      <i *ngIf="!meeting?.fromGoogleCalendar" nz-icon nzType="icons:briefcase"></i>
    </div>
    <div class="name" (click)="openMeeting()">{{ meeting?.name }}</div>
  </div>

  <div class="members-row flex wrap align-center">
    <div class="member-box"
         nzTooltipColor="white"
         [nz-tooltip]="(member.firstName | titlecase) + ' ' + (member.lastName | titlecase)"
         *ngFor="let member of (meeting?.participants | slice:0: 3)">
      <nz-avatar [nzGap]="5" [nzSize]="26"  [nzSrc]="member.photo?.url || undefined"
                 [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
    </div>
    <div class="team-extra" *ngIf="(meeting?.participants?.length || 0) > 3">
      +{{(meeting?.participants?.length || 0) - 3}} colaborador{{ ((meeting?.participants?.length || 0) -3) != 1 ? 'es' : ''}}
    </div>
    <div class="team-extra" *ngIf="(meeting?.participants?.length || 0) <= 3 && (meeting?.participants?.length || 0) > 0">
      {{(meeting?.participants?.length || 0)}} colaborador{{ (meeting?.participants?.length || 0) != 1 ? 'es' : ''}}
    </div>
    <div class="team-extra flex align-center" *ngIf="(meeting?.participants?.length || 0) <= 0">
      <i nz-icon class="empty-icon" nzType="icons:empty" nzTheme="outline" style="margin-right: 6px;"></i> Sin miembros
    </div>
  </div>

  <div class="date-content">
    <div class="date">{{ meeting?.startDate | date: 'dd' }} de {{ meeting?.startDate | date: 'MMM' }} {{ meeting?.startDate | date: 'yyyy' }}</div>
  </div>

  <div class="time-content">
    <div class="turn-box">
      <ng-container>
        <div class="time-box active" [ngClass]="{'red': meeting?.checkinIncident}"
             nzTooltipColor="white" [nz-tooltip]="meeting?.checkinIncident ? 'Check in fuera de tiempo / ubicación' : ''">
          <span>{{ meeting?.startHour }}</span>

          <div class="icon-row flex end" *ngIf="meeting?.checkinIncident">
            <div class="icon">
              <div class="img-box">
                <img src="../assets/images/clock.png">
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="address-content">
    <div class="address">{{ meeting?.fromGoogleCalendar ? (meeting.place !== null && meeting.place.address !== null) ? meeting?.place?.address?.fullAddress : 'Sin dirección registrada' : (meeting?.place?.address?.fullAddress || 'Sin dirección registrada') }}</div>
  </div>

  <div class="address-content">
    <div class="address" *ngIf="meeting?.hangoutLink === null">Sin url registrada</div>
    <a *ngIf="meeting?.hangoutLink !== null" (click)="goToLink(meeting.hangoutLink)">{{ meeting?.hangoutLink }}</a>
  </div>

  <ng-container>
    <div class="meet-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" nz-tooltip="Configurar junta" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="unordered-list" ></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="openMeeting()" [nzDisabled]="isDeleting"><i class="empty-icon grey" nz-icon nzType="eye" nzTheme="outline"></i> Ver detalles</li>
        <li nz-menu-item (click)="editMeeting()" [nzDisabled]="isDeleting" *ngIf="!meeting.fromGoogleCalendar"><i class="empty-icon grey" nz-icon nzType="edit" nzTheme="outline"></i> Editar junta</li>
        <li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar esta junta?"
            (nzOnConfirm)="deleteMeeting()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar junta</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>

