<div class="edit-member-schedule-modal">
  <div class="days-container">
    <div class="day">
      <app-schedule-form-control [dayConfig]="member?.schedule?.monday" [dayFormGroup]="getDayFormGroup('monday')" [dayName]="'Lunes'"></app-schedule-form-control>
    </div>

    <div class="day">
      <app-schedule-form-control [dayConfig]="member?.schedule?.tuesday" [dayFormGroup]="getDayFormGroup('tuesday')" [dayName]="'Martes'"></app-schedule-form-control>
    </div>

    <div class="day">
      <app-schedule-form-control [dayConfig]="member?.schedule?.wednesday" [dayFormGroup]="getDayFormGroup('wednesday')" [dayName]="'Miércoles'"></app-schedule-form-control>
    </div>

    <div class="day">
      <app-schedule-form-control [dayConfig]="member?.schedule?.thursday" [dayFormGroup]="getDayFormGroup('thursday')" [dayName]="'Jueves'"></app-schedule-form-control>
    </div>

    <div class="day">
      <app-schedule-form-control [dayConfig]="member?.schedule?.friday" [dayFormGroup]="getDayFormGroup('friday')" [dayName]="'Viernes'"></app-schedule-form-control>
    </div>

    <div class="day">
      <app-schedule-form-control [dayConfig]="member?.schedule?.saturday" [dayFormGroup]="getDayFormGroup('saturday')" [dayName]="'Sábado'"></app-schedule-form-control>
    </div>

    <div class="day">
      <app-schedule-form-control [dayConfig]="member?.schedule?.sunday" [dayFormGroup]="getDayFormGroup('sunday')" [dayName]="'Domingo'"></app-schedule-form-control>
    </div>
  </div>

  <div class="drawer-footer ant-drawer-footer">
    <div class="flex end wrap">
      <button nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
              (nzOnConfirm)="closeModal()"
              type="button" nz-button nzType="default">Cancelar</button>
      <button [nzLoading]="isLoading" (click)="updateSchedule()" type="button" nz-button nzType="primary">Guardar</button>
    </div>
  </div>
</div>
