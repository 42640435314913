<div class="edit-member-salary">

  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <form nz-form nzLayout="vertical" [formGroup]="memberForm">
    <nz-form-item>
      <nz-form-label>Salario Mensual</nz-form-label>
      <nz-form-control [nzErrorTip]="newPaidAmountErrorTpl">

        <nz-input-number
          style="width: 100%"
          [OnlyNumber]="true"
          formControlName="newPaidAmount"
          [nzMin]="1"
          [nzStep]="10"
          [nzFormatter]="formatter"
          [nzParser]="parser">
        </nz-input-number>

        <ng-template #newPaidAmountErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            El salario es requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Salario IMSS</nz-form-label>
      <nz-form-control [nzErrorTip]="imssPaidAmountErrorTpl">

        <nz-input-number
          style="width: 100%"
          [OnlyNumber]="true"
          formControlName="imssPaidAmount"
          [nzMin]="0"
          [nzStep]="10"
          [nzFormatter]="formatter"
          [nzParser]="parser">
        </nz-input-number>

        <ng-template #imssPaidAmountErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            El salario IMSS es requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Jornada laboral</nz-form-label>
      <nz-form-control [nzErrorTip]="hoursWorkedErrorTpl">

        <nz-select formControlName="hoursWorked">
          <nz-option *ngFor="let hour of hours" [nzValue]="hour" [nzLabel]="hour + ' hrs'"></nz-option>
        </nz-select>

        <ng-template #hoursWorkedErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
           La jornada laboral es requerida
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="btns-container">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="closeModal()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="updateSalary()">Guardar</button>
  </div>
</div>
