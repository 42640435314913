<div class="file-details-component flex center align-center">
  <div class="file-icon flex column align-center">
    <i class="file-icon" nz-icon [nzType]="'icons:' + file?.extension.replace('.', '')"></i>
  </div>

  <div class="file-name">
    {{ file?.name }}
  </div>

  <div class="file-actions flex align-center">
    <i *ngIf="isDownloading" nz-icon nzType="loading" nzTheme="outline"></i>
    <i *ngIf="!isDownloading" nz-icon nzType="download" nzTooltipColor="white" nz-tooltip="Descargar documento" (click)="downloadFile()"></i>
    <i *ngIf="!isDownloading" nz-icon nzType="eye" nzTooltipColor="white" nz-tooltip="Ver documento" (click)="openFile()"></i>
  </div>
</div>
