<div class="paycheck-row flex align-center wrap">
  <div class="container flex align-center between employee">
    <div class="info-container flex align-center">
      <i class="arrow" nz-icon (click)="toggleOpen()"
         [nzType]="isOpen ? 'up' : 'down'" nzTooltipColor="white"
         [nz-tooltip]="isOpen ? 'Cerrar detalles' : 'Ver detalles'">
      </i>

      <nz-avatar [nzSize]="30"
                 [nzSrc]="paycheck?.employee?.photo?.url || undefined"
                 [nzText]="paycheck?.employee?.firstName?.charAt(0) + '' + paycheck?.employee?.lastName?.charAt(0)">
      </nz-avatar>

      <div class="info-box">
        <div class="title">{{ paycheck?.employee?.firstName }} {{ paycheck?.employee?.lastName }}</div>
        <div class="subtitle">{{ paycheck?.employee?.email }}</div>
      </div>
    </div>

    <div class="box" *ngIf="paycheck?.stampedStatus === 'I' || paycheck?.disbursedStatus === 'I'">
      <i class="warning-block" nz-icon nzType="warning" nzTheme="fill"
         [nz-tooltip]="(paycheck?.stampedStatus === 'I' && paycheck?.disbursedStatus === 'I') ? 'Timbrado y dispersado' :
         paycheck?.stampedStatus === 'I' ? 'Timbrado' : paycheck?.disbursedStatus === 'I' ? 'Dispersado' : ''"
         nzTooltipColor="white"></i>
      <!--<div class="category-box flex align-center first">
        <div class="background"></div>
        <div class="type">Timbrado</div>
      </div>

      <div class="category-box flex align-center">
        <div class="background"></div>
        <div class="type">Dispersado</div>
      </div>-->
    </div>
  </div>

  <div class="container">
    <div class="info-box">
      <div class="title">{{ isOpen ? '' : ('Percepciones: ' + (paycheck?.totalEarnings | currency)) }}</div>
      <div class="subtitle">{{ isOpen ? '' : ('Deducciones: ' + (paycheck?.totalDeductions | currency)) }}</div>
    </div>
  </div>

  <div class="container">
    <div class="total-net">Total neto: {{ paycheck?.totalNet | currency }}</div>
  </div>

  <div class="container">
    <div class="total">Total: {{ paycheck?.totalPaid | currency }}</div>
  </div>

  <div class="small-box flex align-center between">
    <i *ngIf="isDeleting" class="loading" nz-icon nzType="loading"></i>
    <i *ngIf="!isDeleting && canDelete" class="delete" nz-icon nzType="delete" nzTooltipColor="white" nz-tooltip="Eliminar colaborador"
       nz-popconfirm="¿Seguro que deseas elimnar este colaborador?" (nzOnConfirm)="deletePayCheck()"></i>
    <i *ngIf="paycheck?.CFDIInvoice && paycheck?.CFDIInvoice?.zipFileUrl" class="download" nz-icon nzType="download"
       nzTrigger="click" nz-dropdown [nzClickHide]="false" [nzDropdownMenu]="menu"></i>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li [nzDisabled]="isDeleting || isDownloading || isDisburseDownloading" nz-menu-item (click)="downloadFiles()">
          <i *ngIf="isDownloading" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          <i *ngIf="!isDownloading" class="empty-icon" nz-icon nzType="download" nzTheme="outline"></i>
          Descargar CFDI
        </li>

        <li [nzDisabled]="isDeleting || isDisburseDownloading || isDownloading" nz-menu-item (click)="downloadDisburseFile()">
          <i *ngIf="isDisburseDownloading" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          <i *ngIf="!isDisburseDownloading" class="empty-icon" nz-icon nzType="download" nzTheme="outline"></i>
          Descargar comprobante de pago
        </li>

        <!--<li nz-menu-item><i class="empty-icon" nz-icon nzType="download" nzTheme="outline"></i> Descargar Comprobante de pago</li>-->
      </ul>
    </nz-dropdown-menu>
  </div>

  <div class="paycheck-details-row flex" [hidden]="!isOpen">
    <nz-spin [nzSpinning]="isLoading" *ngIf="isLoading"></nz-spin>
    <div class="container flex employee" *ngIf="!isLoading">
      <div class="list-box flex-1">
        <div class="title">Resumen del periodo</div>
        <div class="item flex align-center">
          <i nz-icon nzType="icons:not-employee"></i>
          {{ paycheckSummary?.nonAttendances }} falta{{ paycheckSummary?.nonAttendances === 1 ? '' : 's' }}
        </div>

        <div class="item flex align-center">
          <i nz-icon nzType="icons:warning-4"></i>
          {{ paycheckSummary?.notOnTime }} retardo{{ paycheckSummary?.notOnTime === 1 ? '' : 's' }}
        </div>

        <div class="item flex align-center">
          <i nz-icon nzType="icons:pin-4"></i>
          {{ paycheckSummary?.notOnLocation }} fuera de ubicación
        </div>

        <div class="item flex align-center">
          <i nz-icon nzType="icons:time"></i>
          {{ paycheckSummary?.extraHoursLaboured }} hora{{ paycheckSummary?.extraHoursLaboured === 1 ? '' : 's' }} extra
        </div>

        <div class="item flex align-center">
          <i nz-icon nzType="icons:portfolio"></i>
          {{ paycheckSummary?.daysLaboured }} día{{ paycheckSummary?.daysLaboured === 1 ? '' : 's' }} trabajado{{ paycheckSummary?.daysLaboured === 1 ? '' : 's' }}
        </div>

        <div class="item flex align-center">
          <i nz-icon nzType="icons:calendar-4"></i>
          {{ paycheckSummary?.usedVacationDays }} vacaci{{ paycheckSummary?.usedVacationDays === 1 ? 'ón' : 'ones' }} tomada{{ paycheckSummary?.usedVacationDays === 1 ? '' : 's' }}
        </div>

        <div class="item flex align-center">
          <i nz-icon nzType="icons:calendar-4"></i>
          {{ paycheckSummary?.remainingVacationDays }} vacaci{{ paycheckSummary?.remainingVacationDays === 1 ? 'ón' : 'ones' }} restante{{ paycheckSummary?.remainingVacationDays === 1 ? '' : 's' }}
        </div>

        <div class="item flex align-center">
          <i nz-icon nzType="icons:calendar-4"></i>
          {{ paycheckSummary?.maxVacationDays }} vacaci{{ paycheckSummary?.maxVacationDays === 1 ? 'ón' : 'ones' }} total{{ paycheckSummary?.maxVacationDays === 1 ? '' : 'es' }}
        </div>

        <div *ngIf="canDelete" class="item item-delete flex align-center web" nz-popconfirm="¿Seguro que deseas elimnar este colaborador?" (nzOnConfirm)="deletePayCheck()">
          <i nz-icon [nzType]="!isDeleting ? 'delete' : 'loading'"></i> {{ !isDeleting ? ' Eliminar de nómina' : ' Eliminando de la nómina' }}
        </div>
      </div>

      <div class="line"></div>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div class="title">Días laborables: {{ paycheck?.labouredDays }} <i *ngIf="isEditable" class="empty-icon" nz-icon nzType="edit" nzTheme="outline" nzTooltipColor="white" nz-tooltip="Editar" (click)="editPaycheck()"></i></div>

      <div class="text-container">
        <div class="text-name">Días de ausencia</div>
        <div class="text-value">{{ paycheck?.absentDays }}</div>
      </div>

      <div class="text-container">
        <div class="text-name">Días de incapacidad</div>
        <div class="text-value">{{ paycheck?.incapacityDays }}</div>
      </div>

      <div class="text-container">
        <div class="text-name">Días de vacaciones</div>
        <div class="text-value">{{ paycheck?.vacationDays }}</div>
      </div>

      <div class="text-container">
        <div class="text-name">Horas extras dobles</div>
        <div class="text-value">{{ paycheck?.doubleHours }}</div>
      </div>

      <div class="text-container">
        <div class="text-name">Horas extras triples</div>
        <div class="text-value">{{ paycheck?.tripleHours }}</div>
      </div>

      <div class="text-container">
        <div class="text-name">Sueldo mensual</div>
        <div class="text-value">{{ paycheck?.employee?.currentSalary?.paidAmount | currency }}</div>
      </div>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div class="title">Percepciones: {{ paycheck?.totalEarnings | currency }} <i *ngIf="isEditable" class="empty-icon" nz-icon nzType="edit" nzTheme="outline" nzTooltipColor="white" nz-tooltip="Editar" (click)="editEarnings()"></i></div>

      <div class="text-container" *ngFor="let earning of paycheck?.earnings">
        <div class="text-name">{{ earning?.name === 'Sueldo' ? earning?.name + ' ' + paycheck?.payroll?.temporalCategory : earning?.name }}</div>
        <div class="text-value">{{ earning?.worth | currency }} <span *ngIf="earning?.imssDiscount > 0" style="margin-right: 5px;" class="small-text">IMSS: {{ earning?.imssDiscount | currency }}</span>  <span *ngIf="earning?.isrDiscount > 0" class="small-text">ISR: {{ earning?.isrDiscount| currency }}</span></div>
      </div>
    </div>

    <div class="container" *ngIf="!isLoading">
      <div class="title">Deducciones: {{ paycheck?.totalDeductions | currency }} <i *ngIf="isEditable" class="empty-icon" nz-icon nzType="edit" nzTheme="outline" nzTooltipColor="white" nz-tooltip="Editar" (click)="editDeductions()"></i></div>

      <div class="text-container" *ngFor="let deduction of paycheck?.deductions">
        <div class="text-name">{{ deduction?.name }}</div>
        <div class="text-value">{{ deduction?.amount | currency }}</div>
      </div>
    </div>

    <div class="list-box flex-1 responsive" *ngIf="isEditable">
      <div class="item item-delete flex align-center responsive" nz-popconfirm="¿Seguro que deseas elimnar este colaborador?" (nzOnConfirm)="deletePayCheck()">
        <i nz-icon [nzType]="!isDeleting ? 'delete' : 'loading'"></i> {{ !isDeleting ? ' Eliminar de nómina' : ' Eliminando de la nómina' }}
      </div>
    </div>

    <div class="small-box flex align-center between" *ngIf="!isLoading"> </div>
  </div>
</div>
