import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Appeal} from "../../../core/models/appeal";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {AppealDetailsModalComponent} from "../../modals/appeal-details-modal/appeal-details-modal.component";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-appeal-row',
  templateUrl: './appeal-row.component.html',
  styleUrls: ['./appeal-row.component.scss']
})
export class AppealRowComponent implements OnInit, OnDestroy {
  @Input() appeal: Appeal;
  @Input() showDescription = false;
  @Output() updateAppeal = new EventEmitter<void>();
  $onDestroy: Subject<void> = new Subject<void>();

  constructor(private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    if (this.appeal?.attendance?.timestamp) {
      // @ts-ignore
      this.appeal?.attendance?.timestamp = new Date(this.appeal?.attendance?.timestamp.slice(0,-6));
    }
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  getTooltip() {
    if (this.appeal?.approved === null || this.appeal?.approved === false) {
      if (!this.appeal?.attendance?.onTime && this.appeal?.attendance?.onLocation) {
        return this.appeal?.attendance?.eventCategory?.toLowerCase() + ' fuera de tiempo';
      } else if (this.appeal.attendance?.onTime && !this.appeal.attendance?.onLocation) {
        return this.appeal?.attendance?.eventCategory?.toLocaleLowerCase() + ' fuera de ubicación';
      } else {
        return this.appeal.attendance?.eventCategory?.toLocaleLowerCase() + ' fuera de tiempo y ubicación';
      }
    } else {
      if (this.appeal?.attendance?.onTime && !this.appeal?.attendance?.onLocation) {
        return this.appeal.attendance?.eventCategory?.toLocaleLowerCase() +  ' a tiempo y fuera de ubicación';
      } else if (!this.appeal?.attendance?.onTime && this.appeal?.attendance?.onLocation) {
        return this.appeal.attendance?.eventCategory?.toLocaleLowerCase() +  ' fuera de tiempo y en ubicación';
      } else {
        return this.appeal.attendance?.eventCategory?.toLocaleLowerCase() +  ' a tiempo y en ubicación';
      }
    }
  }

  openAppealDetails() {
    const modal = this.drawerService.create({
      nzContent: AppealDetailsModalComponent,
      nzTitle: '',
      nzWidth: 504,
      nzClosable: false,
      nzWrapClassName: 'drawer-details-wrap',
      nzContentParams: {
        appeal: this.appeal
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((appeal: Appeal) => {
      if (appeal) {
        this.updateAppeal.emit();
      }
    })
  }

}
