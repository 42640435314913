import { NgModule } from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import { ShellComponent } from './components/shell/shell.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {RouterModule} from '@angular/router';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzFormModule} from 'ng-zorro-antd/form';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzMessageModule} from 'ng-zorro-antd/message';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import { TeamIconComponent } from './components/team-icon/team-icon.component';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzSelectModule} from 'ng-zorro-antd/select';
import { ColorBoxComponent } from './components/color-box/color-box.component';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import { IconBoxComponent } from './components/icon-box/icon-box.component';
import {SafePipe} from './pipes/safe.pipe';
import { SvgComponent } from './components/svg/svg.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import {NzResultModule} from 'ng-zorro-antd/result';
import { PhonePipe } from './pipes/phone.pipe';
import {NzCardModule} from "ng-zorro-antd/card";
import {OnlyNumbersDirective} from "./directives/only-numbers.directive";
import { MapComponent } from './components/map/map.component';
import {AgmCoreModule} from "@agm/core";
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsModule} from 'ng2-charts';
import { HelpModalOpenerDirective } from './directives/help-modal-opener.directive';
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {NzTimelineModule} from "ng-zorro-antd/timeline";
import {NzStepsModule} from "ng-zorro-antd/steps";
import {NzTreeSelectModule} from "ng-zorro-antd/tree-select";
import {LimitToPipe} from "./pipes/limit-to.pipe";
import { UppercaseDirective } from './directives/uppercase.directive';
import {NzTimePickerModule} from "ng-zorro-antd/time-picker";
import { CtMarkerCluster } from './directives/ct-marker-cluster.directive';


@NgModule({
  declarations: [
    ShellComponent,
    TeamIconComponent,
    ColorBoxComponent,
    IconBoxComponent,
    SafePipe,
    SvgComponent,
    SideMenuComponent,
    PageHeaderComponent,
    UploadImageComponent,
    UploadFileComponent,
    PhonePipe,
    OnlyNumbersDirective,
    MapComponent,
    HelpModalOpenerDirective,
    LimitToPipe,
    UppercaseDirective,
    CtMarkerCluster
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzDrawerModule,
    NzLayoutModule,
    NzPageHeaderModule,
    RouterModule,
    NzIconModule,
    NzToolTipModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzMessageModule,
    NzNotificationModule,
    NzPopoverModule,
    NzSkeletonModule,
    NzSpaceModule,
    NzEmptyModule,
    NzSpinModule,
    NzModalModule,
    NzSelectModule,
    NzDividerModule,
    NzBreadCrumbModule,
    NzPopconfirmModule,
    NzAlertModule,
    NzDropDownModule,
    NzInputNumberModule,
    NzPaginationModule,
    NzRadioModule,
    NzBadgeModule,
    NzDatePickerModule,
    NzResultModule,
    NzCardModule,
    NzSwitchModule,
    NzTimelineModule,
    NzStepsModule,
    NzTreeSelectModule,
    NzStepsModule,
    NzTreeSelectModule,
    AgmCoreModule,
    NgxChartsModule,
    NzTimePickerModule,
    ChartsModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NzLayoutModule,
    NzDrawerModule,
    NzPageHeaderModule,
    NzIconModule,
    NzToolTipModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzMessageModule,
    NzNotificationModule,
    NzPopoverModule,
    NzSkeletonModule,
    NzSpaceModule,
    TeamIconComponent,
    NzEmptyModule,
    NzSpinModule,
    NzModalModule,
    NzSelectModule,
    NzDividerModule,
    IconBoxComponent,
    ColorBoxComponent,
    SideMenuComponent,
    PageHeaderComponent,
    NzBreadCrumbModule,
    NzPopconfirmModule,
    NzAlertModule,
    NzDropDownModule,
    NzInputNumberModule,
    NzPaginationModule,
    NzRadioModule,
    NzBadgeModule,
    NzDatePickerModule,
    NzCardModule,
    UploadImageComponent,
    UploadFileComponent,
    NzResultModule,
    NzSwitchModule,
    NzTimelineModule,
    NzTreeSelectModule,
    NzStepsModule,
    NzTimePickerModule,
    PhonePipe,
    OnlyNumbersDirective,
    MapComponent,
    NgxChartsModule,
    ChartsModule,
    HelpModalOpenerDirective,
    LimitToPipe,
    UppercaseDirective,
    CtMarkerCluster
  ],
  providers: [
    TitleCasePipe
  ]
})
export class SharedModule { }
