import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {Marker} from '../../../core/models/marker';

@Component({
  selector: 'app-geo-fencing-map',
  templateUrl: './geo-fencing-map.component.html',
  styleUrls: ['./geo-fencing-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GeoFencingMapComponent implements OnInit, OnChanges {
  zoom: number = 2;

  center: Marker = {
    longitude: 0,
    latitude: 0
  };
  @Input() userPins: any[] | null = null;
  @Input() marker: Marker;
  @Input() meters: number;
  @Input() clickChanges = true;
  @Output() markerChange: EventEmitter<Marker> = new EventEmitter;

  pin: Marker;

  icon: any = {
    url: "../assets/images/trabajo.png",
    scaledSize: {
      width: 32,
      height: 38
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.marker && this.marker) {
      this.center = {...this.marker};
      this.zoom = 15;
      this.pin = this.marker;
    }
  }

  onClick($event: any) {
    if (this.marker && this.clickChanges) {
      this.marker.latitude = $event.coords.lat;
      this.marker.longitude = $event.coords.lng;
      this.markerChange.emit(this.marker);
    }
  }
}
