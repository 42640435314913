import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {PayrollService} from "../../../core/services/payroll.service";
import {Payroll} from "../../../core/models/payroll";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-stamp-modal',
  templateUrl: './stamp-modal.component.html',
  styleUrls: ['./stamp-modal.component.scss']
})
export class StampModalComponent implements OnInit {
  @Input() payrollUid: string | undefined;
  payroll: Payroll;
  isLoading = true;
  error = false;
  errorMessage = '';

  constructor(private payrollService: PayrollService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.stampPayroll();
  }

  close() {
    if (this.error) {
      this.modalRef.close(null);
    } else {
      this.modalRef.close(this.payroll);
    }
  }

  private stampPayroll() {
    this.isLoading = true;
    this.payrollService.stampPayroll(this.payrollUid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((payroll: Payroll) => {
        this.payroll = payroll;
      }, error => {
        this.error = true;
        this.errorMessage = error.message.replace('GraphQL error: ', '').trim();
      });
  }

}
