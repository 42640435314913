<div class="non-attendance-box flex wrap align-center">
  <div class="number flex align-center">
    <i class="empty-icon" nz-icon nzType="icons:unemployment" nzTheme="outline"></i> <span class="label">Falta</span> #{{nonAttendance.numberId}}
  </div>
  <div class="date">
    {{nonAttendance.createdDate | date : 'dd MMMM yyyy'}}
  </div>
  <div class="turn flex align-center">
    <div class="turn-box">
      {{nonAttendance.turnFromHour}} - {{nonAttendance.turnToHour}}
    </div>
  </div>
</div>
