<div class="help-company-name">
  <section class="help-company-name--text">
    Debe registrarse <strong>tal y como se encuentra</strong> en la <strong>Cédula de Identificación
    Fiscal y Constancia de Situación Fiscal</strong>, respetando números, espacios y signos de puntuación.
  </section>

  <section class="help-company-name--examples">
    <div class="help-company-name--examples-div">
      <span style="color: green; font-size: 15px;">
        <i nz-icon nzType="like" nzTheme="outline"></i> CORRECTO
      </span>

      <span>Cloud Sited Solutions</span>
    </div>

    <div class="help-company-name--examples-div">
      <span style="color: red; font-size: 15px;">
        <i nz-icon nzType="dislike" nzTheme="outline"></i> INCORRECTO
      </span>

      <span>Cloud Sited Solutions S.A de C.V.</span>
    </div>
  </section>

  <section class="help-company-name--buttons">
    <button (click)="closeModal()" type="button" nz-button nzType="primary">Aceptar</button>
  </section>
</div>
