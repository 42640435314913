import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Position} from "../../../core/models/position";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PositionService} from "../../../core/services/position.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize} from "rxjs/operators";
import {Turn} from "../../../core/models/turn";

@Component({
  selector: 'app-edit-position',
  templateUrl: './edit-position.component.html',
  styleUrls: ['./edit-position.component.scss']
})
export class EditPositionComponent implements OnInit, AfterViewInit {
  @Input() position: Position | null;
  @Output() editedPosition = new EventEmitter<Position>();
  isLoading = true;
  error: string | null;
  positionForm: FormGroup;
  isUpdating = false;
  formatterMin = (value: number) => `${value + ' min'}`;
  parserMin = (value: string) => value.replace("min", "").replace(" ", "");

  constructor(private positionService: PositionService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private nzDrawerRef: NzDrawerRef,
              private _cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getDetails();
  }

  ngAfterViewInit() {
    this._cdr.markForCheck();
    this._cdr.detectChanges();
  }

  closeModal() {
    this.nzDrawerRef.close();
  }

  getFormGroup(): FormGroup {
    return this.positionForm.controls.scheduleData as FormGroup;
  }

  getDayFormGroup(day: string): FormGroup {
    return this.getFormGroup().get(day) as FormGroup;
  }

  getFormArray(day: string): FormArray {
    return (this.getFormGroup().get(day) as FormGroup).get('turnsData') as FormArray;
  }

  addTurn(day: string) {
    // @ts-ignore
    this.position?.schedule[day].turns.forEach((turn: Turn) => {
      this.getFormArray(day).push(
        this.formBuilder.group({
          fromHour: [null, Validators.required],
          toHour: [null, Validators.required]
        })
      );
    });
  }

  cleanData(form: any) {
    const data = form;
    data.scheduleData.monday = this.cleanDay(data, 'monday');
    data.scheduleData.tuesday = this.cleanDay(data, 'tuesday');
    data.scheduleData.wednesday = this.cleanDay(data, 'wednesday');
    data.scheduleData.thursday = this.cleanDay(data, 'thursday');
    data.scheduleData.friday = this.cleanDay(data, 'friday');
    data.scheduleData.saturday = this.cleanDay(data, 'saturday');
    data.scheduleData.sunday = this.cleanDay(data, 'sunday');
    data.salaryData = {
      hoursWorked: this.position?.salary?.hoursWorked,
      imssPaidAmount: this.position?.salary?.imssPaidAmount,
      paidAmount: this.position?.salary?.paidAmount
    };
    return data;
  }

  cleanDay(data: any, day: string) {
    if (data.scheduleData[day].turnsData.length === 0) {
      data.scheduleData[day].active = false;
    } else {
      data.scheduleData[day].active = true;
      data.scheduleData[day].turnsData.forEach((turn: any) => {
        turn.fromHour = turn.fromHour.hour;
        turn.toHour = turn.toHour.hour;
      });
    }
    return data.scheduleData[day];
  }

  updatePosition() {
    if (this.positionForm.valid) {
      this.isUpdating = true;
      this.positionService.updatePosition(this.cleanData(this.positionForm.getRawValue()))
        .pipe(finalize(() => {
          this.isUpdating = false;
        }))
        .subscribe((position: Position | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Horario actualizado correctamente');
          // @ts-ignore
          this.position = position
          // @ts-ignore
          this.editedPosition.emit(this.position);
          this.nzDrawerRef.close();
        }, error => {
          this.notification.create('error', 'Error al actualizar el horario', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      // general group data
      this.formHelper.markFormAsDirty(this.positionForm);
      // general day group data
      this.formHelper.markFormAsDirty(this.getDayFormGroup('monday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('tuesday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('wednesday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('thursday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('friday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('saturday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('sunday'));
      // day form group array data
      this.formHelper.markFormArrayAsDirty(this.getFormArray('monday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('tuesday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('wednesday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('thursday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('friday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('saturday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('sunday'));
    }
  }

  private getDetails() {
    this.isLoading = true;
    // @ts-ignore
    this.positionService.getPosition(this.position.uid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((position: Position | null) => {
        this.position = position;
        this.createForm();
      }, error => {
        this.error = error;
      });
  }

  private createForm() {
    this.positionForm = this.formBuilder.group({
      affectsEveryoneSalary: [false],
      affectsEveryoneSchedule: [false],
      name: [this.position?.name, Validators.required],
      paidAmount: [this.position?.salary?.paidAmount, [Validators.required, Validators.min(1)]],
      positionUid: [this.position?.uid],
      toleranceMinutes: [this.position?.toleranceMinutes, Validators.required],
      scheduleData: this.formBuilder.group({
        monday: this.setFormDefault('monday'),
        tuesday: this.setFormDefault('tuesday'),
        wednesday: this.setFormDefault('wednesday'),
        thursday: this.setFormDefault('thursday'),
        friday: this.setFormDefault('friday'),
        saturday: this.setFormDefault('saturday'),
        sunday: this.setFormDefault('sunday')
      })
    });

    this.setDefaultDays();
  }

  private setFormDefault(day: string) {
    return this.formBuilder.group({
      // @ts-ignore
      active: [this.position?.schedule ? this.position.schedule[day].active : true],
      // @ts-ignore
      category: [this.position?.schedule ? (this.position.schedule[day].category === 'Remoto' ? 'R' : 'I') : 'I'],
      turnsData: this.formBuilder.array([])
    });
  }

  private setDefaultDays() {
    this.addTurn('monday');
    this.addTurn('tuesday');
    this.addTurn('wednesday');
    this.addTurn('thursday');
    this.addTurn('friday');
    this.addTurn('saturday');
    this.addTurn('sunday');
  }

}
