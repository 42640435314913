import {Component, Input, OnInit} from '@angular/core';
import {Attendance} from '../../../core/models/attendance';
import {User} from '../../../core/models/user';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {TitleCasePipe} from '@angular/common';
import {AttendanceModalComponent} from '../../modals/attendance-modal/attendance-modal.component';

@Component({
  selector: 'app-not-on-time-box',
  templateUrl: './not-on-time-box.component.html',
  styleUrls: ['./not-on-time-box.component.scss']
})
export class NotOnTimeBoxComponent implements OnInit {
  @Input() attendance: Attendance;
  @Input() member: User | null;
  timestamp: Date;

  constructor(
      private drawerService: NzDrawerService,
      private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void {
    if (this.attendance?.timestamp) {
      this.timestamp = new Date(this.attendance.timestamp.slice(0,-6));
    }
  }

  openAttendance() {
    const modal = this.drawerService.create({
      nzContent: AttendanceModalComponent,
      nzContentParams: {
        member: this.member || undefined,
        attendance: this.attendance
      },
      nzTitle: 'Información del ' + this.titleCasePipe.transform(this.attendance?.eventCategory),
      nzWidth: 504
    });
  }

}
