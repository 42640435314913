<div class="stamp-modal-component">
  <div class="title">
    <ng-container *ngIf="isLoading">Procesando timbrado</ng-container>
    <ng-container *ngIf="!error && !isLoading">Timbrado exitoso</ng-container>
    <ng-container *ngIf="error && !isLoading">Timbrado no realizado</ng-container>
  </div>

  <div class="img-box bell" *ngIf="isLoading">
    <img [src]="'../assets/images/bell.png'" [alt]="'bell-icon'">
  </div>

  <div class="img-box success" *ngIf="!error && !isLoading">
    <img [src]="'../assets/images/success.png'" [alt]="'success-icon'">
  </div>

  <div class="img-box error" *ngIf="error && !isLoading">
    <img [src]="'../assets/images/error.png'" [alt]="'error-icon'">
  </div>

  <div class="message-box">
    <div class="message">
      <ng-container *ngIf="isLoading">Espera unos minutos en lo que se termina de procesar el  timbrado</ng-container>
      <ng-container *ngIf="!error && !isLoading">Se ha timbrado con éxito la nómina</ng-container>
      <ng-container *ngIf="error && !isLoading">{{ errorMessage }}</ng-container>
    </div>

    <div class="btn-box" *ngIf="!isLoading">
      <button class="btn" nz-button nzType="primary" (click)="close()">Aceptar</button>
    </div>
  </div>
</div>
