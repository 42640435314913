import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Paycheck} from "../../../core/models/paycheck";
import {PaycheckSummary} from "../../../core/models/paycheck-summary";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {PayrollService} from "../../../core/services/payroll.service";
import {finalize, takeUntil} from "rxjs/operators";
import {EditPaycheckInfoModalComponent} from "../../modals/edit-paycheck-info-modal/edit-paycheck-info-modal.component";
import {Subject} from "rxjs";
import {EditPaycheckEarningsModalComponent} from "../../modals/edit-paycheck-earnings-modal/edit-paycheck-earnings-modal.component";
import {EditPaycheckDeductionsModalComponent} from "../../modals/edit-paycheck-deductions-modal/edit-paycheck-deductions-modal.component";

@Component({
  selector: 'app-paycheck-row',
  templateUrl: './paycheck-row.component.html',
  styleUrls: ['./paycheck-row.component.scss']
})
export class PaycheckRowComponent implements OnInit, OnDestroy {
  @Input() canDelete = true;
  @Input() isEditable = true;
  @Input() paycheck: Paycheck | null;
  @Output() updatePaycheck = new EventEmitter<void>();
  @Output() removePaycheck = new EventEmitter<void>();
  @Output() updatePaycheckSummary = new EventEmitter<void>();
  isOpen = false;
  isLoading = false;
  paycheckSummary: PaycheckSummary | null = null;
  isDeleting = false;
  isDownloading = false;
  isDisburseDownloading = false;
  $onDestroy: Subject<void> = new Subject<void>();

  constructor(private payrollService: PayrollService,
              private notification: NzNotificationService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.getSummaryInfo();
    }
  }

  deletePayCheck() {
    this.isDeleting = true;
    this.payrollService.deletePaycheck(this.paycheck?.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', 'Colaborador eliminado correctamente');
        this.removePaycheck.emit();
      }, error => {
        this.notification.create('error', 'Error al eliminar el colaborador', error.message.replace("GraphQL error:", "").trim());
      });
  }

  editPaycheck() {
    const modal = this.drawerService.create({
      nzContent: EditPaycheckInfoModalComponent,
      nzTitle: 'Editar días de trabajo',
      nzWidth: 504,
      nzContentParams: {
        paycheck: this.paycheck
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((paycheck: Paycheck) => {
      if (paycheck) {
        this.paycheck = paycheck;
        this.updatePaycheckSummary.emit();
      }
    })
  }

  editEarnings() {
    const modal = this.drawerService.create({
      nzContent: EditPaycheckEarningsModalComponent,
      nzTitle: 'Editar percepciones',
      nzWidth: 504,
      nzContentParams: {
        paycheck: this.paycheck
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((paycheck: Paycheck) => {
      if (paycheck) {
        this.paycheck = paycheck;
        this.updatePaycheckSummary.emit();
      }
    })
  }

  editDeductions() {
    const modal = this.drawerService.create({
      nzContent: EditPaycheckDeductionsModalComponent,
      nzTitle: 'Editar deducciones',
      nzWidth: 504,
      nzContentParams: {
        paycheck: this.paycheck
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((paycheck: Paycheck) => {
      if (paycheck) {
        this.paycheck = paycheck;
        this.updatePaycheckSummary.emit();
      }
    })
  }

  downloadFiles() {
    this.isDownloading = true;
    this.payrollService.downloadFile2(this.paycheck?.CFDIInvoice?.zipFileUrl)
      .pipe(finalize(() => {
        this.isDownloading = false;
      }))
      .subscribe(data => {
        saveAs(data, this.paycheck?.CFDIInvoice?.zipFileName);
      }, error => {
        this.notification.create('error', 'Error al descargar el archivo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  downloadDisburseFile() {
    this.isDisburseDownloading = true;
    /*this.payrollService.downloadDisburseFile(this.paycheck?.CFDIInvoice?.zipFileUrl)
      .pipe(finalize(() => {
        this.isDownloading = false;
      }))
      .subscribe(data => {
        saveAs(data, this.paycheck?.CFDIInvoice?.zipFileName);
      }, error => {
        this.notification.create('error', 'Error al descargar el archivo', error.message.replace("GraphQL error:", "").trim());
      });*/
  }

  private getSummaryInfo() {
    if (this.paycheckSummary === null) {
      this.isLoading = true;
      this.payrollService.getPaycheckSummary(this.paycheck?.uid)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe(paycheckSummary => {
          this.paycheckSummary = paycheckSummary;
        }, error => {
          this.toggleOpen();
          this.notification.create('error', 'Error al obtener los detalles', error.message.replace("GraphQL error:", "").trim());
        });
    }
  }

}
