<div class="map-box2" *ngIf="pinIcon && circularIcon">
  <agm-map [latitude]="selectedGeolocation !== null ? selectedGeolocation.latitude : geolocations[0].latitude"
           [longitude]="selectedGeolocation !== null ? selectedGeolocation.longitude : geolocations[0].longitude"
           [minZoom]="8"
           [zoom]="2"
           [maxZoom]="16"
           [fitBounds]="true"
           [disableDefaultUI]="true"
           [mapTypeId]="'roadmap'"
           [styles]="mapStyle">

    <ng-container *ngIf="selectedGeolocation === null">
      <ct-marker-cluster
        (clusterClick)="clusterClick($event)"
        [imagePath]="'../assets/images/map-pins/map-cluster-pins/' + this.teamColor.replace('#', '') + '-m-'"
        [enableRetinaIcons]="false">

        <ng-container *ngFor="let location of geolocations; let i = index;">
          <agm-marker
            [iconUrl]="location.pinData"
            [agmFitBounds]="true"
            [latitude]="location.latitude"
            [longitude]="location.longitude"
            [markerClickable]="true">
            <agm-info-window [disableAutoPan]="true">
              <div style="margin: 0 12px;">{{ location?.correctTimestamp | date: 'dd' }} de  {{ location?.correctTimestamp | date: 'MMM' }} {{ location?.correctTimestamp | date: 'yyyy' }}</div>
              <div style="margin: 0 12px;">{{ location?.correctTimestamp | date: 'HH:mm' }} hrs</div>
            </agm-info-window>
          </agm-marker>

          <agm-circle
            *ngIf="location.pinData.extra.isLastLocation"
            [strokeColor]="teamColor"
            [strokeOpacity]=".8"
            [strokeWeight]="2"
            [fillColor]="teamColor"
            [fillOpacity]=".25"
            [longitude]="location?.longitude || 0"
            [latitude]="location?.latitude || 0"
            [radius]="200">
          </agm-circle>
        </ng-container>

        <agm-polyline [strokeColor]="teamColor" [visible]="true" [strokeWeight]="3">
          <ng-container *ngFor="let location of geolocations; let i = index;">
            <agm-polyline-point
              [latitude]="location.latitude"
              [longitude]="location.longitude"
              [agmFitBounds]="true">
            </agm-polyline-point>
          </ng-container>
        </agm-polyline>
      </ct-marker-cluster>
    </ng-container>

    <ng-container *ngIf="selectedGeolocation !== null">
      <agm-marker
        [iconUrl]="selectedGeolocation?.isLastLocation ? pinIcon : circularIcon"
        [agmFitBounds]="true"
        [latitude]="selectedGeolocation.latitude"
        [longitude]="selectedGeolocation.longitude"
        [markerClickable]="true">
        <agm-info-window [disableAutoPan]="true">
          <div style="margin: 0 12px;">{{ selectedGeolocation?.correctTimestamp | date: 'dd' }} de  {{ selectedGeolocation?.correctTimestamp | date: 'MMM' }} {{ selectedGeolocation?.correctTimestamp | date: 'yyyy' }}</div>
          <div style="margin: 0 12px;">{{ selectedGeolocation?.correctTimestamp | date: 'HH:mm' }} hrs</div>
        </agm-info-window>
      </agm-marker>

      <agm-circle
        *ngIf="selectedGeolocation?.isLastLocation"
        [strokeColor]="teamColor"
        [strokeOpacity]=".8"
        [strokeWeight]="2"
        [fillColor]="teamColor"
        [fillOpacity]=".25"
        [longitude]="selectedGeolocation?.longitude || 0"
        [latitude]="selectedGeolocation?.latitude || 0"
        [radius]="200">
      </agm-circle>
    </ng-container>
  </agm-map>
</div>
