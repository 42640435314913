import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Payroll} from "../../../core/models/payroll";

@Component({
  selector: 'app-payroll-summary',
  templateUrl: './payroll-summary.component.html',
  styleUrls: ['./payroll-summary.component.scss']
})
export class PayrollSummaryComponent implements OnInit, OnChanges {
  @Input() payroll: Payroll | null;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.payroll && this.payroll) {}
  }

}
