import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {User} from '../../../core/models/user';
import {Observable, Subject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TimeZone} from '../../../core/models/time-zone';
import {Country} from '../../../core/models/country';
import {MemberService} from '../../../core/services/member.service';
import {AddressService} from '../../../core/services/address.service';
import {TeamService} from '../../../core/services/team.service';
import {TimezoneStore} from '../../../core/stores/timezone.store';
import {CountryStore} from '../../../core/stores/country.store';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {DatePipe} from '@angular/common';
import {NzDrawerRef, NzDrawerService} from 'ng-zorro-antd/drawer';
import {debounceTime, finalize, take, takeUntil} from 'rxjs/operators';
import {Address} from '../../../core/models/address';
import {Position} from '../../../core/models/position';
import {PositionService} from '../../../core/services/position.service';
import {NewPositionModalComponent} from '../new-position-modal/new-position-modal.component';
import {Team} from '../../../core/models/team';
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

@Component({
  selector: 'app-create-member-modal',
  templateUrl: './create-member-modal.component.html',
  styleUrls: ['./create-member-modal.component.scss']
})
export class CreateMemberModalComponent implements OnInit, OnDestroy {
  $onDestroy: Subject<void> = new Subject<void>();

  @Input() team: Team | null;
  @Output() memberCreated: EventEmitter<User> = new EventEmitter();

  memberForm: FormGroup;
  isLoading = false;
  error: string | null;
  $timezones: Observable<TimeZone[] | undefined> = this.timezoneStore.getTimezones();
  countries$: Observable<Country[] | undefined> = this.countryStore.getCountries();
  zipcodeLoading: boolean = false;
  zipcodeError: boolean = false;
  colonies: any[] = [];
  addressLoading: boolean;
  addressError: string | null;

  @ViewChild('newPositionTitle') newPositionTitle: TemplateRef<any>;

  positions: Position[] | null = [];
  isLoadingPositions = true;
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(new Date(), current) < 0;
  };
  formatter = (value: number) => `${(value)}` + ' ' + (value === 1 ? 'día' : 'días');
  parser = (value: string) => value.replace("día", "").replace("días", "");

  constructor(
      private memberService: MemberService,
      private addressService: AddressService,
      private teamService: TeamService,
      private timezoneStore: TimezoneStore,
      private countryStore: CountryStore,
      private formHelper: FormHelperService,
      private formBuilder: FormBuilder,
      private notification: NzNotificationService,
      private datePipe: DatePipe,
      private drawerRef: NzDrawerRef,
      private positionService: PositionService,
      private drawerService: NzDrawerService
  ) { }


  ngOnInit(): void {
    this.getPositions();
    this.createForm();
  }

  private getPositions() {
    this.positionService.getPositions()
        .pipe(finalize(() => {
          this.isLoadingPositions = false;
        }))
        .subscribe((positions: Position[] | null) => {
          this.positions = positions;
        }, error => {
          this.notification.create('error', 'Error al obtener los puestos', error.message.replace("GraphQL error:", "").trim());
        });
  }

  openNewPosition(): void {
    const modal = this.drawerService.create({
      nzContent: NewPositionModalComponent,
      nzTitle: this.newPositionTitle,
      nzPlacement: 'left',
      nzWidth: 504
    });

    modal.afterClose.pipe(take(1)).subscribe(newPosition => {
      if (newPosition === undefined || newPosition === null) {
        this.memberForm.controls.positionUid.setValue(null);
      } else {
        this.positions?.push(newPosition);
        // @ts-ignore
        this.positions = [...this.positions];
        this.memberForm.controls.positionUid.setValue(newPosition);
      }
    });
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  getImageControl(): FormControl {
    return this.memberForm.controls.photo as FormControl;
  }

  getTeamsControl(): FormControl {
    return this.memberForm.controls.assignedTeamsUid as FormControl;
  }

  getTeamControl(): FormControl {
    return this.memberForm.controls.teamUid as FormControl;
  }

  getAddressForm(): FormGroup {
    return this.memberForm.controls.addressData as FormGroup;
  }

  closeModal() {
    this.drawerRef.close();
  }

  submitForm() {
    if (this.memberForm.valid) {
      this.isLoading = true;
      this.error = null;
      this.memberService.createMember(this.cleanData(this.memberForm.getRawValue()))
          .pipe(finalize(() => {
            this.isLoading = false;
          }))
          .subscribe((member: User | null) => {
            this.notification.create('success', '¡Operación exitosa!', 'Miembro creado correctamente');
            // @ts-ignore
            this.memberCreated.emit(member);
            this.drawerRef.close();
          }, error => {
            // this.error = error.message.replace("GraphQL error:", "").trim();
            this.notification.create('error', 'Error al crear miembro', error.message.replace("GraphQL error:", "").trim());
          });
    } else {
      this.formHelper.markFormAsDirty(this.memberForm);
      this.formHelper.markGroupAsDirty(this.memberForm, 'addressData');
    }
  }

  cleanData(data: any): any {
    const form = data;
    const teams: string[] = [];
    form.assignedTeamsUid.forEach((team: any) => {
      teams.push(team.uid);
    });
    if (form.positionUid) {
      form.positionUid = form.positionUid.uid;
    }
    form.assignedTeamsUid = teams;
    form.teamUid = form.teamUid.length > 0 ? form.teamUid[0].uid : null;
    form.birthDate = form.birthDate ? this.datePipe.transform(form.birthDate, 'yyyy-MM-dd') : null;
    form.contractDate = form.contractDate ? this.datePipe.transform(form.contractDate, 'yyyy-MM-dd') : null;
    if (form.photo) {
      form.photo = form.photo.uid;
    }
    form.checksInWithTeam = !form.checksInWithTeam;
    delete form.actualVacationsCheck;
    return form;
  }

  vacationsUpdate($event: boolean) {
    if ($event) {
      this.memberForm.controls.actualVacations.setValue(0);
    } else {
      this.memberForm.controls.actualVacations.setValue(null);
    }
  }

  private createForm(): void {
    this.memberForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null],
      birthDate: [null],
      phone: [null, [Validators.pattern(/\d{10}/), Validators.required]],
      assignedTeamsUid: [[]],
      positionUid: [null, [Validators.required]],
      teamUid: [this.team ? [this.team] : []],
      photo: [null],
      timezone: [null, Validators.required],
      contractDate: [null, Validators.required],
      gender: [null, Validators.required],
      checksInWithTeam: [true],
      rfc: [null, Validators.pattern(
          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
      )],
      curp: [null, Validators.pattern(
        /([A-Za-z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HMhm](AS|as|BC|bc|BS|bs|CC|cc|CL|cl|CM|cm|CS|cs|CH|ch|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne)[A-Za-z]{3}[0-9A-Za-z]\d)$/
      )],
      socialSecurityNumber: [null],
      actualVacationsCheck: [false],
      actualVacations: [null],
      addressData: this.formBuilder.group({
        latitude: [0],
        longitude: [0],
        country: ['MX', [Validators.required]],
        state: [null, [Validators.required]],
        colony: [null, [Validators.required]],
        city: [null, [Validators.required]],
        zipcode: [null, [Validators.required]],
        street: [null, [Validators.required]],
        interiorNumber: [null],
        exteriorNumber: [null, [Validators.required]]
      })
    });

    this.memberForm.controls.positionUid.valueChanges.subscribe(value => {
      if (value !== null && value.name === 'new') {
        this.openNewPosition();
      }
    });

    this.getAddressForm().controls.zipcode.valueChanges
        .pipe(
            takeUntil(this.$onDestroy),
            debounceTime(500)
        ).subscribe(zipcode => {
      if (zipcode && this.getAddressForm().controls.country.value) {
        this.getColonies(zipcode, this.getAddressForm().controls.country.value);
      }
    });

    this.getAddressForm().controls.country.valueChanges
        .pipe(
            takeUntil(this.$onDestroy)
        ).subscribe(country => {
      if (country && this.getAddressForm().controls.zipcode.value) {
        this.getColonies(this.getAddressForm().controls.zipcode.value, country);
      }
    });

    this.memberForm.controls.password.valueChanges.subscribe(value => {
      if (value !== null && value === '') {
        this.memberForm.controls.password.setValue(null);
      }
    });
  }

  private setAddress(address: Address | null) {
    if (address) {
      this.zipcodeError = false;
      this.colonies = address.colony;
      this.getAddressForm().controls.state.setValue(address.state);
      this.getAddressForm().controls.city.setValue(address.city);
      this.getAddressForm().controls.colony.setValue(address.colony[0]);
    } else {
      this.zipcodeError = true;
      this.colonies = [];
      this.getAddressForm().controls.colony.setValue(null);
      this.getAddressForm().controls.state.setValue(null);
      this.getAddressForm().controls.city.setValue(null);
    }
  }

  private getColonies(zipcode: string, country: string) {
    this.zipcodeLoading = true;
    this.zipcodeError = false;
    this.addressService.getAddressByZipcode(zipcode, country)
        .pipe(
            finalize(() => {
              this.zipcodeLoading = false;
            })
        )
        .subscribe(
            address => {
              this.setAddress(address);
            }
        )
  }
}
