import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {AnnouncementsTableFilter} from "../models/announcements-table-filter";
import {Observable} from "rxjs";
import {AnnouncementTable} from "../models/announcement-table";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {PayrollTableFilters} from "../models/payroll-table-filters";
import {PayrollTable} from "../models/payroll-table";
import {Payroll} from "../models/payroll";
import {PaycheckSummary} from "../models/paycheck-summary";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {Paycheck} from "../models/paycheck";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  constructor(private apollo: Apollo,
              private authService: AuthenticationService,
              private http: HttpClient) { }

  getPayrollTable(filters: PayrollTableFilters): Observable<PayrollTable> {
    const filtersCopy = {...filters};
    if (filtersCopy.stamped === 'all') {
      filtersCopy.stamped = null;
    }
    if (filtersCopy.disbursed === 'all') {
      filtersCopy.disbursed = null;
    }
    return this.apollo.query<{payrollTable: PayrollTable}>({
      query: gql`
        query payrollTable(
          $substring: String
          $startDate: String!
          $endDate: String!
          $skip: Int
          $limit: Int
          $stamped: String
          $disbursed: String
        ) {
          payrollTable(
            substring: $substring
            skip: $skip
            limit: $limit
            startDate: $startDate
            endDate: $endDate
            stamped: $stamped
            disbursed: $disbursed
          ) {
            count
            payrolls {
              uid
              numberId
              duration
              startDate
              endDate
              name
              employerRegistry {
                businessName
              }
              teams {
                name
                color
                icon {
                  url
                }
              }
              totalDeductions
              totalEarnings
              totalSocialSecurity
              totalNet
              totalPaid
              disbursedStatus
              stampedStatus
              countPaychecks
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              closed
            }
          }
        }
      `,
      variables: {...filtersCopy}
    }).pipe(
      map(({data}) => {
        return data.payrollTable;
      })
    );
  }

  createPayroll(form: any): Observable<Payroll> {
    return this.apollo.mutate<{createPayroll: {payroll: Payroll}}>({
      mutation: gql`
        mutation createPayroll(
            $deductionsUid: [String]!
            $earningsUid: [String]!
            $employerRegistryUid: String!
            $endDate: Date!
            $name: String!
            $startDate: Date!
            $teamsUid: [String]!
            $temporalCategory: String!
            $taxableCategory: String!
        ) {
          createPayroll(
            deductionsUid: $deductionsUid
            earningsUid: $earningsUid
            employerRegistryUid: $employerRegistryUid
            endDate: $endDate
            name: $name
            startDate: $startDate
            teamsUid: $teamsUid
            temporalCategory: $temporalCategory
            taxableCategory: $taxableCategory
          ) {
            payroll {
              uid
              numberId
              duration
              startDate
              endDate
              name
              employerRegistry {
                businessName
              }
              teams {
                color
                icon {
                  url
                }
              }
              totalDeductions
              totalEarnings
              totalSocialSecurity
              totalNet
              totalPaid
              disbursedStatus
              stampedStatus
              countPaychecks
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              closed
            }
          }
        }
      `,
      variables: {...form}
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.createPayroll.payroll;
      })
    );
  }

  getPayroll(payrollUid: string): Observable<Payroll> {
    return this.apollo.query<{payroll: Payroll}>({
      query: gql`
        query payroll($payrollUid: String!) {
          payroll(payrollUid: $payrollUid) {
            uid
            name
            numberId
            startDate
            endDate
            employerRegistry {
              businessName
              proportionalAbsencesPolicy
              riskPercentage
              holidayBonusDays
              holidayBonusPercentage
              ISNPercentage
            }
            totalEarnings
            totalDeductions
            totalNet
            totalSocialSecurity
            totalPaid
            disbursedStatus
            stampedStatus
            temporalCategory
            CFDIInvoice {
              zipFileName
              zipFileUrl
            }
            closed
          }
        }
      `,
      variables: {
        payrollUid
      }
    }).pipe(
      map(({data}) => {
        return data.payroll;
      })
    );
  }

  getPayrollPaycheckTable(filters: any): Observable<Payroll> {
    return this.apollo.query<{payroll: Payroll}>({
      query: gql`
        query payroll($payrollUid: String! $skip: Int $limit: Int $substring: String) {
          payroll(payrollUid: $payrollUid) {
            uid
            name
            numberId
            startDate
            endDate
            employerRegistry {
              businessName
              proportionalAbsencesPolicy
              riskPercentage
              holidayBonusDays
              holidayBonusPercentage
              ISNPercentage
            }
            totalEarnings
            totalDeductions
            totalNet
            totalSocialSecurity
            totalPaid
            disbursedStatus
            stampedStatus
            temporalCategory
            CFDIInvoice {
              zipFileName
              zipFileUrl
            }
            closed
            paychecksTable (skip: $skip, limit: $limit, substring: $substring) {
              count
              paychecks {
                uid
                name
                payrollDuration
                totalEarnings
                totalDeductions
                totalNet
                totalPaid
                employee {
                  firstName
                  lastName
                  email
                  photo {
                    url
                  }
                  currentSalary {
                    paidAmount
                    hoursWorked
                    imssPaidAmount
                  }
                }
                disbursedStatus
                stampedStatus
                CFDIInvoice {
                  zipFileName
                  zipFileUrl
                }
                labouredDays
                absentDays
                incapacityDays
                vacationDays
                doubleHours
                tripleHours
                extraHoursDays
                earnings {
                  uid
                  name
                  colour
                  icon {
                    uid
                    url
                  }
                  conceptCode
                  dataType
                  isDefault
                  worth
                  imssAmount
                  imssDiscount
                  imssCategory
                  isrAmount
                  isrDiscount
                  isrCategory
                  conceptString
                }
                deductions {
                  uid
                  name
                  amount
                  colour
                  icon {
                    uid
                    url
                  }
                  conceptCode
                  dataType
                  isDefault
                  conceptString
                }
                payroll {
                  temporalCategory
                }
              }
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        data.payroll.paychecksTable?.paychecks?.forEach(paycheck => {
          paycheck.earnings?.forEach(earning => {
            earning.editWorth = earning.worth;
          });
          paycheck.deductions?.forEach(deduction => {
            deduction.editAmount = deduction.amount;
          });
        });
        return data.payroll;
      })
    );
  }

  getPaycheckSummary(paycheckUid: string | undefined): Observable<PaycheckSummary> {
    return this.apollo.query<{paycheckSummary: PaycheckSummary}>({
      query: gql`
        query paycheckSummary($paycheckUid: String!) {
          paycheckSummary(paycheckUid: $paycheckUid) {
            daysLaboured
            extraHoursLaboured
            hoursLaboured
            maxVacationDays
            nonAttendances
            notOnLocation
            notOnTime
            remainingVacationDays
            usedVacationDays
          }
        }
      `,
      variables: {
        paycheckUid
      }
    }).pipe(
      map(({data}) => {
        return data.paycheckSummary;
      })
    );
  }

  downloadFile(url: string, params: any): Observable<any> {
    const header = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.authService.credentials?.authToken,
    );
    header.set("content-type", "application/json");
    return this.http.post(url, params, {
      responseType: "blob",
      headers: header,
      observe: "response"
    });
  }

  downloadFile2(url: string | undefined): Observable<any> {
    return this.http.get(url || '', {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Content-Type",
        "Cache-Control":
          "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
        Pragma: "no-cache",
        Expires: "0"
      }
    });
  }

  downloadDisburseFile(url: string | undefined): Observable<any> {
    return this.http.get(url || '', {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Content-Type",
        "Cache-Control":
          "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
        Pragma: "no-cache",
        Expires: "0"
      }
    });
  }

  deletePaycheck(paycheckUid: string | undefined): Observable<string> {
    return this.apollo.mutate<{deletePaycheck: {message: string}}>({
      mutation: gql`
        mutation deletePaycheck($paycheckUid: String!) {
          deletePaycheck(paycheckUid: $paycheckUid) {
            message
          }
        }
      `,
      variables: {
        paycheckUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deletePaycheck.message;
      })
    );
  }

  addMember(form: any): Observable<Paycheck> {
    return this.apollo.mutate<{addPaycheck: {paycheck: Paycheck}}>({
      mutation: gql`
        mutation addPaycheck($employeeUid: String! $payrollUid: String!) {
          addPaycheck(employeeUid: $employeeUid payrollUid: $payrollUid) {
            paycheck {
              uid
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.addPaycheck.paycheck;
      })
    );
  }

  updatePaycheck(form: any): Observable<Paycheck> {
    return this.apollo.mutate<{updatePaycheck: {paycheck: Paycheck}}>({
      mutation: gql`
        mutation updatePaycheck(
            $absentDays: Int!
            $doubleHours: Int!
            $incapacityDays: Int!
            $labouredDays: Float!
            $paycheckUid: String!
            $tripleHours: Int!
            $vacationDays: Int!
            $extraHoursDays: Int!
            $salaryData: SalaryInputType!
        ) {
          updatePaycheck(
            absentDays: $absentDays
            doubleHours: $doubleHours
            incapacityDays: $incapacityDays
            labouredDays: $labouredDays
            paycheckUid: $paycheckUid
            tripleHours: $tripleHours
            vacationDays: $vacationDays
            extraHoursDays: $extraHoursDays
            salaryData: $salaryData
          ) {
            paycheck {
              uid
              name
              payrollDuration
              totalEarnings
              totalDeductions
              totalNet
              totalPaid
              employee {
                firstName
                lastName
                email
                photo {
                  url
                }
                currentSalary {
                  paidAmount
                  hoursWorked
                  imssPaidAmount
                }
              }
              disbursedStatus
              stampedStatus
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              labouredDays
              absentDays
              incapacityDays
              vacationDays
              doubleHours
              tripleHours
              extraHoursDays
              earnings {
                uid
                name
                colour
                icon {
                  uid
                  url
                }
                conceptCode
                dataType
                isDefault
                worth
                imssAmount
                imssDiscount
                imssCategory
                isrAmount
                isrDiscount
                isrCategory
                conceptString
              }
              deductions {
                uid
                name
                amount
                colour
                icon {
                  uid
                  url
                }
                conceptCode
                dataType
                isDefault
                conceptString
              }
              payroll {
                temporalCategory
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        data.updatePaycheck.paycheck.earnings.forEach(earning => {
          earning.editWorth = earning.worth;
        });
        // @ts-ignore
        data.updatePaycheck.paycheck.deductions.forEach(deduction => {
          deduction.editAmount = deduction.amount;
        });
        // @ts-ignore
        return data.updatePaycheck.paycheck;
      })
    );
  }

  updatePaycheckEarnings(form: any): Observable<Paycheck> {
    return this.apollo.mutate<{updatePaycheckEarnings: {paycheck: Paycheck}}>({
      mutation: gql`
        mutation updatePaycheckEarnings(
            $earningsData: [PaycheckConceptInput]!
            $paycheckUid: String!
        ) {
          updatePaycheckEarnings(
            earningsData: $earningsData
            paycheckUid: $paycheckUid
          ) {
            paycheck {
              uid
              name
              payrollDuration
              totalEarnings
              totalDeductions
              totalNet
              totalPaid
              employee {
                firstName
                lastName
                email
                photo {
                  url
                }
                currentSalary {
                  paidAmount
                  hoursWorked
                  imssPaidAmount
                }
              }
              disbursedStatus
              stampedStatus
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              labouredDays
              absentDays
              incapacityDays
              vacationDays
              doubleHours
              tripleHours
              extraHoursDays
              earnings {
                uid
                name
                colour
                icon {
                  uid
                  url
                }
                conceptCode
                dataType
                isDefault
                worth
                imssAmount
                imssDiscount
                imssCategory
                isrAmount
                isrDiscount
                isrCategory
                conceptString
              }
              deductions {
                uid
                name
                amount
                colour
                icon {
                  uid
                  url
                }
                conceptCode
                dataType
                isDefault
                conceptString
              }
              payroll {
                temporalCategory
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        data.updatePaycheckEarnings.paycheck.earnings.forEach(earning => {
          earning.editWorth = earning.worth;
        });
        // @ts-ignore
        data.updatePaycheckEarnings.paycheck.deductions.forEach(deduction => {
          deduction.editAmount = deduction.amount;
        });
        // @ts-ignore
        return data.updatePaycheckEarnings.paycheck;
      })
    );
  }

  updatePaycheckDeductions(form: any): Observable<Paycheck> {
    return this.apollo.mutate<{updatePaycheckDeductions: {paycheck: Paycheck}}>({
      mutation: gql`
        mutation updatePaycheckDeductions(
          $deductionsData: [PaycheckConceptInput]!
          $paycheckUid: String!
        ) {
          updatePaycheckDeductions(
            deductionsData: $deductionsData
            paycheckUid: $paycheckUid
          ) {
            paycheck {
              uid
              name
              payrollDuration
              totalEarnings
              totalDeductions
              totalNet
              totalPaid
              employee {
                firstName
                lastName
                email
                photo {
                  url
                }
                currentSalary {
                  paidAmount
                  hoursWorked
                  imssPaidAmount
                }
              }
              disbursedStatus
              stampedStatus
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              labouredDays
              absentDays
              incapacityDays
              vacationDays
              doubleHours
              tripleHours
              extraHoursDays
              earnings {
                uid
                name
                colour
                icon {
                  uid
                  url
                }
                conceptCode
                dataType
                isDefault
                worth
                imssAmount
                imssDiscount
                imssCategory
                isrAmount
                isrDiscount
                isrCategory
                conceptString
              }
              deductions {
                uid
                name
                amount
                colour
                icon {
                  uid
                  url
                }
                conceptCode
                dataType
                isDefault
                conceptString
              }
              payroll {
                temporalCategory
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        data.updatePaycheckDeductions.paycheck.earnings.forEach(earning => {
          earning.editWorth = earning.worth;
        });
        // @ts-ignore
        data.updatePaycheckDeductions.paycheck.deductions.forEach(deduction => {
          deduction.editAmount = deduction.amount;
        });
        // @ts-ignore
        return data.updatePaycheckDeductions.paycheck;
      })
    );
  }

  stampPayroll(payrollUid: string | undefined): Observable<Payroll> {
    return this.apollo.mutate<{stampPayroll: {payroll: Payroll}}>({
      mutation: gql`
        mutation stampPayroll(
          $payrollUid: String!
        ) {
          stampPayroll(
            payrollUid: $payrollUid
          ) {
            payroll {
              uid
              name
              numberId
              startDate
              endDate
              employerRegistry {
                businessName
                proportionalAbsencesPolicy
                riskPercentage
                holidayBonusDays
                holidayBonusPercentage
                ISNPercentage
              }
              totalEarnings
              totalDeductions
              totalNet
              totalSocialSecurity
              totalPaid
              disbursedStatus
              stampedStatus
              temporalCategory
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              closed
            }
          }
        }
      `,
      variables: {
        payrollUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.stampPayroll.payroll;
      })
    );
  }

  closePayroll(payrollUid: string | undefined): Observable<Payroll> {
    return this.apollo.mutate<{closePayroll: {payroll: Payroll}}>({
      mutation: gql`
        mutation closePayroll(
          $payrollUid: String!
        ) {
          closePayroll(
            payrollUid: $payrollUid
          ) {
            payroll {
              uid
              name
              numberId
              startDate
              endDate
              employerRegistry {
                businessName
                proportionalAbsencesPolicy
                riskPercentage
                holidayBonusDays
                holidayBonusPercentage
                ISNPercentage
              }
              totalEarnings
              totalDeductions
              totalNet
              totalSocialSecurity
              totalPaid
              disbursedStatus
              stampedStatus
              temporalCategory
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              closed
            }
          }
        }
      `,
      variables: {
        payrollUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.closePayroll.payroll;
      })
    );
  }

  uploadFormat(payrollUid: string, file: File): Observable<Payroll> {
    const formData = new FormData();
    formData.append("document_file", file);
    const uploadFormat =
      `mutation {
      updatePaychecks(payrollUid: "` + payrollUid + `") {
         payroll {
            uid
              name
              numberId
              startDate
              endDate
              employerRegistry {
                businessName
                proportionalAbsencesPolicy
                riskPercentage
                holidayBonusDays
                holidayBonusPercentage
                ISNPercentage
              }
              totalEarnings
              totalDeductions
              totalNet
              totalSocialSecurity
              totalPaid
              disbursedStatus
              stampedStatus
              temporalCategory
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              closed
         }
        }
      }`;
    // @ts-ignore
    const header = new HttpHeaders().set("Authorization", "JWT " + this.authService.credentials.authToken);
    return this.http
      .post<{
        data: {
          updatePaychecks: {
            payroll: Payroll;
          };
        };
      }>(environment.apiUrl + "/graphql/?query=" + uploadFormat, formData, {
        headers: header
      })
      .pipe(
        map(res => {
          if (res.data.updatePaychecks) {
            return res.data.updatePaychecks.payroll;
          } else {
            throw Error("Hubo un problema al cargar el archivo.");
          }
        })
      );
  }

  disbursePayroll(payrollUid: string | undefined): Observable<Payroll> {
    return this.apollo.mutate<{disbursePayroll: {payroll: Payroll}}>({
      mutation: gql`
        mutation disbursePayroll(
          $payrollUid: String!
        ) {
          disbursePayroll(
            payrollUid: $payrollUid
          ) {
            payroll {
              uid
              name
              numberId
              startDate
              endDate
              employerRegistry {
                businessName
                proportionalAbsencesPolicy
                riskPercentage
                holidayBonusDays
                holidayBonusPercentage
                ISNPercentage
              }
              totalEarnings
              totalDeductions
              totalNet
              totalSocialSecurity
              totalPaid
              disbursedStatus
              stampedStatus
              temporalCategory
              CFDIInvoice {
                zipFileName
                zipFileUrl
              }
              closed
            }
          }
        }
      `,
      variables: {
        payrollUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.disbursePayroll.payroll;
      })
    );
  }

}
