import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MassiveLoadService {

  constructor(private apollo: Apollo,
              private authService: AuthenticationService,
              private http: HttpClient) { }

  downloadFile(url: string, params: any = null): Observable<any> {
    const header = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.authService.credentials?.authToken,
    );
    header.set("content-type", "application/json");
    return this.http.post(url, params, {
      responseType: "blob",
      headers: header,
      observe: "response"
    });
  }

  downloadFile2(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Content-Type",
        "Cache-Control":
          "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
        Pragma: "no-cache",
        Expires: "0"
      }
    });
  }

  uploadFileEmployees(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("document_file", file);
    const createMultipleEmployees =
      `mutation{
      createMultipleEmployees {
         message
        }
      }`;
    const header = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.authService.credentials?.authToken
    );
    return this.http.post<{createMultipleEmployees: {message: string}}>(environment.apiUrl + "/graphql/?query=" + createMultipleEmployees, formData, {
        headers: header
      });
  }

  uploadFilePositions(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("document_file", file);
    const createMultiplePositions =
      `mutation{
      createMultiplePositions {
         message
        }
      }`;
    const header = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.authService.credentials?.authToken
    );
    return this.http.post<{createMultiplePositions: {message: string}}>(environment.apiUrl + "/graphql/?query=" + createMultiplePositions, formData, {
      headers: header
    });
  }

  uploadFileAddresses(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("document_file", file);
    const createMultipleGeoFencings =
      `mutation{
      createMultipleGeoFencings {
         message
        }
      }`;
    const header = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.authService.credentials?.authToken
    );
    return this.http.post<{createMultipleGeoFencings: {message: string}}>(environment.apiUrl + "/graphql/?query=" + createMultipleGeoFencings, formData, {
      headers: header
    });
  }
}
