import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmployerRegistry} from "../../../core/models/employer-registry";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EmployerRegistryService} from "../../../core/services/employer-registry.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {finalize} from "rxjs/operators";
import {ClientCertificate} from "../../../core/models/client-certificate";

@Component({
  selector: 'app-employer-registry-details-modal',
  templateUrl: './employer-registry-details-modal.component.html',
  styleUrls: ['./employer-registry-details-modal.component.scss']
})
export class EmployerRegistryDetailsModalComponent implements OnInit {
  @Output() updateCertificates = new EventEmitter<ClientCertificate | null>();
  @Input() employerRegistry: EmployerRegistry | null;
  certificateForm: FormGroup;
  showForm = false;
  isLoading = false;
  isDeleting = false;
  isDownloading = false;

  constructor(private employerRegistryService: EmployerRegistryService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    this.showForm = this.employerRegistry?.certificate !== null;
    this.createForm();
  }

  downloadFiles() {
    this.downloadCerFile();
    this.downloadKeyFile();
  }

  downloadCerFile() {
    this.isDownloading = true;
    this.employerRegistryService.downloadFile2(this.employerRegistry?.certificate?.cer?.url || '')
      .pipe(finalize(() => {
        this.isDownloading = false;
      }))
      .subscribe(data => {
        const temp = this.employerRegistry?.certificate?.cer?.url?.split('/');
        // @ts-ignore
        saveAs(data, temp[temp.length - 1].toString());
      }, error => {
        this.notification.create('error', 'Error al descargar el archivo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  downloadKeyFile() {
    this.isDownloading = true;
    this.employerRegistryService.downloadFile2(this.employerRegistry?.certificate?.key?.url || '')
      .pipe(finalize(() => {
        this.isDownloading = false;
      }))
      .subscribe(data => {
        const temp = this.employerRegistry?.certificate?.key?.url?.split('/');
        // @ts-ignore
        saveAs(data, temp[temp.length - 1].toString());
      }, error => {
        this.notification.create('error', 'Error al descargar el archivo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  keyFileChange($event: any) {
    const file: File = $event.target.files[0];
    if (file) {
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], file.name, { type: file.type });
      this.certificateForm.controls.key_file.setValue(newFile);
    }
  }

  cerFileChange($event: any) {
    const file: File = $event.target.files[0];
    if (file) {
      const blob = file.slice(0, file.size, file.type);
      const newFile = new File([blob], file.name, { type: file.type });
      this.certificateForm.controls.cer_file.setValue(newFile);
    }
  }

  deleteCertificates() {
    this.isDeleting = true;
    this.employerRegistryService.deleteCertificates(this.employerRegistry?.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', 'Certificados eliminados correctamente');
        // @ts-ignore
        this.employerRegistry?.certificate = null;
        this.createForm();
        this.showForm = true;
        this.updateCertificates.emit(null);
      }, error => {
        this.notification.create('error', 'Error al eliminar los certificados', error.message.replace("GraphQL error:", "").trim());
      });
  }

  uploadCertificates() {
    if (this.certificateForm.valid) {
      this.isLoading = true;
      this.employerRegistryService.saveCertificateData(this.certificateForm)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((certificate: ClientCertificate) => {
          this.notification.create('success', '¡Operación exitosa!', 'Certificados y contraseña agregados correctamente');
          // @ts-ignore
          this.employerRegistry?.certificate = certificate;
          this.showForm = this.employerRegistry?.certificate !== null;
          this.createForm();
          this.updateCertificates.emit(certificate);
        }, error => {
          this.notification.create('error', 'Error al subir los certificados', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.certificateForm);
    }
  }

  private createForm() {
    this.certificateForm = this.formBuilder.group({
      employerRegistryUid: [this.employerRegistry?.uid, Validators.required],
      passphrase: [null, Validators.required],
      cer_file: [null, Validators.required],
      key_file: [null, Validators.required],
    });
  }
}
