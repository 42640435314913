<div class="employer-registry-row flex between wrap align-center">
  <div class="warning-content">
    <i *ngIf="employerRegistry?.certificate === null" nz-icon nzType="warning" nzTheme="fill" nzTooltipColor="white" nz-tooltip="Pendiente .cer y .key"></i>
  </div>

  <div class="name-content">
    <div class="name">{{ employerRegistry?.businessName }}</div>
  </div>

  <div class="amount-content">
    <div class="amount"><b class="bold">NPIE:</b> {{ employerRegistry?.NPIEId }}</div>
  </div>

  <div class="name-content">
    <div class="name">{{ employerRegistry?.socialReason }}</div>
  </div>

  <div class="amount-content">
    <div class="amount"><b class="bold">RFC:</b> {{ employerRegistry?.rfc }}</div>
  </div>

  <div class="name-content">
    <div class="name">{{ employerRegistry?.ssRisk?.category }} - {{ employerRegistry?.ssRisk?.percentage }}%</div>
  </div>

  <ng-container>
    <div class="deduction-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" [nz-tooltip]="'Configurar registro patronal'" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="setting" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item  (click)="viewEmployerRegistry()" [nzDisabled]="isDeleting"><i class="empty-icon grey" nz-icon nzType="icons:employer-registry" nzTheme="outline"></i> Detalles de registro patronal</li>
        <li nz-menu-item *ngIf="!employerRegistry?.hasPayrolls" (click)="editEmployerRegistry()" [nzDisabled]="isDeleting"><i class="empty-icon grey" nz-icon nzType="edit" nzTheme="outline"></i> Editar registro patronal</li>
        <li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting" *ngIf="!employerRegistry?.hasPayrolls"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar este registro patronal?"
            (nzOnConfirm)="deleteEmployerRegistry()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar registro patronal</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
