import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {DeductionsService} from "../../../core/services/deductions.service";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {Deduction} from "../../../core/models/deduction";
import {Subject} from "rxjs";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {NewDeductionModalComponent} from "../../modals/new-deduction-modal/new-deduction-modal.component";

@Component({
  selector: 'app-deductions-form-control',
  templateUrl: './deductions-form-control.component.html',
  styleUrls: ['./deductions-form-control.component.scss']
})
export class DeductionsFormControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() deductions: FormControl
  @Input() defaultDeductions: Deduction[] = [];
  @Input() moreThanOne: boolean = true;
  @Input() blockDefaults = false;
  @Output() updateTotals = new EventEmitter<void>();
  deductionsCount = 0;

  list: Deduction[] = [];
  deductionsLoading = false;
  isOpen = false;
  deductionsIndex = 1;
  stopLoading = false;
  searchDeduction: null | string;
  $searchChange: Subject<string> = new Subject<string>();
  firstSearch = true;

  $onDestroy: Subject<void> = new Subject<void>();
  compareDeductions = (o1: Deduction, o2: Deduction) => o1?.uid === o2?.uid;

  constructor(private deductionsService: DeductionsService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.deductionsCount = this.deductions.value.length;
    this.loadDeductions();
    this.subscribeToSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultDeductions) {
      const tempList: Deduction[] = [];
      this.deductions?.value?.forEach((deduction: Deduction) => {
        const index = this.defaultDeductions.findIndex(d => d.uid === deduction.uid);
        if (index === -1) {
         if (!deduction.isDefault) {
           tempList.push(deduction);
         }
        }
      });
      this.defaultDeductions.reverse().forEach(deduction => {
        tempList.unshift(deduction);
      });
      this.deductions.setValue(tempList);
    }
  }

  ngOnDestroy(): void {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  openNewDeduction() {
    const modal = this.drawerService.create({
      nzContent: NewDeductionModalComponent,
      nzTitle: 'Nueva deducción',
      nzWidth: 504,
      nzPlacement: 'left'
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((deduction: Deduction) => {
      if (deduction) {
        this.list?.unshift(deduction);
        this.deductions.setValue([...this.deductions.value, deduction]);
        this.updateTotals.emit();
      }
    })
  }

  removeDeduction(deduction: Deduction) {
    this.deductions.setValue(
      this.deductions.value.filter((el: Deduction) => el.uid != deduction.uid)
    );
    this.updateTotals.emit();
  }

  updateAction($event: any) {
    const count = $event.length;
    if (count > this.deductionsCount) {
      this.deductions.value[count - 1].editAmount = this.deductions.value[count - 1].amount;
    }
    this.deductionsCount = $event.length - 1;
    this.updateTotals.emit();
  }


  onDeductionsSearch($event: string) {
    if (!this.firstSearch) {
      this.stopLoading = false;
      this.list = [];
      this.searchDeduction = $event === "" ? null : $event;
      this.deductionsIndex = 1;
      this.loadDeductions();
    }
    this.firstSearch = false;
  }

  loadDeductions() {
    if (!this.stopLoading) {
      this.deductionsLoading = true;
      this.deductionsService.getDeductions({
        substring: this.searchDeduction,
        skip: this.deductionsIndex,
        limit: 1000
      }).pipe(
        finalize(() => {
          this.deductionsLoading = false;
        })
      )
        .subscribe(DeductionsTable => {
          if (DeductionsTable?.deductions?.length) {
            const tempList: Deduction[] = [];
            this.list.forEach(deduction => {
              if (this.defaultDeductions.length > 0) {
                const index = this.defaultDeductions.findIndex(d => d.uid === deduction.uid);
                if (index === -1) {
                  tempList.push(deduction);
                }
              } else {
                tempList.push(deduction);
              }
            });
            DeductionsTable?.deductions.forEach(deduction => {
              if (this.defaultDeductions.length > 0) {
                const index = this.defaultDeductions.findIndex(d => d.uid === deduction.uid);
                if (index === -1) {
                  tempList.push(deduction);
                }
              } else {
                tempList.push(deduction);
              }
            });
            if (this.defaultDeductions.length > 0) {
              this.defaultDeductions.reverse().forEach(deduction => {
                tempList.unshift(deduction);
              });
            }
            this.list = tempList;
          } else {
            this.stopLoading = true;
          }
        });
      this.deductionsIndex++;
    }
  }

  notSelected(deduction: Deduction) {
    const index = this.list.findIndex(e => e.uid === deduction.uid);
    return index !== -1;

  }

  private subscribeToSearch() {
    this.$searchChange
      .pipe(takeUntil(this.$onDestroy), debounceTime(400))
      .subscribe(search => {
        this.onDeductionsSearch(search);
      });
  }

}
