import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Marker} from '../../../core/models/marker';
import {GeoFencing} from '../../../core/models/geo-fencing';

@Component({
  selector: 'app-attendance-map',
  templateUrl: './attendance-map.component.html',
  styleUrls: ['./attendance-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceMapComponent implements OnInit {
  @Input() pin: Marker;
  @Input() geoFencings: GeoFencing[] | undefined;

  geoIcon: any = {
    url: "../assets/images/trabajo.png",
    scaledSize: {
      width: 32,
      height: 38
    }
  };

  icon: any = {
    url: "../assets/images/pin_time.png",
    scaledSize: {
      width: 32,
      height: 38
    }
  };

  constructor() { }

  ngOnInit(): void {

  }

}
