import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {Apollo} from "apollo-angular";
import {EmployerRegistryTable} from "../models/employer-registry-table";
import {SocialSecurityRisk} from "../models/social-security-risk";
import {EmployerRegistry} from "../models/employer-registry";
import {FormGroup} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class EmployerRegistryService {

  constructor(private apollo: Apollo,
              private authService: AuthenticationService,
              private http: HttpClient) { }

  getEmployerRegistries(filters: any): Observable<EmployerRegistryTable | undefined> {
    return this.apollo.query<{employerRegistryTable : EmployerRegistryTable}>({
      query: gql`
        query employerRegistryTable ($substring: String $skip: Int $limit: Int) {
          employerRegistryTable (substring: $substring skip: $skip limit: $limit) {
            count
            employerRegistries {
              uid
              numberId
              businessName
              NPIEId
              socialReason
              rfc
              ssRisk {
                category
                percentage
                uid
              }
              ISNPercentage
              holidayBonusDays
              holidayBonusPercentage
              proportionalAbsencesPolicy
              riskPercentage
              hasPayrolls
              clabe
              certificate {
                cer {
                  url
                }
                key {
                  url
                }
              }
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        return data.employerRegistryTable ;
      })
    )
  }

  deleteEmployerRegistry(employerRegistryUid: string | undefined): Observable<string> {
    return this.apollo.mutate<{deleteEmployerRegistry: {message: string}}>({
      mutation: gql`
        mutation deleteEmployerRegistry($employerRegistryUid: String!) {
          deleteEmployerRegistry(employerRegistryUid: $employerRegistryUid) {
            message
          }
        }
      `,
      variables: {
        employerRegistryUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deleteEmployerRegistry.message;
      })
    )
  }

  getSsrisks(): Observable<SocialSecurityRisk[] | undefined> {
    return this.apollo.query<{socialSecurityRisks : SocialSecurityRisk[]}>({
      query: gql`
        query socialSecurityRisks {
          socialSecurityRisks {
            category
            percentage
            uid
          }
        }
      `,
    }).pipe(
      map(({data}) => {
        return data.socialSecurityRisks ;
      })
    )
  }

  createEmployerRegistry(filters: any): Observable<EmployerRegistry> {
    return this.apollo.mutate<{createEmployerRegistry: { employerRegistry: EmployerRegistry }}>({
      mutation: gql`
        mutation createEmployerRegistry (
            $ISNPercentage: Float!
            $NPIEId: String!
            $businessName: String!
            $clabe: String!
            $holidayBonusDays: Int!
            $holidayBonusPercentage: Float!
            $proportionalAbsencesPolicy: Boolean!
            $rfc: String!
            $riskPercentage: Float!
            $socialReason: String!
            $ssRiskUid: String!
        ) {
          createEmployerRegistry (
            ISNPercentage: $ISNPercentage
            NPIEId: $NPIEId
            businessName: $businessName
            clabe: $clabe
            holidayBonusDays: $holidayBonusDays
            holidayBonusPercentage: $holidayBonusPercentage
            proportionalAbsencesPolicy: $proportionalAbsencesPolicy
            rfc: $rfc
            riskPercentage: $riskPercentage
            socialReason: $socialReason
            ssRiskUid: $ssRiskUid
          ) {
            employerRegistry {
              uid
              numberId
              businessName
              NPIEId
              socialReason
              rfc
              ssRisk {
                category
                percentage
                uid
              }
              ISNPercentage
              holidayBonusDays
              holidayBonusPercentage
              proportionalAbsencesPolicy
              riskPercentage
              hasPayrolls
              clabe
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.createEmployerRegistry.employerRegistry ;
      })
    )
  }

  updateEmployerRegistry(filters: any): Observable<EmployerRegistry> {
    return this.apollo.mutate<{updateEmployerRegistry: { employerRegistry: EmployerRegistry }}>({
      mutation: gql`
        mutation updateEmployerRegistry (
          $employerRegistryUid: String!
          $ISNPercentage: Float!
          $NPIEId: String!
          $businessName: String!
          $clabe: String!
          $holidayBonusPercentage: Float!
          $proportionalAbsencesPolicy: Boolean!
          $rfc: String!
          $riskPercentage: Float!
          $socialReason: String!
          $ssRiskUid: String!
          $holidayBonusDays: Int
        ) {
          updateEmployerRegistry (
            employerRegistryUid: $employerRegistryUid
            ISNPercentage: $ISNPercentage
            NPIEId: $NPIEId
            businessName: $businessName
            clabe: $clabe
            holidayBonusPercentage: $holidayBonusPercentage
            proportionalAbsencesPolicy: $proportionalAbsencesPolicy
            rfc: $rfc
            riskPercentage: $riskPercentage
            socialReason: $socialReason
            ssRiskUid: $ssRiskUid
            holidayBonusDays: $holidayBonusDays
          ) {
            employerRegistry {
              uid
              numberId
              businessName
              NPIEId
              socialReason
              rfc
              ssRisk {
                category
                percentage
                uid
              }
              ISNPercentage
              holidayBonusDays
              holidayBonusPercentage
              proportionalAbsencesPolicy
              riskPercentage
              hasPayrolls
              clabe
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateEmployerRegistry.employerRegistry ;
      })
    )
  }

  saveCertificateData(form: FormGroup): Observable<any> {
    const formData = new FormData();
    formData.append("cer_file", form.controls.cer_file.value);
    formData.append("key_file", form.controls.key_file.value);
    const uploadCertificate =
      `
            mutation {
                uploadClientCertificate (
                    employerRegistryUid: "` + form.controls.employerRegistryUid.value + `"
                    passphrase: "` + form.controls.passphrase.value + `"
                ) {
                    clientCertificate {
                        cer {
                            url
                        }
                        key {
                            url
                        }
                    }
                }
            }
            `;
    // @ts-ignore
    const header = new HttpHeaders().set("Authorization", "JWT " + this.authService.credentials.authToken);
    return this.http
      .post<any>(environment.apiUrl + "/graphql/?query=" + uploadCertificate, formData, {
        headers: header
      })
      .pipe(
        map(res => {
          if (res.data.uploadClientCertificate?.clientCertificate) {
            return res.data.uploadClientCertificate.clientCertificate;
          } else {
            throw Error(res.errors?.length ? res.errors[0]?.message : 'Error desconocido');
          }
        })
      );
  }

  deleteCertificates(employerRegistryUid: string | undefined): Observable<string> {
    const deleteClientCertificate = gql`
      mutation deleteClientCertificate($employerRegistryUid: String!) {
        deleteClientCertificate(employerRegistryUid: $employerRegistryUid)  {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{ deleteClientCertificate: {message: string }}>({
        mutation: deleteClientCertificate,
        variables: {
          employerRegistryUid
        }
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.deleteClientCertificate.message;
        })
      );
  }

  downloadFile(url: string, params: any): Observable<any> {
    const header = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.authService.credentials?.authToken,
    );
    header.set("content-type", "application/json");
    return this.http.post(url, params, {
      responseType: "blob",
      headers: header,
      observe: "response"
    });
  }

  downloadFile2(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Content-Type",
        "Cache-Control":
          "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
        Pragma: "no-cache",
        Expires: "0"
      }
    });
  }

}
