<div class="attendance-modal">

  <div class="error-box" *ngIf="geoFencingsError">
    <nz-alert
          nzType="error"
          [nzMessage]="geoFencingsError"
          nzShowIcon
    ></nz-alert>
  </div>

  <nz-spin [nzSpinning]="geoFencingsLoading" class="map">
    <div class="map-error-box flex center align-center" *ngIf="!pin?.latitude || !pin?.longitude">
      <div class="text">
        Se realizó el checkout de forma automática
      </div>
    </div>
    <app-attendance-map
          [geoFencings]="userGeoFencings"
          [pin]="pin">
    </app-attendance-map>
  </nz-spin>

  <div class="user-row flex align-center wrap between">
    <div class="user-box flex align-center">
      <div class="user-icon">
        <nz-avatar [nzSize]="35"  [nzSrc]="member.photo?.url || undefined"
                   [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
      </div>
      <div class="user-data">
        <div class="name">
          {{member.firstName | titlecase}} {{member.lastName | titlecase}}
        </div>
        <div class="email">
          {{member.email | lowercase}}
        </div>
      </div>
    </div>

    <div class="position-box flex column">
      <div class="name">
        {{member?.position?.name}}
      </div>
      <div class="type">
        {{turn?.dayConfig?.category | titlecase}}
      </div>
    </div>
  </div>

  <div class="line"></div>

  <div class="turn-row flex wrap between align-end">
    <div class="turn-data flex column">
      <div class="title">
        Registro
      </div>
      <div class="data flex" *ngIf="turn">
        <div class="label">
          Turno:
        </div>
        <div class="value">
          {{turn?.fromHour}} - {{turn?.toHour}}
        </div>
      </div>

      <div class="data flex" *ngIf="!turn">
        <div class="label">
          Turno:
        </div>
        <div class="value">
          {{attendance?.turnFromHour}} - {{attendance?.turnToHour}}
        </div>
      </div>

      <div class="data flex">
        <div class="label">
          Hora registrada:
        </div>
        <div class="value">
          {{(timestamp | date: 'h:mm a')?.split('.')?.join('') | lowercase}}
        </div>
      </div>
    </div>

    <div class="status-row flex end">
      <div class="status green" *ngIf="attendance.onTime">
        En tiempo
      </div>
      <div class="status yellow" *ngIf="!attendance.onTime">
        Retardo
      </div>
      <div class="status green" *ngIf="attendance.onLocation">
        En ubicación
      </div>
      <div class="status yellow" *ngIf="!attendance.onLocation">
        Fuera de ubicación
      </div>
    </div>
  </div>

  <div class="line"></div>

  <div class="geo-fencings-column flex column" *ngIf="geoFencingsLoading || userGeoFencings?.length">
    <div class="title">
      Geocercas permitidas
    </div>

    <nz-spin style="margin: 20px;" *ngIf="geoFencingsLoading"></nz-spin>

    <div class="geo-fencing flex align-center" *ngFor="let geoFencing of userGeoFencings">
      <div class="icon">
        <div class="img-box">
          <img src="../../../../assets/images/location-pin.png">
        </div>
      </div>

      <div class="geo-fencing-data flex column">
        <div class="name">
          {{geoFencing.name}}
        </div>
        <div class="address">
          {{getAddress(geoFencing)}}
        </div>
      </div>
    </div>
  </div>
</div>
