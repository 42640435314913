<div class="balance-table-component">

  <div class="balance-table">
    <div class="header-row">
      <div class="actions-column header resume-extra">
        <span></span>
      </div>
      <div class="date-column header resume-extra">
        <span>Fecha</span>
      </div>
      <div class="description-column header resume-extra">
        <span>Descripción</span>
      </div>
      <div class="charge-column header resume-extra">
        <span>Cargo</span>
      </div>
      <div class="payment-column header resume-extra">
        <span>Abono</span>
      </div>
      <div class="price-column header price-extra">
        <span>Saldo</span>
      </div>
    </div>
  </div>

  <div class="balance-table">
    <nz-spin [nzSpinning]="isLoading">
      <div class="balance-row" *ngFor="let transaction of transactions; let i = index">
        <div class="actions-column row">
          <span></span>
        </div>
        <div class="date-column row">
          <span>{{ transaction?.transactionDate | date: 'dd' }} de {{ transaction?.transactionDate | date: 'MMM' }} {{ transaction?.transactionDate | date: 'yyyy' }}</span>
        </div>
        <div class="description-column row">
          <span>{{ transaction?.description ? transaction?.description : '-' }}</span>
        </div>
        <div class="charge-column row">
          <span *ngIf="transaction?.category === 'DB'">{{ transaction?.amount ? '(' + (transaction?.amount | currency) + ') MXN' : '- &nbsp; &nbsp; &nbsp; &nbsp;' }}</span>
          <span *ngIf="transaction?.category === 'CR'">{{  '- &nbsp; &nbsp; &nbsp; &nbsp;' }}</span>
        </div>
        <div class="payment-column row">
          <span *ngIf="transaction?.category === 'CR'">{{ transaction?.amount ? (transaction?.amount | currency) + ' MXN' : '- &nbsp; &nbsp; &nbsp; &nbsp;' }}</span>
          <span *ngIf="transaction?.category === 'DB'">{{  '- &nbsp; &nbsp; &nbsp; &nbsp;' }}</span>
        </div>
        <div class="price-column row price-extra">
          <span>{{ (transaction?.balance < 0 ? '(' + ((transaction?.balance * -1) | currency) + ')' : (transaction?.balance) | currency) }} MXN</span>
        </div>
      </div>

      <div class="total-container">
        <div class="total-title">Balance total al {{ lastDayOfMonth | date: 'dd/MM/yyyy' }}:</div>
        <div class="content">{{total !== null ? (total < 0 ? '(' + (total * -1 | currency) + ')' : total | currency) : 0 | currency }} MXN</div>
      </div>
    </nz-spin>
  </div>

</div>
