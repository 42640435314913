<div class="active-resource-history-modal">
  <nz-spin [nzSpinning]="isLoading" class="spin-loading">

    <div *ngIf="!isLoading && activeResource?.logs" class="active-container">
      <div *ngIf="activeResource?.photo" class="box">
        <div class="img-box">
          <div [style.backgroundImage]="'url(' + activeResource?.photo?.url + ')'" class="img-box"></div>
        </div>
      </div>

      <div *ngIf="!activeResource?.photo" class="box">
        <div [style.backgroundColor]="activeResource?.category?.color" class="active-box">
          <i nz-icon nzType="icons:monitor"></i>
        </div>
      </div>

      <div class="active-info">
        <div class="active-name">{{ activeResource?.name }}</div>
        <div class="active-sku">{{ activeResource?.serial }}</div>
      </div>
    </div>

    <div *ngIf="!isLoading && activeResource?.logs" class="history-container">
      <nz-timeline>
        <nz-timeline-item *ngFor="let log of activeResource?.logs"
                          [nzColor]="(log?.acceptedAssignment || (log?.actionMessage?.indexOf('Eliminado') === -1 && log?.acceptedAssignment===null)) ?  'green' : 'red'">
          <div class="data-container">
            <div
              *ngIf="log?.relatedPerson && (log?.actionMessage?.indexOf('Eliminado') === -1 || log?.actionDescription?.indexOf('rechazado') === -1)"
              [nz-tooltip]="log?.relatedPerson ? (log?.relatedPerson?.firstName | titlecase) + ' ' + (log?.relatedPerson?.lastName | titlecase) : ''"
              class="member-box"
              nzTooltipColor="white">
              <nz-avatar [nzGap]="5" [nzSize]="26" [nzSrc]="log?.relatedPerson?.photo?.url || undefined"
                         [nzText]="log?.relatedPerson?.firstName?.charAt(0) + '' + log?.relatedPerson?.lastName?.charAt(0)"></nz-avatar>
            </div>

            <div class="log-info">
              <div class="date">{{ log?.assignmentDate | date: 'dd/MM/yyyy' }}</div>
              <ng-container *ngIf="log?.actionDescription">
                <div class="text">{{ log?.actionDescription }}</div>
                <div *ngIf="!isLoading" class="file-container">
                  <ng-container *ngFor="let file of log?.evidence">
                    <app-file-details [file]="file"></app-file-details>
                  </ng-container>
                </div>
                <div *ngIf="!isLoading && log?.actionMessage" class="description-container">
                  <div>Descripción: <span class="description">{{ log?.actionMessage }}</span></div>
                </div>
              </ng-container>
              <ng-container *ngIf="log?.actionMessage && !log?.actionDescription">
                <div class="text">{{ log?.actionMessage }}</div>
              </ng-container>
            </div>
          </div>
        </nz-timeline-item>
      </nz-timeline>
    </div>
  </nz-spin>
</div>
