<div class="vacations-table" *ngIf="vacations">

  <div class="title">
    Vacaciones tomadas
  </div>

  <div class="table flex column" *ngIf="vacations?.length">
    <div class="row" *ngFor="let absenceRequest of vacations | slice: (index * 10 - 10) : (index * 10)">
      <app-vacation-box [member]="member" [absenceRequest]="absenceRequest"></app-vacation-box>
    </div>
  </div>

  <div class="pagination-row flex end" *ngIf="vacations?.length">
    <nz-pagination [(nzPageIndex)]="index" [nzTotal]="vacations.length"></nz-pagination>
  </div>

  <ng-container *ngIf="vacations?.length == 0">
    <nz-result nzStatus="404" nzTitle="No se encontraron vacaciones" nzSubTitle="No se encontró ninguna vacación en este rango">
    </nz-result>
  </ng-container>
</div>
