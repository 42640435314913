import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Company} from "../../../core/models/company";
import {GoogleServicesService} from "../../../core/services/google-services.service";
import GoogleUser = gapi.auth2.GoogleUser;
import {NzNotificationService} from "ng-zorro-antd/notification";
import {User} from "../../../core/models/user";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-google-row',
  templateUrl: './google-row.component.html',
  styleUrls: ['./google-row.component.scss']
})
export class GoogleRowComponent implements OnInit {
  @Input() company: Company;
  @Input() userIntegrations: User;
  isLoading = false;
  user: GoogleUser | null = null;

  constructor(private googleServices: GoogleServicesService,
              private ref: ChangeDetectorRef,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    this.googleServices.observable().subscribe((user: GoogleUser | null) => {
      this.user = user;
      this.ref.detectChanges();
    });
  }

  signOut() {
    this.isLoading = true;
    this.googleServices.signOut().then((res: string) => {
      if (res === 'success') {
        this.googleServices.disableGoogleCalendar()
          .pipe(finalize(() => {
            this.isLoading = false;
          }))
          .subscribe(async (user: User) => {
          // @ts-ignore
          this.userIntegrations?.googleCalendarActive = user.googleCalendarActive;
          // @ts-ignore
          this.userIntegrations?.googleToken = user.googleToken;
            this.notification.create('success', '¡Operación exitosa!', 'Cesión cerrada correctamente');
        }, error => {
            this.notification.create('error', 'Error', 'Ocurrió un error al cerrar sesión');
        });
      } else {
        this.notification.create('error', 'Error', 'Ocurrió un error al cerrar sesión');
      }
    });
  }

  signIn() {
    this.isLoading = true;
    this.googleServices.offlineCode().then((code: string | null) => {
      if (code !== null) {
        this.googleServices.getRefreshToken(code).subscribe(res => {
          this.googleServices.getGoogleUserInfo(res.access_token).subscribe(user => {
            this.googleServices.activateGoogleCalendar(
              res.access_token,
              res.refresh_token,
              'a',
              user.email,
              user.name,
              user.picture)
              .pipe(finalize(() => {
                this.isLoading = false;
              }))
              .subscribe(async (user: User) => {
                // @ts-ignore
                this.userIntegrations?.googleCalendarActive = user.googleCalendarActive;
                // @ts-ignore
                this.userIntegrations?.googleToken = user.googleToken;
                this.notification.create('success', '¡Operación exitosa!', 'Se inició sesión correctamente');
              }, async error => {
                this.notification.create('error', 'Error (4)', error.message.replace("GraphQL error:", "").trim()); // back error
              });
          }, error => {
            this.notification.create('error', 'Error (3)', 'Ocurrió un error al iniciar sesión'); // get google user info
            this.isLoading = false;
          });
        }, error => {
          this.notification.create('error', 'Error (2)', 'Ocurrió un error al iniciar sesión'); // refresh token error
          this.isLoading = false;
        });
      } else {
        this.notification.create('error', 'Error (1)', 'Ocurrió un error al iniciar sesión'); // google code error
        this.isLoading = false;
      }
    });
  }

}
