import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Package} from "../../../core/models/package";
import {CompanyService} from "../../../core/services/company.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {finalize} from "rxjs/operators";
import {Company} from "../../../core/models/company";
import {Subscription} from "../../../core/models/subscription";

@Component({
  selector: 'app-subscription-message',
  templateUrl: './subscription-message.component.html',
  styleUrls: ['./subscription-message.component.scss']
})
export class SubscriptionMessageComponent implements OnInit {
  @Input() plan: Package | null;
  @Input() trialMonth: boolean;
  isLoading = false;
  @Output() updatePlan: EventEmitter<Subscription | null> = new EventEmitter();

  constructor(private companyService: CompanyService,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
  }

  close() {
    this.updatePlan.emit(null);
    this.modalRef.close();
  }

  update() {
    this.isLoading = true;
    this.companyService.updateCompanySubscription(this.plan?.uid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((company: Company) => {
        this.updatePlan.emit(company.subscription);
        this.modalRef.close();
      }, error => {
        this.notification.create('error', 'Error al actualizar el plan', error.message.replace("GraphQL error:", "").trim());
      });
  }

}
