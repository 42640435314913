import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import {AuthenticationService} from '../services/authentication.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(
      private router: Router,
      private authenticationService: AuthenticationService
  ) {}

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authenticationService.checkSession().pipe(map(
        token => {
          if (token) {
            return true;
          } else {
            this.authenticationService.resetSession();
            this.router
                .navigate(["./session"], {
                  queryParams: {
                    redirect: state.url
                  },
                  replaceUrl: true
                });
            return false;
          }
        }
    ))
  }
}
