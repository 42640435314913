import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { InfoWindowManager, MarkerManager } from '@agm/core';
import {
  ClusterIconStyle,
  MarkerClustererOptions,
} from '@google/markerclustererplus';
import { Calculator } from '@google/markerclustererplus/dist/markerclusterer';
import { ClusterManager } from '@agm/markerclusterer';
import MarkerClusterer from '@google/markerclustererplus';
import { Cluster } from '@google/markerclustererplus/dist/cluster';

@Directive({
  selector: 'ct-marker-cluster',
  providers: [
    ClusterManager,
    { provide: MarkerManager, useExisting: ClusterManager },
    InfoWindowManager,
  ],
})
export class CtMarkerCluster
  implements OnDestroy, OnChanges, OnInit, MarkerClustererOptions
{
  /**
   * The grid size of a cluster in pixels
   */
  @Input() gridSize: number;

  /**
   * The maximum zoom level that a marker can be part of a cluster.
   */
  @Input() maxZoom: number;

  /**
   * Whether the default behaviour of clicking on a cluster is to zoom into it.
   */
  @Input() zoomOnClick: boolean;

  /**
   * Whether the center of each cluster should be the average of all markers in the cluster.
   */
  @Input() averageCenter: boolean;

  /**
   * The minimum number of markers to be in a cluster before the markers are hidden and a count is shown.
   */
  @Input() minimumClusterSize: number;

  /**
   * An object that has style properties.
   */
  @Input() styles: ClusterIconStyle[];

  /**
   * A function that calculates the cluster style and text based on the markers in the cluster.
   */
  @Input() calculator: Calculator;

  @Input() imagePath: string;
  @Input() imageExtension: string;

  /**
   * The name of the CSS class defining general styles for the cluster markers.
   * Use this class to define CSS styles that are not set up by the code that
   * processes the `styles` array.
   *
   * @defaultValue 'cluster'
   */
  @Input() clusterClass: string;

  /**
   * Whether to allow the use of cluster icons that have sizes that are some
   * multiple (typically double) of their actual display size. Icons such as
   * these look better when viewed on high-resolution monitors such as Apple's
   * Retina displays. Note: if this property is `true`, sprites cannot be used
   * as cluster icons.
   *
   * @defaultValue false
   */
  @Input() enableRetinaIcons: boolean;

  /**
   * Whether to ignore hidden markers in clusters. You may want to set this to
   * `true` to ensure that hidden markers are not included in the marker count
   * that appears on a cluster marker (this count is the value of the `text`
   * property of the result returned by the default `calculator`). If set to
   * `true` and you change the visibility of a marker being clustered, be sure
   * to also call `MarkerClusterer.repaint()`.
   *
   * @defaultValue false
   */
  @Input() ignoreHidden: boolean;

  /**
   * An array of numbers containing the widths of the group of
   * `<imagePath><n>.<imageExtension>` image files. (The images are assumed to
   * be square.)
   *
   * @defaultValue [53, 56, 66, 78, 90]
   */
  @Input() imageSizes: number[];

  /**
   * The tooltip to display when the mouse moves over a cluster marker.
   * (Alternatively, you can use a custom `calculator` function to specify a
   * different tooltip for each cluster marker.)
   *
   * @defaultValue ''
   */
  @Input() title: string;

  @Output() clusterClick: EventEmitter<Cluster> = new EventEmitter<Cluster>();

  private _observableSubscriptions: Subscription[] = [];

  constructor(private _clusterManager: ClusterManager) {}

  ngOnDestroy() {
    this._clusterManager.clearMarkers();
    this._observableSubscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['gridSize']) {
      this._clusterManager.setGridSize(changes['gridSize'].currentValue);
    }
    if (changes['maxZoom']) {
      this._clusterManager.setMaxZoom(changes['maxZoom'].currentValue);
    }
    if (changes['zoomOnClick']) {
      this._clusterManager.setZoomOnClick(changes['zoomOnClick'].currentValue);
    }
    if (changes['averageCenter']) {
      this._clusterManager.setAverageCenter(
        changes['averageCenter'].currentValue
      );
    }
    if (changes['minimumClusterSize']) {
      this._clusterManager.setMinimumClusterSize(
        changes['minimumClusterSize'].currentValue
      );
    }
    if (changes['styles']) {
      this._clusterManager.setStyles(changes['styles'].currentValue);
    }
    if (changes['calculator']) {
      this._clusterManager.setCalculator(changes['calculator'].currentValue);
    }
    if (changes['imagePath']) {
      this._clusterManager.setImagePath(changes['imagePath'].currentValue);
    }
    if (changes['imageExtension']) {
      this._clusterManager.setImageExtension(
        changes['imageExtension'].currentValue
      );
    }
    if (changes['clusterClass']) {
      this._clusterManager.setClusterClass(
        changes['clusterClass'].currentValue
      );
    }
    if (changes['enableRetinaIcons']) {
      this._clusterManager.setEnableRetinaIcons(
        changes['enableRetinaIcons'].currentValue
      );
    }
    if (changes['ignoreHidden']) {
      this._clusterManager.setIgnoreHidden(
        changes['ignoreHidden'].currentValue
      );
    }
    if (changes['imageSizes']) {
      this._clusterManager.setImageSizes(changes['imageSizes'].currentValue);
    }
    if (changes['title']) {
      this._clusterManager.setTitle(changes['title'].currentValue);
    }
  }

  /** @internal */
  ngOnInit() {
    this._clusterManager.init({
      averageCenter: this.averageCenter,
      calculator: this.calculator,
      clusterClass: this.clusterClass,
      enableRetinaIcons: this.enableRetinaIcons,
      gridSize: this.gridSize,
      ignoreHidden: this.ignoreHidden,
      imageExtension: this.imageExtension,
      imagePath: this.imagePath,
      imageSizes: this.imageSizes,
      maxZoom: this.maxZoom,
      minimumClusterSize: this.minimumClusterSize,
      styles: this.styles,
      title: this.title,
      zoomOnClick: this.zoomOnClick,
    });

    this._clusterManager
      .getClustererInstance()
      .then((markerClusterer: MarkerClusterer) => {
        google.maps.event.addListener(
          markerClusterer,
          'clusterclick',
          (cluster) => {
            this.clusterClick.emit(cluster);
          }
        );
      });
  }
}
