<div class="icon-box-component flex align-center">

  <div *ngIf="!isImage" [ngStyle]="{
    marginRight: (size/3)-5 + 'px'
  }" class="icon-svg flex align-center">
    <app-svg [size]="size" [src]="icon?.url + ''"></app-svg>
  </div>

  <div *ngIf="isImage" [ngStyle]="{
    marginRight: (size/3)-5 + 'px', height: (size + 5) + 'px'
  }" class="icon-image flex align-center">
    <div class="img-box" [ngStyle]="{width: size + 'px'}">
      <img [src]="icon?.url + ''">
    </div>
  </div>

  <div class="label">
    {{icon.name | titlecase}}
  </div>
</div>
