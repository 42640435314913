import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from "../../../core/models/user";
import {ActiveResources} from "../../../core/models/active-resources";
import {NzModalRef} from "ng-zorro-antd/modal";
import {ActiveResourcesService} from "../../../core/services/active-resources.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {Subject} from "rxjs";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {NewActiveResourceModalComponent} from "../new-active-resource-modal/new-active-resource-modal.component";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-assign-active-resource-modal',
  templateUrl: './assign-active-resource-modal.component.html',
  styleUrls: ['./assign-active-resource-modal.component.scss']
})
export class AssignActiveResourceModalComponent implements OnInit, OnDestroy {
  @Input() member: User | null;
  activeResourceForm: FormGroup;
  $onDestroy: Subject<void> = new Subject<void>();
  isLoading = false;

  activeResources: ActiveResources[] = [];
  activeResourcesLoading = false;
  activeResourcesIndex = 1;
  stopLoading = false;
  searchActiveResource: null | string;
  $searchChange: Subject<string> = new Subject<string>();
  firstSearch = true;

  constructor(private activeResourcesService: ActiveResourcesService,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              private notification: NzNotificationService,
              private modalRef: NzModalRef,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.subscribeToSearch();
    this.loadMoreActiveResources();
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();

    this.$searchChange.next();
    this.$searchChange.complete();
  }

  close() {
    this.modalRef.close(null);
  }

  activeSearch($event: any): void {
    this.$searchChange.next($event);
  }

  loadMoreActiveResources() {
    if (!this.stopLoading) {
      this.activeResourcesLoading = true;
      this.activeResourcesService.getActiveResourcesTable({
        unassigned: true,
        substring: this.searchActiveResource,
        skip: this.activeResourcesIndex,
        limit: 10
      }).pipe(
        finalize(() => {
          this.activeResourcesLoading = false;
        })
      )
        .subscribe(activeTable => {
          if (activeTable.activeResources?.length) {
            activeTable.activeResources.forEach(active => {
              if (this.activeResourceForm.controls.selectedActive.value !== null &&
                active.uid !== this.activeResourceForm.controls.selectedActive.value.uid &&
                active.active === true) {
                this.activeResources.push(active);
              } else {
                if (active.active) {
                  this.activeResources.push(active);
                }
              }
            });
          } else {
            this.stopLoading = true;
          }
        });
      this.activeResourcesIndex++;
    }
  }

  openNewActiveResource() {
    const modal = this.drawerService.create({
      nzContent: NewActiveResourceModalComponent,
      nzTitle: 'Nuevo activo',
      nzWidth: 504
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((activeResource: ActiveResources) => {
      if (activeResource) {
        if (activeResource.currentHolder !== null) {
          this.modalRef.close(activeResource);
        } else {
          this.activeResources.push(activeResource);
          const index = this.activeResources.findIndex(r => r.uid === activeResource.uid);
          if (index !== -1) {
            this.activeResourceForm.controls.selectedActive.setValue(this.activeResources[index]);
          }
        }
      }
    })
  }

  assignActive() {
    if (this.activeResourceForm.valid) {
      this.isLoading = true;
      const data = this.activeResourceForm.getRawValue();
      this.activeResourcesService.updateActiveResource({
        resourceUid: data.selectedActive.uid,
        employeeUid: this.member?.uid
      }).pipe(finalize(() => {
        this.isLoading = false;
      }))
        .subscribe((active: ActiveResources) => {
          this.notification.create('success', '¡Operación exitosa!', 'Activo asignado correctamente');
          this.modalRef.close(active);
        }, error => {
          this.notification.create('error', 'Error al asignar el activo', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelperService.markFormAsDirty(this.activeResourceForm);
    }
  }

  onActiveResourceSearch($event: string) {
    if (!this.firstSearch) {
      this.stopLoading = false;
      this.activeResources = [];
      this.searchActiveResource = $event === "" ? null : $event;
      this.activeResourcesIndex = 1;
      this.loadMoreActiveResources();
    }
    this.firstSearch = false;
  }

  private createForm() {
    this.activeResourceForm = this.formBuilder.group({
      selectedActive: [null, Validators.required]
    });

    this.activeResourceForm.controls.selectedActive.valueChanges.subscribe(value => {
      if (value !== null && value.name === 'new') {
        this.openNewActiveResource();
      }
    });
  }

  private subscribeToSearch() {
    this.$searchChange
      .pipe(takeUntil(this.$onDestroy), debounceTime(400))
      .subscribe(search => {
        this.onActiveResourceSearch(search);
      });
  }

}
