import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Team} from '../models/team';
import {Apollo} from 'apollo-angular';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {Company} from '../models/company';
import {EmployeesFilter} from '../models/employees-filter';
import {User} from '../models/user';
import {MembersTableFilter} from "../models/members-table-filter";
import {EmployeesTable} from "../models/employees-table";

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
      private apollo: Apollo
  ) { }

  getTeams(): Observable<Team[] | undefined> {
    return this.apollo.query<{myCompany: Company}>({
      query: gql`
        query myCompany {
            myCompany {
                teams {
                    uid
                    name
                    color
                    timezone
                    icon {
                        url
                        uid
                    }
                    geoFencings {
                        rangeMeters
                        remoteExclusive
                        uid
                        name
                        address {
                            state
                            street
                            interiorNumber
                            exteriorNumber
                            zipcode
                            colony
                            city
                            colony
                            latitude
                            longitude
                        }
                    }
                    members {
                        uid
                        firstName
                        lastName
                        email
                        photo {
                            url
                        }
                    }
                    teamLeaders {
                        uid
                        firstName
                        lastName
                        email
                        photo {
                            url
                        }
                    }
                  slackChannels {
                    uid
                    channelId
                    name
                    botAdded
                  }
                }
            }
        }
      `
    }).pipe(
        map(({data}) => {
          return data.myCompany.teams;
    })
    )
  }

  getTeamMembers(filter: EmployeesFilter): Observable<{ count: number, employees: User }> {
    return this.apollo.query<{employeesTable: { count: number, employees: User }}>({
      query: gql`
          query employeesTable(
            $substring: String
            $teamUid: String
            $unassigned: Boolean
            $skip: Int
            $limit: Int
            $sort: String
            $order: String
          ) {
              employeesTable(
                  substring: $substring
                  teamUid: $teamUid
                  unassigned: $unassigned
                  skip: $skip
                  limit: $limit
                  sort: $sort
                  order: $order
              ) {
                  count
                  employees {
                      uid
                      firstName
                      email
                      lastName
                      position {
                          uid
                          name
                      }
                      photo {
                          url
                      }
                      totalUnseenAbsenceRequests
                  }
              }
          }
      `,
      variables: {
        ...filter
      }
    }).pipe(
        map(({data}) => {
          return data.employeesTable;
        })
    )
  }

  getTeam(teamUid: string): Observable<Team> {
    return this.apollo.query<{team: Team}>({
      query: gql`
          query team(
              $teamUid: String!
          ) {
              team(
                  teamUid: $teamUid
              ) {
                  uid
                  name
                  color
                  icon {
                      url
                      uid
                  }
              }
          }
      `,
      variables: {
        teamUid
      }
    }).pipe(
        map(({data}) => {
          return data.team;
        })
    )
  }

  createTeam(team: Team): Observable<Team | undefined> {
    return this.apollo.mutate<{createTeam: { team: Team }}>({
      mutation: gql`
          mutation createTeam(
              $color: String!
              $iconUid: String!
              $membersUid: [String]!
              $name: String!
              $teamLeadersUid: [String]!
              $timezone: String!
              $geoFencingsUid: [String]!
              $slackChannelsUid: [String]!
          ) {
              createTeam(
                  color: $color
                  iconUid: $iconUid
                  membersUid: $membersUid
                  name: $name
                  teamLeadersUid: $teamLeadersUid
                  timezone: $timezone
                  geoFencingsUid: $geoFencingsUid
                  slackChannelsUid: $slackChannelsUid
              ) {
                  team {
                      uid
                      name
                      color
                      timezone
                      icon {
                          url
                          uid
                      }
                      geoFencings {
                          rangeMeters
                          remoteExclusive
                          uid
                          name
                          address {
                              state
                              street
                              interiorNumber
                              exteriorNumber
                              zipcode
                              colony
                              city
                              colony
                              latitude
                              longitude
                          }
                      }
                      members {
                          uid
                          firstName
                          lastName
                          email
                          photo {
                              url
                          }
                      }
                      teamLeaders {
                          uid
                          firstName
                          lastName
                          email
                          photo {
                              url
                          }
                      }
                  }
              }
          }
      `,
      variables: {
        ...team
      }
    }).pipe(
        map(({data}) => {
          return data?.createTeam.team;
        })
    )
  }

  deleteTeam(teamUid: string | undefined): Observable<string | undefined> {
    return this.apollo.mutate<{deleteTeam: { message: string }}>({
      mutation: gql`
          mutation deleteTeam(
              $teamUid: String!
          ) {
              deleteTeam(
                  teamUid: $teamUid
              ) {
                  message
              }
          }
      `,
      variables: {
        teamUid
      }
    }).pipe(
        map(({data}) => {
          return data?.deleteTeam.message;
        })
    )
  }

  editTeam(team: Team): Observable<Team | undefined> {
    return this.apollo.mutate<{updateTeam: { team: Team }}>({
      mutation: gql`
          mutation updateTeam(
              $color: String!
              $iconUid: String!
              $membersUid: [String]!
              $name: String!
              $teamLeadersUid: [String]!
              $timezone: String!
              $teamUid: String!
              $geoFencingsUid: [String]!
              $slackChannelsUid: [String]!
          ) {
              updateTeam(
                  color: $color
                  iconUid: $iconUid
                  membersUid: $membersUid
                  name: $name
                  teamLeadersUid: $teamLeadersUid
                  timezone: $timezone
                  teamUid: $teamUid
                  geoFencingsUid: $geoFencingsUid
                  slackChannelsUid: $slackChannelsUid
              ) {
                  team {
                      uid
                      name
                      color
                      timezone
                      icon {
                          url
                          uid
                      }
                      geoFencings {
                          rangeMeters
                          remoteExclusive
                          uid
                          name
                          address {
                              state
                              street
                              interiorNumber
                              exteriorNumber
                              zipcode
                              colony
                              city
                              colony
                              latitude
                              longitude
                          }
                      }
                      members {
                          uid
                          firstName
                          lastName
                          email
                          photo {
                              url
                          }
                      }
                      teamLeaders {
                          uid
                          firstName
                          lastName
                          email
                          photo {
                              url
                          }
                      }
                    slackChannels {
                      uid
                      channelId
                      name
                      botAdded
                    }
                  }
              }
          }
      `,
      variables: {
        ...team
      }
    }).pipe(
        map(({data}) => {
          return data?.updateTeam.team;
        })
    )
  }

  getTeamsMembers(): Observable<Team[]> {
    // @ts-ignore
    return this.apollo.query<{myCompany: Company}>({
      query: gql`
        query myCompany {
          myCompany {
            teams {
              uid
              name
              members {
                uid
                firstName
                lastName
              }
            }
          }
        }
      `
    }).pipe(
      map(({data}) => {
        return data.myCompany.teams;
      })
    )
  }

  getGeoLocations(filters: any, scheduleOnly: boolean = true): Observable<EmployeesTable> {
    return this.apollo.query<{employeesTable: EmployeesTable}>({
      query: gql`
        query employeesTable(
          $substring: String
          $teamUid: String
          $unassigned: Boolean
          $skip: Int
          $limit: Int
          $sort: String
          $order: String
          $startDate: String!
          $endDate: String!
          $eventType: String
          $scheduleOnly: Boolean
        ) {
          employeesTable(
            substring: $substring
            teamUid: $teamUid
            unassigned: $unassigned
            skip: $skip
            limit: $limit
            sort: $sort
            order: $order
            eventType: $eventType
            scheduleOnly: $scheduleOnly
          ) {
            employees{
              uid
              firstName
              lastName
              email
              photo {
                url
              }
              schedule {
                monday {
                  category
                }
                tuesday {
                  category
                }
                wednesday {
                  category
                }
                thursday {
                  category
                }
                friday {
                  category
                }
                saturday {
                  category
                }
                sunday {
                  category
                }
              }
              calendarSchedules(
                endDate: $startDate
                startDate: $startDate
              ) {
                date
                festivityDays {
                  uid
                  numberId
                  name
                  colour
                  startDate
                  endDate
                }
                turns {
                  uid
                  index
                  fromHour
                  dayConfig {
                    category
                    approvedAbsence {
                      uid
                    }
                    active
                  }
                  toHour
                  checkinAtt {
                    eventCategory
                    autoCreated
                    latitude
                    longitude
                    onLocation
                    onTime
                    timestamp
                  }
                  checkoutAtt {
                    eventCategory
                    autoCreated
                    latitude
                    longitude
                    onLocation
                    onTime
                    timestamp
                  }
                }
              }
              geoLocations(startDate: $startDate endDate: $endDate) {
                latitude
                longitude
                timestamp
                uid
              }
            }
            count
          }
        }
      `,
      variables: {...filters, scheduleOnly}
    }).pipe(
      map(({data}) => {
        return data.employeesTable;
      })
    )
  }
}
