import {Component, Input, OnInit} from '@angular/core';
import {EmployerRegistry} from "../../../core/models/employer-registry";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SocialSecurityRisk} from "../../../core/models/social-security-risk";
import {EmployerRegistryService} from "../../../core/services/employer-registry.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-edit-employer-registry-modal',
  templateUrl: './edit-employer-registry-modal.component.html',
  styleUrls: ['./edit-employer-registry-modal.component.scss']
})
export class EditEmployerRegistryModalComponent implements OnInit {
  @Input() employerRegistry: EmployerRegistry | null;
  isLoading = true;
  isCreating = false;
  error: string | null;
  employerRegistryForm: FormGroup;
  socialSecurityRisks: SocialSecurityRisk[] | undefined;
  formatterPercentage = (value: number) => `${value + ' %'}`;
  parserPercentage = (value: string) => value.replace(" %", "");
  formatterDays = (value: number) => `${value + (value === 1 ? ' día' : ' días')}`;
  parserDays = (value: string) => value.replace(' día', '').replace(' días', '');

  constructor(private employerRegistryService: EmployerRegistryService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.getSsrisks();
  }

  close() {
    this.drawerRef.close(null);
  }

  setPercentage($event: SocialSecurityRisk) {
    this.employerRegistryForm.controls.riskPercentage.setValue($event?.percentage);
  }

  updateEmployerRegistry() {
    if (this.employerRegistryForm.valid) {
      this.isCreating = true;
      const data = this.employerRegistryForm.getRawValue();
      data.ssRiskUid = data.ssRiskUid.uid;
      this.employerRegistryService.updateEmployerRegistry(data)
        .pipe(finalize(() => {
          this.isCreating = false;
        }))
        .subscribe((employerRegistry: EmployerRegistry) => {
          this.notification.create('success', '¡Operación exitosa!', 'Registro patronal actualizado correctamente');
          this.drawerRef.close(employerRegistry);
        }, error => {
          this.notification.create('error', 'Error al actualizar el registro patronal', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.employerRegistryForm);
    }
  }

  private getSsrisks() {
    this.isLoading = true;
    this.employerRegistryService.getSsrisks()
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((socialSecurityRisks: SocialSecurityRisk[] | undefined) => {
        this.socialSecurityRisks = socialSecurityRisks;
        this.createForm();
      }, error => {
        this.error = error.message.replace("GraphQL error:", "").trim();
        // this.notification.create('error', 'Error al crear el activo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  private createForm() {
    this.employerRegistryForm = this.formBuilder.group({
      employerRegistryUid: [this.employerRegistry?.uid],
      ISNPercentage: [this.employerRegistry?.ISNPercentage, Validators.required],
      NPIEId: [this.employerRegistry?.NPIEId, Validators.required],
      businessName: [this.employerRegistry?.businessName, Validators.required],
      clabe: [this.employerRegistry?.clabe, Validators.required],
      holidayBonusDays: [this.employerRegistry?.holidayBonusDays, Validators.required],
      holidayBonusPercentage: [this.employerRegistry?.holidayBonusPercentage, Validators.required],
      proportionalAbsencesPolicy: [this.employerRegistry?.proportionalAbsencesPolicy],
      rfc: [this.employerRegistry?.rfc, [Validators.pattern(
        /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
      ), Validators.required]],
      riskPercentage: [this.employerRegistry?.riskPercentage, Validators.required],
      socialReason:[this.employerRegistry?.socialReason, Validators.required],
      ssRiskUid: [this.getSsrisk(), Validators.required]
    });
  }

  private getSsrisk() {
    const index = this.socialSecurityRisks?.findIndex(ssr => ssr.uid === this.employerRegistry?.ssRisk?.uid);
    if (index !== -1) {
      // @ts-ignore
      return this.socialSecurityRisks[index];
    }
    return null;
  }

}
