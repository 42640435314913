<nz-spin class="spin-loading" [nzSpinning]="isLoading">
  <form [formGroup]="employerRegistryForm" *ngIf="!isLoading && employerRegistryForm">
    <div class="new-team">

      <div class="error-box" *ngIf="error && !isLoading">
        <nz-alert
          nzType="error"
          [nzMessage]="error"
          nzShowIcon
        ></nz-alert>
      </div>

      <div class="new-team-container flex wrap">

        <div nzLayout="vertical" nz-form class="form-column">
          <nz-form-item>
            <nz-form-label>Nombre del registro</nz-form-label>
            <nz-form-control [nzErrorTip]="nameErrorTpl">

              <input nz-input placeholder="Nombre del registro" formControlName="businessName" type="text">

              <ng-template #nameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Ingresa el nombre del registro
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>NPIE (Número Patronal de Identificación Electrónica)</nz-form-label>
            <nz-form-control [nzErrorTip]="NPIEErrorTpl">

              <input nz-input placeholder="NPIE" formControlName="NPIEId" type="text">

              <ng-template #NPIEErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  El NPIE es requerido
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>RFC</nz-form-label>
            <nz-form-control [nzErrorTip]="rfcErrorTpl">

              <input nz-input placeholder="RFC" formControlName="rfc" type="text">

              <ng-template #rfcErrorTpl let-control>
                <ng-container *ngIf="control.hasError('pattern')">
                  El RFC es incorrecto
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">
                  El RFC es requerido
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Razón social</nz-form-label>
            <nz-form-control [nzErrorTip]="lastNameErrorTpl">

              <input nz-input placeholder="Razón social" formControlName="socialReason" type="text">

              <ng-template #lastNameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  La razón social es requerida
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>CLABE</nz-form-label>
            <nz-form-control [nzErrorTip]="clabeErrorTpl">

              <input nz-input placeholder="CLABE" formControlName="clabe" type="text">

              <ng-template #clabeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  La CLABE es requerida
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="category">Categoría</nz-form-label>
            <nz-form-control class="select" [nzErrorTip]="categoryErrorTpl">
              <nz-select nzShowSearch id="category" nzPlaceHolder="Selecciona la categoría" formControlName="ssRiskUid" (ngModelChange)="setPercentage($event)">
                <nz-option *ngFor="let ssrisk of socialSecurityRisks" [nzLabel]="ssrisk?.category" [nzValue]="ssrisk"></nz-option>
              </nz-select>

              <ng-template #categoryErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge una categoría
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <div class="items-container" *ngIf="employerRegistryForm.controls.ssRiskUid.value !== null">
            <nz-form-item class="item">
              <nz-form-label>Porcentaje</nz-form-label>
              <nz-form-control [nzErrorTip]="riskPercentageErrorTpl">

                <nz-input-number
                  style="width: 100%"
                  [OnlyNumber]="true"
                  formControlName="riskPercentage"
                  [nzStep]="1"
                  [nzFormatter]="formatterPercentage"
                  [nzParser]="parserPercentage">
                </nz-input-number>

                <ng-template #riskPercentageErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El porcentaje es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="items-container">
            <nz-form-item class="item">
              <nz-form-label>Prima vacacional</nz-form-label>
              <nz-form-control [nzErrorTip]="holidayBonusPercentageErrorTpl">

                <nz-input-number
                  style="width: 100%"
                  [OnlyNumber]="true"
                  formControlName="holidayBonusPercentage"
                  [nzStep]="1"
                  [nzMin]="25"
                  [nzFormatter]="formatterPercentage"
                  [nzParser]="parserPercentage">
                </nz-input-number>

                <ng-template #holidayBonusPercentageErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El porcentaje es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="item">
              <nz-form-label>Días de aguinaldo</nz-form-label>
              <nz-form-control [nzErrorTip]="holidayBonusDaysErrorTpl">

                <nz-input-number
                  style="width: 100%"
                  [OnlyNumber]="true"
                  formControlName="holidayBonusDays"
                  [nzStep]="1"
                  [nzMin]="15"
                  [nzFormatter]="formatterDays"
                  [nzParser]="parserDays">
                </nz-input-number>

                <ng-template #holidayBonusDaysErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Los días de aguinaldo son requeridos
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="items-container">
            <nz-form-item class="item">
              <nz-form-label>ISN</nz-form-label>
              <nz-form-control [nzErrorTip]="ISNPercentageErrorTpl">

                <nz-input-number
                  style="width: 100%"
                  [OnlyNumber]="true"
                  formControlName="ISNPercentage"
                  [nzMin]="0"
                  [nzStep]="1"
                  [nzFormatter]="formatterPercentage"
                  [nzParser]="parserPercentage">
                </nz-input-number>

                <ng-template #ISNPercentageErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El porcentaje es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="item">
              <nz-form-label class="check-label"></nz-form-label>
              <nz-form-control class="check">
                <label nz-checkbox formControlName="proportionalAbsencesPolicy">Política de ausencias proporcionales</label>
              </nz-form-control>
            </nz-form-item>

          </div>

        </div>
      </div>
    </div>

  </form>
</nz-spin>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="close()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button *ngIf="!isLoading" [nzLoading]="isCreating" (click)="createEmployerRegistry()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
