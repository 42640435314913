<form (submit)="submitForm()" [formGroup]="companyForm">
  <div class="edit-member">

    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <div class="edit-member-container">
      <div class="flex center wrap form-column member-icon">
        <app-upload-image style="width: 26%" [roundImage]="true" [folder]="'profile'" [image]="getImageControl()"></app-upload-image>
      </div>

      <div nzLayout="vertical" nz-form class="form-column flex wrap">

        <div class="flex wrap form-row" style="width: 100%; flex-basis: auto;">
          <form [formGroup]="companyForm">
            <nz-form-item>
              <nz-form-label nzFor="firstName">Nombre de la empresa</nz-form-label>
              <nz-form-control [nzErrorTip]="nameErrorTpl">

                <input nz-input placeholder="Nombre de la empresa" formControlName="name" type="text" id="firstName">

                <ng-template #nameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El nombre de la empresa es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="companyForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-label nzFor="lastName">
                Razón Social
                <i
                  nz-tooltip="Ayuda"
                  [nzTooltipColor]="'white'"
                  class="help-icon"
                  nz-icon
                  nzType="info-circle"
                  nzTheme="fill"
                  (click)="openHelp()"></i>
              </nz-form-label>
              <nz-form-control [nzErrorTip]="lastNameErrorTpl">

                <input nz-input placeholder="Razón social" formControlName="socialReason" type="text" id="lastName">

                <ng-template #lastNameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    La razón social es requerida
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="companyForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-label nzFor="rfc">RFC</nz-form-label>
              <nz-form-control [nzErrorTip]="rfcErrorTpl">

                <input nz-input placeholder="RFC" formControlName="rfc" type="text" id="rfc">

                <ng-template #rfcErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    El RFC es incorrecto
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    El RFC es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="companyForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="vacationCarryoverPolicy">Vacaciones acumuladas</label>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="companyForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="automaticCheckout">Check out automático</label>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="companyForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="outOfLocationAllowed">Check in fuera de ubicación</label>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="companyForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="usesOfficialFestivityDays">Días festivos</label>
              </nz-form-control>
            </nz-form-item>
          </form>

      </div>
    </div>
    </div>
  </div>

  <button style="display:none;" type="submit" #submit>Guardar</button>

</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="closeModal()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isLoading" (click)="submit.click()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
