<div class="geo-fencings-form-control flex column">

  <ng-template #newGeoFencingTitle>
    <div class="flex align-center center">
      <span>Nueva geocerca</span>
      <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'6'" nz-icon nzType="info-circle" nzTheme="fill"></i>
    </div>
  </ng-template>

  <div class="flex">
    <nz-select
          [(nzOpen)]="isOpen"
          class="hidden-select"
          [nzDropdownRender]="renderTemplate"
          [nzDropdownMatchSelectWidth]="false"
          [nzMaxTagCount]="0"
          [compareWith]="compareGeoFencing"
          [nzOptionHeightPx]="40"
          [nzOptionOverflowSize]="5"
          [nzDropdownClassName]="'geo-fencing-form-control-select'"
          nzMode="multiple"
          nzPlaceHolder="Selecciona a los miembros"
          [formControl]="geoFencings">

      <ng-container *ngFor="let geoFencing of list">
        <nz-option nzCustomContent [nzLabel]="(geoFencing.name | titlecase) || ''" [nzValue]="geoFencing">
          <div class="geo-fencing-row flex align-center">
            <div class="geo-fencing-icon">
              <div class="img-box control-image">
                <img src="../../../../assets/images/geo-fencing.png">
              </div>
            </div>
            <div class="geo-fencing-name">
              {{geoFencing.name | titlecase}}
            </div>
          </div>
        </nz-option>
      </ng-container>

      <nz-option *ngIf="geoFencingsLoading" nzDisabled nzCustomContent>
        <div class="geo-fencing-row loading-row align-center flex">
          <i nz-icon nzType="loading" class="loading-icon"></i> Cargando geocercas...
        </div>
      </nz-option>

    </nz-select>

    <ng-template #renderTemplate>
      <div class="search-box" (click)="openNewGeoFencing()">
        <div class="line"></div>
        <div class="new-label">
          + Crear nueva geocerca
        </div>
      </div>
    </ng-template>

    <div class="geo-fencing add-new" (click)="isOpen = true;">
      <i nz-icon nzType="icons:new-geo-fencing" class="loading-icon"></i>
    </div>
  </div>

  <div class="geo-fencing align-center geo-fencing-box flex wrap" *ngFor="let geoFencing of geoFencings.value">
    <i nzTooltipColor="white"
       [nz-tooltip]="'Remover geocerca'" (click)="removeGeoFencing(geoFencing)" class="close-icon" nz-icon [nzTwotoneColor]="'#FF8484'" nzType="close-circle" nzTheme="twotone"></i>
    <div class="img-box">
      <img src="../../../../assets/images/geo-fencing.png">
    </div>

    <div class="flex center column geo-fencing-data">
      <div class="name">
        {{geoFencing.name}} <span *ngIf="geoFencing.remoteExclusive" class="small-label"> - Solo remoto</span>
      </div>
      <div class="address">
        {{getFullAddress(geoFencing)}}
      </div>
    </div>
  </div>
</div>
