<div class="member-meeting-non-attendance-table" *ngIf="meetings">

  <div class="title">
    Inasistencias
  </div>

  <div class="table flex column" *ngIf="meetings?.length">
    <div class="row" *ngFor="let meeting of meetings | slice: (index * 10 - 10) : (index * 10)">
      <app-meeting-box [meeting]="meeting" [showMeetingAttendance]="false"></app-meeting-box>
    </div>
  </div>

  <div class="pagination-row flex end" *ngIf="meetings?.length">
    <nz-pagination [(nzPageIndex)]="index" [nzTotal]="meetings.length"></nz-pagination>
  </div>

  <ng-container *ngIf="meetings?.length == 0">
    <nz-result nzStatus="404" nzTitle="No se encontraron juntas" nzSubTitle="No se encontró ninguna junta en este rango">
    </nz-result>
  </ng-container>
</div>
