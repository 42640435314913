import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbsenceRequest} from '../../../core/models/absence-request';
import {User} from '../../../core/models/user';
import {AbsenceRequestModalComponent} from '../../modals/absence-request-modal/absence-request-modal.component';
import {NzDrawerService} from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-vacation-box',
  templateUrl: './vacation-box.component.html',
  styleUrls: ['./vacation-box.component.scss']
})
export class VacationBoxComponent implements OnInit {
  @Input() absenceRequest: AbsenceRequest;
  @Input() member: User | null;

  @ViewChild('absenceModalTitle') absenceModalTitle: TemplateRef<any>;

  constructor(
      private drawerService: NzDrawerService
  ) { }

  ngOnInit(): void {
  }

  openAbsenceRequest() {
    const modal = this.drawerService.create({
      nzContent: AbsenceRequestModalComponent,
      nzContentParams: {
        member: this.member || undefined,
        absenceRequest: this.absenceRequest
      },
      nzTitle: this.absenceModalTitle,
      nzWidth: 504
    });
  }
}
