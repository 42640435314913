<div class="map-box">
  <agm-map [latitude]="selectedGeolocation !== null ? selectedGeolocation.latitude : geolocations[0].latitude"
           [longitude]="selectedGeolocation !== null ? selectedGeolocation.longitude : geolocations[0].longitude"
           [minZoom]="8"
           [zoom]="2"
           [maxZoom]="16"
           [fitBounds]="true"
           [disableDefaultUI]="true"
           [mapTypeId]="'roadmap'"
           [styles]="mapStyle"
           class="round-img">

    <ng-container *ngIf="selectedGeolocation === null">
      <ct-marker-cluster
        (clusterClick)="clusterClick($event)"
        [imagePath]="'../assets/images/map-pins/map-cluster-pins/' + this.teamColor.replace('#', '') + '-m-'"
        [enableRetinaIcons]="false">

        <ng-container *ngFor="let location of geolocations; let i = index;">
          <agm-marker
            [iconUrl]="location.pinData"
            [agmFitBounds]="true"
            [latitude]="location.latitude"
            [longitude]="location.longitude"
            [markerClickable]="true">
            <agm-info-window [disableAutoPan]="true">
              <div style="margin: 0 12px;">{{ location?.correctTimestamp | date: 'dd' }} de  {{ location?.correctTimestamp | date: 'MMM' }} {{ location?.correctTimestamp | date: 'yyyy' }}</div>
              <div style="margin: 0 12px;">{{ location?.correctTimestamp | date: 'HH:mm' }} hrs</div>
            </agm-info-window>
          </agm-marker>
        </ng-container>
      </ct-marker-cluster>
    </ng-container>

    <ng-container *ngIf="selectedGeolocation !== null">
      <agm-marker
        [iconUrl]="selectedGeolocation?.pinData"
        [agmFitBounds]="true"
        [latitude]="selectedGeolocation.latitude"
        [longitude]="selectedGeolocation.longitude"
        [markerClickable]="true">
        <agm-info-window [disableAutoPan]="true">
          <div style="margin: 0 12px;">{{ selectedGeolocation?.correctTimestamp | date: 'dd' }} de  {{ selectedGeolocation?.correctTimestamp | date: 'MMM' }} {{ selectedGeolocation?.correctTimestamp | date: 'yyyy' }}</div>
          <div style="margin: 0 12px;">{{ selectedGeolocation?.correctTimestamp | date: 'HH:mm' }} hrs</div>
        </agm-info-window>
      </agm-marker>

      <!--<agm-overlay
        [latitude]="selectedGeolocation?.latitude"
        [longitude]="selectedGeolocation?.longitude">
        <div style="width: 33px; height: 33px; margin-left: -6px;">
          <img style="width: 100%; height: 100%; border-radius: 50%;" [src]="selectedGeolocation?.pinData?.url" [alt]="'user-icon'">
        </div>
      </agm-overlay>-->

      <agm-circle
        *ngIf="selectedGeolocation?.isLastLocation"
        [strokeColor]="teamColor"
        [strokeOpacity]=".8"
        [strokeWeight]="2"
        [fillColor]="teamColor"
        [fillOpacity]=".25"
        [longitude]="selectedGeolocation?.longitude || 0"
        [latitude]="selectedGeolocation?.latitude || 0"
        [radius]="200">
      </agm-circle>
    </ng-container>
  </agm-map>
</div>
