// use this environment to test using development BD

export const environment = {
   production: false,
   apiUrl: 'https://api-nommi.cloudsitedsolutions.com',
   googleApiKey: 'AIzaSyCNuSoBMgrfcm9TO8rYGLXhEcUi7G1ZxoI',
   geoNamesUser: "vrsVenders",
   geoNamesApi: "https://secure.geonames.org",
   conekta: {
     public_key: 'key_PNsLk2kAraq3TgN9ctvBRSA'
   },
   appUrl:'https://www.nommi.cloudsitedsolutions.com'
};

// use this environment to test using production BD

/*export const environment = {
   production: false,
   apiUrl: 'https://api.nommi.mx',
   googleApiKey: 'AIzaSyCNuSoBMgrfcm9TO8rYGLXhEcUi7G1ZxoI',
   geoNamesUser: "vrsVenders",
   geoNamesApi: "https://secure.geonames.org",
   conekta: {
     public_key: 'key_VmtrogKjs7u3Fy4ArHsJLHA'
   },
   appUrl: 'https://www.app.nommi.mx'
};*/
