import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '../../../core/models/company';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { CompanyService } from '../../../core/services/company.service';
import { FormHelperService } from '../../../core/services/form-helper.service';
import { HelpCompanyNameComponent } from '../help-company-name/help-company-name.component';

@Component({
  selector: 'app-edit-company-general-data-modal',
  templateUrl: './edit-company-general-data-modal.component.html',
  styleUrls: ['./edit-company-general-data-modal.component.scss'],
})
export class EditCompanyGeneralDataModalComponent implements OnInit {
  @Input() company: Company | null;
  @Output() companyEdited: EventEmitter<Company> = new EventEmitter();

  companyForm: FormGroup;
  isLoading = false;
  error: string | null;

  constructor(
    private companyService: CompanyService,
    private formHelper: FormHelperService,
    private formBuilder: FormBuilder,
    private notification: NzNotificationService,
    private drawerRef: NzDrawerRef,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  closeModal() {
    this.drawerRef.close();
  }

  getImageControl(): FormControl {
    return this.companyForm.controls.photoUid as FormControl;
  }

  submitForm() {
    if (this.companyForm.valid) {
      this.isLoading = true;
      const data = this.companyForm.getRawValue();
      if (data.photoUid !== null) {
        data.photoUid = data.photoUid.uid;
      }
      this.companyService
        .updateCompany(data)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (company: Company) => {
            this.notification.create(
              'success',
              '¡Operación exitosa!',
              'Datos actualizados correctamente'
            );
            // @ts-ignore
            this.companyEdited.emit(company);
            this.drawerRef.close();
          },
          (error) => {
            // this.error = error.message.replace("GraphQL error:", "").trim();
            this.notification.create(
              'error',
              'Error al actualizar la información',
              error.message.replace('GraphQL error:', '').trim()
            );
          }
        );
    } else {
      this.formHelper.markFormAsDirty(this.companyForm);
    }
  }

  private createForm() {
    this.companyForm = this.formBuilder.group({
      name: [this.company?.name, Validators.required],
      photoUid: [this.company?.photo],
      rfc: [
        this.company?.rfc ? this.company.rfc : null,
        [
          Validators.pattern(
            /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
          ),
          Validators.required,
        ],
      ],
      socialReason: [this.company?.socialReason, Validators.required],
      automaticCheckout: [this.company?.automaticCheckout],
      outOfLocationAllowed: [this.company?.outOfLocationAllowed],
      usesOfficialFestivityDays: [this.company?.usesOfficialFestivityDays],
      vacationCarryoverPolicy: [this.company?.vacationCarryoverPolicy],
    });
  }

  openHelp() {
    this.modalService.create({
      nzTitle: 'Ayuda',
      nzContent: HelpCompanyNameComponent,
      nzWidth: 500,
      nzFooter: null,
      nzWrapClassName: 'vertical-center-modal',
    });
  }
}
