import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TimeService} from "../../../core/services/time.service";
import {Interval} from "../../../core/models/interval";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {DayConfig} from "../../../core/models/day-config";

@Component({
  selector: 'app-schedule-form-control',
  templateUrl: './schedule-form-control.component.html',
  styleUrls: ['./schedule-form-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleFormControlComponent implements OnInit {
  @Input() dayConfig: DayConfig | null = null;
  @Input() dayFormGroup: FormGroup;
  @Input() dayName: string | null;
  timeIntervals: Interval[] = this.timeService.getFullDayHours(15);
  categories = [
    {name: 'Presencial', value: 'I', selected: true},
    {name: 'Remoto', value: 'R', selected: false}
  ];
  clean = false;
  lastHourNotSelected = true;

  constructor(private timeService: TimeService,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (this.dayConfig !== null) {
      if (this.dayConfig.category === 'Remoto') {
        this.categories[0].selected = false;
        this.categories[1].selected = true;
      }
    }
  }

  addTurn() {
    if (this.getFormArray().valid) {
      if (this.getFormArray().length === 1) {
        const turn = this.getTurn(0);
        if (turn !== undefined) {
          if (turn.value.fromHour !== null && turn.value.toHour !== null) {
            for (let i = turn.value.toHour.index; i >= 0; i--) {
              this.timeIntervals[i].blocked = true;
            }
          }
        }
      }
      this.getFormArray().push(
        this.formBuilder.group({
          fromHour: [null, Validators.required],
          toHour: [null, Validators.required]
        })
      );
    } else {
      this.formHelper.markFormArrayAsDirty(this.getFormArray());
    }
  }

  removeTurn(index: number) {
    if ((this.getFormArray().length - 1) === 1) {
      this.timeIntervals.forEach((interval: Interval) => {
        interval.blocked = false;
      });
      this.getFormArray().removeAt(index);
    } else {
      this.timeIntervals.forEach((interval: Interval) => {
        interval.blocked = false;
      });
      this.getFormArray().removeAt(index);
      const turn = this.getTurn(this.getFormArray().length - 2);
      if (turn !== undefined) {
        if (turn.value.fromHour !== null && turn.value.toHour !== null) {
          for (let i = turn.value.toHour.index; i >= 0; i--) {
            this.timeIntervals[i].blocked = true;
          }
        }
      }
    }
    this.timeIntervals = [...this.timeIntervals];
    this.clean = true;
  }

  getFormArray(): FormArray {
    return this.dayFormGroup.get('turnsData') as FormArray;
  }

  getTurn(index: number): FormGroup {
    return this.getFormArray().at(index) as FormGroup;
  }

  setCategory(name: string) {
    if (name === 'p') {
      if (this.categories[0].selected) {
        this.categories[1].selected = false;
        this.dayFormGroup.controls.category.setValue(this.categories[0].value);
      } else {
        this.categories[1].selected = true;
        this.dayFormGroup.controls.category.setValue(this.categories[1].value);
      }
    } else {
      if (this.categories[1].selected) {
        this.categories[0].selected = false;
        this.dayFormGroup.controls.category.setValue(this.categories[1].value);
      } else {
        this.categories[0].selected = true;
        this.dayFormGroup.controls.category.setValue(this.categories[0].value);
      }
    }
  }

  updateIntervals($event: Interval[]) {
    this.timeIntervals = $event;
  }
}
