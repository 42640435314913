<div class="assign-active-resource-modal-component">

  <form nz-form nzLayout="vertical" [formGroup]="activeResourceForm" *ngIf="activeResourceForm">
    <form [formGroup]="activeResourceForm" style="flex-basis: auto;">
      <nz-form-item>
        <nz-form-label>Seleccionar activo fijo</nz-form-label>
        <nz-form-control [nzErrorTip]="activeErrorTpl">

          <nz-select
            formControlName="selectedActive"
            [nzPlaceHolder]="'Seleccionar activo fijo'"
            nzShowSearch
            nzServerSearch
            (nzOnSearch)="activeSearch($event)"
            (nzScrollToBottom)="loadMoreActiveResources()"
            [nzDropdownRender]="renderTemplate">
            <nz-option [nzValue]="{name: 'new'}" [nzLabel]="'+ Nuevo activo fijo'"></nz-option>
            <nz-option *ngFor="let active of activeResources" [nzValue]="active" [nzLabel]="active?.name"></nz-option>
          </nz-select>

          <ng-template #renderTemplate>
            <nz-spin *ngIf="activeResourcesLoading"></nz-spin>
          </ng-template>

          <ng-template #activeErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El activo es requerido
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </form>

  <div class="btns-container" *ngIf="!isLoading">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="assignActive()">Guardar</button>
  </div>
</div>
