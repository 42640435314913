<div class="member-geo-locations-card" [ngClass]="showMore ? 'active' : 'close'">
  <div class="topo-container">
    <div class="title-container" (click)="showMore = !showMore">
      <i nz-icon nzType="down" *ngIf="!showMore"></i>
      <i nz-icon nzType="up" *ngIf="showMore"></i>
      <div class="title">Ubicaciones ({{ cluster?.length > 0 ? cluster?.length : geolocations?.length }})</div>
    </div>

    <div class="filters-container" *ngIf="cluster?.length > 0">
      <div class="clean" (click)="cleanCluster()">Limpiar grupo</div>
    </div>

    <div class="filters-container" *ngIf="cluster?.length === 0 && selectedLocationActive">
      <div class="clean" (click)="cleanSelected()">Limpiar filtro</div>
    </div>
  </div>

  <div class="location-container">
    <ng-container *ngIf="cluster?.length === 0">
      <div class="location-box" [ngClass]="{'location-active': location?.selected}"
           *ngFor="let location of geolocations; let i = index;" (click)="locationClick(i, location.selected, false)">
        <div class="img-box" [style.width]="location?.isLastLocation ? '24px' : '30px'">
          <img [src]="location?.isLastLocation ? pinIcon.url : circularIcon.url" alt="pin-icon">
        </div>

        <div class="location-info-box">
          <div class="text-tile" *ngIf="location?.isLastLocation">Última ubicación registrada</div>
          <div class="text">{{ location?.correctTimestamp | date: 'dd' }} de {{ location?.correctTimestamp | date: 'MMM' }} {{ location?.correctTimestamp | date: 'yyyy' }}</div>
          <div class="text">{{ location?.correctTimestamp | date: 'HH:mm' }} hrs</div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="cluster?.length > 0">
      <div class="location-box" [ngClass]="{'location-active': location?.selected}"
           *ngFor="let location of cluster; let i = index;" (click)="locationClick(i, location.selected, true)">
        <div class="img-box" [style.width]="location?.isLastLocation ? '24px' : '30px'">
          <img [src]="location?.isLastLocation ? pinIcon.url : circularIcon.url" alt="pin-icon">
        </div>

        <div class="location-info-box">
          <div class="text-tile" *ngIf="location?.isLastLocation">Última ubicación registrada</div>
          <div class="text">{{ location?.correctTimestamp | date: 'dd' }} de {{ location?.correctTimestamp | date: 'MMM' }} {{ location?.correctTimestamp | date: 'yyyy' }}</div>
          <div class="text">{{ location?.correctTimestamp | date: 'HH:mm' }} hrs</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
