<div class="non-attendances-table" *ngIf="nonAttendances">

  <div class="title">
    Inasistencias
  </div>

  <div class="table flex column" *ngIf="nonAttendances?.length">
    <div class="row" *ngFor="let nonAttendance of nonAttendances | slice: (index * 10 - 10) : (index * 10)">
      <app-non-attendance-box [nonAttendance]="nonAttendance"></app-non-attendance-box>
    </div>
  </div>

  <div class="pagination-row flex end" *ngIf="nonAttendances?.length">
    <nz-pagination [(nzPageIndex)]="index" [nzTotal]="nonAttendances.length"></nz-pagination>
  </div>

  <ng-container *ngIf="nonAttendances?.length == 0">
    <nz-result nzStatus="404" nzTitle="No se encontraron inasistencias" nzSubTitle="No se encontró ninguna inasistencia en este rango">
    </nz-result>
  </ng-container>
</div>
