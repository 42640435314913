import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { es_ES } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from "@angular/common/locales/es";
import esUs from "@angular/common/locales/es-US";
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import {FeaturesModule} from "./features/features.module";

registerLocaleData(es, "es");
registerLocaleData(esUs, "es-US");

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        CoreModule,
        AppRouting,
        HttpClientModule,
        BrowserAnimationsModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleApiKey
        }),
        SharedModule,
        FeaturesModule
    ],
  providers: [
    { provide: NZ_I18N, useValue: es_ES },
    { provide: LOCALE_ID, useValue: "es-US" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
