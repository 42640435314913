import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {File as SelfFile, File} from '../../../core/models/file';
import {IconService} from '../../../core/services/icon.service';
import {FileService} from '../../../core/services/file.service';
import {saveAs} from 'file-saver';
import {NzMessageService} from 'ng-zorro-antd/message';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-download-file-box',
  templateUrl: './download-file-box.component.html',
  styleUrls: ['./download-file-box.component.scss']
})
export class DownloadFileBoxComponent implements OnInit {
  @Input() file: File;
  @Input() canDelete: boolean = false;
  isLoading: boolean;

  @Output() fileDeleted: EventEmitter<SelfFile> = new EventEmitter<SelfFile>();

  constructor(
      private iconService: IconService,
      private fileService: FileService,
      private messageService: NzMessageService
  ) { }

  ngOnInit(): void {
    this.file.mime = this.iconService.getMime(this.file.extension);
  }

  deleteFile() {
    this.fileDeleted.emit(this.file);
  }

  downloadFile() {
    this.isLoading = true;
    this.fileService.downloadFile(this.file.url)
        .pipe(
            finalize(() => {
              this.isLoading = false;
            })
        )
        .subscribe(data => {
          saveAs(data.body, this.file.name);
        }, error => {
          this.messageService.create(
              'error',
              error.message.replace('GraphQL error:', '').trim()
          );
        });
  }

  openFile() {
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,left=450,top=100`;
    window.open(this.file.url, '_blank', params);
  }
}
