import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Company} from "../../../core/models/company";
import {PackagesService} from "../../../core/services/packages.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {Package} from "../../../core/models/package";
import {finalize, takeUntil} from "rxjs/operators";
import {NzModalService} from "ng-zorro-antd/modal";
import {SubscriptionMessageComponent} from "../subscription-message/subscription-message.component";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Subject} from "rxjs";
import {Subscription} from "../../../core/models/subscription";

@Component({
  selector: 'app-edit-company-subscription',
  templateUrl: './edit-company-subscription.component.html',
  styleUrls: ['./edit-company-subscription.component.scss']
})
export class EditCompanySubscriptionComponent implements OnInit, OnDestroy {
  @Input() company: Company | null;
  packages: Package[];
  filterPackages: Package[];
  selectedPackage: Package | null = null;
  yearly: boolean;
  isLoading = true;
  error: string | null;
  @Output() companyEdited: EventEmitter<Company> = new EventEmitter();
  $onDestroy: Subject<void> = new Subject<void>();

  constructor(private packagesService: PackagesService,
              private modalService: NzModalService,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.getPackages();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  close() {
    this.drawerRef.close();
  }

  setPlan(plan: Package) {
    this.selectedPackage = plan;
  }

  updatePlan() {
    const modal = this.modalService.create({
      nzClosable: false,
      nzMaskClosable: false,
      nzContent: SubscriptionMessageComponent,
      nzWidth: 500,
      nzFooter: null,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        plan: this.selectedPackage,
        trialMonth: this.company?.subscription?.trialMonth
      }
    });

    modal.afterOpen.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.updatePlan
        .pipe(takeUntil(this.$onDestroy))
        .subscribe((subscription: Subscription | null) => {
          if (subscription !== null) {
            // @ts-ignore
            this.company?.subscription = subscription;
            // @ts-ignore
            this.companyEdited.emit(this.company);
            this.notification.create('success', '¡Operación exitosa!', 'Plan actualizado correctamente');
            this.drawerRef.close();
          }
        });
    });
  }

  filterPackagesBy(yearlyCharge: boolean, setDefault: boolean = false) {
    if (yearlyCharge) {
      this.filterPackages = this.packages?.filter(el => el.temporalChargeType === 'Anual');
    } else {
      this.filterPackages = this.packages?.filter(el => el.temporalChargeType === 'Mensual');
    }

    if (setDefault) {
      const index = this.filterPackages.findIndex(p => p.uid === this.company?.subscription?.activePackage?.uid);
      if (index !== -1) {
        this.selectedPackage = this.filterPackages[index];
      }
    }
  }

  private getPackages() {
    this.isLoading = true;
    this.packagesService.getPackages()
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe(packages => {
      this.packages = packages;
      this.yearly = this.company?.subscription?.activePackage?.temporalChargeType === 'Anual'
      this.filterPackagesBy(this.yearly, true);
    }, error => {
      this.error = error.message.replace("GraphQL error:", "").trim();
    });
  }

}
