import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CompanyModules} from '../../../core/models/company-modules';
import {Subject} from 'rxjs';
import {CompanyModulesService} from '../../../core/services/company-modules.service';

@Component({
  selector: 'app-privileges-form-control',
  templateUrl: './privileges-form-control.component.html',
  styleUrls: ['./privileges-form-control.component.scss']
})
export class PrivilegesFormControlComponent implements OnInit, OnDestroy {
  @Input() privileges: FormControl;

  list: CompanyModules[] = this.companyModulesService.companyModules;

  isOpen = false;

  compareModules = (o1: CompanyModules, o2: CompanyModules) => o1?.name === o2?.name;

  $onDestroy: Subject<void> = new Subject<void>();

  constructor(
      private companyModulesService: CompanyModulesService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  removePrivilege(privilege: any) {
    this.privileges.setValue(
        this.privileges.value.filter((el: CompanyModules) => el.name != privilege.name)
    );
  }

}
