import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {TimeZone} from '../models/time-zone';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor(
      private apollo: Apollo
  ) { }

  getTimezones(): Observable<TimeZone[] | undefined> {
    return this.apollo.query<{timeZones: TimeZone[]}>({
      query: gql`
          query timezones {
              timeZones {
                  uid
                  name
              }
          }
      `,
    }).pipe(
        map(({data}) => {
          return data.timeZones;
        })
    )
  }
}
