<div class="earning-row flex between wrap align-center">

  <div class="team-name-row flex align-center">
    <div class="team-icon flex align-center center">
      <app-team-icon [size]="28" [icon]="earning.icon?.url"  [color]="earning.colour"></app-team-icon>
    </div>
    <div class="team-name">
      <a>{{earning.name}}</a>
    </div>
  </div>

  <div class="category-content">
    <div class="category">{{ earning?.conceptString }}</div>
  </div>

  <div class="amount-content">
    <div class="amount"><b class="bold">Monto por defecto:</b> {{ earning?.worth | currency }}</div>
  </div>

  <div class="amount-content">
    <ng-container [ngSwitch]="earning?.imssCategory">
      <div *ngSwitchCase="'% de UMA'" class="amount"><b class="bold">IMSS:</b> {{ earning?.imssAmount }} % de UMA</div>
      <div *ngSwitchCase="'% del salario minimo'" class="amount"><b class="bold">IMSS:</b> {{ earning?.imssAmount }} % del salario minimo</div>
      <div *ngSwitchCase="'% del monto'" class="amount"><b class="bold">IMSS:</b> {{ earning?.imssAmount }} % del monto</div>
      <div *ngSwitchCase="'Cantidad'" class="amount"><b class="bold">IMSS:</b> {{ earning?.imssAmount | currency }}</div>
      <div *ngSwitchCase="'Ninguno'" class="amount"><b class="bold">IMSS:</b> {{ earning?.imssAmount || 'N/A' }}</div>
    </ng-container>
  </div>

  <div class="amount-content">
    <ng-container [ngSwitch]="earning?.isrCategory">
      <div *ngSwitchCase="'% de UMA'" class="amount"><b class="bold">ISR:</b> {{ earning?.isrAmount }} % de UMA</div>
      <div *ngSwitchCase="'% del salario minimo'" class="amount"><b class="bold">ISR:</b> {{ earning?.isrAmount }} % del salario minimo</div>
      <div *ngSwitchCase="'% del monto'" class="amount"><b class="bold">ISR:</b> {{ earning?.isrAmount }} % del monto</div>
      <div *ngSwitchCase="'Cantidad'" class="amount"><b class="bold">ISR:</b> {{ earning?.isrAmount | currency }}</div>
      <div *ngSwitchCase="'Ninguno'" class="amount"><b class="bold">ISR:</b> {{ earning?.isrAmount || 'N/A' }}</div>
    </ng-container>
  </div>

  <ng-container>
    <div class="earning-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" [nz-tooltip]="earning?.isDefault ? '' : 'Configurar percepción'" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i [ngClass]="{'icon-white': earning?.isDefault}" nz-icon nzType="setting" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item *ngIf="!earning?.isDefault" (click)="editEarning()" [nzDisabled]="isDeleting"><i class="empty-icon grey" nz-icon nzType="edit" nzTheme="outline"></i> Editar percepción</li>
        <li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting" *ngIf="!earning?.isDefault"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar esta deducción?"
            (nzOnConfirm)="deletePerception()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar percepción</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
