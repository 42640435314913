<div class="deduction-row flex between wrap align-center">

  <div class="team-name-row flex align-center">
    <div class="team-icon flex align-center center">
      <app-team-icon [size]="28" [icon]="deduction.icon?.url"  [color]="deduction.colour"></app-team-icon>
    </div>
    <div class="team-name">
      <a>{{deduction.name}}</a>
    </div>
  </div>

  <div class="category-content">
    <div class="category">{{ deduction?.conceptString }}</div>
  </div>

  <div class="amount-content">
    <div class="amount"><b class="bold">Monto por defecto:</b> {{ deduction?.amount | currency }}</div>
  </div>

  <ng-container>
    <div class="deduction-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" [nz-tooltip]="deduction?.isDefault ? '' : 'Configurar deducción'" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i [ngClass]="{'icon-white': deduction?.isDefault}" nz-icon nzType="setting" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item *ngIf="!deduction?.isDefault" (click)="editDeduction()" [nzDisabled]="isDeleting"><i class="empty-icon grey" nz-icon nzType="edit" nzTheme="outline"></i> Editar deducción</li>
        <li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting" *ngIf="!deduction?.isDefault"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar esta deducción?"
            (nzOnConfirm)="deleteDeduction()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar deducción</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
