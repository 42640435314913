import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EmployeeOnTime} from "../../../core/models/employee-on-time";

@Component({
  selector: 'app-user-horizontal-bar',
  templateUrl: './user-horizontal-bar.component.html',
  styleUrls: ['./user-horizontal-bar.component.scss']
})
export class UserHorizontalBarComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() isLoading: boolean;
  @Input() title: string;
  @Input() data: EmployeeOnTime[];
  @Input() barColor: string;
  @Output() updateChart = new EventEmitter<{month: number, year: number}>();
  selectedMonth: number = new Date().getMonth();
  selectedYear: number = new Date().getFullYear();
  years: any[];
  months = [
    {uid: 0, name: 'Enero'},
    {uid: 1, name: 'Febrero'},
    {uid: 2, name: 'Marzo'},
    {uid: 3, name: 'Abril'},
    {uid: 4, name: 'Mayo'},
    {uid: 5, name: 'Junio'},
    {uid: 6, name: 'Julio'},
    {uid: 7, name: 'Agosto'},
    {uid: 8, name: 'Septiembre'},
    {uid: 9, name: 'Octubre'},
    {uid: 10, name: 'Noviembre'},
    {uid: 11, name: 'Diciembre'},
  ];

  constructor() { }

  ngOnInit(): void {
    this.setYears(new Date().getFullYear());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && this.isLoading) {}
    if (changes.data && this.data) {}
  }

  yearChange() {
    this.updateChart.emit({month: (this.selectedMonth + 1), year: this.selectedYear})
  }

  prevMonth() {
    if (this.selectedMonth === 0) {
      this.selectedMonth = 11;
    } else {
      this.selectedMonth--;
    }
    this.updateChart.emit({month: (this.selectedMonth + 1), year: this.selectedYear})
  }

  nextMonth() {
    if (this.selectedMonth === 11) {
      this.selectedMonth = 0;
    } else {
      this.selectedMonth++;
    }
    this.updateChart.emit({month: (this.selectedMonth + 1), year: this.selectedYear})
  }

  private setYears(actualYear: number): void {
    this.years = [];
    const initialYear = 2021;
    const pastYears = actualYear - initialYear;
    for (let i = pastYears; i >= 0; i--) {
      this.years.push(initialYear + i);
    }
  }

}
