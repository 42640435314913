import {Component, Input, OnInit} from '@angular/core';
import {Color} from '../../../core/models/color';

@Component({
  selector: 'app-color-box',
  templateUrl: './color-box.component.html',
  styleUrls: ['./color-box.component.scss']
})
export class ColorBoxComponent implements OnInit {
  @Input() color: Color;
  @Input() size = 30;

  constructor() { }

  ngOnInit(): void {
  }

}
