import {Component, Input, OnInit} from '@angular/core';
import {Meeting} from "../../../core/models/meeting";

@Component({
  selector: 'app-member-meeting-not-on-time-table',
  templateUrl: './member-meeting-not-on-time-table.component.html',
  styleUrls: ['./member-meeting-not-on-time-table.component.scss']
})
export class MemberMeetingNotOnTimeTableComponent implements OnInit {
  @Input() meetings: Meeting[] | undefined;
  index: number = 1;


  constructor() { }

  ngOnInit(): void {
  }

}
