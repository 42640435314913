import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class NotAuthenticationGuard implements CanActivate {
  constructor(
      private router: Router,
      private authenticationService: AuthenticationService
  ) {}

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): boolean {
    if (!this.authenticationService.isAuthenticated()) {
      return true;
    }
    this.router
        .navigate(["./"], {
          replaceUrl: true
        })
        .finally(() => {});
    return false;
  }
}

