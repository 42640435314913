<form (submit)="updateVacations()" [formGroup]="memberForm">
  <div class="edit-member">

    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <div class="edit-member-container">
      <div nzLayout="vertical" nz-form class="form-column flex wrap">

        <div class="flex wrap form-row" style="width: 100%;">

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label>Tipo de cálculo de días</nz-form-label>
              <nz-form-control>
                <nz-radio-group formControlName="rule">
                  <label nz-radio [nzValue]="'a'">Absoluto</label>
                  <label nz-radio [nzValue]="'v'">Variable</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </form>

        </div>

        <ng-container *ngFor="let vacationConfig of getArray().controls; let i = index;">
          <app-vacation-config-control class="row-control" [vacationFormGroup]="getArrayControl(i)"></app-vacation-config-control>
        </ng-container>

      </div>
    </div>
  </div>

  <button style="display:none;" type="submit" #submit>Guardar</button>

</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="closeModal()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isLoading" (click)="submit.click()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
