<form (submit)="submitForm()" [formGroup]="memberForm">
  <div class="create-member">

    <ng-template #newPositionTitle>
      <div class="flex align-center">
        <span>Nuevo Puesto</span>
        <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'1'" nz-icon nzType="info-circle" nzTheme="fill"></i>
      </div>
    </ng-template>

    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
            nzType="error"
            [nzMessage]="error"
            nzShowIcon
      ></nz-alert>
    </div>

    <div class="create-member-container">
      <div class="flex center wrap form-column member-icon">
        <app-upload-image style="width: 26%" [roundImage]="true" [folder]="'profile'" [image]="getImageControl()"></app-upload-image>
      </div>

      <div nzLayout="vertical" nz-form class="form-column flex wrap">

        <div class="flex wrap form-row">
          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="firstName">Nombre</nz-form-label>
              <nz-form-control [nzErrorTip]="nameErrorTpl">

                <input nz-input placeholder="Nombre" formControlName="firstName" type="text" id="firstName">

                <ng-template #nameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El nombre es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="lastName">Apellido</nz-form-label>
              <nz-form-control [nzErrorTip]="lastNameErrorTpl">

                <input nz-input placeholder="Apellido" formControlName="lastName" type="text" id="lastName">

                <ng-template #lastNameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El apellido es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label>Selecciona el puesto</nz-form-label>
              <nz-form-control [nzErrorTip]="positionErrorTpl">

                <nz-select formControlName="positionUid" [nzPlaceHolder]="'Seleccionar puesto'">
                  <nz-option [nzValue]="{name: 'new'}" [nzLabel]="'+ Nuevo puesto'"></nz-option>
                  <nz-option *ngFor="let position of positions" [nzValue]="position" [nzLabel]="position?.name"></nz-option>
                </nz-select>

                <ng-template #positionErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El puesto es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item class="padding-right">
              <nz-form-label nzFor="email">Correo electrónico</nz-form-label>
              <nz-form-control [nzErrorTip]="emailErrorTpl">

                <input nz-input placeholder="Correo electrónico" autocomplete="false" formControlName="email" type="text" id="email">

                <ng-template #emailErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El correo es requerido
                  </ng-container>
                  <ng-container *ngIf="control.hasError('email')">
                    El correo es invalido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="padding-right">
              <nz-form-label nzFor="password">Contraseña <i *ngIf="memberForm?.controls?.password?.value === null" class="warning" nzTheme="fill" nz-icon nzType="warning"></i> <span *ngIf="memberForm?.controls?.password?.value === null" class="warning-text">Si no se agrega una contraseña se le asignará una auto generada</span></nz-form-label>
              <nz-form-control>
                <input nz-input placeholder="Contraseña" autocomplete="false" formControlName="password" type="text" id="password">
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="rfc">RFC</nz-form-label>
              <nz-form-control [nzErrorTip]="rfcErrorTpl">

                <input nz-input placeholder="RFC" formControlName="rfc" type="text" id="rfc">

                <ng-template #rfcErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    El RFC es incorrecto
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="curp">CURP</nz-form-label>
              <nz-form-control [nzErrorTip]="curpErrorTpl">
                <input nz-input placeholder="CURP" formControlName="curp" type="text" id="curp" uppercase>

                <ng-template #curpErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    El CURP es incorrecto
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="gender">Género</nz-form-label>
              <nz-form-control [nzErrorTip]="genderErrorTpl">

                <nz-select formControlName="gender" [nzPlaceHolder]="'Selecciona género'" id="gender" [nzAllowClear]="false">
                  <nz-option nzValue="M" nzLabel="Masculino"></nz-option>
                  <nz-option nzValue="F" nzLabel="Femenino"></nz-option>
                  <nz-option nzValue="O" nzLabel="Otro"></nz-option>
                </nz-select>

                <ng-template #genderErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El género es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="birthDate">Fecha de nacimiento</nz-form-label>
              <nz-form-control [nzErrorTip]="birthDateErrorTpl">

                <nz-date-picker style="width: 100%;" id="birthDate" [nzPlaceHolder]="'Fecha de nacimiento'" formControlName="birthDate" [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false" [nzDisabledDate]="disabledDate"></nz-date-picker>

                <ng-template #birthDateErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    La fecha de nacimiento es incorrecta
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="phone">Teléfono</nz-form-label>
              <nz-form-control [nzErrorTip]="phoneErrorTpl">

                <input nz-input formControlName="phone" placeholder="Teléfono" type="text" id="phone" [OnlyNumber]="true" />

                <ng-template #phoneErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    El teléfono es invalido
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    El teléfono es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="street">Calle</nz-form-label>
              <nz-form-control [nzErrorTip]="streetErrorTpl">

                <input nz-input
                       placeholder="Calle"
                       formControlName="street"
                       type="text"
                       id="street">

                <ng-template #streetErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Ingresa la calle de la dirección
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="exteriorNumber">No. Exterior</nz-form-label>
              <nz-form-control [nzErrorTip]="exteriorNumberErrorTpl">

                <input nz-input placeholder="No. Exterior"
                       formControlName="exteriorNumber"
                       type="text" id="exteriorNumber">

                <ng-template #exteriorNumberErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Ingresa el número exterior
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="interiorNumber">No. Interior</nz-form-label>
              <nz-form-control [nzErrorTip]="interiorNumberErrorTpl">

                <input nz-input placeholder="No. Interior"
                       formControlName="interiorNumber"
                       type="text" id="interiorNumber">

                <ng-template #interiorNumberErrorTpl let-control>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="zipcode">Código postal</nz-form-label>
              <nz-form-control [nzErrorTip]="zipcodeErrorTpl">

                <nz-input-group [nzSuffix]="suffixTemplateInfo">
                  <input nz-input
                         placeholder="Código postal"
                         formControlName="zipcode" type="text" id="zipcode">
                </nz-input-group>

                <ng-template #suffixTemplateInfo>
                  <ng-container *ngIf="zipcodeLoading">
                    <nz-spin nzSize="small"></nz-spin>
                  </ng-container>
                  <ng-container *ngIf="zipcodeError">
                    <i nz-icon
                       nz-tooltip
                       nzTooltipTitle="No se encontró el código postal"
                       [nzTooltipColor]="'white'"
                       style="color: #faad14"
                       nzType="info-circle"></i>
                  </ng-container>
                </ng-template>

                <ng-template #zipcodeErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Ingresa el código postal
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="colony">Colonia</nz-form-label>
              <nz-form-control [nzErrorTip]="colonyErrorTpl">

                <nz-select nzShowSearch id="colony" nzPlaceHolder="Colonia" formControlName="colony">
                  <nz-option *ngFor="let colony of colonies" nzCustomContent [nzLabel]="colony" [nzValue]="colony">
                    {{colony}}
                  </nz-option>
                </nz-select>

                <ng-template #colonyErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Seleccione la colonia
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="city">Ciudad</nz-form-label>
              <nz-form-control [nzErrorTip]="cityErrorTpl">

                <input nz-input placeholder="Ciudad"
                       formControlName="city" type="text" id="city">

                <ng-template #cityErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Ingresa la ciudad
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="zipcode">Estado</nz-form-label>
              <nz-form-control [nzErrorTip]="stateErrorTpl">

                <input nz-input
                       placeholder="Estado"
                       formControlName="state"
                       type="text" id="state">

                <ng-template #stateErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Ingresa el estado
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="country">País</nz-form-label>
              <nz-form-control [nzErrorTip]="countryErrorTpl">

                <nz-select nzShowSearch id="country" nzPlaceHolder="País" formControlName="country">
                  <nz-option *ngFor="let country of (countries$ | async)" nzCustomContent [nzLabel]="country!.countryName" [nzValue]="country.countryCode">
                    {{country.countryName}}
                  </nz-option>
                </nz-select>

                <ng-template #countryErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Seleccione el país
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="contractDate">Fecha de contatación</nz-form-label>
              <nz-form-control [nzErrorTip]="contractDateErrorTpl">

                <nz-date-picker style="width: 100%;" id="contractDate" [nzPlaceHolder]="'Fecha de nacimiento'" formControlName="contractDate" [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false" [nzDisabledDate]="disabledDate"></nz-date-picker>

                <ng-template #contractDateErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    La fecha de contratación es requerida
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="memberForm">
            <nz-form-item>
              <nz-form-label nzFor="NSS">Número de seguridad social</nz-form-label>
              <nz-form-control>

                <input nz-input formControlName="socialSecurityNumber" placeholder="NSS" type="text" id="NSS" [OnlyNumber]="true" />

              </nz-form-control>
            </nz-form-item>
          </form>
        </div>

      </div>

      <div nzLayout="vertical" nz-form class="form-column">

        <nz-form-item style="margin-bottom: 12px;">
          <nz-form-control>
            <label nz-checkbox formControlName="actualVacationsCheck" (ngModelChange)="vacationsUpdate($event)">Configurar vacaciones restantes</label>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item [hidden]="!memberForm.controls.actualVacationsCheck.value">
          <nz-form-label>Vacaciones restantes</nz-form-label>
          <nz-form-control [nzErrorTip]="positionErrorTpl">

            <nz-input-number
              style="width: 100%"
              formControlName="actualVacations"
              [nzPlaceHolder]="'Días de vacaciones'"
              [OnlyNumber]="true"
              [nzMin]="0"
              [nzStep]="1"
              [nzFormatter]="formatter"
              [nzParser]="parser">
            </nz-input-number>

          </nz-form-control>
        </nz-form-item>

      </div>

      <div nzLayout="vertical" nz-form class="form-column">

        <nz-form-item>
          <nz-form-label nzFor="color">Seleccionar zona horaria</nz-form-label>
          <nz-form-control [nzErrorTip]="timezoneErrorTpl">
            <nz-select nzShowSearch id="timezone" nzPlaceHolder="Zona horaria" formControlName="timezone">
              <nz-option *ngFor="let timezone of ($timezones | async)" nzCustomContent [nzLabel]="timezone.name ? timezone.name.toString() : ''" [nzValue]="timezone.name ? timezone.name.toString() : ''">
                {{timezone.name}}
              </nz-option>
            </nz-select>

            <ng-template #timezoneErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge una zona horaria
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="checksInWithTeam">Hacer check in en esta zona horaria</label>
          </nz-form-control>
        </nz-form-item>

      </div>

      <div nzLayout="vertical" nz-form class="form-column">

        <div style="margin-bottom: 26px">
          <nz-form-label>Es lider de equipo</nz-form-label>
          <app-teams-form-control [teams]="getTeamsControl()"></app-teams-form-control>
        </div>

      </div>

      <div nzLayout="vertical" nz-form class="form-column">

        <div style="margin-bottom: 40px">
          <ng-container *ngIf="!team">
            <nz-form-label>Es miembro de equipo</nz-form-label>
            <app-teams-form-control [moreThanOne]="false" [teams]="getTeamControl()"></app-teams-form-control>
          </ng-container>
        </div>

      </div>
    </div>
  </div>

  <button style="display:none;" type="submit" #submit>Guardar</button>

</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="closeModal()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isLoading" (click)="submit.click()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
