import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {Marker} from '../../../core/models/marker';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, finalize, startWith, takeUntil} from 'rxjs/operators';
import {Country} from '../../../core/models/country';
import {CountryStore} from '../../../core/stores/country.store';
import {AddressService} from '../../../core/services/address.service';
import {Address} from '../../../core/models/address';
import {GeoFencing} from '../../../core/models/geo-fencing';
import {GeoFencingService} from '../../../core/services/geo-fencing.service';

@Component({
  selector: 'app-new-geo-fencing-modal',
  templateUrl: './new-geo-fencing-modal.component.html',
  styleUrls: ['./new-geo-fencing-modal.component.scss']
})
export class NewGeoFencingModalComponent implements OnInit, OnDestroy {
  @Input() employeeUid: string | null = null;
  @Input() address: Address | null = null;
  marker: any;
  newGeoFencing: FormGroup;
  zipcodeLoading: boolean = false;
  zipcodeError: boolean = false;

  colonies: any[] = [];
  addressLoading: boolean;
  addressError: string | null;

  $onDestroy: Subject<void> = new Subject<void>();
  countries$: Observable<Country[] | undefined> = this.countryStore.getCountries();

  @Output() geoFencingCreated: EventEmitter<GeoFencing> = new EventEmitter<GeoFencing>();
  isLoading: boolean;
  error: string | null;

  constructor(
      private modalRef: NzModalRef,
      private formBuilder: FormBuilder,
      private formHelper: FormHelperService,
      private countryStore: CountryStore,
      private addressService: AddressService,
      private geoFencingService: GeoFencingService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  destroyModal() {
    this.modalRef.close();
  }

  submitForm() {
    if (this.newGeoFencing.valid) {
        this.isLoading = true;
        this.error = null;
        const data = this.newGeoFencing.getRawValue();
        if (this.employeeUid !== null) {
          data.employeeUid = this.employeeUid;
        }
        this.geoFencingService.createGeoFencing(data)
            .pipe(
                finalize(() => {
                  this.isLoading = false;
                })
            ).subscribe(
                res => {
                  this.geoFencingCreated.emit(res);
                  this.modalRef.close();
                }, error => {
                  this.error = error;
            }
        );
    } else {
      this.formHelper.markFormAsDirty(this.newGeoFencing);
      this.formHelper.markGroupAsDirty(this.newGeoFencing, 'addressData');
    }
  }

  onMarkerChange($event: Marker) {
    this.getAddressForm().controls.latitude.setValue($event.latitude);
    this.getAddressForm().controls.longitude.setValue($event.longitude);
  }

  getAddressForm(): FormGroup {
    return this.newGeoFencing.controls.addressData as FormGroup;
  }

  private createForm() {
    this.newGeoFencing = this.formBuilder.group({
      name: [null, [Validators.required]],
      rangeMeters: [300, [Validators.required]],
      remoteExclusive: [false],
      addressData: this.formBuilder.group({
        latitude: [this.address ? this.address?.latitude : null],
        longitude: [this.address ? this.address?.longitude : null],
        country: [this.address ? null : 'MX', [Validators.required]],
        state: [this.address ? this.address?.state : null, [Validators.required]],
        colony: [null, [Validators.required]],
        city: [this.address ? this.address?.city : null, [Validators.required]],
        zipcode: [this.address ? this.address?.zipcode : null, [Validators.required]],
        street: [this.address ? this.address?.street : null, [Validators.required]],
        interiorNumber: [this.address ? this.address?.interiorNumber : null],
        exteriorNumber: [this.address ? this.address?.exteriorNumber : null, [Validators.required]]
      })
    });

    this.getAddressForm().controls.zipcode.valueChanges
        .pipe(
            takeUntil(this.$onDestroy),
            debounceTime(500)
        ).subscribe(zipcode => {
          if (zipcode && this.getAddressForm().controls.country.value) {
            this.getColonies(zipcode, this.getAddressForm().controls.country.value);
          }
    });

    this.getAddressForm().controls.country.valueChanges
        .pipe(
            takeUntil(this.$onDestroy)
        ).subscribe(country => {
          if (country && this.getAddressForm().controls.zipcode.value) {
            this.getColonies(this.getAddressForm().controls.zipcode.value, country);
          }
    });

    combineLatest([
        this.getAddressForm().controls.country.valueChanges.pipe(startWith(null)),
        this.getAddressForm().controls.state.valueChanges.pipe(startWith(null)),
        this.getAddressForm().controls.colony.valueChanges.pipe(startWith(null)),
        this.getAddressForm().controls.city.valueChanges.pipe(startWith(null)),
        this.getAddressForm().controls.zipcode.valueChanges.pipe(startWith(null)),
        this.getAddressForm().controls.street.valueChanges.pipe(startWith(null)),
        this.getAddressForm().controls.interiorNumber.valueChanges.pipe(startWith(null)),
        this.getAddressForm().controls.exteriorNumber.valueChanges.pipe(startWith(null))
        ])
        .pipe(
            takeUntil(this.$onDestroy),
            debounceTime(500)
        ).subscribe(
        (changes) => {
              if (this.getAddressForm().valid) {
                this.getCoordinates();
                if (this.address === null) {
                  this.getCoordinates();
                } else {
                  if (this.address?.latitude !== 0 && this.address?.longitude !== 0) {
                    this.marker = {
                      latitude: this.address?.latitude,
                      longitude: this.address?.longitude
                    };
                  } else {
                    this.getCoordinates();
                  }
                }
              }
            });

    if (this.address !== null) {
      this.getCountry();
    }
  }

  private getCountry() {
    this.countries$.subscribe(countries => {
      if (countries) {
        const index = countries.findIndex(c => c.countryName === this.address?.country);
        if (index !== -1) {
          this.getAddressForm().controls.country.setValue(countries[index].countryCode);
        } else {
          this.getAddressForm().controls.country.setValue('MX');
        }
        if (this.address !== null) {
          if (this.getAddressForm().controls.zipcode.value && this.getAddressForm().controls.country.value) {
            this.getColonies(this.getAddressForm().controls.zipcode.value, this.getAddressForm().controls.country.value);
          }
        }
      }
    });
  }

  private setAddress(address: Address | null) {
    if (address) {
      this.zipcodeError = false;
      this.colonies = address.colony;
      if (this.address === null) {
        this.getAddressForm().controls.state.setValue(address.state);
        this.getAddressForm().controls.city.setValue(address.city);
        this.getAddressForm().controls.colony.setValue(address.colony[0]);
      } else {
        const index = address.colony.findIndex((c: string) => c === this.address?.colony);
        if (index !== -1) {
          this.getAddressForm().controls.colony.setValue(address.colony[index]);
        } else {
          this.getAddressForm().controls.colony.setValue(address.colony[0]);
        }
      }
    } else {
      this.zipcodeError = true;
      this.colonies = [];
      this.getAddressForm().controls.colony.setValue(null);
      this.getAddressForm().controls.state.setValue(null);
      this.getAddressForm().controls.city.setValue(null);
    }
  }

  private getColonies(zipcode: string, country: string) {
    this.zipcodeLoading = true;
    this.zipcodeError = false;
    this.addressService.getAddressByZipcode(zipcode, country)
        .pipe(
            finalize(() => {
              this.zipcodeLoading = false;
            })
        )
        .subscribe(
            address => {
              this.setAddress(address);
            }
        )
  }

  private getCoordinates() {
    this.addressLoading = true;
    this.addressError = null;
    this.addressService.getCoordinates(this.getAddressForm().getRawValue())
        .pipe(
            finalize(() => {
              this.addressLoading = false;
            })
        ).subscribe(coordinates => {
          this.marker = coordinates ? coordinates : null;
          if (coordinates) {
            this.getAddressForm().controls.latitude.setValue(coordinates.latitude);
            this.getAddressForm().controls.longitude.setValue(coordinates.longitude);
          }
    }, error => {
      this.addressError = error;
    })
  }
}
