import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Announcement} from '../../../core/models/announcement';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-announcement-details',
  templateUrl: './announcement-details.component.html',
  styleUrls: ['./announcement-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnnouncementDetailsComponent implements OnInit {
  @Input() announcement: Announcement;
  announcementForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.announcementForm = this.formBuilder.group({
      addressedTeams: [this.announcement.addressedTeams]
    });
  }

  getTeamsControl(): FormControl {
    return this.announcementForm.controls.addressedTeams as FormControl;
  }

}
