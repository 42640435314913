<div class="change-active-resource-holder-modal-component">

  <form nz-form nzLayout="vertical" [formGroup]="membersForm" *ngIf="membersForm">
    <form [formGroup]="membersForm" style="width: 100%; flex-basis: auto;">
      <div class="active-container">
        <div class="box" *ngIf="activeResource?.photo">
          <div class="img-box" [style.backgroundImage]="'url(' + activeResource?.photo?.url + ')'"></div>
        </div>

        <div class="box" *ngIf="!activeResource?.photo">
          <div class="active-box" [style.backgroundColor]="activeResource?.category?.color">
            <i nz-icon nzType="icons:monitor"></i>
          </div>
        </div>

        <div class="active-info">
          <div class="active-name">{{ activeResource?.name }}</div>
          <div class="active-sku">{{ activeResource?.serial }}</div>
        </div>
      </div>


      <nz-form-item>
        <nz-form-label>Seleccionar colaborador</nz-form-label>
        <nz-form-control [nzErrorTip]="memberErrorTpl">

          <nz-select
            formControlName="member"
            [nzPlaceHolder]="'Seleccionar colaborador'"
            nzShowSearch
            nzServerSearch
            (nzOnSearch)="onMembersSearch($event)"
            (nzScrollToBottom)="loadMoreMembers()"
            [nzDropdownRender]="renderTemplate">
            <nz-option [nzValue]="{uid: 'delete'}" [nzLabel]="'No asignar'"></nz-option>
            <nz-option nzCustomContent *ngFor="let member of members" [nzValue]="member" [nzLabel]="member?.firstName + ' ' + member?.lastName">
              <div class="member-row flex align-center">
                <div class="member-photo member-photo-control">
                  <nz-avatar [nzSize]="30"  [nzSrc]="member.photo?.url || undefined"
                             [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
                </div>
                <div class="member-name">
                  {{(member.firstName + ' ' + member.lastName) | titlecase}}
                </div>
              </div>
            </nz-option>
          </nz-select>

          <ng-template #renderTemplate>
            <nz-spin *ngIf="membersLoading"></nz-spin>
          </ng-template>

          <ng-template #memberErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El colaborador es requerido
            </ng-container>
          </ng-template>

          <!--<ng-template #optionsTemplate let-selected>
            <div *ngIf="selected.nzValue.uid === 'delete'">No asignar</div>
            <div class="member member-photo-control" nzTooltipColor="white" *ngIf="selected.nzValue.uid !== 'delete'"
                 [nz-tooltip]="(selected.nzValue.firstName | titlecase) + ' ' + (selected.nzValue.lastName | titlecase)">
              <nz-avatar [nzSize]="20"  [nzSrc]="selected.nzValue.photo?.url || undefined"
                         [nzText]="selected.nzValue.firstName?.charAt(0) + '' + selected.nzValue.lastName?.charAt(0)"></nz-avatar>
              <div class="name">
                {{(selected.nzValue.firstName + ' ' + selected.nzValue.lastName) | titlecase}}
              </div>
            </div>
          </ng-template>-->
        </nz-form-control>
      </nz-form-item>
    </form>
  </form>

  <div class="btns-container">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="updateHolder()">Guardar</button>
  </div>
</div>
