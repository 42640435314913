<div class="side-menu flex column hide-scrollbar" [ngClass]="{'show': !hidden}">

  <div class="main-menu mobile flex column">
    <div class="menu-header">
      <div [routerLink]="['/home/profile']" class="home-header flex align-center" *ngIf="($user | async)">
        <div class="company-avatar">
          <nz-avatar [nzGap]="6" [nzSize]="30" [nzText]="($user | async) ? (($user | async)?.firstName?.charAt(0) + '' + ($user | async)?.lastName?.charAt(0)) : undefined" [nzSrc]="($user | async)?.photo?.url"></nz-avatar>
        </div>

        <div class="user-box flex column">
          <div class="company-name">
            {{($user | async)?.firstName + ' ' + ($user | async)?.lastName}}
          </div>
          <div class="user-name" *ngIf="($user | async)?.position">
            {{($user | async)?.position?.name}}
          </div>
        </div>
      </div>
    </div>

    <div class="links-column">
      <div (click)="toggleSideMenu()" routerLinkActive="active" [routerLink]="[link.link]" class="link flex align-center" *ngFor="let link of pages">
        <i class="page-icon" nz-icon [nzType]="link.icon"></i>
        <div class="label">
          {{link.name}}
        </div>
      </div>

      <div (click)="toggleSideMenu()" routerLinkActive="active" [routerLink]="[link.link]" class="link flex align-center" *ngFor="let link of settings">
        <i class="page-icon" nz-icon [nzType]="link.icon"></i>
        <div class="label">
          {{link.name}}
        </div>
      </div>

      <div (click)="logout()" class="link flex align-center">
        <i class="page-icon" nz-icon [nzType]="'logout'"></i>
        <div class="label">
          Cerrar sesión
        </div>
      </div>
    </div>

  </div>

  <div class="second-menu flex column">
    <div class="menu-header">
      <ng-template [ngTemplateOutlet]="header"></ng-template>
    </div>

    <div class="links-column" *ngIf="menu?.links?.length">
      <div (click)="toggleSideMenu()" routerLinkActive="active" [routerLink]="[link.route]" class="link flex align-center" *ngFor="let link of menu?.links">
        <i class="page-icon" nz-icon [nzType]="link.icon"></i>
        <div class="label">
          {{link.name}}
        </div>
      </div>
    </div>

    <div class="groups-column flex column" *ngIf="menu?.groups?.length">
      <div class="group" *ngFor="let group of menu?.groups">
        <div class="group-name">
          {{group.title | uppercase}}
        </div>
        <div class="links-column">
          <div (click)="toggleSideMenu()" routerLinkActive="active" [routerLink]="[link.route]" class="link flex align-center" *ngFor="let link of group?.links">
            <i class="page-icon" nz-icon [nzType]="link.icon"></i>
            <div class="label">
              {{link.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="backdrop mobile" (click)="toggleSideMenu()" [ngClass]="{'show': !hidden}"></div>

<div class="toggle-menu mobile" (click)="toggleSideMenu()">
  <i nz-icon nzType="menu" nzTheme="outline"></i>
</div>
