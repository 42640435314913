import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {File} from '../models/file';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
      private http: HttpClient,
      private authService: AuthenticationService
  ) { }

  public uploadImage(category: string, file: any, name: string = ''): Observable<File> {
    const formData = new FormData();
    formData.append("image_file", file);
    const uploadImage =
        `mutation{
      adminUploadFile(
         folderName: "` + category + `" name: "` + name + `") {
         file {
          uid
          url
          name
          extension
         }
        }
      }`;
    const header = new HttpHeaders().set(
        "Authorization",
        "JWT " + this.authService.credentials?.authToken
    );
    return this.http
        .post<{
          data: {
            adminUploadFile: {
              file: File;
            };
          };
        }>(environment.apiUrl + "/graphql/?query=" + uploadImage, formData, {
          headers: header
        })
        .pipe(
            map(res => {
              if (res.data.adminUploadFile) {
                return res.data.adminUploadFile.file;
              } else {
                throw Error("Hubo un problema al subir el archivo.");
              }
            })
        );
  }

  downloadFile(url: string | undefined): Observable<any> {
    return this.http.get(url || '', {
      responseType: 'blob',
      observe: 'response'
    });
  }

  downloadFileWithAuth(url: string): Observable<any> {
    // @ts-ignore
    const header = new HttpHeaders().set('Authorization', 'JWT ' + this.authService.credentials.authToken);
    return this.http.get(url, {
      responseType: 'blob',
      headers: header,
      observe: 'response'
    });
  }

  downloadFile2(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Cache-Control':
          'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      }
    });
  }
}
