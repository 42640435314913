import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActiveResources} from "../../../core/models/active-resources";
import {ActiveResourcesService} from "../../../core/services/active-resources.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {ActiveResourceCategory} from "../../../core/models/active-resource-category";
import {Subject} from "rxjs";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {NzModalService} from "ng-zorro-antd/modal";
import {CreateActiveResourceCategoryComponent} from "../create-active-resource-category/create-active-resource-category.component";
import {DatePipe} from "@angular/common";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

@Component({
  selector: 'app-new-active-resource-modal',
  templateUrl: './new-active-resource-modal.component.html',
  styleUrls: ['./new-active-resource-modal.component.scss']
})
export class NewActiveResourceModalComponent implements OnInit, OnDestroy {
  activeResourceForm: FormGroup;
  isLoading = false;
  error: string | null;
  $onDestroy: Subject<void> = new Subject<void>();

  categories: ActiveResourceCategory[] = [];
  categoriesLoading = false;
  categoriesIndex = 1;
  stopLoading = false;
  searchCategory: null | string;
  $searchChange: Subject<string> = new Subject<string>();
  firstSearch = true;

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(new Date(), current) < 0;
  };

  constructor(private activeResourcesService: ActiveResourcesService,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              private notification: NzNotificationService,
              private modalService: NzModalService,
              private datePipe: DatePipe,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.subscribeToSearch();
    this.loadMoreCategories();
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();

    this.$searchChange.next();
    this.$searchChange.complete();
  }

  close() {
    this.drawerRef.close(null);
  }

  getImageControl(): FormControl {
    return this.activeResourceForm.controls.photoUid as FormControl;
  }

  getEmployeeControl(): FormControl {
    return this.activeResourceForm.controls.employeeUid as FormControl;
  }

  categorySearch($event: any): void {
    this.$searchChange.next($event);
  }

  loadMoreCategories(): void {
    if (!this.stopLoading) {
      this.categoriesLoading = true;
      this.activeResourcesService.getActiveResourceCategories({
        substring: this.searchCategory,
        skip: this.categoriesIndex,
        limit: 10
      }).pipe(
        finalize(() => {
          this.categoriesLoading = false;
        })
      )
        .subscribe(categoriesTable => {
          if (categoriesTable.categories?.length) {
            this.categories = [...this.categories, ...categoriesTable.categories];
          } else {
            this.stopLoading = true;
          }
        });
      this.categoriesIndex++;
    }
  }

  openNewCategory() {
    const modal = this.modalService.create({
      nzTitle: 'Nueva categoría',
      nzContent: CreateActiveResourceCategoryComponent,
      nzWidth: 500,
      nzFooter: null,
      nzWrapClassName: 'vertical-center-modal',
      nzOnCancel: () => new Promise((resolve, reject) => {
        this.activeResourceForm.controls.categoryUid.setValue(null);
        // close modal
        resolve(true);
      })
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((category: ActiveResourceCategory | null) => {
      if (category !== null) {
        this.categories.push(category);
        this.activeResourceForm.controls.categoryUid.setValue(category);
      } else {
        this.activeResourceForm.controls.categoryUid.setValue(null);
      }
    });
  }

  onCategorySearch($event: string) {
    if (!this.firstSearch) {
      this.stopLoading = false;
      this.categories = [];
      this.searchCategory = $event === "" ? null : $event;
      this.categoriesIndex = 1;
      this.loadMoreCategories();
    }
    this.firstSearch = false;
  }

  createActiveResource() {
    if (this.activeResourceForm.valid) {
      this.isLoading = true;
      const data = this.activeResourceForm.getRawValue();
      if (data.photoUid !== null) {
        data.photoUid = data.photoUid.uid;
      }
      if (data.employeeUid.length > 0) {
        data.employeeUid = data.employeeUid[0].uid;
      } else {
        data.employeeUid = null;
      }
      data.purchaseDate = this.datePipe.transform(data.purchaseDate, 'yyyy-MM-dd');
      if (data.categoryUid !== null && data.categoryUid !== 'all') {
        // @ts-ignore
        data.categoryUid = data.categoryUid.uid;
      } else {
        data.categoryUid = null;
      }
      this.activeResourcesService.createActiveResource(data)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((activeResource: ActiveResources) => {
          this.notification.create('success', '¡Operación exitosa!','Activo creado correctamente');
          this.drawerRef.close(activeResource);
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al crear el activo', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelperService.markFormAsDirty(this.activeResourceForm);
    }
  }

  private createForm() {
    this.activeResourceForm = this.formBuilder.group({
      categoryUid: [null, Validators.required],
      description: [null],
      employeeUid: [[]],
      name: [null, Validators.required],
      photoUid: [null],
      purchaseDate: [null, Validators.required],
      serial: [null, Validators.required]
    });

    this.activeResourceForm.controls.categoryUid.valueChanges.subscribe(value => {
      if (value !== undefined && value !== null && value.name === 'new') {
        this.openNewCategory();
      }
    });
  }

  private subscribeToSearch() {
    this.$searchChange
      .pipe(takeUntil(this.$onDestroy), debounceTime(400))
      .subscribe(search => {
        this.onCategorySearch(search);
      });
  }
}
