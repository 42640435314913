<nz-spin [nzSpinning]="isLoading" class="upload-image">
  <ng-container *ngIf="!image?.value">
    <div (click)="newImage.click()" class="no-photo-box flex center align-center" [style]="{background: roundImage ? 'white' : '#d8d8d8'}">
      <div class="img-box">
        <img src="../assets/images/photo-no.png">
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="image?.value">
    <div (click)="newImage.click()" class="photo-box">
      <div class="background-image" [ngStyle]="{backgroundImage: 'url(' + image?.value?.url + ')', borderRadius: roundImage ? '50%' : '0'}"></div>
    </div>
  </ng-container>
</nz-spin>

<input style="display: none;" type="file" #newImage (change)="uploadImage($event)" accept="image/jpg,image/jpeg,image/png">
