<div class="add-paycheck-modal">

  <div class="error-box" *ngIf="error">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <form nz-form nzLayout="vertical" [formGroup]="payrollForm" *ngIf="payrollForm">
    <nz-form-item>
      <nz-form-label>Seleccionar colaborador</nz-form-label>
      <nz-form-control [nzErrorTip]="memberErrorTpl">

        <nz-select
          formControlName="employeeUid"
          [nzPlaceHolder]="'Seleccionar colaborador'"
          nzShowSearch
          nzServerSearch
          (nzOnSearch)="substringSearch($event)"
          (nzScrollToBottom)="loadMembers()"
          [nzDropdownRender]="renderTemplate">
          <nz-option [nzValue]="{uid: 'new'}" [nzLabel]="'+ Nuevo colaborador'"></nz-option>
          <nz-option *ngFor="let member of members" [nzValue]="member?.uid" [nzLabel]="member?.firstName + ' ' + member?.lastName"></nz-option>
        </nz-select>

        <ng-template #memberErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            El colaborador es requerido
          </ng-container>
        </ng-template>

        <ng-template #renderTemplate>
          <nz-spin *ngIf="membersLoading"></nz-spin>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="btns-container">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="addMember()">Guardar</button>
  </div>
</div>
