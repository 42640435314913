import { Component, OnInit } from '@angular/core';
import {EmployerRegistryService} from "../../../core/services/employer-registry.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {finalize} from "rxjs/operators";
import {SocialSecurityRisk} from "../../../core/models/social-security-risk";
import {EmployerRegistry} from "../../../core/models/employer-registry";

@Component({
  selector: 'app-new-employer-registry-modal',
  templateUrl: './new-employer-registry-modal.component.html',
  styleUrls: ['./new-employer-registry-modal.component.scss']
})
export class NewEmployerRegistryModalComponent implements OnInit {
  isLoading = true;
  isCreating = false;
  error: string | null;
  employerRegistryForm: FormGroup;
  socialSecurityRisks: SocialSecurityRisk[] | undefined;
  formatterPercentage = (value: number) => `${value + ' %'}`;
  parserPercentage = (value: string) => value.replace(" %", "");
  formatterDays = (value: number) => `${value + (value === 1 ? ' día' : ' días')}`;
  parserDays = (value: string) => value.replace(' día', '').replace(' días', '');

  constructor(private employerRegistryService: EmployerRegistryService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.getSsrisks();
  }

  close() {
    this.drawerRef.close(null);
  }

  setPercentage($event: SocialSecurityRisk) {
    this.employerRegistryForm.controls.riskPercentage.setValue($event?.percentage);
  }

  createEmployerRegistry() {
    if (this.employerRegistryForm.valid) {
      this.isCreating = true;
      const data = this.employerRegistryForm.getRawValue();
      data.ssRiskUid = data.ssRiskUid.uid;
      this.employerRegistryService.createEmployerRegistry(data)
        .pipe(finalize(() => {
          this.isCreating = false;
        }))
        .subscribe((employerRegistry: EmployerRegistry) => {
          this.notification.create('success', '¡Operación exitosa!', 'Registro patronal creado correctamente');
          this.drawerRef.close(employerRegistry);
        }, error => {
          this.notification.create('error', 'Error al crear el registro patronal', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.employerRegistryForm);
    }
  }

  private getSsrisks() {
    this.isLoading = true;
    this.employerRegistryService.getSsrisks()
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((socialSecurityRisks: SocialSecurityRisk[] | undefined) => {
        this.socialSecurityRisks = socialSecurityRisks;
        this.createForm();
      }, error => {
        this.error = error.message.replace("GraphQL error:", "").trim();
        // this.notification.create('error', 'Error al crear el activo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  private createForm() {
    this.employerRegistryForm = this.formBuilder.group({
        ISNPercentage: [3, Validators.required],
        NPIEId: [null, Validators.required],
        businessName: [null, Validators.required],
        clabe: [null, Validators.required],
        holidayBonusDays: [15, Validators.required],
        holidayBonusPercentage: [25, Validators.required],
        proportionalAbsencesPolicy: [false],
        rfc: [null, [Validators.pattern(
          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
        ), Validators.required]],
        riskPercentage: [null, Validators.required],
        socialReason:[null, Validators.required],
        ssRiskUid: [null, Validators.required]
    });
  }
}
