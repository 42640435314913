import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-help-company-name',
  templateUrl: './help-company-name.component.html',
  styleUrls: ['./help-company-name.component.scss'],
})
export class HelpCompanyNameComponent implements OnInit {
  constructor(private nzModuleRef: NzModalRef) {}

  ngOnInit(): void {}

  closeModal() {
    this.nzModuleRef.close();
  }
}
