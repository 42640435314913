import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Company} from "../../../core/models/company";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../../../core/services/company.service";
import {AddressService} from "../../../core/services/address.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {Address} from "../../../core/models/address";
import {Observable, Subject} from "rxjs";
import {Country} from "../../../core/models/country";
import {CountryStore} from "../../../core/stores/country.store";

@Component({
  selector: 'app-edit-company-contact-data',
  templateUrl: './edit-company-contact-data.component.html',
  styleUrls: ['./edit-company-contact-data.component.scss']
})
export class EditCompanyContactDataComponent implements OnInit, OnDestroy {
  @Input() company: Company | null;
  companyForm: FormGroup;
  isLoading = false;
  error: string | null;
  @Output() companyEdited: EventEmitter<Company> = new EventEmitter();
  $onDestroy: Subject<void> = new Subject<void>();
  countries$: Observable<Country[] | undefined> = this.countryStore.getCountries();
  zipcodeLoading: boolean = false;
  zipcodeError: boolean = false;
  colonies: any[] = [];
  addressLoading: boolean;
  addressError: string | null;

  constructor(private companyService: CompanyService,
              private addressService: AddressService,
              private countryStore: CountryStore,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  closeModal() {
    this.drawerRef.close();
  }

  getAddressForm(): FormGroup {
    return this.companyForm.controls.addressData as FormGroup;
  }

  submitForm() {
    if (this.companyForm.valid) {
      this.isLoading = true;
      const data = this.companyForm.getRawValue();
      this.companyService.updateCompanyContact(data)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((company: Company) => {
          this.notification.create('success', '¡Operación exitosa!', 'Datos actualizados correctamente');
          // @ts-ignore
          this.companyEdited.emit(company);
          this.drawerRef.close();
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al actualizar la información', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.companyForm);
      this.formHelper.markGroupAsDirty(this.companyForm, 'addressData');
    }
  }

  private createForm() {
    this.companyForm = this.formBuilder.group({
      email: [this.company?.contact?.email, [Validators.required, Validators.email]],
      firstName: [this.company?.contact?.firstName, Validators.required],
      lastName: [this.company?.contact?.lastName, Validators.required],
      phone: [this.company?.contact?.phone ? this.company?.contact?.phone : null, [Validators.pattern(/\d{10}/)]],
      addressData: this.formBuilder.group({
        latitude: [0],
        longitude: [0],
        country: ['MX', [Validators.required]],
        state: [this.company?.contact?.address ? this.company?.contact?.address?.state : null, [Validators.required]],
        colony: [this.company?.contact?.address ? this.company?.contact?.address?.colony : null, [Validators.required]],
        city: [this.company?.contact?.address ? this.company?.contact?.address?.city : null, [Validators.required]],
        zipcode: [this.company?.contact?.address ? this.company?.contact?.address?.zipcode : null, [Validators.required]],
        street: [this.company?.contact?.address ? this.company?.contact?.address?.street : null, [Validators.required]],
        interiorNumber: [this.company?.contact?.address ? this.company?.contact?.address?.interiorNumber : null],
        exteriorNumber: [this.company?.contact?.address ? this.company?.contact?.address?.exteriorNumber : null, [Validators.required]]
      })
    });

    this.getAddressForm().controls.zipcode.valueChanges
      .pipe(
        takeUntil(this.$onDestroy),
        debounceTime(500)
      ).subscribe(zipcode => {
      if (zipcode && this.getAddressForm().controls.country.value) {
        this.getColonies(zipcode, this.getAddressForm().controls.country.value);
      }
    });

    this.getAddressForm().controls.country.valueChanges
      .pipe(
        takeUntil(this.$onDestroy)
      ).subscribe(country => {
      if (country && this.getAddressForm().controls.zipcode.value) {
        this.getColonies(this.getAddressForm().controls.zipcode.value, country);
      }
    });

    if (this.company?.contact?.address !== null) {
      this.getCountry();
    }
  }

  private getCountry() {
    this.countries$.subscribe(countries => {
      if (countries) {
        const index = countries.findIndex(c => c.countryName === this.company?.contact?.address?.country);
        if (index !== -1) {
          this.getAddressForm().controls.country.setValue(countries[index].countryCode);
        } else {
          this.getAddressForm().controls.country.setValue('MX');
        }
        if (this.company?.contact?.address !== null) {
          if (this.getAddressForm().controls.zipcode.value && this.getAddressForm().controls.country.value) {
            this.getColonies(this.getAddressForm().controls.zipcode.value, this.getAddressForm().controls.country.value);
          }
        }
      }
    });
  }

  private setAddress(address: Address | null) {
    if (address) {
      this.zipcodeError = false;
      this.colonies = address.colony;
      if (this.company?.contact?.address === null) {
        this.getAddressForm().controls.state.setValue(address.state);
        this.getAddressForm().controls.city.setValue(address.city);
        this.getAddressForm().controls.colony.setValue(address.colony[0]);
      } else {
        const index = address.colony.findIndex((c: string) => c === this.company?.contact?.address?.colony);
        if (index !== -1) {
          this.getAddressForm().controls.colony.setValue(address.colony[index]);
        } else {
          this.getAddressForm().controls.colony.setValue(address.colony[0]);
        }
      }
    } else {
      this.zipcodeError = true;
      this.colonies = [];
      this.getAddressForm().controls.colony.setValue(null);
      this.getAddressForm().controls.state.setValue(null);
      this.getAddressForm().controls.city.setValue(null);
    }
  }

  private getColonies(zipcode: string, country: string) {
    this.zipcodeLoading = true;
    this.zipcodeError = false;
    this.addressService.getAddressByZipcode(zipcode, country)
      .pipe(
        finalize(() => {
          this.zipcodeLoading = false;
        })
      )
      .subscribe(
        address => {
          this.setAddress(address);
        }
      )
  }

}
