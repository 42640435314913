<div class="modules-form-control align-center flex wrap">

  <div class="flex">
    <nz-select
          [(nzOpen)]="isOpen"
          class="hidden-select"
          [nzDropdownMatchSelectWidth]="false"
          [nzMaxTagCount]="0"
          [compareWith]="compareModules"
          [nzOptionHeightPx]="40"
          [nzOptionOverflowSize]="5"
          [nzDropdownClassName]="'modules-form-control-select'"
          nzMode="multiple"
          nzPlaceHolder="Selecciona a los miembros"
          [formControl]="privileges">

      <ng-container *ngFor="let module of list">
        <nz-option nzCustomContent [nzLabel]="(module.name | titlecase) || ''" [nzValue]="module">
          <div class="module-row flex align-center">
            <div class="module-photo module-photo-control">
              <app-team-icon type="icon" [size]="28" [icon]="module.icon"  [color]="module.color"></app-team-icon>
            </div>
            <div class="module-name">
              {{(module.name) | titlecase}}
            </div>
          </div>
        </nz-option>
      </ng-container>

    </nz-select>
  </div>

  <ng-container>
    <div class="module bubble" nzTooltipColor="white"
         [nz-tooltip]="(module.name | titlecase)" *ngFor="let module of privileges.value">
      <i (click)="removePrivilege(module)" class="close-icon" nz-icon [nzTwotoneColor]="'#FF8484'" nzType="close-circle" nzTheme="twotone"></i>
      <app-team-icon [size]="36" type="icon" [icon]="module.icon" [color]="module.color"></app-team-icon>
    </div>
  </ng-container>

  <div class="module add-new" (click)="isOpen = true;">
    <i nz-icon nzType="icons:member-add" class="loading-icon"></i>
  </div>
</div>
