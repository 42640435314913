<div class="new-document-type">
  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <form nz-form nzLayout="vertical" [formGroup]="documentsForm" *ngIf="documentsForm && !error">
    <nz-form-item>
      <nz-form-label>Documento</nz-form-label>
      <nz-form-control [nzErrorTip]="documentErrorTpl">

        <input nz-input formControlName="document" [placeholder]="'Nombre del documento'">

        <ng-template #documentErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            El nombre del documento es requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="btns-container">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button *ngIf="!error" [nzLoading]="isLoading" nz-button nzType="primary" (click)="createDocument()">Guardar</button>
  </div>
</div>
