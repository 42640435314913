<div class="count-card-component">
  <nz-spin [nzSpinning]="isLoading">
    <div class="counter-container" *ngIf="!isLoading">
      <ng-container [ngSwitch]="type">
        <i *ngSwitchCase="'employees'" nz-icon nzType="icons:employee"></i>
        <i *ngSwitchCase="'teams'" nz-icon nzType="icons:customer"></i>
        <i *ngSwitchCase="'leaders'" nz-icon nzType="icons:leader"></i>
      </ng-container>

      <div class="details-box">
        <div class="amount">{{ data }}</div>
        <ng-container [ngSwitch]="type">
          <div *ngSwitchCase="'employees'" class="subtitle">Empleados Totales</div>
          <div *ngSwitchCase="'teams'" class="subtitle">Equipos de trabajo</div>
          <div *ngSwitchCase="'leaders'" class="subtitle">Líderes</div>
        </ng-container>
      </div>
    </div>
  </nz-spin>
</div>
