import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {GeoFencing} from '../../../core/models/geo-fencing';
import {GeoFencingService} from '../../../core/services/geo-fencing.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {NewGeoFencingModalComponent} from '../../modals/new-geo-fencing-modal/new-geo-fencing-modal.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AddressService} from '../../../core/services/address.service';

@Component({
  selector: 'app-geo-fencings-form-control',
  templateUrl: './geo-fencings-form-control.component.html',
  styleUrls: ['./geo-fencings-form-control.component.scss']
})
export class GeoFencingsFormControlComponent implements OnInit, OnDestroy {

  @Input() geoFencings: FormControl;

  list: GeoFencing[] | undefined;

  geoFencingsLoading = false;
  isOpen = false;

  $onDestroy: Subject<void> = new Subject<void>();
  $searchChange: Subject<string> = new Subject<string>();

  compareGeoFencing = (o1: GeoFencing, o2: GeoFencing) => o1?.uid === o2?.uid;

  @ViewChild('newGeoFencingTitle') newGeoFencingTitle: TemplateRef<any>;

  constructor(
      private geoFencingService: GeoFencingService,
      private modalService: NzModalService,
      private addressService: AddressService
  ) { }

  ngOnInit(): void {
    this.loadGeoFencings();
  }

  ngOnDestroy(): void {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  private loadGeoFencings() {
    this.geoFencingsLoading = true;
    this.geoFencingService.getGeoFencings()
        .pipe(
            finalize(() => {
              this.geoFencingsLoading = false;
            })
        )
        .subscribe( (res) => {
          this.list = res;
        });
  }

  removeGeoFencing(geoFencing: any) {
    this.geoFencings.setValue(
        this.geoFencings.value.filter((el: GeoFencing) => el.uid != geoFencing.uid)
    );
  }

  openNewGeoFencing() {
    const modal = this.modalService.create({
      nzContent: NewGeoFencingModalComponent,
      nzWidth: 696,
      nzTitle: this.newGeoFencingTitle,
      nzClosable: true,
      nzCentered: true
    });

    modal.componentInstance?.geoFencingCreated.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(geoFencing => {
      this.list?.push(geoFencing);
      this.geoFencings.setValue([...this.geoFencings.value, geoFencing]);
    })
  }

  getFullAddress(geoFencing: GeoFencing): string {
    return this.addressService.concatAddress(geoFencing.address);
  }
}
