import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {FileService} from '../../../core/services/file.service';
import {finalize} from 'rxjs/operators';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  @Input() roundImage: boolean = false;
  @Input() image: FormControl;
  @Input() folder: string;
  isLoading: boolean;

  constructor(
      private fileService: FileService,
      private notificationService: NzNotificationService
  ) { }

  ngOnInit(): void {
  }

  uploadImage($event: any) {
    if ($event.target.files[0] !== undefined) {
      this.isLoading = true;
      const file: File = $event.target.files[0];
      const blob = file.slice(0, file.size, "image/jpg");
      const newFile = new File([blob], this.folder + ".jpg", { type: "image/jpg" });
      this.fileService
        .uploadImage(this.folder, newFile)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (img: any) => {
            this.image.setValue(img);
          },
          error => {
            this.notificationService.create(
              "error",
              "Error al subir la imagen",
              error.message.replace("GraphQL error:", "").trim(),
              {
                nzPlacement: 'topLeft'
              }
            )
          }
        );
    }
    }
}
