import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {EarningTable} from "../models/earning-table";
import {EarningConcept} from "../models/earning-concept";
import {Earning} from "../models/earning";

@Injectable({
  providedIn: 'root'
})
export class EarningsService {

  constructor(private apollo: Apollo) { }

  getEarnings(filters: any, defaultOnly: boolean | null = null): Observable<EarningTable | undefined> {
    return this.apollo.query<{earningTable: EarningTable}>({
      query: gql`
        query earningTable($substring: String $skip: Int $limit: Int $defaultOnly: Boolean) {
          earningTable(substring: $substring skip: $skip limit: $limit defaultOnly: $defaultOnly) {
            count
            earnings {
              uid
              name
              colour
              icon {
                uid
                url
              }
              conceptCode
              dataType
              isDefault
              worth
              imssAmount
              imssCategory
              isrAmount
              isrCategory
              conceptString
            }
          }
        }
      `,
      variables: {
        ...filters,
        defaultOnly
      }
    }).pipe(
      map(({data}) => {
        data.earningTable.earnings?.forEach(earning => {
          earning.editWorth = earning.worth;
        });
        return data.earningTable;
      })
    )
  }

  getConcepts(): Observable<EarningConcept[]> {
    return this.apollo.query<{ earningConcepts: EarningConcept[]}>({
      query: gql`
        query earningConcepts {
          earningConcepts {
            id
            description
          }
        }
      `,
    }).pipe(
      map(({data}) => {
        return data.earningConcepts;
      })
    )
  }

  createEarning(filters: any): Observable<Earning> {
    return this.apollo.mutate<{createEarning: {earning: Earning}}>({
      mutation: gql`
        mutation createEarning(
          $colour: String!
          $conceptCode: String!
          $worth: Float
          $iconUid: String!
          $imssAmount: Float
          $imssCategory: String
          $isrAmount: Float
          $isrCategory: String
          $name: String!
        ) {
          createEarning(
            colour: $colour
            conceptCode: $conceptCode
            worth: $worth
            iconUid: $iconUid
            imssAmount: $imssAmount
            imssCategory: $imssCategory
            isrAmount: $isrAmount
            isrCategory: $isrCategory
            name: $name
          ) {
            earning {
              uid
              name
              colour
              icon {
                uid
                url
              }
              conceptCode
              dataType
              isDefault
              worth
              imssAmount
              imssCategory
              isrAmount
              isrCategory
              conceptString
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        data.createEarning.earning.editWorth = data.createEarning.earning.worth;
        // @ts-ignore
        return data.createEarning.earning;
      })
    )
  }

  updateEarning(filters: any): Observable<Earning> {
    return this.apollo.mutate<{updateEarning: {earning: Earning}}>({
      mutation: gql`
        mutation updateEarning(
            $colour: String
            $conceptCode: String
            $worth: Float
            $earningUid: String!
            $iconUid: String
            $imssAmount: Float
            $imssCategory: String
            $isrAmount: Float
            $isrCategory: String
            $name: String
        ) {
          updateEarning(
            colour: $colour
            conceptCode: $conceptCode
            worth: $worth
            earningUid: $earningUid
            iconUid: $iconUid
            imssAmount: $imssAmount
            imssCategory: $imssCategory
            isrAmount: $isrAmount
            isrCategory: $isrCategory
            name: $name
          ) {
            earning {
              uid
              name
              colour
              icon {
                uid
                url
              }
              conceptCode
              dataType
              isDefault
              worth
              imssAmount
              imssCategory
              isrAmount
              isrCategory
              conceptString
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        data.updateEarning.earning.editWorth = data.updateEarning.earning.worth;
        // @ts-ignore
        return data.updateEarning.earning;
      })
    )
  }

  deleteEarning(earningUid: string | undefined): Observable<string> {
    return this.apollo.mutate<{deleteEarning: {message: string}}>({
      mutation: gql`
        mutation deleteEarning($earningUid: String!) {
          deleteEarning(earningUid: $earningUid) {
            message
          }
        }
      `,
      variables: {
        earningUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deleteEarning.message;
      })
    )
  }
}
