import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FestivityDay} from "../../../core/models/festivity-day";
import {FestivityDaysService} from "../../../core/services/festivity-days.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {EditFestivityDayModalComponent} from "../../modals/edit-festivity-day-modal/edit-festivity-day-modal.component";

@Component({
  selector: 'app-festivity-day-row',
  templateUrl: './festivity-day-row.component.html',
  styleUrls: ['./festivity-day-row.component.scss']
})
export class FestivityDayRowComponent implements OnInit, OnDestroy {
  @Input() festivityDay: FestivityDay | null;
  @Output() deleteFestivityDay = new EventEmitter<FestivityDay>();
  isDeleting = false;
  $onDestroy: Subject<void> = new Subject<void>();

  constructor(private festivityDaysService: FestivityDaysService,
              private notification: NzNotificationService,
              private modalService: NzModalService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  editFestivityDay() {
    const modal = this.modalService.create({
      nzContent: EditFestivityDayModalComponent,
      nzTitle: 'Editar día festivo',
      nzCentered: true,
      nzWidth: 504,
      nzFooter: null,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        festivityDay: this.festivityDay
      },
      nzOnCancel: () => new Promise((resolve, reject) => {
        // close modal
        resolve(undefined);
      })
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((festivityDay: FestivityDay | null | undefined) => {
      if (festivityDay !== undefined && festivityDay !== null) {
        this.festivityDay = festivityDay;
      }
    });
  }

  removeFestivityDay() {
    this.isDeleting = true;
    this.festivityDaysService.deleteFestivityDays(this.festivityDay?.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', message);
        // @ts-ignore
        this.deleteFestivityDay.emit(this.festivityDay);
      }, error => {
        this.notification.create('error', 'Error al eliminar día festivo', error.message.replace("GraphQL error:", "").trim());
      });
  }

}
