import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import {Country} from '../models/country';
import {CountryService} from '../services/country.service';

@Injectable({
  providedIn: 'root'
})
export class CountryStore {
  countries$: Subject<Country[] | undefined> = new ReplaySubject();
  countries: Country[] | undefined;

  isLoading$: BehaviorSubject<boolean>;
  isError$: BehaviorSubject<string | null>;

  constructor(
      private countryService: CountryService
  ) {
    if (!this.isLoading$) {
      this.isLoading$ = new BehaviorSubject(false) as BehaviorSubject<boolean>;
    }

    if (!this.isError$) {
      this.isError$ = new BehaviorSubject(null)  as BehaviorSubject<string | null>;
    }

    if (!this.countries) {
      this.subscribeToGetCountries();
    }
  }

  getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  getIsError(): Observable<string | null> {
    return this.isError$.asObservable();
  }

  private subscribeToGetCountries(): void {
    this.countryService
        .getCountries()
        .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
        )
        .subscribe(
            (countries: Country[] | undefined) => {
              this.countries = countries;
              this.countries$.next(this.countries);
            },
            (error: any) => {
              this.isError$.next(
                  error.message.replace("GraphQL error:", "").trim()
              );
            }
        );
  }

  getCountries(): Observable<Country[] | undefined> {
    return this.countries$.asObservable();
  }
}
