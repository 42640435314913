<div class="page-header" [style.margin-left]="marginLeft !== null ?( marginLeft + 'px') : '0'">
  <div class="wrapper-container">
    <div class="wrapper-box flex">
      <div class="blank-space mobile">

      </div>
      <div class="content stretch">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
      </div>
    </div>
  </div>
</div>
