import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PaymentService} from "../../../core/services/payment.service";
import {CompanyService} from "../../../core/services/company.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {finalize, takeUntil} from "rxjs/operators";
import {Company} from "../../../core/models/company";
import {PaymentMethod} from "../../../core/models/payment-method";
import {NewPaymentMethodComponent} from "../new-payment-method/new-payment-method.component";
import {Subject} from "rxjs";
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-add-credits-modal',
  templateUrl: './add-credits-modal.component.html',
  styleUrls: ['./add-credits-modal.component.scss']
})
export class AddCreditsModalComponent implements OnInit, OnDestroy {
  @Input() amount: number = 0;
  isLoading = false;
  creditsForm: FormGroup;
  isLoadingPaymentMethods = true;
  paymentMethods: PaymentMethod[] | null;
  error: string | null;
  $onDestroy: Subject<void> = new Subject<void>();
  formatter = (value: number) => `${this.currencyPipe.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");

  constructor(private paymentService: PaymentService,
              private companyService: CompanyService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private modalService: NzModalService,
              private currencyPipe: CurrencyPipe,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.getCompanyPaymentMethods();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  closeModal() {
    this.modalRef.close(null);
  }

  openNewPaymentMethod() {
    const modal = this.modalService.create({
      nzTitle: 'Nuevo método de pago',
      nzContent: NewPaymentMethodComponent,
      nzWidth: 730,
      nzFooter: null,
      nzWrapClassName: 'vertical-center-modal',
      nzOnCancel: () => new Promise((resolve, reject) => {
        // close modal
        resolve(undefined);
      })
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((paymentMethod: PaymentMethod | null | undefined) => {
      if (paymentMethod !== undefined && paymentMethod !== null) {
        this.paymentMethods?.push(paymentMethod);
        // @ts-ignore
        this.creditsForm.controls.paymentMethodUid.setValue(this.paymentMethods[this.paymentMethods?.length - 1]);
      } else {
        this.creditsForm.controls.paymentMethodUid.setValue(null);
      }
    });
  }

  addPayment() {
    if (this.creditsForm.valid) {
      this.isLoading = true;
      const data = this.creditsForm.getRawValue();
      if (data.paymentMethodUid.uid === 'payment link') {
        data.paymentMethodUid = null;
        data.usePaymentLink = true;
      } else {
        data.paymentMethodUid =  data.paymentMethodUid.uid;
      }
      this.paymentService.addCreditsToCompany(data)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((company: Company) => {
          if (data.paymentMethodUid.uid === 'payment link') {
            this.notification.create('success', '¡Operación exitosa!', 'Link de pago enviado por correo');
          } else {
            this.notification.create('success', '¡Operación exitosa!', 'Pago realizado correctamente');
          }
          this.modalRef.close(company);
        }, error => {
          this.notification.create('error', 'Error al realizar el pago', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.creditsForm);
    }
  }

  private getCompanyPaymentMethods() {
    this.isLoadingPaymentMethods = true;
    this.companyService.getCompanyPaymentMethods()
      .pipe(finalize(() => {
        this.isLoadingPaymentMethods = false;
      }))
      .subscribe((company: Company) => {
        this.paymentMethods = company.paymentMethods || [];
        this.createForm();
      }, error => {
        this.error = error.message.replace("GraphQL error:", "").trim();
        // this.notification.create('error', 'Error al obtener la información', error.message.replace("GraphQL error:", "").trim());
      });
  }

  private createForm() {
    this.creditsForm = this.formBuilder.group({
      amount: [this.amount, Validators.required],
      paymentMethodUid: [this.getDefault(), Validators.required],
      usePaymentLink: [false]
    });

    this.creditsForm.controls.paymentMethodUid.valueChanges.subscribe(value => {
      if (value && value.uid === 'new') {
        this.openNewPaymentMethod();
      }
    });
  }

  private getDefault() {
    const index = this.paymentMethods?.findIndex(p => p.default === true);
    if (index !== -1) {
      // @ts-ignore
      return this.paymentMethods[index];
    }
    return null;
  }
}
