import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbsenceRequest} from '../../../core/models/absence-request';
import {AbsenceRequestModalComponent} from '../../modals/absence-request-modal/absence-request-modal.component';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-absence-request-row',
  templateUrl: './absence-request-row.component.html',
  styleUrls: ['./absence-request-row.component.scss']
})
export class AbsenceRequestRowComponent implements OnInit, OnDestroy {
  @Input() absenceRequest: AbsenceRequest;
  $onDestroy: Subject<void> = new Subject<void>();

  @ViewChild('absenceModalTitle') absenceModalTitle: TemplateRef<any>;

  constructor(
      private drawerService: NzDrawerService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  openAbsenceRequest() {
    const modal = this.drawerService.create({
      nzContent: AbsenceRequestModalComponent,
      nzContentParams: {
        member: this.absenceRequest?.employee,
        absenceRequest: this.absenceRequest
      },
      nzTitle: this.absenceModalTitle,
      nzWidth: 504
    });

    modal.afterOpen.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.absenceRequestUpdated
          .pipe(takeUntil(this.$onDestroy))
          .subscribe((newAbsence: AbsenceRequest) => {
            this.absenceRequest = newAbsence;
          })
    })
  }

  onUpdated($event: AbsenceRequest) {
    this.absenceRequest = $event;
  }
}
