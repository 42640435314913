<div class="flex align-center">
  <div class="color-box" [ngStyle]="{
    backgroundColor: color.value,
    height: size + 'px',
    width: size + 'px',
    borderRadius: (size/2) + 'px',
    marginRight: (size/3) + 'px'
  }">
  </div>
  <div class="label">
    {{color.label | titlecase}}
  </div>
</div>
