import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbsenceType} from '../../../core/models/absence-type';
import {finalize, takeUntil} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd/modal';
import {EditAbsenceTypeComponent} from '../../modals/edit-absence-type/edit-absence-type.component';
import {Subject} from 'rxjs';
import {AbsenceTypeService} from '../../../core/services/absence-type.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-absence-type-row',
  templateUrl: './absence-type-row.component.html',
  styleUrls: ['./absence-type-row.component.scss']
})
export class AbsenceTypeRowComponent implements OnInit, OnDestroy {
  @Input() absenceType: AbsenceType;
  isLoading: boolean;

  $onDestroy: Subject<void> = new Subject<void>();

  @Output() absenceTypeDeleted: EventEmitter<AbsenceType> = new EventEmitter<AbsenceType>();

  constructor(
      private modalService: NzModalService,
      private absenceTypeService: AbsenceTypeService,
      private notificationService: NzNotificationService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  openEditAbsenceType() {
    const modal = this.modalService.create({
      nzContent: EditAbsenceTypeComponent,
      nzTitle: 'Editar tipo de ausencia',
      nzCentered: true,
      nzWidth: 504,
      nzComponentParams: {
        absenceType: this.absenceType
      }
    });

    modal.afterOpen.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.absenceTypeEdited
          .pipe(takeUntil(this.$onDestroy))
          .subscribe((newAbsenceType: AbsenceType) => {
            this.absenceType = newAbsenceType;
          })
    })
  }

  deleteAbsenceType() {
    this.isLoading = true;
    this.absenceTypeService.deleteAbsenceType(this.absenceType.uid).pipe(
        finalize(() => {
          this.isLoading = false;
        })
    ).subscribe(message => {
      this.absenceTypeDeleted.emit(this.absenceType);
      this.notificationService.success('Tipo de ausencia eliminado', 'El tipo de ausencia se ha eliminado con éxito');
    }, error => {
      this.notificationService.error('Error al eliminar tipo de ausencia', error);
    })
  }
}
