<div class="flex column pie-container">
  <nz-spin [nzSpinning]="isLoading">
    <div class="pie-graph">
      <div class="title">{{ title }}</div>

      <div class="pie-box">
        <canvas class="canvas" baseChart *ngIf="!isLoading"
                [options]="chartOptions"
                [colors]="pieChartColors"
                [data]="doughnutChartData"
                [labels]="doughnutChartLabels"
                [chartType]="doughnutChartType">
        </canvas>

        <div class="options-container">
          <div class="option-box" *ngFor="let label of doughnutChartLabels; let i = index;">
            <div class="color" [style.background-color]="pieChartColors[0].backgroundColor[i]"></div>
            <div class="name" [ngClass]="{'cross': doughnutChartData[i] === 0}" (click)="itemClick(i)">{{ label }}</div>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
</div>
