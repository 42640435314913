<div class="employer-registry-details-modal-component">
  <div class="info-container">
    <div class="title">Nombre del registro:</div>
    <div class="content">{{ employerRegistry?.businessName }}</div>
  </div>

  <div class="info-container">
    <div class="title">NPIE:</div>
    <div class="content">{{ employerRegistry?.NPIEId }}</div>
  </div>

  <div class="info-container">
    <div class="title">RFC:</div>
    <div class="content">{{ employerRegistry?.rfc }}</div>
  </div>

  <div class="info-container">
    <div class="title">Razón social:</div>
    <div class="content">{{ employerRegistry?.socialReason }}</div>
  </div>

  <div class="info-container">
    <div class="title">CLABE:</div>
    <div class="content">{{ employerRegistry?.clabe }}</div>
  </div>

  <div class="info-container">
    <div class="title">Categoría ({{ employerRegistry?.ssRisk?.category }}):</div>
    <div class="content">{{ employerRegistry?.riskPercentage }}%</div>
  </div>

  <div class="info-container">
    <div class="title">Prima vacacional:</div>
    <div class="content">{{ employerRegistry?.holidayBonusPercentage }}%</div>
  </div>

  <div class="info-container">
    <div class="title">Días de aguinaldo:</div>
    <div class="content">{{ employerRegistry?.holidayBonusDays }} día{{ employerRegistry?.holidayBonusDays === 1 ? '' : 's' }}</div>
  </div>

  <div class="info-container">
    <div class="title">ISN:</div>
    <div class="content">{{ employerRegistry?.ISNPercentage }}%</div>
  </div>

  <div class="info-container">
    <div class="title">Política de ausencias proporcionales:</div>
    <div class="content">{{ employerRegistry?.proportionalAbsencesPolicy ? 'Sí' : 'No' }}</div>
  </div>

  <div class="info-container" *ngIf="employerRegistry?.certificate !== null">
    <div class="title">Archivos:</div>
    <div class="content">
      .cer y .key
      <i *ngIf="isDeleting || isDownloading" class="download" nz-icon nzType="loading"></i>
      <i *ngIf="!isDeleting && !isDownloading" class="download" nz-icon nzType="download" nzTooltipColor="white" nz-tooltip="Descargar archivos .cer y .key" (click)="downloadFiles()"></i>
      <i *ngIf="!isDeleting && !isDownloading" class="delete" nz-icon nzType="delete" nzTheme="outline" nzTooltipColor="white" nz-tooltip="Eliminar archivos .cer y .key"
      nz-popconfirm="¿Seguro que deseas eliminar los archivos .cer y .key?" (nzOnConfirm)="deleteCertificates()"></i>
    </div>
  </div>
</div>

<div class="certificates-form" *ngIf="employerRegistry?.certificate === null && certificateForm">

  <div class="documents-container">
    <div class="document">
      <div class="doc-name">
        <i class="success" nz-icon nzType="check-circle" *ngIf="certificateForm.controls.cer_file.value !== null"></i>
        <div>{{ certificateForm.controls.cer_file.value !== null ? 'Archivo .cer' : 'Subir archivo .cer' }}</div>
      </div>
      <i nz-icon nzType="icons:dots" [nz-tooltip]="certificateForm.controls.cer_file.value !== null ? 'Cambiar archivo .cer' : 'Agregar archivo .cer'" nzTooltipColor="white" (click)="cerFile.click()"></i>
    </div>

    <div class="document">
      <div class="doc-name">
        <i class="success" nz-icon nzType="check-circle" *ngIf="certificateForm.controls.key_file.value !== null"></i>
        <div>{{ certificateForm.controls.key_file.value !== null ? 'Archivo .key' : 'Subir archivo .key' }}</div>
      </div>
      <i nz-icon nzType="icons:dots" [nz-tooltip]="certificateForm.controls.key_file.value !== null ? 'Cambiar archivo .key' : 'Agregar archivo .key'" nzTooltipColor="white" (click)="keyFile.click()"></i>
    </div>
  </div>


  <input style="display: none;" type="file" #cerFile (change)="cerFileChange($event)" accept=".cer">
  <input style="display: none;" type="file" #keyFile (change)="keyFileChange($event)" accept=".key">


  <form nz-form nzLayout="vertical" [formGroup]="certificateForm">
    <nz-form-item>
      <nz-form-label>Contraseña</nz-form-label>
      <nz-form-control [nzErrorTip]="passphraseErrorTpl">

        <input style="border-radius: 8px;" placeholder="Contraseña de los archivos del SAT" nz-input formControlName="passphrase" type="password">

        <ng-template #passphraseErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Por favor ingresa tu clave para desencriptar los archivos
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="btns-box">
    <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="uploadCertificates()">Guardar</button>
  </div>
</div>
