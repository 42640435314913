import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {Icon} from '../models/icon';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {MimeType} from '../models/mime-type';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  fileTypes: MimeType[] = [
    {
      ext: '.png',
      icon: 'png'
    },
    {
      ext: '.jpg',
      icon: 'jpg'
    },
    {
      ext: '.jpeg',
      icon: 'jpg'
    },
    {
      ext: '.pdf',
      icon: 'pdf'
    },
    {
      ext: '.pptx',
      icon: 'ppt'
    },
    {
      ext: '.ppt',
      icon: 'ppt'
    },
    {
      ext: '.xls',
      icon: 'xls'
    },
    {
      ext: '.xlsx',
      icon: 'xls'
    },
    {
      ext: '.cvs',
      icon: 'xls'
    },
    {
      ext: '.doc',
      icon: 'doc'
    },
    {
      ext: '.docx',
      icon: 'doc'
    }
  ];

  constructor(
      private apollo: Apollo
  ) { }

  getMime(extension: string | undefined): string | undefined {
    const found = this.fileTypes.find(el => el.ext == extension);
    return found ? found.icon : 'none';
  }

  getIcons(category: string | null = 'icon'): Observable<Icon[] | undefined> {
    return this.apollo.query<{icons: Icon[]}>({
      query: gql`
          query icons (
              $category: String!
          ) {
              icons(
                  category: $category
              ) {
                  uid
                  url
                  name
              }
          }
      `,
      variables: {
        category
      }
    }).pipe(
        map(({data}) => {
          return data.icons;
        })
    )
  }
}
