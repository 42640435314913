import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {MeetingsTable} from "../models/meetings-table";
import {DatePipe} from "@angular/common";
import {Meeting} from "../models/meeting";

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {

  constructor(private apollo: Apollo,
              private datePipe: DatePipe) { }

  getMeetings(filters: any) {
    const copyFilters = {...filters};
    if (copyFilters.startDate) {
      copyFilters.startDate = this.datePipe.transform(copyFilters.startDate, 'yyyy-MM-dd');
    }
    if (copyFilters.endDate) {
      copyFilters.endDate = this.datePipe.transform(copyFilters.endDate, 'yyyy-MM-dd');
    }
    return this.apollo.query<{meetingTable: MeetingsTable}>({
      query : gql`
        query meetingTable(
            $teamUid: String
            $startDate: String!
            $endDate: String!
            $substring: String
            $historical: Boolean
            $eventType: String
            $employeeUid: String
            $skip: Int!
            $limit: Int!
        ) {
        meetingTable(
            teamUid: $teamUid
            startDate: $startDate
            endDate: $endDate
            substring: $substring
            historical: $historical
            eventType: $eventType
            employeeUid: $employeeUid
            skip: $skip
            limit: $limit
          ) {
            count
            meetings {
              uid
              fromGoogleCalendar
              name
              participants {
                uid
                firstName
                lastName
                photo {
                  url
                }
              }
              startDate
              startHour
              checkinIncident
              hangoutLink
              place {
                latitude
                longitude
                rangeMeters
                address {
                  city
                  colony
                  country
                  exteriorNumber
                  interiorNumber
                  latitude
                  longitude
                  state
                  street
                  zipcode
                  fullAddress
                }
              }
            }
          }
        }
      `,
      variables: {
        ...copyFilters
      }
    }).pipe(
      map(({data}) => {
        return data?.meetingTable;
      })
    )
  }

  getMeeting(meetingUid: string | undefined) {
    return this.apollo.query<{meeting: Meeting}>({
      query : gql`
        query meeting($meetingUid: String!) {
          meeting(meetingUid: $meetingUid) {
            uid
            fromGoogleCalendar
            name
            participants {
              uid
              firstName
              lastName
              photo {
                url
              }
              email
              meetingAttendance(meetingUid: $meetingUid) {
                latitude
                longitude
                onLocation
                onTime
                timestamp
              }
            }
            startDate
            startHour
            checkinIncident
            hangoutLink
            place {
              latitude
              longitude
              rangeMeters
              address {
                city
                colony
                country
                exteriorNumber
                interiorNumber
                latitude
                longitude
                state
                street
                zipcode
                fullAddress
              }
            }
          }
        }
      `,
      variables: {
        meetingUid
      }
    }).pipe(
      map(({data}) => {
        return data?.meeting;
      })
    )
  }

  deleteMeeting(meetingUid: string | undefined) {
    return this.apollo.mutate<{deleteMeetingAdmin: {message: string}}>({
      mutation : gql`
        mutation deleteMeetingAdmin($meetingUid: String!) {
          deleteMeetingAdmin(meetingUid: $meetingUid) {
            message
          }
        }
      `,
      variables: {
        meetingUid
      }
    }).pipe(
      map(({data}) => {
        return data?.deleteMeetingAdmin.message;
      })
    )
  }

  createMeeting(form: any) {
    return this.apollo.mutate<{createMeetingAdmin: {meeting: Meeting}}>({
      mutation : gql`
        mutation createMeetingAdmin(
          $addressData: AddressInput
          $name: String!
          $participantsUid: [String]!
          $startDate: Date!
          $startHour: String!
        ) {
          createMeetingAdmin(
            addressData: $addressData
            name: $name
            participantsUid: $participantsUid
            startDate: $startDate
            startHour: $startHour
          ) {
            meeting {
              uid
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        return data?.createMeetingAdmin.meeting;
      })
    )
  }

  editMeeting(form: any) {
    return this.apollo.mutate<{updateMeetingAdmin: {meeting: Meeting}}>({
      mutation : gql`
        mutation updateMeetingAdmin(
          $addressData: AddressInput
          $name: String
          $participantsUid: [String]
          $startDate: Date
          $startHour: String
          $meetingUid: String!
        ) {
          updateMeetingAdmin(
            addressData: $addressData
            name: $name
            participantsUid: $participantsUid
            startDate: $startDate
            startHour: $startHour
            meetingUid: $meetingUid
          ) {
            meeting {
              uid
              fromGoogleCalendar
              name
              participants {
                uid
                firstName
                lastName
                photo {
                  url
                }
              }
              startDate
              startHour
              checkinIncident
              hangoutLink
              place {
                latitude
                longitude
                rangeMeters
                address {
                  city
                  colony
                  country
                  exteriorNumber
                  interiorNumber
                  latitude
                  longitude
                  state
                  street
                  zipcode
                }
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        return data?.updateMeetingAdmin.meeting;
      })
    )
  }
}
