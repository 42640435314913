<div class="home-geo-locations-card" [ngClass]="showMore ? 'active' : 'close'">
  <div class="topo-container">
    <div class="title-container" (click)="showMore = !showMore">
      <i nz-icon nzType="down" *ngIf="!showMore"></i>
      <i nz-icon nzType="up" *ngIf="showMore"></i>
      <div class="title">Última ubicación</div>
    </div>

    <div class="filters-container" *ngIf="selectedLocationActive">
      <div class="clean" (click)="cleanSelected()">Limpiar grupo</div>
    </div>
  </div>

  <div class="location-container">
    <ng-container>
      <div class="location-box" [ngClass]="{'location-active': team?.selected}"
           *ngFor="let team of teamLocations; let i = index;" (click)="locationClick(i, team.selected)">
       <div class="team-info-container flex align-center between">
         <div class="flex align-center">
           <div class="team-icon flex align-center center">
             <app-team-icon [size]="28" [icon]="team?.icon?.url"  [color]="team?.color"></app-team-icon>
           </div>
           <div class="team-name">
             <div>{{ team.name }} ({{ team?.members?.length }})</div>
           </div>
         </div>
         <div class="clean" *ngIf="team?.selectedMember" (click)="cleanTeamSelected(i)">Limpiar filtro</div>
       </div>

        <div class="location-info-box" [ngClass]="{'active-team': team?.selected, 'close-team': !team?.selected}">
          <div class="member-row"
               *ngFor="let member of team?.members"
               (click)="setSelectedMember(member, i, true)"
               [ngClass]="{'member-row-active': member?.selected}"
               (mouseover)="setSelectedMember(member, i, false)"
               (mouseout)="team?.selectedMember ? returnSelected(i) : cleanTeamSelected(i)">
            <div>
              <nz-avatar [nzGap]="6" [nzSize]="30" [nzText]="(member?.firstName?.charAt(0) + '' + member?.lastName?.charAt(0))" [nzSrc]="member?.photo?.url"></nz-avatar>
            </div>

            <div class="box-container">
              <div class="profile-info-box">
                <div class="name">{{ member?.firstName }} {{ member?.lastName }}</div>
                <div class="email">{{ member?.email }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>
