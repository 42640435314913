<div class="slack-row flex align-center between wrap">
  <div class="name-container">
    <div class="img-box">
      <img [src]="'../assets/images/slack-purple.png'" alt="slack-logo">
    </div>

    <div class="name">Slack</div>
  </div>

  <div class="description-box" *ngIf="!company?.slackActive">
    <div class="text">Integra slack con tu cuenta de Nommi y podrás automatizar notificaciones en tus canales de equipos de trabajo cada anuncio que se crea desde la app.</div>
  </div>

  <div class="buttons-container">
    <button *ngIf="!company?.slackActive" nz-button nzType="primary" (click)="goToSlack()">Integrar</button>
    <!--<button *ngIf="company?.slackActive"
            class="default" nz-button nzType="default"
            [nzLoading]="isRemoving"
            nz-popconfirm="¿Seguro que deseas quitar esta integración?"
            (nzOnConfirm)="removeSlack()">
      Quitar integración
    </button>-->

    <div class="tag flex align-center" *ngIf="company?.slackActive">
      <div class="background"></div>
      Integrado
    </div>
  </div>
</div>
