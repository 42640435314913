import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EarningConcept} from "../../../core/models/earning-concept";
import {Earning} from "../../../core/models/earning";
import {Subject} from "rxjs";
import {EarningsService} from "../../../core/services/earnings.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {finalize, takeUntil} from "rxjs/operators";
import {EditEarningModalComponent} from "../../modals/edit-earning-modal/edit-earning-modal.component";

@Component({
  selector: 'app-earning-row',
  templateUrl: './earning-row.component.html',
  styleUrls: ['./earning-row.component.scss']
})
export class EarningRowComponent implements OnInit, OnDestroy {
  @Input() earningConcepts: EarningConcept[];
  @Input() earning: Earning | null;
  @Output() removeEarning = new EventEmitter<void>();
  isDeleting = false;
  $onDestroy: Subject<void> = new Subject<void>();

  constructor(private earningsService: EarningsService,
              private notification: NzNotificationService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  deletePerception() {
    this.isDeleting = true;
    this.earningsService.deleteEarning(this.earning?.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', 'Percepción eliminada correctamente');
        this.removeEarning.emit();
      }, error => {
        this.notification.create('error', 'Error al eliminar la percerpción', error.message.replace("GraphQL error:", "").trim());
      });
  }

  editEarning() {
    const modal = this.drawerService.create({
      nzContent: EditEarningModalComponent,
      nzTitle: 'Editar percepción',
      nzWidth: 504,
      nzContentParams: {
        earning: this.earning,
        earningConcepts: this.earningConcepts
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((earning: Earning) => {
      if (earning) {
        this.earning = earning;
      }
    })
  }

}
