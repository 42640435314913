import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../core/models/user';
import {Router} from '@angular/router';

@Component({
  selector: 'app-today-member-box',
  templateUrl: './today-member-box.component.html',
  styleUrls: ['./today-member-box.component.scss']
})
export class TodayMemberBoxComponent implements OnInit {
  @Input() member: User;
  @Input() showTeam: boolean = false;

  // TODO Implement optional team box

  constructor(
      private router: Router
  ) { }

  ngOnInit(): void {}

  goToPending() {
    this.router.navigate(['/members/' + this.member.uid + '/details']).then();
  }

  goToSchedule() {
    this.router.navigate(['/members/' + this.member.uid + '/schedule']).then();
  }
}
