import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company} from "../../../core/models/company";
import {CompanyService} from "../../../core/services/company.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {CreditCardValidators} from "angular-cc-library";
import {finalize} from "rxjs/operators";
import {PaymentMethod} from "../../../core/models/payment-method";

@Component({
  selector: 'app-new-payment-method',
  templateUrl: './new-payment-method.component.html',
  styleUrls: ['./new-payment-method.component.scss']
})
export class NewPaymentMethodComponent implements OnInit {
  @Input()  company: Company | null;
  isLoading = false;
  error: string | null;
  newPaymentMethodForm: FormGroup;
  paymentMethod: any = {
    type: null,
    cardNumber: null,
    holderName: null,
    cvv: null,
    expirationDate: null
  };
  cardImage = 'tc-card-c';

  constructor(private companyService: CompanyService,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  close() {
    this.modalRef.close(null);
  }

  createPaymentMethod(): void {
    if (this.newPaymentMethodForm.valid) {
      this.error = null;
      // this.isLoading = true;
      const data = this.newPaymentMethodForm.getRawValue();
      const params = {
        card: {
          number: data.cardNumber,
          name: data.holderName,
          exp_year: data.expirationDate.split(' ')[2].toString(),
          exp_month: data.expirationDate.split(' ')[0].toString(),
          cvc: data.cvv
        }
      };
      Conekta.Token.create(params, (res: any) => {
          this.isLoading = true;
          this.error = null;
          this.companyService.addPaymentMethod(res.id)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe((paymentMethod: PaymentMethod) => {
              this.notification.create(
                "success",
                "¡Operación exitosa!",
                'El método fue agregado correctamente'
              );
              this.modalRef.close(paymentMethod);
            }, error => {
              this.error = error.message.replace('GraphQL error: ', '').trim();
              /*this.notification.create(
                "error",
                "Error al agregar método de pago",
                error.message.replace('GraphQL error: ', '').trim()
              );*/
            });
        },
        (error: any) => {
          this.isLoading = false;
          this.error = 'Error al generar token';
        }
      );
    } else {
      this.formHelper.markFormAsDirty(this.newPaymentMethodForm);
    }
  }

  updatePaymentMethod(): void {
    const data = this.newPaymentMethodForm.getRawValue();
    const cn = data.cardNumber.split(' ');
    this.paymentMethod = {
      type: this.getType(data.cardNumber),
      cardNumber: cn.length > 3 ? cn[3] : null,
      holderName: data.holderName,
      cvv: data.cvv,
      expirationDate: data.expirationDate
    };
  }

  private createForm(): void {
    this.newPaymentMethodForm = this.formBuilder.group({
      cardNumber: ['', [CreditCardValidators.validateCCNumber, Validators.required]],
      holderName: ['', [Validators.required]],
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      expirationDate: ['', [CreditCardValidators.validateExpDate, Validators.required]]
    });

    this.newPaymentMethodForm.valueChanges.subscribe(value => {
      if (value) {
        this.updatePaymentMethod();
      }
    });
  }

  private getType(key: string): string {
    const firstDigit = key[0];
    switch (firstDigit) {
      case '4':
        this.cardImage = 'visa-c';
        return 'visa';
      case '5':
        this.cardImage = 'mastercard-c';
        return 'mastercard';
      case '3':
        this.cardImage = 'american_express-c';
        return 'american_express';
      default:
        this.cardImage = 'tc-card-c';
        return 'tc-card';
    }
  }

}
