import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company} from "../../../core/models/company";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../../../core/services/company.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-new-document-type',
  templateUrl: './new-document-type.component.html',
  styleUrls: ['./new-document-type.component.scss']
})
export class NewDocumentTypeComponent implements OnInit {
  @Input()  company: Company | null;
  @Output() companyEdited: EventEmitter<Company> = new EventEmitter();
  isLoading = false;
  error: string | null;
  documentsForm: FormGroup;

  constructor(private companyService: CompanyService,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  close() {
    this.modalRef.close();
  }

  private createForm() {
    this.documentsForm = this.formBuilder.group({
      document: [null, Validators.required]
    });
  }

  createDocument() {
    if (this.documentsForm.valid) {
      this.isLoading = true;
      const newDocument = this.documentsForm.getRawValue().document;
      // @ts-ignore
      const data = [...this.company?.documentsCategories];
      data.push(newDocument);
      this.companyService.updateCompanyDocuments(data)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((company: Company) => {
          this.notification.create('success', '¡Operación exitosa!', 'Documento creado correctamente');
          // @ts-ignore
          this.companyEdited.emit(company);
          this.modalRef.close();
        }, error => {
          this.notification.create('error', 'Error al agregar documento', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.documentsForm);
    }
  }
}
