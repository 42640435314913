<div class="turn-box flex align-center">

  <ng-container *ngIf="turn?.checkinAtt">
    <div (click)="openAttendance(turn?.checkinAtt)" class="time-box active" [ngClass]="{
    'green': (turn?.checkinAtt?.onTime && turn?.checkinAtt?.onLocation),
    'yellow': (!turn?.checkinAtt?.onTime || !turn?.checkinAtt?.onLocation),
    'red': (!turn?.checkinAtt?.onTime && !turn?.checkinAtt?.onLocation)
    }"
         nzTooltipColor="white" [nz-tooltip]="getCheckinLabel()"
    >
      <span>{{(turn.checkinAtt?.correctTimestamp | date: 'HH:mm') || turn?.fromHour}}</span>

      <div class="icon-row flex end" *ngIf="(!turn?.checkinAtt?.onTime || !turn?.checkinAtt?.onLocation)">
        <div class="icon" *ngIf="!turn?.checkinAtt?.onLocation && !turn.checkinAtt?.onTime">
          <i nz-icon nzType="exclamation-circle" nzTheme="fill" class="yellow-s"></i>
        </div>
        <div class="icon" *ngIf="!turn?.checkinAtt?.onTime && turn?.checkinAtt?.onLocation">
          <div class="img-box">
            <img src="../assets/images/clock-y.png">
          </div>
        </div>
        <div class="icon" *ngIf="!turn?.checkinAtt?.onLocation && turn?.checkinAtt?.onTime">
          <i nz-icon nzType="icons:location-pin" class="yellow-s"></i>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!turn?.checkinAtt">
    <div class="time-box" nzTooltipColor="white" [ngClass]="{'red': isPastHourFrom()}" [nz-tooltip]="isPastHourFrom() ? 'Check in no realizado' : 'Check in pendiente'">
      {{(turn.checkoutAtt?.correctTimestamp | date: 'HH:mm') || turn?.fromHour}}

      <div class="icon-row flex end" *ngIf="isPastHourFrom()">
        <div class="icon">
          <i nz-icon nzType="exclamation-circle" nzTheme="fill" class="red-s"></i>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="separator">
    -
  </div>

  <ng-container *ngIf="turn?.checkoutAtt">
    <div (click)="openAttendance(turn?.checkoutAtt)" class="time-box active" [ngClass]="{
    'green': (turn?.checkoutAtt?.onTime && turn?.checkoutAtt?.onLocation),
    'yellow': (!turn?.checkoutAtt?.onTime || !turn?.checkoutAtt?.onLocation),
    'red': (!turn?.checkoutAtt?.onTime && !turn?.checkoutAtt?.onLocation)
    }"
         nzTooltipColor="white" [nz-tooltip]="getCheckoutLabel()"
    >
      <span>{{(turn?.checkoutAtt?.correctTimestamp | date: 'HH:mm') || turn?.toHour}}</span>

      <div class="icon-row flex end" *ngIf="(!turn?.checkoutAtt?.onTime || !turn?.checkoutAtt?.onLocation)">
        <div class="icon" *ngIf="!turn?.checkoutAtt?.onLocation && !turn.checkoutAtt?.onTime">
          <i nz-icon nzType="exclamation-circle" nzTheme="fill" class="yellow-s"></i>
        </div>
        <div class="icon" *ngIf="!turn?.checkoutAtt?.onTime && turn?.checkoutAtt?.onLocation">
          <div class="img-box">
            <img src="../assets/images/clock-y.png">
          </div>
        </div>
        <div class="icon" *ngIf="!turn?.checkoutAtt?.onLocation && turn?.checkoutAtt?.onTime">
          <i nz-icon nzType="icons:location-pin" class="yellow-s"></i>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!turn?.checkoutAtt">
    <div class="time-box" nzTooltipColor="white" [ngClass]="{'red': isPastHourTo()}" [nz-tooltip]="isPastHourTo() ? 'Check out no realizado' : 'Check out pendiente'">
      {{(turn?.checkoutAtt?.correctTimestamp | date: 'HH:mm') || turn?.toHour}}

      <div class="icon-row flex end" *ngIf="isPastHourTo()">
        <div class="icon">
          <i nz-icon nzType="exclamation-circle" nzTheme="fill" class="red-s"></i>
        </div>
      </div>
    </div>
  </ng-container>
</div>
