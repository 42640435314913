
<form (submit)="submitForm()"
      [formGroup]="newGeoFencing"
      nz-form nzLayout="vertical">

  <div class="flex column">

    <div class="error-box" *ngIf="addressError && !addressLoading">
      <nz-alert
            nzType="error"
            [nzMessage]="addressError"
            nzShowIcon
      ></nz-alert>
    </div>

    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
            nzType="error"
            [nzMessage]="error"
            nzShowIcon
      ></nz-alert>
    </div>

    <nz-spin [nzSpinning]="addressLoading" class="map">
      <div class="map-error-box flex center align-center" *ngIf="!marker">
        <div class="text">
          Ingrese la dirección de la geocerca para poder ajustar la ubicación exacta en el mapa.
        </div>
      </div>

      <app-geo-fencing-map [meters]="newGeoFencing.controls.rangeMeters.value" (markerChange)="onMarkerChange($event)"
                           [marker]="marker"></app-geo-fencing-map>
    </nz-spin>

    <div class="flex wrap form-row">
      <nz-form-item>
        <nz-form-label nzFor="name">Nombre de geocerca</nz-form-label>
        <nz-form-control [nzErrorTip]="nameErrorTpl">


          <input nz-input
                 placeholder="Nombra la geocerca"
                 formControlName="name" type="text" id="name">

          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              Ingresa el nombre de la geocerca
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="rangeMeters">Área de geocerca</nz-form-label>
        <nz-form-control [nzErrorTip]="rangeMetersErrorTpl">

          <nz-input-group nzAddOnAfter="metros">
            <nz-input-number
                  style="width: 100%"
                  [nzPlaceHolder]="'Ingresa los metros de tolerancia'"
                  formControlName="rangeMeters"
                  [nzMin]="50"
                  [nzMax]="5000"
                  [nzStep]="10"></nz-input-number>
          </nz-input-group>

          <ng-template #rangeMetersErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              Ingresa el area de la geocerca
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <form formGroupName="addressData">
        <nz-form-item>
          <nz-form-label nzFor="street">Calle</nz-form-label>
          <nz-form-control [nzErrorTip]="streetErrorTpl">

            <input nz-input
                   placeholder="Calle"
                   formControlName="street"
                   type="text"
                   id="street">

            <ng-template #streetErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa la calle de la dirección
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <form formGroupName="addressData" class="flex inner-form-row">
        <nz-form-item>
          <nz-form-label nzFor="exteriorNumber">No. Exterior</nz-form-label>
          <nz-form-control [nzErrorTip]="exteriorNumberErrorTpl">

            <input nz-input placeholder="No. Exterior"
                   formControlName="exteriorNumber"
                   type="text" id="exteriorNumber">

            <ng-template #exteriorNumberErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa el número exterior
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="interiorNumber">No. Interior</nz-form-label>
          <nz-form-control [nzErrorTip]="interiorNumberErrorTpl">

            <input nz-input placeholder="No. Interior"
                   formControlName="interiorNumber"
                   type="text" id="interiorNumber">

            <ng-template #interiorNumberErrorTpl let-control>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <form formGroupName="addressData">
        <nz-form-item>
          <nz-form-label nzFor="zipcode">Código postal</nz-form-label>
          <nz-form-control [nzErrorTip]="zipcodeErrorTpl">

            <nz-input-group [nzSuffix]="suffixTemplateInfo">
              <input nz-input
                     placeholder="Código postal"
                     formControlName="zipcode" type="text" id="zipcode">
            </nz-input-group>

            <ng-template #suffixTemplateInfo>
              <ng-container *ngIf="zipcodeLoading">
                <nz-spin nzSize="small"></nz-spin>
              </ng-container>
              <ng-container *ngIf="zipcodeError">
                <i nz-icon
                   nz-tooltip
                   nzTooltipTitle="No se encontró el código postal"
                   [nzTooltipColor]="'white'"
                   style="color: #faad14"
                   nzType="info-circle"></i>
              </ng-container>
            </ng-template>

            <ng-template #zipcodeErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa el código postal
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <form formGroupName="addressData">
        <nz-form-item>
          <nz-form-label nzFor="colony">Colonia</nz-form-label>
          <nz-form-control [nzErrorTip]="colonyErrorTpl">

            <nz-select nzShowSearch id="colony" nzPlaceHolder="Colonia" formControlName="colony">
              <nz-option *ngFor="let colony of colonies" nzCustomContent [nzLabel]="colony" [nzValue]="colony">
                {{colony}}
              </nz-option>
            </nz-select>

            <ng-template #colonyErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Seleccione la colonia
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <form formGroupName="addressData">
        <nz-form-item>
          <nz-form-label nzFor="city">Ciudad</nz-form-label>
          <nz-form-control [nzErrorTip]="cityErrorTpl">

            <input nz-input placeholder="Ciudad"
                   formControlName="city" type="text" id="city">

            <ng-template #cityErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa la ciudad
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <form formGroupName="addressData">
        <nz-form-item>
          <nz-form-label nzFor="zipcode">Estado</nz-form-label>
          <nz-form-control [nzErrorTip]="stateErrorTpl">

            <input nz-input
                   placeholder="Estado"
                   formControlName="state"
                   type="text" id="state">

            <ng-template #stateErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa el estado
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <form formGroupName="addressData">
        <nz-form-item>
          <nz-form-label nzFor="country">País</nz-form-label>
          <nz-form-control [nzErrorTip]="countryErrorTpl">

            <nz-select nzShowSearch id="country" nzPlaceHolder="País" formControlName="country">
              <nz-option *ngFor="let country of (countries$ | async)" nzCustomContent [nzLabel]="country!.countryName" [nzValue]="country.countryCode">
                {{country.countryName}}
              </nz-option>
            </nz-select>

            <ng-template #countryErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Seleccione el país
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <nz-form-item class="checkbox">
        <label nz-checkbox formControlName="remoteExclusive">
          Exclusivamente para remoto
        </label>
      </nz-form-item>

    </div>
  </div>

  <button style="display:none;" type="submit" #submit>Guardar</button>

  <div *nzModalFooter>
    <button class="not-border"
            nz-button
            nzType="default"
            type="button"
            (click)="destroyModal()">Cancelar</button>
    <button class="not-border"
            nz-button
            [nzLoading]="isLoading"
            nzType="primary"
            (click)="submit.click()" type="button">Guardar</button>
  </div>
</form>
