<form nz-form nzLayout="vertical"
      (submit)="submitForm()"
      [formGroup]="newAbsenceType">

  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
          nzType="error"
          [nzMessage]="error"
          nzShowIcon
    ></nz-alert>
  </div>

  <nz-form-item>
    <nz-form-label nzFor="name">Nombre del tipo de asueto </nz-form-label>
    <nz-form-control [nzErrorTip]="nameErrorTpl">

      <input nz-input placeholder="Nombra el tipo de asueto" formControlName="name" type="text" id="name">

      <ng-template #nameErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">
          Ingresa el nombre del tipo de asueto
        </ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="color">Seleccionar color</nz-form-label>
    <nz-form-control [nzErrorTip]="colorErrorTpl">
      <nz-select [nzCustomTemplate]="colorTemplate" id="color" nzPlaceHolder="Color" formControlName="colour">
        <nz-option *ngFor="let color of colors" nzCustomContent [nzLabel]="color!.label" [nzValue]="color!.value">
          <app-color-box [color]="color"></app-color-box>
        </nz-option>
      </nz-select>

      <ng-template #colorTemplate let-selected>
        <app-color-box [size]="20" [color]="{
              label: selected.nzLabel,
              value: selected.nzValue
            }"></app-color-box>
      </ng-template>

      <ng-template #colorErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">
          Escoge un color
        </ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <div class="flex wrap">
    <nz-form-item class="checkbox">
      <label nz-checkbox formControlName="paid">
        Pagadas
      </label>
    </nz-form-item>

    <nz-form-item class="checkbox">
      <label nz-checkbox formControlName="vacation">
        Resta dias de vacaciones
      </label>
    </nz-form-item>
  </div>

  <button style="display:none;" type="submit" #submit>Guardar</button>

  <div *nzModalFooter>
    <button class="not-border"
            nz-button
            nzType="default"
            type="button"
            (click)="destroyModal()">Cancelar</button>
    <button class="not-border"
            nz-button
            [nzLoading]="isLoading"
            nzType="primary"
            (click)="submit.click()" type="button">Guardar</button>
  </div>
</form>
