import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor() { }

  formatDate(date: any): string {
    const correctDate = new Date(date);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    let d = new Date(correctDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  cleanDate(date: any) {
    return date.split('-').join('/');
  }
}
