import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Position} from "../../../core/models/position";
import {PositionService} from "../../../core/services/position.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {CurrencyPipe} from "@angular/common";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-new-position-modal',
  templateUrl: './new-position-modal.component.html',
  styleUrls: ['./new-position-modal.component.scss']
})
export class NewPositionModalComponent implements OnInit {
  @Output() newPosition: EventEmitter<Position> = new EventEmitter();
  newPositionForm: FormGroup;
  isLoading = false;
  hours = [5, 7, 7.5, 8];
  formatter = (value: number) => `${this.currencyPipe.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");
  formatterMin = (value: number) => `${value + ' min'}`;
  parserMin = (value: string) => value.replace("min", "").replace(" ", "");

  constructor( private positionService: PositionService,
               private formHelper: FormHelperService,
               private formBuilder: FormBuilder,
               private notification: NzNotificationService,
               private currencyPipe: CurrencyPipe,
               private nzDrawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  closeModal() {
    this.nzDrawerRef.close();
  }

  getFormGroup(): FormGroup {
    return this.newPositionForm.controls.scheduleData as FormGroup;
  }

  getDayFormGroup(day: string): FormGroup {
    return this.getFormGroup().get(day) as FormGroup;
  }

  getFormArray(day: string): FormArray {
    return (this.getFormGroup().get(day) as FormGroup).get('turnsData') as FormArray;
  }

  addTurn(day: string) {
    this.getFormArray(day).push(
      this.formBuilder.group({
        fromHour: [null, Validators.required],
        toHour: [null, Validators.required]
      })
    );
  }

  removeTurn(day: string, index: number) {
    this.getFormArray(day).removeAt(index);
  }

  cleanData(form: any) {
    const data = form;
    data.scheduleData.monday = this.cleanDay(data, 'monday');
    data.scheduleData.tuesday = this.cleanDay(data, 'tuesday');
    data.scheduleData.wednesday = this.cleanDay(data, 'wednesday');
    data.scheduleData.thursday = this.cleanDay(data, 'thursday');
    data.scheduleData.friday = this.cleanDay(data, 'friday');
    data.scheduleData.saturday = this.cleanDay(data, 'saturday');
    data.scheduleData.sunday = this.cleanDay(data, 'sunday');
    data.salaryData = {
      hoursWorked: data.hoursWorked,
      imssPaidAmount: data.imssPaidAmount,
      paidAmount: data.paidAmount
    };
    delete data.hoursWorked;
    delete data.imssPaidAmount;
    delete data.paidAmount;
    return data;
  }

  cleanDay(data: any, day: string) {
    if (data.scheduleData[day].turnsData.length === 0) {
      data.scheduleData[day].active = false;
    } else {
      data.scheduleData[day].turnsData.forEach((turn: any) => {
        turn.fromHour = turn.fromHour.hour;
        turn.toHour = turn.toHour.hour;
      });
    }
    return data.scheduleData[day];
  }

  createPosition() {
    if (this.newPositionForm.valid) {
      this.isLoading = true;
      this.positionService.createNewPositions(this.cleanData(this.newPositionForm.getRawValue()))
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((position: Position | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Puesto creado correctamente');
          // @ts-ignore
          this.newPosition.emit(position);
          this.nzDrawerRef.close(position);
        }, error => {
          this.notification.create('error', 'Error al crear puesto', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      // general group data
      this.formHelper.markFormAsDirty(this.newPositionForm);
      // general day group data
      this.formHelper.markFormAsDirty(this.getDayFormGroup('monday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('tuesday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('wednesday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('thursday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('friday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('saturday'));
      this.formHelper.markFormAsDirty(this.getDayFormGroup('sunday'));
      // day form group array data
      this.formHelper.markFormArrayAsDirty(this.getFormArray('monday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('tuesday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('wednesday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('thursday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('friday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('saturday'));
      this.formHelper.markFormArrayAsDirty(this.getFormArray('sunday'));
    }
  }

  private createForm() {
    this.newPositionForm = this.formBuilder.group({
      name: [null, Validators.required],
      paidAmount: [0, [Validators.required, Validators.min(1)]],
      hoursWorked: [this.hours[this.hours.length - 1], Validators.required],
      imssPaidAmount:[0, Validators.required],
      toleranceMinutes: [0, [Validators.required]],
      scheduleData: this.formBuilder.group({
        monday: this.setFormDefault(),
        tuesday: this.setFormDefault(),
        wednesday: this.setFormDefault(),
        thursday: this.setFormDefault(),
        friday: this.setFormDefault(),
        saturday: this.setFormDefault(),
        sunday: this.setFormDefault()
      })
    });

    this.setDefaultDays();
  }

  private setFormDefault() {
    return this.formBuilder.group({
      active: [true],
      category: ['I'],
      turnsData: this.formBuilder.array([])
    });
  }

  private setDefaultDays() {
    this.addTurn('monday');
    this.addTurn('tuesday');
    this.addTurn('wednesday');
    this.addTurn('thursday');
    this.addTurn('friday');
    this.addTurn('saturday');
    this.addTurn('sunday');
  }

}
