<nz-spin [nzSpinning]="isLoading">
  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <form [formGroup]="payrollForm" *ngIf="!isLoading && payrollForm && !error">
    <div class="new-payroll">

      <div class="new-payroll-container flex wrap">

        <div nzLayout="vertical" nz-form class="form-column">
          <nz-form-item>
            <nz-form-label nzFor="name">Nombre</nz-form-label>
            <nz-form-control [nzErrorTip]="nameErrorTpl">

              <input nz-input placeholder="Nombre de la nómina" formControlName="name" type="text" id="name">

              <ng-template #nameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Ingresa el nombre de la nómina
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Registro patronal</nz-form-label>
            <nz-form-control [nzErrorTip]="employerRegistryUidErrorTpl">
              <nz-select
                nzShowSearch
                nzServerSearch
                (nzOnSearch)="onEmployerRegistrySearch($event)"
                (nzScrollToBottom)="loadMoreEmployerRegistries()"
                nzPlaceHolder="Selecciona el registro patronal"
                formControlName="employerRegistryUid"
                [nzDropdownRender]="renderTemplate">
                <nz-option *ngFor="let employerRegistry of employerRegistries" [nzLabel]="employerRegistry.businessName" [nzValue]="employerRegistry"></nz-option>
              </nz-select>

              <ng-template #renderTemplate>
                <nz-spin *ngIf="isLoadingEmployerRegistry"></nz-spin>
              </ng-template>

              <ng-template #employerRegistryUidErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge un registro patronal
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <div class="employer-info-container" *ngIf="payrollForm?.controls?.employerRegistryUid?.value !== null">
            <div class="item">Política de riesgo: <span>{{ payrollForm?.controls?.employerRegistryUid?.value?.riskPercentage }}%</span></div>
            <div class="item">Prima vacacional: <span>{{ payrollForm?.controls?.employerRegistryUid?.value?.holidayBonusPercentage }}%</span></div>
            <div class="item">Días de aguinaldo: <span>{{ payrollForm?.controls?.employerRegistryUid?.value?.holidayBonusDays }} día{{ payrollForm?.controls?.employerRegistryUid?.value?.holidayBonusDays === 1 ? '' : 's' }}</span></div>
            <div class="item">ISN: <span>{{ payrollForm?.controls?.employerRegistryUid?.value?.ISNPercentage }}%</span></div>
            <div class="item" style="width: 100%;">Política de ausencias proporcionales: <span>{{ payrollForm?.controls?.employerRegistryUid?.value?.proportionalAbsencesPolicy ? 'Sí' : 'No' }}</span></div>
          </div>

          <nz-form-item>
            <nz-form-label>Tipo <i *ngIf="payrollForm?.controls?.payrollType?.value !== null && payrollForm?.controls?.payrollType?.value === 'N'" class="warning" nzTheme="fill" nz-icon nzType="warning"></i> <span *ngIf="payrollForm?.controls?.payrollType?.value !== null && payrollForm?.controls?.payrollType?.value === 'N'" class="warning-text">Se agregarán percepciones y deducciones automáticas</span></nz-form-label>
            <nz-form-control [nzErrorTip]="payrollTypeErrorTpl">
              <nz-select nzPlaceHolder="Selecciona el tipo de nómina" formControlName="payrollType">
                <nz-option *ngFor="let payrollType of payrollTypes" [nzLabel]="payrollType.name" [nzValue]="payrollType.value"></nz-option>
              </nz-select>

              <ng-template #payrollTypeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge un tipo de nómina
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Periodo de la nómina</nz-form-label>
            <nz-form-control [nzErrorTip]="temporalCategoryErrorTpl">
              <nz-select nzPlaceHolder="Selecciona el periodo de la nómina" formControlName="temporalCategory">
                <nz-option *ngFor="let category of categories" [nzLabel]="category?.name" [nzValue]="category?.value"></nz-option>
              </nz-select>

              <ng-template #temporalCategoryErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge un periodo para la nómina
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Seleccionar fechas del periodo</nz-form-label>
            <nz-form-control [nzErrorTip]="dateRangeErrorTpl">
              <nz-range-picker formControlName="dateRange" (ngModelChange)="onChangeRange($event)" [nzAllowClear]="false"></nz-range-picker>

              <ng-template #dateRangeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge un rango de fechas para la nómina
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <div style="margin-bottom: 15px">
            <nz-form-label>Selecciona a los equipos de trabajo</nz-form-label>
            <app-teams-form-control [teams]="getTeamsFormControl()"></app-teams-form-control>
            <div class="error" *ngIf="payrollForm.controls.teamsUid.touched && payrollForm.controls.teamsUid.hasError('invalidArray')">Al menos un equipo de trabajo es necesario</div>
          </div>

          <div style="margin-bottom: 15px">
            <nz-form-label>Selecciona las deducciones para esta nómina</nz-form-label>
            <app-deductions-form-control
              [deductions]="getDeductionsFormControl()"
              [defaultDeductions]="payrollForm?.controls.payrollType?.value !== null ? payrollForm?.controls?.payrollType?.value === 'N' ? defaultDeductions : [] : []">
            </app-deductions-form-control>
          </div>

          <div style="margin-bottom: 40px">
            <nz-form-label>Selecciona las percepciones para esta nómina</nz-form-label>
            <app-earnings-form-control
              [earnings]="getEarningsFormControl()"
              [defaultEarnings]="payrollForm?.controls.payrollType?.value !== null ? payrollForm?.controls?.payrollType?.value === 'N' ? defaultEarnings : [] : []">
            </app-earnings-form-control>
          </div>

        </div>
      </div>
    </div>

  </form>
</nz-spin>

<div class="drawer-footer ant-drawer-footer" *ngIf="!isLoading">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="closeModal()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isCreating" (click)="createPayroll()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
