<nz-spin [nzSpinning]="isLoadingChoices" *ngIf="isLoadingChoices"></nz-spin>

<form
  nz-form
  nzLayout="vertical"
  [formGroup]="companyForm"
  (ngSubmit)="submitForm()"
  *ngIf="!isLoadingChoices"
>
  <div class="edit-company">
    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <div class="edit-company-container">
      <div class="form-column flex wrap">
        <div class="flex wrap form-row">
          <nz-form-item class="full-width">
            <nz-form-label nzFor="regime">Régimen Fiscal</nz-form-label>
            <nz-form-control>
              <nz-select
                class="select-options"
                id="regime"
                formControlName="taxRegimeUid"
                [nzPlaceHolder]="'Selecciona el tipo de régimen'"
                [nzAllowClear]="false"
              >
                <nz-option
                  *ngFor="let option of regimeChoices"
                  [nzValue]="option.uid"
                  [nzLabel]="option.description!"
                ></nz-option>
              </nz-select>

              <span class="message-error" *ngIf="taxRegimeUid?.invalid && taxRegimeUid?.touched">
                Seleccione el tipo de régimen fiscal.
              </span>
            </nz-form-control>
          </nz-form-item>

          <h2 style="width: 100%; flex-basis: auto">Dirección fiscal</h2>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="street">Calle</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Calle"
                  formControlName="street"
                  type="text"
                  id="street"
                />

                <span class="message-error" *ngIf="street?.invalid && street?.touched">
                  Ingrese el nombre de la calle.
                </span>
              </nz-form-control>
            </nz-form-item>
          </section>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="exteriorNumber">No. Exterior</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="No. Exterior"
                  formControlName="exteriorNumber"
                  type="text"
                  id="exteriorNumber"
                />

                <span class="message-error" *ngIf="exteriorNumber?.invalid && exteriorNumber?.touched">
                  Ingrese el número exterior.
                </span>
              </nz-form-control>
            </nz-form-item>
          </section>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="interiorNumber">No. Interior</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="No. Interior"
                  formControlName="interiorNumber"
                  type="text"
                  id="interiorNumber"
                />
              </nz-form-control>
            </nz-form-item>
          </section>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="zipcode">Código postal</nz-form-label>
              <nz-form-control>
                <nz-input-group [nzSuffix]="suffixTemplateInfo">
                  <input
                    nz-input
                    placeholder="Código postal"
                    formControlName="zipcode"
                    type="text"
                    id="zipcode"
                  />
                </nz-input-group>

                <ng-template #suffixTemplateInfo>
                  <ng-container *ngIf="zipcodeLoading">
                    <nz-spin nzSize="small"></nz-spin>
                  </ng-container>

                  <ng-container *ngIf="zipcodeError">
                    <i
                      nz-icon
                      nz-tooltip
                      nzTooltipTitle="No se encontró el código postal"
                      [nzTooltipColor]="'white'"
                      style="color: #faad14"
                      nzType="info-circle"
                    ></i>
                  </ng-container>
                </ng-template>

                <span class="message-error" *ngIf="zipcode?.invalid && zipcode?.touched">
                  Ingrese el código postal.
                </span>
              </nz-form-control>
            </nz-form-item>
          </section>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="colony">Colonia</nz-form-label>
              <nz-form-control>
                <nz-select
                  nzShowSearch
                  id="colony"
                  nzPlaceHolder="Colonia"
                  formControlName="colony"
                >
                  <nz-option
                    *ngFor="let colony of colonies"
                    nzCustomContent
                    [nzLabel]="colony"
                    [nzValue]="colony"
                  >
                    {{ colony }}
                  </nz-option>
                </nz-select>

                <span class="message-error" *ngIf="colony?.invalid && colony?.touched">
                  Ingrese el nombre de la colonia.
                </span>
              </nz-form-control>
            </nz-form-item>
          </section>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="city">Ciudad</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Ciudad"
                  formControlName="city"
                  type="text"
                  id="city"
                />

                <span class="message-error" *ngIf="city?.invalid && city?.touched">
                  Ingrese el nombre de la ciudad.
                </span>
              </nz-form-control>
            </nz-form-item>
          </section>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="zipcode">Estado</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Estado"
                  formControlName="state"
                  type="text"
                  id="state"
                />

                <span class="message-error" *ngIf="state?.invalid && state?.touched">
                  Ingrese el nombre del estado.
                </span>
              </nz-form-control>
            </nz-form-item>
          </section>

          <section formGroupName="addressData">
            <nz-form-item>
              <nz-form-label nzFor="country">País</nz-form-label>
              <nz-form-control>
                <nz-select
                  nzShowSearch
                  id="country"
                  nzPlaceHolder="País"
                  formControlName="country"
                >
                  <nz-option
                    *ngFor="let country of countries$ | async"
                    nzCustomContent
                    [nzLabel]="country!.countryName"
                    [nzValue]="country.countryCode"
                  >
                    {{ country.countryName }}
                  </nz-option>
                </nz-select>

                <span class="message-error" *ngIf="country?.invalid && country?.touched">
                  Ingrese el nombre del país.
                </span>
              </nz-form-control>
            </nz-form-item>
          </section>
        </div>
      </div>
    </div>
  </div>

  <button style="display: none" type="submit" #submit>Guardar</button>
</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button
      nz-popconfirm
      nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
      (nzOnConfirm)="closeModal()"
      type="button"
      nz-button
      nzType="default"
    >
      Cancelar
    </button>

    <button
      *ngIf="companyForm"
      [nzLoading]="isLoading"
      [disabled]="!companyForm.valid"
      (click)="submitForm()"
      type="button"
      nz-button
      nzType="primary"
    >
      Guardar
    </button>
  </div>
</div>
