<div class="meeting-box flex between wrap align-center">
  <div class="name-content">
    <div class="container">
      <div class="img-box" *ngIf="meeting?.fromGoogleCalendar">
        <img [src]="'../assets/images/meet.png'" [alt]="'meet-icon'">
      </div>
      <i *ngIf="!meeting?.fromGoogleCalendar" nz-icon nzType="icons:briefcase"></i>
    </div>
    <div class="name" (click)="openMeeting()">{{ meeting?.name }}</div>
  </div>

  <div class="date-content">
    <div class="date">{{ meeting?.startDate | date: 'dd' }} de {{ meeting?.startDate | date: 'MMM' }} {{ meeting?.startDate | date: 'yyyy' }}</div>
  </div>

  <div class="address-content">
    <div class="address">{{ meeting?.fromGoogleCalendar ? (meeting.place !== null && meeting.place.address !== null) ? meeting?.place?.address?.fullAddress : 'Sin dirección registrada' : (meeting?.place?.address?.fullAddress || 'Sin dirección registrada') }}</div>
  </div>

  <div class="time-content" *ngIf="showMeetingAttendance">
    <div class="turn-box" style="width: max-content;">
      <ng-container>
        <div class="time-box" [ngClass]="{
        'red': (meeting?.meetingAttendance === null && isTodayOrBefore()) || (meeting?.meetingAttendance !== null && !meeting?.meetingAttendance?.onTime && !meeting?.meetingAttendance?.onLocation),
        'yellow': meeting?.meetingAttendance !== null && (!meeting?.meetingAttendance?.onTime || !meeting?.meetingAttendance?.onLocation),
        'green': meeting?.meetingAttendance !== null && (meeting?.meetingAttendance?.onTime && meeting?.meetingAttendance?.onLocation)
        }"
             nzTooltipColor="white"
             [nz-tooltip]="(meeting?.meetingAttendance === null && isTodayOrBefore()) || (meeting?.meetingAttendance !== null && !meeting?.meetingAttendance?.onTime && !meeting?.meetingAttendance?.onLocation) ? 'Check in fuera de tiempo / ubicación' :
             meeting?.meetingAttendance !== null && !meeting?.meetingAttendance?.onTime ? 'Check in fuera de tiempo' :  meeting?.meetingAttendance !== null && !meeting?.meetingAttendance?.onLocation ? 'Check in fuera de ubicación' : ''">
          <span>{{ meeting?.meetingAttendance !== null ? (meeting?.meetingAttendance?.timestamp | date: 'HH:mm') : isTodayOrBefore() ? 'No realizado' : 'Pendiente' }}</span>

          <div class="icon-row flex end" *ngIf="isTodayOrBefore()">
            <div class="icon">
              <div class="img-box" *ngIf="meeting?.meetingAttendance === null || (meeting?.meetingAttendance !== null && !meeting?.meetingAttendance?.onTime && !meeting?.meetingAttendance?.onLocation)">
                <img src="../assets/images/clock.png">
              </div>
              <div class="img-box" *ngIf="meeting?.meetingAttendance !== null && !meeting?.meetingAttendance?.onTime">
                <img src="../assets/images/clock-y.png">
              </div>
              <div class="img-box" *ngIf="meeting?.meetingAttendance !== null && !meeting?.meetingAttendance?.onLocation">
                <img src="../assets/images/pin-1-y.png">
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="time-content">
    <div class="turn-box">
      <ng-container>
        <div class="time-box grey-1"
             nzTooltipColor="white" [nz-tooltip]="'Hora de la junta'">
          <span>{{ meeting?.startHour }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container>
    <div class="meet-action flex align-center">
      <i nz-icon nzType="unordered-list" (click)="openMeeting()"></i>
    </div>
  </ng-container>
</div>
