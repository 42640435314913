<div class="flex wrap members-form-control">

  <nz-select
        [nzMaxMultipleCount]="!moreThanOne ? 1 : 100000000000"
        [(nzOpen)]="isOpen"
        class="hidden-select"
        [nzDropdownMatchSelectWidth]="false"
        [nzDropdownRender]="renderTemplate"
        [nzMaxTagCount]="0"
        [compareWith]="compareUser"
        [nzOptionHeightPx]="40"
        [nzOptionOverflowSize]="5"
        [nzDropdownClassName]="'member-form-control-select'"
        (nzScrollToBottom)="loadMembers()"
        nzMode="multiple"
        nzPlaceHolder="Selecciona a los miembros"
        [formControl]="members"
        (ngModelChange)="updateMembers()">

    <ng-container *ngFor="let member of list">
      <nz-option nzCustomContent [nzLabel]="((member.firstName + ' ' + member.lastName) | titlecase) || ''" [nzValue]="member">
        <div class="member-row flex align-center">
          <div class="member-photo member-photo-control">
            <nz-avatar [nzSize]="30"  [nzSrc]="member.photo?.url || undefined"
                       [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
          </div>
          <div class="member-name">
            {{(member.firstName + ' ' + member.lastName) | titlecase}}
          </div>
        </div>
      </nz-option>
    </ng-container>

    <nz-option *ngIf="membersLoading" nzDisabled nzCustomContent>
      <div class="member-row loading-row align-center flex">
        <i nz-icon nzType="loading" class="loading-icon"></i> Cargando miembros...
      </div>
    </nz-option>

  </nz-select>

  <ng-template #renderTemplate>
    <div class="search-box">
      <div class="line"></div>
      <nz-input-group [nzPrefix]="suffixIconSearch">
        <input [(ngModel)]="substring" (ngModelChange)="substringSearch()" type="text" nz-input placeholder="Buscar miembro" />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </div>
  </ng-template>

  <div class="member bubble" nzTooltipColor="white"
       [nz-tooltip]="(member.firstName | titlecase) + ' ' + (member.lastName | titlecase)" *ngFor="let member of members.value">
    <i (click)="removeMember(member)" class="close-icon" nz-icon [nzTwotoneColor]="'#FF8484'" nzType="close-circle" nzTheme="twotone"></i>
    <nz-avatar [nzSize]="52"  [nzSrc]="member.photo?.url || undefined"
               [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
  </div>

  <div class="member add-new" (click)="isOpen = true;" *ngIf="moreThanOne ? true : members.value.length === 0">
    <i nz-icon nzType="icons:member-add" class="loading-icon"></i>
  </div>
</div>

<!--<nz-select-->
<!--      (nzScrollToBottom)="loadMembers()"-->
<!--      nzPlaceHolder="Select users"-->
<!--      nzAllowClear-->
<!--      [nzDropdownRender]="listTemplate"-->
<!--&gt;-->
<!--  <nz-option *ngFor="let o of ['asdasd', 'asdasdas', 'asdasdasd', 'asdasdad', 'asdasd', 'asdasdas', 'asdasdasd', 'asdasdad', 'asdasd', 'asdasdas', 'asdasdasd', 'asdasdad', 'asdasd', 'asdasdas', 'asdasdasd', 'asdasdad', 'asdasd', 'asdasdas', 'asdasdasd', 'asdasdad']" [nzValue]="o" [nzLabel]="o"></nz-option>-->
<!--</nz-select>-->
<!--<ng-template #listTemplate>-->
<!--  <nz-spin *ngIf="membersLoading"></nz-spin>-->
<!--</ng-template>-->
