<div class="new-position-modal">
  <form nz-form nzLayout="vertical" [formGroup]="newPositionForm" (ngSubmit)="createPosition()">
    <nz-form-item>
      <nz-form-label nzFor="name">Nombre del puesto</nz-form-label>
      <nz-form-control [nzErrorTip]="nameErrorTpl">

        <input nz-input placeholder="Nombre del puesto" formControlName="name" type="text" id="name">

        <ng-template #nameErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            El nombre del puesto es requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div class="form-items">
      <nz-form-item class="item">
        <nz-form-label>Salario</nz-form-label>
        <nz-form-control [nzErrorTip]="paidAmountErrorTpl">

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="paidAmount"
            [nzMin]="1"
            [nzStep]="10"
            [nzFormatter]="formatter"
            [nzParser]="parser">
          </nz-input-number>

          <ng-template #paidAmountErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El salario es requerido
            </ng-container>

            <ng-container *ngIf="control.hasError('min')">
              El salario mínimo es de $1.00
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Salario IMSS</nz-form-label>
        <nz-form-control [nzErrorTip]="imssPaidAmountErrorTpl">

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="imssPaidAmount"
            [nzMin]="0"
            [nzStep]="10"
            [nzFormatter]="formatter"
            [nzParser]="parser">
          </nz-input-number>

          <ng-template #imssPaidAmountErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El salario IMSS es requerido
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="form-items">
      <nz-form-item class="item">
        <nz-form-label>Jornada laboral</nz-form-label>
        <nz-form-control [nzErrorTip]="hoursWorkedErrorTpl">

          <nz-select formControlName="hoursWorked">
            <nz-option *ngFor="let hour of hours" [nzValue]="hour" [nzLabel]="hour + ' hrs'"></nz-option>
          </nz-select>

          <ng-template #hoursWorkedErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              La jornada laboral es requerida
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Tolerancia de check in / out</nz-form-label>
        <nz-form-control [nzErrorTip]="toleranceMinutesErrorTpl">

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="toleranceMinutes"
            [nzStep]="10"
            [nzFormatter]="formatterMin"
            [nzParser]="parserMin">
          </nz-input-number>

          <ng-template #toleranceMinutesErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              La tolerancia es requerida
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="days-container">
      <div class="day">
        <app-schedule-form-control [dayFormGroup]="getDayFormGroup('monday')" [dayName]="'Lunes'"></app-schedule-form-control>
      </div>

      <div class="day">
        <app-schedule-form-control [dayFormGroup]="getDayFormGroup('tuesday')" [dayName]="'Martes'"></app-schedule-form-control>
      </div>

      <div class="day">
        <app-schedule-form-control [dayFormGroup]="getDayFormGroup('wednesday')" [dayName]="'Miércoles'"></app-schedule-form-control>
      </div>

      <div class="day">
        <app-schedule-form-control [dayFormGroup]="getDayFormGroup('thursday')" [dayName]="'Jueves'"></app-schedule-form-control>
      </div>

      <div class="day">
        <app-schedule-form-control [dayFormGroup]="getDayFormGroup('friday')" [dayName]="'Viernes'"></app-schedule-form-control>
      </div>

      <div class="day">
        <app-schedule-form-control [dayFormGroup]="getDayFormGroup('saturday')" [dayName]="'Sábado'"></app-schedule-form-control>
      </div>

      <div class="day">
        <app-schedule-form-control [dayFormGroup]="getDayFormGroup('sunday')" [dayName]="'Domingo'"></app-schedule-form-control>
      </div>
    </div>

    <button style="display:none;" type="submit" #submit>Crear</button>
  </form>

  <div class="drawer-footer ant-drawer-footer">
    <div class="flex end wrap">
      <button nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
              (nzOnConfirm)="closeModal()"
              type="button" nz-button nzType="default">Cancelar</button>
      <button [nzLoading]="isLoading" (click)="submit.click()" type="button" nz-button nzType="primary">Crear</button>
    </div>
  </div>
</div>
