<div class="payment-box flex wrap align-center">
  <div class="payment-id payment-column">
    <b>Pago</b> <span class="id">#{{payment.numberId}}</span>
  </div>

  <div class="status payment-column">
    <ng-container [ngSwitch]="payment.status">
      <div nzTooltipColor="white" nz-tooltip="Pendiente de pago" class="status-box yellow" *ngSwitchCase="'pp'">
        Pendiente
      </div>
      <div nzTooltipColor="white" nz-tooltip="Pago realizado" class="status-box green" *ngSwitchCase="'pa'">
        Pagado
      </div>
      <div nzTooltipColor="white" [nz-tooltip]="payment.rejectReason" class="status-box red" *ngSwitchCase="'pd'">
        Declinado
      </div>
      <div nzTooltipColor="white" nz-tooltip="Pago reembolsado" class="status-box blue" *ngSwitchCase="'re'">
        Reembolsado
      </div>
    </ng-container>
  </div>

  <div class="link payment-column flex align-center">
    <ng-container *ngIf="payment.paymentLink">
      <a *ngIf="payment.status !== 'pa'" target="_blank" [href]="payment.paymentLink">Pagar aquí</a>
      <div *ngIf="payment.status === 'pa'">Link de pago</div>
    </ng-container>
    <ng-container *ngIf="payment.paymentMethod">
      <div class="tc-logo img-box" *ngIf="payment?.paymentMethod?.brand !== null && payment?.paymentMethod?.brand !== 'tc-card'">
        <img [src]="'../assets/images/' + payment?.paymentMethod?.brand + '-c.png'" [alt]="payment?.paymentMethod?.brand + '-logo'">
      </div>
      <div class="number">
        Terminación en {{payment?.paymentMethod?.last4}}
      </div>
    </ng-container>
  </div>

  <div class="amount payment-column">
    {{payment.amount | currency}} pesos
  </div>

  <div class="payment-column date">
    {{(payment.transactionDate | date) || 'Sin fecha'}}
  </div>

  <div class="download-box flex end">
    <i nzTooltipColor="white" nz-tooltip="Descargar factura" *ngIf="payment?.invoice?.zipFileUrl && !isLoading" (click)="downloadInvoice()" nz-icon nzType="download"></i>
    <nz-spin [nzSpinning]="true" [nzSize]="'small'" *ngIf="isLoading"></nz-spin>
  </div>
</div>
