<nz-spin [nzSpinning]="isLoadingCompany" *ngIf="isLoadingCompany"></nz-spin>

<div class="error-box" *ngIf="error && !isLoadingCompany">
  <nz-alert
    nzType="error"
    [nzMessage]="error"
    nzShowIcon
  ></nz-alert>
</div>

<form [formGroup]="editTeamForm" *ngIf="!isLoadingCompany">
  <div class="edit-team">

    <div class="edit-team-container flex wrap">
      <div class="flex center wrap form-column team-icon">
        <app-team-icon
              [size]="90"
              [icon]="editTeamForm.controls.icon.value?.url"
              [color]="editTeamForm.controls.color.value"></app-team-icon>
      </div>

      <div nzLayout="vertical" nz-form class="form-column">
        <nz-form-item>
          <nz-form-label nzFor="icon">Seleccionar icono</nz-form-label>
          <nz-form-control [nzErrorTip]="iconErrorTpl">
            <nz-select [compareWith]="compareIconFn" [nzCustomTemplate]="iconTemplate" id="icon" nzPlaceHolder="Icono" formControlName="icon">
              <nz-option *ngFor="let icon of ($icons | async)" nzCustomContent [nzLabel]="icon!.name" [nzValue]="icon">
                <app-icon-box [icon]="icon"></app-icon-box>
              </nz-option>
            </nz-select>

            <ng-template #iconTemplate let-selected>
              <app-icon-box [size]="20" [icon]="selected.nzValue"></app-icon-box>
            </ng-template>

            <ng-template #iconErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge un icono
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="color">Seleccionar color</nz-form-label>
          <nz-form-control [nzErrorTip]="colorErrorTpl">
            <nz-select [nzCustomTemplate]="colorTemplate" id="color" nzPlaceHolder="Color" formControlName="color">
              <nz-option *ngFor="let color of colors" nzCustomContent [nzLabel]="color!.label" [nzValue]="color!.value">
                <app-color-box [color]="color"></app-color-box>
              </nz-option>
            </nz-select>

            <ng-template #colorTemplate let-selected>
              <app-color-box [size]="20" [color]="{
              label: selected.nzLabel,
              value: selected.nzValue
            }"></app-color-box>
            </ng-template>

            <ng-template #colorErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge un color
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nzLayout="vertical" nz-form class="form-column">
        <nz-form-item>
          <nz-form-label nzFor="teamName">Nombre del equipo </nz-form-label>
          <nz-form-control [nzErrorTip]="nameErrorTpl">

            <input nz-input placeholder="Nombra el equipo de trabajo" formControlName="name" type="text" id="teamName">

            <ng-template #nameErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa el nombre del equipo
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="color">Seleccionar zona horaria</nz-form-label>
          <nz-form-control [nzErrorTip]="timezoneErrorTpl">
            <nz-select nzShowSearch id="timezone" nzPlaceHolder="Zona horaria" formControlName="timezone">
              <nz-option *ngFor="let timezone of ($timezones | async)" nzCustomContent [nzLabel]="timezone.name ? timezone.name.toString() : ''" [nzValue]="timezone.name ? timezone.name.toString() : ''">
                {{timezone.name}}
              </nz-option>
            </nz-select>

            <ng-template #timezoneErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge una zona horaria
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <app-slack-box
          *ngIf="!company.slackActive"
          [marginBottom]="20"
          (close)="closeModal()">
        </app-slack-box>

        <nz-form-item *ngIf="company.slackActive">
          <nz-form-label nzFor="icon">Seleccionar canales de slack</nz-form-label>
          <nz-form-control>
            <nz-select nzPlaceHolder="Seleccionar canales de slack" formControlName="slackChannelsUid" nzMode="multiple">
              <nz-option *ngFor="let channel of slackChannels" [nzLabel]="'#' + channel.name" [nzValue]="channel.uid"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <div style="margin-bottom: 15px">
          <nz-form-label>Selecciona lideres de equipo</nz-form-label>
          <app-members-form-control [type]="'TeamLeaders'"
                                    [members]="getTeamLeadersFormControl()">
          </app-members-form-control>
        </div>

        <div style="margin-bottom: 15px">
          <nz-form-label>Selecciona miembros del equipo</nz-form-label>
          <app-members-form-control [type]="'Members'"
                                    [members]="getMemberFormControl()">
          </app-members-form-control>
        </div>

        <div style="margin-bottom: 30px">
          <nz-form-label>Geocercas</nz-form-label>
          <app-geo-fencings-form-control [geoFencings]="getGeoFencingsFormControl()">
          </app-geo-fencings-form-control>
        </div>

      </div>
    </div>
  </div>

</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="closeModal()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isLoading" (click)="submitForm()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
