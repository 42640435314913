import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Position} from "../../../core/models/position";
import {finalize} from "rxjs/operators";
import {PositionService} from "../../../core/services/position.service";
import {CurrencyPipe} from "@angular/common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {Turn} from "../../../core/models/turn";

@Component({
  selector: 'app-edit-salary',
  templateUrl: './edit-salary.component.html',
  styleUrls: ['./edit-salary.component.scss']
})
export class EditSalaryComponent implements OnInit {
  @Input() position: Position | null;
  @Output() salaryEdited: EventEmitter<Position> = new EventEmitter();
  isLoading = true;
  error: string | null;
  positionForm: FormGroup;
  isUpdating = false;
  hours = [5, 7, 7.5, 8];
  formatter = (value: number) => `${this.currencyPipe.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");

  constructor(private positionService: PositionService,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              private notification: NzNotificationService,
              private currencyPipe: CurrencyPipe,
              private nzModalRef: NzModalRef) { }

  ngOnInit(): void {
    this.getDetails();
  }

  closeModal() {
    this.nzModalRef.close();
  }

  cleanForm(data: any) {
    const form = data;
    form.scheduleData = {};
    form.scheduleData.monday = this.setDaySchedule('monday');
    form.scheduleData.tuesday = this.setDaySchedule('tuesday');
    form.scheduleData.wednesday = this.setDaySchedule('wednesday');
    form.scheduleData.thursday = this.setDaySchedule('thursday');
    form.scheduleData.friday = this.setDaySchedule('friday');
    form.scheduleData.saturday = this.setDaySchedule('saturday');
    form.scheduleData.sunday = this.setDaySchedule('sunday');
    form.salaryData = {
      hoursWorked: form.hoursWorked,
      imssPaidAmount: form.imssPaidAmount,
      paidAmount: form.paidAmount
    };
    delete form.hoursWorked;
    delete form.imssPaidAmount;
    delete form.paidAmount;
    return form;
  }

  setDaySchedule(day: string) {
    const turnsData: Turn[] = [];
    // @ts-ignore
    this.position?.schedule[day]?.turns?.forEach((turn: Turn) => {
      turnsData.push({
        fromHour: turn.fromHour,
        toHour: turn.toHour
      });
    });
    return  {
      // @ts-ignore
      active: this.position?.schedule[day]?.active,
      // @ts-ignore
      category: this.position?.schedule[day]?.category === 'Remoto' ? 'R' : 'I',
      turnsData
    };
  }

  updateSalary() {
    if (this.positionForm.valid) {
      this.isUpdating = true;
      this.positionService.updatePosition(this.cleanForm(this.positionForm.getRawValue()))
        .pipe(finalize(() => {
          this.isUpdating = false;
        }))
        .subscribe((position: Position | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Salario actualizado correctamente');
          // @ts-ignore
          this.salaryEdited.emit(position);
          this.nzModalRef.close();
        }, error => {
          this.notification.create('error', 'Error al actualizar el salario', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelperService.markFormAsDirty(this.positionForm);
    }
  }

  private getDetails() {
    this.isLoading = true;
    // @ts-ignore
    this.positionService.getPosition(this.position.uid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((position: Position | null) => {
        this.position = position;
        this.createForm();
      }, error => {
        this.error = error;
      });
  }

  private createForm() {
    this.positionForm = this.formBuilder.group({
      affectsEveryoneSalary: [false],
      affectsEveryoneSchedule: [false],
      name: [this.position?.name],
      paidAmount: [this.position?.salary?.paidAmount, [Validators.required, Validators.min(1)]],
      hoursWorked: [this.position?.salary?.hoursWorked, Validators.required],
      imssPaidAmount:[this.position?.salary?.imssPaidAmount, Validators.required],
      positionUid: [this.position?.uid],
      toleranceMinutes: [this.position?.toleranceMinutes],
      scheduleData: [null]
    });
  }
}
