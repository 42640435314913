import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import {PrivilegesTableFilter} from '../models/privileges-table-filter';
import {Observable} from 'rxjs';
import {EmployeesTable} from '../models/employees-table';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrivilegesService {

  constructor(
      private apollo: Apollo
  ) { }

  updateEmployeePrivileges(update: {availableModules: string[], employeesUid: string[]}):Observable<string | undefined> {
    return this.apollo.mutate<{updateEmployeePrivileges: {message: string}}>({
      mutation: gql`
          mutation updateEmployeePrivileges($employeesUid: [String]! $availableModules: [String]!) {
              updateEmployeePrivileges(employeesUid: $employeesUid availableModules: $availableModules) {
                  message
              }
          }
      `,
      variables: {
        ...update
      }
    }).pipe(
        map(({data}) => {
          return data?.updateEmployeePrivileges.message;
        })
    )
  }

  getPrivilegedEmployees(filters: PrivilegesTableFilter): Observable<EmployeesTable> {
    return this.apollo.query<{employeesTable: EmployeesTable}>({
      query: gql`
          query employeesTable($substring: String $skip: Int $limit: Int) {
              employeesTable(substring: $substring skip: $skip limit: $limit sort: "name" order: "asc") {
                  count
                  employees {
                      uid
                      firstName
                      lastName
                      position {
                          name
                      }
                      email
                      availableModules
                      photo {
                          url
                      }
                  }
              }
          }
      `,
      variables: {
        ...filters
      }
    }).pipe(
        map(({data}) => {
          return data.employeesTable;
        })
    )
  }
}
