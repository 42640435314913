import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DeductionConcept} from "../../../core/models/deduction-concept";
import {DeductionsService} from "../../../core/services/deductions.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Color} from "../../../core/models/color";
import {ColorService} from "../../../core/services/color.service";
import {IconStore} from "../../../core/stores/icons.store";
import {Observable} from "rxjs";
import {Icon} from "../../../core/models/icon";
import {CurrencyPipe} from "@angular/common";
import {finalize} from "rxjs/operators";
import {Deduction} from "../../../core/models/deduction";

@Component({
  selector: 'app-new-deduction-modal',
  templateUrl: './new-deduction-modal.component.html',
  styleUrls: ['./new-deduction-modal.component.scss']
})
export class NewDeductionModalComponent implements OnInit {
  newDeductionForm: FormGroup;
  isLoading = true;
  isCreating = false;
  error: string | null;
  deductionConcepts: DeductionConcept[] | undefined;
  colors: Color[] = this.colorService.getColors();
  $icons: Observable<Icon[] | undefined> = this.iconsStore.getIcons();
  formatter = (value: number) => `${this.currencyPipe.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");

  constructor(private deductionsService: DeductionsService,
              private colorService: ColorService,
              private iconsStore: IconStore,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private currencyPipe: CurrencyPipe,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.getConcepts();
  }

  close() {
    this.drawerRef.close(null);
  }

  createDeduction() {
    if (this.newDeductionForm.valid) {
      this.isCreating = true;
      const data = this.newDeductionForm.getRawValue();
      data.iconUid = data.iconUid.uid;
      data.conceptCode = data.conceptCode.id;
      this.deductionsService.createDeduction(data)
        .pipe(finalize(() => {
          this.isCreating = false;
        }))
        .subscribe((deduction: Deduction) => {
          this.notification.create('success', '¡Operación exitosa!', 'Deducción creada correctamente');
          this.drawerRef.close(deduction);
        }, error => {
          this.notification.create('error', 'Error al crear la deducción', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.newDeductionForm);
    }
  }

  private getConcepts() {
    this.isLoading = true;
    this.deductionsService.getConcepts()
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((deductionConcepts: DeductionConcept[]) => {
        this.deductionConcepts = deductionConcepts;
        this.createForm();
      }, error => {
        this.error = error.message.replace("GraphQL error:", "").trim();
        // this.notification.create('error', 'Error al crear el activo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  private createForm() {
    this.newDeductionForm = this.formBuilder.group({
      amount: [0, Validators.required],
      colour: [null, Validators.required],
      conceptCode:[null, Validators.required],
      iconUid: [null, Validators.required],
      name: [null, Validators.required]
    });
  }
}
