<div class="teams-form-control flex wrap">

  <ng-template #newTeamTitle>
    <div class="flex align-center">
      <span>Nuevo equipo de trabajo</span>
      <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'2'" nz-icon nzType="info-circle" nzTheme="fill"></i>
    </div>
  </ng-template>

  <div class="flex">
    <nz-select
          [nzMaxMultipleCount]="!moreThanOne ? 1 : 100000000000"
          [(nzOpen)]="isOpen"
          class="hidden-select"
          [nzDropdownRender]="renderTemplate"
          [nzDropdownMatchSelectWidth]="false"
          [nzMaxTagCount]="0"
          [compareWith]="compareTeams"
          [nzOptionHeightPx]="40"
          [nzOptionOverflowSize]="5"
          [nzDropdownClassName]="'team-form-control-select'"
          nzMode="multiple"
          nzPlaceHolder="Selecciona a los miembros"
          [formControl]="teams"
          (ngModelChange)="addDeleteTeam($event)">

      <ng-container *ngFor="let team of list">
        <nz-option nzCustomContent [nzLabel]="(team.name | titlecase) || ''" [nzValue]="team">
          <div class="team-row flex align-center">
            <div class="team-photo team-photo-control">
              <app-team-icon [size]="28" [icon]="team.icon?.url"  [color]="team.color"></app-team-icon>
            </div>
            <div class="team-name">
              {{(team.name) | titlecase}}
            </div>
          </div>
        </nz-option>
      </ng-container>

      <nz-option *ngIf="teamsLoading" nzDisabled nzCustomContent>
        <div class="team-row loading-row align-center flex">
          <i nz-icon nzType="loading" class="loading-icon"></i> Cargando equipos...
        </div>
      </nz-option>

    </nz-select>

    <ng-template #renderTemplate>
      <div class="search-box" (click)="openNewTeam()">
        <div class="line"></div>
        <div class="new-label">
          + Crear nuevo equipo
        </div>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="!teamsLoading">
    <div class="team bubble" nzTooltipColor="white"
         [nz-tooltip]="(team.name | titlecase)" *ngFor="let team of teams.value">
      <i *ngIf="allowDelete" (click)="removeTeam(team)" class="close-icon" nz-icon [nzTwotoneColor]="'#FF8484'" nzType="close-circle" nzTheme="twotone"></i>
      <app-team-icon [size]="50" [icon]="team.icon?.url"  [color]="team.color"></app-team-icon>
    </div>
  </ng-container>

  <div class="team add-new" (click)="isOpen = true;" *ngIf="allowNew && moreThanOne ? true : teams.value.length === 0">
    <i nz-icon nzType="icons:member-add" class="loading-icon"></i>
  </div>
</div>
