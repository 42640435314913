<form (submit)="createActiveResource()" [formGroup]="activeResourceForm">
  <div class="create-active-resource">

    <!--<ng-template #newPositionTitle>
      <div class="flex align-center">
        <span>Nuevo Puesto</span>
        <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'1'" nz-icon nzType="info-circle" nzTheme="fill"></i>
      </div>
    </ng-template>-->

    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <div class="create-active-resource-container">
      <div class="flex center wrap form-column active-icon">
        <app-upload-image style="width: 26%" [roundImage]="true" [folder]="'active'" [image]="getImageControl()"></app-upload-image>
      </div>

      <div nzLayout="vertical" nz-form class="form-column flex wrap">

        <div class="flex wrap form-row">
          <form [formGroup]="activeResourceForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-label nzFor="name">Nombre del activo fijo</nz-form-label>
              <nz-form-control [nzErrorTip]="nameErrorTpl">

                <input nz-input placeholder="Nombre del activo fijo" formControlName="name" type="text" id="name">

                <ng-template #nameErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El nombre es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="activeResourceForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-label nzFor="sku">Número de serie - SKU</nz-form-label>
              <nz-form-control [nzErrorTip]="skuErrorTpl">

                <input nz-input placeholder="Número de serie - SKU" formControlName="serial" type="text" id="sku">

                <ng-template #skuErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El número de serie - SKU es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="activeResourceForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-label>Categoría</nz-form-label>
              <nz-form-control [nzErrorTip]="categoryErrorTpl">

                <nz-select
                  formControlName="categoryUid"
                  [nzPlaceHolder]="'Seleccionar categoría'"
                  nzShowSearch
                  nzServerSearch
                  (nzOnSearch)="categorySearch($event)"
                  (nzScrollToBottom)="loadMoreCategories()"
                  [nzDropdownRender]="renderTemplate"
                  [nzCustomTemplate]="optionsTemplate">
                  <nz-option [nzValue]="{name: 'new'}" [nzLabel]="'+ Nueva categoría'"></nz-option>
                  <nz-option nzCustomContent *ngFor="let category of categories" [nzValue]="category" [nzLabel]="category?.name">
                    <div class="flex align-center">
                      <div class="color-box" [ngStyle]="{
                                                  backgroundColor: category.color,
                                                  height: '20px',
                                                  width:  '20px',
                                                  borderRadius: (20/2) + 'px',
                                                  marginRight: (20/3) + 'px'
                                                }">
                      </div>
                      <div class="label">
                        {{category.name | titlecase}}
                      </div>
                    </div>
                  </nz-option>
                </nz-select>

                <ng-template #renderTemplate>
                  <nz-spin *ngIf="categoriesLoading"></nz-spin>
                </ng-template>

                <ng-template #optionsTemplate let-selected>
                  <div *ngIf="selected.nzValue.name === 'new'">+ Nueva categoría</div>
                  <div class="flex align-center" *ngIf="selected.nzValue.name !== 'new'">
                    <div class="color-box" [ngStyle]="{
                                                  backgroundColor: selected.nzValue.color,
                                                  height: '20px',
                                                  width:  '20px',
                                                  borderRadius: (20/2) + 'px',
                                                  marginRight: (20/3) + 'px'
                                                }">
                    </div>
                    <div class="label">
                      {{selected.nzValue.name | titlecase}}
                    </div>
                  </div>
                </ng-template>

                <ng-template #categoryErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    La categoría es requerida
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="activeResourceForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item class="padding-right">
              <nz-form-label nzFor="email">Fecha de compra</nz-form-label>
              <nz-form-control [nzErrorTip]="dateErrorTpl">

                <nz-date-picker style="width: 100%" formControlName="purchaseDate" [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false" [nzDisabledDate]="disabledDate"></nz-date-picker>

                <ng-template #dateErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    La fecha de compra es requerida
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>

          <form [formGroup]="activeResourceForm" style="width: 100%; flex-basis: auto;">
            <nz-form-item>
              <nz-form-label nzFor="rfc">Descripción</nz-form-label>
              <nz-form-control>

                <textarea nz-input rows="4" cols="4" formControlName="description" placeholder="Descripción"></textarea>

              </nz-form-control>
            </nz-form-item>
          </form>
        </div>

      </div>

      <div nzLayout="vertical" nz-form class="form-column">

        <div style="margin-bottom: 40px">
          <ng-container>
            <nz-form-label>Colaborador asignado</nz-form-label>
            <app-members-form-control [type]="'all'" [moreThanOne]="false" [members]="getEmployeeControl()"></app-members-form-control>
          </ng-container>
        </div>

      </div>
    </div>
  </div>

  <button style="display:none;" type="submit" #submit>Guardar</button>

</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="close()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isLoading" (click)="submit.click()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
