<div class="absence-request-row flex wrap align-center">

  <ng-template #absenceModalTitle>
    <div class="flex align-center">
      <span *ngIf="absenceRequest.approved">Detalles de ausencia</span>
      <span *ngIf="!absenceRequest.approved">Solicitud de ausencia</span>
      <i nz-tooltip="Ayuda" [nzTooltipColor]="'white'" class="help-icon" appHelpModalOpener [helpIndex]="'5'" nz-icon nzType="info-circle" nzTheme="fill"></i>
    </div>
  </ng-template>

  <div class="member-data flex align-center">
    <div [routerLink]="['/members/' + absenceRequest?.employee?.uid]" class="member-photo router-label">
      <nz-avatar [nzSize]="30"  [nzSrc]="absenceRequest?.employee?.photo?.url || undefined"
                 [nzText]="absenceRequest?.employee?.firstName?.charAt(0) + '' + absenceRequest?.employee?.lastName?.charAt(0)"></nz-avatar>
    </div>

    <div class="member-name flex column center">
      <a [routerLink]="['/members/' + absenceRequest?.employee?.uid]" class="name router-label">
        {{absenceRequest?.employee?.firstName | titlecase}} {{absenceRequest?.employee?.lastName | titlecase}}
      </a>
      <div class="email">
        {{absenceRequest?.employee?.email | lowercase}}
      </div>
    </div>
  </div>

  <div class="type-box">
    <div class="type">
      <app-absence-request-box (absenceRequestUpdated)="onUpdated($event)" [member]="absenceRequest?.employee" [absenceRequest]="absenceRequest"></app-absence-request-box>
    </div>
  </div>

  <div class="date-box">
    Del {{absenceRequest.fromDate | date : 'dd MMM yyy'}} al {{absenceRequest.toDate | date : 'dd MMM yyy'}}
  </div>

  <div class="status-row flex end">
    <div class="status">
      <div class="background green" *ngIf="absenceRequest?.approved"></div>

      <div class="background red" *ngIf="absenceRequest?.approved == false"></div>

      <div class="background yellow" *ngIf="absenceRequest?.approved == null"></div>

      <div class="label green" *ngIf="absenceRequest?.approved">
        Solicitud aprobada
      </div>

      <div class="label red" *ngIf="absenceRequest?.approved == false">
        Solicitud rechazada
      </div>

      <div class="label yellow" *ngIf="absenceRequest?.approved == null">
        Pendiente de aprobación
      </div>
    </div>
  </div>

  <div class="actions-row flex end">

    <div *ngIf="absenceRequest?.evidences?.length"
         class="icon-box flex align-center"
         nzTooltipColor="white"
         [nz-tooltip]="absenceRequest?.evidences?.length == 1 ? absenceRequest?.evidences?.length + ' archivo adjunto' : absenceRequest?.evidences?.length + ' archivos adjuntos'">
      <span class="count">{{absenceRequest?.evidences?.length}}</span> <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
    </div>

    <div class="icon-box" (click)="openAbsenceRequest()" nz-tooltip="Abrir solicitud" nzTooltipColor="white" >
      <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
    </div>
  </div>
</div>
