import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PayrollService} from "../../../core/services/payroll.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../core/models/user";
import {Subject} from "rxjs";
import {MemberService} from "../../../core/services/member.service";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {Paycheck} from "../../../core/models/paycheck";

@Component({
  selector: 'app-add-paycheck-modal',
  templateUrl: './add-paycheck-modal.component.html',
  styleUrls: ['./add-paycheck-modal.component.scss']
})
export class AddPaycheckModalComponent implements OnInit, OnDestroy {
  @Input() payrollUid: string;
  payrollForm: FormGroup;
  isLoading = false;
  error: string | null;
  members: User[] | null = [];
  membersLoading = true;
  $onDestroy: Subject<void> = new Subject<void>();
  stopLoading = false;
  searchIndex = 1;
  substring: string | null;
  $searchChange: Subject<string> = new Subject<string>();
  firstSearch = true;

  constructor(private payrollService: PayrollService,
              private memberService: MemberService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.subscribeToSearch();
    this.loadMembers();
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();

    this.$searchChange.next();
    this.$searchChange.complete();
  }

  close() {
    this.modalRef.close(null);
  }

  loadMembers() {
    if (!this.stopLoading) {
      this.membersLoading = true;
      this.memberService.getMembers({
        substring: this.substring,
        skip: this.searchIndex,
        unassigned: false,
        limit: 10,
        order: 'desc',
        sort: "number_id"
      })
        .pipe(
          finalize(() => {
            this.membersLoading = false;
          })
        )
        .subscribe( (res) => {
          if (res?.employees?.length) {
            this.members = [...(this.members || []), ...res.employees];
          } else {
            this.stopLoading = true;
          }
        });
      this.searchIndex++;
    }
  }

  substringSearch($event: any): void {
    this.$searchChange.next($event);
  }

  searchChanged($event: string) {
    if (!this.firstSearch) {
      this.stopLoading = false;
      this.members = [];
      this.substring = $event === "" ? null : $event;
      this.searchIndex = 1;
      this.loadMembers();
    }
    this.firstSearch = false;
  }

  addMember() {
    if (this.payrollForm.valid) {
      this.isLoading = true;
      this.payrollService.addMember(this.payrollForm.getRawValue())
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((paycheck: Paycheck) => {
          this.notification.create('success', '¡Operación exitosa!', 'Colaborador agregado correctamente');
          this.modalRef.close(paycheck);
        }, error => {
          this.notification.create('error', 'Error al agregar el colaborador', error.message.replace("GraphQL error:", "").trim());
        });
    } else  {
      this.formHelper.markFormAsDirty(this.payrollForm);
    }
  }

  private createForm() {
    this.payrollForm = this.formBuilder.group({
      payrollUid: [this.payrollUid],
      employeeUid: [null, Validators.required]
    });
  }

  private subscribeToSearch() {
    this.$searchChange
      .pipe(takeUntil(this.$onDestroy), debounceTime(400))
      .subscribe(search => {
        this.searchChanged(search);
      });
  }

}
