import {Component, Input, OnInit} from '@angular/core';
import {Attendance} from '../../../core/models/attendance';
import {User} from '../../../core/models/user';

@Component({
  selector: 'app-member-not-on-time-table',
  templateUrl: './member-not-on-time-table.component.html',
  styleUrls: ['./member-not-on-time-table.component.scss']
})
export class MemberNotOnTimeTableComponent implements OnInit {
  @Input() attendances: Attendance[] | undefined;
  @Input() member: User | null;
  index: number = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
