import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Transaction} from "../../../core/models/transaction";
import {lastDayOfMonth} from "date-fns";

@Component({
  selector: 'app-balance-table',
  templateUrl: './balance-table.component.html',
  styleUrls: ['./balance-table.component.scss']
})
export class BalanceTableComponent implements OnInit, OnChanges {
  @Input() transactions: Transaction[] | null;
  @Input() isLoading: boolean;
  @Input() month: string;
  @Input() year: string;
  total = 0;
  lastDayOfMonth: Date;

  constructor() { }

  ngOnInit(): void {
    this.setTotal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && this.isLoading) {}
    if (changes.month && this.month || changes.year && this.year) {
      const month = +((+this.month) + 1);
      const date = this.year + '-' + (month < 10 ? ('0' + month) : month) + '-05';
      const correctDate = new Date(date);
      correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
      const today = new Date();
      if (this.month === today.getMonth().toString() && this.year === today.getFullYear().toString()) {
        this.lastDayOfMonth = today;
      } else {
        this.lastDayOfMonth = lastDayOfMonth(correctDate);
      }
    }
    if (changes.transactions && this.transactions) {
      this.setTotal();
    }
  }

  private setTotal() {
    // @ts-ignore
    this.total = this.transactions[this.transactions?.length - 1].balance;
  }
}
