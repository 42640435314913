<div class="map-box-meet">
  <agm-map [latitude]="center.latitude"
           [longitude]="center.longitude"
           (mapClick)="onClick($event)"
           [minZoom]="2"
           [disableDefaultUI]="true"
           [(zoom)]="zoom">
    <agm-marker
          [animation]="'DROP'"
          [iconUrl]="icon"
          *ngIf="pin"
          [latitude]="pin.latitude"
          [longitude]="pin.longitude"></agm-marker>

    <ng-container *ngIf="userPins !== null">
      <agm-marker *ngFor="let userPin of userPins"
        [animation]="'DROP'"
        [iconUrl]="userPin.icon"
        [latitude]="userPin.latitude"
        [longitude]="userPin.longitude"></agm-marker>
    </ng-container>

    <agm-circle
          (circleClick)="onClick($event)"
          [strokeColor]="'#435C7A'"
          [strokeOpacity]=".8"
          [strokeWeight]="2"
          [fillColor]="'#435C7A'"
          [fillOpacity]=".25"
          *ngIf="meters && pin"
          [longitude]="pin.longitude"
          [latitude]="pin.latitude"
          [radius]="meters"></agm-circle>
  </agm-map>
</div>
