import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../core/models/user';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CompanyModulesService} from '../../../core/services/company-modules.service';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {PrivilegesService} from '../../../core/services/privileges.service';

@Component({
  selector: 'app-privileges-row',
  templateUrl: './privileges-row.component.html',
  styleUrls: ['./privileges-row.component.scss']
})
export class PrivilegesRowComponent implements OnInit, OnDestroy {
  @Input() member: User;

  memberForm: FormGroup;

  $onDestroy: Subject<void> = new Subject<void>();

  $searchChange: Subject<void> = new Subject<void>();

  constructor(
      private formBuilder: FormBuilder,
      private companyModulesService: CompanyModulesService,
      private privilegesService: PrivilegesService
  ) { }

  ngOnInit(): void {
    this.memberForm = this.formBuilder.group({
      employeesUid: [[this.member.uid]],
      availableModules: [this.member.availableModules?.map(module => {
        return this.companyModulesService.companyModules.find(el => el.name == module)
      })]
    });

    this.memberForm.controls.availableModules.valueChanges.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(value => {
      this.$searchChange.next();
    });

    this.$searchChange
        .pipe(takeUntil(this.$onDestroy), debounceTime(1000))
        .subscribe(() => {
          this.updatePrivileges();
        });
  }

  getFormControl(): FormControl {
    return this.memberForm.controls.availableModules as FormControl;
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();

    this.$searchChange.next();
    this.$searchChange.complete();
  }

  private updatePrivileges() {
    const data = this.memberForm.getRawValue();
    data.availableModules = data.availableModules.map((el: { id: any; }) => el.id);
    this.privilegesService.updateEmployeePrivileges(data)
        .pipe()
        .subscribe(res => {

        }, error => {

        })
  }
}
