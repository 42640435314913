import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {GraphQLModule} from './modules/graphql.module';
import {AuthenticatedGuard} from './guards/authenticated.guard';
import {SharedModule} from '../shared/shared.module';
import {NotAuthenticationGuard} from './guards/not-authenticated.guard';
import {TitleModule} from './modules/title.module';
import {NavigationModule} from './modules/navigation.module';



@NgModule({
  declarations: [],
  providers: [
      AuthenticatedGuard,
      NotAuthenticationGuard,
      DatePipe
  ],
  imports: [
    CommonModule,
    GraphQLModule,
    SharedModule,
    TitleModule,
    NavigationModule
  ]
})
export class CoreModule { }
