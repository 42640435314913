import { Injectable } from '@angular/core';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(private datePipe: DatePipe) { }

  getFullDayHours(interval: number) {
    const dayHours = [];
    let hour = 0;
    let minutes = 0;
    let index = 0;
    while (hour < 24) {
      const hourString = (hour < 10 ? ('0' + hour) : hour) + ':' + (minutes < 10 ? ('0' + minutes) : minutes);
      dayHours.push({index: index, hour: hourString, blocked: false});
      if ((minutes + interval) >= 60) {
        hour++;
        minutes = 0;
      } else {
        minutes += interval;
      }
      index++;
    }
    return dayHours;
  }
}
