import {Component, Input, OnInit} from '@angular/core';
import {NonAttendance} from '../../../core/models/non-attendance';

@Component({
  selector: 'app-member-non-attendance-table',
  templateUrl: './member-non-attendance-table.component.html',
  styleUrls: ['./member-non-attendance-table.component.scss']
})
export class MemberNonAttendanceTableComponent implements OnInit {
  @Input() nonAttendances: NonAttendance[] | undefined;
  index: number = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
