import {NgModule} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {ApolloLink, from, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from '../../../environments/environment';
import {onError} from '@apollo/client/link/error';
import {HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../services/authentication.service';

const uri = environment.apiUrl + '/graphql/'; // <-- add the URL of the GraphQL server here

@NgModule()
export class GraphQLModule {

  constructor(
      apollo: Apollo,
      httpLink: HttpLink,
      private authService: AuthenticationService
  ) {
    const link = httpLink.create({uri: uri});

    const authMiddleware = new ApolloLink((operation, forward) => {
      if (this.authService.credentials) {
        operation.setContext({
          headers: new HttpHeaders().set(
              "Authorization",
              "JWT " + this.authService.credentials.authToken
          )
        });
      }
      return forward(operation);
    });

    const errorLink = onError(({networkError, response, operation}) => {
      if (networkError) {
        networkError.message = 'Verifica tu conexión a Internet';
      }
      response?.errors?.forEach(error => {
        if (error.message === "El Token no es válido" || error.message == "Un Token es requerido para poder hacer esta petición") {
          if (operation.operationName != 'verifyToken') {
            this.authService.logout();
          }
          // @ts-ignore
          response?.errors = null;
        }
      })
    });

    apollo.create({
      link: errorLink.concat(from([authMiddleware, link])),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {fetchPolicy: "no-cache"},
        query: {fetchPolicy: "no-cache"}
      }
    });
  }
}
