import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Payroll} from "../../../core/models/payroll";
import {Router} from "@angular/router";
import {PayrollService} from "../../../core/services/payroll.service";
import {environment} from "../../../../environments/environment";
import {finalize} from "rxjs/operators";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-payroll-row',
  templateUrl: './payroll-row.component.html',
  styleUrls: ['./payroll-row.component.scss']
})
export class PayrollRowComponent implements OnInit {
  @Input() payroll: Payroll | undefined;
  @Output() removePayroll = new EventEmitter<void>();
  isDeleting = false;
  isDownloading = false;
  isDownloadingCFDIS = false;

  constructor(private router: Router,
              private payrollService: PayrollService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
  }

  downloadPayroll() {
    this.isDownloading = true;
    const params = {"payroll_uid": this.payroll?.uid};
    this.payrollService.downloadFile(environment.apiUrl + '/download_paychecks/', params)
      .pipe(finalize(() => {
        this.isDownloading = false;
      }))
      .subscribe(data => {
        saveAs(
          data.body,
          data.headers
            .get("Content-Disposition")
            .replace("attachment;filename=", "")
        );
      }, error => {
        this.notification.create('error', 'Error al descargar el archivo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  downloadPayrollCFDIS() {
    this.isDownloadingCFDIS = true;
    this.payrollService.downloadFile2(this.payroll?.CFDIInvoice?.zipFileUrl)
      .pipe(finalize(() => {
        this.isDownloadingCFDIS = false;
      }))
      .subscribe(data => {
        saveAs(data, this.payroll?.CFDIInvoice?.zipFileName);
      }, error => {
        this.notification.create('error', 'Error al descargar el archivo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  deletePayroll() {

  }

  goToDetails() {
    this.router.navigate(['/home/payroll/' + this.payroll?.uid]).then();
  }
}
