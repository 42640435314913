<div class="geo-fencing-details">
  <app-geo-fencing-map [meters]="geoFencing?.rangeMeters" [marker]="marker"></app-geo-fencing-map>

  <div class="geofencing-container">
    <div class="space-container">
      <div class="title">Área de geocerca:</div>
      <div class="value">{{ geoFencing?.rangeMeters }} {{ geoFencing?.rangeMeters === 1 ? 'mt' : 'mts' }}</div>
    </div>
  </div>

  <div class="address-container">
    <i nz-icon nzType="icons:location-pin"></i>
    <div class="address">{{ geoFencing?.fullAddress }}</div>
  </div>

  <div class="teams-container">
    <div class="name">Equipos de trabajo</div>

    <div class="teams-box">
      <div class="teams-row flex wrap align-center">
        <div class="team-box"
             nzTooltipColor="white"
             [nz-tooltip]="(team.name | titlecase)"
             *ngFor="let team of (geoFencing?.teams)">
          <app-team-icon [size]="28" [icon]="team.icon?.url"  [color]="team.color"></app-team-icon>
        </div>
        <div class="announcement-extra flex align-center" *ngIf="(geoFencing?.teams?.length || 0) <= 0">
          <i class="empty-icon" nz-icon nzType="icons:empty" nzTheme="outline" style="margin-right: 6px;"></i> Sin equipo asignado
        </div>
      </div>
    </div>
  </div>
</div>
