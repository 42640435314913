import {Component, Input, OnInit} from '@angular/core';
import {Position} from "../../../core/models/position";
import {PositionService} from "../../../core/services/position.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.component.html',
  styleUrls: ['./position-details.component.scss'],
})
export class PositionDetailsComponent implements OnInit {
  @Input() position: Position | null;
  isLoading = true;
  error: string | null;

  constructor(private positionService: PositionService) { }

  ngOnInit(): void {
    this.getDetails();
  }

  private getDetails() {
    this.isLoading = true;
    // @ts-ignore
    this.positionService.getPosition(this.position.uid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((position: Position | null) => {
        this.position = position;
      }, error => {
        this.error = error;
      });
  }

}
