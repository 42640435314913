import {Component, HostListener, Input, OnInit} from '@angular/core';
import {MassiveLoadService} from "../../../core/services/massive-load.service";
import {environment} from "../../../../environments/environment";
import {finalize} from "rxjs/operators";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-massive-load-modal',
  templateUrl: './massive-load-modal.component.html',
  styleUrls: ['./massive-load-modal.component.scss']
})
export class MassiveLoadModalComponent implements OnInit {
  @Input() type: string;
  isLoading: boolean;
  isDownloading: boolean;
  step = 0;
  file: any | null = null;
  screenWidth: number;
  screenHeight: number;
  vertical: boolean = false;

  @HostListener("window:resize", ["$event"]) onResize(event: any) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.vertical = this.screenWidth <= 612;
  }

  constructor(private massiveLoadService: MassiveLoadService,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.vertical = this.screenWidth <= 612;
  }

  close() {
    if (this.file !== null) {
      this.modalRef.close(true);
    } else {
      this.modalRef.close();
    }
  }

  next() {
    this.step += 1;
  }

  back() {
    this.step -= 1;
  }

  downloadTemplate() {
    this.isDownloading = true;
    let url = '';
    switch (this.type) {
      case 'employees':
        url = '/download_employees_template/';
        break;
      case 'positions':
        url = '/download_employees_positions_template/';
        break;
      case 'addresses':
        url = '/download_employees_addresses_template/';
        break;
    }
    this.massiveLoadService.downloadFile(environment.apiUrl + url)
      .pipe(finalize(() => {
        this.isDownloading = false;
      }))
      .subscribe(data => {
        saveAs(
          data.body,
          data.headers
            .get("Content-Disposition")
            .replace("attachment;filename=", "")
        );
        this.next();
      }, error => {
        this.notification.create('error', 'Error al descargar el template', `<pre>` + error.message.replace("GraphQL error:", "").trim() + `</pre>`);
      });
  }

  uploadDocument($event: any) {
    this.isLoading = true;
    const file: File = $event.target.files[0];
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], file.name, { type: file.type });
    switch (this.type) {
      case 'employees':
        this.massiveLoadService.uploadFileEmployees(newFile)
          .pipe(finalize(() => {
            this.isLoading = false;
          }))
          .subscribe((res: any) => {
            if (res.errors && res.errors.length > 0) {
              this.notification.create('error', 'Error al subir documento', `<pre>` + res.errors[0].message.replace("GraphQL error:", "").trim() + `</pre>`, {nzDuration: 6000});
            } else {
              this.file = {name: file.name};
              this.next();
              this.notification.create('success', '¡Operación exitosa!', res.data.createMultipleEmployees.message);
            }
          }, error => {
            this.notification.create('error', 'Error al subir documento', `<pre>` + error.message.replace("GraphQL error:", "").trim() + `</pre>`);
          });
        break;
      case 'positions':
        this.massiveLoadService.uploadFilePositions(newFile)
          .pipe(finalize(() => {
            this.isLoading = false;
          }))
          .subscribe((res: any) => {
            if (res.errors && res.errors.length > 0) {
              this.notification.create('error', 'Error al subir documento', `<pre>` + res.errors[0].message.replace("GraphQL error:", "").trim() + `</pre>`, {nzDuration: 6000});
            } else {
              this.file = {name: file.name};
              this.next();
              this.notification.create('success', '¡Operación exitosa!', res.data.createMultiplePositions.message);
            }
          }, error => {
            this.notification.create('error', 'Error al subir documento',`<pre>` + error.message.replace("GraphQL error:", "").trim() + `</pre>`);
          });
        break;
      case 'addresses':
        this.massiveLoadService.uploadFileAddresses(newFile)
          .pipe(finalize(() => {
            this.isLoading = false;
          }))
          .subscribe((res: any) => {
            if (res.errors && res.errors.length > 0) {
              this.notification.create('error', 'Error al subir documento', `<pre>` + res.errors[0].message.replace("GraphQL error:", "").trim() + `</pre>`, {nzDuration: 6000});
            } else {
              this.file = {name: file.name};
              this.next();
              this.notification.create('success', '¡Operación exitosa!', res.data.createMultipleGeoFencings.message);
            }
          }, error => {
            this.notification.create('error', 'Error al subir documento', `<pre>` + error.message.replace("GraphQL error:", "").trim() + `</pre>`);
          });
        break;
    }
  }
}
