import {
  Component,
  Input,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {Icon} from '../../../core/models/icon';

@Component({
  selector: 'app-icon-box',
  templateUrl: './icon-box.component.html',
  styleUrls: ['./icon-box.component.scss']
})
export class IconBoxComponent implements OnInit {
  @Input() icon: Icon;
  @Input() size = 30;
  @Input() isImage = false;

  constructor() { }

  ngOnInit(): void {

  }
}
