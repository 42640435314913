import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GeoFencing} from "../../../core/models/geo-fencing";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {combineLatest, Observable, Subject} from "rxjs";
import {Country} from "../../../core/models/country";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {CountryStore} from "../../../core/stores/country.store";
import {AddressService} from "../../../core/services/address.service";
import {GeoFencingService} from "../../../core/services/geo-fencing.service";
import {debounceTime, finalize, startWith, takeUntil} from "rxjs/operators";
import {Marker} from "../../../core/models/marker";
import {Address} from "../../../core/models/address";

@Component({
  selector: 'app-edit-geo-fencing-modal',
  templateUrl: './edit-geo-fencing-modal.component.html',
  styleUrls: ['./edit-geo-fencing-modal.component.scss']
})
export class EditGeoFencingModalComponent implements OnInit, OnDestroy {
  @Input() geofencing: GeoFencing | null = null;
  marker: any;
  editGeoFencing: FormGroup;
  zipcodeLoading: boolean = false;
  zipcodeError: boolean = false;

  colonies: any[] = [];
  addressLoading: boolean;
  addressError: string | null;

  $onDestroy: Subject<void> = new Subject<void>();
  countries$: Observable<Country[] | undefined> = this.countryStore.getCountries();

  @Output() geoFencingEdited: EventEmitter<GeoFencing> = new EventEmitter<GeoFencing>();
  isLoading: boolean;
  error: string | null;

  constructor(
    private modalRef: NzModalRef,
    private formBuilder: FormBuilder,
    private formHelper: FormHelperService,
    private countryStore: CountryStore,
    private addressService: AddressService,
    private geoFencingService: GeoFencingService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  destroyModal() {
    this.modalRef.close();
  }

  submitForm() {
    if (this.editGeoFencing.valid) {
      this.isLoading = true;
      this.error = null;
      this.geoFencingService.updateGeoFencing(this.editGeoFencing.getRawValue())
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        ).subscribe(
        res => {
          this.geoFencingEdited.emit(res);
          this.modalRef.close();
        }, error => {
          this.error = error;
        }
      );
    } else {
      this.formHelper.markFormAsDirty(this.editGeoFencing);
      this.formHelper.markGroupAsDirty(this.editGeoFencing, 'addressData');
    }
  }

  onMarkerChange($event: Marker) {
    this.getAddressForm().controls.latitude.setValue($event.latitude);
    this.getAddressForm().controls.longitude.setValue($event.longitude);
  }

  getAddressForm(): FormGroup {
    return this.editGeoFencing.controls.addressData as FormGroup;
  }

  private createForm() {
    this.editGeoFencing = this.formBuilder.group({
      geoFencingUid: [this.geofencing?.uid],
      name: [this.geofencing ? this.geofencing?.name : null, [Validators.required]],
      rangeMeters: [this.geofencing ? this.geofencing?.rangeMeters : 300, [Validators.required]],
      remoteExclusive: [this.geofencing ? this.geofencing?.remoteExclusive : false],
      addressData: this.formBuilder.group({
        latitude: [this.geofencing ? this.geofencing?.address?.latitude : null],
        longitude: [this.geofencing ? this.geofencing?.address?.longitude : null],
        country: [null, [Validators.required]],
        state: [this.geofencing ? this.geofencing?.address?.state : null, [Validators.required]],
        colony: [null, [Validators.required]],
        city: [this.geofencing ? this.geofencing?.address?.city : null, [Validators.required]],
        zipcode: [this.geofencing ? this.geofencing?.address?.zipcode : null, [Validators.required]],
        street: [this.geofencing ? this.geofencing?.address?.street : null, [Validators.required]],
        interiorNumber: [this.geofencing ? this.geofencing?.address?.interiorNumber : null],
        exteriorNumber: [this.geofencing ? this.geofencing?.address?.exteriorNumber : null, [Validators.required]]
      })
    });

    this.getAddressForm().controls.zipcode.valueChanges
      .pipe(
        takeUntil(this.$onDestroy),
        debounceTime(500)
      ).subscribe(zipcode => {
      if (zipcode && this.getAddressForm().controls.country.value) {
        this.getColonies(zipcode, this.getAddressForm().controls.country.value);
      }
    });

    this.getAddressForm().controls.country.valueChanges
      .pipe(
        takeUntil(this.$onDestroy)
      ).subscribe(country => {
      if (country && this.getAddressForm().controls.zipcode.value) {
        this.getColonies(this.getAddressForm().controls.zipcode.value, country);
      }
    });

    combineLatest([
      this.getAddressForm().controls.country.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.state.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.colony.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.city.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.zipcode.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.street.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.interiorNumber.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.exteriorNumber.valueChanges.pipe(startWith(null))
    ])
      .pipe(
        takeUntil(this.$onDestroy),
        debounceTime(500)
      ).subscribe(
      (changes) => {
        if (this.getAddressForm().valid) {
          this.getCoordinates();
          if (this.geofencing === null) {
            this.getCoordinates();
          } else {
            if (this.geofencing?.address?.latitude !== 0 && this.geofencing?.address?.longitude !== 0) {
              this.marker = {
                latitude: this.geofencing?.address?.latitude,
                longitude: this.geofencing?.address?.longitude
              };
            } else {
              this.getCoordinates();
            }
          }
        }
      });

    if (this.geofencing !== null) {
      this.getCountry();
    }
  }

  private getCountry() {
    this.countries$.subscribe(countries => {
      if (countries) {
        const index = countries.findIndex(c => c.countryName === this.geofencing?.address?.country);
        if (index !== -1) {
          this.getAddressForm().controls.country.setValue(countries[index].countryCode);
        } else {
          this.getAddressForm().controls.country.setValue('MX');
        }
        if (this.geofencing !== null) {
          if (this.getAddressForm().controls.zipcode.value && this.getAddressForm().controls.country.value) {
            this.getColonies(this.getAddressForm().controls.zipcode.value, this.getAddressForm().controls.country.value);
          }
        }
      }
    });
  }

  private setAddress(address: Address | null) {
    if (address) {
      this.zipcodeError = false;
      this.colonies = address.colony;
      if (this.geofencing === null) {
        this.getAddressForm().controls.state.setValue(address.state);
        this.getAddressForm().controls.city.setValue(address.city);
        this.getAddressForm().controls.colony.setValue(address.colony[0]);
      } else {
        const index = address.colony.findIndex((c: string) => c === this.geofencing?.address?.colony);
        if (index !== -1) {
          this.getAddressForm().controls.colony.setValue(address.colony[index]);
        } else {
          this.getAddressForm().controls.colony.setValue(address.colony[0]);
        }
      }
    } else {
      this.zipcodeError = true;
      this.colonies = [];
      this.getAddressForm().controls.colony.setValue(null);
      this.getAddressForm().controls.state.setValue(null);
      this.getAddressForm().controls.city.setValue(null);
    }
  }

  private getColonies(zipcode: string, country: string) {
    this.zipcodeLoading = true;
    this.zipcodeError = false;
    this.addressService.getAddressByZipcode(zipcode, country)
      .pipe(
        finalize(() => {
          this.zipcodeLoading = false;
        })
      )
      .subscribe(
        address => {
          this.setAddress(address);
        }
      )
  }

  private getCoordinates() {
    this.addressLoading = true;
    this.addressError = null;
    this.addressService.getCoordinates(this.getAddressForm().getRawValue())
      .pipe(
        finalize(() => {
          this.addressLoading = false;
        })
      ).subscribe(coordinates => {
      this.marker = coordinates ? coordinates : null;
      if (coordinates) {
        this.getAddressForm().controls.latitude.setValue(coordinates.latitude);
        this.getAddressForm().controls.longitude.setValue(coordinates.longitude);
      }
    }, error => {
      this.addressError = error;
    })
  }
}
