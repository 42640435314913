import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Meeting} from "../../../core/models/meeting";
import {MeetingsService} from "../../../core/services/meetings.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize} from "rxjs/operators";
import {AddressService} from "../../../core/services/address.service";
import {User} from "../../../core/models/user";
import {isToday, isBefore} from 'date-fns';

@Component({
  selector: 'app-meeting-details-modal',
  templateUrl: './meeting-details-modal.component.html',
  styleUrls: ['./meeting-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MeetingDetailsModalComponent implements OnInit {
  @Input() meeting: Meeting;
  isLoading = true;
  error: string | null;
  marker: any;
  userPins: any[] | null = null;
  addressLoading = true;

  constructor(private meetingsService: MeetingsService,
              private addressService: AddressService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.getMeetingInfo();
  }

  close() {
    this.drawerRef.close();
  }

  goToLink(hangoutLink: string) {
    window.open(hangoutLink);
  }

  isPastDate() {
    const today = new Date();
    // @ts-ignore
    const correctDate = new Date(this.meeting.startDate);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());

    if (isBefore(correctDate, today) || isToday(correctDate)) {
      const hour = today.getHours().toString();
      const minutes = today.getMinutes().toString();
      // @ts-ignore
      const checkHour = this.meeting?.startHour?.split(':')[0];
      // @ts-ignore
      const checkMinutes = this.meeting?.startHour?.split(':')[1];
      // @ts-ignore
      if (hour >= checkHour) {
        // @ts-ignore
        return minutes > checkMinutes;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private getMeetingInfo() {
    this.isLoading = true;
    this.meetingsService.getMeeting(this.meeting.uid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((meeting: Meeting) => {
        if (!meeting?.fromGoogleCalendar && meeting?.place && meeting.place?.address) {
          meeting.place.address.fullAddress = this.addressService.concatAddress(meeting.place.address);
        }
        const pins: any[] = [];
        meeting.participants?.forEach((user: User) => {
          if (user?.meetingAttendance?.timestamp) {
            // @ts-ignore
            user?.meetingAttendance?.correctTimestamp = new Date(user?.meetingAttendance?.timestamp.slice(0,-6));
          }
          if (user.meetingAttendance?.longitude && user?.meetingAttendance?.latitude) {
            pins.push({
              icon: {
                url: user.photo ? user.photo.url : "../assets/images/user-pin.png",
                scaledSize: {
                  width: 30,
                  height: 30
                }
              },
              latitude: user.meetingAttendance.latitude,
              longitude: user.meetingAttendance.longitude
            });
          }
        });
        this.userPins = pins.length > 0 ? pins : null;
        this.marker = {
          latitude: this.meeting.place?.latitude,
          longitude: this.meeting.place?.longitude
        };
        this.addressLoading = false;
        this.meeting = meeting;
      }, error => {
        this.error =  error.message.replace("GraphQL error:", "").trim();
      });
  }

  /*private getCoordinates() {
    this.addressLoading = true;
    this.addressService.getCoordinates({
      city: this.meeting?.place?.address?.city,
      colony: this.meeting?.place?.address?.colony,
      country: this.meeting?.place?.address?.country,
      exteriorNumber: this.meeting?.place?.address?.exteriorNumber,
      interiorNumber: this.meeting?.place?.address?.interiorNumber,
      longitude: this.meeting?.place?.address?.longitude,
      latitude: this.meeting?.place?.address?.latitude,
      state: this.meeting?.place?.address?.state,
      street: this.meeting?.place?.address?.street,
      zipcode: this.meeting?.place?.address?.zipcode,
    })
      .pipe(
        finalize(() => {
          this.addressLoading = false;
        })
      ).subscribe(coordinates => {
      this.marker = coordinates ? coordinates : null;
    }, error => {
      this.error =  error.message.replace("GraphQL error:", "").trim();
    })
  }*/
}
