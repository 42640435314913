<div class="team-geo-locations-card" [ngClass]="showMore ? 'active' : 'close'">
  <div class="topo-container">
    <div class="title-container" (click)="showMore = !showMore">
      <i nz-icon nzType="down" *ngIf="!showMore"></i>
      <i nz-icon nzType="up" *ngIf="showMore"></i>
      <div class="title">Ubicaciones ({{ cluster?.length > 0 ? cluster?.length : geolocations?.length }})</div>
    </div>

    <div class="filters-container" *ngIf="cluster?.length > 0">
      <div class="clean" (click)="cleanCluster()">Limpiar grupo</div>
    </div>

    <div class="filters-container" *ngIf="cluster?.length === 0 && selectedLocationActive">
      <div class="clean" (click)="cleanSelected()">Limpiar filtro</div>
    </div>
  </div>

  <div class="location-container">
    <ng-container *ngIf="cluster?.length === 0">
      <div class="location-box" [ngClass]="{'location-active': location?.selected}"
           *ngFor="let location of geolocations; let i = index;" (click)="locationClick(i, location.selected, false)">
        <div class="profile-box">
          <nz-avatar [nzGap]="6" [nzSize]="30" [nzText]="location?.user ? (location?.user?.firstName?.charAt(0) + '' + location?.user?.lastName?.charAt(0)) : undefined" [nzSrc]="location?.user?.photo?.url"></nz-avatar>
        </div>

        <div class="location-info-box">
           <div class="box-container">
             <div class="profile-info-box">
               <div class="name">{{ location?.user?.firstName }} {{ location?.user?.lastName }}</div>
               <div class="email">{{ location?.user?.email }}</div>
             </div>
             <div class="schedule-type">{{ location?.user?.scheduleType }}</div>
           </div>

          <div class="table-box" [ngClass]="location?.selected ? 'active-table' : 'close-table'">
            <table>
              <tr>
                <td class="turn-cell" *ngFor="let schedule of location?.user.calendarSchedules">
                  <ng-container *ngIf="schedule.turns?.length && !schedule.absenceRequests?.length && !schedule?.festivityDays?.length">
                    <div class="turns-column flex column align-center center">
                      <div class="turn-box" *ngFor="let turn of schedule.turns">
                        <app-turn-box [member]="location?.user" [turn]="turn" [isPastDate]="isBefore(schedule.date)" [isToday]="isToday(schedule.date)"></app-turn-box>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="schedule.absenceRequests?.length">
                    <ng-container *ngFor="let absenceRequest of schedule.absenceRequests | slice: 0 : 1">
                      <div class="turns-column flex column align-center center">
                        <div class="turn-box">
                          <app-absence-request-box [member]="location?.user" [absenceRequest]="absenceRequest"></app-absence-request-box>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="schedule?.festivityDays?.length && !schedule.absenceRequests?.length">
                    <div class="category-container flex">
                      <div class="category-box flex align-center" *ngFor="let festivityDay of schedule.festivityDays">

                        <div class="background" [ngStyle]="{backgroundColor: festivityDay?.colour}"></div>

                        <div class="icon">
                          <i nz-icon nzType="icons:party-fill" [ngStyle]="{color: festivityDay?.colour}" nzTheme="fill"></i>
                        </div>

                        <div class="type" [ngStyle]="{color: festivityDay?.colour}">
                          {{ festivityDay?.name | titlecase }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!schedule.turns?.length && !schedule.absenceRequests?.length && !schedule?.festivityDays?.length">
                    <div class="label flex center align-center">
                      Ausente
                    </div>
                  </ng-container>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="cluster?.length > 0">
      <div class="location-box" [ngClass]="{'location-active': location?.selected}"
           *ngFor="let location of cluster; let i = index;" (click)="locationClick(i, location.selected, true)">
        <div class="profile-box">
          <nz-avatar [nzGap]="6" [nzSize]="30" [nzText]="location?.user ? (location?.user?.firstName?.charAt(0) + '' + location?.user?.lastName?.charAt(0)) : undefined" [nzSrc]="location?.user?.photo?.url"></nz-avatar>
        </div>

        <div class="location-info-box">
          <div class="box-container">
            <div class="profile-info-box">
              <div class="name">{{ location?.user?.firstName }} {{ location?.user?.lastName }}</div>
              <div class="email">{{ location?.user?.email }}</div>
            </div>

            <div class="schedule-type">{{ location?.user?.scheduleType }}</div>
          </div>

          <div class="table-box" [ngClass]="location?.selected ? 'active-table' : 'close-table'">
            <table>
              <tr>
                <td class="turn-cell" *ngFor="let schedule of location?.user.calendarSchedules">
                  <ng-container *ngIf="schedule.turns?.length && !schedule.absenceRequests?.length && !schedule?.festivityDays?.length">
                    <div class="turns-column flex column align-center center">
                      <div class="turn-box" *ngFor="let turn of schedule.turns">
                        <app-turn-box [member]="location?.user" [turn]="turn" [isPastDate]="isBefore(schedule.date)" [isToday]="isToday(schedule.date)"></app-turn-box>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="schedule.absenceRequests?.length">
                    <ng-container *ngFor="let absenceRequest of schedule.absenceRequests | slice: 0 : 1">
                      <div class="turns-column flex column align-center center">
                        <div class="turn-box">
                          <app-absence-request-box [member]="location?.user" [absenceRequest]="absenceRequest"></app-absence-request-box>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="schedule?.festivityDays?.length && !schedule.absenceRequests?.length">
                    <div class="category-container flex">
                      <div class="category-box flex align-center" *ngFor="let festivityDay of schedule.festivityDays">

                        <div class="background" [ngStyle]="{backgroundColor: festivityDay?.colour}"></div>

                        <div class="icon">
                          <i nz-icon nzType="icons:party-fill" [ngStyle]="{color: festivityDay?.colour}" nzTheme="fill"></i>
                        </div>

                        <div class="type" [ngStyle]="{color: festivityDay?.colour}">
                          {{ festivityDay?.name | titlecase }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!schedule.turns?.length && !schedule.absenceRequests?.length && !schedule?.festivityDays?.length">
                    <div class="label flex center align-center">
                      Ausente
                    </div>
                  </ng-container>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
