import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GeoFencing} from '../models/geo-fencing';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';
import {Company} from '../models/company';
import {User} from '../models/user';
import {GeoFencingTable} from "../models/geo-fencing-table";

@Injectable({
  providedIn: 'root'
})
export class GeoFencingService {

  constructor(
      private apollo: Apollo
  ) { }

  getGeoFencings(): Observable<GeoFencing[] | undefined> {
    return this.apollo.query<{myCompany: Company}>({
      query: gql`
          query geoFencings {
              myCompany {
                  geoFencings {
                      uid
                      name
                      rangeMeters
                      remoteExclusive
                      address {
                          state
                          street
                          interiorNumber
                          exteriorNumber
                          zipcode
                          colony
                          city
                          colony
                          latitude
                          longitude
                      }
                  }
              }
          }
      `
    }).pipe(
        map(({data}) => {
          return data.myCompany.geoFencings;
        })
    )
  }

  createGeoFencing(geoFencing: GeoFencing): Observable<GeoFencing | undefined> {
    return this.apollo.mutate<{createGeoFencing: {geoFencing: GeoFencing}}>({
      mutation: gql`
          mutation createGeoFencing(
              $addressData: AddressInput!
              $employeeUid: String
              $name: String!
              $rangeMeters: Int!
              $teamUid: String
              $remoteExclusive: Boolean
          ) {
              createGeoFencing(
                addressData: $addressData
                employeeUid: $employeeUid
                name: $name
                rangeMeters: $rangeMeters
                teamUid: $teamUid
                remoteExclusive: $remoteExclusive
              ) {
                  geoFencing {
                      borrowedFromTeam
                      uid
                      name
                      rangeMeters
                      remoteExclusive
                      address {
                          state
                          street
                          interiorNumber
                          exteriorNumber
                          zipcode
                          colony
                          city
                          colony
                          latitude
                          longitude
                      }
                  }
              }
          }
      `,
      variables: {
        ...geoFencing
      }
    }).pipe(
        map(({data}) => {
          return data?.createGeoFencing.geoFencing;
        })
    )
  }

  updateGeoFencing(geoFencing: GeoFencing): Observable<GeoFencing | undefined> {
    return this.apollo.mutate<{updateGeoFencing: {geoFencing: GeoFencing}}>({
      mutation: gql`
        mutation updateGeoFencing(
          $addressData: AddressInput
          $geoFencingUid: String!
          $name: String!
          $rangeMeters: Int!
          $remoteExclusive: Boolean
        ) {
          updateGeoFencing(
            addressData: $addressData
            geoFencingUid: $geoFencingUid
            name: $name
            rangeMeters: $rangeMeters
            remoteExclusive: $remoteExclusive
          ) {
            geoFencing {
              borrowedFromTeam
              uid
              name
              rangeMeters
              remoteExclusive
              address {
                state
                street
                interiorNumber
                exteriorNumber
                zipcode
                colony
                city
                colony
                latitude
                longitude
              }
            }
          }
        }
      `,
      variables: {
        ...geoFencing
      }
    }).pipe(
      map(({data}) => {
        return data?.updateGeoFencing.geoFencing;
      })
    )
  }

  getUserGeoFencings(employeeUid: string | undefined): Observable<GeoFencing[] | undefined> {
    return this.apollo.query<{employee: User}>({
      query: gql`
          query geoFencings(
              $employeeUid: String!
          ) {
              employee(employeeUid: $employeeUid) {
                  geoFencings {
                      uid
                      name
                      rangeMeters
                      remoteExclusive
                      address {
                          state
                          street
                          interiorNumber
                          exteriorNumber
                          zipcode
                          colony
                          city
                          colony
                          latitude
                          longitude
                      }
                  }
              }
          }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
        map(({data}) => {
          return data.employee.geoFencings;
        })
    )
  }

  removeGeoFencing(geoFencingUid: string | undefined): Observable<string | null> {
    return this.apollo.mutate<{deleteGeoFencing: {message: string}}>({
      mutation: gql`
        mutation deleteGeoFencing(
          $geoFencingUid: String!
        ) {
          deleteGeoFencing(geoFencingUid: $geoFencingUid) {
            message
          }
        }
      `,
      variables: {
        geoFencingUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deleteGeoFencing.message;
      })
    )
  }

  getTableGeoFencings(filters: any): Observable<GeoFencingTable | undefined> {
    return this.apollo.query<{geoFencingsTable: GeoFencingTable}>({
      query: gql`
        query geoFencingsTable($substring: String $skip: Int $limit: Int) {
          geoFencingsTable(substring: $substring skip: $skip limit: $limit) {
            count
            geoFencings {
              uid
              numberId
              name
              rangeMeters
              remoteExclusive
              address {
                state
                street
                interiorNumber
                exteriorNumber
                zipcode
                colony
                city
                colony
                latitude
                longitude
              }
              teams {
                uid
                name
                color
                icon {
                  url
                }
              }
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        return data.geoFencingsTable;
      })
    )
  }

  deleteGeofencing(geoFencingUid: string | undefined): Observable<string | null> {
    return this.apollo.mutate<{deleteGeoFencing: {message: string}}>({
      mutation: gql`
        mutation deleteGeoFencing($geoFencingUid: String!) {
          deleteGeoFencing(geoFencingUid: $geoFencingUid) {
            message
          }
        }
      `,
      variables: {
        geoFencingUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deleteGeoFencing.message;
      })
    )
  }
}
