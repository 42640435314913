<div class="map-box-attendance">
  <agm-map [ngClass]="pin?.icon ? 'round-img' : ''"
           [latitude]="pin.latitude"
           [longitude]="pin.longitude"
           [minZoom]="8"
           [zoom]="8"
           [maxZoom]="16"
           [fitBounds]="true"
           [disableDefaultUI]="true">

    <ng-container *ngFor="let geoFencing of geoFencings">
      <agm-marker
            [iconUrl]="geoIcon"
            [agmFitBounds]="true"
            [longitude]="geoFencing.address?.longitude || 0"
            [latitude]="geoFencing.address?.latitude || 0"></agm-marker>

      <agm-circle
            [strokeColor]="'#435C7A'"
            [strokeOpacity]=".8"
            [strokeWeight]="2"
            [fillColor]="'#435C7A'"
            [fillOpacity]=".25"
            [longitude]="geoFencing.address?.longitude || 0"
            [latitude]="geoFencing.address?.latitude || 0"
            [radius]="geoFencing.rangeMeters || 0"></agm-circle>
    </ng-container>

    <ng-container *ngIf="pin">
      <agm-marker
            [zIndex]="100"
            [animation]="'DROP'"
            [iconUrl]="pin?.icon ? pin?.icon : icon"
            [agmFitBounds]="true"
            [longitude]="pin.longitude"
            [latitude]="pin.latitude">
      </agm-marker>
    </ng-container>
  </agm-map>
</div>
