<div class="non-on-location-box flex wrap align-center">
  <div class="number flex align-center" (click)="openAttendance()">
    <i class="empty-icon" nz-icon nzType="icons:location" nzTheme="outline"></i> <span class="label">Fuera</span> #{{attendance.numberId}}
  </div>
  <div class="date">
    {{attendance.createdDate | date : 'dd MMMM yyyy'}}
  </div>
  <div class="category">
    {{attendance.eventCategory | titlecase}}
  </div>
  <div class="timestamp flex align-center">
    <div class="timestamp-box" (click)="openAttendance()" [ngClass]="{'red': !attendance.onTime}" *ngIf="!attendance?.autoCreated">
      {{timestamp | date: 'HH:mm'}} hrs
    </div>
    <div class="timestamp-box" (click)="openAttendance()" [ngClass]="{'red': !attendance.onTime}" *ngIf="attendance?.autoCreated">
      No realizado
    </div>
  </div>
  <div class="turn flex align-center">
    <div class="turn-box">
      {{attendance.turnFromHour}} - {{attendance.turnToHour}}
    </div>
  </div>
</div>
