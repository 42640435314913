import {Component, Input, OnInit} from '@angular/core';
import {Payment} from '../../../core/models/payment';
import {FileService} from '../../../core/services/file.service';
import {finalize} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-payment-box',
  templateUrl: './payment-box.component.html',
  styleUrls: ['./payment-box.component.scss']
})
export class PaymentBoxComponent implements OnInit {
  @Input() payment: Payment;

  isLoading: boolean;

  constructor(
      private fileService: FileService,
      private messageService: NzMessageService
  ) { }

  ngOnInit(): void {

  }

  downloadInvoice() {
    this.isLoading = true;
    this.fileService.downloadFile(this.payment?.invoice?.zipFileUrl)
        .pipe(
            finalize(() => {
              this.isLoading = false;
            })
        )
        .subscribe(data => {
          saveAs(data.body, this.payment.invoice?.zipFileName);
        }, error => {
          this.messageService.create(
              'error',
              error.message.replace('GraphQL error:', '').trim()
          );
        });
  }
}
