import {Component, Input, OnInit} from '@angular/core';
import {ActiveResources} from "../../../core/models/active-resources";
import {ActiveResourcesService} from "../../../core/services/active-resources.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {finalize} from "rxjs/operators";
import {ActiveResourceLog} from "../../../core/models/active-resource-log";

@Component({
  selector: 'app-active-resource-history-modal',
  templateUrl: './active-resource-history-modal.component.html',
  styleUrls: ['./active-resource-history-modal.component.scss']
})
export class ActiveResourceHistoryModalComponent implements OnInit {
  @Input() activeResource: ActiveResources;
  isLoading = true;

  constructor(private activeResourcesService: ActiveResourcesService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    this.getLogs();
  }

  private getLogs() {
    this.isLoading = true;
    this.activeResourcesService.getActiveResourceLogs(this.activeResource.uid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((logs: ActiveResourceLog[] | undefined) => {
        this.activeResource.logs = logs;
      }, error => {
        this.notification.create('error', 'Error al obtener el historial del activo', error.message.replace("GraphQL error:", "").trim());
      });
  }

}
