import { Injectable } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apollo: Apollo) {}

  getMe(): Observable<User> {
    const getMe = gql`
        query whoami {
            whoami {
                uid
                firstName
                hasLeaderAccess
                availableModules
                company {
                    name
                    photo {
                        url
                    }
                }
                lastName
                position {
                    name
                }
                email
                hasAdminAccess
                photo {
                    url
                }
               company {
                 active
               }
            }
        }
    `;
    return this.apollo
        .query<{ whoami: User }>({
          query: getMe
        })
        .pipe(
            map(({ data }) => {
              return data.whoami;
            })
        );
  }

  deleteUser(employeeUid: string | undefined): Observable<string> {
    const deleteEmployee = gql`
      mutation deleteEmployee($employeeUid: String!) {
        deleteEmployee(employeeUid: $employeeUid) {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{ deleteEmployee: {message: string} }>({
        mutation: deleteEmployee,
        variables: {
          employeeUid
        }
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.deleteEmployee.message;
        })
      );
  }

  reactivateEmployee(employeeUid: string | undefined): Observable<string> {
    const reactivateEmployee = gql`
      mutation reactivateEmployee($employeeUid: String!) {
        reactivateEmployee(employeeUid: $employeeUid) {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{ reactivateEmployee: {message: string} }>({
        mutation: reactivateEmployee,
        variables: {
          employeeUid
        }
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.reactivateEmployee.message;
        })
      );
  }

  getMeIntegrations(): Observable<User> {
    const getMe = gql`
      query whoami {
        whoami {
          uid
          googleCalendarActive
          googleToken {
            uid
            name
            email
            profilePictureUrl
          }
        }
      }
    `;
    return this.apollo
      .query<{ whoami: User }>({
        query: getMe
      })
      .pipe(
        map(({ data }) => {
          return data.whoami;
        })
      );
  }

  getGeoLocations(): Observable<User> {
    const getMe = gql`
      query whoami {
        whoami {
          uid
          geoLocations {
            latitude
            longitude
            timestamp
            uid
          }
        }
      }
    `;
    return this.apollo
      .query<{ whoami: User }>({
        query: getMe
      })
      .pipe(
        map(({ data }) => {
          return data.whoami;
        })
      );
  }

}
