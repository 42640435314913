import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Color} from "../../../core/models/color";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import {FestivityDay} from "../../../core/models/festivity-day";
import {FestivityDaysService} from "../../../core/services/festivity-days.service";
import {ColorService} from "../../../core/services/color.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {DatePipe} from "@angular/common";
import {NzModalRef} from "ng-zorro-antd/modal";
import {finalize} from "rxjs/operators";
import {TeamService} from "../../../core/services/team.service";
import {Team} from "../../../core/models/team";
import {User} from "../../../core/models/user";

@Component({
  selector: 'app-edit-festivity-day-modal',
  templateUrl: './edit-festivity-day-modal.component.html',
  styleUrls: ['./edit-festivity-day-modal.component.scss']
})
export class EditFestivityDayModalComponent implements OnInit {
  @Input() festivityDay: FestivityDay | null;
  isLoadingTeams = true;
  editFestivityDayForm: FormGroup;
  isLoading = false;
  colors: Color[] = this.colorService.getColors();
  error: string | null;
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(new Date(), current) < 0;
  };
  nodes: any = [];

  constructor(private festivityDaysService: FestivityDaysService,
              private teamsService: TeamService,
              private colorService: ColorService,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              private notification: NzNotificationService,
              private datePipe: DatePipe,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.getTeams();
  }

  close() {
    this.modalRef.close(null);
  }

  updateFestivityDay() {
    if (this.editFestivityDayForm.valid) {
      this.isLoading = true;
      const data = this.editFestivityDayForm.getRawValue();
      this.festivityDaysService.updateFestivityDay(this.cleanData(data))
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((festivityDay: FestivityDay) => {
          this.notification.create('success', '¡Operación exitosa!','Día festivo actualizado correctamente');
          this.modalRef.close(festivityDay);
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al actualizar el día festivo', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelperService.markFormAsDirty(this.editFestivityDayForm);
    }
  }

  rangeChange($event: any) {
    this.editFestivityDayForm.controls.startDate.setValue($event[0]);
    this.editFestivityDayForm.controls.endDate.setValue($event[1]);
  }

  private createForm() {
    this.editFestivityDayForm = this.formBuilder.group({
      festivityDayUid: [this.festivityDay?.uid],
      name: [this.festivityDay?.name, Validators.required],
      colour: [this.festivityDay?.colour, Validators.required],
      startDate: [this.setDate(this.festivityDay?.startDate), Validators.required],
      endDate: [this.setDate(this.festivityDay?.endDate), Validators.required],
      excludedEmployeesUids: [this.setUids()],
      dates: [[this.setDate(this.festivityDay?.startDate), this.setDate(this.festivityDay?.endDate)], Validators.required]
    });
  }

  private setDate(date: any): Date {
    const correctDate = new Date(date);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    return correctDate;
  }

  private setUids() {
    const uids: any[] = [];
    this.festivityDay?.excludedEmployees?.forEach((employee: User) => {
      uids.push(employee.uid);
    });
    return uids;
  }

  private getTeams() {
    this.isLoadingTeams = true;
    this.teamsService.getTeamsMembers()
      .subscribe((teams: Team[]) => {
        this.createNodes(teams);
      }, error => {
        this.isLoadingTeams = false;
        this.error = error.message.replace("GraphQL error:", "").trim();
      });
  }

  private createNodes(teams: Team[]) {
    this.nodes = [];
    teams.forEach((team: Team, indexT: number) => {
      const leaves: any[] = [];
      team.members?.forEach((member: User, indexM: number) => {
        leaves.push({
          title: member.firstName + ' ' + member.lastName,
          value: member.uid,
          key: member.uid,
          isLeaf: true
        })
      });
      this.nodes.push({
        title: team.name,
        value: team.uid,
        key: team.uid,
        children: leaves
      });
    });
    this.createForm();
    this.isLoadingTeams = false;
  }

  private cleanData(data: any) {
    const form = data;
    form.startDate = this.datePipe.transform(form.startDate, 'yyyy-MM-dd');
    form.endDate = this.datePipe.transform(form.endDate, 'yyyy-MM-dd');
    const employeesUids: string[] = [];
    data.excludedEmployeesUids.forEach((key: string) => {
      const teamIndex = this.nodes.findIndex((node: any) => node.key === key);
      if (teamIndex !== -1) {
        this.nodes[teamIndex].children.forEach((leaf: any) => {
          employeesUids.push(leaf.key);
        });
      } else {
        employeesUids.push(key);
      }
    });
    form.excludedEmployeesUids = employeesUids;
    return form;
  }
}
