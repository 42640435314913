import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Meeting} from "../../../core/models/meeting";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {finalize, takeUntil} from "rxjs/operators";
import {MeetingDetailsModalComponent} from "../../modals/meeting-details-modal/meeting-details-modal.component";
import {EditMeetingModalComponent} from "../../modals/edit-meeting-modal/edit-meeting-modal.component";
import {Subject} from "rxjs";
import {MeetingsService} from "../../../core/services/meetings.service";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-meeting-row',
  templateUrl: './meeting-row.component.html',
  styleUrls: ['./meeting-row.component.scss']
})
export class MeetingRowComponent implements OnInit, OnDestroy {
  @Input() meeting: Meeting | undefined;
  @Output() removeMeeting = new EventEmitter<Meeting>();
  $onDestroy: Subject<void> = new Subject<void>();
  isDeleting = false;

  constructor(private meetingsService: MeetingsService,
              private notification: NzNotificationService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  editMeeting() {
    const modal = this.drawerService.create({
      nzContent: EditMeetingModalComponent,
      nzTitle: 'Editar junta',
      nzWidth: 504,
      nzContentParams: {
        meeting: this.meeting
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((meeting: Meeting) => {
      if (meeting) {
        this.meeting = meeting;
      }
    })
  }

  deleteMeeting() {
    this.isDeleting = true;
    this.meetingsService.deleteMeeting(this.meeting?.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string | undefined) => {
        this.notification.create('success', '¡Operación exitosa!', 'Junta eliminada correctamente');
        this.removeMeeting.error(this.meeting);
      }, error => {
        this.notification.create('error', 'Error al eliminar la junta', error.message.replace("GraphQL error:", "").trim());
      });
  }

  openMeeting() {
    const modal = this.drawerService.create({
      nzContent: MeetingDetailsModalComponent,
      nzTitle: '',
      nzWidth: 504,
      nzWrapClassName: 'meeting-details-wrap',
      nzClosable: false,
      nzContentParams: {
        meeting: this.meeting
      }
    });
  }

  goToLink(hangoutLink: string) {
    window.open(hangoutLink);
  }
}
