import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() content: TemplateRef<any>;
  @Input() marginLeft: string | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
