<div class="create-festivity-day-modal-component">
  <nz-spin [nzSpinning]="isLoadingTeams">
    <div class="error-box" *ngIf="error && !isLoadingTeams">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <form nz-form nzLayout="vertical" [formGroup]="newFestivityDayForm" *ngIf="!isLoadingTeams && newFestivityDayForm && !error">

      <nz-form-item>
        <nz-form-label nzFor="name">Nombre</nz-form-label>
        <nz-form-control [nzErrorTip]="nameErrorTpl">

          <input nz-input placeholder="Nombre del día" formControlName="name" type="text" id="name">

          <ng-template #nameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El nombre es requerido
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="color">Color</nz-form-label>
        <nz-form-control [nzErrorTip]="colorErrorTpl">
          <nz-select [nzCustomTemplate]="colorTemplate" id="color" nzPlaceHolder="Seleccionar el color del día festivo" formControlName="colour">
            <nz-option *ngFor="let color of colors" nzCustomContent [nzLabel]="color!.label" [nzValue]="color!.value">
              <app-color-box [color]="color"></app-color-box>
            </nz-option>
          </nz-select>

          <ng-template #colorTemplate let-selected>
            <app-color-box [size]="20" [color]="{
              label: selected.nzLabel,
              value: selected.nzValue
            }"></app-color-box>
          </ng-template>

          <ng-template #colorErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              Escoge un color
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item class="date">
        <nz-form-label nzFor="color">Selecciona los días</nz-form-label>
        <nz-form-control [nzErrorTip]="startDateErrorTpl">

          <nz-range-picker
            style="width: 100%"
            formControlName="dates"
            [nzPlaceHolder]="['Desde', 'Hasta']"
            [nzFormat]="'dd/MM/yyyy'"
            [nzAllowClear]="false"
            (ngModelChange)="rangeChange($event)">
          </nz-range-picker>

          <ng-template #startDateErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El rango de fechas es requerido
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item>
        <nz-form-label>Excluir empleados / equipos</nz-form-label>
        <nz-form-control>

          <nz-tree-select
            style="width: 100%"
            formControlName="excludedEmployeesUids"
            [nzNodes]="nodes"
            nzShowSearch
            nzCheckable
            nzPlaceHolder="Seleccionar empleados / equipos"
          ></nz-tree-select>

        </nz-form-control>
      </nz-form-item>

      <!--<div class="date-container">
        <div class="title">Selecciona los días</div>

        <nz-form-item class="date">
          <nz-form-control [nzErrorTip]="startDateErrorTpl">

            <nz-date-picker style="width: 100%" [nzPlaceHolder]="'Desde'" formControlName="startDate" [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false"></nz-date-picker>

            <ng-template #startDateErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                La fecha es requerida
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="date">
          <nz-form-control [nzErrorTip]="endDateErrorTpl">

            <nz-date-picker style="width: 100%" [nzPlaceHolder]="'Hasta'" formControlName="endDate" [nzFormat]="'dd/MM/yyyy'" [nzAllowClear]="false"></nz-date-picker>

            <ng-template #endDateErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                La fecha es requerida
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>-->
    </form>

    <div class="btns-container" *ngIf="!isLoadingTeams">
      <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
      <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="createFestivityDay()">Guardar</button>
    </div>
  </nz-spin>
</div>
