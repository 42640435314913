<div class="deductions-form-control flex wrap">

  <div class="flex">
    <nz-select
      [nzMaxMultipleCount]="!moreThanOne ? 1 : 100000000000"
      [(nzOpen)]="isOpen"
      class="hidden-select"
      [nzDropdownRender]="renderTemplate"
      [nzDropdownMatchSelectWidth]="false"
      [nzMaxTagCount]="0"
      [compareWith]="compareDeductions"
      [nzOptionHeightPx]="40"
      [nzOptionOverflowSize]="5"
      [nzDropdownClassName]="'deduction-form-control-select'"
      nzMode="multiple"
      nzPlaceHolder="Selecciona las deducciones"
      [formControl]="deductions"
      nzShowSearch
      nzServerSearch
      (nzOnSearch)="onDeductionsSearch($event)"
      (nzScrollToBottom)="loadDeductions()"
      (ngModelChange)="updateAction($event)">

      <ng-container *ngFor="let deduction of list">
        <nz-option nzCustomContent [nzLabel]="(deduction?.isDefault ? (deduction?.name) : (deduction?.name | titlecase)) || ''"
                   [nzValue]="deduction" [nzDisabled]="blockDefaults ? (deduction?.isDefault && notSelected(deduction)) : false">
          <div class="deduction-row flex align-center">
            <div class="deduction-photo deduction-photo-control">
              <app-team-icon [size]="28" [icon]="deduction?.icon?.url"  [color]="deduction?.colour"></app-team-icon>
            </div>
            <div class="deduction-name">
              {{ deduction?.isDefault ? (deduction?.name) : (deduction?.name | titlecase)}}
            </div>
          </div>
        </nz-option>
      </ng-container>

      <nz-option *ngIf="deductionsLoading" nzDisabled nzCustomContent>
        <div class="deductions-row loading-row align-center flex">
          <i nz-icon nzType="loading" class="loading-icon"></i> Cargando deducciones...
        </div>
      </nz-option>

    </nz-select>

    <ng-template #renderTemplate>
      <div class="search-box" (click)="openNewDeduction()">
        <div class="line"></div>
        <div class="new-label">
          + Crear nueva deducción
        </div>
      </div>
    </ng-template>
  </div>

  <ng-container>
    <div class="deduction bubble" nzTooltipColor="white"
         [nz-tooltip]="(deduction?.name | titlecase)" *ngFor="let deduction of deductions.value">
      <i *ngIf="blockDefaults ? !deduction?.isDefault : true" (click)="removeDeduction(deduction)" class="close-icon" nz-icon [nzTwotoneColor]="'#FF8484'" nzType="close-circle" nzTheme="twotone"></i>
      <app-team-icon [size]="50" [icon]="deduction?.icon?.url"  [color]="deduction?.colour"></app-team-icon>
    </div>
  </ng-container>

  <div class="deduction add-new" (click)="isOpen = true;" *ngIf="moreThanOne ? true : deductions.value.length === 0">
    <i nz-icon nzType="icons:deduction-add" class="loading-icon d"></i>
  </div>
</div>
