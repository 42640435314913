import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {Menu} from '../../../core/models/menu';
import {Observable} from 'rxjs';
import {User} from '../../../core/models/user';
import {UserStore} from '../../../core/stores/user.store';
import {MenuLinksService} from '../../../core/services/menu-links.service';
import {AuthenticationService} from "../../../core/services/authentication.service";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input() header: TemplateRef<any>;
  @Input() menu: Menu;

  hidden = true;
  pages = this.menuLinks.pages;
  settings = this.menuLinks.settings;

  $user: Observable<User | null> = this.userStore.getUser();

  constructor(
      private userStore: UserStore,
      private menuLinks: MenuLinksService,
      private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  toggleSideMenu() {
    this.hidden = !this.hidden;
  }

  logout() {
    this.authService.logout();
  }
}
