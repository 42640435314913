<div class="geo-fencing-row flex between wrap align-center">
  <div class="number-content">
    <div class="number">Geocerca <span (click)="goToDetails()">#{{ geoFencing?.numberId }}</span></div>
  </div>

  <div class="name-content">
    <div class="name">{{ geoFencing?.name }}</div>
  </div>

  <div class="teams-row flex wrap align-center">
    <div class="team-box"
         nzTooltipColor="white"
         [nz-tooltip]="(team.name | titlecase)"
         *ngFor="let team of (geoFencing?.teams | slice:0: 5)">
      <app-team-icon [size]="28" [icon]="team.icon?.url"  [color]="team.color"></app-team-icon>
    </div>
    <div class="announcement-extra" style="margin-left: 6px;" *ngIf="(geoFencing?.teams?.length || 0) > 0">
      {{(geoFencing?.teams?.length || 0)}} Equipo{{ (geoFencing?.teams?.length || 0) != 1 ? 's' : ''}}
    </div>
    <div class="announcement-extra flex align-center" *ngIf="(geoFencing?.teams?.length || 0) <= 0">
      <i class="empty-icon" nz-icon nzType="icons:empty" nzTheme="outline" style="margin-right: 6px;"></i> Sin equipo asignado
    </div>
  </div>

  <div class="address-container">
    <i nz-icon nzType="icons:location-pin"></i>
    <div class="address">{{ geoFencing?.fullAddress }}</div>
  </div>

  <ng-container>
    <div class="geofencing-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" nz-tooltip="Configurar geocerca" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="setting" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="editGeoFencing()" [nzDisabled]="isDeleting"><i class="empty-icon" nz-icon nzType="edit" nzTheme="outline"></i> Editar geocerca</li>
        <li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar esta geocerca?"
            (nzOnConfirm)="deleteGeoFencing()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar geocerca</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
