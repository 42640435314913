import {Component, Input, OnInit} from '@angular/core';
import {AbsenceRequest} from '../../../core/models/absence-request';
import {User} from '../../../core/models/user';

@Component({
  selector: 'app-member-vacations-table',
  templateUrl: './member-vacations-table.component.html',
  styleUrls: ['./member-vacations-table.component.scss']
})
export class MemberVacationsTableComponent implements OnInit {
  @Input() vacations: AbsenceRequest[] | undefined;
  @Input() member: User | null;
  index: number = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
