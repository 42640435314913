<div class="payroll-row flex between wrap align-center">

  <div class="icons-row">
    <div class="icons-box">
      <i *ngIf="payroll?.stampedStatus !== 'N'" [style.color]="payroll?.stampedStatus === 'C' ? '#78E7AC' : '#FF9904'" nz-icon nzType="icons:ticket" nz-popover [nzPopoverContent]="contentTemplate1"></i>
      <i *ngIf="payroll?.disbursedStatus !== 'N'" [style.color]="payroll?.disbursedStatus === 'C' ? '#78E7AC' : '#FF9904'" nz-icon nzType="icons:money-ticket" nz-popover [nzPopoverContent]="contentTemplate2"></i>
    </div>

    <ng-template #contentTemplate1>
      <div class="popover-name">Nómina timbrada</div>
    </ng-template>

    <ng-template #contentTemplate2>
      <div class="popover-name">Nómina dispersada</div>
    </ng-template>
  </div>

  <div class="category-row">
    <div class="category-num flex align-center" (click)="goToDetails()"><div class="dot" [style.background-color]="payroll?.closed ? '#78E7AC' : '#FF9904'" nzTooltipColor="white" [nz-tooltip]="payroll?.closed ? 'Nómina cerrada' : 'Nómina pendiente de cerrar'"></div> Nómina <span>#{{ payroll?.numberId }}</span></div>
    <div class="period">Periodo {{ payroll?.startDate | date: 'dd' }} {{ payroll?.startDate | date: 'MMM' }} - {{ payroll?.endDate | date: 'dd' }} {{ payroll?.endDate | date: 'MMM' }}</div>
  </div>

  <div class="category-row">
    <div class="category">{{ payroll?.name }}</div>
    <div class="period">{{ payroll?.employerRegistry?.businessName }}</div>
  </div>

  <div class="teams-box">
    <div class="count" *ngIf="payroll?.teams === null || payroll?.teams.length === 0">Ningun equipo asigando</div>
    <div class="teams-container" *ngIf="payroll?.teams !== null || payroll?.teams.length > 0">
      <app-team-icon class="team" *ngFor="let team of payroll?.teams.slice(0, 3)" [size]="36" type="url" [icon]="team.icon?.url" [color]="team.color"
      nzTooltipColor="white" [nz-tooltip]="team?.name"></app-team-icon>
      <div *ngIf="payroll?.teams?.length > 3" class="number">+ {{ payroll?.teams?.length - 3 }}</div>
    </div>
  </div>

  <div class="category-row">
    <div class="count">{{ payroll?.countPaychecks }} colaborador{{ payroll?.countPaychecks === 1 ? '' : 'es' }}</div>
  </div>

  <div class="category-row">
    <div class="total" nz-popover [nzPopoverContent]="contentTemplate3">Total: {{ payroll?.totalPaid | currency }}</div>

    <ng-template #contentTemplate3>
      <div class="popover-name">Deducciones: <span class="popover-total">{{ payroll?.totalDeductions | currency }}</span></div>
      <div class="popover-name">Percepciones: <span class="popover-total">{{ payroll?.totalEarnings | currency }}</span></div>
      <div class="popover-name">Total neto: <span class="popover-total">{{ payroll?.totalNet | currency }}</span></div>
      <div class="popover-name">Total carga social: <span class="popover-total">{{ payroll?.totalSocialSecurity | currency }}</span></div>
    </ng-template>
  </div>

  <ng-container>
    <div class="earning-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" [nz-tooltip]="'Acciones de nómina'" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="icons:dots" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item nz-popconfirm [nzDisabled]="isDeleting || isDownloading || isDownloadingCFDIS" (click)="goToDetails()">
          <i class="empty-icon" nz-icon nzType="eye" nzTheme="outline"></i>
          Ver detalles de nómina</li>
        <li nz-menu-item nz-popconfirm [nzDisabled]="isDeleting || isDownloading || isDownloadingCFDIS" (click)="downloadPayroll()">
          <i *ngIf="isDownloading" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          <i *ngIf="!isDownloading" class="empty-icon" nz-icon nzType="download" nzTheme="outline"></i>
          Descargar nómina</li>
        <li nz-menu-item nz-popconfirm [nzDisabled]="isDeleting || isDownloading || isDownloadingCFDIS" (click)="downloadPayrollCFDIS()" *ngIf="payroll?.CFDIInvoice && payroll?.CFDIInvoice?.zipFileUrl">
          <i *ngIf="isDownloadingCFDIS" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          <i *ngIf="!isDownloadingCFDIS" class="empty-icon" nz-icon nzType="download" nzTheme="outline"></i>
          Descargar CFDIS</li>
        <!--<li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar esta deducción?"
            (nzOnConfirm)="deletePayroll()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar nómina</li>-->
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
