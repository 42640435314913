import {Component, Input, OnInit} from '@angular/core';
import {GeoFencing} from "../../../core/models/geo-fencing";
import {Animation} from "@agm/core/directives/marker";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input() geoFencing: GeoFencing;
  icon = {
    url: '../assets/images/pin-1.png',
    scaledSize: {
      width: 18,
      height: 22
    }
  };
  userLocationMarkerAnimation: Animation;

  constructor() {}

  ngOnInit() {}

  mapReading() {
    this.userLocationMarkerAnimation = "DROP";
  }
}
