import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuLinksService {

  pages = [
    {
      link: '/home',
      icon: 'home',
      name: 'Home'
    },
    // {
    //   link: '/home/search',
    //   icon: 'search',
    //   name: 'Buscar'
    // },
    {
      link: '/settings',
      icon: 'setting',
      name: 'Configuración'
    },
    {
      link: '/billing',
      icon: 'wallet',
      name: 'Facturación'
    }
  ];

  settings = [
    // {
    //   link: '/home/calendar',
    //   icon: 'calendar',
    //   name: 'Calendario'
    // },
    {
      link: '/notifications',
      icon: 'bell',
      name: 'Notificaciones'
    },
  ];

  constructor() { }
}
