import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActiveResourceCategory} from "../../../core/models/active-resource-category";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActiveResourcesService} from "../../../core/services/active-resources.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {Color} from "../../../core/models/color";
import {ColorService} from "../../../core/services/color.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-create-active-resource-category',
  templateUrl: './create-active-resource-category.component.html',
  styleUrls: ['./create-active-resource-category.component.scss']
})
export class CreateActiveResourceCategoryComponent implements OnInit {
  categoryForm: FormGroup;
  isLoading = false;
  error: string | null;
  colors: Color[] = this.colorService.getColors();

  constructor(private activeResourcesService: ActiveResourcesService,
              private colorService: ColorService,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  close() {
    this.modalRef.close(null);
  }

  createCategory() {
    if (this.categoryForm.valid) {
      this.isLoading = true;
      const data = this.categoryForm.getRawValue();
      this.activeResourcesService.createActiveResourceCategory(data.color, data.name)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((category: ActiveResourceCategory) => {
          this.notification.create('success', '¡Operación exitosa!','Categoría creada correctamente');
          this.modalRef.close(category);
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al crear la categoría', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelperService.markFormAsDirty(this.categoryForm);
    }
  }

  private createForm() {
    this.categoryForm = this.formBuilder.group({
      color: [null, Validators.required],
      name: [null, Validators.required]
    });
  }

}
