import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-slack-box',
  templateUrl: './slack-box.component.html',
  styleUrls: ['./slack-box.component.scss']
})
export class SlackBoxComponent implements OnInit {
  @Input() marginBottom = 0;
  @Output() close = new EventEmitter<void>();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToSlack() {
    this.router.navigate(['/settings/integrations']).then(() => {
      this.close.emit();
    });
  }

}
