import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import {UserService} from '../services/user.service';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserStore {
  user$: BehaviorSubject<User | null>;
  user: User | null;
  isLoading$: BehaviorSubject<boolean>;
  isError$: BehaviorSubject<string | null>;

  constructor(private userService: UserService) {
    if (!this.isLoading$) {
      this.isLoading$ = new BehaviorSubject(false) as BehaviorSubject<boolean>;
    }

    if (!this.isError$) {
      this.isError$ = new BehaviorSubject(null)  as BehaviorSubject<string | null>;
    }

    if (!this.user$) {
      this.user$ = new BehaviorSubject(null)  as BehaviorSubject<User | null>;
    }
  }

  getUser(): Observable<User | null> {
    return this.user$.asObservable();
  }

  logoutUser(): void {
    this.user = null;
    this.user$.next(null);
  }

  updateUser(user: User): void {
    this.user = {...this.user, ...user};
    this.user$.next(this.user);
  }

  refreshUser(): void {
    this.subscribeToGetUser();
  }

  getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  getIsError(): Observable<string | null> {
    return this.isError$.asObservable();
  }

  private subscribeToGetUser(): void {
    this.isLoading$.next(true);
    this.isError$.next(null);
    this.userService
        .getMe()
        .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
        )
        .subscribe(
            (user: User) => {
              this.user = user;
              this.user$.next(this.user);
            },
            (error: any) => {
              this.isError$.next(
                  error.message.replace("GraphQL error:", "").trim()
              );
            }
        );
  }
}
