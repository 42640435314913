<div class="position-row flex between wrap align-center">
  <div class="number-content">
    <div class="number">Puesto <span (click)="goToDetails()">#{{ position?.numberId }}</span></div>
  </div>

  <div class="name-content">
    <div class="name">{{ position?.name }}</div>
  </div>

  <div class="members-row flex wrap align-center">
    <div class="member-box"
         nzTooltipColor="white"
         [nz-tooltip]="(member.firstName | titlecase) + ' ' + (member.lastName | titlecase)"
         *ngFor="let member of (position?.employees | slice:0: 5)">
      <nz-avatar [nzGap]="5" [nzSize]="26"  [nzSrc]="member.photo?.url || undefined"
                 [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
    </div>
    <div class="team-extra" *ngIf="(position?.employees?.length || 0) > 5">
      +{{(position?.employees?.length || 0) - 5}} colaborador{{ (position?.employees?.length || 0) != 1 ? 'es' : ''}}
    </div>
    <div class="team-extra" *ngIf="(position?.employees?.length || 0) <= 5 && (position?.employees?.length || 0) > 0">
      {{(position?.employees?.length || 0)}} colaborador{{ (position?.employees?.length || 0) != 1 ? 'es' : ''}}
    </div>
    <div class="team-extra flex align-center" *ngIf="(position?.employees?.length || 0) <= 0">
      <i nz-icon class="empty-icon" nzType="icons:empty" nzTheme="outline" style="margin-right: 6px;"></i> Sin miembros
    </div>
  </div>

  <div class="salary-content">
    <div class="salary">{{ position?.salary?.paidAmount | currency }} al mes</div>
  </div>

  <div class="tolerance-content">
    <div class="tolerance">{{ position?.toleranceMinutes }} min de tolerancia</div>
  </div>

  <ng-container>
    <div class="position-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" nz-tooltip="Configurar puesto" [nzClickHide]="false" [nzDropdownMenu]="menu">
      <i nz-icon nzType="setting" nzTheme="fill"></i>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item (click)="editSalary()" [nzDisabled]="isDeleting"><i class="empty-icon grey" nz-icon nzType="icons:money-bag" nzTheme="outline"></i> Editar salario</li>
        <li nz-menu-item (click)="editPosition()" [nzDisabled]="isDeleting"><i class="empty-icon" nz-icon nzType="edit" nzTheme="outline"></i> Editar puesto</li>
        <li nz-menu-item nzDanger nz-popconfirm [nzDisabled]="isDeleting"
            nzPopconfirmTitle="¿Estas seguro que deseas eliminar este puesto?"
            (nzOnConfirm)="deletePosition()">
          <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
          <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
          Eliminar puesto</li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</div>
