import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Position} from "../../../core/models/position";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {finalize, takeUntil} from "rxjs/operators";
import {EditPositionComponent} from "../../modals/edit-position/edit-position.component";
import {Subject} from "rxjs";
import {NzModalService} from "ng-zorro-antd/modal";
import {EditSalaryComponent} from "../../modals/edit-salary/edit-salary.component";
import {PositionDetailsComponent} from "../../modals/position-details/position-details.component";
import {PositionService} from "../../../core/services/position.service";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-position-row',
  templateUrl: './position-row.component.html',
  styleUrls: ['./position-row.component.scss']
})
export class PositionRowComponent implements OnInit, OnDestroy {
  @Input() position: Position;
  @Output() removePosition = new EventEmitter<void>();
  $onDestroy: Subject<void> = new Subject<void>();
  isDeleting = false;

  constructor(private positionService: PositionService,
              private notification: NzNotificationService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  deletePosition() {
    this.isDeleting = true;
    this.positionService.deletePosition(this.position.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string | null) => {
        this.removePosition.emit();
        this.notification.create('success', '¡Operación exitosa!', 'Puesto eliminado correctamente');
      }, error => {
        this.notification.create('error', 'Error al obtener los puestos', error.message.replace("GraphQL error:", "").trim());
      });
  }

  editPosition() {
    const modal = this.drawerService.create({
      nzContent: EditPositionComponent,
      nzTitle: 'Editar puesto',
      nzWidth: 504,
      nzContentParams: {
        position: this.position
      }
    });

    modal.afterOpen.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.editedPosition
        .pipe(takeUntil(this.$onDestroy))
        .subscribe((position: Position) => {
          if (position !== undefined && position !== null) {
           this.position = position;
          }
        });
    })
  }

  editSalary() {
    const modal = this.modalService.create({
      nzTitle: 'Editar salario',
      nzContent: EditSalaryComponent,
      nzWidth: 500,
      nzFooter: null,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        position: this.position
      }
    });

    modal.afterOpen.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.salaryEdited
        .pipe(takeUntil(this.$onDestroy))
        .subscribe((position: Position) => {
          if (position !== undefined && position !== null) {
            this.position = position;
          }
        });
    });
  }

  goToDetails() {
    const modal = this.drawerService.create({
      nzContent: PositionDetailsComponent,
      nzTitle: this.position.name,
      nzWidth: 504,
      nzContentParams: {
        position: this.position
      }
    });

    /*modal.afterOpen.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.editedPosition
        .pipe(takeUntil(this.$onDestroy))
        .subscribe((position: Position) => {
          if (position !== undefined && position !== null) {
            // update position
          }
        });
    })*/
  }
}
