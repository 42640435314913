import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Company } from '../models/company';
import { ContractShift } from '../models/contract-shift';
import { PaymentMethod } from '../models/payment-method';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private apollo: Apollo) {}

  registerCompany(data: any): Observable<Company> {
    const registerCompany = gql`
      mutation registerCompany(
        $baseData: UserInputType!
        $companyData: CompanyInputType!
        $packageUid: String!
        $token: String
      ) {
        registerCompany(
          baseData: $baseData
          companyData: $companyData
          packageUid: $packageUid
          token: $token
        ) {
          company {
            uid
            admins {
              uid
              firstName
              lastName
              photo {
                url
              }
            }
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ registerCompany: { company: Company } }>({
        mutation: registerCompany,
        variables: {
          ...data,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.registerCompany.company;
        })
      );
  }

  getCompanyAdmins(): Observable<Company> {
    const myCompany = gql`
      query myCompany {
        myCompany {
          uid
          admins {
            uid
            firstName
            lastName
            photo {
              url
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{ myCompany: Company }>({
        query: myCompany,
      })
      .pipe(
        map(({ data }) => {
          return data.myCompany;
        })
      );
  }

  updateCompanyAdmins(adminsUid: string[]): Observable<Company> {
    const updateCompany = gql`
      mutation updateCompany($adminsUid: [String]) {
        updateCompany(adminsUid: $adminsUid) {
          company {
            uid
            admins {
              uid
              firstName
              lastName
              photo {
                url
              }
            }
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ updateCompany: { company: Company } }>({
        mutation: updateCompany,
        variables: {
          adminsUid,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.updateCompany.company;
        })
      );
  }

  getCompanyGeneralData(): Observable<Company> {
    const myCompany = gql`
      query myCompany {
        myCompany {
          uid
          name
          socialReason
          rfc
          joinDate
          photo {
            uid
            url
          }
          contact {
            uid
            firstName
            lastName
            email
            phone
            address {
              street
              city
              zipcode
              country
              colony
              state
              latitude
              longitude
              interiorNumber
              exteriorNumber
            }
          }
          subscription {
            dueBalance
          }
          vacationCarryoverPolicy
          automaticCheckout
          outOfLocationAllowed
          usesOfficialFestivityDays
          taxRegime {
            uid
          }
          taxDomicile {
            city
            colony
            country
            exteriorNumber
            fullAddress
            interiorNumber
            latitude
            longitude
            state
            street
            uid
            zipcode
          }
        }
      }
    `;

    return this.apollo
      .query<{ myCompany: Company }>({
        query: myCompany,
      })
      .pipe(
        map(({ data }) => {
          return data.myCompany;
        })
      );
  }

  updateCompany(form: any): Observable<Company> {
    const updateCompany = gql`
      mutation updateCompany(
        $name: String
        $photoUid: String
        $rfc: String
        $socialReason: String
        $automaticCheckout: Boolean
        $outOfLocationAllowed: Boolean
        $usesOfficialFestivityDays: Boolean
        $vacationCarryoverPolicy: Boolean
      ) {
        updateCompany(
          name: $name
          photoUid: $photoUid
          rfc: $rfc
          socialReason: $socialReason
          automaticCheckout: $automaticCheckout
          outOfLocationAllowed: $outOfLocationAllowed
          usesOfficialFestivityDays: $usesOfficialFestivityDays
          vacationCarryoverPolicy: $vacationCarryoverPolicy
        ) {
          company {
            uid
            name
            socialReason
            rfc
            photo {
              uid
              url
            }
            contact {
              uid
              firstName
              lastName
              email
              phone
              address {
                street
                city
                zipcode
                country
                colony
                state
                latitude
                longitude
                interiorNumber
                exteriorNumber
              }
            }
            vacationCarryoverPolicy
            automaticCheckout
            outOfLocationAllowed
            usesOfficialFestivityDays
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ updateCompany: { company: Company } }>({
        mutation: updateCompany,
        variables: {
          ...form,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.updateCompany.company;
        })
      );
  }

  updateCompanyContact(form: any): Observable<Company> {
    const updateCompanyContact = gql`
      mutation updateCompanyContact(
        $addressData: AddressInput
        $email: String
        $firstName: String
        $lastName: String
        $phone: String
      ) {
        updateCompanyContact(
          addressData: $addressData
          email: $email
          firstName: $firstName
          lastName: $lastName
          phone: $phone
        ) {
          company {
            uid
            name
            socialReason
            rfc
            photo {
              uid
              url
            }
            contact {
              uid
              firstName
              lastName
              email
              phone
              address {
                street
                city
                zipcode
                country
                colony
                state
                latitude
                longitude
                interiorNumber
                exteriorNumber
              }
            }
            vacationCarryoverPolicy
            automaticCheckout
            outOfLocationAllowed
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ updateCompanyContact: { company: Company } }>({
        mutation: updateCompanyContact,
        variables: {
          ...form,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.updateCompanyContact.company;
        })
      );
  }

  getCompanyPaymentMethods(): Observable<Company> {
    const myCompany = gql`
      query myCompany {
        myCompany {
          uid
          paymentMethods {
            uid
            name
            brand
            expMonth
            expYear
            last4
            active
            default
          }
        }
      }
    `;
    return this.apollo
      .query<{ myCompany: Company }>({
        query: myCompany,
      })
      .pipe(
        map(({ data }) => {
          return data.myCompany;
        })
      );
  }

  getCompanyVacations(): Observable<Company> {
    const myCompany = gql`
      query myCompany {
        myCompany {
          uid
          vacationsRules {
            rule
            vacationsConfig {
              uid
              index
              label
              days
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{ myCompany: Company }>({
        query: myCompany,
      })
      .pipe(
        map(({ data }) => {
          return data.myCompany;
        })
      );
  }

  updateCompanyVacations(form: any): Observable<Company> {
    const updateCompanyVacationConfig = gql`
      mutation updateCompanyVacationConfig(
        $forAllEmployees: Boolean!
        $rule: String!
        $vacationsConfigData: [VacationConfigInputType]!
      ) {
        updateCompanyVacationConfig(
          forAllEmployees: $forAllEmployees
          rule: $rule
          vacationsConfigData: $vacationsConfigData
        ) {
          company {
            uid
            vacationsRules {
              rule
              vacationsConfig {
                uid
                index
                label
                days
              }
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{ updateCompanyVacationConfig: { company: Company } }>({
        query: updateCompanyVacationConfig,
        variables: {
          ...form,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.updateCompanyVacationConfig.company;
        })
      );
  }

  getCompanyDocuments(): Observable<Company> {
    const myCompany = gql`
      query myCompany {
        myCompany {
          uid
          documentsCategories
        }
      }
    `;
    return this.apollo
      .query<{ myCompany: Company }>({
        query: myCompany,
      })
      .pipe(
        map(({ data }) => {
          return data.myCompany;
        })
      );
  }

  updateCompanyDocuments(categories: string[]): Observable<Company> {
    const upsertCompanyDocumentTypes = gql`
      mutation upsertCompanyDocumentTypes($categories: [String]!) {
        upsertCompanyDocumentTypes(categories: $categories) {
          company {
            uid
            documentsCategories
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ upsertCompanyDocumentTypes: { company: Company } }>({
        mutation: upsertCompanyDocumentTypes,
        variables: {
          categories,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.upsertCompanyDocumentTypes.company;
        })
      );
  }

  getCompanySubscription(): Observable<Company> {
    const myCompany = gql`
      query myCompany {
        myCompany {
          uid
          activeEmployees
          subscription {
            uid
            active
            activePackage {
              uid
              availableModules
              employeePrice
              monthlyPrice
              name
              temporalChargeType
            }
            occupiedSeats
            closingCycleDate
            trialMonth
          }
        }
      }
    `;
    return this.apollo
      .query<{ myCompany: Company }>({
        query: myCompany,
      })
      .pipe(
        map(({ data }) => {
          return data.myCompany;
        })
      );
  }

  updateCompanySubscription(
    newPackageUid: string | undefined
  ): Observable<Company> {
    const changeSubscriptionPackage = gql`
      mutation changeSubscriptionPackage($newPackageUid: String!) {
        changeSubscriptionPackage(newPackageUid: $newPackageUid) {
          company {
            uid
            subscription {
              uid
              active
              activePackage {
                uid
                availableModules
                employeePrice
                monthlyPrice
                name
                temporalChargeType
              }
              occupiedSeats
              closingCycleDate
              trialMonth
            }
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ changeSubscriptionPackage: { company: Company } }>({
        mutation: changeSubscriptionPackage,
        variables: {
          newPackageUid,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.changeSubscriptionPackage.company;
        })
      );
  }

  addPaymentMethod(token: string | undefined): Observable<PaymentMethod> {
    const addPaymentMethod = gql`
      mutation addPaymentMethod($token: String!) {
        addPaymentMethod(token: $token) {
          paymentMethod {
            uid
            name
            brand
            expMonth
            expYear
            last4
            active
            default
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ addPaymentMethod: { paymentMethod: PaymentMethod } }>({
        mutation: addPaymentMethod,
        variables: {
          token,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.addPaymentMethod.paymentMethod;
        })
      );
  }

  changeDefaultPaymentMethod(
    paymentMethodUid: string | undefined
  ): Observable<PaymentMethod> {
    const changeDefaultPaymentMethod = gql`
      mutation changeDefaultPaymentMethod($paymentMethodUid: String!) {
        changeDefaultPaymentMethod(paymentMethodUid: $paymentMethodUid) {
          paymentMethod {
            uid
            name
            brand
            expMonth
            expYear
            last4
            active
            default
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ changeDefaultPaymentMethod: { paymentMethod: PaymentMethod } }>(
        {
          mutation: changeDefaultPaymentMethod,
          variables: {
            paymentMethodUid,
          },
        }
      )
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.changeDefaultPaymentMethod.paymentMethod;
        })
      );
  }

  deletePaymentMethod(
    paymentMethodUid: string | undefined
  ): Observable<string> {
    const removePaymentMethod = gql`
      mutation removePaymentMethod($paymentMethodUid: String!) {
        removePaymentMethod(paymentMethodUid: $paymentMethodUid) {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{ removePaymentMethod: { message: string } }>({
        mutation: removePaymentMethod,
        variables: {
          paymentMethodUid,
        },
      })
      .pipe(
        map(({ data }) => {
          // @ts-ignore
          return data.removePaymentMethod.message;
        })
      );
  }

  getCompanyIntegrations(): Observable<Company> {
    const myCompany = gql`
      query myCompany {
        myCompany {
          uid
          name
          slackActive
        }
      }
    `;
    return this.apollo
      .query<{ myCompany: Company }>({
        query: myCompany,
      })
      .pipe(
        map(({ data }) => {
          return data.myCompany;
        })
      );
  }

  getRegimeChoices(): Observable<ContractShift[]> {
    const REGIME_CHOICES = gql`
      query taxRegimes {
        taxRegimes(category: "C") {
          uid
          code
          description
          naturalPerson
          legalPerson
        }
      }
    `;

    return this.apollo
      .query<{ taxRegimes: ContractShift[] }>({
        query: REGIME_CHOICES,
      })
      .pipe(
        map(({ data }) => {
          return data.taxRegimes;
        })
      );
  }

  updateCompanyTaxData(data: any): Observable<Company> {
    const UPDATE_COMPANY = gql`
      mutation updateCompanyTaxData(
        $companyUid: String
        $taxRegimeUid: String
        $addressData: AddressInput
      ) {
        updateCompanyTaxData(
          companyUid: $companyUid
          taxRegimeUid: $taxRegimeUid
          addressData: $addressData
        ) {
          company {
            uid
            name
            socialReason
            rfc
            photo {
              uid
              url
            }
            contact {
              uid
              firstName
              lastName
              email
              phone
              address {
                street
                city
                zipcode
                country
                colony
                state
                latitude
                longitude
                interiorNumber
                exteriorNumber
              }
            }
            vacationCarryoverPolicy
            automaticCheckout
            outOfLocationAllowed
            usesOfficialFestivityDays
            taxDomicile {
              city
              colony
              country
              exteriorNumber
              fullAddress
              interiorNumber
              latitude
              longitude
              state
              street
              uid
              zipcode
            }
            taxRegime {
              uid
              code
              description
            }
          }
        }
      }
    `;

    return this.apollo.mutate<{ updateCompanyTaxData: { company: Company } }>({
      mutation: UPDATE_COMPANY,
      variables: {
        ...data
      },
    }).pipe(
      map(({ data }) => {
        return data!.updateCompanyTaxData.company;
      })
    );
  }
}
