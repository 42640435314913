<div class="edit-paycheck-deductions-modal">
  <form nz-form nzLayout="vertical" [formGroup]="paycheckForm" *ngIf="paycheckForm">

    <div style="margin-bottom: 40px">
      <nz-form-label>Deducciones de la nómina</nz-form-label>
      <app-deductions-form-control
        [deductions]="getDeductionsFormControl()"
        [blockDefaults]="true"
        (updateTotals)="setTotal()">
      </app-deductions-form-control>
    </div>
  </form>

  <div class="item-container">
    <div class="item" *ngFor="let deduction of getDeductionsArray(); let i = index">
      <label>{{ deduction?.name }}</label>
      <nz-input-number
        (ngModelChange)="setTotal()"
        [nzDisabled]="deduction.isDefault"
        style="width: 100%"
        [OnlyNumber]="true"
        [(ngModel)]="deduction.editAmount"
        [nzMin]="0"
        [nzStep]="10"
        [nzFormatter]="formatter"
        [nzParser]="parser">
      </nz-input-number>
    </div>
  </div>

  <div class="row-container">
    <div class="text">Total deducciones</div>
    <div class="content">{{ total | currency }}</div>
  </div>

  <div class="drawer-footer ant-drawer-footer">
    <div class="flex end wrap">
      <button nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
              (nzOnConfirm)="close()"
              type="button" nz-button nzType="default">Cancelar</button>
      <button [nzLoading]="isLoading" (click)="updateDeductions()" type="button" nz-button nzType="primary">Guardar</button>
    </div>
  </div>
</div>
