import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbsenceRequest} from '../../../core/models/absence-request';
import {User} from '../../../core/models/user';
import {AbsenceRequestService} from '../../../core/services/absence-request.service';
import {finalize} from 'rxjs/operators';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {DatePipe} from "@angular/common";
import {isBefore} from "date-fns";

@Component({
  selector: 'app-absence-request-modal',
  templateUrl: './absence-request-modal.component.html',
  styleUrls: ['./absence-request-modal.component.scss']
})
export class AbsenceRequestModalComponent implements OnInit {
  @Input() absenceRequest: AbsenceRequest | undefined;
  @Input() member: User | undefined;
  isLoading: boolean;

  @Output() absenceRequestUpdated: EventEmitter<AbsenceRequest> = new EventEmitter<AbsenceRequest>();

  error: string | null;
  isOnTime = false;
  today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  constructor(
      private absenceRequestService: AbsenceRequestService,
      private drawerRef: NzDrawerRef,
      private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const correctDate = new Date(this.absenceRequest?.fromDate);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    const absenceDay = this.datePipe.transform(correctDate, 'yyyy-MM-dd');
    if ((isBefore(new Date(), correctDate) || this.today === absenceDay)) {
      this.isOnTime = true;
    }
  }

  closeModal() {
    this.drawerRef.close();
  }

  approveAbsenceRequest() {
    this.isLoading = true;
    this.error = null;
    this.absenceRequestService.acceptAbsenceRequest(this.absenceRequest?.uid)
        .pipe(
            finalize(() => {
              this.isLoading = false;
            })
        ).subscribe(
            absenceRequest => {
              this.absenceRequest = absenceRequest;
              this.absenceRequestUpdated.emit(absenceRequest);
              this.closeModal();
            }, error => {
              this.error = error;
        }
    );
  }

  denyAbsenceRequest() {
    this.isLoading = true;
    this.error = null;
    this.absenceRequestService.denyAbsenceRequest(this.absenceRequest?.uid)
        .pipe(
            finalize(() => {
              this.isLoading = false;
            })
        ).subscribe(
        absenceRequest => {
          this.absenceRequest = absenceRequest;
          this.absenceRequestUpdated.emit(absenceRequest);
          this.closeModal();
        }, error => {
          this.error = error;
        }
    );
  }
}
