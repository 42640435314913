import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {AuthenticationService} from "./authentication.service";
import {environment} from "../../../environments/environment";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {SlackChannel} from "../models/slack-channel";

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(private apollo: Apollo,
              private authService: AuthenticationService) { }

  installSlack(): void {
    window.open(environment.apiUrl + '/slack/install/?authorization=JWT+' + this.authService.credentials?.authToken, '_self');
  }

  getSlackChannels() {
    return this.apollo.query<{companySlackChannels: SlackChannel[]}>({
      query : gql`
        query companySlackChannels {
          companySlackChannels{
            uid
            channelId
            name
            botAdded
          }
        }
      `,
    }).pipe(
      map(({data}) => {
        return data?.companySlackChannels;
      })
    )
  }
}
