<div class="team-box flex wrap">

  <div class="team-line" [ngStyle]="{backgroundColor: team.color}"></div>

  <div class="team-name-row flex align-center">
    <div class="team-icon flex align-center center">
      <app-team-icon [size]="28" [icon]="team.icon?.url"  [color]="team.color"></app-team-icon>
    </div>
    <div class="team-name">
      <a (click)="goToTeamDetails()">{{team.name}}</a>
    </div>
  </div>

  <div class="leaders-row flex wrap align-center">
    <div class="leader-box"
         nzTooltipColor="white"
         [nz-tooltip]="(member.firstName | titlecase) + ' ' + (member.lastName | titlecase)"
         *ngFor="let member of (team.teamLeaders | slice:0: 5)">
      <nz-avatar [nzGap]="5" [nzSize]="26"  [nzSrc]="member.photo?.url || undefined"
                 [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
    </div>
    <div class="team-extra" *ngIf="(team?.teamLeaders?.length || 0) > 0">
      {{(team.teamLeaders?.length || 0)}} Líder{{ (team.teamLeaders?.length || 0) != 1 ? 'es' : ''}}
    </div>
    <div class="team-extra flex align-center" *ngIf="(team.teamLeaders?.length || 0) <= 0">
      <i class="empty-icon" nz-icon nzType="icons:empty" nzTheme="outline"></i>Sin lideres
    </div>
  </div>

  <div class="members-row flex wrap align-center">
    <div class="member-box"
         nzTooltipColor="white"
         [nz-tooltip]="(member.firstName | titlecase) + ' ' + (member.lastName | titlecase)"
         *ngFor="let member of (team.members | slice:0: 5)">
      <nz-avatar [nzGap]="5" [nzSize]="26"  [nzSrc]="member.photo?.url || undefined"
                 [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
    </div>
    <div class="team-extra" *ngIf="(team.members?.length || 0) > 5">
      +{{(team.members?.length || 0) - 5}} colaborador{{ (team.members?.length || 0) != 1 ? 'es' : ''}}
    </div>
    <div class="team-extra" *ngIf="(team.members?.length || 0) <= 5 && (team.members?.length || 0) > 0">
      {{(team.members?.length || 0)}} colaborador{{ (team.members?.length || 0) != 1 ? 'es' : ''}}
    </div>
    <div class="team-extra flex align-center" *ngIf="(team.members?.length || 0) <= 0">
      <i nz-icon class="empty-icon" nzType="icons:empty" nzTheme="outline"></i>Sin miembros
    </div>
  </div>

  <div class="team-actions flex end align-center">

    <ng-container *ngIf="!isLoading">
      <div class="team-action flex align-center" nzTrigger="click" nz-dropdown nzTooltipColor="white" nz-tooltip="Configurar equipo" [nzClickHide]="false" [nzDropdownMenu]="menu">
        <i nz-icon nzType="setting" nzTheme="fill"></i>
      </div>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="openEditTeam()"><i class="empty-icon" nz-icon nzType="edit" nzTheme="outline"></i> Editar equipo</li>
          <li nz-menu-item nzDanger nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas eliminar este equipo de trabajo?"
              (nzOnConfirm)="deleteTeam()"><i class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i> Eliminar equipo</li>
        </ul>
      </nz-dropdown-menu>

      <div class="team-action flex align-center" nzTooltipColor="white" nz-tooltip="Ver miembros" (click)="goToTeamMembers()">
        <i nz-icon nzType="icons:customer" nzTheme="outline"></i>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <nz-spin nzSize="small"></nz-spin>
    </ng-container>
  </div>
</div>
