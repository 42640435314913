import {Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit, OnChanges {
  @Input() src = '';
  @Input() size: number | undefined = 30;

  constructor(
      private el: ElementRef,
      private http: HttpClient,
  ) {}

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.http.get(this.src, {responseType: 'text'}).subscribe(svg => {
      this.el.nativeElement.innerHTML = '<div class="svg-component" style="width:' + this.size +'px; height: ' + this.size + 'px">' + svg + '</div>';
    });
  }
}
