<form [formGroup]="editDeductionForm" *ngIf="editDeductionForm">
  <div class="new-team">

    <div class="error-box" *ngIf="error && !isUpdating">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <div class="new-team-container flex wrap">
      <div class="flex center wrap form-column team-icon">
        <app-team-icon
          [size]="90"
          [icon]="editDeductionForm.controls.iconUid.value?.url"
          [color]="editDeductionForm.controls.colour.value"></app-team-icon>
      </div>

      <div nzLayout="vertical" nz-form class="form-column">
        <nz-form-item>
          <nz-form-label nzFor="icon">Seleccionar icono</nz-form-label>
          <nz-form-control [nzErrorTip]="iconErrorTpl">
            <nz-select [nzCustomTemplate]="iconTemplate" id="icon" nzPlaceHolder="Icono" formControlName="iconUid">
              <nz-option *ngFor="let icon of icons" nzCustomContent [nzLabel]="icon!.name" [nzValue]="icon">
                <app-icon-box [icon]="icon"></app-icon-box>
              </nz-option>
            </nz-select>

            <ng-template #iconTemplate let-selected>
              <app-icon-box [size]="20" [icon]="selected.nzValue"></app-icon-box>
            </ng-template>

            <ng-template #iconErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge un icono
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="color">Seleccionar color</nz-form-label>
          <nz-form-control [nzErrorTip]="colorErrorTpl">
            <nz-select [nzCustomTemplate]="colorTemplate" id="color" nzPlaceHolder="Color" formControlName="colour">
              <nz-option *ngFor="let color of colors" nzCustomContent [nzLabel]="color!.label" [nzValue]="color!.value">
                <app-color-box [color]="color"></app-color-box>
              </nz-option>
            </nz-select>

            <ng-template #colorTemplate let-selected>
              <app-color-box [size]="20" [color]="{
              label: selected.nzLabel,
              value: selected.nzValue
            }"></app-color-box>
            </ng-template>

            <ng-template #colorErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge un color
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nzLayout="vertical" nz-form class="form-column">
        <nz-form-item>
          <nz-form-label nzFor="name">Nombre de la deducción</nz-form-label>
          <nz-form-control [nzErrorTip]="nameErrorTpl">

            <input nz-input placeholder="Nombre de la deducción" formControlName="name" type="text" id="name">

            <ng-template #nameErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa el nombre de la deducción
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="category">Categoría del SAT</nz-form-label>
          <nz-form-control [nzErrorTip]="categoryErrorTpl">
            <nz-select nzShowSearch id="category" nzPlaceHolder="Selecciona la categoría del SAT" formControlName="conceptCode" [nzCustomTemplate]="template">
              <nz-option *ngFor="let concept of deductionConcepts" nzCustomContent [nzLabel]="concept?.description" [nzValue]="concept">
                <div class="concept">{{concept?.description}}</div>
              </nz-option>
            </nz-select>

            <ng-template #template let-selected>
              <div>{{ selected.nzValue?.description | limitTo: 50: true }}</div>
            </ng-template>

            <ng-template #categoryErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge una categoría
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>Monto por defecto</nz-form-label>
          <nz-form-control [nzErrorTip]="newPaidAmountErrorTpl">

            <nz-input-number
              [OnlyNumber]="true"
              formControlName="amount"
              [nzMin]="0"
              [nzStep]="10"
              [nzFormatter]="formatter"
              [nzParser]="parser">
            </nz-input-number>

            <ng-template #newPaidAmountErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                El monto por defecto es requerido
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>

</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="close()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isUpdating" (click)="updateDeduction()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
