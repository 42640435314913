import {Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {UserStore} from './core/stores/user.store';
import {NavigationEnd, Router} from '@angular/router';
declare const fbq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(
      private userStore: UserStore,
      private router: Router
  ) {
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        fbq('track', 'PageView');
      }
    });
  }

  ngOnInit() {

    // this.userStore.refreshUser();

    // Conekta setUp
    Conekta.setPublicKey(environment.conekta.public_key);
    Conekta.setLanguage("es");
  }
}
