import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeoLocation} from "../../../core/models/geo-location";
import {isToday, isBefore} from "date-fns";

@Component({
  selector: 'app-team-geo-locations-card',
  templateUrl: './team-geo-locations-card.component.html',
  styleUrls: ['./team-geo-locations-card.component.scss']
})
export class TeamGeoLocationsCardComponent implements OnInit {
  @Input() cluster: GeoLocation[];
  @Input() geolocations: GeoLocation[];
  @Input() teamColor: string;
  circularIcon: any;
  pinIcon: any;
  @Output() selectedLocation = new EventEmitter<GeoLocation | null>();
  @Output() clearCluster = new EventEmitter<void>();
  showMore = false;
  selectedLocationActive = false;

  constructor() { }

  ngOnInit(): void {
    this.geolocations.forEach((geolocation: GeoLocation) => {
      if (geolocation?.timestamp) {
        // @ts-ignore
        geolocation?.correctTimestamp = new Date(geolocation?.timestamp.slice(0,-6));
      }
    });

    this.circularIcon = {
      url: '../assets/images/map-pins/circular-pins/' + this.teamColor.replace('#', '') + '.png',
      scaledSize: {
        width: 30,
        height: 30
      }
    };

    this.pinIcon = {
      url: '../assets/images/map-pins/map-pins/' + this.teamColor.replace('#', '') + '.png',
      scaledSize: {
        width: 22,
        height: 30
      }
    };
  }

  locationClick(index: number, selected: boolean, cluster: boolean) {
    if (cluster) {
      if (!this.cluster[index].selected) {
        this.cluster.forEach(geolocation => geolocation.selected = false);
        this.cluster[index].selected = !selected;
        if (this.cluster[index].selected) {
          this.selectedLocation.emit(this.cluster[index]);
        }
      }
    } else {
      if (!this.geolocations[index].selected) {
        this.geolocations.forEach(geolocation => geolocation.selected = false);
        this.geolocations[index].selected = !selected;
        if (this.geolocations[index].selected) {
          this.selectedLocationActive = true;
          this.selectedLocation.emit(this.geolocations[index]);
        }
      }
    }
  }

  cleanCluster() {
    this.selectedLocationActive = false;
    this.cluster.forEach(geolocation => geolocation.selected = false);
    this.clearCluster.emit();
  }

  cleanSelected() {
    this.selectedLocationActive = false;
    this.geolocations.forEach(geolocation => geolocation.selected = false);
    this.selectedLocation.emit(null);
  }

  isBefore(date: any) {
    const correctDate = new Date(date);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    return isBefore(correctDate, new Date()) && !isToday(correctDate);
  }

  isToday(date: any) {
    const correctDate = new Date(date);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    return isToday(correctDate);
  }

}
