import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActiveResources} from "../../../core/models/active-resources";
import {ActiveResourcesService} from "../../../core/services/active-resources.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {User} from "../../../core/models/user";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {MemberService} from "../../../core/services/member.service";

@Component({
  selector: 'app-change-active-resource-holder-modal',
  templateUrl: './change-active-resource-holder-modal.component.html',
  styleUrls: ['./change-active-resource-holder-modal.component.scss']
})
export class ChangeActiveResourceHolderModalComponent implements OnInit, OnDestroy {
  @Input() activeResource: ActiveResources | null;
  membersForm: FormGroup;
  isLoading = false;
  $onDestroy: Subject<void> = new Subject<void>();

  members: User[] = [];
  membersLoading = false;
  membersIndex = 1;
  stopLoading = false;
  searchMember: null | string;
  $searchChange: Subject<string> = new Subject<string>();
  firstSearch = true;

  constructor(private activeResourcesService: ActiveResourcesService,
              private memberService: MemberService,
              private formBuilder: FormBuilder,
              private formHelperService: FormHelperService,
              private notification: NzNotificationService,
              private modalRef: NzModalRef) { }

  ngOnInit(): void {
    this.subscribeToSearch();
    this.loadMoreMembers();
    if (this.activeResource?.currentHolder !== null) {
      this.members.push(<User>this.activeResource?.currentHolder);
    }
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();

    this.$searchChange.next();
    this.$searchChange.complete();
  }

  close() {
    this.modalRef.close(null);
  }

  updateHolder() {
    if (this.membersForm.valid) {
      this.isLoading = true;
      const data = this.membersForm.getRawValue();
      this.activeResourcesService.updateActiveResource({
        // @ts-ignore
        resourceUid: this.activeResource.uid,
        employeeUid: data.member.uid === 'delete' ? null : data.member.uid
      }).pipe(finalize(() => {
        this.isLoading = false;
      }))
        .subscribe((active: ActiveResources) => {
          this.notification.create('success', '¡Operación exitosa!', 'Activo reasignado correctamente');
          this.modalRef.close(active);
        }, error => {
          this.notification.create('error', 'Error al asignar el activo', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelperService.markFormAsDirty(this.membersForm);
    }
  }

  loadMoreMembers() {
    if (!this.stopLoading) {
      this.membersLoading = true;
      this.memberService.getMembers({
        substring: this.searchMember,
        skip: this.membersIndex,
        unassigned: false,
        limit: 10,
        order: "desc",
        sort: "number_id"
      })
        .pipe(
          finalize(() => {
            this.membersLoading = false;
          })
        )
        .subscribe( (res) => {
          if (res?.employees?.length) {
            res.employees.forEach(employee => {
              if (this.activeResource?.currentHolder !== null && employee.uid !== this.activeResource?.currentHolder?.uid) {
                this.members.push(employee);
              }
              if (this.activeResource?.currentHolder !== null && employee.uid === this.activeResource?.currentHolder?.uid) {
                const index = this.members.findIndex(m => m.uid === this.activeResource?.currentHolder?.uid);
                if (index === -1) {
                  this.members.push(employee);
                }
              }
              if (this.activeResource?.currentHolder === null) {
                this.members.push(employee);
              }
            });
          } else {
            this.stopLoading = true;
          }
        });
      this.membersIndex++;
    }
  }

  onMembersSearch($event: string) {
    if (!this.firstSearch) {
      this.stopLoading = false;
      this.members = [];
      this.searchMember = $event === "" ? null : $event;
      this.membersIndex = 1;
      this.loadMoreMembers();
    }
    this.firstSearch = false;
  }

  private createForm() {
    this.membersForm = this.formBuilder.group({
      member: [this.members[0], Validators.required]
    });
  }

  private subscribeToSearch() {
    this.$searchChange
      .pipe(takeUntil(this.$onDestroy), debounceTime(400))
      .subscribe(search => {
        this.onMembersSearch(search);
      });
  }

}
