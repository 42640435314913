import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {curveMonotoneX} from 'd3-shape';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() data: any[];
  @Input() isLoading: boolean;
  @Output() updateChart = new EventEmitter<{year: number}>();
  multi: any[] = [];
  curve: any = curveMonotoneX;
  selectedYear: number = new Date().getFullYear();
  years: any[];
  // options
  @Input() legend: boolean = false;
  @Input() showLabels: boolean = true;
  @Input() animations: boolean = true;
  @Input() xAxis: boolean = true;
  @Input() yAxis: boolean = true;
  @Input() isGradient: boolean = true;
  @Input() showYAxisLabel: boolean = false;
  @Input() showXAxisLabel: boolean = false;
  @Input() xAxisLabel: string | null = 'Fecha';
  @Input() yAxisLabel: string | null = 'Empleados';
  @Input() timeline: boolean = false;

  @Input() colorScheme: any = {
    domain: ['#22d3f4']
  };

  constructor() { }

  ngOnInit(): void {
    this.setYears(new Date().getFullYear());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && this.isLoading) {}
    if (changes.data && this.data) {
      this.multi = [
        {
          "name": "Empleados",
          "series": this.data
        }
      ]
    }
  }

  yearChange() {
    this.updateChart.emit({year: this.selectedYear})
  }

  yAxisTickFormattingFn(value: any) {
    return value;
  }

  onSelect(event: any) {
    // console.log(event);
  }

  private setYears(actualYear: number): void {
    this.years = [];
    const initialYear = 2021;
    const pastYears = actualYear - initialYear;
    for (let i = pastYears; i >= 0; i--) {
      this.years.push(initialYear + i);
    }
  }

}
