import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {GeoFencing} from "../../../core/models/geo-fencing";
import {GeoFencingService} from "../../../core/services/geo-fencing.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {EditGeoFencingModalComponent} from "../../modals/edit-geo-fencing-modal/edit-geo-fencing-modal.component";
import {finalize, takeUntil} from "rxjs/operators";
import {AddressService} from "../../../core/services/address.service";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {GeoFencingDetailsComponent} from "../../modals/geo-fencing-details/geo-fencing-details.component";

@Component({
  selector: 'app-geo-fencing-row',
  templateUrl: './geo-fencing-row.component.html',
  styleUrls: ['./geo-fencing-row.component.scss']
})
export class GeoFencingRowComponent implements OnInit, OnDestroy {
  @Input() geoFencing: GeoFencing;
  @Output() removePosition = new EventEmitter<void>();
  $onDestroy: Subject<void> = new Subject<void>();
  isDeleting = false;

  constructor(private geoFencingService: GeoFencingService,
              private addressService: AddressService,
              private notification: NzNotificationService,
              private drawerService: NzDrawerService,
              private modalService: NzModalService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  deleteGeoFencing() {
    this.isDeleting = true;
    this.geoFencingService.deleteGeofencing(this.geoFencing.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string | null) => {
        this.removePosition.emit();
        this.notification.create('success', '¡Operación exitosa!', message || 'Geocerca eliminada correctamente');
      }, error => {
        this.notification.create('error', 'Error al obtener los puestos', error.message.replace("GraphQL error:", "").trim());
      });
  }

  editGeoFencing() {
    const modal = this.modalService.create({
      nzContent: EditGeoFencingModalComponent,
      nzWidth: 696,
      nzTitle: 'Editar geocerca',
      nzClosable: true,
      nzCentered: true,
      nzComponentParams: {
        geofencing: this.geoFencing
      }
    });

    modal.componentInstance?.geoFencingEdited.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(geoFencing => {
      if (geoFencing) {
        if (geoFencing?.address) {
          geoFencing.fullAddress = this.addressService.concatAddress(geoFencing.address);
        } else {
          // @ts-ignore
          geoFencing.fullAddress = 'Sin dirección registrada';
        }
        this.geoFencing = geoFencing;
      }
    })
  }

  goToDetails() {
    const modal = this.drawerService.create({
      nzContent: GeoFencingDetailsComponent,
      nzTitle: this.geoFencing.name,
      nzWidth: 504,
      nzContentParams: {
        geoFencing: this.geoFencing
      }
    });

    /*modal.afterOpen.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.editedPosition
        .pipe(takeUntil(this.$onDestroy))
        .subscribe((position: Position) => {
          if (position !== undefined && position !== null) {
            // update position
          }
        });
    })*/
  }
}
