<div class="member-box flex align-center">
  <a [routerLink]="['/members/' + member?.uid]" class="member-photo router-label">
    <nz-avatar [nzSize]="30"  [nzSrc]="member.photo?.url || undefined"
               [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
  </a>

  <div class="member-name flex column center">
    <a [routerLink]="['/members/' + member?.uid]" class="name router-label">
      {{member.firstName | titlecase}} {{member.lastName | titlecase}}
    </a>
    <div class="email">
      {{member.email | lowercase}}
    </div>
  </div>

  <div class="member-position">
    {{member.position?.name || 'Sin puesto'}}
  </div>

  <div class="privileges flex align-center">
    <app-privileges-form-control style="width: 100%" [privileges]="getFormControl()"></app-privileges-form-control>
  </div>
</div>
