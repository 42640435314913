<div class="slack-row flex align-center between wrap" [style.margin-bottom]="marginBottom + 'px'">
  <div class="name-container">
    <div class="img-box">
      <img [src]="'../assets/images/slack-purple.png'" alt="slack-logo">
    </div>

    <div class="name">Slack</div>
  </div>

  <div class="description-box">
    <div class="text">Integra slack con tu cuenta de Nommi y podrás automatizar notificaciones en tus canales de equipos de trabajo cada anuncio que se crea desde la app.</div>
  </div>

  <div class="buttons-container">
    <button
      nz-button
      nzType="primary"
      [nz-popconfirm]="message"
      (nzOnConfirm)="goToSlack()">
      Integrar
    </button>
  </div>

  <ng-template #message>
    <div class="flex">
      <i nz-icon nzType="exclamation-circle" nzTheme="fill" style="margin-top: 6px; margin-right: 8px; color: #faad14; font-size: 14px;"></i>
      <div class="box">
        <div>¿Seguro que desas cerrar esta venta?</div>
        <div><b>Nota:</b> No se guardarán los cambios realizados.</div>
      </div>
    </div>
  </ng-template>
</div>
