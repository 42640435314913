import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GeoFencing} from "../../../core/models/geo-fencing";
import {AddressService} from "../../../core/services/address.service";
import {finalize, takeUntil} from "rxjs/operators";
import {NzModalService} from "ng-zorro-antd/modal";
import {EditGeoFencingModalComponent} from "../../modals/edit-geo-fencing-modal/edit-geo-fencing-modal.component";
import {Subject} from "rxjs";
import {GeoFencingService} from "../../../core/services/geo-fencing.service";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss']
})
export class MapBoxComponent implements OnInit, OnDestroy {
  @Input() geoFencing: GeoFencing | undefined;
  $onDestroy: Subject<void> = new Subject<void>();
  @Output() removeGeofencing = new EventEmitter<GeoFencing>();

  constructor(private addressService: AddressService,
              private geoFencingService: GeoFencingService,
              private modalService: NzModalService,
              private notification: NzNotificationService) { }

  ngOnInit(): void {
    if (this.geoFencing?.address) {
      this.geoFencing.fullAddress = this.addressService.concatAddress(this.geoFencing.address);
    } else {
      // @ts-ignore
      this.geoFencing.fullAddress = 'Sin dirección registrada';
    }
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  openLocation() {
    if (this.geoFencing?.fullAddress !== 'Sin dirección registrada') {
      window.open('https://www.google.com/maps/search/' + this.geoFencing?.fullAddress);
    }
  }

  deleteGeofencing() {
    // @ts-ignore
    this.geoFencing.isLoading = true;
    this.geoFencingService.removeGeoFencing(this.geoFencing?.uid)
      .pipe(finalize(() => {
        // @ts-ignore
        this.geoFencing.isLoading = false;
      }))
      .subscribe((message: string | null) => {
        this.notification.create('success', '¡Operación exitosa!', 'La geocerca se eliminó correctamente');
        this.removeGeofencing.emit(this.geoFencing);
      }, error => {
        this.notification.create('error', 'Error al eliminar la geocerca', error.message.replace("GraphQL error:", "").trim());
      });
  }

  editGeofencing() {
    const modal = this.modalService.create({
      nzContent: EditGeoFencingModalComponent,
      nzWidth: 696,
      nzTitle: 'Editar geocerca',
      nzClosable: true,
      nzCentered: true,
      nzComponentParams: {
        geofencing: this.geoFencing
      }
    });

    modal.componentInstance?.geoFencingEdited.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe(geoFencing => {
      if (geoFencing) {
        if (geoFencing?.address) {
          geoFencing.fullAddress = this.addressService.concatAddress(geoFencing.address);
        } else {
          // @ts-ignore
          geoFencing.fullAddress = 'Sin dirección registrada';
        }
        this.geoFencing = geoFencing;
      }
    })
  }
}
