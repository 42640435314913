import {Component, Input, OnInit} from '@angular/core';
import {Paycheck} from "../../../core/models/paycheck";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PayrollService} from "../../../core/services/payroll.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {finalize} from "rxjs/operators";
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-edit-paycheck-info-modal',
  templateUrl: './edit-paycheck-info-modal.component.html',
  styleUrls: ['./edit-paycheck-info-modal.component.scss']
})
export class EditPaycheckInfoModalComponent implements OnInit {
  @Input() paycheck: Paycheck | null;
  paycheckForm: FormGroup;
  isLoading = false;
  hours = [5, 7, 7.5, 8];
  formatterHrs = (value: number) => `${value + ( value === 1 ? ' hr' : ' hrs' )}`;
  parserHrs = (value: string) => value.replace(" hr", "").replace(" hrs", "");
  formatterDays = (value: number) => `${value + ( value === 1 ? ' día' : ' días' )}`;
  parserDays = (value: string) => value.replace(" día", "").replace(" días", "");
  formatter = (value: number) => `${this.currency.transform(value)}`;
  parser = (value: string) => value.replace(",", "").replace("$", "");

  constructor(private payrollService: PayrollService,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private currency: CurrencyPipe,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  close() {
    this.drawerRef.close(null);
  }

  updatePaycheck() {
    if (this.paycheckForm.valid) {
      this.isLoading = true;
      const data = this.paycheckForm.getRawValue();
      data.salaryData = {
        hoursWorked: data.hoursWorked,
        imssPaidAmount: data.imssPaidAmount,
        paidAmount: data.paidAmount
      };
      delete data.hoursWorked;
      delete data.imssPaidAmount;
      delete data.paidAmount;
      this.payrollService.updatePaycheck(data)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((paycheck: Paycheck) => {
          this.notification.create('success', '¡Operación exitosa!', 'Días de trabajo actualizados correctamente');
          this.drawerRef.close(paycheck);
        }, error => {
          this.notification.create('error', 'Error al editar los días de trabajo', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.paycheckForm);
    }
  }

  private createForm() {
    this.paycheckForm = this.formBuilder.group({
      absentDays: [this.paycheck?.absentDays, Validators.required],
      doubleHours: [this.paycheck?.doubleHours, Validators.required],
      incapacityDays: [this.paycheck?.incapacityDays, Validators.required],
      labouredDays: [this.paycheck?.labouredDays, Validators.required],
      paycheckUid: [this.paycheck?.uid, Validators.required],
      tripleHours: [this.paycheck?.tripleHours, Validators.required],
      vacationDays: [this.paycheck?.vacationDays, Validators.required],
      extraHoursDays: [this.paycheck?.extraHoursDays, Validators.required],
      hoursWorked: [this.paycheck?.employee?.currentSalary?.hoursWorked, Validators.required],
      paidAmount: [this.paycheck?.employee?.currentSalary?.paidAmount, Validators.required],
      imssPaidAmount: [this.paycheck?.employee?.currentSalary?.imssPaidAmount, Validators.required]
    });
  }

}
