import { Injectable } from '@angular/core';
import GoogleUser = gapi.auth2.GoogleUser;
import GoogleAuth = gapi.auth2.GoogleAuth;
import {Observable, ReplaySubject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {User} from "../models/user";
import {Apollo, gql} from "apollo-angular";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GoogleServicesService {
  private auth2: GoogleAuth;
  private googleUser = new ReplaySubject<GoogleUser | null>(1);

  constructor(private http: HttpClient,
              private apollo: Apollo) {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: '904760971988-tmqiopt3hlih338o35dnvb5unpusak73.apps.googleusercontent.com',
        ux_mode: "popup"
      });
    });
  }

  getRefreshToken(code: string): Observable<any> {
    return this.http.post('https://oauth2.googleapis.com/token?code=' + code +
      '&client_id=904760971988-tmqiopt3hlih338o35dnvb5unpusak73.apps.googleusercontent.com' +
      '&client_secret=5h2MkLNMuFVdq97Aky7rOBVd' +
      '&grant_type=authorization_code' +
      '&redirect_uri=' + environment.appUrl, {});
  }

  getGoogleUserInfo(token: string): Observable<any> {
    const header = new HttpHeaders();
    header.append('Content-Type', 'application/json');
    header.append('Accept', 'application/json');
    return this.http.get('https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + token, { headers: header });
  }

  async offlineCode() {
    return this.auth2.grantOfflineAccess({
      scope: "https://www.googleapis.com/auth/calendar.events.readonly"
    }).then((res: {code: string}) => {
      return res.code;
    }).catch(() => {
      return null;
    });
  }

  async signIn() {
    return await this.auth2.signIn({
      scope: "https://www.googleapis.com/auth/calendar.events.readonly"
    }).then((user: GoogleUser) => {
      this.googleUser.next(user);
      return user;
    }).catch(() => {
      this.googleUser.next(null);
      return null
    });
  }

  async signOut() {
    return this.auth2.signOut()
      .then(() => {
        this.googleUser.next(null);
        return 'success';
      }).catch(() => {
        return 'error';
      });
  }

  observable(): Observable<GoogleUser | null> {
    return this.googleUser;
  }

  activateGoogleCalendar(token: string, refreshToken: string, deviceType: string, email: string, name: string, profilePictureUrl: string): Observable<User> {
    const activateGoogleCalendar = gql`
      mutation activateGoogleCalendar(
        $token: String!
        $refreshToken: String!
        $email: String!
        $name: String!
        $profilePictureUrl: String!
        $deviceType: String!
      ) {
        activateGoogleCalendar(
          token: $token
          refreshToken: $refreshToken
          email: $email
          name: $name,
          profilePictureUrl: $profilePictureUrl
          deviceType: $deviceType
        ) {
          employee {
            uid
            googleCalendarActive
            googleToken {
              uid
              name
              email
              profilePictureUrl
            }
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ activateGoogleCalendar: { employee: User } }>({
        mutation: activateGoogleCalendar,
        variables: {
          token,
          refreshToken,
          deviceType,
          email,
          name,
          profilePictureUrl
        }
      })
      .pipe(
        map(({ data }) =>
          // @ts-ignore
          data.activateGoogleCalendar.employee)
      );
  }

  disableGoogleCalendar(): Observable<User> {
    const disableGoogleCalendar = gql`
      mutation disableGoogleCalendar {
        disableGoogleCalendar {
          employee {
            uid
            googleCalendarActive
            googleToken {
              uid
              name
              email
              profilePictureUrl
            }
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ disableGoogleCalendar: { employee: User } }>({
        mutation: disableGoogleCalendar
      })
      .pipe(
        map(({ data }) =>
          // @ts-ignore
          data.disableGoogleCalendar.employee)
      );
  }
}
