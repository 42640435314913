import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Credentials, CredentialsResponse, LoginData} from '../models/session';
import {UserStore} from '../stores/user.store';
import {Observable} from 'rxjs';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';

const credentialsKey = "credentials";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private credentials$: Credentials | null;

  constructor(private router: Router, private userStore: UserStore, private apollo: Apollo) {
    const savedCredentials =
        sessionStorage.getItem(credentialsKey) ||
        localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this.credentials$ = JSON.parse(savedCredentials);
    }
  }

  logout(): void {
    this.setCredentials();
    this.userStore.logoutUser();
    this.router.navigate(["./session"], { queryParamsHandling: "merge" }).then();
  }

  resetSession(): void {
    this.setCredentials();
  }

  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  get credentials(): Credentials | null {
    return this.credentials$;
  }

  login(context: LoginData): Observable<CredentialsResponse> {
    const authToken = gql`
        mutation authToken($email: String!, $password: String!) {
            authToken(email: $email, password: $password) {
                token
            }
        }
    `;
    return this.apollo
        .mutate<any>({
          mutation: authToken,
          variables: {
            email: context.email ? context.email.toLocaleLowerCase() : null,
            password: context.password
          }
        })
        .pipe(
            map(response => {
              const credentials: Credentials = {
                email: context.email,
                authToken: response.data.authToken.token
              };
              this.setCredentials(credentials, context.remember);
              response.data.email = context.email;
              return response.data;
            })
        );
  }

  checkSession(): Observable<{ token: string } | undefined> {
    const verifyToken = gql`
        mutation verifyToken($token: String!) {
            verifyToken(token: $token) {
                token
            }
        }
    `;
    return this.apollo
        .mutate<{ verifyToken: { token: string } }>({
          mutation: verifyToken,
          variables: {
            token: this.credentials?.authToken || ''
          }
        })
        .pipe(
            map(({ data }) => {
              return data?.verifyToken;
            })
        );
  }

  setCredentials(credentials?: Credentials, remember?: boolean) {
    this.credentials$ = credentials || null;
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  sendCode(email: string): Observable<string | undefined> {
    const sendRecoveryEmail = gql`
      mutation sendRecoveryEmail($email: String!) {
        sendRecoveryEmail(email: $email) {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{sendRecoveryEmail: {message: string}}>({
        mutation: sendRecoveryEmail,
        variables: {
          email
        }
      })
      .pipe(
        map(response => {
          return response.data?.sendRecoveryEmail.message
        })
      );
  }

  resetPassword(form: any): Observable<string | undefined> {
    const sendRecoveryEmail = gql`
      mutation resetPassword($password: String!, $recoveryId: String!) {
        resetPassword(password: $password, recoveryId: $recoveryId) {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{resetPassword: {message: string}}>({
        mutation: sendRecoveryEmail,
        variables: {
          ...form
        }
      })
      .pipe(
        map(response => {
          return response.data?.resetPassword.message
        })
      );
  }
}
