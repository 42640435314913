import {Injectable} from '@angular/core';
import {AbsenceRequestsTableFilter} from '../models/absence-requests-table-filter';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {AbsenceRequestsTable} from '../models/absence-requests-table';
import {AbsenceRequest} from '../models/absence-request';
import {DateHelperService} from './date-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AbsenceRequestService {

  constructor(
      private apollo: Apollo,
      private dateHelper: DateHelperService
  ) { }

  getAbsenceRequestsTable(filters: AbsenceRequestsTableFilter): Observable<AbsenceRequestsTable> {
      if (filters.startDate)
          filters.startDate = this.dateHelper.formatDate(filters.startDate);
      if (filters.endDate)
          filters.endDate = this.dateHelper.formatDate(filters.endDate);
      return this.apollo.query<{absenceRequestsTable: AbsenceRequestsTable}>({
          query: gql`
              query absenceRequestsTable(
                  $substring: String
                  $teamUid: String
                  $startDate: String
                  $endDate: String
                  $skip: Int
                  $limit: Int
                  $sort: String
                  $order: String
                  $eventType: String
              ) {
                  absenceRequestsTable(
                      substring: $substring
                      teamUid: $teamUid
                      skip: $skip
                      limit: $limit
                      sort: $sort
                      startDate: $startDate
                      endDate: $endDate
                      order: $order
                      eventType: $eventType
                  ) {
                      absenceRequestsList{
                          uid
                          numberId
                          description
                          approved
                          fromDate
                          toDate
                          absenceType {
                              name
                              vacation
                              paid
                              colour
                              uid
                          }
                          employee {
                              uid
                              firstName
                              lastName
                              email
                              photo {
                                  url
                              }
                            remainingVacationDays
                          }
                          approvedBy {
                              uid
                              firstName
                              lastName
                              email
                              photo {
                                  url
                              }
                          }
                          evidences {
                              uid
                              name
                              extension
                              url
                          }
                      }
                      count
                  }
              }
          `,
          variables: {...filters}
      }).pipe(
          map(({data}) => {
              return data.absenceRequestsTable;
          })
      );
  }

  denyAbsenceRequest(absenceRequestUid: string | undefined): Observable<AbsenceRequest | undefined> {
        return this.apollo.mutate<{ denyAbsenceRequest: { absenceRequest: AbsenceRequest } }>({
            mutation: gql`
                mutation denyAbsenceRequest(
                    $absenceRequestUid: String!
                ) {
                    denyAbsenceRequest(
                        absenceRequestUid: $absenceRequestUid
                    ) {
                        absenceRequest {
                            uid
                            numberId
                            description
                            approved
                            fromDate
                            toDate
                            absenceType {
                                name
                                vacation
                                paid
                                colour
                                uid
                            }
                            employee {
                                uid
                                firstName
                                lastName
                                email
                                photo {
                                    url
                                }
                            }
                            approvedBy {
                                uid
                                firstName
                                lastName
                                email
                                photo {
                                    url
                                }
                            }
                            evidences {
                                uid
                                name
                                extension
                                url
                            }
                        }
                    }
                }
            `,
            variables: {absenceRequestUid}
        }).pipe(
            map(({data}) => {
                return data?.denyAbsenceRequest.absenceRequest;
            })
        );
    }

  acceptAbsenceRequest(absenceRequestUid: string | undefined): Observable<AbsenceRequest | undefined> {
      return this.apollo.mutate<{acceptAbsenceRequest: {absenceRequest: AbsenceRequest}}>({
          mutation: gql`
              mutation acceptAbsenceRequest(
                  $absenceRequestUid: String!
              ) {
                  acceptAbsenceRequest(
                      absenceRequestUid: $absenceRequestUid
                  ) {
                      absenceRequest {
                          uid
                          numberId
                          description
                          approved
                          fromDate
                          toDate
                          absenceType {
                              name
                              vacation
                              paid
                              colour
                              uid
                          }
                          employee {
                              uid
                              firstName
                              lastName
                              email
                              photo {
                                  url
                              }
                          }
                          approvedBy {
                              uid
                              firstName
                              lastName
                              email
                              photo {
                                  url
                              }
                          }
                          evidences {
                              uid
                              name
                              extension
                              url
                          }
                      }
                  }
              }
          `,
          variables: {absenceRequestUid}
      }).pipe(
          map(({data}) => {
              return data?.acceptAbsenceRequest.absenceRequest;
          })
      );
  }

  getEmployeeAbsences(employeeUid: string | undefined): Observable<any> {
    const employeeAbsences = gql`
      query employeeAbsences($employeeUid: String!) {
        current: employeeAbsences(employeeUid: $employeeUid absenceType: "current") {
          uid
          numberId
          description
          approved
          fromDate
          toDate
          evidences {
            name
            extension
            url
          }
          absenceType {
            colour
            name
            vacation
            paid
            uid
          }
          employee {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
            remainingVacationDays
          }
          approvedBy {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
          }
        },
        upcoming: employeeAbsences(employeeUid: $employeeUid absenceType: "upcoming") {
          uid
          numberId
          description
          approved
          fromDate
          toDate
          evidences {
            name
            extension
            url
          }
          absenceType {
            colour
            name
            vacation
            paid
            uid
          }
          employee {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
            remainingVacationDays
          }
          approvedBy {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
          }
        },
        last: employeeAbsences(employeeUid: $employeeUid absenceType: "last") {
          uid
          numberId
          description
          approved
          fromDate
          toDate
          evidences {
            name
            extension
            url
          }
          absenceType {
            colour
            name
            vacation
            paid
            uid
          }
          employee {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
            remainingVacationDays
          }
          approvedBy {
            uid
            firstName
            lastName
            email
            photo {
              url
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{ employeeAbsences: any }>({
        query: employeeAbsences,
        variables: {
          employeeUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data;
        })
      );
  }
}
