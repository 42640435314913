import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Announcement} from '../../../core/models/announcement';
import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {EditAnnouncementModalComponent} from '../../modals/edit-announcement-modal/edit-announcement-modal.component';
import {AnnouncementDetailsComponent} from '../../modals/announcement-details/announcement-details.component';
import {AnnouncementService} from '../../../core/services/announcement.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-announcement-box',
  templateUrl: './announcement-box.component.html',
  styleUrls: ['./announcement-box.component.scss']
})
export class AnnouncementBoxComponent implements OnInit, OnDestroy {
  $onDestroy: Subject<void> = new Subject<void>();

  @Input() announcement: Announcement;
  @Output() announcementDeleted: EventEmitter<Announcement> = new EventEmitter<Announcement>();
  isLoading: boolean;

  constructor(
      private drawerService: NzDrawerService,
      private announcementService: AnnouncementService,
      private notificationService: NzNotificationService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  openEditAnnouncement() {
    const modal = this.drawerService.create({
      nzContent: EditAnnouncementModalComponent,
      nzTitle: 'Editar anuncio',
      nzContentParams: {
        announcement: this.announcement
      },
      nzWidth: 504
    });

    modal.afterOpen.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.announcementEdited
          .pipe(takeUntil(this.$onDestroy))
          .subscribe((editedAnnouncement: Announcement) => {
            this.announcement = editedAnnouncement;
          })
    })
  }

  deleteAnnouncement() {
    this.isLoading = true;
    this.announcementService.deleteAnnouncement(this.announcement.uid).pipe(
        finalize(() => {
          this.isLoading = false;
        })
    ).subscribe(message => {
      this.announcementDeleted.emit(this.announcement);
      this.notificationService.success('Anuncio eliminado', 'El anuncio se ha eliminado con éxito');
    }, error => {
      this.notificationService.error('Error al eliminar anuncio', error);
    })
  }

  openAnnouncement() {
    const modal = this.drawerService.create({
      nzContent: AnnouncementDetailsComponent,
      nzTitle: this.announcement.title,
      nzContentParams: {
        announcement: this.announcement
      },
      nzWidth: 504
    });
  }
}
