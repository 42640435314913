import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {File as SelfFile} from '../../../core/models/file';
import {finalize} from 'rxjs/operators';
import {FileService} from '../../../core/services/file.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  isLoading: boolean;

  @Input() folder: string;

  @Output() fileUploaded: EventEmitter<SelfFile> = new EventEmitter<SelfFile>();

  constructor(
      private fileService: FileService,
      private notificationService: NzNotificationService
  ) { }

  ngOnInit(): void {
  }

  uploadImage($event: any) {
    this.isLoading = true;
    const file: File = $event.target.files[0];
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], file.name, { type: file.type });
    this.fileService
        .uploadImage(this.folder, newFile)
        .pipe(
            finalize(() => {
              this.isLoading = false;
            })
        )
        .subscribe(
            (file: any) => {
              this.fileUploaded.emit(file);
            },
            error => {
              this.notificationService.create(
                  "error",
                  "Error al subir el archivo",
                  error.message.replace("GraphQL error:", "").trim(),
                  {
                    nzPlacement: 'topLeft'
                  }
              )
            }
        );
  }

}
