import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {Apollo} from "apollo-angular";
import {Position} from "../models/position";
import {PositionTable} from "../models/position-table";

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  constructor(private apollo: Apollo) { }

  getPositions(): Observable<Position[] | null> {
    return this.apollo.query<{positions: Position[]}>({
      query: gql`
        query positions {
          positions {
            uid
            name
          }
        }
      `
    }).pipe(
      map(({data}) => {
        return data.positions;
      })
    )
  }

  createNewPositions(form: any): Observable<Position | null> {
    return this.apollo.mutate<{createPosition: { position: Position }}>({
      mutation: gql`
        mutation createPosition(
            $name: String!
            $salaryData: SalaryInputType!
            $scheduleData: ScheduleInputType!
            $toleranceMinutes: Int!
        ) {
          createPosition(
            name: $name
            salaryData: $salaryData
            scheduleData: $scheduleData
            toleranceMinutes: $toleranceMinutes
          ) {
            position {
              uid
              name
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.createPosition.position;
      })
    )
  }

  getPositionsTable(filter: any): Observable<PositionTable | null> {
    return this.apollo.query<{positionsTable: PositionTable}>({
      query: gql`
        query positionsTable($substring: String $skip: Int $limit: Int) {
          positionsTable(substring: $substring skip: $skip limit: $limit) {
            count
            positions {
              uid
              numberId
              name
              toleranceMinutes
              salary {
                paidAmount
              }
              employees {
                firstName
                lastName
                photo {
                  url
                }
              }
            }
          }
        }
      `,
      variables: {
        ...filter
      }
    }).pipe(
      map(({data}) => {
        return data.positionsTable;
      })
    )
  }

  getPosition(positionUid: string | undefined): Observable<Position | null> {
    return this.apollo.query<{position: Position}>({
      query: gql`
        query position($positionUid: String!) {
          position(positionUid: $positionUid) {
            uid
            numberId
            name
            toleranceMinutes
            salary {
              paidAmount
              imssPaidAmount
              hoursWorked
            }
            schedule {
              uid
              monday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              monday {
                active
                category
                requiresGeolocation
                turns {
                  fromHour
                  toHour
                }
              }
              tuesday {
                active
                category
                requiresGeolocation
                turns {
                  fromHour
                  toHour
                }
              }
              wednesday {
                active
                category
                requiresGeolocation
                turns {
                  fromHour
                  toHour
                }
              }
              thursday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              friday {
                active
                category
                requiresGeolocation
                turns {
                  fromHour
                  toHour
                }
              }
              saturday {
                active
                category
                requiresGeolocation
                turns {
                  fromHour
                  toHour
                }
              }
              sunday {
                active
                category
                requiresGeolocation
                turns {
                  fromHour
                  toHour
                }
              }
            }
          }
        }
      `,
      variables: {
        positionUid
      }
    }).pipe(
      map(({data}) => {
        return data.position;
      })
    )
  }

  deletePosition(positionUid: string | undefined): Observable<string | null> {
    return this.apollo.mutate<{deletePosition: {message: string}}>({
      mutation: gql`
        mutation deletePosition($positionUid: String!) {
          deletePosition(positionUid: $positionUid) {
            message
          }
        }
      `,
      variables: {
        positionUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deletePosition.message;
      })
    )
  }

  updatePosition(form: any): Observable<Position | null> {
    return this.apollo.mutate<{updatePosition: {position: Position}}>({
      mutation: gql`
        mutation updatePosition(
          $affectsEveryoneSalary: Boolean!
          $affectsEveryoneSchedule: Boolean!
          $name: String
          $salaryData: SalaryInputType!
          $positionUid: String!
          $scheduleData: ScheduleInputType!
          $toleranceMinutes: Int
        ) {
          updatePosition(
            affectsEveryoneSalary: $affectsEveryoneSalary
            affectsEveryoneSchedule: $affectsEveryoneSchedule
            name: $name
            salaryData: $salaryData
            positionUid: $positionUid
            scheduleData: $scheduleData
            toleranceMinutes: $toleranceMinutes
          ) {
            position {
              uid
              numberId
              name
              toleranceMinutes
              salary {
                paidAmount
              }
              employees {
                firstName
                lastName
                photo {
                  url
                }
              }
              schedule {
                uid
                monday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
                monday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
                tuesday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
                wednesday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
                thursday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
                friday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
                saturday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
                sunday {
                  active
                  category
                  requiresGeolocation
                  turns {
                    uid
                    fromHour
                    toHour
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updatePosition.position;
      })
    )
  }
}
