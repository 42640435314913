import { Injectable } from '@angular/core';
import {Color} from '../models/color';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  colors: Color[] = [
    { value: '#D62828', label: 'rojo'},
    { value: '#FC7171', label: 'fresa'},
    { value: '#E36414', label: 'cobre'},
    { value: '#F79D65', label: 'mamey'},
    { value: '#FF9F1C', label: 'naranja'},
    { value: '#FFD131', label: 'amarillo'},
    { value: '#B9CF72', label: 'verde oliva'},
    { value: '#76CF72', label: 'verde'},
    { value: '#41AF90', label: 'verde esmeralda'},
    { value: '#80DEDA', label: 'azul celeste'},
    { value: '#95BAFF', label: 'azul'},
    { value: '#3386BD', label: 'azul cobalto'},
    { value: '#7B2CBF', label: 'morado'},
    { value: '#9596FF', label: 'morado claro'},
    { value: '#B795FF', label: 'lila'},
    { value: '#E359F5', label: 'purpura'},
    { value: '#F495FF', label: 'rosa caramelo'},
    { value: '#FF95BB', label: 'rosa'},
    { value: '#B07D62', label: 'cafe'},
    { value: '#BCB8B1', label: 'gris claro'},
    { value: '#888888', label: 'gris plata'},
    { value: '#4F4F4F', label: 'gris oscuro'},
    { value: '#000000', label: 'negro'}
  ]

  constructor() { }

  getColors(): Color[] {
    return [...this.colors];
  }
}
