import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Deduction} from "../../../core/models/deduction";
import {DeductionsService} from "../../../core/services/deductions.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {DeductionConcept} from "../../../core/models/deduction-concept";
import {finalize, takeUntil} from "rxjs/operators";
import {EditDeductionModalComponent} from "../../modals/edit-deduction-modal/edit-deduction-modal.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-deduction-row',
  templateUrl: './deduction-row.component.html',
  styleUrls: ['./deduction-row.component.scss']
})
export class DeductionRowComponent implements OnInit, OnDestroy {
  @Input() deductionConcepts: DeductionConcept[];
  @Input() deduction: Deduction | null;
  @Output() removeDeduction = new EventEmitter<void>();
  isDeleting = false;
  $onDestroy: Subject<void> = new Subject<void>();

  constructor(private deductionsService: DeductionsService,
              private notification: NzNotificationService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  deleteDeduction() {
    this.isDeleting = true;
    this.deductionsService.deleteDeduction(this.deduction?.uid)
      .pipe(finalize(() => {
        this.isDeleting = false;
      }))
      .subscribe((message: string) => {
        this.notification.create('success', '¡Operación exitosa!', 'Deducción eliminada correctamente');
        this.removeDeduction.emit();
      }, error => {
        this.notification.create('error', 'Error al eliminar la deducción', error.message.replace("GraphQL error:", "").trim());
      });
  }

  editDeduction() {
    const modal = this.drawerService.create({
      nzContent: EditDeductionModalComponent,
      nzTitle: 'Editar deducción',
      nzWidth: 504,
      nzContentParams: {
        deduction: this.deduction,
        deductionConcepts: this.deductionConcepts
      }
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((deduction: Deduction) => {
      if (deduction) {
        this.deduction = deduction;
      }
    })
  }

}
