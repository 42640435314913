<div class="position-details">
  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <nz-spin class="spin-loading" [nzSpinning]="isLoading">

    <div class="info-container" *ngIf="!isLoading && position && !error">
      <div class="box-container">
        <i nz-icon nzType="icons:money-bag"></i>
        <div class="info-box">
          <div class="title">Salario</div>
          <div class="subtitle">{{ position?.salary?.paidAmount | currency }} {{ position?.salary?.paidAmount === 1 ? 'peso' : 'pesos' }} al mes</div>
        </div>
      </div>

      <div class="box-container">
        <i nz-icon nzType="clock-circle" nzTheme="fill"></i>
        <div class="info-box">
          <div class="title">Tolerancia</div>
          <div class="subtitle">{{ position?.toleranceMinutes }} {{ position?.toleranceMinutes === 1 ? 'minuto' : 'minutos' }}</div>
        </div>
      </div>
    </div>

    <div class="title-box" *ngIf="!isLoading && position && !error">
      <div class="title">Horario</div>
    </div>

    <div class="table-box" *ngIf="!isLoading && position && !error">
      <div class="schedule-table">
        <!--<div class="header-row">
          <div class="day-column header">
            <span>Día</span>
          </div>
          <div class="type-column header">
            <span>Tipo</span>
          </div>
          <div class="schedule-column header">
            <span></span>
          </div>
        </div>-->

        <div class="schedule-row" *ngIf="position?.schedule?.monday?.active">
          <div class="day-column row">
            <span class="day-title">LUN</span>
          </div>
          <div class="type-column row">
            <span class="type-title">{{ position?.schedule?.monday?.category }}</span>
          </div>
          <div class="schedule-column row schedule-box">
            <div class="schedule-block type-title" *ngFor="let turn of position?.schedule?.monday?.turns">
              {{ turn?.fromHour }} - {{ turn?.toHour }}
            </div>
          </div>
        </div>

        <div class="schedule-row" *ngIf="position?.schedule?.tuesday?.active">
          <div class="day-column row">
            <span class="day-title">MAR</span>
          </div>
          <div class="type-column row">
            <span class="type-title">{{ position?.schedule?.tuesday?.category }}</span>
          </div>
          <div class="schedule-column row schedule-box">
            <div class="schedule-block type-title" *ngFor="let turn of position?.schedule?.tuesday?.turns">
              {{ turn?.fromHour }} - {{ turn?.toHour }}
            </div>
          </div>
        </div>

        <div class="schedule-row" *ngIf="position?.schedule?.wednesday?.active">
          <div class="day-column row">
            <span class="day-title">MIE</span>
          </div>
          <div class="type-column row">
            <span class="type-title">{{ position?.schedule?.wednesday?.category }}</span>
          </div>
          <div class="schedule-column row schedule-box">
            <div class="schedule-block type-title" *ngFor="let turn of position?.schedule?.wednesday?.turns">
              {{ turn?.fromHour }} - {{ turn?.toHour }}
            </div>
          </div>
        </div>

        <div class="schedule-row" *ngIf="position?.schedule?.thursday?.active">
          <div class="day-column row">
            <span class="day-title">JUE</span>
          </div>
          <div class="type-column row">
            <span class="type-title">{{ position?.schedule?.thursday?.category }}</span>
          </div>
          <div class="schedule-column row schedule-box">
            <div class="schedule-block type-title" *ngFor="let turn of position?.schedule?.thursday?.turns">
              {{ turn?.fromHour }} - {{ turn?.toHour }}
            </div>
          </div>
        </div>

        <div class="schedule-row" *ngIf="position?.schedule?.friday?.active">
          <div class="day-column row">
            <span class="day-title">VIE</span>
          </div>
          <div class="type-column row">
            <span class="type-title">{{ position?.schedule?.friday?.category }}</span>
          </div>
          <div class="schedule-column row schedule-box">
            <div class="schedule-block type-title" *ngFor="let turn of position?.schedule?.friday?.turns">
              {{ turn?.fromHour }} - {{ turn?.toHour }}
            </div>
          </div>
        </div>

        <div class="schedule-row" *ngIf="position?.schedule?.saturday?.active">
          <div class="day-column row">
            <span class="day-title">SAB</span>
          </div>
          <div class="type-column row">
            <span class="type-title">{{ position?.schedule?.saturday?.category }}</span>
          </div>
          <div class="schedule-column row schedule-box">
            <div class="schedule-block type-title" *ngFor="let turn of position?.schedule?.saturday?.turns">
              {{ turn?.fromHour }} - {{ turn?.toHour }}
            </div>
          </div>
        </div>

        <div class="schedule-row" *ngIf="position?.schedule?.sunday?.active">
          <div class="day-column row">
            <span class="day-title">DOM</span>
          </div>
          <div class="type-column row">
            <span class="type-title">{{ position?.schedule?.sunday?.category }}</span>
          </div>
          <div class="schedule-column row schedule-box">
            <div class="schedule-block type-title" *ngFor="let turn of position?.schedule?.sunday?.turns">
              {{ turn?.fromHour }} - {{ turn?.toHour }}
            </div>
          </div>
        </div>

      </div>
    </div>

  </nz-spin>
</div>
