import { Injectable } from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";
import {DeductionsTable} from "../models/deductions-table";
import {DeductionConcept} from "../models/deduction-concept";
import {Deduction} from "../models/deduction";

@Injectable({
  providedIn: 'root'
})
export class DeductionsService {

  constructor(private apollo: Apollo) { }

  getDeductions(filters: any, defaultOnly: boolean | null = null): Observable<DeductionsTable | undefined> {
    return this.apollo.query<{deductionTable: DeductionsTable}>({
      query: gql`
        query deductionTable($substring: String $skip: Int $limit: Int, $defaultOnly: Boolean) {
          deductionTable(substring: $substring skip: $skip limit: $limit defaultOnly: $defaultOnly) {
            count
            deductions {
              uid
              name
              amount
              colour
              icon {
                uid
                url
              }
              conceptCode
              dataType
              isDefault
              conceptString
            }
          }
        }
      `,
      variables: {
        ...filters,
        defaultOnly
      }
    }).pipe(
      map(({data}) => {
        data.deductionTable.deductions?.forEach(deduction => {
          deduction.editAmount = deduction.amount;
        });
        return data.deductionTable;
      })
    )
  }

  getConcepts(): Observable<DeductionConcept[]> {
    return this.apollo.query<{deductionConcepts: DeductionConcept[]}>({
      query: gql`
        query deductionConcepts {
          deductionConcepts {
            id
            description
          }
        }
      `,
    }).pipe(
      map(({data}) => {
        return data.deductionConcepts;
      })
    )
  }

  createDeduction(filters: any): Observable<Deduction> {
    return this.apollo.mutate<{createDeduction: {deduction: Deduction}}>({
      mutation: gql`
        mutation createDeduction(
            $amount: Float!
            $colour: String!
            $conceptCode: String!
            $iconUid: String!
            $name: String!
        ) {
          createDeduction(
            amount: $amount
            colour: $colour
            conceptCode: $conceptCode
            iconUid: $iconUid
            name: $name
          ) {
            deduction {
              uid
              name
              amount
              colour
              icon {
                uid
                url
              }
              conceptCode
              dataType
              isDefault
              conceptString
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        data.createDeduction.deduction.editAmount = data.createDeduction.deduction.amount;
        // @ts-ignore
        return data.createDeduction.deduction;
      })
    )
  }

  updateDeduction(filters: any): Observable<Deduction> {
    return this.apollo.mutate<{updateDeduction: {deduction: Deduction}}>({
      mutation: gql`
        mutation updateDeduction(
          $amount: Float!
          $colour: String!
          $conceptCode: String!
          $iconUid: String!
          $name: String!
          $deductionUid: String!
        ) {
          updateDeduction(
            amount: $amount
            colour: $colour
            conceptCode: $conceptCode
            iconUid: $iconUid
            name: $name
            deductionUid: $deductionUid
          ) {
            deduction {
              uid
              name
              amount
              colour
              icon {
                uid
                url
              }
              conceptCode
              dataType
              isDefault
              conceptString
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        data.createDeduction.deduction.editAmount = data.createDeduction.deduction.amount;
        // @ts-ignore
        return data.updateDeduction.deduction;
      })
    )
  }

  deleteDeduction(deductionUid: string | undefined): Observable<string> {
    return this.apollo.mutate<{deleteDeduction: {message: string}}>({
      mutation: gql`
        mutation deleteDeduction($deductionUid: String!) {
          deleteDeduction(deductionUid: $deductionUid) {
            message
          }
        }
      `,
      variables: {
        deductionUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.deleteDeduction.message;
      })
    )
  }
}
