<div class="error-box" *ngIf="error && !isLoading">
  <nz-alert
    nzType="error"
    [nzMessage]="error"
    nzShowIcon
  ></nz-alert>
</div>

<nz-spin class="spin-loading" [nzSpinning]="isLoading">
  <div class="type-switcher flex center align-center" *ngIf="!isLoading && packages && !error">
    <span>Mensual</span>
    <nz-switch [ngModelOptions]="{standalone: true}" (ngModelChange)="filterPackagesBy($event)" [(ngModel)]="yearly"></nz-switch>
    <div class="special-charge flex column">
      <span>Anual</span>
      <span class="special-text">Ahorras 17%</span>
    </div>
  </div>

  <div class="plans-row flex center wrap">
    <div class="plan-box" (click)="setPlan(plan)" *ngFor="let plan of filterPackages">
      <div class="package-box flex column align-center">
        <div class="package-title">
          Plan {{plan.name | lowercase}}
        </div>

        <div class="package-card" [ngClass]="{'active': plan.uid === selectedPackage?.uid}">
          <div class="price">
            ${{plan.employeePrice | number}}
          </div>
          <div class="type">
            por empleado {{plan.temporalChargeType | lowercase}}
          </div>
          <div class="features flex column">
            <div class="feature" *ngFor="let feature of plan.availableModules">
              - {{feature}}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</nz-spin>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="close()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isLoading" (click)="updatePlan()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>


