import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class NavigationModule {
  constructor(
      private router: Router,
  ) {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      const content = document.getElementById("shell-box");
      if (content) {
        content.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
    });
  }

}
