<div class="appeal-row flex between wrap align-center">
  <div class="appeal-info-box">
    <i nz-icon nzType="icons:shapes"
       [ngClass]="{
       'red': appeal?.approved !== null && !appeal?.approved,
       'orange': appeal?.approved === null,
       'green': appeal?.approved !== null && appeal?.approved}"
       nzTooltipColor="white"
      [nz-tooltip]="(appeal?.approved !== null && !appeal?.approved) ? 'Rechazada' :
      (appeal?.approved !== null && appeal?.approved) ? 'Aprobada' : 'Pendiente'">
    </i>
    <div class="number">Aclaración <span (click)="openAppealDetails()">#{{ appeal?.numberId }}</span></div>
  </div>

  <div class="user-info-box" *ngIf="!showDescription">
    <div class="name">{{ appeal?.issuedBy?.firstName }} {{ appeal?.issuedBy?.lastName }}</div>
  </div>

  <div class="date-content">
    <div class="date">{{ appeal?.createdDate | date: 'dd' }} de {{ appeal?.createdDate | date: 'MMM' }} {{ appeal?.createdDate | date: 'yyyy' }}</div>
  </div>

  <div class="user-description-box" *ngIf="showDescription">
    <div class="description">{{ (appeal?.description | limitTo: 164 : true) || 'Sin descripción' }}</div>
  </div>

  <div class="time-content">
    <div class="turn-box">
      <ng-container>
        <div class="time-box active" [ngClass]="{
        'green': (appeal?.attendance?.onTime && appeal?.attendance?.onLocation),
        'yellow': (!appeal?.attendance?.onTime || !appeal?.attendance?.onLocation),
        'red': (!appeal?.attendance?.onTime && !appeal?.attendance?.onLocation)
        }"
             nzTooltipColor="white" [nz-tooltip]="getTooltip()">
          <span>{{ appeal?.attendance?.timestamp | date: 'HH:mm' }}</span>

          <div class="icon-row flex end" *ngIf="(!appeal?.attendance?.onTime || !appeal?.attendance?.onLocation)">
            <div class="icon" *ngIf="!appeal?.attendance?.onLocation && !appeal?.attendance?.onTime">
              <i nz-icon nzType="exclamation-circle" nzTheme="fill" class="yellow-s"></i>
            </div>
            <div class="icon" *ngIf="!appeal?.attendance?.onTime && appeal?.attendance?.onLocation">
              <div class="img-box">
                <img src="../assets/images/clock-y.png">
              </div>
            </div>
            <div class="icon" *ngIf="!appeal?.attendance?.onLocation && appeal?.attendance?.onTime">
              <i nz-icon nzType="icons:location-pin" class="yellow-s"></i>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container>
    <div class="appeal-action flex align-center">
      <i nz-icon nzType="unordered-list" (click)="openAppealDetails()"></i>
    </div>
  </ng-container>
</div>
