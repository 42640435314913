import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MeetingsService} from "../../../core/services/meetings.service";
import {CountryStore} from "../../../core/stores/country.store";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {AddressService} from "../../../core/services/address.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {DatePipe} from "@angular/common";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {combineLatest, Observable, Subject} from "rxjs";
import {Country} from "../../../core/models/country";
import {Meeting} from "../../../core/models/meeting";
import {debounceTime, finalize, startWith, takeUntil} from "rxjs/operators";
import {Marker} from "../../../core/models/marker";
import {Address} from "../../../core/models/address";

@Component({
  selector: 'app-edit-meeting-modal',
  templateUrl: './edit-meeting-modal.component.html',
  styleUrls: ['./edit-meeting-modal.component.scss']
})
export class EditMeetingModalComponent implements OnInit, OnDestroy {
  @Input() meeting: Meeting | undefined;
  meetingForm: FormGroup;
  isLoading: boolean;
  error: string | null;
  marker: any;
  zipcodeLoading: boolean = false;
  zipcodeError: boolean = false;
  colonies: any[] = [];
  addressLoading: boolean;
  addressError: string | null;
  $onDestroy: Subject<void> = new Subject<void>();
  countries$: Observable<Country[] | undefined> = this.countryStore.getCountries();

  constructor(private meetingsService: MeetingsService,
              private countryStore: CountryStore,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private addressService: AddressService,
              private notification: NzNotificationService,
              private datePipe: DatePipe,
              private drawerRef: NzDrawerRef) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  close() {
    this.drawerRef.close(null);
  }

  onMarkerChange($event: Marker) {
    this.getAddressForm().controls.latitude.setValue($event.latitude);
    this.getAddressForm().controls.longitude.setValue($event.longitude);
  }

  getAddressForm(): FormGroup {
    return this.meetingForm.controls.addressData as FormGroup;
  }

  getEmployeeControl(): FormControl {
    return this.meetingForm.controls.participantsUid as FormControl;
  }

  updateMeeting() {
    if (this.meetingForm.valid) {
      this.isLoading = true;
      this.error = null;
      const data = this.cleanData(this.meetingForm.getRawValue());
      this.meetingsService.editMeeting(data)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        ).subscribe(
        (meeting: Meeting | undefined) => {
          if (meeting?.place?.address) {
            meeting.place.address.fullAddress = this.addressService.concatAddress(meeting.place.address);
          }
          this.notification.create('success', '¡Operación exitosa!', 'Junta actualizada correctamente');
          this.drawerRef.close(meeting);
        }, error => {
          this.error = error;
        }
      );
    } else {
      this.formHelper.markFormAsDirty(this.meetingForm);
      this.formHelper.markGroupAsDirty(this.meetingForm, 'addressData');
    }
  }

  private createForm() {
    this.meetingForm = this.formBuilder.group({
      meetingUid: [this.meeting?.uid],
      name: [this.meeting ? this.meeting?.name : null, Validators.required],
      participantsUid: [this.meeting ? this.meeting?.participants : []],
      startDate: [this.meeting ? this.setDate(this.meeting.startDate) : null, Validators.required],
      startHour: [this.meeting ? this.setHour(this.meeting.startHour) : null, Validators.required],
      addressData: this.formBuilder.group({
        latitude: [this.meeting ? this.meeting?.place?.address?.longitude : null],
        longitude: [this.meeting ? this.meeting?.place?.address?.latitude : null],
        country: [null, [Validators.required]],
        state: [this.meeting ? this.meeting?.place?.address?.state : null, [Validators.required]],
        colony: [null, [Validators.required]],
        city: [this.meeting ? this.meeting?.place?.address?.city : null, [Validators.required]],
        zipcode: [this.meeting ? this.meeting?.place?.address?.zipcode : null, [Validators.required]],
        street: [this.meeting ? this.meeting?.place?.address?.street : null, [Validators.required]],
        interiorNumber: [this.meeting ? this.meeting?.place?.address?.interiorNumber : null],
        exteriorNumber: [this.meeting ? this.meeting?.place?.address?.exteriorNumber : null, [Validators.required]]
      })
    });

    this.getAddressForm().controls.zipcode.valueChanges
      .pipe(
        takeUntil(this.$onDestroy),
        debounceTime(500)
      ).subscribe(zipcode => {
      if (zipcode && this.getAddressForm().controls.country.value) {
        this.getColonies(zipcode, this.getAddressForm().controls.country.value);
      }
    });

    this.getAddressForm().controls.country.valueChanges
      .pipe(
        takeUntil(this.$onDestroy)
      ).subscribe(country => {
      if (country && this.getAddressForm().controls.zipcode.value) {
        this.getColonies(this.getAddressForm().controls.zipcode.value, country);
      }
    });

    combineLatest([
      this.getAddressForm().controls.country.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.state.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.colony.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.city.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.zipcode.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.street.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.interiorNumber.valueChanges.pipe(startWith(null)),
      this.getAddressForm().controls.exteriorNumber.valueChanges.pipe(startWith(null))
    ])
      .pipe(
        takeUntil(this.$onDestroy),
        debounceTime(500)
      ).subscribe(
      (changes) => {
        if (this.getAddressForm().valid) {
          this.getCoordinates();
          if (this.meeting === null) {
            this.getCoordinates();
          } else {
            if (this.meeting?.place?.address?.latitude !== 0 && this.meeting?.place?.address?.longitude !== 0) {
              this.marker = {
                latitude: this.meeting?.place?.address?.latitude,
                longitude: this.meeting?.place?.address?.longitude
              };
            } else {
              this.getCoordinates();
            }
          }
        }
      });

    if (this.meeting !== null) {
      this.getCountry();
    }
  }

  private setDate(date: any) {
    const correctDate = new Date(date);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    return correctDate;
  }

  private setHour(hour: any) {
    const split = hour.split(':');
    const today = new Date();
    today.setHours(+(split[0]));
    today.setMinutes(+(split[1]));
    return today;
  }

  private getCountry() {
    this.countries$.subscribe(countries => {
      if (countries) {
        const index = countries.findIndex(c => c.countryName === this.meeting?.place?.address?.country);
        if (index !== -1) {
          this.getAddressForm().controls.country.setValue(countries[index].countryCode);
        } else {
          this.getAddressForm().controls.country.setValue('MX');
        }
        if (this.meeting !== null) {
          if (this.getAddressForm().controls.zipcode.value && this.getAddressForm().controls.country.value) {
            this.getColonies(this.getAddressForm().controls.zipcode.value, this.getAddressForm().controls.country.value);
          }
        }
      }
    });
  }

  private setAddress(address: Address | null) {
    if (address) {
      this.zipcodeError = false;
      this.colonies = address.colony;
      if (this.meeting === null) {
        this.getAddressForm().controls.state.setValue(address.state);
        this.getAddressForm().controls.city.setValue(address.city);
        this.getAddressForm().controls.colony.setValue(address.colony[0]);
      } else {
        const index = address.colony.findIndex((c: string) => c === this.meeting?.place?.address?.colony);
        if (index !== -1) {
          this.getAddressForm().controls.colony.setValue(address.colony[index]);
        } else {
          this.getAddressForm().controls.colony.setValue(address.colony[0]);
        }
      }
    } else {
      this.zipcodeError = true;
      this.colonies = [];
      this.getAddressForm().controls.colony.setValue(null);
      this.getAddressForm().controls.state.setValue(null);
      this.getAddressForm().controls.city.setValue(null);
    }
  }

  private getColonies(zipcode: string, country: string) {
    this.zipcodeLoading = true;
    this.zipcodeError = false;
    this.addressService.getAddressByZipcode(zipcode, country)
      .pipe(
        finalize(() => {
          this.zipcodeLoading = false;
        })
      )
      .subscribe(
        address => {
          this.setAddress(address);
        }
      )
  }

  private getCoordinates() {
    this.addressLoading = true;
    this.addressError = null;
    this.addressService.getCoordinates(this.getAddressForm().getRawValue())
      .pipe(
        finalize(() => {
          this.addressLoading = false;
        })
      ).subscribe(coordinates => {
      this.marker = coordinates ? coordinates : null;
      if (coordinates) {
        this.getAddressForm().controls.latitude.setValue(coordinates.latitude);
        this.getAddressForm().controls.longitude.setValue(coordinates.longitude);
      }
    }, error => {
      this.addressError = error;
    })
  }

  private cleanData(data: any) {
    const form = data;
    form.startDate = this.datePipe.transform(form.startDate, 'yyy-MM-dd');
    form.startHour = this.datePipe.transform(form.startHour, 'HH:mm');
    const uids: string[] = [];
    form.participantsUid.forEach((participant: any) => {
      uids.push(participant.uid);
    });
    form.participantsUid = uids;
    return form;
  }
}
