import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbsenceType} from '../../../core/models/absence-type';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {Color} from '../../../core/models/color';
import {ColorService} from '../../../core/services/color.service';
import {AbsenceTypeService} from '../../../core/services/absence-type.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-new-absence-type',
  templateUrl: './new-absence-type.component.html',
  styleUrls: ['./new-absence-type.component.scss']
})
export class NewAbsenceTypeComponent implements OnInit {

  newAbsenceType: FormGroup;

  @Output() absenceTypeCreated: EventEmitter<AbsenceType> = new EventEmitter<AbsenceType>();

  colors: Color[] = this.colorService.getColors();

  isLoading: boolean;
  error: string | null;

  constructor(
      private modalRef: NzModalRef,
      private formBuilder: FormBuilder,
      private formHelper: FormHelperService,
      private colorService: ColorService,
      private absenceTypeService: AbsenceTypeService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  destroyModal() {
    this.modalRef.close();
  }

  private createForm() {
    this.newAbsenceType = this.formBuilder.group({
      name: [null, Validators.required],
      colour: [null, Validators.required],
      paid: [false],
      vacation: [false]
    });
  }

  submitForm() {
    if (this.newAbsenceType.valid) {
      this.isLoading = true;
      this.error = null;
      this.absenceTypeService.createAbsenceTypes(this.cleanData())
          .pipe(
              finalize(() => {
                this.isLoading = false;
              })
          ).subscribe(absenceType => {
            this.absenceTypeCreated.emit(absenceType);
            this.destroyModal();
      }, error => {
            this.error = error;
      })
    } else {
      this.formHelper.markFormAsDirty(this.newAbsenceType);
    }
  }

  private cleanData(): AbsenceType {
    const data: AbsenceType = this.newAbsenceType.getRawValue();
    return data;
  }
}
