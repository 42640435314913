<div class="edit-actual-vacations">

  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <form nz-form nzLayout="vertical" [formGroup]="editVacationsForm">
    <nz-form-item>
      <nz-form-label>Vacaciones restantes</nz-form-label>
      <nz-form-control>

        <nz-input-number
          style="width: 100%"
          formControlName="actualVacations"
          [nzPlaceHolder]="'Días de vacaciones'"
          [OnlyNumber]="true"
          [nzMin]="0"
          [nzStep]="1"
          [nzFormatter]="formatter"
          [nzParser]="parser">
        </nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="btns-container">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="updateVacations()">Guardar</button>
  </div>
</div>
