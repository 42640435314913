import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Team} from '../../../core/models/team';
import {Router} from '@angular/router';
import {finalize, takeUntil} from 'rxjs/operators';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {EditTeamModalComponent} from '../../modals/edit-team-modal/edit-team-modal.component';
import {Subject} from 'rxjs';
import {TeamService} from '../../../core/services/team.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-team-box',
  templateUrl: './team-box.component.html',
  styleUrls: ['./team-box.component.scss']
})
export class TeamBoxComponent implements OnInit, OnDestroy {
  $onDestroy: Subject<void> = new Subject<void>();
  @Input() team: Team;
  @Output() teamDeleted: EventEmitter<Team> = new EventEmitter<Team>();

  isLoading: boolean;

  constructor(
      private router: Router,
      private drawerService: NzDrawerService,
      private teamService: TeamService,
      private notificationService: NzNotificationService
  ) { }

  ngOnInit(): void {
    if (this.team?.color?.charAt(0) !== '#') {
      this.team.color = '#' + this.team.color;
    }
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  goToTeamDetails() {
    this.router.navigate(['./teams/' + this.team.uid + '/details']).then();
  }

  goToTeamMembers() {
    this.router.navigate(['./teams/' + this.team.uid + '/members']).then();
  }

  openEditTeam() {
    const modal = this.drawerService.create({
      nzContent: EditTeamModalComponent,
      nzTitle: 'Editar equipo de trabajo',
      nzContentParams: {
        team: this.team
      },
      nzWidth: 504
    });

    modal.afterOpen.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.teamEdited
          .pipe(takeUntil(this.$onDestroy))
          .subscribe((editedTeam: Team) => {
            this.team = editedTeam;
          })
    })
  }

  deleteTeam() {
    this.isLoading = true;
    this.teamService.deleteTeam(this.team.uid).pipe(
        finalize(() => {
          this.isLoading = false;
        })
    ).subscribe(message => {
        this.teamDeleted.emit(this.team);
        this.notificationService.success('Equipo eliminado', 'El equipo se ha eliminado con éxito');
    }, error => {
      this.notificationService.error('Error al eliminar equipo', error);
    })
  }
}
