<div class="shell" id="shell-box">
  <div class="layout flex">
    <div class="side-bar-desktop desktop">
      <div class="side-bar flex column align-center hide-scrollbar">
        <div class="logo-icon">
          <div class="img-box">
            <img src="../assets/images/icon-white.png">
          </div>
        </div>

        <div class="links-box stretch flex column">
          <div class="link-box flex align-end" *ngFor="let page of pages">
            <div nz-tooltip  nzTooltipColor="white" [nzTooltipTitle]="page?.name" routerLinkActive="active" [routerLink]="[page.link]" class="link flex align-center center">
              <i class="page-icon" nz-icon [nzType]="page.icon"></i>
            </div>
          </div>
        </div>

        <div class="links-box flex column align-center">
          <div class="link-box flex align-end" *ngFor="let page of settings">
            <div nz-tooltip nzTooltipColor="white" [nzTooltipTitle]="page.name" routerLinkActive="active" [routerLink]="[page.link]" class="link flex align-center center">
              <i class="page-icon" nz-icon [nzType]="page.icon"></i>
            </div>
          </div>

          <div class="user-box flex align-center" nz-popover [nzPopoverContent]="contentTemplate">
            <div class="user-info">
              <div class="user-avatar">
                <nz-avatar [nzGap]="6" [nzSize]="25" [nzText]="($user | async) ? (($user | async)?.firstName?.charAt(0) + '' + ($user | async)?.lastName?.charAt(0)) : undefined" [nzSrc]="($user | async)?.photo?.url"></nz-avatar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #contentTemplate>
      <div class="user-menu flex column">
        <div (click)="logout()" class="user-option flex align-center">
          <i nz-icon nzType="logout" nzTheme="outline"></i> <span>Cerrar sesión</span>
        </div>
      </div>
    </ng-template>

    <div class="content-box stretch">
      <nz-layout>
        <router-outlet></router-outlet>
      </nz-layout>
    </div>
  </div>
</div>
