<div class="map-box-geo-fencing">
  <div class="map">
    <app-map [geoFencing]="geoFencing"></app-map>
  </div>

  <div class="locations-row flex wrap">
    <div class="title">{{ geoFencing?.name }}:</div>
    <div class="location-box flex">
      <div class="icon-p">
        <div class="img-box flex column center">
          <img src="../assets/images/pin-1.png">
        </div>
      </div>

      <div class="geo-name" (click)="openLocation()">
        {{ geoFencing?.fullAddress }}
      </div>

      <div class="btns-box" *ngIf="geoFencing?.borrowedFromTeam === null">
        <i *ngIf="geoFencing?.isLoading" class="icon loading-icon" nz-icon nzType="loading"></i>
        <i *ngIf="!geoFencing?.isLoading" class="icon edit-icon" nz-icon nzType="edit" nzTooltipColor="white" [nz-tooltip]="'Editar'" (click)="editGeofencing()"></i>
        <i *ngIf="!geoFencing?.isLoading" class="icon delete-icon" nz-icon nzType="delete" nzTooltipColor="white" [nz-tooltip]="'Eliminar'" nz-popconfirm="¿Seguro que deseas eliminar esta geocerca?" (nzOnConfirm)="deleteGeofencing()"></i>
      </div>
    </div>
  </div>
</div>
