import { Component, OnInit } from '@angular/core';
import {UserStore} from '../../../core/stores/user.store';
import {Observable} from 'rxjs';
import {User} from '../../../core/models/user';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {MenuLinksService} from '../../../core/services/menu-links.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  pages = this.menuLinks.pages;
  settings = this.menuLinks.settings;

  $user: Observable<User | null> = this.userStore.getUser();
  $userLoading: Observable<boolean | null> = this.userStore.getIsLoading();

  constructor(
      private userStore: UserStore,
      private authService: AuthenticationService,
      private menuLinks: MenuLinksService
  ) {}

  ngOnInit(): void {
    this.userStore.refreshUser();
  }

  logout() {
    this.authService.logout();
  }
}
