import {Component, Input, OnInit} from '@angular/core';
import {Meeting} from "../../../core/models/meeting";
import {MeetingDetailsModalComponent} from "../../modals/meeting-details-modal/meeting-details-modal.component";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {isToday, isBefore} from 'date-fns'

@Component({
  selector: 'app-meeting-box',
  templateUrl: './meeting-box.component.html',
  styleUrls: ['./meeting-box.component.scss']
})
export class MeetingBoxComponent implements OnInit {
  @Input() meeting: Meeting | undefined;
  @Input() showMeetingAttendance = true;

  constructor(private drawerService: NzDrawerService) { }

  ngOnInit(): void {
  }

  openMeeting() {
    const modal = this.drawerService.create({
      nzContent: MeetingDetailsModalComponent,
      nzTitle: '',
      nzWidth: 504,
      nzWrapClassName: 'meeting-details-wrap',
      nzClosable: false,
      nzContentParams: {
        meeting: this.meeting
      }
    });
  }

  isTodayOrBefore(): boolean {
    // @ts-ignore
    const correctDate = new Date(this.meeting.startDate);
    correctDate.setMinutes(correctDate.getMinutes() + correctDate.getTimezoneOffset());
    return (isToday(correctDate) && this.isPastHour()) || isBefore(correctDate, new Date());

  }

  isPastHour(): boolean {
    // @ts-ignore
    const splitTime = this.meeting.startHour?.split(':');
    const hour = new Date().getHours().toString();
    const minutes = new Date().getMinutes().toString();
    // @ts-ignore
    if (hour >= splitTime[0]) {
      // @ts-ignore
      if (hour === splitTime[0]) {
        // @ts-ignore
        return minutes > splitTime[1];
      } else {
        return false;
      }
    }
    return false;
  }
}
