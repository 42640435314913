import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {GeoLocation} from "../../../core/models/geo-location";
import {MapTypeStyle} from "@agm/core/services/google-maps-types";
import {Cluster} from "@google/markerclustererplus/dist/cluster";
import {ClusterIconStyle} from "@google/markerclustererplus";

@Component({
  selector: 'app-member-geo-locations-map',
  templateUrl: './member-geo-locations-map.component.html',
  styleUrls: ['./member-geo-locations-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MemberGeoLocationsMapComponent implements OnInit, OnChanges {
  @Input() selectedGeolocation: GeoLocation | null = null;
  @Input() geolocations: GeoLocation[];
  @Input() teamColor: string;
  @Output() selectedCluster = new EventEmitter<GeoLocation[]>();
  circularIcon: any;
  pinIcon: any;
  mapStyle: MapTypeStyle[] = [
    {
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
        stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
        elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
        elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
        elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
        elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "road",
        elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
        elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
        elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
        elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
        elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
        elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
        elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
        elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }],
    },
    {
      featureType: "water",
        elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    // for hidding elelmnts
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    }
  ];

  clusterStyle: ClusterIconStyle[];

  constructor() { }

  ngOnInit(): void {
   /* this.clusterStyle  = [
      {
        textColor: 'white',
        url: '../assets/images/map-pins/map-cluster-pins/' + this.teamColor.replace('#', '') + '-m-1.png',
        height: 53,
        width: 52,
        textLineHeight: 53
      },
      {
        textColor: 'white',
        url: '../assets/images/map-pins/map-cluster-pins/' + this.teamColor.replace('#', '') + '-m-2.png',
        height: 56,
        width: 55,
        textLineHeight: 56
      },
      {
        textColor: 'white',
        url: '../assets/images/map-pins/map-cluster-pins/' + this.teamColor.replace('#', '') + '-m-3.png',
        height: 66,
        width: 65,
        textLineHeight: 66
      },
      {
        textColor: 'white',
        url: '../assets/images/map-pins/map-cluster-pins/' + this.teamColor.replace('#', '') + '-m-4.png',
        height: 78,
        width: 77,
        textLineHeight: 78
      }
      ,{
        textColor: 'white',
        url: '../assets/images/map-pins/map-cluster-pins/' + this.teamColor.replace('#', '') + '-m-5.png',
        height: 90,
        width: 89,
        textLineHeight: 90
      }
    ];*/

    this.circularIcon = {
      url: '../assets/images/map-pins/map-circular-pins/' + this.teamColor.replace('#', '') + '.png',
      scaledSize: {
        width: 30,
        height: 30
      }
    };

    this.pinIcon = {
      url: '../assets/images/map-pins/map-pins/' + this.teamColor.replace('#', '') + '.png',
      scaledSize: {
        width: 22,
        height: 30
      }
    };

    this.geolocations.forEach((geolocation: GeoLocation) => {
      if (geolocation?.timestamp) {
        // @ts-ignore
        geolocation?.correctTimestamp = new Date(geolocation?.timestamp.slice(0,-6));
      }
      if (geolocation.isLastLocation) {
        const pin = {...this.pinIcon};
        pin.extra = geolocation;
        geolocation.pinData = pin;
      } else {
        const pin = {...this.circularIcon};
        pin.extra = geolocation;
        geolocation.pinData = pin;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedGeolocation && this.selectedGeolocation !== null) {
      if (this.selectedGeolocation?.timestamp) {
        // @ts-ignore
        this.selectedGeolocation?.correctTimestamp = new Date(this.selectedGeolocation?.timestamp.slice(0,-6));
      }
    }
  }

  clusterClick(cluster: Cluster) {
    const selectedCluster: GeoLocation[] = [];
    cluster.getMarkers().map((marker: any) => {
      selectedCluster.push(marker.getIcon().extra);
    });
    this.selectedCluster.emit(selectedCluster);
  }
}
