<div class="file-box-component flex center align-center">
  <div class="file-icon flex column align-center">
    <i *ngIf="file?.document?.icon || false" class="file-icon" nz-icon [nzType]="'icons:' + file?.document?.icon"></i>
  </div>
  <div class="file-name" style="white-space: break-spaces;">
    {{file?.document?.icon ?  file.name : ('Subir ' + name)}}
  </div>

  <div class="file-actions flex column align-center" nzTrigger="click" nz-dropdown [nzClickHide]="false" [nzDropdownMenu]="menu">
    <i nz-icon nzType="icons:dots"></i>
  </div>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li nz-menu-item *ngIf="file?.document?.url !== null && (file?.approved === null || !file?.approved)" [nzDisabled]="isRejecting || isAccepting || isDeleting || isDownloading || isUploading"
          (click)="approveFile()">
        <i *ngIf="isAccepting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
        <i *ngIf="!isAccepting" class="empty-icon" nz-icon nzType="check-circle" nzTheme="outline"></i>
        Aprobar archivo
      </li>
      <li nz-menu-item *ngIf="file?.document?.url !== null && (file?.approved === null || file?.approved)" [nzDisabled]="isRejecting || isAccepting || isDeleting || isDownloading || isUploading"
          (click)="unApproveFile()">
        <i *ngIf="isRejecting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
        <i *ngIf="!isRejecting" class="empty-icon" nz-icon nzType="close-circle" nzTheme="outline"></i>
        Desaprobar archivo
      </li>
      <li nz-menu-item *ngIf="file?.document?.url === null" [nzDisabled]="isRejecting || isAccepting || isDeleting || isDownloading || isUploading"
          (click)="newFile.click()">
        <i class="empty-icon" nz-icon nzType="upload" nzTheme="outline"></i>
        Subir archivo
      </li>
      <li nz-menu-item *ngIf="file?.document?.url !== null" [nzDisabled]="isRejecting || isAccepting || isDeleting || isDownloading || isUploading"
          (click)="openFile()">
        <i class="empty-icon" nz-icon nzType="eye" nzTheme="outline"></i>
        Ver archivo
      </li>
      <li nz-menu-item *ngIf="file?.document?.url !== null" [nzDisabled]="isRejecting || isAccepting || isDeleting || isDownloading || isUploading"
          (click)="downloadFile()">
        <i *ngIf="isDownloading" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
        <i *ngIf="!isDownloading" class="empty-icon" nz-icon nzType="download" nzTheme="outline"></i>
        Descargar archivo
      </li>
      <li nz-menu-item nzDanger *ngIf="file?.document?.url !== null" [nzDisabled]="isRejecting || isAccepting || isDeleting || isDownloading || isUploading"
          nz-popconfirm="¿Seguro que deseas eliminar este archivo?" (nzOnConfirm)="deleteFile()">
        <i *ngIf="isDeleting" class="empty-icon" nz-icon nzType="loading" nzTheme="outline"></i>
        <i *ngIf="!isDeleting" class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i>
        Eliminar archivo
      </li>
    </ul>
  </nz-dropdown-menu>

  <input style="display: none;" type="file" #newFile (change)="uploadFile($event)" accept="*">
</div>
