<agm-map (mapReady)="mapReading()" [fitBounds]="true" [zoom]="17" [usePanning]="true" [fitBoundsPadding]="60">

  <agm-marker
    [iconUrl]="'../assets/images/pin-1-small.png'"
    [agmFitBounds]="true"
    [animation]="userLocationMarkerAnimation"
    [latitude]="geoFencing?.address?.latitude || 0"
    [longitude]="geoFencing?.address?.longitude || 0">
  </agm-marker>

</agm-map>
