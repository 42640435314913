<nz-spin [nzSpinning]="isLoadingCompany" *ngIf="isLoadingCompany"></nz-spin>

<div class="error-box" *ngIf="error && !isLoadingCompany">
  <nz-alert
    nzType="error"
    [nzMessage]="error"
    nzShowIcon
  ></nz-alert>
</div>

<form [formGroup]="announcementForm" *ngIf="!isLoadingCompany">
  <div class="new-announcement">

    <div class="new-announcement-container flex wrap">
      <div class="flex center wrap form-column announcement-icon medium-1">
        <app-upload-image [folder]="'announcements'" [image]="getImageControl()"></app-upload-image>
      </div>

      <div nzLayout="vertical" nz-form class="form-column medium-2">
        <nz-form-item>
          <nz-form-label nzFor="icon">Seleccionar icono</nz-form-label>
          <nz-form-control [nzErrorTip]="iconErrorTpl">
            <nz-select [compareWith]="compareIcons" [nzCustomTemplate]="iconTemplate" id="icon" nzPlaceHolder="Icono" formControlName="icon">
              <nz-option *ngFor="let icon of ($icons | async)" nzCustomContent [nzLabel]="icon!.name" [nzValue]="icon">
                <app-icon-box [size]="25" [isImage]="true" [icon]="icon"></app-icon-box>
              </nz-option>
            </nz-select>

            <ng-template #iconTemplate let-selected>
              <app-icon-box [isImage]="true" [size]="20" [icon]="selected.nzValue"></app-icon-box>
            </ng-template>

            <ng-template #iconErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escoge un icono
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzFor="announcementName">Nombre del anuncio </nz-form-label>
          <nz-form-control [nzErrorTip]="nameErrorTpl">

            <input nz-input placeholder="Nombra el anuncio" formControlName="title" type="text" id="announcementName">

            <ng-template #nameErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa el nombre del anuncio
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nzLayout="vertical" nz-form class="form-column">

        <div style="margin-bottom: 20px">
          <nz-form-label>Selecciona equipos de trabajo</nz-form-label>
          <app-teams-form-control [teams]="getTeamsControl()"></app-teams-form-control>
        </div>

        <nz-form-item>
          <nz-form-label nzFor="announcementDateRange">Disponible durante</nz-form-label>
          <nz-form-control [nzErrorTip]="dateRangeErrorTpl">

            <nz-range-picker formControlName="dateRange" id="announcementDateRange"></nz-range-picker>

            <ng-template #dateRangeErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Ingresa el rango de fechas
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <div class="items-container">
          <nz-form-item class="item">
            <nz-form-label nzFor="publicationDate">Fecha de publicación</nz-form-label>
            <nz-form-control [nzErrorTip]="publicationDateErrorTpl">

              <nz-date-picker
                style="width: 100%"
                formControlName="publicationDate"
                id="publicationDate"
                [nzAllowClear]="true"
                [nzPlaceHolder]="'Selecciona fecha'"
                (ngModelChange)="updateValidators($event)">
              </nz-date-picker>

              <ng-template #publicationDateErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  La fecha de publicación es requerida
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="item">
            <nz-form-label nzFor="publicationHour">Hora de publicación</nz-form-label>
            <nz-form-control [nzErrorTip]="publicationHourErrorTpl">

              <nz-time-picker
                style="width: 100%"
                formControlName="publicationHour"
                id="publicationHour"
                nzFormat="HH:mm"
                [nzMinuteStep]="5">
              </nz-time-picker>

              <ng-template #publicationHourErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  La hora de publicación es requerida
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>

        <nz-form-item>
          <nz-form-label nzFor="announcementDescription">Cuerpo del anuncio</nz-form-label>
          <nz-form-control [nzErrorTip]="descriptionErrorTpl">

            <textarea nz-input placeholder="Escribe el mensaje" formControlName="description" type="text" id="announcementDescription"></textarea>

            <ng-template #descriptionErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Escriba el cuerpo del anuncio
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <div class="checks-container">
          <nz-form-item class="check">
            <nz-form-control>
              <div class="flex align-center">
                <label nz-checkbox formControlName="sendBySlack"></label>
                <div class="content">
                  <div class="img-box">
                    <img [src]="'../assets/images/slack.png'" alt="slack-icon">
                  </div>
                  <div class="name">Anunciar slack</div>
                </div>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="check">
            <nz-form-control>
              <div class="flex align-center">
                <label nz-checkbox formControlName="sendByEmail"></label>
                <div class="content">
                  <div class="img-box">
                    <img [src]="'../assets/images/send.png'" alt="send-icon">
                  </div>
                  <div class="name">Anunciar por mail</div>
                </div>
              </div>
            </nz-form-control>
          </nz-form-item>
        </div>

        <app-slack-box
          *ngIf="!company.slackActive"
          [marginBottom]="20"
          (close)="closeModal()">
        </app-slack-box>

        <nz-form-item *ngIf="announcementForm.controls.sendBySlack.value">
          <nz-form-label nzFor="icon">Seleccionar canales de slack</nz-form-label>
          <nz-form-control>
            <nz-select nzPlaceHolder="Seleccionar canales de slack" formControlName="slackChannelsUid" nzMode="multiple">
              <nz-option *ngFor="let channel of slackChannels" [nzLabel]="'#' + channel.name" [nzValue]="channel.uid"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <div style="margin-bottom: 40px">
          <nz-form-label>Archivos adjuntos</nz-form-label>


          <div class="files-column flex column">
            <ng-container *ngIf="announcementForm.controls.files?.value?.length == 0">
              <nz-empty [nzNotFoundContent]="contentTpl">
                <ng-template #contentTpl>
                  <div class="flex column align-center center">
                    <span style="margin-bottom: 15px">
                      No has agregado ningún archivo
                    </span>
                    <app-upload-file [folder]="'announcements'" (fileUploaded)="onNewFileUploaded($event)"></app-upload-file>
                  </div>
                </ng-template>
              </nz-empty>
            </ng-container>

            <div class="file-box" *ngFor="let file of announcementForm?.controls?.files?.value">
              <app-download-file-box (fileDeleted)="onFileDeleted($event)" [canDelete]="true" [file]="file"></app-download-file-box>
            </div>

            <div class="flex end file-box" *ngIf="announcementForm.controls.files?.value?.length">
              <app-upload-file [folder]="'announcements'" (fileUploaded)="onNewFileUploaded($event)"></app-upload-file>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</form>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="closeModal()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button [nzLoading]="isLoading" (click)="submitForm()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
