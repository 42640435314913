import {Directive, HostListener, Input} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {HelpModalComponent} from '../../features/modals/help-modal/help-modal.component';
import {HelpService} from '../../core/services/help.service';

@Directive({
  selector: '[appHelpModalOpener]'
})
export class HelpModalOpenerDirective {

  @Input() helpIndex: string;

  @HostListener('click') onClick(){
    this.openModal();
  }

  constructor(
      private modalService: NzModalService,
      private helpService: HelpService
  ) { }

  openModal() {
    const help = this.helpService.helpList.find(el => el.uid == this.helpIndex);

    const modal = this.modalService.create({
      nzContent: HelpModalComponent,
      nzTitle: help?.title || 'Ayuda',
      nzCentered: true,
      nzWidth: 560,
      nzComponentParams: {
        help: help
      }
    });
  }

}
