<nz-spin [nzSpinning]="isLoading">
  <div class="area-chart">
    <div class="filters-container">
      <div class="title">{{ title }}</div>

      <div class="filters-box">
        <nz-select [(ngModel)]="selectedYear" (ngModelChange)="yearChange()">
          <nz-option *ngFor="let year of years" [nzValue]="year" [nzLabel]="year.toString()"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="chart">
      <ngx-charts-area-chart
        [scheme]="colorScheme"
        [legend]="legend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [curve]="curve"
        [yAxis]="yAxis"
        [gradient]="isGradient"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [results]="multi"
        [yAxisTickFormatting]="yAxisTickFormattingFn"
        (select)="onSelect($event)">
        <ng-template #tooltipTemplate let-model="model">
          <div>{{ model?.value }} Empleado{{ model?.value === 1 ? '' : 's' }}</div>
        </ng-template>

        <ng-template #seriesTooltipTemplate let-model="model">
          <div>{{ model[0]?.value }} Empleado{{ model[0]?.value === 1 ? '' : 's' }}</div>
        </ng-template>
      </ngx-charts-area-chart>
    </div>
  </div>
</nz-spin>
