<div class="edit-salary">
  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <nz-spin class="spin-loading" [nzSpinning]="isLoading">

    <form nz-form nzLayout="vertical" [formGroup]="positionForm" *ngIf="!isLoading && positionForm && !error">
      <nz-form-item>
        <nz-form-label>Salario</nz-form-label>
        <nz-form-control [nzErrorTip]="newPaidAmountErrorTpl">

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="paidAmount"
            [nzMin]="1"
            [nzStep]="10"
            [nzFormatter]="formatter"
            [nzParser]="parser">
          </nz-input-number>

          <ng-template #newPaidAmountErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El salario es requerido
            </ng-container>
            <ng-container *ngIf="control.hasError('min')">
              El salario minimo es de $1.00
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Salario IMSS</nz-form-label>
        <nz-form-control [nzErrorTip]="imssPaidAmountErrorTpl">

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="imssPaidAmount"
            [nzMin]="0"
            [nzStep]="10"
            [nzFormatter]="formatter"
            [nzParser]="parser">
          </nz-input-number>

          <ng-template #imssPaidAmountErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El salario IMSS es requerido
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Jornada laboral</nz-form-label>
        <nz-form-control [nzErrorTip]="hoursWorkedErrorTpl">

          <nz-select formControlName="hoursWorked">
            <nz-option *ngFor="let hour of hours" [nzValue]="hour" [nzLabel]="hour + ' hrs'"></nz-option>
          </nz-select>

          <ng-template #hoursWorkedErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              La jornada laboral es requerida
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item>
        <nz-form-control>

          <nz-radio-group formControlName="affectsEveryoneSalary">
            <label nz-radio [nzValue]="true" class="radio-text">Aplicar cambios a todos los miembros con este puesto</label>
            <label nz-radio [nzValue]="false" class="radio-text">Guardar configuracion a futuros miembros</label>
          </nz-radio-group>

        </nz-form-control>
      </nz-form-item>

    </form>

    <div class="btns-container" *ngIf="!isLoading">
      <button [disabled]="isUpdating" nz-button nzType="default" (click)="closeModal()">Cancelar</button>
      <button *ngIf="positionForm" [nzLoading]="isUpdating" nz-button nzType="primary" (click)="updateSalary()">Guardar</button>
    </div>

  </nz-spin>
</div>
