<div class="new-payment-method-component">

  <div class="error-box" *ngIf="error">
    <div>{{ error }}</div>
  </div>

  <div class="tc-box">
    <div class="tc-img-box">
      <img src="../assets/images/card-base.png" alt="credit-card-img">
      <div class="tc-logo" *ngIf="paymentMethod?.type !== null && paymentMethod?.type !== 'tc-card'">
        <img [src]="'../assets/images/' + paymentMethod?.type + '.png'" [alt]="paymentMethod?.type + '-logo'">
      </div>
      <div class="tc-final-digits">{{ paymentMethod?.cardNumber }}</div>
      <div class="holder-name">{{ paymentMethod?.holderName }}</div>
      <div class="expiration-date">{{ paymentMethod?.expirationDate }}</div>
    </div>

    <div class="form-box">
      <form nz-form [formGroup]="newPaymentMethodForm" *ngIf="newPaymentMethodForm" style="margin: auto;">

        <div class="tc-form-box">
          <div class="tc-icon-box">
            <img [src]="'../assets/images/' + cardImage + '.png'" [alt]="cardImage + '-logo'">
          </div>
          <nz-form-item style="width: 91%">
            <nz-form-control nzErrorTip="Número de tarjeta inválido">
              <nz-input-group>
                <input nzSize="large" nz-input formControlName="cardNumber" placeholder="Número de tarjeta" id="cc-number" type="tel" autocomplete="cc-number" ccNumber />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="holder-name-box">
          <nz-form-item style="width: 100%">
            <nz-form-control nzErrorTip="El nombre es requerido">
              <nz-input-group>
                <input nzSize="large" type="text" nz-input formControlName="holderName" placeholder="Nombre (como aparece en la tarjeta)" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="cvv-expiration-box">
          <nz-form-item style="width: 30%; padding-right: 10px;">
            <nz-form-control nzErrorTip="CVV inválido">
              <nz-input-group>
                <input nzSize="default" nz-input formControlName="cvv" placeholder="CVV" id="cc-cvc" type="tel" autocomplete="off" ccCVC />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 30%; padding-left: 10px;">
            <nz-form-control nzErrorTip="Fecha inválida">
              <nz-input-group>
                <input nzSize="default" nz-input formControlName="expirationDate" placeholder="MM/YY" id="cc-exp-date" type="tel" autocomplete="cc-exp" ccExp />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="button-upload">
          <button style="margin-right: 20px;" nz-button nzType="default" [nzSize]="'default'" [disabled]="isLoading" (click)="close()">Cancelar</button>
          <button  nz-button nzType="primary" [nzSize]="'default'" [nzLoading]="isLoading" (click)="createPaymentMethod()">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>
