import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { Address } from '../models/address';
import { environment } from '../../../environments/environment';
import { Marker } from '../models/marker';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient) {}

  getAddressByZipcode(
    zipcode: string,
    country: string = 'MX'
  ): Observable<Address | null> {
    return this.http
      .get(
        environment.geoNamesApi +
          '/postalCodeSearchJSON?postalcode=' +
          zipcode +
          '&country=' +
          country +
          '&username=' +
          environment.geoNamesUser
      )
      .pipe(
        map((geoNameRes: any) => {
          if (geoNameRes.postalCodes.length) {
            const postalCode = geoNameRes.postalCodes[0];

            const latLongAddress = geoNameRes.postalCodes.map(
              (geoName: any) => {
                return {
                  latitude: geoName.lat,
                  longitude: geoName.lng,
                  placeName: geoName.placeName,
                };
              }
            );

            const address: Address = {
              state: postalCode.adminName1,
              city: postalCode.adminName3
                ? postalCode.adminName3 +
                  (postalCode.adminName3 !== postalCode.adminName2
                    ? ' - ' + postalCode.adminName2
                    : '')
                : postalCode.adminName2,
              colony: geoNameRes.postalCodes.map(
                (postal: { placeName: any }) => postal.placeName
              ),
              latLongAddress,
            };

            return address;
          }
          return null;
        })
      );
  }

  getCoordinates(address: Address): Observable<Marker | undefined> {
    return this.http
      .get<any>(
        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          address.street +
          '+' +
          address.colony +
          '+' +
          address.city +
          '+' +
          address.state +
          '+' +
          address.zipcode +
          '+' +
          address.country +
          '&key=' +
          environment.googleApiKey
      )
      .pipe(
        map((res: any) => {
          if (res.status === 'OK') {
            return {
              latitude: res.results[0].geometry.location.lat,
              longitude: res.results[0].geometry.location.lng,
            };
          }
          throwError('No se encontraron las coordenadas');
        })
      );
  }

  concatAddress(address: Address | undefined): string {
    if (address && address.street) {
      return (
        address.street +
        (address.exteriorNumber ? ' ' + address.exteriorNumber + ', ' : ', ') +
        (address.interiorNumber
          ? 'Int. ' + address.interiorNumber + ', '
          : '') +
        address.colony +
        ', ' +
        address.zipcode +
        ', ' +
        address.city +
        ', ' +
        address.state
      );
    } else {
      return '';
    }
  }
}
