import {Component, Input, OnInit} from '@angular/core';
import {Turn} from '../../../core/models/turn';
import {User} from '../../../core/models/user';
import {GeoFencingService} from '../../../core/services/geo-fencing.service';
import {finalize} from 'rxjs/operators';
import {GeoFencing} from '../../../core/models/geo-fencing';
import {Attendance} from '../../../core/models/attendance';
import {Marker} from '../../../core/models/marker';
import {AddressService} from '../../../core/services/address.service';

@Component({
  selector: 'app-attendance-modal',
  templateUrl: './attendance-modal.component.html',
  styleUrls: ['./attendance-modal.component.scss']
})
export class AttendanceModalComponent implements OnInit {

  @Input() turn: Turn;
  @Input() member: User;
  @Input() attendance: Attendance;

  geoFencingsLoading: boolean = true;
  geoFencingsError: string | null;

  userGeoFencings: GeoFencing[] | undefined = []
  pin: Marker;
  timestamp: Date;

  constructor(
      private geoFencingsService: GeoFencingService,
      private addressService: AddressService
  ) { }

  ngOnInit(): void {

    if (this.attendance?.timestamp) {
      this.timestamp = new Date(this.attendance.timestamp.slice(0,-6));
    }

    this.pin = {
      latitude: Number(this.attendance.latitude),
      longitude: Number(this.attendance.longitude)
    }
    this.getGeoFencings();
  }

  private getGeoFencings() {
    this.geoFencingsLoading = true;
    this.geoFencingsError = null;
    this.geoFencingsService.getUserGeoFencings(this.member.uid)
        .pipe(
            finalize(() => {
              this.geoFencingsLoading = false;
            })
        ).subscribe(geoFencings => {
      this.userGeoFencings = geoFencings;
    }, error => {
          this.geoFencingsError = error;
    })
  }

  getAddress(geoFencing: GeoFencing): string {
    return this.addressService.concatAddress(geoFencing.address);
  }
}
