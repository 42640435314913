<div class="payroll-summary-component">
  <div class="totals-container">

    <div class="total-box">
      <div class="info-box">
        <div class="type">Total percepciones</div>
        <div class="content">{{ payroll?.totalEarnings | currency }}</div>
      </div>

      <div class="line"></div>
    </div>

    <div class="total-box">
      <div class="info-box">
        <div class="type">Total deducciones</div>
        <div class="content">{{ payroll?.totalDeductions | currency }}</div>
      </div>

      <div class="line responsive"></div>
    </div>

    <div class="total-box">
      <div class="info-box">
        <div class="type">Cantidad Neta</div>
        <div class="content">{{ payroll?.totalNet | currency }}</div>
      </div>

      <div class="line"></div>
    </div>

    <div class="total-box">
      <div class="info-box">
        <div class="type">Total carga social</div>
        <div class="content">{{ payroll?.totalSocialSecurity | currency }}</div>
      </div>

      <div class="line responsive"></div>
    </div>

    <div class="total-box">
      <div class="info-box">
        <div class="type">Total</div>
        <div class="content">{{ payroll?.totalPaid | currency }}</div>
      </div>
    </div>

  </div>
</div>
