import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {User} from "../../../core/models/user";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MemberService} from "../../../core/services/member.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalRef} from "ng-zorro-antd/modal";
import {finalize, take, takeUntil} from "rxjs/operators";
import {PositionService} from "../../../core/services/position.service";
import {Position} from "../../../core/models/position";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {Subject} from "rxjs";
import {NewPositionModalComponent} from "../new-position-modal/new-position-modal.component";

@Component({
  selector: 'app-edit-member-position',
  templateUrl: './edit-member-position.component.html',
  styleUrls: ['./edit-member-position.component.scss']
})
export class EditMemberPositionComponent implements OnInit, OnDestroy {
  @Input() member: User | null;
  memberForm: FormGroup;
  isLoading = false;
  error: string | null;
  @Output() positionEdited: EventEmitter<User> = new EventEmitter();
  positions: Position[] | null = [];
  isLoadingPositions = true;
  $onDestroy: Subject<void> = new Subject<void>();

  @ViewChild('newPositionTitle') newPositionTitle: TemplateRef<any>;

  constructor(
      private memberService: MemberService,
      private positionService: PositionService,
      private formHelper: FormHelperService,
      private formBuilder: FormBuilder,
      private notification: NzNotificationService,
      private nzModuleRef: NzModalRef,
      private drawerService: NzDrawerService
  ) { }

  ngOnInit(): void {
    this.getPositions();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  closeModal() {
    this.nzModuleRef.close();
  }

  openNewPosition(): void {
    const modal = this.drawerService.create({
      nzContent: NewPositionModalComponent,
      nzTitle: this.newPositionTitle,
      nzWidth: 504
    });

    modal.afterClose.pipe(take(1)).subscribe(newPosition => {
      if (newPosition === undefined || newPosition === null) {
        this.memberForm.controls.positionUid.setValue(null);
      } else {
        this.positions?.push(newPosition);
        // @ts-ignore
        this.positions = [...this.positions];
        this.memberForm.controls.positionUid.setValue(newPosition);
      }
    });
  }

  updatePosition() {
    if (this.memberForm.valid) {
      this.isLoading = true;
      const data = this.memberForm.getRawValue();
      data.positionUid = data.positionUid.uid;
      this.memberService.updateMemberPosition(data.employeeUid, data.positionUid)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((member: User | null) => {
          this.notification.create('success', '¡Operación exitosa!', 'Puesto actualizado correctamente');
          // @ts-ignore
          this.positionEdited.emit(member);
          this.nzModuleRef.close();
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al actualizar el puesto', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.memberForm);
    }
  }

  private getPositions() {
    this.positionService.getPositions()
      .pipe(finalize(() => {
        this.isLoadingPositions = false;
      }))
      .subscribe((positions: Position[] | null) => {
        this.positions = positions;
        this.createForm();
      }, error => {
        this.notification.create('error', 'Error al obtener los puestos', error.message.replace("GraphQL error:", "").trim());
      });
  }

  private createForm() {
    this.memberForm = this.formBuilder.group({
      employeeUid: [this.member?.uid],
      // @ts-ignore
      positionUid: [this.member?.position ? this.getPosition(this.member.position) : null, [Validators.required]]
    });

    this.memberForm.controls.positionUid.valueChanges.subscribe(value => {
      if (value !== null && value.name === 'new') {
        this.openNewPosition();
      }
    });
  }

  // @ts-ignore
  private getPosition(position: Position): Position {
    const index = this.positions?.findIndex(p => p.uid === position.uid);
    if (index !== -1) {
      // @ts-ignore
      return this.positions[index];
    }
  }
}
