import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Appeal} from "../../../core/models/appeal";
import {AppealsService} from "../../../core/services/appeals.service";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {finalize} from "rxjs/operators";
import {GeoFencing} from "../../../core/models/geo-fencing";
import {AddressService} from "../../../core/services/address.service";

@Component({
  selector: 'app-appeal-details-modal',
  templateUrl: './appeal-details-modal.component.html',
  styleUrls: ['./appeal-details-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppealDetailsModalComponent implements OnInit {
  @Input() appeal: Appeal;
  isLoading = true;
  error: string | null = null;
  marker: any;
  userGeofencings: GeoFencing[] | null = null;
  addressLoading = true;
  isLoadingApproveDeny = false;

  constructor(private appealsService: AppealsService,
              private addressService: AddressService,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.getAppealMoreInfo();
  }

  close() {
    this.drawerRef.close(null);
  }

  approveDenyRequest(approved: boolean) {
    this.isLoadingApproveDeny = true;
    this.appealsService.acceptOrDenyAppeal(this.appeal.uid, approved)
      .pipe(finalize(() => {
        this.isLoadingApproveDeny = false;
      }))
      .subscribe((appeal: Appeal | undefined) => {
        this.notification.create('success', '¡Operación exitosa!', approved ? 'Aclaración aprobada con éxito' : 'Aclaración rechazada con éxito');
        this.drawerRef.close(appeal);
      }, error => {
        this.notification.create('error', 'Error',  error.message.replace("GraphQL error:", "").trim());
      });
  }

  private getAppealMoreInfo() {
    this.isLoading = true;
    this.appealsService.getAppeal(this.appeal.uid)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((appeal: Appeal) => {
        if (appeal?.attendance?.timestamp) {
          // @ts-ignore
          appeal?.attendance?.correctTimestamp = new Date(appeal?.attendance?.timestamp.slice(0,-6));
        }
        appeal?.issuedBy?.geoFencings?.forEach((geofencing: GeoFencing) => {
          if (geofencing?.address !== null) {
            // @ts-ignore
            geofencing?.address?.fullAddress = this.addressService.concatAddress(geofencing.address);
          }
        });
        this.userGeofencings = appeal?.issuedBy?.geoFencings || [];
        this.marker = {
          icon: {
            url: appeal?.issuedBy?.photo ? appeal?.issuedBy?.photo?.url : "../assets/images/user-pin.png",
            scaledSize: {
              width: 30,
              height: 30
            }
          },
          latitude: appeal?.attendance?.latitude,
          longitude: appeal?.attendance?.longitude
        };
        this.addressLoading = false;
        this.appeal = appeal;
      }, error => {
        this.error = error.message.replace("GraphQL error:", "").trim();
      });
  }
}
