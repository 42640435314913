import { AbstractControl, ValidationErrors } from "@angular/forms";

export declare type ValidatorFn = (
  control: AbstractControl
) => ValidationErrors | null;

export class ArrayValidator {
  static ArrayContainMin(min: number = 0): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (min === 0) {
        if (control.value.length >= 0) {
          return null;
        } else {
          return { invalidArray: true };
        }
      } else {
        if (control.value.length < min) {
          return { invalidArray: true };
        } else {
          return null;
        }
      }
    };
  }
}
