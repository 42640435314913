import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ColorService} from '../../../core/services/color.service';
import {Color} from '../../../core/models/color';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {Team} from '../../../core/models/team';
import {Icon} from '../../../core/models/icon';
import {Observable, Subject} from 'rxjs';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {IconStore} from '../../../core/stores/icons.store';
import {TimezoneStore} from '../../../core/stores/timezone.store';
import {TeamService} from '../../../core/services/team.service';
import {finalize} from 'rxjs/operators';
import {TimeZone} from '../../../core/models/time-zone';
import {CompanyService} from "../../../core/services/company.service";
import {IntegrationsService} from "../../../core/services/integrations.service";
import {Company} from "../../../core/models/company";
import {SlackChannel} from "../../../core/models/slack-channel";

@Component({
  selector: 'app-new-team-modal',
  templateUrl: './new-team-modal.component.html',
  styleUrls: ['./new-team-modal.component.scss']
})
export class NewTeamModalComponent implements OnInit, OnDestroy {
  $onDestroy: Subject<void> = new Subject<void>();

  newTeamForm: FormGroup;

  colors: Color[] = this.colorService.getColors();

  isLoading = false;
  error: string | null;

  $icons: Observable<Icon[] | undefined> = this.iconsStore.getIcons();
  $timezones: Observable<TimeZone[] | undefined> = this.timezoneStore.getTimezones();

  @Output() teamCreated: EventEmitter<Team> = new EventEmitter();
  company: Company| null = null;
  isLoadingCompany = true;
  slackChannels: SlackChannel[] = [];

  constructor(
      private formBuilder: FormBuilder,
      private colorService: ColorService,
      private iconsStore: IconStore,
      private formHelper: FormHelperService,
      private drawerRef: NzDrawerRef,
      private timezoneStore: TimezoneStore,
      private teamService: TeamService,
      private companyService: CompanyService,
      private integrationsService: IntegrationsService
  ) { }

  ngOnInit(): void {
    this.integrationsService.getSlackChannels()
      .subscribe((slackChannels: SlackChannel[]) => {
        this.slackChannels = slackChannels;
        this.companyService.getCompanyIntegrations()
          .pipe(finalize(() => {
            this.isLoadingCompany = false;
          }))
          .subscribe((company: Company) => {
            this.company = company;
            this.createForm();
          });
      }, error => {
        this.isLoadingCompany = false;
        this.error = error;
      });
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  private createForm() {
    this.newTeamForm = this.formBuilder.group({
      color: [null, [Validators.required]],
      icon: [null, [Validators.required]],
      members: [[]],
      name: [null, [Validators.required]],
      teamLeaders: [[]],
      geoFencings: [[]],
      timezone: [null, Validators.required],
      slackChannelsUid: [[]]
    });
  }

  submitForm() {
    this.error = null;
    if (this.newTeamForm.valid) {
      this.isLoading = true;
      this.teamService.createTeam(this.cleanData())
          .pipe(
              finalize(() => {
                this.isLoading = false;
              })
          )
          .subscribe(newTeam => {
            this.teamCreated.next(newTeam);
            this.closeModal();
          }, error => {
            this.error = error;
          })
    } else {
      this.formHelper.markFormAsDirty(this.newTeamForm);
    }
  }

  closeModal() {
    this.drawerRef.close();
  }

  getTeamLeadersFormControl() {
    return this.newTeamForm.controls.teamLeaders as FormControl;
  }

  getMemberFormControl() {
    return this.newTeamForm.controls.members as FormControl;
  }

  getGeoFencingsFormControl() {
    return this.newTeamForm.controls.geoFencings as FormControl;
  }

  private cleanData(): Team {
    const data: any = this.newTeamForm.getRawValue();
    data.membersUid = data.members?.map((el: any) => el.uid);
    delete data.members;
    data.teamLeadersUid = data.teamLeaders?.map((el: any) => el.uid);
    delete data.teamLeaders;
    data.geoFencingsUid = data.geoFencings?.map((el: any) => el.uid);
    delete data.geoFencings;
    data.iconUid = data.icon?.uid;
    delete data.icon;
    return data;
  }
}
