<div class="member-box flex align-center">
  <div class="member-status">
    <i nz-icon nzType="icons:shapes"
       [style.color]="member?.active ? '#64D27C' : '#979797'"
       nzTheme="fill"
       nzTooltipColor="white"
       [nz-tooltip]="member?.active ? 'Activo' : 'Suspendido'"></i>
  </div>

  <a [routerLink]="['/members/' + member?.uid]" class="member-photo router-label">
    <nz-avatar [nzSize]="30"  [nzSrc]="member.photo?.url || undefined"
               [nzText]="member.firstName?.charAt(0) + '' + member.lastName?.charAt(0)"></nz-avatar>
  </a>

  <div class="member-name flex column center">
    <a [routerLink]="['/members/' + member?.uid]" class="name router-label">
      {{member.firstName | titlecase}} {{member.lastName | titlecase}}
    </a>
    <div class="email">
      {{member.email | lowercase}}
    </div>
  </div>

  <div class="member-position">
    {{member.position?.name || 'Sin puesto'}}
  </div>

  <div class="turn-type" *ngFor="let schedule of member.calendarSchedules">
    <ng-container *ngFor="let turn of schedule.turns?.slice(0,1)">
      <ng-container *ngIf="turn.dayConfig?.active">
        <ng-container *ngIf="turn.dayConfig?.category == 'Remoto'">
          Remoto
        </ng-container>
        <ng-container *ngIf="turn.dayConfig?.category != 'Remoto'">
          Presencial
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!turn.dayConfig?.active">
        Ausente
      </ng-container>
    </ng-container>

    <ng-container *ngIf="schedule.turns?.length == 0">
      Ausente
    </ng-container>

  </div>

  <div class="turn-type" *ngIf="!member?.calendarSchedules?.length">
    Sin horario
  </div>

  <div class="turns-row flex">
    <ng-container *ngFor="let schedule of member.calendarSchedules">
      <ng-container *ngIf="!schedule.absenceRequests?.length && !schedule?.festivityDays?.length">
        <div class="turn-box" *ngFor="let turn of schedule.turns">
          <app-turn-box [member]="member" [turn]="turn"></app-turn-box>
        </div>
      </ng-container>
      <ng-container *ngIf="schedule.absenceRequests?.length">
        <ng-container *ngFor="let absenceRequest of schedule.absenceRequests | slice: 0: 1">
          <app-absence-request-box [member]="member" [absenceRequest]="absenceRequest"></app-absence-request-box>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="schedule?.festivityDays?.length && !schedule?.absenceRequests?.length">
        <div class="category-container flex">
          <div class="category-box flex align-center" *ngFor="let festivityDay of schedule.festivityDays">

            <div class="background" [ngStyle]="{backgroundColor: festivityDay?.colour}"></div>

            <div class="icon">
              <i nz-icon nzType="icons:party-fill" [ngStyle]="{color: festivityDay?.colour}" nzTheme="fill"></i>
            </div>

            <div class="type" [ngStyle]="{color: festivityDay?.colour}">
              {{ festivityDay?.name | titlecase }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="actions-row flex end">
    <div class="icon-box" (click)="goToPending()" nz-tooltip="Solicitudes pendientes" nzTooltipColor="white" *ngIf="member?.pendingAbsenceRequest?.length">

      <nz-badge nzDot (click)="goToPending()">
        <i nz-icon nzType="calendar" nzTheme="fill"></i>
      </nz-badge>
    </div>

    <div class="icon-box" (click)="goToSchedule()" nz-tooltip="Abrir registros" nzTooltipColor="white" >
      <i nz-icon nzType="icons:dots" nzTheme="outline"></i>
    </div>
  </div>
</div>
