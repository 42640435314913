<div class="turn-form-control">
  <i class="close-icon" nz-icon nzType="close-circle" nzTheme="fill" [nz-tooltip]="'Eliminar turno'" nzTooltipColor="white" (click)="removeTurn()"></i>

  <form nz-form nzLayout="vertical" [formGroup]="turnForm">
    <nz-form-item>
      <nz-form-label>De</nz-form-label>
      <nz-form-control [nzErrorTip]="fromErrorTpl">

        <nz-select formControlName="fromHour" [nzPlaceHolder]="'09:00 hrs'" (ngModelChange)="setSelectedFrom($event)" [nzDisabled]="editable">
          <nz-option *ngFor="let interval of selectedIntervals" [nzValue]="interval" [nzLabel]="interval?.hour + ' hrs'" [nzDisabled]="interval?.blocked"></nz-option>
        </nz-select>

        <ng-template #fromErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Campo requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>A</nz-form-label>
      <nz-form-control [nzErrorTip]="toErrorTpl">

        <nz-select formControlName="toHour" [nzPlaceHolder]="'13:00 hrs'" (ngModelChange)="setSelectedTo($event)" [nzDisabled]="editable">
          <nz-option *ngFor="let interval of selectedIntervals" [nzValue]="interval" [nzLabel]="interval?.hour + ' hrs'" [nzDisabled]="interval?.blocked"></nz-option>
        </nz-select>

        <ng-template #toErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Campo requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
