import {Component, Input, OnInit} from '@angular/core';
import {Label, MultiDataSet} from 'ng2-charts';
import {ChartType} from 'chart.js';

@Component({
  selector: 'app-pie-graph',
  templateUrl: './pie-graph.component.html',
  styleUrls: ['./pie-graph.component.scss']
})
export class PieGraphComponent implements OnInit {
  @Input() isLoading: boolean;

  // options
  @Input() arcWidth: number = .25;
  @Input() gradient: boolean = true;
  @Input() showLegend: boolean = true;
  @Input() showLabels: boolean = false;
  @Input() isDoughnut: boolean = true;
  @Input() legendPosition: any = 'right';
  @Input() legendTitle: string | null = null;

  // -----
  @Input() type: string;
  @Input() serviceData: any;
  @Input() title = '';
  @Input() doughnutChartLabels: Label[] = ['Hombres', 'Mujeres'];
  @Input() doughnutChartData: any = [
    [250, 450]
  ];
  @Input() pieChartColors = [
    {
      backgroundColor: ['#7d64e5', '#fd5970'],
      borderWidth: [7,7]
    },
  ];
  doughnutChartType: ChartType = 'doughnut';
  chartOptions: any = {
    responsive: false,
    cutoutPercentage: 60,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'right',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    },
    tooltips: {
      enable: true,
      mode: 'nearest',
      callbacks: {
        /*title: (tooltipItem, data) => {
          return 'Día: ' + tooltipItem[0].index.toString();
        },*/
        label: (tooltipItem: any, data: any) => {
          const legend = [];
          switch (this.type) {
            case 'positions':
              legend.push(
                'No. de personas en puesto ' + this.doughnutChartLabels[tooltipItem.index].toString().toLocaleLowerCase() + ': ' + this.serviceData[tooltipItem.index].amount
                + ' (' + this.doughnutChartData[tooltipItem.index].toString() + '%)'
              );
              break;
            case 'teams':
              legend.push(
                'No. de personas en ' + this.doughnutChartLabels[tooltipItem.index].toString().toLocaleLowerCase() + ': ' + this.serviceData[tooltipItem.index].amount
                + ' (' + this.doughnutChartData[tooltipItem.index].toString() + '%)'
              );
              break;
            case 'gender':
              legend.push(
                'No. de ' + this.doughnutChartLabels[tooltipItem.index].toString().toLocaleLowerCase() + ': ' + this.serviceData[tooltipItem.index].amount
                + ' (' + this.doughnutChartData[tooltipItem.index].toString() + '%)'
              );
              break;
            default:
              legend.push(
                this.doughnutChartData[tooltipItem.index].toString() +
                ' ' +
                this.doughnutChartLabels[tooltipItem.index]
              );
          }
          return legend;
        }
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  itemClick(index: number) {
    const temp: any[] = [];
    this.doughnutChartData.forEach((item: any, i: number) => {
      if (i === index) {
        if (item > 0) {
          temp.push(0);
        } else {
          temp.push(this.serviceData[index].percent);
        }
      } else {
        temp.push(item);
      }
    });
    this.doughnutChartData = temp;
  }

}
