import {Component, Input, OnInit} from '@angular/core';
import {Earning} from "../../../core/models/earning";
import {EarningConcept} from "../../../core/models/earning-concept";
import {Subject} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Color} from "../../../core/models/color";
import {Icon} from "../../../core/models/icon";
import {EarningsService} from "../../../core/services/earnings.service";
import {ColorService} from "../../../core/services/color.service";
import {IconStore} from "../../../core/stores/icons.store";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {CurrencyPipe} from "@angular/common";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-edit-earning-modal',
  templateUrl: './edit-earning-modal.component.html',
  styleUrls: ['./edit-earning-modal.component.scss']
})
export class EditEarningModalComponent implements OnInit {
  @Input() earningConcepts: EarningConcept[];
  @Input() earning: Earning | null;
  $onDestroy: Subject<void> = new Subject<void>();
  editEarningForm: FormGroup;
  isLoading = true;
  isUpdating = false;
  error: string | null;
  colors: Color[] = this.colorService.getColors();
  icons: Icon[] | undefined;
  earningCategories = [
    {value: 'U', name: '% de UMA'},
    {value: 'M', name: '% del salario minimo'},
    {value: 'P', name: '% del monto'},
    {value: 'C', name: 'Cantidad'}
  ];
  formatterCurrency = (value: number) => `${this.currencyPipe.transform(value)}`;
  parserCurrency = (value: string) => value.replace(",", "").replace("$", "");
  formatterPercentage = (value: number) => `${value + ' %'}`;
  parserPercentage = (value: string) => value.replace(" %", "");
  formatter = (value: number) => `${value}`;
  parser = (value: string) => value.replace("", "");

  constructor(private earningsService: EarningsService,
              private colorService: ColorService,
              private iconsStore: IconStore,
              private formBuilder: FormBuilder,
              private formHelper: FormHelperService,
              private notification: NzNotificationService,
              private currencyPipe: CurrencyPipe,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.iconsStore.getIcons().pipe(takeUntil(this.$onDestroy)).subscribe(icons => {
      this.icons = icons;
      this.getConcepts();
    });
  }

  close() {
    this.drawerRef.close(null);
  }

  imssValueChange($event: boolean) {
    if ($event) {
      this.editEarningForm.controls.imssAmount.setValidators([Validators.required]);
      this.editEarningForm.controls.imssCategory.setValidators([Validators.required]);
      this.editEarningForm.controls.imssCategory.setValue(null);
    } else {
      this.editEarningForm.controls.imssAmount.clearValidators();
      this.editEarningForm.controls.imssAmount.setValue(0);
      this.editEarningForm.controls.imssCategory.clearValidators();
      this.editEarningForm.controls.imssCategory.setValue(null);
    }
    this.editEarningForm.controls.imssAmount.updateValueAndValidity();
    this.editEarningForm.controls.imssCategory.updateValueAndValidity();
  }

  isrValueChange($event: boolean) {
    if ($event) {
      this.editEarningForm.controls.isrAmount.setValidators([Validators.required]);
      this.editEarningForm.controls.isrCategory.setValidators([Validators.required]);
      this.editEarningForm.controls.isrCategory.setValue(null);
    } else {
      this.editEarningForm.controls.isrAmount.clearValidators();
      this.editEarningForm.controls.isrAmount.setValue(0);
      this.editEarningForm.controls.isrCategory.clearValidators();
      this.editEarningForm.controls.isrCategory.setValue(null);
    }
    this.editEarningForm.controls.isrAmount.updateValueAndValidity();
    this.editEarningForm.controls.isrCategory.updateValueAndValidity();
  }

  updateEarning() {
    if (this.editEarningForm.valid) {
      this.isUpdating = true;
      const data = this.editEarningForm.getRawValue();
      data.iconUid = data.iconUid.uid;
      data.conceptCode = data.conceptCode.id;
      if (!data.imssCheck) {
        data.imssCategory = 'N';
      }
      if (!data.isrCheck) {
        data.isrCategory = 'N';
      }
      data.worth = data.defaultAmount;
      delete data.defaultAmount;
      delete data.imssCheck;
      delete data.isrCheck;
      this.earningsService.updateEarning(data)
        .pipe(finalize(() => {
          this.isUpdating = false;
        }))
        .subscribe((earning: Earning) => {
          this.notification.create('success', '¡Operación exitosa!', 'Percepción actualizada correctamente');
          this.drawerRef.close(earning);
        }, error => {
          this.notification.create('error', 'Error al actualizar la percepción', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.editEarningForm);
    }
  }

  private getConcepts() {
    this.isLoading = true;
    this.earningsService.getConcepts()
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((earningConcepts: EarningConcept[]) => {
        this.earningConcepts = earningConcepts;
        this.createForm();
      }, error => {
        this.error = error.message.replace("GraphQL error:", "").trim();
        // this.notification.create('error', 'Error al crear el activo', error.message.replace("GraphQL error:", "").trim());
      });
  }

  private createForm() {
    this.editEarningForm = this.formBuilder.group({
      earningUid: [this.earning?.uid],
      defaultAmount: [this.earning?.worth ? this.earning?.worth : 0, Validators.required],
      colour: [this.earning?.colour, Validators.required],
      conceptCode:[this.getCode(), Validators.required],
      iconUid: [this.getIcon(), Validators.required],
      name: [this.earning?.name, Validators.required],
      imssCheck: [this.earning?.imssCategory !== 'Ninguno'],
      imssAmount: [this.earning?.imssAmount ? this.earning?.imssAmount : 0, this.earning?.imssCategory !== 'Ninguno' ? [Validators.required] : []],
      imssCategory: [this.getCategory('IMSS'), this.earning?.imssCategory !== 'Ninguno' ? [Validators.required] : []],
      isrCheck: [this.earning?.isrCategory !== 'Ninguno'],
      isrAmount: [this.earning?.isrAmount ? this.earning?.isrAmount : 0, this.earning?.imssCategory !== 'Ninguno' ? [Validators.required] : []],
      isrCategory: [this.getCategory('ISR'), this.earning?.isrCategory !== 'Ninguno' ? [Validators.required] : []]
    });
  }

  private getCode() {
    const index = this.earningConcepts?.findIndex(d => d.id === this.earning?.conceptCode);
    if (index !== -1) {
      return this.earningConcepts[index];
    } else {
      return null;
    }
  }

  private getIcon() {
    const index = this.icons?.findIndex(i => i.uid === this.earning?.icon?.uid);
    if (index !== -1) {
      // @ts-ignore
      return this.icons[index];
    } else {
      return null;
    }
  }

  private getCategory(type: string) {
    let index = -1;
    if (type === 'IMSS') {
      index = this.earningCategories?.findIndex(d => d.name === this.earning?.imssCategory);
    } else {
      index = this.earningCategories?.findIndex(d => d.name === this.earning?.isrCategory);
    }
    if (index !== -1) {
      return this.earningCategories[index].value;
    } else {
      return null;
    }
  }
}
