import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Company} from "../../../core/models/company";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CompanyService} from "../../../core/services/company.service";
import {FormHelperService} from "../../../core/services/form-helper.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzDrawerRef} from "ng-zorro-antd/drawer";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-edit-vacations-modal',
  templateUrl: './edit-vacations-modal.component.html',
  styleUrls: ['./edit-vacations-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditVacationsModalComponent implements OnInit {
  @Input() company: Company | null;
  companyForm: FormGroup;
  isLoading = false;
  error: string | null;
  @Output() companyEdited: EventEmitter<Company> = new EventEmitter();

  constructor(private companyService: CompanyService,
              private formHelper: FormHelperService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private drawerRef: NzDrawerRef) { }

  ngOnInit(): void {
    this.createForm();
  }

  closeModal() {
    this.drawerRef.close();
  }

  getArray(): FormArray {
    return this.companyForm.controls.vacationsConfigData as FormArray;
  }

  getArrayControl(index: number): FormGroup {
    return this.getArray().at(index) as FormGroup;
  }

  updateVacations() {
    if (this.companyForm.valid) {
      this.isLoading = true;
      this.companyService.updateCompanyVacations(this.cleanData(this.companyForm.getRawValue()))
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe((company: Company) => {
          this.notification.create('success', '¡Operación exitosa!', 'Datos actualizados correctamente');
          // @ts-ignore
          this.companyEdited.emit(company);
          this.drawerRef.close();
        }, error => {
          // this.error = error.message.replace("GraphQL error:", "").trim();
          this.notification.create('error', 'Error al actualizar las vacaciones', error.message.replace("GraphQL error:", "").trim());
        });
    } else {
      this.formHelper.markFormAsDirty(this.companyForm);
      this.formHelper.markFormArrayAsDirty(this.getArray());
    }
  }

  cleanData(data: any) {
    const form = data;
    form.vacationsConfigData.forEach((config: any) => {
      delete config.label;
    });
    return form;
  }

  private createForm() {
    this.companyForm = this.formBuilder.group({
      forAllEmployees: [false],
      rule: [this.company?.vacationsRules?.rule === 'Absolute' ? 'a' : 'v'],
      vacationsConfigData: this.formBuilder.array([])
    });
    this.setConfigData();
  }

  private setConfigData() {
    const arr = this.getArray();
    this.company?.vacationsRules?.vacationsConfig?.forEach((config, index) => {
      arr.push(
        this.formBuilder.group({
          days: [config.days, Validators.required],
          index: [index],
          uid: [config.uid],
          label: [config.label]
        })
      )
    });
  }
}
