<div class="subscription-message">
  <nz-alert
    *ngIf="trialMonth"
    style="margin-bottom: 20px;"
    nzType="warning"
    nzMessage="¡Advertencia!"
    nzDescription="Al cambiar de plan, tu periodo de prueba se considerará como finalizado"
    nzShowIcon
  ></nz-alert>

  <div class="message-container">
    <div class="message">¿Estás seguro que quieres actualizar tu plan actual a Plan {{ plan?.name }}?</div>
  </div>

  <div class="btns-container">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="update()">Guardar</button>
  </div>
</div>
