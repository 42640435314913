import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Package} from '../models/package';
import {Apollo} from 'apollo-angular';
import {Position} from '../models/position';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {

  constructor(
      private apollo: Apollo
  ) { }

  getPackages(): Observable<Package[]> {
    return this.apollo.query<{packages: Package[]}>({
      query: gql`
          query packages {
              packages {
                  uid
                  availableModules
                  employeePrice
                  monthlyPrice
                  name
                  temporalChargeType
              }
          }
      `
    }).pipe(
        map(({data}) => {
          return data.packages;
        })
    )
  }
}
