<div class="non-attendances-table" *ngIf="attendances">

  <div class="title">
    Fuera de ubicación
  </div>

  <div class="table flex column" *ngIf="attendances?.length">
    <div class="row" *ngFor="let attendance of attendances | slice: (index * 10 - 10) : (index * 10)">
      <app-not-on-location-box [member]="member" [attendance]="attendance"></app-not-on-location-box>
    </div>
  </div>

  <div class="pagination-row flex end" *ngIf="attendances?.length">
    <nz-pagination [(nzPageIndex)]="index" [nzTotal]="attendances.length"></nz-pagination>
  </div>

  <ng-container *ngIf="attendances?.length == 0">
    <nz-result nzStatus="404" nzTitle="No se encontraron registros" nzSubTitle="No se encontró ningún registro en este rango">
    </nz-result>
  </ng-container>
</div>
