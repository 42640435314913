import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import { NewTeamModalComponent } from './modals/new-team-modal/new-team-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MembersFormControlComponent} from './controls/members-form-control/members-form-control.component';
import {TeamBoxComponent} from './components/team-box/team-box.component';
import { EditTeamModalComponent } from './modals/edit-team-modal/edit-team-modal.component';
import { NewGeoFencingModalComponent } from './modals/new-geo-fencing-modal/new-geo-fencing-modal.component';
import { GeoFencingMapComponent } from './maps/geo-fencing-map/geo-fencing-map.component';
import {AgmCoreModule} from '@agm/core';
import { GeoFencingsFormControlComponent } from './controls/geo-fencings-form-control/geo-fencings-form-control.component';
import { TodayMemberBoxComponent } from './components/today-member-box/today-member-box.component';
import { TurnBoxComponent } from './components/turn-box/turn-box.component';
import { AttendanceModalComponent } from './modals/attendance-modal/attendance-modal.component';
import { AttendanceMapComponent } from './maps/attendance-map/attendance-map.component';
import { AbsenceRequestBoxComponent } from './components/absence-request-box/absence-request-box.component';
import { AbsenceRequestModalComponent } from './modals/absence-request-modal/absence-request-modal.component';
import { DownloadFileBoxComponent } from './components/download-file-box/download-file-box.component';
import { NewAnnouncementModalComponent } from './modals/new-announcement-modal/new-announcement-modal.component';
import { TeamsFormControlComponent } from './controls/teams-form-control/teams-form-control.component';
import {RouterModule} from '@angular/router';
import { AnnouncementBoxComponent } from './components/announcement-box/announcement-box.component';
import { EditAnnouncementModalComponent } from './modals/edit-announcement-modal/edit-announcement-modal.component';
import { AnnouncementDetailsComponent } from './modals/announcement-details/announcement-details.component';
import { AbsenceRequestRowComponent } from './components/absence-request-row/absence-request-row.component';
import { EditMemberGeneralDataModalComponent } from './modals/edit-member-general-data-modal/edit-member-general-data-modal.component';
import { EditMemberSalaryComponent } from './modals/edit-member-salary/edit-member-salary.component';
import { EditMemberPositionComponent } from './modals/edit-member-position/edit-member-position.component';
import { NewPositionModalComponent } from './modals/new-position-modal/new-position-modal.component';
import {MemberNonAttendanceTableComponent} from "./tables/member-non-attendance-table/member-non-attendance-table.component";
import {MemberNotOnTimeTableComponent} from "./tables/member-not-on-time-table/member-not-on-time-table.component";
import {MemberNotOnLocationTableComponent} from "./tables/member-not-on-location-table/member-not-on-location-table.component";
import {MemberVacationsTableComponent} from "./tables/member-vacations-table/member-vacations-table.component";
import {NonAttendanceBoxComponent} from "./components/non-attendance-box/non-attendance-box.component";
import {NotOnLocationBoxComponent} from "./components/not-on-location-box/not-on-location-box.component";
import {NotOnTimeBoxComponent} from "./components/not-on-time-box/not-on-time-box.component";
import {VacationBoxComponent} from "./components/vacation-box/vacation-box.component";
import { MapBoxComponent } from './components/map-box/map-box.component';
import { EditGeoFencingModalComponent } from './modals/edit-geo-fencing-modal/edit-geo-fencing-modal.component';
import { FileBoxComponent } from './components/file-box/file-box.component';
import { EditMemberScheduleModalComponent } from './modals/edit-member-schedule-modal/edit-member-schedule-modal.component';
import { ScheduleFormControlComponent } from './controls/schedule-form-control/schedule-form-control.component';
import { TurnFormControlComponent } from './controls/turn-form-control/turn-form-control.component';
import {AbsenceTypeRowComponent} from './components/absence-type-row/absence-type-row.component';
import { NewAbsenceTypeComponent } from './modals/new-absence-type/new-absence-type.component';
import { EditAbsenceTypeComponent } from './modals/edit-absence-type/edit-absence-type.component';
import { PositionRowComponent } from './components/position-row/position-row.component';
import { EditPositionComponent } from './modals/edit-position/edit-position.component';
import { EditSalaryComponent } from './modals/edit-salary/edit-salary.component';
import { PositionDetailsComponent } from './modals/position-details/position-details.component';
import { GeoFencingRowComponent } from './components/geo-fencing-row/geo-fencing-row.component';
import { GeoFencingDetailsComponent } from './modals/geo-fencing-details/geo-fencing-details.component';
import {CreateMemberModalComponent} from './modals/create-member-modal/create-member-modal.component';
import { BarVerticalMultiComponent } from './graphs/bar-vertical-multi/bar-vertical-multi.component';
import { PieGraphComponent } from './graphs/pie-graph/pie-graph.component';
import { AreaChartComponent } from './graphs/area-chart/area-chart.component';
import { EditCompanyGeneralDataModalComponent } from './modals/edit-company-general-data-modal/edit-company-general-data-modal.component';
import { EditCompanyContactDataComponent } from './modals/edit-company-contact-data/edit-company-contact-data.component';
import { NewPaymentMethodComponent } from './modals/new-payment-method/new-payment-method.component';
import {CreditCardDirectivesModule} from "angular-cc-library";
import { EditVacationsModalComponent } from './modals/edit-vacations-modal/edit-vacations-modal.component';
import { VacationConfigControlComponent } from './controls/vacation-config-control/vacation-config-control.component';
import { HelpModalComponent } from './modals/help-modal/help-modal.component';
import { EditMemberVacationsModalComponent } from './modals/edit-member-vacations-modal/edit-member-vacations-modal.component';
import { NewDocumentTypeComponent } from './modals/new-document-type/new-document-type.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { EditCompanySubscriptionComponent } from './modals/edit-company-subscription/edit-company-subscription.component';
import { SubscriptionMessageComponent } from './modals/subscription-message/subscription-message.component';
import {TeamLocationsComponent} from './components/team-locations/team-locations.component';
import { NewActiveResourceModalComponent } from './modals/new-active-resource-modal/new-active-resource-modal.component';
import { CreateActiveResourceCategoryComponent } from './modals/create-active-resource-category/create-active-resource-category.component';
import { ActiveResourceRowComponent } from './components/active-resource-row/active-resource-row.component';
import { EditActiveResourceModalComponent } from './modals/edit-active-resource-modal/edit-active-resource-modal.component';
import { ActiveResourceHistoryModalComponent } from './modals/active-resource-history-modal/active-resource-history-modal.component';
import { AssignActiveResourceModalComponent } from './modals/assign-active-resource-modal/assign-active-resource-modal.component';
import { PaymentBoxComponent } from './components/payment-box/payment-box.component';
import {ChangeActiveResourceHolderModalComponent} from "./modals/change-active-resource-holder-modal/change-active-resource-holder-modal.component";
import { CreateFestivityDayModalComponent } from './modals/create-festivity-day-modal/create-festivity-day-modal.component';
import { FestivityDayRowComponent } from './components/festivity-day-row/festivity-day-row.component';
import { EditFestivityDayModalComponent } from './modals/edit-festivity-day-modal/edit-festivity-day-modal.component';
import { BalanceTableComponent } from './tables/balance-table/balance-table.component';
import { AddCreditsModalComponent } from './modals/add-credits-modal/add-credits-modal.component';
import { MassiveLoadModalComponent } from './modals/massive-load-modal/massive-load-modal.component';
import { PrivilegesRowComponent } from './components/privileges-row/privileges-row.component';
import { PrivilegesFormControlComponent } from './controls/privileges-form-control/privileges-form-control.component';
import { EditActualVacationsComponent } from './modals/edit-actual-vacations/edit-actual-vacations.component';
import {NewDeductionModalComponent} from "./modals/new-deduction-modal/new-deduction-modal.component";
import { DeductionRowComponent } from './components/deduction-row/deduction-row.component';
import { EditDeductionModalComponent } from './modals/edit-deduction-modal/edit-deduction-modal.component';
import { NewEarningModalComponent } from './modals/new-earning-modal/new-earning-modal.component';
import { EarningRowComponent } from './components/earning-row/earning-row.component';
import { EditEarningModalComponent } from './modals/edit-earning-modal/edit-earning-modal.component';
import { NewEmployerRegistryModalComponent } from './modals/new-employer-registry-modal/new-employer-registry-modal.component';
import { EmployerRegistryRowComponent } from './components/employer-registry-row/employer-registry-row.component';
import { EditEmployerRegistryModalComponent } from './modals/edit-employer-registry-modal/edit-employer-registry-modal.component';
import { CountCardComponent } from './graphs/count-card/count-card.component';
import { UserHorizontalBarComponent } from './graphs/user-horizontal-bar/user-horizontal-bar.component';
import { EmployerRegistryDetailsModalComponent } from './modals/employer-registry-details-modal/employer-registry-details-modal.component';
import { PayrollRowComponent } from './components/payroll-row/payroll-row.component';
import { NewPayrollModalComponent } from './modals/new-payroll-modal/new-payroll-modal.component';
import { DeductionsFormControlComponent } from './controls/deductions-form-control/deductions-form-control.component';
import { EarningsFormControlComponent } from './controls/earnings-form-control/earnings-form-control.component';
import { PayrollSummaryComponent } from './components/payroll-summary/payroll-summary.component';
import { PaycheckRowComponent } from './components/paycheck-row/paycheck-row.component';
import { AddPaycheckModalComponent } from './modals/add-paycheck-modal/add-paycheck-modal.component';
import { EditPaycheckInfoModalComponent } from './modals/edit-paycheck-info-modal/edit-paycheck-info-modal.component';
import { EditPaycheckEarningsModalComponent } from './modals/edit-paycheck-earnings-modal/edit-paycheck-earnings-modal.component';
import { EditPaycheckDeductionsModalComponent } from './modals/edit-paycheck-deductions-modal/edit-paycheck-deductions-modal.component';
import { MemberPaycheckRowComponent } from './components/member-paycheck-row/member-paycheck-row.component';
import { StampModalComponent } from './modals/stamp-modal/stamp-modal.component';
import { MeetingRowComponent } from './components/meeting-row/meeting-row.component';
import { MeetingDetailsModalComponent } from './modals/meeting-details-modal/meeting-details-modal.component';
import { CreateMeetingModalComponent } from './modals/create-meeting-modal/create-meeting-modal.component';
import { EditMeetingModalComponent } from './modals/edit-meeting-modal/edit-meeting-modal.component';
import { SlackRowComponent } from './components/slack-row/slack-row.component';
import { SlackBoxComponent } from './components/slack-box/slack-box.component';
import { GoogleRowComponent } from './components/google-row/google-row.component';
import { MemberMeetingAllTableComponent } from './tables/member-meeting-all-table/member-meeting-all-table.component';
import { MeetingBoxComponent } from './components/meeting-box/meeting-box.component';
import { MemberMeetingNotOnTimeTableComponent } from './tables/member-meeting-not-on-time-table/member-meeting-not-on-time-table.component';
import { MemberMeetingNotOnLocationTableComponent } from './tables/member-meeting-not-on-location-table/member-meeting-not-on-location-table.component';
import { MemberMeetingNonAttendanceTableComponent } from './tables/member-meeting-non-attendance-table/member-meeting-non-attendance-table.component';
import { DispersionModalComponent } from './modals/dispersion-modal/dispersion-modal.component';
import { AppealRowComponent } from './components/appeal-row/appeal-row.component';
import { AppealDetailsModalComponent } from './modals/appeal-details-modal/appeal-details-modal.component';
import { FileDetailsComponent } from './components/file-details/file-details.component';
import { MemberGeoLocationsMapComponent } from './maps/member-geo-locations-map/member-geo-locations-map.component';
import { MemberGeoLocationsCardComponent } from './maps/member-geo-locations-card/member-geo-locations-card.component';
import {AgmMarkerClustererModule} from "@agm/markerclusterer";
import { TeamGeoLocationsCardComponent } from './maps/team-geo-locations-card/team-geo-locations-card.component';
import { TeamGeoLocationsMapComponent } from './maps/team-geo-locations-map/team-geo-locations-map.component';
import {AgmOverlays} from "agm-overlays";
import { HomeGeoLocationsMapComponent } from './maps/home-geo-locations-map/home-geo-locations-map.component';
import { HomeGeoLocationsCardComponent } from './maps/home-geo-locations-card/home-geo-locations-card.component';
import { EditCompanyFiscalDataComponent } from './modals/edit-company-fiscal-data/edit-company-fiscal-data.component';
import { HelpCompanyNameComponent } from './modals/help-company-name/help-company-name.component';

@NgModule({
  declarations: [
    NewTeamModalComponent,
    MembersFormControlComponent,
    TeamBoxComponent,
    EditTeamModalComponent,
    NewGeoFencingModalComponent,
    GeoFencingMapComponent,
    GeoFencingsFormControlComponent,
    TodayMemberBoxComponent,
    TurnBoxComponent,
    AttendanceModalComponent,
    AttendanceMapComponent,
    AbsenceRequestBoxComponent,
    AbsenceRequestModalComponent,
    DownloadFileBoxComponent,
    NewAnnouncementModalComponent,
    TeamsFormControlComponent,
    AnnouncementBoxComponent,
    EditAnnouncementModalComponent,
    AnnouncementDetailsComponent,
    AbsenceRequestRowComponent,
    EditMemberGeneralDataModalComponent,
    EditMemberSalaryComponent,
    EditMemberPositionComponent,
    NewPositionModalComponent,
    MemberNonAttendanceTableComponent,
    MemberNotOnTimeTableComponent,
    MemberNotOnLocationTableComponent,
    MemberVacationsTableComponent,
    NonAttendanceBoxComponent,
    NotOnLocationBoxComponent,
    NotOnTimeBoxComponent,
    VacationBoxComponent,
    MapBoxComponent,
    EditGeoFencingModalComponent,
    FileBoxComponent,
    EditMemberScheduleModalComponent,
    ScheduleFormControlComponent,
    TurnFormControlComponent,
    AbsenceTypeRowComponent,
    NewAbsenceTypeComponent,
    EditAbsenceTypeComponent,
    PositionRowComponent,
    EditPositionComponent,
    EditSalaryComponent,
    PositionDetailsComponent,
    GeoFencingRowComponent,
    GeoFencingDetailsComponent,
    CreateMemberModalComponent,
    BarVerticalMultiComponent,
    PieGraphComponent,
    AreaChartComponent,
    EditCompanyGeneralDataModalComponent,
    EditCompanyContactDataComponent,
    NewPaymentMethodComponent,
    EditVacationsModalComponent,
    VacationConfigControlComponent,
    HelpModalComponent,
    EditMemberVacationsModalComponent,
    NewDocumentTypeComponent,
    EditCompanySubscriptionComponent,
    SubscriptionMessageComponent,
    TeamLocationsComponent,
    NewActiveResourceModalComponent,
    CreateActiveResourceCategoryComponent,
    ActiveResourceRowComponent,
    EditActiveResourceModalComponent,
    ActiveResourceHistoryModalComponent,
    AssignActiveResourceModalComponent,
    PaymentBoxComponent,
    ChangeActiveResourceHolderModalComponent,
    CreateFestivityDayModalComponent,
    FestivityDayRowComponent,
    EditFestivityDayModalComponent,
    BalanceTableComponent,
    AddCreditsModalComponent,
    MassiveLoadModalComponent,
    EditActualVacationsComponent,
    NewDeductionModalComponent,
    PrivilegesRowComponent,
    PrivilegesFormControlComponent,
    DeductionRowComponent,
    EditDeductionModalComponent,
    NewEarningModalComponent,
    EarningRowComponent,
    EditEarningModalComponent,
    NewEmployerRegistryModalComponent,
    EmployerRegistryRowComponent,
    EditEmployerRegistryModalComponent,
    EditActualVacationsComponent,
    CountCardComponent,
    UserHorizontalBarComponent,
    EmployerRegistryDetailsModalComponent,
    PayrollRowComponent,
    NewPayrollModalComponent,
    DeductionsFormControlComponent,
    EarningsFormControlComponent,
    PayrollSummaryComponent,
    PaycheckRowComponent,
    AddPaycheckModalComponent,
    EditPaycheckInfoModalComponent,
    EditPaycheckEarningsModalComponent,
    EditPaycheckDeductionsModalComponent,
    MemberPaycheckRowComponent,
    StampModalComponent,
    MeetingRowComponent,
    MeetingDetailsModalComponent,
    CreateMeetingModalComponent,
    EditMeetingModalComponent,
    SlackRowComponent,
    SlackBoxComponent,
    GoogleRowComponent,
    MemberMeetingAllTableComponent,
    MeetingBoxComponent,
    MemberMeetingNotOnTimeTableComponent,
    MemberMeetingNotOnLocationTableComponent,
    MemberMeetingNonAttendanceTableComponent,
    DispersionModalComponent,
    AppealRowComponent,
    AppealDetailsModalComponent,
    FileDetailsComponent,
    MemberGeoLocationsMapComponent,
    MemberGeoLocationsCardComponent,
    TeamGeoLocationsCardComponent,
    TeamGeoLocationsMapComponent,
    HomeGeoLocationsMapComponent,
    HomeGeoLocationsCardComponent,
    EditCompanyFiscalDataComponent,
    HelpCompanyNameComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AgmCoreModule,
    RouterModule,
    CreditCardDirectivesModule,
    DragScrollModule,
    AgmMarkerClustererModule,
    AgmOverlays
  ],
  exports: [
    TeamBoxComponent,
    TodayMemberBoxComponent,
    TurnBoxComponent,
    AbsenceRequestBoxComponent,
    AnnouncementBoxComponent,
    AbsenceRequestRowComponent,
    MemberNonAttendanceTableComponent,
    MemberNotOnTimeTableComponent,
    MemberNotOnLocationTableComponent,
    MemberVacationsTableComponent,
    CreateMemberModalComponent,
    NonAttendanceBoxComponent,
    MapBoxComponent,
    FileBoxComponent,
    AbsenceTypeRowComponent,
    PositionRowComponent,
    GeoFencingRowComponent,
    MembersFormControlComponent,
    BarVerticalMultiComponent,
    PieGraphComponent,
    AreaChartComponent,
    TeamLocationsComponent,
    ActiveResourceRowComponent,
    PaymentBoxComponent,
    FestivityDayRowComponent,
    BalanceTableComponent,
    PrivilegesRowComponent,
    DeductionRowComponent,
    EarningRowComponent,
    EmployerRegistryRowComponent,
    CountCardComponent,
    UserHorizontalBarComponent,
    PayrollRowComponent,
    PayrollSummaryComponent,
    PaycheckRowComponent,
    MemberPaycheckRowComponent,
    MeetingRowComponent,
    SlackRowComponent,
    GoogleRowComponent,
    MemberMeetingAllTableComponent,
    MemberMeetingNotOnTimeTableComponent,
    MemberMeetingNotOnLocationTableComponent,
    MemberMeetingNonAttendanceTableComponent,
    AppealRowComponent,
    MemberGeoLocationsMapComponent,
    MemberGeoLocationsCardComponent,
    TeamGeoLocationsMapComponent,
    TeamGeoLocationsCardComponent,
    HomeGeoLocationsMapComponent,
    HomeGeoLocationsCardComponent
  ],
  entryComponents: [
    NewTeamModalComponent,
    EditTeamModalComponent
  ],
  providers: [DatePipe, CurrencyPipe]
})
export class FeaturesModule { }
