import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import {Icon} from '../models/icon';
import {IconService} from '../services/icon.service';

@Injectable({
  providedIn: 'root'
})
export class IconStore {
  icons$: Subject<Icon[] | undefined> = new ReplaySubject();
  icons: Icon[] | undefined;

  images$: Subject<Icon[] | undefined> = new ReplaySubject();
  images: Icon[] | undefined;

  isLoading$: BehaviorSubject<boolean>;
  isError$: BehaviorSubject<string | null>;

  constructor(
      private iconService: IconService
  ) {
    if (!this.isLoading$) {
      this.isLoading$ = new BehaviorSubject(false) as BehaviorSubject<boolean>;
    }

    if (!this.isError$) {
      this.isError$ = new BehaviorSubject(null)  as BehaviorSubject<string | null>;
    }

    if (!this.icons) {
      this.subscribeToGetIcons();
    }

    if (!this.images) {
      this.subscribeToGetImages();
    }
  }

  getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  getIsError(): Observable<string | null> {
    return this.isError$.asObservable();
  }

  private subscribeToGetImages(): void {
    this.iconService
        .getIcons('icon')
        .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
        )
        .subscribe(
            (icons: Icon[] | undefined) => {
              this.icons = icons;
              this.icons$.next(this.icons);
            },
            (error: any) => {
              this.isError$.next(
                  error.message.replace("GraphQL error:", "").trim()
              );
            }
        );
  }

  private subscribeToGetIcons(): void {
    this.iconService
        .getIcons('image')
        .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
        )
        .subscribe(
            (images: Icon[] | undefined) => {
              this.images = images;
              this.images$.next(this.images);
            },
            (error: any) => {
              this.isError$.next(
                  error.message.replace("GraphQL error:", "").trim()
              );
            }
        );
  }

  getIcons(): Observable<Icon[] | undefined> {
    return this.icons$.asObservable();
  }

  getImages(): Observable<Icon[] | undefined> {
    return this.images$.asObservable();
  }
}
