import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {AbsenceRequest} from '../../../core/models/absence-request';
import {User} from '../../../core/models/user';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {AbsenceRequestModalComponent} from '../../modals/absence-request-modal/absence-request-modal.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-absence-request-box',
  templateUrl: './absence-request-box.component.html',
  styleUrls: ['./absence-request-box.component.scss']
})
export class AbsenceRequestBoxComponent implements OnInit, OnDestroy {
  @Input() absenceRequest: AbsenceRequest;
  @Input() member: User | undefined;

  $onDestroy: Subject<void> = new Subject<void>();

  @ViewChild('absenceModalTitle') absenceModalTitle: TemplateRef<any>;

  @Output() absenceRequestUpdated: EventEmitter<AbsenceRequest> = new EventEmitter<AbsenceRequest>();

  constructor(
      private drawerService: NzDrawerService
  ) { }

  ngOnInit(): void {
    const time = new Date(this.absenceRequest.toDate).getTime() - new Date(this.absenceRequest.fromDate).getTime();
    this.absenceRequest.days =( time / (1000 * 3600 * 24)) + 1;
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  openModal() {
    const modal = this.drawerService.create({
      nzContent: AbsenceRequestModalComponent,
      nzContentParams: {
        member: this.member,
        absenceRequest: this.absenceRequest
      },
      nzTitle: this.absenceModalTitle,
      nzWidth: 504
    });

    modal.afterOpen.pipe(
        takeUntil(this.$onDestroy)
    ).subscribe(() => {
      modal.getContentComponent()?.absenceRequestUpdated
          .pipe(takeUntil(this.$onDestroy))
          .subscribe((newAbsence: AbsenceRequest) => {
            this.absenceRequest = newAbsence;
            this.absenceRequestUpdated.emit(newAbsence);
          })
    })
  }

}
