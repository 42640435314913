<nz-spin class="spin-loading" [nzSpinning]="isLoading">
  <form [formGroup]="editEarningForm" *ngIf="!isLoading && editEarningForm">
    <div class="new-team">

      <div class="error-box" *ngIf="error && !isLoading">
        <nz-alert
          nzType="error"
          [nzMessage]="error"
          nzShowIcon
        ></nz-alert>
      </div>

      <div class="new-team-container flex wrap">
        <div class="flex center wrap form-column team-icon">
          <app-team-icon
            [size]="90"
            [icon]="editEarningForm.controls.iconUid.value?.url"
            [color]="editEarningForm.controls.colour.value"></app-team-icon>
        </div>

        <div nzLayout="vertical" nz-form class="form-column">
          <nz-form-item>
            <nz-form-label nzFor="icon">Seleccionar icono</nz-form-label>
            <nz-form-control [nzErrorTip]="iconErrorTpl">
              <nz-select [nzCustomTemplate]="iconTemplate" id="icon" nzPlaceHolder="Icono" formControlName="iconUid">
                <nz-option *ngFor="let icon of icons" nzCustomContent [nzLabel]="icon!.name" [nzValue]="icon">
                  <app-icon-box [icon]="icon"></app-icon-box>
                </nz-option>
              </nz-select>

              <ng-template #iconTemplate let-selected>
                <app-icon-box [size]="20" [icon]="selected.nzValue"></app-icon-box>
              </ng-template>

              <ng-template #iconErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge un icono
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="color">Seleccionar color</nz-form-label>
            <nz-form-control [nzErrorTip]="colorErrorTpl">
              <nz-select [nzCustomTemplate]="colorTemplate" id="color" nzPlaceHolder="Color" formControlName="colour">
                <nz-option *ngFor="let color of colors" nzCustomContent [nzLabel]="color!.label" [nzValue]="color!.value">
                  <app-color-box [color]="color"></app-color-box>
                </nz-option>
              </nz-select>

              <ng-template #colorTemplate let-selected>
                <app-color-box [size]="20" [color]="{
              label: selected.nzLabel,
              value: selected.nzValue
            }"></app-color-box>
              </ng-template>

              <ng-template #colorErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge un color
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nzLayout="vertical" nz-form class="form-column">
          <nz-form-item>
            <nz-form-label nzFor="name">Nombre de la percepción</nz-form-label>
            <nz-form-control [nzErrorTip]="nameErrorTpl">

              <input nz-input placeholder="Nombre de la percepción" formControlName="name" type="text" id="name">

              <ng-template #nameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Ingresa el nombre de la percepción
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzFor="category">Categoría del SAT</nz-form-label>
            <nz-form-control class="select" [nzErrorTip]="categoryErrorTpl">
              <nz-select nzShowSearch id="category" nzPlaceHolder="Selecciona la categoría del SAT" formControlName="conceptCode" [nzCustomTemplate]="template">
                <nz-option *ngFor="let concept of earningConcepts" nzCustomContent [nzLabel]="concept?.description" [nzValue]="concept">
                  <div class="concept">{{concept?.description}}</div>
                </nz-option>
              </nz-select>

              <ng-template #template let-selected>
                <div>{{ selected.nzValue?.description | limitTo: 50: true }}</div>
              </ng-template>

              <ng-template #categoryErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Escoge una categoría
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <div class="items-container">
            <nz-form-item class="item">
              <nz-form-label>Monto por defecto</nz-form-label>
              <nz-form-control [nzErrorTip]="newPaidAmountErrorTpl">

                <nz-input-number
                  style="width: 100%"
                  [OnlyNumber]="true"
                  formControlName="defaultAmount"
                  [nzMin]="0"
                  [nzStep]="10"
                  [nzFormatter]="formatterCurrency"
                  [nzParser]="parserCurrency">
                </nz-input-number>

                <ng-template #newPaidAmountErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    El monto por defecto es requerido
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <nz-form-item>
            <nz-form-control>
              <label nz-checkbox formControlName="imssCheck" (ngModelChange)="imssValueChange($event)">Gravable para IMSS</label>
            </nz-form-control>
          </nz-form-item>


          <div class="items-container" *ngIf="editEarningForm.controls.imssCheck.value">
            <nz-form-item class="item">
              <nz-form-label>Tipo</nz-form-label>
              <nz-form-control [nzErrorTip]="imssCategoryErrorTpl">

                <nz-select nzPlaceHolder="Selecciona el tipo" formControlName="imssCategory">
                  <nz-option *ngFor="let category of earningCategories"  [nzLabel]="category?.name" [nzValue]="category?.value">
                    {{ category?.name }}
                  </nz-option>
                </nz-select>

                <ng-template #imssCategoryErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Elige un tipo
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="item">
              <nz-form-label>Cantidad</nz-form-label>
              <nz-form-control [nzErrorTip]="imssAmountErrorTpl">

                <nz-input-number
                  style="width: 100%"
                  [OnlyNumber]="true"
                  formControlName="imssAmount"
                  [nzMin]="0"
                  [nzStep]="10"
                  [nzFormatter]="editEarningForm.controls.imssCategory.value !== null ?
                  (editEarningForm.controls.imssCategory.value === 'U' ||
                  editEarningForm.controls.imssCategory.value === 'M' ||
                  editEarningForm.controls.imssCategory.value === 'P') ? formatterPercentage :
                  editEarningForm.controls.imssCategory.value === 'C' ? formatterCurrency : formatter : formatter"
                  [nzParser]="editEarningForm.controls.imssCategory.value !== null ?
                  (editEarningForm.controls.imssCategory.value === 'U' ||
                  editEarningForm.controls.imssCategory.value === 'M' ||
                  editEarningForm.controls.imssCategory.value === 'P') ? parserPercentage :
                  editEarningForm.controls.imssCategory.value === 'C' ? parserCurrency : parser : parser">
                </nz-input-number>

                <ng-template #imssAmountErrorTpl let-control>
                  La cantidad es requerida
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>


          <nz-form-item>
            <nz-form-control>
              <label nz-checkbox formControlName="isrCheck" (ngModelChange)="isrValueChange($event)">Gravable para ISR</label>
            </nz-form-control>
          </nz-form-item>

          <div class="items-container" *ngIf="editEarningForm.controls.isrCheck.value">
            <nz-form-item class="item">
              <nz-form-label>Tipo</nz-form-label>
              <nz-form-control [nzErrorTip]="isrCategoryErrorTpl">

                <nz-select nzPlaceHolder="Selecciona el tipo" formControlName="isrCategory">
                  <nz-option *ngFor="let category of earningCategories"  [nzLabel]="category?.name" [nzValue]="category?.value">
                    {{ category?.name }}
                  </nz-option>
                </nz-select>

                <ng-template #isrCategoryErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    Elige un tipo
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="item">
              <nz-form-label>Cantidad</nz-form-label>
              <nz-form-control [nzErrorTip]="isrAmounttErrorTpl">

                <nz-input-number
                  style="width: 100%"
                  [OnlyNumber]="true"
                  formControlName="isrAmount"
                  [nzMin]="0"
                  [nzStep]="10"
                  [nzFormatter]="editEarningForm.controls.isrCategory.value !== null ?
                  (editEarningForm.controls.isrCategory.value === 'U' ||
                  editEarningForm.controls.isrCategory.value === 'M' ||
                  editEarningForm.controls.isrCategory.value === 'P') ? formatterPercentage :
                  editEarningForm.controls.isrCategory.value === 'C' ? formatterCurrency : formatter : formatter"
                  [nzParser]="editEarningForm.controls.isrCategory.value !== null ?
                  (editEarningForm.controls.isrCategory.value === 'U' ||
                  editEarningForm.controls.isrCategory.value === 'M' ||
                  editEarningForm.controls.isrCategory.value === 'P') ? parserPercentage :
                  editEarningForm.controls.isrCategory.value === 'C' ? parserCurrency : parser : parser">
                </nz-input-number>

                <ng-template #isrAmounttErrorTpl let-control>
                  La cantidad es requerida
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>
      </div>
    </div>

  </form>
</nz-spin>

<div class="drawer-footer ant-drawer-footer">
  <div class="flex end wrap">
    <button nz-popconfirm
            nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
            (nzOnConfirm)="close()"
            type="button" nz-button nzType="default">Cancelar</button>
    <button *ngIf="!isLoading" [nzLoading]="isUpdating" (click)="updateEarning()" type="button" nz-button nzType="primary">Guardar</button>
  </div>
</div>
