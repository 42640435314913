<div class="edit-paycheck-info-modal">
  <form nz-form nzLayout="vertical" [formGroup]="paycheckForm" *ngIf="paycheckForm">

    <div class="item-container">
      <nz-form-item class="item">
        <nz-form-label>Días Trabajados</nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="labouredDays"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="formatterDays"
            [nzParser]="parserDays">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Días de ausencia</nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="absentDays"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="formatterDays"
            [nzParser]="parserDays">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>


      <nz-form-item class="item">
        <nz-form-label>Días de incapacidad</nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="incapacityDays"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="formatterDays"
            [nzParser]="parserDays">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Días de vacaciones</nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="vacationDays"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="formatterDays"
            [nzParser]="parserDays">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Horas extra dobles</nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="doubleHours"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="formatterHrs"
            [nzParser]="parserHrs">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Horas extra triples</nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="tripleHours"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="formatterHrs"
            [nzParser]="parserHrs">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Días con horas extra <i style="cursor: default" nz-tooltip="El número de días en que el trabajador realizó horas extra en el periodo" [nzTooltipColor]="'white'" class="help-icon" nz-icon nzType="info-circle" nzTheme="fill"></i></nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="extraHoursDays"
            [nzMin]="0"
            [nzStep]="1"
            [nzFormatter]="formatterDays"
            [nzParser]="parserDays">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="row-container">
      <div class="text">Días laborales</div>
      <div class="content">{{ paycheck?.labouredDays }}</div>
    </div>

    <div class="title">Editar sueldo</div>

    <div class="item-container">
      <nz-form-item class="item">
        <nz-form-label>Salario</nz-form-label>
        <nz-form-control>

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="paidAmount"
            [nzMin]="1"
            [nzStep]="10"
            [nzFormatter]="formatter"
            [nzParser]="parser">
          </nz-input-number>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item">
        <nz-form-label>Salario IMSS</nz-form-label>
        <nz-form-control [nzErrorTip]="imssPaidAmountErrorTpl">

          <nz-input-number
            style="width: 100%"
            [OnlyNumber]="true"
            formControlName="imssPaidAmount"
            [nzMin]="0"
            [nzStep]="10"
            [nzFormatter]="formatter"
            [nzParser]="parser">
          </nz-input-number>

          <ng-template #imssPaidAmountErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              El salario IMSS es requerido
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="item" style="margin-bottom: 20%">
        <nz-form-label>Jornada laboral</nz-form-label>
        <nz-form-control [nzErrorTip]="hoursWorkedErrorTpl">

          <nz-select formControlName="hoursWorked">
            <nz-option *ngFor="let hour of hours" [nzValue]="hour" [nzLabel]="hour + ' hrs'"></nz-option>
          </nz-select>

          <ng-template #hoursWorkedErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              La jornada laboral es requerida
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

  </form>

  <div class="drawer-footer ant-drawer-footer">
    <div class="flex end wrap">
      <button nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas cerrar esta ventana?"
              (nzOnConfirm)="close()"
              type="button" nz-button nzType="default">Cancelar</button>
      <button [nzLoading]="isLoading" (click)="updatePaycheck()" type="button" nz-button nzType="primary">Guardar</button>
    </div>
  </div>
</div>
