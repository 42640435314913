import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {NzMessageService} from 'ng-zorro-antd/message';
import {CompanyModulesService} from '../../core/services/company-modules.service';
import {map} from 'rxjs/operators';
import {UserService} from '../../core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class IsLeaderGuard implements CanActivate {
  constructor(
      private userService: UserService,
      private messageService: NzMessageService,
      private modulesService: CompanyModulesService,
  ) {
  }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.getMe().pipe(
        map(user => {
          if (!user?.hasAdminAccess && !user?.hasLeaderAccess) {
            this.messageService.error('No tienes los privilegios para entrar a ' + (route.data.title || 'este modulo') );
            this.modulesService.navigateHome(user);
            return false;
          }
          return true;
        })
    )
  }

}
