import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {Company} from '../models/company';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {EmployeeFilter} from '../models/employee-filter';
import {EmployeesTable} from '../models/employees-table';
import {MembersTableFilter} from '../models/members-table-filter';
import {DateHelperService} from './date-helper.service';
import {MemberScheduleFilters} from "../models/member-schedule-filters";
import {ScheduleStats} from "../models/schedule-stats";
import {Schedule} from "../models/schedule";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {PaycheckTable} from "../models/paycheck-table";
import {ContractShift} from "../models/contract-shift";
import {MeetingsRegistryType} from "../models/meetings-registry-type";
import {MemberMeetingsFilters} from "../models/member-meetings-filters";

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
      private apollo: Apollo,
      private dateHelper: DateHelperService,
      private authService: AuthenticationService,
      private http: HttpClient
  ) { }

  getAllMembers(): Observable<User[] | undefined> {
    return this.apollo.query<{myCompany: Company}>({
      query: gql`
          query myCompany {
              myCompany {
                  employees {
                      uid
                      firstName
                      lastName
                      photo {
                          url
                      }
                  }
              }
          }
      `
    }).pipe(
        map(({data}) => {
          return data.myCompany.employees;
        })
    )
  }

  getMember(employeeUid: string): Observable<User | null> {
      return this.apollo.query<{employee: User}>({
          query: gql`
              query employee(
                  $employeeUid: String!
              ) {
                  employee(
                    employeeUid: $employeeUid
                  ) {
                      uid
                      firstName
                      lastName
                      email
                      photo {
                          url
                      }
                    active
                  }
              }
          `,
          variables: {
              employeeUid
          }
      }).pipe(
          map(({data}) => {
              return data.employee;
          })
      )
  }

  getMembers(filter: EmployeeFilter): Observable<EmployeesTable | undefined> {
    return this.apollo.query<{employeesTable: EmployeesTable}>({
      query: gql`
          query employeesTable(
              $substring: String
              $teamUid: String
              $unassigned: Boolean
              $skip: Int
              $limit: Int
              $sort: String
              $order: String
          ) {
              employeesTable(
                  substring: $substring
                  teamUid: $teamUid
                  unassigned: $unassigned
                  skip: $skip
                  limit: $limit
                  sort: $sort
                  order: $order
              ) {
                  employees{
                      uid
                      firstName
                      lastName
                      photo {
                          url
                      }
                  }
                  count
              }
          }
      `,
      variables: {...filter}
    }).pipe(
        map(({data}) => {
          return data.employeesTable;
        })
    )
  }

  getEmployeesTable(filters: MembersTableFilter, scheduleOnly: boolean = true): Observable<EmployeesTable> {
      if (filters.startDate)
          filters.startDate = this.dateHelper.formatDate(filters.startDate);
      if (filters.endDate)
          filters.endDate = this.dateHelper.formatDate(filters.endDate);
    return this.apollo.query<{employeesTable: EmployeesTable}>({
      query: gql`
          query employeesTable(
              $substring: String
              $teamUid: String
              $unassigned: Boolean
              $skip: Int
              $limit: Int
              $sort: String
              $order: String
              $startDate: String!
              $endDate: String!
              $approvedAbsences: Boolean
              $eventType: String
              $scheduleOnly: Boolean
          ) {
              employeesTable(
                  substring: $substring
                  teamUid: $teamUid
                  unassigned: $unassigned
                  skip: $skip
                  limit: $limit
                  sort: $sort
                  order: $order
                  eventType: $eventType
                  scheduleOnly: $scheduleOnly
              ) {
                  employees{
                      uid
                      firstName
                      lastName
                      remainingVacationDays
                      socialSecurityNumber
                      active
                      pendingAbsenceRequest: upcomingPendingAbsenceRequests {
                          uid
                          numberId
                          description
                          approved
                          fromDate
                          toDate
                          absenceType {
                              colour
                              name
                              paid
                              vacation
                              uid
                          }
                          evidences {
                              name
                              extension
                              url
                          }
                      }
                      email
                      position {
                          name
                      }
                      calendarSchedules(
                          endDate: $endDate
                          startDate: $startDate
                      ) {
                          date
                          festivityDays {
                            uid
                            numberId
                            name
                            colour
                            startDate
                            endDate
                          }
                          turns {
                              uid
                              index
                              fromHour
                              dayConfig {
                                  category
                                  approvedAbsence {
                                      uid
                                  }
                                  active
                              }
                              toHour
                              checkinAtt {
                                  eventCategory
                                  autoCreated
                                  latitude
                                  longitude
                                  onLocation
                                  onTime
                                  timestamp
                              }
                              checkoutAtt {
                                  eventCategory
                                  autoCreated
                                  latitude
                                  longitude
                                  onLocation
                                  onTime
                                  timestamp
                              }
                          }
                          absenceRequests(approved: $approvedAbsences){
                              uid
                              numberId
                              description
                              approved
                              fromDate
                              toDate
                              absenceType {
                                  colour
                                  name
                                  paid
                                  vacation
                                  uid
                              }
                              evidences {
                                  name
                                  extension
                                  url
                              }
                              approvedBy {
                                  firstName
                                  lastName
                                  email
                                  photo {
                                      url
                                  }
                              }
                          }
                      }
                      photo {
                          url
                      }
                  }
                  count
              }
          }
      `,
      variables: {...filters, scheduleOnly}
    }).pipe(
        map(({data}) => {
          return data.employeesTable;
        })
    )
  }

  getMemberGeneralData(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            firstName
            lastName
            email
            photo {
              uid
              url
            }
            phone
            birthDate
            timezone
            rfc
            gender
            checksInWithTeam
            contractDate
            address {
              city
              colony
              country
              exteriorNumber
              interiorNumber
              latitude
              longitude
              state
              street
              zipcode
            }
            remainingVacationDays
            socialSecurityNumber
            usedVacationDays
            assignedTeams {
              uid
              name
              color
              timezone
              icon {
                url
                uid
              }
            }
            team {
              uid
              name
              color
              timezone
              icon {
                url
                uid
              }
            }
            contractType
            curp
            shiftType
            externalNumber
            taxRegime {
              uid
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  updateMember(data: any): Observable<User | null> {
    return this.apollo.mutate<{updateEmployee: { employee: User }}>({
      mutation: gql`
        mutation updateEmployee(
          $employeeUid: String!
          $email: String
          $addressData: AddressInput
          $assignedTeamsUid: [String]
          $birthDate: Date
          $checksInWithTeam: Boolean
          $contractDate: Date
          $firstName: String
          $gender: String
          $geoFencingsUid: [String]
          $lastName: String
          $phone: String
          $photo: String
          $rfc: String
          $teamUid: String
          $timezone: String
          $socialSecurityNumber: String
          $curp: String
          $contractType: String
          $shiftType: String
          $externalNumber: String
          $taxRegimeUid: String
        ) {
          updateEmployee(
            employeeUid: $employeeUid
            email: $email
            addressData: $addressData
            assignedTeamsUid: $assignedTeamsUid
            birthDate: $birthDate
            checksInWithTeam: $checksInWithTeam
            contractDate: $contractDate
            firstName: $firstName
            gender: $gender
            geoFencingsUid: $geoFencingsUid
            lastName: $lastName
            phone: $phone
            photo: $photo
            rfc: $rfc
            teamUid: $teamUid
            timezone: $timezone
            socialSecurityNumber: $socialSecurityNumber
            curp: $curp
            contractType: $contractType
            shiftType: $shiftType
            externalNumber: $externalNumber
            taxRegimeUid: $taxRegimeUid
          ) {
            employee {
              uid
              firstName
              lastName
              email
              photo {
                uid
                url
              }
              phone
              birthDate
              timezone
              rfc
              gender
              checksInWithTeam
              contractDate
              address {
                city
                colony
                country
                exteriorNumber
                interiorNumber
                latitude
                longitude
                state
                street
                zipcode
              }
              remainingVacationDays
              socialSecurityNumber
              usedVacationDays
              assignedTeams {
                uid
                name
                color
                timezone
                icon {
                  url
                  uid
                }
              }
              team {
                uid
                name
                color
                timezone
                icon {
                  url
                  uid
                }
              }
              contractType
              curp
              shiftType
              externalNumber
              taxRegime {
                uid
              }
            }
          }
        }
      `,
      variables: {
        ...data
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateEmployee.employee;
      })
    );
  }

  getMemberPositionSalary(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            position {
              uid
              name
            }
            currentSalary {
              uid
              paidAmount
              imssPaidAmount
              hoursWorked
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  updateMemberSalary(form: any): Observable<User | null> {
    return this.apollo.mutate<{updateEmployeeSalary: { employee: User }}>({
      mutation: gql`
        mutation updateEmployeeSalary(
          $employeeUid: String!
          $salaryData: SalaryInputType!
        ) {
          updateEmployeeSalary(
            employeeUid: $employeeUid
            salaryData: $salaryData
          ) {
            employee {
              uid
              position {
                uid
                name
              }
              currentSalary {
                uid
                paidAmount
                imssPaidAmount
                hoursWorked
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateEmployeeSalary.employee;
      })
    )
  }

  updateMemberPosition(employeeUid: string | undefined, positionUid: string): Observable<User | null> {
    return this.apollo.mutate<{updateEmployeePosition: { employee: User }}>({
      mutation: gql`
        mutation updateEmployeePosition(
          $employeeUid: String!
          $positionUid: String!
        ) {
          updateEmployeePosition(
            employeeUid: $employeeUid
            positionUid: $positionUid
          ) {
            employee {
              uid
              position {
                uid
                name
              }
              currentSalary {
                uid
                paidAmount
              }
            }
          }
        }
      `,
      variables: {
        employeeUid,
        positionUid
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateEmployeePosition.employee;
      })
    )
  }

  getSchedule(filters: MemberScheduleFilters): Observable<ScheduleStats | undefined> {
    return this.apollo.query<{employeeScheduleStats: ScheduleStats}>({
      query: gql`
        query employeeScheduleStats(
          $employeeUid: String!
          $temporalCategory: String
          $month: Int
          $year: Int
        ) {
          employeeScheduleStats(
            employeeUid: $employeeUid
            temporalCategory: $temporalCategory
            month: $month
            year: $year
          ) {
            nonAttendancesCount
            notOnLocationCount
            notOnTimeCount
            vacationsCount
            nonAttendances {
              numberId
              createdDate
              turnFromHour
              turnToHour
            }
            notOnLocation {
              uid
              numberId
              eventCategory
              autoCreated
              latitude
              longitude
              onLocation
              onTime
              createdDate
              timestamp
              turnFromHour
              turnToHour
            }
            notOnTime {
              uid
              numberId
              eventCategory
              autoCreated
              latitude
              longitude
              onLocation
              onTime
              createdDate
              timestamp
              turnFromHour
              turnToHour
            }
            vacations {
              uid
              numberId
              description
              approved
              fromDate
              toDate
              seen
              absenceType {
                colour
                name
                paid
                vacation
              }
              employee {
                email
                firstName
                lastName
                photo {
                  url
                }
                remainingVacationDays
              }
              approvedBy {
                email
                firstName
                lastName
                photo {
                  url
                }
              }
              evidences {
                uid
                url
                extension
                name
              }
            }
          }
        }
      `,
      variables: {...filters}
    }).pipe(
      map(({data}) => {
        return data.employeeScheduleStats;
      })
    )
  }

  getMemberGeoFencings(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            geoFencings {
              borrowedFromTeam
              rangeMeters
              remoteExclusive
              uid
              name
              address {
                state
                street
                interiorNumber
                exteriorNumber
                zipcode
                colony
                city
                colony
                latitude
                longitude
              }
            }
            address {
              city
              colony
              country
              exteriorNumber
              interiorNumber
              latitude
              longitude
              state
              street
              zipcode
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  getMemberDocuments(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            documents {
              name
              approved
              document {
                uid
                name
                url
                extension
              }
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  deleteMemberDocument(fileUid: string | undefined): Observable<string | undefined> {
    const ownerDeleteEmployeeFile = gql`
      mutation ownerDeleteEmployeeFile($fileUid: String) {
        ownerDeleteEmployeeFile(fileUid: $fileUid) {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{ ownerDeleteEmployeeFile: {message: string} }>({
        mutation: ownerDeleteEmployeeFile,
        variables: {
          fileUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.ownerDeleteEmployeeFile.message;
        })
      );
  }

  updateMemberDocument(documentUid: string, employeeUid: string | null): Observable<string | undefined> {
    const leaderUploadEmployeeDocument = gql`
      mutation leaderUploadEmployeeDocument($documentUid: String! $employeeUid: String!) {
        leaderUploadEmployeeDocument(documentUid: $documentUid employeeUid: $employeeUid) {
          message
        }
      }
    `;
    return this.apollo
      .mutate<{ leaderUploadEmployeeDocument: {message: string} }>({
        mutation: leaderUploadEmployeeDocument,
        variables: {
          documentUid,
          employeeUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.leaderUploadEmployeeDocument.message;
        })
      );
  }

  approveMemberDocument(documentUid: string | undefined, employeeUid: string | null): Observable<User | undefined> {
    const approveDocument = gql`
      mutation approveDocument($documentUid: String! $employeeUid: String!) {
        approveDocument(documentUid: $documentUid employeeUid: $employeeUid) {
          employee {
            uid
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ approveDocument: {employee: User} }>({
        mutation: approveDocument,
        variables: {
          documentUid,
          employeeUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.approveDocument.employee;
        })
      );
  }

  getMemberSchedule(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            schedule {
              uid
              monday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              monday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              tuesday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              wednesday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              thursday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              friday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              saturday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
              sunday {
                active
                category
                requiresGeolocation
                turns {
                  uid
                  fromHour
                  toHour
                }
              }
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  unApproveMemberDocument(documentUid: string | undefined, employeeUid: string | null): Observable<User | undefined> {
    const rejectDocument = gql`
      mutation rejectDocument($documentUid: String! $employeeUid: String!) {
        rejectDocument(documentUid: $documentUid employeeUid: $employeeUid) {
          employee {
            uid
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ rejectDocument: {employee: User} }>({
        mutation: rejectDocument,
        variables: {
          documentUid,
          employeeUid
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.rejectDocument.employee;
        })
      );
  }

  updateMemberSchedule(form: any): Observable<Schedule | undefined> {
    const updateEmployeeSchedule = gql`
      mutation updateEmployeeSchedule($employeeUid: String! $scheduleData: ScheduleInputType!) {
        updateEmployeeSchedule(employeeUid: $employeeUid scheduleData: $scheduleData) {
          schedule {
            uid
            monday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
            monday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
            tuesday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
            wednesday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
            thursday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
            friday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
            saturday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
            sunday {
              active
              category
              requiresGeolocation
              turns {
                uid
                fromHour
                toHour
              }
            }
          }
        }
      }
    `;
    return this.apollo
      .mutate<{ updateEmployeeSchedule: {schedule: Schedule} }>({
        mutation: updateEmployeeSchedule,
        variables: {
          ...form
        }
      })
      .pipe(
        map(({ data }) => {
          return data?.updateEmployeeSchedule.schedule;
        })
      );
  }

  createMember(data: any) {
    data.startDate = this.dateHelper.formatDate(new Date());
    data.endDate = this.dateHelper.formatDate(new Date());
    return this.apollo.mutate<{createEmployee: { employee: User }}>({
      mutation: gql`
          mutation createEmployee(
              $addressData: AddressInput
              $assignedTeamsUid: [String]!
              $birthDate: Date
              $checksInWithTeam: Boolean
              $contractDate: Date
              $firstName: String!
              $gender: String
              $geoFencingsUid: [String]
              $lastName: String!
              $phone: String
              $photo: String
              $positionUid: String
              $rfc: String
              $curp: String
              $teamUid: String
              $timezone: String
              $email: String!
              $password: String
              $startDate: String!
              $endDate: String!
              $socialSecurityNumber: String
          ) {
              createEmployee(
                  addressData: $addressData
                  assignedTeamsUid: $assignedTeamsUid
                  positionUid: $positionUid
                  baseData: {
                      birthDate: $birthDate
                      contractDate: $contractDate
                      firstName: $firstName
                      gender: $gender
                      lastName: $lastName
                      phone: $phone
                      password: $password
                      email: $email
                      timezone: $timezone
                      checksInWithTeam: $checksInWithTeam
                      rfc: $rfc
                      socialSecurityNumber: $socialSecurityNumber
                      curp: $curp
                  }
                  photoUid: $photo
                  teamUid: $teamUid
                  geoFencingsUid: $geoFencingsUid
              ) {
                  employee {
                      uid
                      firstName
                      lastName
                      email
                      remainingVacationDays
                      calendarSchedules(
                          endDate: $endDate
                          startDate: $startDate
                      ) {
                          date
                          turns {
                              uid
                              index
                              fromHour
                              dayConfig {
                                  category
                                  approvedAbsence {
                                      uid
                                  }
                                  active
                              }
                              toHour
                              checkinAtt {
                                  eventCategory
                                  autoCreated
                                  latitude
                                  longitude
                                  onLocation
                                  onTime
                                  timestamp
                              }
                              checkoutAtt {
                                  eventCategory
                                  autoCreated
                                  latitude
                                  longitude
                                  onLocation
                                  onTime
                                  timestamp
                              }
                          }
                      }
                      photo {
                          url
                      }
                  }
              }
          }
      `,
      variables: {
        ...data
      }
    }).pipe(
        map(({data}) => {
          // @ts-ignore
          return data.createEmployee.employee;
        })
    )
  }

  downloadFile(url: string, params: any): Observable<any> {
    const header = new HttpHeaders().set(
      "Authorization",
      "JWT " + this.authService.credentials?.authToken,
    );
    header.set("content-type", "application/json");
    return this.http.post(url, params, {
      responseType: "blob",
      headers: header,
      observe: "response"
    });
  }

  downloadFile2(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "Content-Type",
        "Cache-Control":
          "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
        Pragma: "no-cache",
        Expires: "0"
      }
    });
  }

  getMemberVacations(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            vacationsRules {
              rule
              vacationsConfig {
                uid
                index
                label
                days
              }
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  updateMemberVacations(form: any): Observable<User | null> {
    return this.apollo.mutate<{updateEmployeeVacationConfig: { employee: User }}>({
      mutation: gql`
        mutation updateEmployeeVacationConfig(
          $employeeUid: String!
          $rule: String!
          $vacationsConfigData: [VacationConfigInputType]!
        ) {
          updateEmployeeVacationConfig(
            employeeUid: $employeeUid
            rule: $rule
            vacationsConfigData: $vacationsConfigData
          ) {
            employee {
              uid
              vacationsRules {
                rule
                vacationsConfig {
                  uid
                  index
                  label
                  days
                }
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateEmployeeVacationConfig.employee;
      })
    )
  }

  getMemberActiveResources(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            activeResources {
              uid
              name
              serial
              purchaseDate
              registrationDate
              lastAssigned
              description
              active
              photo {
                url
              }
              currentHolder {
                uid
                firstName
                lastName
                photo {
                  url
                }
                email
              }
              category {
                uid
                name
                color
              }
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  updateMemberActualVacations(form: any): Observable<User | null> {
    return this.apollo.mutate<{updateEmployeeActualVacations: {employee: User}}>({
      mutation: gql`
        mutation updateEmployeeActualVacations(
          $employeeUid: String!
          $actualVacations: Int!
        ) {
          updateEmployeeActualVacations(
            employeeUid: $employeeUid
            actualVacations: $actualVacations
          ) {
            employee {
              uid
              firstName
              lastName
              email
              photo {
                uid
                url
              }
              phone
              birthDate
              timezone
              rfc
              gender
              checksInWithTeam
              contractDate
              address {
                city
                colony
                country
                exteriorNumber
                interiorNumber
                latitude
                longitude
                state
                street
                zipcode
              }
              remainingVacationDays
              socialSecurityNumber
              usedVacationDays
              assignedTeams {
                uid
                name
                color
                timezone
                icon {
                  url
                  uid
                }
              }
              team {
                uid
                name
                color
                timezone
                icon {
                  url
                  uid
                }
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateEmployeeActualVacations.employee;
      })
    )
  }

  getMemberPayrollsTable(filters: any): Observable<PaycheckTable> {
    if (filters.startDate)
      filters.startDate = this.dateHelper.formatDate(filters.startDate);
    if (filters.endDate)
      filters.endDate = this.dateHelper.formatDate(filters.endDate);
    return this.apollo.query<{paycheckTable: PaycheckTable}>({
      query : gql`
        query paycheckTable(
            $employeeUid: String!
            $substring: String
            $startDate: String
            $endDate: String
            $skip: Int
            $limit: Int
        ) {
          paycheckTable(
            employeeUid: $employeeUid
            substring: $substring
            startDate: $startDate
            endDate: $endDate
            skip: $skip
            limit: $limit
          ) {
            count
            paychecks {
              payroll {
                uid
                numberId
                startDate
                endDate
                stampedStatus
                disbursedStatus
                employerRegistry {
                  businessName
                }
                name
              }
              totalDeductions
              totalEarnings
              totalNet
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.paycheckTable;
      })
    )
  }

  getContractChoices(): Observable<ContractShift[]> {
    return this.apollo.query<{contractTypes: ContractShift[]}>({
      query: gql`
        query contractTypes {
          contractTypes {
            id
            description
          }
        }
      `
    }).pipe(
      map(({data}) => {
        return data.contractTypes;
      })
    )
  }

  getRegimeChoices(): Observable<ContractShift[]> {
    const REGIME_CHOICES = gql`
      query taxRegimes {
        taxRegimes (category: "E") {
          uid
          code
          description
          naturalPerson
          legalPerson
        }
      }
    `;

    return this.apollo.query<{ taxRegimes: ContractShift[] }>({
      query: REGIME_CHOICES
    }).pipe(
      map(({ data }) => {
        return data.taxRegimes;
      })
    )
  }

  getShiftChoices(): Observable<ContractShift[]> {
    return this.apollo.query<{shiftTypes: ContractShift[]}>({
      query: gql`
        query shiftTypes {
          shiftTypes {
            id
            description
          }
        }
      `
    }).pipe(
      map(({data}) => {
        return data.shiftTypes;
      })
    )
  }

  getMemberBankData(employeeUid: string | undefined): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            bankAccount {
              uid
              clabe
              bank {
                uid
                name
                code
              }
            }
          }
        }
      `,
      variables: {
        employeeUid
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }

  createBankAccount(form: any): Observable<User | null> {
    return this.apollo.mutate<{createBankAccount: { employee: User }}>({
      mutation: gql`
        mutation createBankAccount(
          $bankUid: String!
          $clabe: String!
          $employeeUid: String!
        ) {
          createBankAccount(
            bankUid: $bankUid
            clabe: $clabe
            employeeUid: $employeeUid
          ) {
            employee {
              uid
              bankAccount {
                uid
                clabe
                bank {
                  uid
                  name
                  code
                }
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.createBankAccount.employee;
      })
    )
  }

  updateBankAccount(form: any): Observable<User | null> {
    return this.apollo.mutate<{updateBankAccount: { employee: User }}>({
      mutation: gql`
        mutation updateBankAccount(
          $bankUid: String
          $clabe: String
          $employeeUid: String!
        ) {
          updateBankAccount(
            bankUid: $bankUid
            clabe: $clabe
            employeeUid: $employeeUid
          ) {
            employee {
              uid
              bankAccount {
                uid
                clabe
                bank {
                  uid
                  name
                  code
                }
              }
            }
          }
        }
      `,
      variables: {
        ...form
      }
    }).pipe(
      map(({data}) => {
        // @ts-ignore
        return data.updateBankAccount.employee;
      })
    )
  }

  getMeetings(filters: MemberMeetingsFilters): Observable<MeetingsRegistryType | undefined> {
    return this.apollo.query<{meetingsRegistry: MeetingsRegistryType}>({
      query: gql`
        query meetingsRegistry(
          $employeeUid: String!
          $temporalCategory: String
          $month: Int
          $year: Int
        ) {
          meetingsRegistry(
            employeeUid: $employeeUid
            temporalCategory: $temporalCategory
            month: $month
            year: $year
          ) {
            nonAttendancesCount
            notOnLocationCount
            notOnTimeCount
            allMeetingsCount
            allMeetings {
              uid
              name
              startDate
              startHour
              checkinIncident
              fromGoogleCalendar
              redFlag
              incidentsCount
              meetingAttendance(employeeUid: $employeeUid) {
                onTime
                onLocation
                timestamp
              }
              place {
                longitude
                latitude
                address {
                  city
                  colony
                  country
                  exteriorNumber
                  interiorNumber
                  latitude
                  longitude
                  state
                  street
                  zipcode
                  fullAddress
                }
              }
            }
            nonAttendances {
              uid
              name
              startDate
              startHour
              checkinIncident
              fromGoogleCalendar
              redFlag
              incidentsCount
              meetingAttendance(employeeUid: $employeeUid) {
                onTime
                onLocation
                timestamp
              }
              place {
                longitude
                latitude
                address {
                  city
                  colony
                  country
                  exteriorNumber
                  interiorNumber
                  latitude
                  longitude
                  state
                  street
                  zipcode
                  fullAddress
                }
              }
            }
            notOnLocation {
              uid
              name
              startDate
              startHour
              checkinIncident
              fromGoogleCalendar
              redFlag
              incidentsCount
              meetingAttendance(employeeUid: $employeeUid) {
                onTime
                onLocation
                timestamp
              }
              place {
                longitude
                latitude
                address {
                  city
                  colony
                  country
                  exteriorNumber
                  interiorNumber
                  latitude
                  longitude
                  state
                  street
                  zipcode
                  fullAddress
                }
              }
            }
            notOnTime {
              uid
              name
              startDate
              startHour
              checkinIncident
              fromGoogleCalendar
              redFlag
              incidentsCount
              meetingAttendance(employeeUid: $employeeUid) {
                onTime
                onLocation
                timestamp
              }
              place {
                longitude
                latitude
                address {
                  city
                  colony
                  country
                  exteriorNumber
                  interiorNumber
                  latitude
                  longitude
                  state
                  street
                  zipcode
                  fullAddress
                }
              }
            }
          }
        }
      `,
      variables: {...filters}
    }).pipe(
      map(({data}) => {
        return data.meetingsRegistry;
      })
    )
  }

  getGeoLocations(filters: any): Observable<User | null> {
    return this.apollo.query<{employee: User}>({
      query: gql`
        query employee(
          $employeeUid: String!
          $startDate: String!
          $endDate: String!
        ) {
          employee(
            employeeUid: $employeeUid
          ) {
            uid
            team {
              uid
              name
              color
            }
            geoLocations(startDate: $startDate endDate: $endDate) {
              latitude
              longitude
              timestamp
              uid
            }
          }
        }
      `,
      variables: {
        ...filters
      }
    }).pipe(
      map(({data}) => {
        return data.employee;
      })
    )
  }
}
