<div class="schedule-form-control">
  <div class="name-container">
    <div class="name">{{ dayName | titlecase }}</div>
    <div class="checks-box">
      <label nz-checkbox [(ngModel)]="categories[0].selected" (ngModelChange)="setCategory('p')">{{ categories[0].name }}</label>
      <label nz-checkbox [(ngModel)]="categories[1].selected" (ngModelChange)="setCategory('r')">{{ categories[1].name }}</label>
    </div>
  </div>

  <drag-scroll class="turns-container" [ngClass]="{'max-height': getFormArray().length > 0}">
    <div class="turn" *ngFor="let turn of getFormArray().controls, let i = index;">
      <app-turn-form-control
        [clean]="clean"
        [index]="i"
        [dayConfig]="dayConfig"
        [editable]="i !== (this.getFormArray().length - 1)"
        [turnForm]="getTurn(i)"
        [intervals]="timeIntervals"
        (updateInterval)="updateIntervals($event)"
        (deleteTurn)="removeTurn(i)">
      </app-turn-form-control>
    </div>

    <i *ngIf="getFormArray().length > 0 && lastHourNotSelected" class="add-icon" nz-icon nzType="plus-circle" nzTheme="fill" [nz-tooltip]="'Agregar turno'" nzTooltipColor="white" (click)="addTurn()"></i>

    <nz-empty style="margin: 6px auto;" *ngIf="getFormArray().length === 0" [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="footerTpl">
      <ng-template #contentTpl>
        <span>Este día no tienes turnos</span>
      </ng-template>

      <ng-template #footerTpl>
        <button nz-button nzType="primary" (click)="addTurn()">Agregar turnos</button>
      </ng-template>
    </nz-empty>
  </drag-scroll>

  <!--<div class="turns-container">
    <div class="turn" *ngFor="let turn of getFormArray().controls, let i = index;">
      <app-turn-form-control
        [clean]="clean"
        [index]="i"
        [dayConfig]="dayConfig"
        [editable]="i !== (this.getFormArray().length - 1)"
        [turnForm]="getTurn(i)"
        [intervals]="timeIntervals"
        (updateInterval)="updateIntervals($event)"
        (deleteTurn)="removeTurn(i)">
      </app-turn-form-control>
    </div>

    <i *ngIf="getFormArray().length > 0 && lastHourNotSelected" class="add-icon" nz-icon nzType="plus-circle" nzTheme="fill" [nz-tooltip]="'Agregar turno'" nzTooltipColor="white" (click)="addTurn()"></i>

    <nz-empty style="margin: 6px auto;" *ngIf="getFormArray().length === 0" [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="footerTpl">
      <ng-template #contentTpl>
        <span>Este día no tienes turnos</span>
      </ng-template>

      <ng-template #footerTpl>
        <button nz-button nzType="primary" (click)="addTurn()">Agregar turnos</button>
      </ng-template>
    </nz-empty>
  </div>-->
</div>
