import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Earning} from "../../../core/models/earning";
import {Subject} from "rxjs";
import {EarningsService} from "../../../core/services/earnings.service";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {debounceTime, finalize, takeUntil} from "rxjs/operators";
import {NewEarningModalComponent} from "../../modals/new-earning-modal/new-earning-modal.component";

@Component({
  selector: 'app-earnings-form-control',
  templateUrl: './earnings-form-control.component.html',
  styleUrls: ['./earnings-form-control.component.scss']
})
export class EarningsFormControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() earnings: FormControl
  @Input() defaultEarnings: Earning[] = [];
  @Input() moreThanOne: boolean = true;
  @Input() blockDefaults = false;
  @Output() updateTotals = new EventEmitter<void>();
  earningsCount = 0;

  list: Earning[] = [];
  earningsLoading = false;
  isOpen = false;
  earningsIndex = 1;
  stopLoading = false;
  searchEarning: null | string;
  $searchChange: Subject<string> = new Subject<string>();
  firstSearch = true;

  $onDestroy: Subject<void> = new Subject<void>();
  compareDeductions = (o1: Earning, o2: Earning) => o1?.uid === o2?.uid;

  constructor(private earningsService: EarningsService,
              private drawerService: NzDrawerService) { }

  ngOnInit(): void {
    this.earningsCount = this.earnings.value.length;
    this.loadEarnings();
    this.subscribeToSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultEarnings) {
      const tempList: Earning[] = [];
      this.earnings?.value?.forEach((earning: Earning) => {
        const index = this.defaultEarnings.findIndex(d => d.uid === earning.uid);
        if (index === -1) {
          if (!earning.isDefault) {
            tempList.push(earning);
          }
        }
      });
      this.defaultEarnings.reverse().forEach(earning => {
        tempList.unshift(earning);
      });
      this.earnings.setValue(tempList);
    }
  }

  ngOnDestroy(): void {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  openNewEarning() {
    const modal = this.drawerService.create({
      nzContent: NewEarningModalComponent,
      nzTitle: 'Nueva percepción',
      nzWidth: 504,
      nzPlacement: 'left'
    });

    modal.afterClose.pipe(
      takeUntil(this.$onDestroy)
    ).subscribe((earning: Earning) => {
      if (earning) {
        this.list?.unshift(earning);
        this.earnings.setValue([...this.earnings.value, earning]);
        this.updateTotals.emit();
      }
    })
  }

  removeEarning(earning: Earning) {
    this.earnings.setValue(
      this.earnings.value.filter((el: Earning) => el.uid != earning.uid)
    );
    this.updateTotals.emit();
  }

  updateAction($event: any) {
    const count = $event.length;
    if (count > this.earningsCount) {
      this.earnings.value[count - 1].editWorth = this.earnings.value[count - 1].worth;
    }
    this.earningsCount = $event.length - 1;
    this.updateTotals.emit();
  }

  onEarningsSearch($event: string) {
    if (!this.firstSearch) {
      this.stopLoading = false;
      this.list = [];
      this.searchEarning = $event === "" ? null : $event;
      this.earningsIndex = 1;
      this.loadEarnings();
    }
    this.firstSearch = false;
  }

  loadEarnings() {
    if (!this.stopLoading) {
      this.earningsLoading = true;
      this.earningsService.getEarnings({
        substring: this.searchEarning,
        skip: this.earningsIndex,
        limit: 1000
      }).pipe(
        finalize(() => {
          this.earningsLoading = false;
        })
      )
        .subscribe(EarningsTable => {
          if (EarningsTable?.earnings?.length) {
            const tempList: Earning[] = [];
            this.list.forEach(earning => {
              if (this.defaultEarnings.length > 0) {
                const index = this.defaultEarnings.findIndex(d => d.uid === earning.uid);
                if (index === -1) {
                  tempList.push(earning);
                }
              } else {
                tempList.push(earning);
              }
            });
            EarningsTable?.earnings.forEach(earning => {
              if (this.defaultEarnings.length > 0) {
                const index = this.defaultEarnings.findIndex(d => d.uid === earning.uid);
                if (index === -1) {
                  tempList.push(earning);
                }
              } else {
                tempList.push(earning);
              }
            });
            if (this.defaultEarnings.length > 0) {
              this.defaultEarnings.reverse().forEach(earning => {
                tempList.unshift(earning);
              });
            }
            this.list = tempList;
          } else {
            this.stopLoading = true;
          }
        });
      this.earningsIndex++;
    }
  }

  notSelected(earning: Earning) {
    const index = this.list.findIndex(e => e.uid === earning.uid);
    return index !== -1;

  }

  private subscribeToSearch() {
    this.$searchChange
      .pipe(takeUntil(this.$onDestroy), debounceTime(400))
      .subscribe(search => {
        this.onEarningsSearch(search);
      });
  }
}
