<div class="meeting-details-modal">
  <div class="title-container">
    <div class="title-box">
      <div class="container">
        <div class="img-box" *ngIf="meeting?.fromGoogleCalendar">
          <img [src]="'../assets/images/meet.png'" [alt]="'meet-icon'">
        </div>
        <i *ngIf="!meeting?.fromGoogleCalendar" nz-icon nzType="icons:briefcase"></i>
    </div>
      <div class="title">{{ meeting?.name }}</div>
    </div>

    <i class="close" nz-icon nzType="close" (click)="close()"></i>
  </div>

  <div class="meeting-body">
    <nz-spin [nzSpinning]="isLoading" *ngIf="isLoading"></nz-spin>

    <div class="error-box" *ngIf="error && !isLoading">
      <nz-alert
        nzType="error"
        [nzMessage]="error"
        nzShowIcon
      ></nz-alert>
    </div>

    <div class="date-row" *ngIf="!isLoading">
      <div class="date-box">
        <div class="date">{{ meeting?.startDate | date: 'dd' }}</div>
        <div class="subtitle">{{ (meeting?.startDate | date: 'MMM')?.toUpperCase().replace('.', '') }}</div>
      </div>
      <div class="time-box">
        <div class="time">{{ meeting?.startHour }}</div>
        <div class="subtitle">HRS</div>
      </div>
    </div>

    <div class="address-row" *ngIf="!isLoading">
      <div class="img-box">
        <img [src]="'../assets/images/pin-1.png'" [alt]="'pin-icon'">
      </div>
      <div class="address">{{ meeting?.fromGoogleCalendar ? (meeting?.place !== null && meeting?.place?.address !== null) ? meeting?.place?.address?.fullAddress : 'Sin dirección registrada' : (meeting?.place?.address?.fullAddress || 'Sin dirección registrada') }}</div>
    </div>

    <div class="url-row" *ngIf="!isLoading">
      <i nz-icon nzType="link" nzTheme="outline"></i>
      <a *ngIf="meeting?.hangoutLink !== null" (click)="goToLink(meeting.hangoutLink)">{{ meeting?.hangoutLink }}</a>
      <div class="url" *ngIf="meeting?.hangoutLink === null">Sin url registrada</div>
    </div>

    <nz-spin [nzSpinning]="addressLoading">
      <div class="map-row" *ngIf="!isLoading && meeting?.place !== null">
        <app-geo-fencing-map [meters]="meeting?.place?.rangeMeters || 200" [marker]="marker" [userPins]="userPins" [clickChanges]="false"></app-geo-fencing-map>
      </div>
    </nz-spin>

    <div class="users-row" *ngIf="!isLoading">
      <div class="user-block" *ngFor="let member of meeting?.participants">
        <div class="user-row flex align-center">
          <div class="user-photo">
            <nz-avatar [nzSize]="30"  [nzSrc]="member?.photo?.url || undefined"
                       [nzText]="member?.firstName?.charAt(0) + '' + member?.lastName?.charAt(0)"></nz-avatar>
          </div>

          <div class="user-data">
            <div class="user-name">
              {{member?.firstName | titlecase}} {{member?.lastName | titlecase}}
            </div>
            <div class="user-email">
              {{member?.email}}
            </div>
          </div>
        </div>

        <div class="user-title">Registro</div>

        <div class="user-info-box">
          <div class="user-time-box">
            <div class="time">Hora: <span class="subtime">{{ meeting?.startHour }}</span></div>
            <div class="time">Hora registrada: <span class="subtime">{{ (member?.meetingAttendance?.correctTimestamp | date:'HH:mm') || '-'}}</span></div>
          </div>

          <div class="status-box" *ngIf="!isPastDate() && member?.meetingAttendance === null">
            <!--<div class="status">
              <div class="background grey"></div>
              <div class="label grey">
                Pendiente
              </div>
            </div>-->

            <div class="status">
              <div class="background grey"></div>
              <div class="label grey">
                Pendiente
              </div>
            </div>
          </div>

          <div class="status-box" *ngIf="isPastDate() && member?.meetingAttendance === null">
            <!--<div class="status">
              <div class="background red"></div>
              <div class="label red">
                No realizado
              </div>
            </div>-->

            <div class="status">
              <div class="background red"></div>
              <div class="label red">
                No realizado
              </div>
            </div>
          </div>

          <div class="status-box" *ngIf="isPastDate() && member?.meetingAttendance !== null">
            <div class="status">
              <div class="background green" *ngIf="member?.meetingAttendance !== null && member?.meetingAttendance?.onTime"></div>

              <div class="background yellow" *ngIf="member?.meetingAttendance !== null && !member?.meetingAttendance?.onTime"></div>

              <div class="background grey" *ngIf="member?.meetingAttendance === null"></div>

              <div class="label green" *ngIf="member?.meetingAttendance !== null && member?.meetingAttendance?.onTime">
                En tiempo
              </div>

              <div class="label yellow" *ngIf="member?.meetingAttendance !== null && !member?.meetingAttendance?.onTime">
                Fuera de tiempo
              </div>

              <div class="label grey" *ngIf="member?.meetingAttendance === null">
                Pendiente
              </div>
            </div>

            <div class="status">
              <div class="background green" *ngIf="member?.meetingAttendance !== null && member?.meetingAttendance?.onLocation"></div>

              <div class="background yellow" *ngIf="member?.meetingAttendance !== null && !member?.meetingAttendance?.onLocation"></div>

              <div class="background grey" *ngIf="member?.meetingAttendance === null"></div>

              <div class="label green" *ngIf="member?.meetingAttendance !== null && member?.meetingAttendance?.onLocation">
                En ubicación
              </div>

              <div class="label yellow" *ngIf="member?.meetingAttendance !== null && !member?.meetingAttendance?.onLocation">
                Fuera de ubicación
              </div>

              <div class="label grey" *ngIf="member?.meetingAttendance === null">
                Pendiente
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
