import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Country} from '../models/country';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
      private http: HttpClient
  ) { }

  getCountries(): Observable<Country[] | undefined> {
    return this.http
        .get(
            environment.geoNamesApi +
            "/countryInfoJSON?username=" +
            environment.geoNamesUser
        )
        .pipe(
            map((countries: any) => {
              return countries.geonames;
            })
        );
  }
}
