<div class="announcement-details">
  <div class="announcement-image" *ngIf="announcement?.mainImage">
    <div class="img-box">
      <img [src]="announcement?.mainImage?.url">
    </div>
  </div>

  <div class="date-row flex between wrap">
    <div class="date">
      <span class="label">Desde:</span> {{announcement.startDate | date : 'dd MMM yyyy'}}
    </div>

    <div class="date">
      <span class="label">Hasta:</span> {{announcement.endDate | date : 'dd MMM yyyy'}}
    </div>
  </div>

  <div class="description" *ngIf="announcement.description">
    {{announcement.description}}
  </div>

  <div class="files-column flex column">

    <div class="file-box" *ngFor="let file of announcement?.files">
      <app-download-file-box [file]="file"></app-download-file-box>
    </div>
  </div>

  <div class="teams-box">
    <nz-form-label class="team-label">Equipos de trabajo</nz-form-label>
    <app-teams-form-control [teams]="getTeamsControl()" [allowDelete]="false" [allowNew]="false"></app-teams-form-control>
  </div>

  <div class="channels-title" *ngIf="announcement.slackChannels.length > 0">Canales de slack:</div>

  <div class="channels-container">
    <div class="channel" *ngFor="let channel of announcement.slackChannels">
      <div class="img-box">
        <img [src]="'../assets/images/slack.png'" alt="slack-icon">
      </div>
      <div class="channel-name">{{ channel.name }}</div>
    </div>
  </div>
</div>
