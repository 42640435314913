<div class="announcement-box flex wrap">

  <div class="announcement-name-row flex align-center">
    <div class="announcement-icon flex align-center center">
      <div class="img-box">
        <img [src]="announcement?.icon?.url">
      </div>
    </div>
    <div (click)="openAnnouncement()" class="announcement-name router-label">
      {{announcement.title}}
    </div>
  </div>

  <div class="teams-row flex wrap align-center">
    <div class="team-box"
         nzTooltipColor="white"
         [nz-tooltip]="(team.name | titlecase)"
         *ngFor="let team of (announcement?.addressedTeams | slice:0: 5)">
        <app-team-icon [size]="28" [icon]="team.icon?.url"  [color]="team.color"></app-team-icon>
    </div>
    <div class="announcement-extra" *ngIf="(announcement?.addressedTeams?.length || 0) > 0">
      {{(announcement.addressedTeams?.length || 0)}} Equipo{{ (announcement.addressedTeams?.length || 0) != 1 ? 's' : ''}}
    </div>
    <div class="announcement-extra flex align-center" *ngIf="(announcement.addressedTeams?.length || 0) <= 0">
      <i class="empty-icon" nz-icon nzType="icons:empty" nzTheme="outline"></i>Sin equipo asignado
    </div>
  </div>

  <div class="integrations-box flex align-center">
    <div class="img-box" *ngIf="announcement.slackChannels.length > 0">
      <img [src]="'../assets/images/slack.png'" alt="slack-icon">
    </div>
  </div>

  <div class="description-box flex align-center">
    <div class="description">
      {{announcement.description}}
    </div>
  </div>


  <div class="announcement-actions flex end align-center">

    <ng-container *ngIf="!isLoading">

      <div *ngIf="announcement?.files?.length"
           class="announcement-action flex align-center"
           nzTooltipColor="white"
           [nz-tooltip]="announcement?.files?.length == 1 ? announcement?.files?.length + ' archivo adjunto' : announcement?.files?.length + ' archivos adjuntos'">
        <span class="count">{{announcement?.files?.length}}</span> <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
      </div>

      <div class="announcement-action flex align-center"
           nzTrigger="click" nz-dropdown nzTooltipColor="white"
           nz-tooltip="Configurar anuncio" [nzClickHide]="false" [nzDropdownMenu]="menu">
        <i nz-icon nzType="setting" nzTheme="fill"></i>
      </div>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="openEditAnnouncement()">
            <i class="empty-icon" nz-icon nzType="edit" nzTheme="outline"></i> Editar anuncio</li>
          <li nz-menu-item nzDanger nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas eliminar este anuncio?"
              (nzOnConfirm)="deleteAnnouncement()">
            <i class="empty-icon" nz-icon nzType="delete" nzTheme="outline"></i> Eliminar anuncio</li>
        </ul>
      </nz-dropdown-menu>

<!--      <div class="announcement-action flex align-center" nzTooltipColor="white" nz-tooltip="Ver anuncio" (click)="openAnnouncement()">-->
<!--        <i nz-icon nzType="eye" nzTheme="fill"></i>-->
<!--      </div>-->
    </ng-container>

    <ng-container *ngIf="isLoading">
      <nz-spin nzSize="small"></nz-spin>
    </ng-container>
  </div>
</div>
