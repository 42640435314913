<div class="create-active-resource-category-component">
  <div class="error-box" *ngIf="error && !isLoading">
    <nz-alert
      nzType="error"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  </div>

  <form nz-form nzLayout="vertical" [formGroup]="categoryForm" *ngIf="categoryForm && !error">

    <nz-form-item>
      <nz-form-label nzFor="name">Nombre</nz-form-label>
      <nz-form-control [nzErrorTip]="nameErrorTpl">

        <input nz-input placeholder="Nombre de la categoría" formControlName="name" type="text" id="name">

        <ng-template #nameErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            El nombre es requerido
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="color">Color</nz-form-label>
      <nz-form-control [nzErrorTip]="colorErrorTpl">
        <nz-select [nzCustomTemplate]="colorTemplate" id="color" nzPlaceHolder="Seleccionar el color de la categoría" formControlName="color">
          <nz-option *ngFor="let color of colors" nzCustomContent [nzLabel]="color!.label" [nzValue]="color!.value">
            <app-color-box [color]="color"></app-color-box>
          </nz-option>
        </nz-select>

        <ng-template #colorTemplate let-selected>
          <app-color-box [size]="20" [color]="{
              label: selected.nzLabel,
              value: selected.nzValue
            }"></app-color-box>
        </ng-template>

        <ng-template #colorErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Escoge un color
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>


  </form>

  <div class="btns-container" *ngIf="!isLoading">
    <button [disabled]="isLoading" nz-button nzType="default" (click)="close()">Cancelar</button>
    <button [nzLoading]="isLoading" nz-button nzType="primary" (click)="createCategory()">Guardar</button>
  </div>
</div>
