import {Component, Input, OnInit} from '@angular/core';
import {Company} from "../../../core/models/company";
import {IntegrationsService} from "../../../core/services/integrations.service";

@Component({
  selector: 'app-slack-row',
  templateUrl: './slack-row.component.html',
  styleUrls: ['./slack-row.component.scss']
})
export class SlackRowComponent implements OnInit {
  @Input() company: Company | null;
  isRemoving = false;

  constructor(private integrationsService: IntegrationsService) { }

  ngOnInit(): void {
  }

  goToSlack() {
    this.integrationsService.installSlack();
  }

  removeSlack() {
    this.isRemoving = true;
  }
}
