import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {User} from '../../../core/models/user';
import {MemberService} from '../../../core/services/member.service';
import {debounceTime, finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-members-form-control',
  templateUrl: './members-form-control.component.html',
  styleUrls: ['./members-form-control.component.scss']
})
export class MembersFormControlComponent implements OnInit, OnDestroy {
  @Input() members: FormControl;
  @Input() type: 'TeamLeaders' | 'Members' | 'all';
  @Input() moreThanOne: boolean = true;
  @Output() membersChanged = new EventEmitter<void>();

  list: User[] | undefined;

  membersLoading = false;
  isOpen = false;
  stopLoading = false;

  searchIndex = 1;

  substring: string | null;

  $onDestroy: Subject<void> = new Subject<void>();
  $searchChange: Subject<string> = new Subject<string>();

  compareUser = (o1: User, o2: User) => o1?.uid === o2?.uid;

  constructor(
      private memberService: MemberService
  ) { }

  ngOnInit(): void {
    this.subscribeToSearch();
    this.loadMembers();
    //  TODO add create new member only for admins
  }

  ngOnDestroy(): void {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  loadMembers() {
    if (!this.stopLoading) {
      this.membersLoading = true;
      this.memberService.getMembers({
        substring: this.substring,
        skip: this.searchIndex,
        unassigned: this.type === 'all' ? false : this.type != 'TeamLeaders',
        limit: 10,
        order: this.type === 'all' ? "desc" : this.type == 'TeamLeaders' ? "asc" : "desc",
        sort: "number_id"
      })
          .pipe(
              finalize(() => {
                this.membersLoading = false;
              })
          )
          .subscribe( (res) => {
            if (res?.employees?.length) {
              this.list = [...(this.list || []), ...res.employees];
            } else {
              this.stopLoading = true;
            }
          });
      this.searchIndex++;
    }
  }

  substringSearch(): void {
    this.$searchChange.next();
  }

  private subscribeToSearch() {
    this.$searchChange
        .pipe(takeUntil(this.$onDestroy), debounceTime(400))
        .subscribe(() => {
          this.stopLoading = false;
          this.searchChanged();
        });
  }

  searchChanged() {
    this.searchIndex = 1;
    this.list = []
    this.loadMembers();
  }

  removeMember(member: any) {
    this.members.setValue(
        this.members.value.filter((el: User) => el.uid != member.uid)
    );
  }

  updateMembers() {
    this.membersChanged.emit();
  }
}
