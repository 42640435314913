import { Injectable } from '@angular/core';
import {Help} from '../models/help';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  helpList: Help[] = [
    {
      uid: '1',
      title: 'Agrega un nuevo puesto',
      description: 'Establece cuantos puestos conformen la organización de tu empresa para que puedas designarlos a tus colaboradores.',
      image: 'puesto.png'
    },
    {
      uid: '2',
      title: 'Agrega un nuevo equipo de trabajo',
      description: 'Los equipos de trabajo sirven para mantener la organización de tu empresa. Identifica cada uno con un ícono, nombre y color. Puedes asignarle a cada equipo uno o mas lideres y geocercas.',
      image: 'equipo.png'
    },
    {
      uid: '3',
      title: 'Agrega un nuevo colaborador',
      description: 'Los colaboradores son todas aquellas personas que conforman tu empresa. Puedes registrar toda su información general así como su puesto, sueldo y horarios.',
      image: 'colaborador.png'
    },
    {
      uid: '4',
      title: 'Agrega un nuevo anuncio',
      description: 'Un anuncio sirve para hacerle llegar a tus colaboradores un mensaje. Puedes elegir a que equipos les aparecerá este anuncio y las fechas en que estará disponible.',
      image: 'anuncio.png'
    },
    {
      uid: '5',
      title: 'Solicitud de ausencia',
      description: 'Las solicitudes son peticiones por parte de tus colaboradores para ausentarse. Revisa el tipo de asueto que se esta solicitando, las fechas y su estatus.',
      image: 'solicitud.png'
    },
    {
      uid: '6',
      title: 'Agrega una nueva geocerca',
      description: 'Las geocercas son las ubicaciones en las que tu colaborador puede marcar como correcto su registro de check in y check out.',
      image: 'geocercas.png'
    },
    {
      uid: '7',
      title: 'Agrega una tipo de asueto',
      description: 'Establece los tipos de asueto que existen en tu empresa para que tus colaboradores puedan hacer sus solicitudes con base a estos. Indica si es pagado o no y si resta días de caciones o no.',
      image: 'ausencia.png'
    },
    {
      uid: '8',
      title: 'Información general',
      description: 'Revisa y edita los datos generales de tu colaborador para llevar un mejor registro sobre tu empresa.',
      image: 'colaborador.png'
    },
    {
      uid: '9',
      title: 'Puesto y salario',
      description: 'Revisa y edita el puesto que tiene tu colaborador dentro de la empresa, así como su sueldo designado.',
      image: 'artes.png'
    },
    {
      uid: '10',
      title: 'Documentos',
      description: 'Sube y revisa los archivos que corresponden a la documentación de tu colaborador.',
      image: 'documentos.png'
    },
  ]

  constructor() { }
}
