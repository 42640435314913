import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Announcement} from '../models/announcement';
import {AnnouncementsTableFilter} from '../models/announcements-table-filter';
import {AnnouncementTable} from '../models/announcement-table';
import {Apollo} from 'apollo-angular';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(
      private apollo: Apollo,
      private datePipe: DatePipe
  ) { }

  createAnnouncement(announcement: Announcement): Observable<Announcement | undefined> {
    return this.apollo.mutate<{createAnnouncement: { announcement: Announcement }}>({
      mutation: gql`
          mutation createAnnouncement(
              $addressedTeamsUid: [String]!
              $description: String
              $endDate: Date!
              $filesUid: [String]!
              $iconUid: String!
              $mainImageUid: String
              $startDate: Date!
              $title: String!
              $publicationDate: Date
              $publicationHour: String
              $sendByEmail: Boolean
              $slackChannelsUid: [String]!
          ) {
              createAnnouncement(
                addressedTeamsUid: $addressedTeamsUid
                description: $description
                endDate: $endDate
                filesUid: $filesUid
                iconUid: $iconUid
                mainImageUid: $mainImageUid
                startDate: $startDate
                title: $title
                publicationDate: $publicationDate
                publicationHour: $publicationHour
                sendByEmail: $sendByEmail
                slackChannelsUid: $slackChannelsUid
              ) {
                  announcement {
                      title
                      description
                      uid
                      startDate
                      endDate
                      publicationHour
                      publicationDate
                      sendByEmail
                      files {
                          url
                          extension
                          name
                          uid
                      }
                      icon {
                          uid
                          url
                      }
                      mainImage {
                          url
                          extension
                          name
                          uid
                      }
                      addressedTeams {
                          uid
                          name
                          icon {
                              url
                          }
                          color
                      }
                    slackChannels {
                      uid
                      channelId
                      name
                      botAdded
                    }
                  }
              }
          }
      `,
      variables: {
        ...announcement
      }
    }).pipe(
        map(({data}) => {
          return data?.createAnnouncement.announcement;
        })
    )
  }

  editAnnouncement(announcement: Announcement): Observable<Announcement | undefined> {
    return this.apollo.mutate<{updateAnnouncement: { announcement: Announcement }}>({
      mutation: gql`
          mutation updateAnnouncement(
              $addressedTeamsUid: [String]!
              $description: String
              $endDate: Date!
              $filesUid: [String]!
              $iconUid: String!
              $mainImageUid: String
              $startDate: Date!
              $title: String!
              $announcementUid: String!
              $publicationDate: Date
              $publicationHour: String
              $sendByEmail: Boolean
              $slackChannelsUid: [String]!
          ) {
              updateAnnouncement(
                  addressedTeamsUid: $addressedTeamsUid
                  description: $description
                  endDate: $endDate
                  filesUid: $filesUid
                  iconUid: $iconUid
                  mainImageUid: $mainImageUid
                  startDate: $startDate
                  title: $title
                  announcementUid: $announcementUid
                  publicationDate: $publicationDate
                  publicationHour: $publicationHour
                  sendByEmail: $sendByEmail
                  slackChannelsUid: $slackChannelsUid
              ) {
                  announcement {
                      title
                      description
                      startDate
                      endDate
                      publicationDate
                      publicationHour
                      sendByEmail
                      uid
                      files {
                          url
                          extension
                          name
                          uid
                      }
                      icon {
                          uid
                          url
                      }
                      mainImage {
                          url
                          extension
                          name
                          uid
                      }
                      addressedTeams {
                          uid
                          name
                          icon {
                              url
                          }
                          color
                      }
                    slackChannels {
                      uid
                      channelId
                      name
                      botAdded
                    }
                  }
              }
          }
      `,
      variables: {
        ...announcement
      }
    }).pipe(
        map(({data}) => {
          return data?.updateAnnouncement.announcement;
        })
    )
  }

  getAnnouncementsTable(filters: AnnouncementsTableFilter): Observable<AnnouncementTable> {
    const copyFilters = {...filters};
    if (copyFilters.startDate) {
      copyFilters.startDate = this.datePipe.transform(copyFilters.startDate, 'yyyy-MM-dd');
    }
    if (copyFilters.endDate) {
      copyFilters.endDate = this.datePipe.transform(copyFilters.endDate, 'yyyy-MM-dd');
    }
    return this.apollo.query<{announcementsTable: AnnouncementTable}>({
      query: gql`
          query announcementsTable(
              $substring: String
              $teamUid: String
              $startDate: String
              $endDate: String
              $skip: Int
              $limit: Int
              $sort: String
              $order: String
          ) {
              announcementsTable(
                  substring: $substring
                  teamUid: $teamUid
                  skip: $skip
                  limit: $limit
                  sort: $sort
                  startDate: $startDate
                  endDate: $endDate
                  order: $order
              ) {
                  announcements{
                      title
                      description
                      uid
                      startDate
                      endDate
                      publicationDate
                      publicationHour
                      sendByEmail
                      files {
                          url
                          extension
                          name
                          uid
                      }
                      icon {
                          uid
                          url
                      }
                      mainImage {
                          url
                          extension
                          name
                          uid
                      }
                      addressedTeams {
                          uid
                          name
                          icon {
                              url
                          }
                          color
                      }
                    slackChannels {
                      uid
                      channelId
                      name
                      botAdded
                    }
                  }
                  count
              }
          }
      `,
      variables: {...copyFilters}
    }).pipe(
        map(({data}) => {
          return data.announcementsTable;
        })
    );
  }

  deleteAnnouncement(announcementUid: string | undefined) {
    return this.apollo.mutate<{deleteAnnouncement: { message: string}}>({
      mutation: gql`
          mutation deleteAnnouncement(
              $announcementUid: String!
          ) {
              deleteAnnouncement(
                  announcementUid: $announcementUid
              ) {
                  message
              }
          }
      `,
      variables: {
        announcementUid
      }
    }).pipe(
        map(({data}) => {
          return data?.deleteAnnouncement.message;
        })
    )
  }
}
