import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IconStore} from '../../../core/stores/icons.store';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelperService} from '../../../core/services/form-helper.service';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {TeamService} from '../../../core/services/team.service';
import {Icon} from '../../../core/models/icon';
import {finalize} from 'rxjs/operators';
import {Announcement} from '../../../core/models/announcement';
import {AnnouncementService} from '../../../core/services/announcement.service';
import { differenceInCalendarDays } from 'date-fns';
import {Team} from '../../../core/models/team';
import {DateHelperService} from '../../../core/services/date-helper.service';
import {DatePipe} from "@angular/common";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {CompanyService} from "../../../core/services/company.service";
import {Company} from "../../../core/models/company";
import {IntegrationsService} from "../../../core/services/integrations.service";
import {SlackChannel} from "../../../core/models/slack-channel";

@Component({
  selector: 'app-new-announcement-modal',
  templateUrl: './new-announcement-modal.component.html',
  styleUrls: ['./new-announcement-modal.component.scss']
})
export class NewAnnouncementModalComponent implements OnInit, OnDestroy {
  $onDestroy: Subject<void> = new Subject<void>();

  $icons: Observable<Icon[] | undefined> = this.iconsStore.getImages();
  newAnnouncementForm: FormGroup;

  @Input() team: Team | null;

  @Output() announcementCreated: EventEmitter<Announcement> = new EventEmitter();

  isLoading = false;
  error: string | null;

  today = new Date();
  disabledDate = (current: Date): boolean =>
      differenceInCalendarDays(current, this.today) < 0;

  company: Company| null = null;
  isLoadingCompany = true;
  slackChannels: SlackChannel[] = [];

  constructor(
      private iconsStore: IconStore,
      private formBuilder: FormBuilder,
      private formHelper: FormHelperService,
      private drawerRef: NzDrawerRef,
      private teamService: TeamService,
      private announcementService: AnnouncementService,
      private dateHelper: DateHelperService,
      private datePipe: DatePipe,
      private notification: NzNotificationService,
      private companyService: CompanyService,
      private integrationsService: IntegrationsService
  ) { }

  ngOnInit(): void {
    this.integrationsService.getSlackChannels()
      .subscribe((slackChannels: SlackChannel[]) => {
        this.slackChannels = slackChannels;
        this.companyService.getCompanyIntegrations()
          .pipe(finalize(() => {
            this.isLoadingCompany = false;
          }))
          .subscribe((company: Company) => {
            this.company = company;
            this.createForm();
          });
    }, error => {
        this.isLoadingCompany = false;
        this.error = error;
      });
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }

  private createForm() {
    this.newAnnouncementForm = this.formBuilder.group({
      addressedTeams: [this.team ? [this.team] : []],
      description: [null, [Validators.required]],
      dateRange: [null, [Validators.required]],
      files: [[]],
      icon: [null, [Validators.required]],
      mainImage: [null],
      title:  [null, [Validators.required]],
      publicationDate: [null],
      publicationHour: [{value: null, disabled: true}],
      sendByEmail: [false],
      sendBySlack: [false],
      slackChannelsUid: [[]]
    });
  }

  getImageControl(): FormControl {
    return this.newAnnouncementForm.controls.mainImage as FormControl;
  }

  getTeamsControl(): FormControl {
    return this.newAnnouncementForm.controls.addressedTeams as FormControl;
  }

  submitForm() {
    this.error = null;
    if (this.newAnnouncementForm.valid) {
      this.isLoading = true;
      this.announcementService.createAnnouncement(this.cleanData())
          .pipe(
              finalize(() => {
                this.isLoading = false;
              })
          )
          .subscribe(newAnnouncement => {
            this.notification.create('success', '¡Operación exitosa!', 'Anuncio creado correctamente');
            this.announcementCreated.emit(newAnnouncement);
            this.closeModal();
          }, error => {
            this.error = error;
          })
    } else {
      this.formHelper.markFormAsDirty(this.newAnnouncementForm);
    }
  }

  closeModal() {
    this.drawerRef.close();
  }

  private cleanData(): Announcement {
    const data: any = this.newAnnouncementForm.getRawValue();
    data.filesUid = data.files?.map((el: any) => el.uid);
    delete data.files;

    data.addressedTeamsUid = data.addressedTeams?.map((el: any) => el.uid);
    delete data.addressedTeams;

    data.mainImageUid = data.mainImage?.uid;
    delete data.mainImage;

    data.iconUid = data.icon?.uid;
    delete data.icon;

    data.startDate = this.dateHelper.formatDate(data.dateRange[0]);
    data.endDate = this.dateHelper.formatDate(data.dateRange[1]);
    delete data.dateRange;

    if (data.publicationDate !== null) {
      data.publicationDate = this.datePipe.transform(data.publicationDate, 'yyyy-MM-dd');
    }

    if (data.publicationHour !== null) {
      data.publicationHour = this.datePipe.transform(data.publicationHour, 'HH:mm') || '';
    }

    if (!data.sendBySlack) {
      data.slackChannelsUid = [];
    }
    delete data.sendBySlack;

    return data;
  }

  onNewFileUploaded($event: any) {
    this.newAnnouncementForm.controls.files.setValue([...this.newAnnouncementForm.controls.files.value, $event]);
  }

  onFileDeleted($event: any) {
    this.newAnnouncementForm.controls.files.setValue(this.newAnnouncementForm.controls.files.value.filter((file: { uid: any; }) => file.uid !== $event.uid));
  }

  updateValidators($event: any) {
    if ($event === null) {
      this.newAnnouncementForm.controls.publicationDate.clearValidators();
      this.newAnnouncementForm.controls.publicationHour.setValue(null);
      this.newAnnouncementForm.controls.publicationHour.disable();
      this.newAnnouncementForm.controls.publicationHour.clearValidators();
    }
    if ($event !== null) {
      this.newAnnouncementForm.controls.publicationDate.setValidators([Validators.required]);
      if (this.newAnnouncementForm.controls.publicationHour.value === null) {
        this.newAnnouncementForm.controls.publicationHour.setValue(null);
        this.newAnnouncementForm.controls.publicationHour.enable();
        this.newAnnouncementForm.controls.publicationHour.setValidators([Validators.required]);
      }
    }
    this.newAnnouncementForm.controls.publicationDate.updateValueAndValidity();
    this.newAnnouncementForm.controls.publicationHour.updateValueAndValidity();
  }

  addTeamChannels($event: Team) {
    const tempArr = [...this.newAnnouncementForm.controls.slackChannelsUid.value];
    $event.slackChannels?.forEach((channel: SlackChannel) => {
      const index = this.slackChannels.findIndex(c => c.uid === channel.uid);
      if (index !== -1) {
       const indexFound = tempArr.findIndex(channelUid => channelUid === channel.uid);
       if (indexFound === -1) {
         tempArr.push(this.slackChannels[index].uid);
       }
      }
    });
    this.newAnnouncementForm.controls.slackChannelsUid.setValue(tempArr);
  }
}
