import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Paycheck} from "../../../core/models/paycheck";

@Component({
  selector: 'app-member-paycheck-row',
  templateUrl: './member-paycheck-row.component.html',
  styleUrls: ['./member-paycheck-row.component.scss']
})
export class MemberPaycheckRowComponent implements OnInit {
  @Input() paycheck: Paycheck | undefined;
  isDownloading = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToDetails() {
    this.router.navigate(['/home/payroll/' + this.paycheck?.payroll?.uid]).then();
  }

  download() {

  }
}
